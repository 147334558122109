import { ExchangeRate } from 'types';

import api from './api';

export const fetchExchangeRates = async (benchmarkId: number) => {
  const { success, data } = await api.get<ExchangeRate[]>(
    `/benchmarks/${benchmarkId}/exchange_rates`,
  );

  if (success) {
    return data.map((exchangeRateData) => ({
      ...exchangeRateData,
      exchangeRate: Number(exchangeRateData.exchangeRate), // long floats returned as strings,
    }));
  }

  return [];
};
