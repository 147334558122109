import { useEffect, useState, useCallback } from 'react';

import { LocationSuggest } from 'js/components/common/location-fields/LocationSuggest';
import LoadingPlaceholder from 'js/design-system/LoadingPlaceholder/LoadingPlaceholder';
import { SHOULD_VERIFY, shouldVerify } from 'js/services/employeeService';
import { Employee } from 'types';

import { MULTIPLE_LOCATIONS } from './MultiStatesBulkEditDrawer';

interface LocationNameInputProps {
  employee?: Employee;
  onSelect: (locationName: string) => void;
  name?: string;
  loading?: boolean;
  initialLocationName?: string;
  onToggle?: () => void;
}

export const LocationNameInput = ({
  employee,
  onSelect,
  name,
  loading,
  initialLocationName,
  onToggle,
}: LocationNameInputProps) => {
  const notVerified = shouldVerify(employee, SHOULD_VERIFY.location);

  const multipleOption = {
    value: MULTIPLE_LOCATIONS,
    label: MULTIPLE_LOCATIONS,
  };

  const getInputValue = useCallback((employee?: Employee, initialLocationName?: string) => {
    if (employee) {
      return employee.locationName || '';
    }
    return initialLocationName || MULTIPLE_LOCATIONS;
  }, []);

  const [inputValue, setInputValue] = useState(getInputValue(employee, initialLocationName));

  useEffect(() => {
    const searchValue = getInputValue(employee, initialLocationName);
    setInputValue(searchValue);
  }, [employee, getInputValue, initialLocationName]);

  if (loading) {
    return <LoadingPlaceholder />;
  }

  return (
    <LocationSuggest
      name={name || `${employee?.id}-locationNameInput`}
      defaultValue={inputValue}
      appendedOption={employee ? undefined : multipleOption}
      onSelect={(place) => Promise.resolve(onSelect(place.placeFormatted))}
      placeholder="Choose location"
      positionY={employee ? 'bottom' : 'top'}
      needsVerification={notVerified}
      onToggle={onToggle}
    />
  );
};
