import { Company, EditCompanyPayload, EquityConnection } from 'types';

import api from './api';

export const fetchCompanies = async () => {
  const { success, data, error } = await api.get<Company[]>('/companies');
  return { success, data, error: error || !success ? 'Failed to load companies' : null };
};

export const fetchCompany = async (companyId: number) => {
  const { success, data, error } = await api.get<Company>(`/companies/${companyId}`);

  if (success) {
    return data;
  }

  throw new Error(`Failed to fetch company: ${error}`);
};

export const updateCompany = async (companyId: number, params: EditCompanyPayload) => {
  const { success, data, error } = await api.put<Company>(`/companies/${companyId}`, {
    params: { company: params },
  });

  if (success) return data;

  throw new Error(`Failed to update company: ${error}`);
};

export const fetchEquityConnection = async (id: number) => {
  const { success, data, error } = await api.get<EquityConnection>(
    `/companies/${id}/equity_connection`,
  );

  if (success) {
    return data;
  }

  throw new Error(`Failed to fetch equity connection: ${error}`);
};
