import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { HRISConnectSuccessEventDetails } from 'js/analytics/types/hris';
import { OAuthInstallEventDetails } from 'js/analytics/types/oauth';
import useTrackEvent from 'js/analytics/useTrackEvent';
import api, { createQueryParams } from 'js/api/api';
import { getRipplingRedirectStorageKey } from 'js/components/hris/connect/ConnectRipplingButton';
import useAppSelector from 'js/hooks/useAppSelector';
import { currentUserSelector } from 'js/selectors/currentUser';
import { checkCurrentSession } from 'js/services/auth/actions';
import localStorageSafe from 'js/utils/local-storage-safe';

/**
 * This is the URL that Rippling redirects to after installing OpenComp
 * This is used by both the "Required Installation Flow" and the "Optional Installation Flow"
 * https://developer.rippling.com/docs/rippling-api/4c56660ae18e4-partner-requirements#2-build-the-integration-against-ripplings-sandbox-environment
 * The code is exchanged for auth tokens
 * In the Required flow, the user is redirected back to Rippling afterwards
 */
export const RipplingInstallCallback = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  // https://developer.rippling.com/docs/rippling-api/b5608aa747ff8-authorization#initial-redirect
  const code = params.get('code') as string;
  const redirectUri = params.get('redirect_uri') as string;

  const { trackEvent } = useTrackEvent<OAuthInstallEventDetails | HRISConnectSuccessEventDetails>();

  const currentUser = useAppSelector(currentUserSelector);

  useEffect(() => {
    const callRipplingTokenInstall = async () => {
      await api.execute({
        call: () =>
          api.post(`/rippling_install`, {
            params: { code },
          }),
        onSuccess: () => {
          // Rippling is both an SSO provider and an HRIS provider
          trackEvent('oauth.install', {
            identityProvider: 'rippling',
            source: 'callback',
          });
          trackEvent('hris.connect.success', { source: 'rippling' });
          // if there is a redirect URL stored in local storage, go there
          // otherwise, use the redirect URL given by Rippling via query params
          let calculatedRedirect = redirectUri;
          if (currentUser?.lastViewed?.companyId) {
            const { companyId } = currentUser.lastViewed;
            const redirectStorageKey = getRipplingRedirectStorageKey(companyId);
            const storedRedirect = localStorageSafe.getItem(redirectStorageKey);
            if (storedRedirect) {
              calculatedRedirect = storedRedirect;
              localStorageSafe.removeItem(redirectStorageKey);
            }
          }
          window.location.replace(calculatedRedirect);
        },
      });
    };

    const isBlank = (s: string | undefined | null) => s === undefined || s === null || s === '';
    const checkUserLoggedIn = async () => {
      const userLoggedIn = await checkCurrentSession();
      const search = createQueryParams({
        continue: '/rippling/install/',
        redirect_uri: redirectUri,
        code,
      });
      if (userLoggedIn) {
        if (isBlank(currentUser?.userSegment)) {
          navigate({ pathname: '/onboarding/user', search });
        } else if (!currentUser?.landingCompanyId) {
          navigate({ pathname: '/onboarding/company', search });
        } else {
          await callRipplingTokenInstall();
        }
      } else {
        navigate({ pathname: '/login', search });
      }
    };

    checkUserLoggedIn();
  }, [code, currentUser, redirectUri, navigate, trackEvent]);
};
