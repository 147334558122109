import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

import marketIllustration from 'img/illustrations/market.svg';
import CompanyResemble from 'js/components/onboarding/common/CompanyResemble';
import Button from 'js/design-system/Button/Button';
import { ButtonSize, ButtonVariant } from 'js/design-system/Button/types';
import useAppSelector from 'js/hooks/useAppSelector';
import useNumberParams from 'js/hooks/useNumberParams';
import queries from 'js/queries';
import { getById } from 'js/selectors/companies';
import { Segment, ScenarioType } from 'types';

import styles from './WelcomeModal.module.scss';

interface WelcomeModalProps {
  companyId?: number;
}

const WelcomeModal = ({ companyId }: WelcomeModalProps) => {
  const { scenarioId } = useNumberParams();
  const company = useAppSelector((state) => getById(state, companyId));
  const scenario = useAppSelector((state) => state.scenarios.byId[scenarioId]);
  const { data: segmentGroups = [] } = useQuery({
    ...queries.benchmarks.detail(scenario?.benchmarkId as number)._ctx.segmentGroups,
    select: (segmentGroups) =>
      segmentGroups.filter((segmentGroup) => segmentGroup.name === 'Total Funds Raised'),
  });
  const segmentShortName = segmentGroups[0]?.segments
    ?.find((segment: Segment) => segment.id === scenario.segments[0]?.id)
    ?.name.replace(' million', 'M');

  const [show, setShow] = useState(true);

  if (!company) return null;

  const demoScenario = scenario?.scenarioType === ScenarioType.Demo;

  return (
    <Modal
      show={show}
      centered
      className={styles.modal}
      dialogClassName={styles.modalDialog}
      contentClassName={styles.modalContent}
    >
      <Modal.Body>
        <div className={styles.board}>
          <CompanyResemble
            companyName={demoScenario ? 'Dunder Mifflin' : company.companyName}
            segmentShortName={segmentShortName}
            numberEmployees={company.numEmployees}
            className={styles.companyResemble}
            bodyClassName={styles.bodyCompany}
          >
            <img src={marketIllustration} alt="an illustration of the Market tab" />
          </CompanyResemble>
        </div>
        <div className={styles.welcomeBody}>
          <div className={styles.title}>Welcome</div>
          <div className={styles.message}>
            {demoScenario ? (
              <>
                We created <span className={styles.scenarioName}>{scenario.name}</span> for you to
                start exploring.
                <br />
                Connect your HRIS to see how your company compares to the market.
              </>
            ) : (
              <>
                OpenComp is the only reliable compensation data, benchmarks, and insights for modern
                employers.
              </>
            )}
          </div>
          <Button
            variant={ButtonVariant.Filled}
            size={ButtonSize.Medium}
            onClick={() => setShow(false)}
          >
            Start Exploring
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default WelcomeModal;
