import { ArrowLineDown } from '@phosphor-icons/react';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import IconButton from 'js/design-system/IconButton/IconButton';
import Menu, { MenuItem } from 'js/design-system/Menu/Menu';
import Tooltip from 'js/design-system/Tooltip/Tooltip';
import useAppSelector from 'js/hooks/useAppSelector';
import useFeatureAccess from 'js/hooks/useFeatureAccess';
import useNumberParams from 'js/hooks/useNumberParams';
import queries from 'js/queries';
import scenarioViewSelector from 'js/selectors/scenarioView';
import { getById } from 'js/selectors/scenarios';
import { filterEmployees } from 'js/services/employeeFilterService';
import { exportRange, exportRangeGroup, exportScenario } from 'js/utils/csv-exporter';
import {
  CompMode,
  DataExportType,
  Employee,
  ProductPermission,
  ScenarioViewMode,
  UpgradeEventSource,
} from 'types';

import styles from './ExportLink.module.scss';

const allExportOptions = {
  [DataExportType.Employees]: {
    label: 'Employees',
    value: DataExportType.Employees,
    limitFeature: undefined,
    limitTooltip: undefined,
  },
  [DataExportType.EmployeesWithRange]: {
    label: 'Employees',
    value: DataExportType.EmployeesWithRange,
    limitFeature: undefined,
    limitTooltip: undefined,
  },
  [DataExportType.RangeSets]: {
    label: 'Ranges',
    value: DataExportType.RangeSets,
    limitFeature: 'download_ranges' as ProductPermission,
    limitTooltip: 'Upgrade to download ranges',
  },
  [DataExportType.RangeGroup]: {
    label: 'Ranges',
    value: DataExportType.RangeGroup,
    limitFeature: 'download_ranges' as ProductPermission,
    limitTooltip: 'Upgrade to download ranges',
  },
};

interface ExportLinkProps {
  exportOptions: DataExportType[];
}

export const ExportLink = ({ exportOptions = [DataExportType.Employees] }: ExportLinkProps) => {
  const { scenarioId, rangeGroupId } = useNumberParams();
  const scenario = useAppSelector((state) => getById(state, scenarioId));
  const scenarioViewConfig = useSelector(scenarioViewSelector);
  const { compDisplayType, diversityGroupType, employeeSort, mode, rangeDisplayType } =
    scenarioViewConfig;
  const { data: allEmployees = [] } = useQuery(
    queries.scenarios.detail(scenarioId)._ctx.employees(),
  );
  const filteredEmployeeIds: number[] = filterEmployees(allEmployees, scenarioViewConfig).map(
    (e: Employee) => e.id,
  );

  const { useAccessReady, hasFeatureAccess, guardFeature } = useFeatureAccess();

  const [open, setOpen] = useState(false);

  const options = useMemo(
    () =>
      exportOptions.reduce((optionList: MenuItem<string>[], value: DataExportType) => {
        const data = allExportOptions[value];

        if (!data.limitFeature || hasFeatureAccess(data.limitFeature)) {
          return [
            ...optionList,
            {
              value: data.value,
              label: data.label,
            },
          ];
        }

        if (useAccessReady) {
          return [
            ...optionList,
            {
              value: String(data.value),
              label: String(data.value),
              displayLabel: (
                <Tooltip content={data.limitTooltip}>
                  <div className={styles.tooltipItem}>{data.label}</div>
                </Tooltip>
              ),
            },
          ];
        }

        return optionList;
      }, []),
    [useAccessReady, hasFeatureAccess, exportOptions],
  );

  const getAllSortOptions = (prioritySortFields: string[]) =>
    employeeSort ? [...prioritySortFields, employeeSort] : prioritySortFields;

  const handleDownload = async (exportType: string | React.MouseEvent<HTMLElement, MouseEvent>) => {
    // If all employees, don't bother passing in filtered employees
    // otherwise the URL can get too long and cause http error
    // Long term, the backend should be responsible for filtering employees
    const exportEmployeeIds =
      filteredEmployeeIds.length === allEmployees.length ? undefined : filteredEmployeeIds;
    switch (exportType) {
      case DataExportType.Employees: {
        let prioritySortFields = ['department_name'];
        if (mode === ScenarioViewMode.Diversity) {
          const diversitySortFields =
            diversityGroupType === 'both' ? ['ethnicity', 'gender'] : [diversityGroupType];
          prioritySortFields =
            compDisplayType === CompMode.Ranges
              ? diversitySortFields.concat(['range_group'])
              : diversitySortFields;
        }

        const allSortOptions = getAllSortOptions(prioritySortFields);

        await exportScenario(scenario, 'market', allSortOptions, exportEmployeeIds);

        break;
      }
      case DataExportType.EmployeesWithRange: {
        const prioritySortFields =
          rangeDisplayType === 'departments'
            ? ['department_name', 'range_group']
            : ['range_group', 'department_name'];

        const allSortOptions = getAllSortOptions(prioritySortFields);

        await exportScenario(scenario, 'ranges', allSortOptions, exportEmployeeIds);

        break;
      }
      case DataExportType.RangeSets: {
        await guardFeature(
          () => exportRange(scenario),
          allExportOptions[exportType].limitFeature as ProductPermission,
          UpgradeEventSource.DownloadRanges,
        );
        break;
      }
      case DataExportType.RangeGroup: {
        await guardFeature(
          () => exportRangeGroup(scenario, rangeGroupId),
          allExportOptions[exportType].limitFeature as ProductPermission,
          UpgradeEventSource.DownloadRanges,
        );
        break;
      }
      default: {
        await exportScenario(
          scenario,
          'market',
          ['department_name', employeeSort],
          exportEmployeeIds,
        );
      }
    }
  };

  if (options.length > 1) {
    return (
      <Menu
        trigger={
          <Tooltip content="Download">
            <IconButton
              icon={ArrowLineDown}
              size="medium"
              shape="square"
              className={classNames({ [styles.open]: open })}
            />
          </Tooltip>
        }
        menuItems={options}
        onSelect={handleDownload}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
      />
    );
  }

  return (
    <Tooltip content="Download">
      <IconButton icon={ArrowLineDown} size="medium" shape="square" onClick={handleDownload} />
    </Tooltip>
  );
};
