import classNames from 'classnames';

import logo from 'img/opencomp-logos/white-full.svg';
import useAppSelector from 'js/hooks/useAppSelector';
import useNumberParams from 'js/hooks/useNumberParams';
import { getById as companySelector } from 'js/selectors/companies';
import { getById as scenarioSelector } from 'js/selectors/scenarios';
import { PlayBook } from 'types';

import HelpButton from './HelpButton';
import { TotalRewardsMenu } from './TotalRewardsMenu';
import UserMenu from './UserMenu';

import styles from './NavBar.module.scss';

const TotalRewardsNavBar = () => {
  const { scenarioId } = useNumberParams();
  const scenario = useAppSelector((state) => scenarioSelector(state, scenarioId));
  const company = useAppSelector((state) => companySelector(state, scenario?.companyId));

  return (
    <div className={styles.container}>
      <div className={classNames(styles.navBar, styles.navBar__totalRewards)}>
        <div className={styles.navContainer}>
          <div className={styles.oneRow}>
            <img src={logo} alt="OpenComp logo" className={styles.logoWide} />
            <div className={styles.separator} />
            <TotalRewardsMenu scenarioId={scenarioId} company={company} />
          </div>
          <div className={styles.oneRow}>
            <HelpButton playbook={PlayBook.TotalRewardsHelp} />
            {company && <UserMenu company={company} avatarProps={{ variant: 'medium' }} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TotalRewardsNavBar;
