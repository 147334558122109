import { LightningSlash } from '@phosphor-icons/react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { useDismissCompanyNotification } from 'js/api/companyNotifications';
import Button from 'js/design-system/Button/Button';
import { ButtonSize, ButtonVariant } from 'js/design-system/Button/types';
import InformationalModal from 'js/design-system/InformationalModal';
import queries from 'js/queries';

const ReconnectHRISModal = ({ companyId }: { companyId: number }) => {
  const navigate = useNavigate();
  const { data: notifications = [] } = useQuery(
    queries.companies.detail(companyId)._ctx.notifications,
  );
  const notification = notifications.find((n) => n.name === 'sync_hris');

  const dismiss = useDismissCompanyNotification(companyId);
  if (!notification) {
    return null;
  }

  return (
    <InformationalModal
      title="Your HRIS has been disconnected"
      description="Your HRIS was recently disconnected. Please reconnect it to continue getting the most from your OpenComp experience."
      icon={LightningSlash}
      iconWeight="fill"
      primaryLabel={
        <Button
          variant={ButtonVariant.Filled}
          size={ButtonSize.Medium}
          onClick={() => {
            navigate(`/companies/${companyId}/hris`);
          }}
        >
          Reconnect my HRIS
        </Button>
      }
      secondaryLabel="Close"
      onSecondaryClick={() => dismiss.mutate(notification.id)}
      show
      onHide={() => dismiss.mutate(notification.id)}
    />
  );
};

export default ReconnectHRISModal;
