import { EquityType } from 'types';

export enum CompanyOnboardStatus {
  Started = 'started',
  EmployeesAdded = 'employees_added',
  Complete = 'complete',
}

export type Company = {
  id: number;
  baseScenarioId: number;
  billingEmail: string | null;
  paid: boolean;
  companyName: string;
  developerSettings: boolean;
  employeeStockOptionPool: number;
  equityType: EquityType;
  equityValue: number | null;
  fundingRound: string;
  fundsRaised: number;
  hasCashConnection: boolean;
  hasEquityConnection: boolean;
  headquarterLocationId: number | null;
  industrySectorId: number;
  isTest: boolean;
  isPrimary: boolean | null;
  autoJoin: boolean | null;
  identityProvider: string | null;
  landingScenarioId: number;
  location: string;
  numEmployees: number;
  numScenarios: number;
  numSeats: number | null;
  onboardStatus: CompanyOnboardStatus;
  partnerCode: string | null;
  productId: number;
  seatLimit: number | null;
  subscriptionId: number | null;
  totalSharesOutstanding: number;
  subscriptionCreatedAt: string | null;
  subscriptionExpirationDate: string | null;
  promotionId: number | null;
  productName: string;
  industrySectorName: string;
  showEquity: boolean;
  hasCustomFields?: boolean;
};

export interface DeleteCompanyResponse {
  landingCompanyId: number | null;
  landingScenarioId: number | null;
}

export type EditCompanyPayload = {
  companyName: string;
  location: string;
  industrySectorId: number | string;
  fundsRaised: number;
  billingEmail: string | null;
  showEquity: boolean;
};
