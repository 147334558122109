import { BannerVariant } from 'js/components-legacy/common/SyncBanner';
import {
  UserPermission,
  EmployeeUser,
  ConnectionType,
  Benefit,
  ScenarioSettings,
  OffersSettings,
} from 'types';

import { Segment } from './benchmark';

export enum CashDisplayType {
  TotalCash = 'total_cash',
  BaseSalary = 'base_salary',
  SalaryOte = 'salary_ote',
  None = 'none',
}

export enum EquityDisplayType {
  NumOfShares = 'num_of_shares',
  PercentOwnership = 'ownership_percentage',
  None = 'none',
}

export enum TitleCashDisplayType {
  TotalCash = 'Total Cash',
  BaseSalary = 'Base Salary',
  SalaryOte = 'Salary / OTE',
  None = 'Do not display',
}

export enum TitleEquityDisplayType {
  NumOfShares = 'Number of Shares',
  PercentOwnership = 'Percent Ownership',
  None = 'Do not display',
}

export enum EquityType {
  Options = 'options',
  Rsu = 'rsu',
  RsuOptions = 'rsu_options',
}

export enum ScenarioType {
  Base = 'base',
  Demo = 'demo',
  ModelScenario = 'model_scenario',
}

export enum RangePositionMetric {
  RangePosition = 'range_position',
  CompaRatio = 'compa_ratio',
}

export enum TitleRangePositionMetric {
  RangePosition = 'Range Position',
  CompaRatio = 'Compa Ratio',
}

export enum DiversityGroupType {
  Both = 'both',
  Ethnicity = 'ethnicity',
  Gender = 'gender',
}

export type PerformanceScaleStep = {
  position: number;
  label: string;
  _destroy?: boolean;
};

export type PerformanceScaleStepWithId = PerformanceScaleStep & {
  id: number;
};

export type Scenario = {
  id: number;
  name: string;
  benchmarkConfirmed?: boolean;
  benchmarkId: number;
  benchmarkLabel?: string;
  benefits: Benefit[];
  brandPrimaryColor?: string;
  cashDisplayType: CashDisplayType;
  companyId: number;
  connection: ConnectionType | null;
  createdAt: string;
  createdBy: Pick<UserPermission, 'id' | 'fullName' | 'email' | 'avatar' | 'userId'>;
  createdById: number;
  equityDisplayType: EquityDisplayType;
  equityType: EquityType;
  equityValue: number | null;
  expectedAdditionalDilutions: number[] | null;
  expectedValuations: number[] | null;
  hiddenEmployeesBit: number;
  geoAdjustmentTemplateId: number | null;
  hypotheticalPrice: number | null;
  imported: boolean;
  importedAt: string | null;
  initialVestingAmount: number | null;
  initialVestingPeriod: number | null;
  logo?: string | null;
  numActiveEmployees: number;
  numEmployees: number;
  optionPool: number;
  outstandingShares: number;
  parentId: number | null;
  preferredPrice: number | null;
  remainingVestingPeriod: string | null;
  scenarioType: ScenarioType;
  stage: string | null;
  strikePrice: number | null;
  totalVestingPeriod: number | null;
  updatedAt: string;
  offersSetting?: ScenarioSettings<OffersSettings> | null;
  inProgress: boolean;
  performanceScaleStepsAttributes?: PerformanceScaleStep[];
  defaultApprovalTemplateId: number | null;
  customBenchmark: boolean;
  segments: Segment[];
};

export interface ScenarioChannelReceive {
  scenario: Scenario;
  users: UserPermission[];
  employeesUsers: EmployeeUser[];
  message: 'list_geo_tiers' | 'list_employees';
}

export interface EquityChannelReceive {
  message: BannerVariant;
}

export interface ScenarioDuplicatorChannelReceive {
  scenario: Scenario;
  success: boolean;
}

export type ScenarioImportResponseError = {
  csvDataErrors?: string[];
  parsingError?: string;
};

export type ScenarioFormData = Scenario & {
  normalizedHiddenEmployeesBit: string[];
  importFile: File | null;
  importFileS3Path?: string;
  importFileFilename?: string;
  prePopulateScenarioId?: number;
  logo: File | string | null;
  offersSettingAttributes?: Scenario['offersSetting'];
  segmentIds: number[];
};
