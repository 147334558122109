import { ElementType } from 'react';

export enum RangeStrategySummaryVariant {
  Default = 'default',
  Textless = 'textless',
}

export enum StrategyType {
  Position = 'pay_range_position',
  Width = 'pay_range_width',
  Geo = 'scenario_geo_adjustment',
}

export type StrategyTemplate = {
  id: number | null;
  description: string;
  descriptionWithoutEquity?: string;
  icon: ElementType;
  name: string;
  nameWithoutEquity?: string;
  shortName: string;
  isCustom?: boolean;
  extraTitle?: string;
} & (
  | {
      strategyType: StrategyType.Position;
      presets: PositionPresets;
    }
  | { strategyType: StrategyType.Width; presets: WidthPresets }
  | { strategyType: StrategyType.Geo; presets: GeoPresets }
);

export type PositionStrategyTemplate = StrategyTemplate & { strategyType: StrategyType.Position };
export type WidthStrategyTemplate = StrategyTemplate & { strategyType: StrategyType.Width };
export type GeoStrategyTemplate = StrategyTemplate & { strategyType: StrategyType.Geo };

type PositionPresets = {
  cashTarget: number;
  equityTarget: number;
};

type WidthPresets = {
  cashWidth: number;
  equityWidth: number;
};

type GeoPresets = {
  all: string;
  except?: PremiumException[] | TechException[];
};

type PremiumException = {
  locationId: number;
  cashAdjustment: number;
  equityAdjustment: number;
  geoAdjustableType: string;
  benchmarkLocationId: number;
};

type TechException = {
  benchmarkLocationId: number;
  geoAdjustableType: string;
  geoAdjustableId: number;
};

export type StrategyCardCategoryRow = (string | number | React.ReactNode)[];
