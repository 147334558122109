import { ArrowsDownUp } from '@phosphor-icons/react';

import IconButton from 'js/design-system/IconButton/IconButton';
import Menu, { MenuProps } from 'js/design-system/Menu/Menu';
import Tooltip from 'js/design-system/Tooltip/Tooltip';
import { Color, keys } from 'types';

interface SortProps<T extends string>
  extends Pick<MenuProps<T>, 'onOpen' | 'onClose' | 'onSelect'> {
  defaultValue?: T;
  sortOptions: Partial<Record<T, string>>;
  showEquity?: boolean;
  iconClassName?: string;
  iconColor?: Color;
}

export const Sort = <T extends string>({
  defaultValue,
  iconClassName,
  sortOptions,
  onSelect,
  iconColor = 'primary-gray-600',
  ...rest
}: SortProps<T>) => {
  const options = keys(sortOptions).map((key) => ({
    value: key,
    label: sortOptions[key] as string,
    default: key === defaultValue,
  }));

  return (
    <Menu
      {...rest}
      trigger={
        <Tooltip content="Sort">
          <IconButton
            icon={ArrowsDownUp}
            size="medium"
            iconColor={iconColor}
            className={iconClassName}
            data-testid="sort-by-button"
          />
        </Tooltip>
      }
      menuItems={options}
      highlightSelectedItem
      onSelect={onSelect}
    />
  );
};
