import { ReactNode } from 'react';
import { Outlet } from 'react-router-dom';

import { FeatureFlags } from 'js/config/feature-flags';
import useAppSelector from 'js/hooks/useAppSelector';
import useFeatureAccess from 'js/hooks/useFeatureAccess';
import useFeatureFlag from 'js/hooks/useFeatureFlag';
import useNumberParams from 'js/hooks/useNumberParams';
import { getById as scenarioSelector } from 'js/selectors/scenarios';
import { FetchStatus, ProductPermission } from 'types';

interface ProtectedRouteProps {
  productPermission: ProductPermission;
  featureFlag: FeatureFlags;
  noAccessComponent?: ReactNode;
  operator?: 'or' | 'and';
}

const ProductPermissionFeatureFlagProtectedRoute = ({
  productPermission,
  featureFlag,
  noAccessComponent,
  operator = 'or',
}: ProtectedRouteProps) => {
  const { scenarioId, companyId } = useNumberParams();
  const scenario = useAppSelector((state) => scenarioSelector(state, scenarioId));

  const { hasFeatureAccess, useAccessReady } = useFeatureAccess();
  const hasAccess = hasFeatureAccess(productPermission);
  const { enabled: flagEnabled, status: featureFlagStatus } = useFeatureFlag(
    featureFlag,
    companyId || scenario?.companyId,
  );

  if (!useAccessReady || featureFlagStatus === FetchStatus.Loading) {
    return null;
  }

  const shouldHaveAccess = operator === 'and' ? hasAccess && flagEnabled : hasAccess || flagEnabled;

  return shouldHaveAccess ? <Outlet /> : <>{noAccessComponent}</>;
};

export default ProductPermissionFeatureFlagProtectedRoute;
