import { formatError } from 'js/components-legacy/common/AlertMessage';
import {
  ImportRangeGroupingParams,
  ImportRangeGroupingResponse,
  JobRoleWithLevels,
  PayRangeGroup,
} from 'types';

import api from './api';
import { ImportRangeError } from './ranges';

export const fetchRangeGroupJobRoles = async (rangeGroupId: number) => {
  if (!rangeGroupId) return [];

  const { success, data } = await api.get<JobRoleWithLevels[]>(
    `/pay_range_groups/${rangeGroupId}/job_roles`,
  );
  if (success) return data;
  throw new Error('Failed to fetch job roles');
};

export const createPayRangeGroup = async (payRangeId: number, jobFamilyId: number) => {
  const { success, data, error } = await api.post<PayRangeGroup>(
    `/pay_ranges/${payRangeId}/pay_range_groups`,
    {
      params: { jobFamilyId },
    },
  );

  if (success) return data;

  throw new Error(`Failed to create pay range group: ${formatError(error)}`);
};

export const createPayRangeGroupBatch = async (payRangeId: number, jobFamilyIds: number[]) => {
  const { success, data, error } = await api.post<PayRangeGroup[]>(
    `/pay_ranges/${payRangeId}/pay_range_groups/batch`,
    {
      params: { jobFamilyIds },
    },
  );

  if (success) return data;

  throw new Error(`Failed to create pay range groups: ${formatError(error)}`);
};

export const importRangeGrouping = async (
  payRangeId: number,
  params: ImportRangeGroupingParams,
) => {
  const { data, success, error } = await api.post<ImportRangeGroupingResponse>(
    `/pay_ranges/${payRangeId}/pay_range_groups/import`,
    {
      params,
    },
  );

  if (success) return data;

  throw new ImportRangeError('Failed to import range grouping.', error);
};
