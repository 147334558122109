import classNames from 'classnames';
import colors from 'css/_colors.scss';
import spacings from 'css/_spacings.scss';
import { forwardRef } from 'react';

import Text from 'js/design-system/Text/Text';

import { BadgeProps, PaddingSize } from './BadgeTypes';

import styles from './Badge.module.scss';

const Badge = forwardRef<HTMLDivElement, BadgeProps>(
  ({ children, size = 'medium', className, color, backgroundColor = 'primary-gray-800' }, ref) => {
    const paddingBySize: PaddingSize = {
      small: ['spacing-2', 'spacing-4'],
      medium: [0, 'spacing-4'],
      large: ['spacing-4', 'spacing-8'],
    };

    const paddingStyle = paddingBySize[size]
      .map((key) => (key === 0 ? key : spacings[key]))
      .join(' ');

    return (
      <div
        ref={ref}
        style={{ padding: paddingStyle, backgroundColor: colors[backgroundColor] }}
        className={classNames(styles.badgeWrapper, className)}
      >
        <Text color={color || 'neutral-full-white'} variant={`text-badge-${size}`}>
          {children}
        </Text>
      </div>
    );
  },
);

Badge.displayName = 'Badge';

export default Badge;
