import useAppSelector from 'js/hooks/useAppSelector';
import useNumberParams from 'js/hooks/useNumberParams';
import { getById as companySelector } from 'js/selectors/companies';
import { getById as scenarioSelector } from 'js/selectors/scenarios';

const useShowEquity = (overrideCompany?: { showEquity: boolean }) => {
  const { scenarioId, companyId } = useNumberParams();
  const scenario = useAppSelector((state) => scenarioSelector(state, scenarioId));
  const storedCompany = useAppSelector((state) =>
    companySelector(state, companyId || scenario?.companyId),
  );
  const company = overrideCompany || storedCompany;

  return company ? company.showEquity : true;
};

export default useShowEquity;
