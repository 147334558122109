export enum DropDownAlign {
  Start = 'start',
  End = 'end',
}

export type DropDownValue = number | string | null;

export type DropDownOption = {
  label: React.ReactNode | string;
  value: DropDownValue;
  disabled?: boolean;
};
