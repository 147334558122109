import classNames from 'classnames';

import Text from 'js/design-system/Text/Text';
import { Variant } from 'js/design-system/Text/types';
import buttonize from 'js/utils/buttonize';
import { Color } from 'types';

import styles from './TextButton.module.scss';

// Utility type to exclude React components from ReactNode
// The TextButton should only ever be allowed to take text, not nested components.
type ExcludeComponents<T> = T extends React.ReactNode
  ? T extends React.ComponentType<any>
    ? never
    : T
  : T;

export interface TextButtonProps {
  children: ExcludeComponents<React.ReactNode>;
  onClick?: (event: React.SyntheticEvent<Element, Event>) => void;
  variant?: Variant;
  color?: Color;
  className?: string;
}

const TextButton = ({
  children,
  onClick = () => {},
  variant = 'text-body-main',
  color = 'oc-primary',
  className,
}: TextButtonProps) => (
  <Text
    variant={variant}
    color={color}
    className={classNames(styles.textButton, className)}
    Element="span"
    {...buttonize(onClick)}
  >
    {children}
  </Text>
);

export default TextButton;
