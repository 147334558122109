import { CaretDown, CaretUp } from '@phosphor-icons/react';
import classNames from 'classnames';
import { KeyboardEvent, ReactNode, cloneElement, useEffect, useRef, useState } from 'react';

import ListNavigation from 'js/components-legacy/common/ListNavigation';
import { ButtonProps } from 'js/design-system/Button/types';
import { isButtonElement } from 'js/design-system/Menu/helpers';
import { useClickOutside } from 'js/hooks/useClickOutside';
import useDebounce from 'js/hooks/useDebounce';
import buttonize from 'js/utils/buttonize';
import { logError } from 'js/utils/logger';

import Icon from '../../Icon';
import { FieldProps } from '../ConnectedField';
import FieldContainer from '../FieldContainer';
import { SelectXPosition, SelectYPosition } from '../Select/Select';
import { TextField } from '../TextField';

import styles from '../Select/Select.module.scss';

export type Suggestion<T> = {
  value: T;
  label: string;
  displayLabel?: ReactNode;
  disabled?: boolean;
};

export interface SuggestProps<T> extends FieldProps<T> {
  suggestions: Suggestion<T>[];
  /** Use this function to generate new suggestions. */
  onType: (val: string) => void;
  positionX?: SelectXPosition;
  positionY?: Omit<SelectYPosition, 'auto'>;
  defaultValue?: string;
  actionButton?: React.ReactElement<ButtonProps>;
  /** If `true`, the field's value can only be updated by selecting a `Suggestion`.
   * Otherwise, whatever is entered into the input will be the "selected" value of the field.
   * */
  forceSelection?: boolean;
  /** Allows a loading indicator to be displayed instead of `No results` on initial load. */
  loading?: boolean;
  // props for TextField
  placeholder?: string;
  inputAppend?: ReactNode;
  inputPrepend?: ReactNode;
  helpText?: ReactNode;
  onToggle?: () => void;
}

/**
 * Suggest is similar to a filterable Select, with a distinct difference -
 * the user controls the input's `onChange` method (surfaced as `onType`) and uses it to generate new options, rather than filter existing ones.
 * This is particularly useful when generating async options, like for location names or job roles.
 */
export const Suggest = <T extends string | number>({
  suggestions,
  placeholder = 'Search',
  inputAppend,
  inputPrepend,
  helpText,
  positionX = 'left',
  positionY = 'bottom',
  defaultValue,
  actionButton,
  onType,
  onToggle,
  forceSelection,
  loading,
  ...connectedFieldProps
}: SuggestProps<T>) => {
  const ref = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [open, setOpen] = useState(false);
  const [searchTerm, , setSearchTerm] = useDebounce(defaultValue || '', 250);
  const [lastValidValue, setLastValidValue] = useState(defaultValue || '');

  useEffect(() => {
    onToggle?.();
  }, [onToggle, open, suggestions.length]);

  const onItemClick = (suggestion: Suggestion<T>) => {
    if (connectedFieldProps.onChange) {
      connectedFieldProps.onChange(suggestion.value);
    }
    setSearchTerm(suggestion.label);
    setLastValidValue(suggestion.label);
    setOpen(false);
  };

  const onClose = () => {
    if (open) {
      setOpen(false);

      if (forceSelection && searchTerm && searchTerm !== lastValidValue) {
        setSearchTerm(lastValidValue);
      }
    }
  };

  const onKeyDown = (e: KeyboardEvent) => {
    if (e.shiftKey && e.key === 'Tab') {
      onClose();
    }
  };

  const onActionButtonClick = (e: React.MouseEvent<HTMLElement>) => {
    setOpen(false);
    const upstreamClick = actionButton?.props.onClick;
    if (upstreamClick) upstreamClick(e);
  };

  useClickOutside(ref, onClose);

  // The input's `onChange` is debounced. When the debounce is complete, call upstream handlers.
  useEffect(() => {
    if (!forceSelection && connectedFieldProps.onChange) {
      connectedFieldProps.onChange(searchTerm as T);
    }
    onType(searchTerm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  useEffect(() => {
    if (defaultValue || defaultValue === '') {
      setSearchTerm(defaultValue);
      setLastValidValue(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  if (actionButton && !isButtonElement(actionButton)) {
    logError('`actionButton` must be a `Button` component');
    return null;
  }

  return (
    <FieldContainer
      name={connectedFieldProps.name}
      label={connectedFieldProps.label}
      subLabel={connectedFieldProps.subLabel}
      labelPosition={connectedFieldProps.labelPosition}
      required={connectedFieldProps.required}
      labelClassName={connectedFieldProps.labelClassName}
    >
      <div className={styles.selectContainer} ref={ref}>
        <TextField
          name={connectedFieldProps.name}
          placeholder={placeholder}
          value={searchTerm}
          inputPrepend={inputPrepend}
          inputAppend={
            inputAppend || (
              <Icon size={16} color="primary-gray-600" icon={open ? CaretUp : CaretDown} />
            )
          }
          className={classNames(styles.suggest, connectedFieldProps.wrapperClassName)}
          readonly={false}
          onChange={(val) => setSearchTerm(String(val))}
          onKeyDown={onKeyDown}
          onFocus={() => setOpen(true)}
          validation={connectedFieldProps.validation}
          disabled={connectedFieldProps.disabled}
          helpText={helpText}
          ref={inputRef}
        />
        <ListNavigation items={suggestions} onClose={onClose}>
          {(itemsContainerRef: React.RefObject<HTMLDivElement>) => (
            <div
              style={positionY === 'bottom' ? { top: inputRef.current?.offsetHeight } : {}}
              className={classNames(styles.menu, styles.suggestMenu, {
                [styles.open]: open,
                [styles.top]: positionY === 'top',
                [styles.right]: positionX === 'right',
              })}
            >
              <div className={styles.itemsContainer} ref={itemsContainerRef}>
                {suggestions.length === 0 ? (
                  <div className={classNames(styles.option, styles.disabled)}>
                    {loading ? 'Loading' : 'No results'}
                  </div>
                ) : (
                  suggestions.map((suggestion) => (
                    <div
                      key={suggestion.value}
                      className={classNames(styles.option, {
                        [styles.disabled]: suggestion.disabled,
                      })}
                      {...buttonize(() =>
                        suggestion.disabled ? undefined : onItemClick(suggestion),
                      )}
                    >
                      {suggestion.displayLabel || suggestion.label}
                    </div>
                  ))
                )}
              </div>

              {actionButton && (
                <>
                  <div className={styles.divider} />
                  <div className={styles.actionButton}>
                    {cloneElement(actionButton, {
                      onClick: onActionButtonClick,
                    })}
                  </div>
                </>
              )}
            </div>
          )}
        </ListNavigation>
      </div>
    </FieldContainer>
  );
};
