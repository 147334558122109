import { snakeCase } from 'change-case';
import { saveAs } from 'file-saver';

import { trackEvent } from 'js/analytics';
import { getEventSourceValueByCurrentRoute } from 'js/analytics/utils';
import { fetchPercentiles, getLastUploadResults } from 'js/api/analyst/benchmarkPercentiles';
import { fetchJobRoles } from 'js/api/analyst/jobRoles';
import { fetchJobTypes } from 'js/api/analyst/jobTypes';
import { fetchJobAreas } from 'js/api/job-areas';

import api from '../api/api';

const getJobArchitectureData = (jobRoles, jobAreas, jobFamilies, jobTypes) =>
  convertToCSV(
    jobRoles.map((jobRole) => {
      const jobArea = jobAreas.find(
        (ja) => ja.id === jobFamilies.find((jf) => jf.id === jobRole.jobFamilyId).jobAreaId,
      );
      return {
        JobRoleId: jobRole.id,
        JobRole: jobRole.name,
        JobType: jobTypes.find((jt) => jt.id === jobRole.jobTypeId).name,
        JobFamily: jobFamilies.find((jf) => jf.id === jobRole.jobFamilyId).name,
        JobArea: jobArea?.name,
        CommssionBased: jobRole.commissionBased,
        Description: jobRole.description,
        JobRoleCode: jobRole.code,
      };
    }),
  );

const getMarketProxyData = async (benchmarkPercentiles) => {
  const mappedData = benchmarkPercentiles.map((percentileRow) => {
    const proxyDataRow = {};

    proxyDataRow['Job Role Id'] = percentileRow.jobRoleId;
    proxyDataRow.Level = percentileRow.jobLevel;
    proxyDataRow['Location Id'] = percentileRow.locationId;

    if (percentileRow.segmentGroup1) {
      proxyDataRow['Segment Group 1'] = percentileRow.segmentGroup1;
    }

    if (percentileRow.segmentGroup2) {
      proxyDataRow['Segment Group 2'] = percentileRow.segmentGroup2;
    }

    Object.entries(percentileRow.baseSalaries).forEach(([percentile, value]) => {
      proxyDataRow[`Base ${percentile}th`] = value;
    });

    Object.entries(percentileRow.totalCash).forEach(([percentile, value]) => {
      proxyDataRow[`TTC ${percentile}th`] = value;
    });

    Object.entries(percentileRow.ownershipPercents).forEach(([percentile, value]) => {
      proxyDataRow[`Equity % ${percentile}th`] = value;
    });

    return proxyDataRow;
  });

  return mappedData && mappedData.length > 0 ? convertToCSV(mappedData) : '';
};

const saveFileCSV = async (exportData, fileName) => {
  const blob = new Blob([exportData], { type: 'text/plain;charset=utf-8' });
  saveAs(blob, fileName);
};

export const convertToCSV = async (data) => {
  const module = await import('json2csv');

  const json2csvParser = new module.Parser();
  return json2csvParser.parse(data);
};

const exportScenario = async (scenario, context, sort, employeeIds) => {
  const source = getEventSourceValueByCurrentRoute();

  const { data: exportData } = await api.get(`/scenarios/${scenario.id}/employees.csv`, {
    urlParams: {
      context,
      filters: employeeIds && employeeIds.length ? { id: employeeIds } : undefined,
      sort: sort.map((key) => snakeCase(key)),
      source,
    },
  });

  trackEvent('scenario.export', { source, scenarioId: scenario.id, companyId: scenario.companyId });

  await saveFileCSV(exportData, `${scenario.id}-${scenario.name}.csv`);
};

// export from ranges homepage
const exportRange = async (scenario, includeGeoTier = true) => {
  const { data: exportData } = await api.get(
    `/scenarios/${scenario.id}/current_pay_range.csv?include_geo_tier=${includeGeoTier}`,
  );
  await saveFileCSV(exportData, `${scenario.id}-${scenario.name}-ranges.csv`);
};

// export from range group page
const exportRangeGroup = async (scenario, rangeGroupId) => {
  const { data: exportData } = await api.get(
    `/scenarios/${scenario.id}/current_pay_range.csv?pay_range_band_filters[pay_range_group_id]=${rangeGroupId}`,
  );
  await saveFileCSV(exportData, `${scenario.id}-${scenario.name}-ranges.csv`);
};

const exportJobArchitecture = async (getExportData = getJobArchitectureData) => {
  const responses = await Promise.all([
    fetchJobRoles(),
    fetchJobTypes(),
    fetchJobAreas(),
    api.get('/job_families'),
  ]);

  const [jobRoles, jobTypes, jobAreas, { data: jobFamilies }] = responses;

  const exportData = await getExportData(jobRoles, jobAreas, jobFamilies, jobTypes);
  await saveFileCSV(exportData, 'job-architecture.csv');
};

const exportMarketData = async (benchmark, getExportData = getMarketProxyData) => {
  const benchmarkPercentiles = await fetchPercentiles(benchmark.id, true);
  const exportData = await getExportData(benchmarkPercentiles);
  await saveFileCSV(
    exportData,
    `custom-market-proxy-${benchmark.name}.csv`,
    'This export contains no data',
  );
};

const exportMarketDataValidation = async () => {
  const benchmarkPercentiles = await getLastUploadResults();
  await saveFileCSV(
    benchmarkPercentiles,
    `validated-market-proxy.csv`,
    'The benchmark validations are empty or have expired. Please try again.',
  );
};

// Export Compensation Cycle
const exportCompCycle = async (compCycle) => {
  const { data: exportData } = await api.get(`/comp_cycles/${compCycle.id}.csv`);
  await saveFileCSV(exportData, `${compCycle.id}-${compCycle.name}-compensation-cycle.csv`);
};

const exportRangeGroupingTemplate = async (scenarioId) => {
  const { data: exportData } = await api.get(`/scenarios/${scenarioId}/range_mapping_template.csv`);

  await saveFileCSV(exportData, `${scenarioId}-range-mapping-template.csv`);
};

export {
  exportScenario,
  exportRange,
  exportRangeGroup,
  exportJobArchitecture,
  exportMarketData,
  exportMarketDataValidation,
  exportCompCycle,
  exportRangeGroupingTemplate,
};
