export default {
  click: {
    button: 'HRIS Button Click',
  },
  connect: {
    open: 'HRIS Connect Open',
    success: 'HRIS Connect Success',
    invite: 'HRIS Connect Invite',
  },
  disconnect: 'HRIS Disconnect',
};
