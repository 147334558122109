import { useCallback } from 'react';

import ACTIONS from 'js/constants/actions';
import useAppDispatch from 'js/hooks/useAppDispatch';
import { WebsocketsConsumer } from 'js/websockets';
import { CompProviderCred } from 'types';

interface CompProviderCredChannelProps {
  companyId: number;
}

export const CompProviderCredChannel = ({ companyId }: CompProviderCredChannelProps) => {
  const dispatch = useAppDispatch();

  const onReceiveCashConnection = useCallback(
    ({ cred }: { cred: CompProviderCred }) => {
      const data = typeof cred === 'string' ? JSON.parse(cred) : cred;
      dispatch({ type: ACTIONS.RECEIVE_CASH_CONNECTION, data, id: companyId });
    },
    [companyId, dispatch],
  );

  return (
    <WebsocketsConsumer
      channel="CompProviderCredChannel"
      id={companyId}
      onReceived={onReceiveCashConnection}
    />
  );
};
