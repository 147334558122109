/* eslint-disable @typescript-eslint/no-explicit-any */
interface MessageResponseProps {
  defaultError: string;
  errors: { [key: string]: string };
  success?: string;
  confirm?: { [key: string]: string };
  info?: { [key: string]: string };
}

interface Messages {
  signUp: MessageResponseProps;
  signIn: MessageResponseProps;
  password: MessageResponseProps;
  verification: MessageResponseProps;
}

export const messages: Messages = {
  signUp: {
    defaultError: 'Error signing up.',
    success: 'Thanks for creating an account. Welcome to OpenComp!',
    errors: {
      UsernameExistsException: 'Email is invalid or already taken.',
    },
    confirm: {
      info: 'Thanks! Please check your email for a security code.',
      error: 'Unable to sign up with the security code provided.',
      resend: 'Error sending security code.',
    },
  },
  signIn: {
    defaultError: 'Error logging in.',
    errors: {
      NotAuthorizedException: 'Incorrect username or password.',
      UserNotFoundException: 'An account with the given email does not exist.',
      UserNotConfirmedException: 'Cannot log in. Email provided has not yet been verified.',
    },
  },
  password: {
    defaultError: 'Unable to reset password.',
    info: {
      reset:
        "In support of OpenComp's security measures, we periodically require you to change your password. A security code has been sent to your email to initiate this change. If you need help, please contact support@opencomp.com.",
      forget: 'Please check your email for a security code to reset your password.',
    },
    errors: {
      UserNotFoundException: 'An account with the given email does not exist.',
      InvalidParameterException: 'Cannot reset password. Email not found.',
      ExpiredCodeException: 'Invalid security code provided. Please request a new security code.',
      LimitExceededException: 'Attempt limit exceeded.',
      notVerified: 'Cannot reset password. Email provided has not been verified.',
      notFound: 'Cannot reset password. Email not found.',
    },
    confirm: {
      error: 'Unable to reset password.',
      success: 'Thanks, your password has been changed.',
    },
  },
  verification: {
    defaultError: 'Unable to verify.',
    info: {
      verificationCodeResend: 'Please check your email for the verification code.',
    },
    errors: {
      InvalidParameterException: 'Invalid email provided.',
      UserNotFoundException: 'An account with the given email does not exist.',
      LimitExceededException: 'Attempt limit exceeded.',
      CodeMismatchException: 'Invalid security code provided.',
      ExpiredCodeException: 'Invalid security code provided.',
      NotAuthorizedException: 'Incorrect username or password.',
      AuthError: 'A valid email is required.',
    },
  },
};

export const getErrorMessage = (error: any, action?: keyof Messages) => {
  if (error instanceof Error && action) {
    return messages[action].errors[error.name] || messages[action].defaultError;
  }
  return 'Something went wrong. Please try again.';
};
