import { useEffect } from 'react';

const ChatScript = () => {
  useEffect(() => {
    if (
      process.env.REACT_APP_HUBSPOT_ENABLED !== 'true' ||
      document.getElementById('hs-script-loader') !== null
    ) {
      return;
    }

    // Append script to the body
    window.hsConversationsSettings = { loadImmediately: false, enableWidgetCookieBanner: false };
    const hscs = document.createElement('script');
    hscs.id = 'hs-script-loader';
    hscs.defer = true;
    hscs.src = '//js.hs-scripts.com/9483132.js';

    document.body.appendChild(hscs);
  }, []);

  return null;
};

export default ChatScript;
