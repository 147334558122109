import { createQueryKeys } from '@lukemorales/query-key-factory';

import { fetchComments } from 'js/api/comments';
import { ActivityModules } from 'js/components/activity-log';

const offers = createQueryKeys('offers', {
  detail: (id: number) => ({
    queryKey: [id],
    contextQueries: {
      comments: {
        queryKey: null,
        queryFn: () => fetchComments(ActivityModules.Offers, id),
      },
    },
  }),
});

export default offers;
