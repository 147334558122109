import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { getDepartmentHash } from 'js/components/benchmarking/table/utils';
import ColorPill from 'js/design-system/ColorPill/ColorPill';
import Tooltip from 'js/design-system/Tooltip/Tooltip';
import useAccess from 'js/hooks/useAccess';
import useAppSelector from 'js/hooks/useAppSelector';
import useNumberParams from 'js/hooks/useNumberParams';
import { getById as companySelector } from 'js/selectors/companies';
import { getById as scenarioSelector } from 'js/selectors/scenarios';
import { calculatePercentComplete, getCounts } from 'js/services/benchmarkService';
import { pluralize } from 'js/utils/string-format';
import { Employee } from 'types';

import styles from './CompletionTag.module.scss';

interface CompletionTagProps {
  employees: Employee[];
  departmentId?: number;
}

const CompletionTag = ({ employees, departmentId }: CompletionTagProps) => {
  const { scenarioId } = useNumberParams();
  const scenario = useAppSelector((state) => scenarioSelector(state, scenarioId));
  const company = useAppSelector((state) => companySelector(state, scenario?.companyId));
  const { persistentBenchmarkingAccessible } = useAccess({ company });

  const percentComplete = calculatePercentComplete(employees);

  if (percentComplete === 100) return null;

  const counts = getCounts(employees);

  return (
    <Tooltip
      content={`${counts.verified} of these employees (${percentComplete}%) ${pluralize(
        'has',
        'have',
        counts.verified,
      )} been successfully benchmarked. Click to benchmark ${
        counts.verified === 0 ? 'them' : 'the rest'
      }`}
    >
      <Link
        className={classNames(styles.completionTag, {
          [styles.disabled]: !persistentBenchmarkingAccessible,
        })}
        to={`/scenarios/${scenarioId}/benchmarking${
          departmentId ? getDepartmentHash(departmentId) : ''
        }`}
      >
        <ColorPill pillColor="blue" text={`${percentComplete}%`} data-testid="completion-tag" />
      </Link>
    </Tooltip>
  );
};

export default CompletionTag;
