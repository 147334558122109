import { isEmpty } from 'js/utils/value';
import { EmailValidationError } from 'types';

const required = (error: string | null, value: unknown) => (isEmpty(value) ? error : undefined);

const PERSONAL_DOMAINS = [
  'gmail.com',
  'yahoo.com',
  'aol.com',
  'hotmail.com',
  'live.com',
  'att.net',
  'earthlink.com',
  'me.com',
  'aim.com',
];

const isValidEmail = (value: string | null) => {
  const regexEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return !!value && regexEmail.test(value);
};

const isWorkEmail = (value: string | null) => {
  const domain = value?.split('@')[1];
  return !(domain && PERSONAL_DOMAINS.includes(domain)) && isValidEmail(value);
};

const isCaseInsensitiveEqual = (value: string | undefined, match: string) => {
  if (!value) return 'Please enter a value';
  return value.toLowerCase() !== match.toLowerCase() ? 'Values do not match' : undefined;
};

const emailValidation = (value: string | null, required = true) => {
  if (!value) {
    return required ? 'Please enter your email' : undefined;
  }

  if (!isValidEmail(value)) {
    return EmailValidationError.Invalid;
  }

  return undefined;
};

const workEmailValidation = (value: string | null, required = true) => {
  if (!value) {
    return required ? EmailValidationError.Empty : undefined;
  }
  if (!isValidEmail(value)) {
    return EmailValidationError.Invalid;
  }
  if (!isWorkEmail(value)) {
    return EmailValidationError.NotWorkEmail;
  }
  return undefined;
};

const passwordValidation = (value: string | null) => {
  if (!value) {
    return 'Please enter your password.';
  }
  const regexPass =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[=+^$*.[\]{}()?\-"!@#%&/\\,><':;|_~`])\S{8,64}$/;
  return regexPass.test(value)
    ? undefined
    : 'Must contain at least 8 characters, one number, one uppercase, one lowercase and one special case character.';
};

const jobTitleValidation = (
  value: string | null,
  errorMessage = 'Please provide your job title.',
  errorMessageTooShort = 'Please provide a minimum of three letters describing your job title.',
) => {
  if (!value) {
    return errorMessage;
  }
  if (value.length < 3) {
    return errorMessageTooShort;
  }
  return undefined;
};

const hexCodeValidation = (val: string, errorMessage = 'Not a valid hex code') =>
  /^([0-9A-Fa-f]{6})$/i.test(val) ? undefined : errorMessage;

export {
  isWorkEmail,
  isValidEmail,
  isCaseInsensitiveEqual,
  required,
  emailValidation,
  passwordValidation,
  workEmailValidation,
  jobTitleValidation,
  hexCodeValidation,
};
