const countNestedObjWithProp = (object, checkProperty, nestedKey) => {
  let count = 0;
  Object.keys(object || {}).forEach((key) => {
    if (object[key][nestedKey]) {
      count += countNestedObjWithProp(object[key][nestedKey], checkProperty, nestedKey);
    } else if (checkProperty(object[key])) {
      count += 1;
    }
  });
  return count;
};

export default countNestedObjWithProp;
