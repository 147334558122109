import { useQuery } from '@tanstack/react-query';

import queries from 'js/queries';
import { isValidJobRoleLevel } from 'js/services/levelService';
import { JobRoleWithLevels, NullJobRole } from 'types';

export const useJobLevels = (
  scenarioId: number,
  jobRole?: JobRoleWithLevels | NullJobRole | null,
) => {
  return useQuery({
    ...queries.scenarios.detail(scenarioId)._ctx.jobLevels({ jobTypeId: jobRole?.jobType?.id }),
    select: (jobLevels) =>
      jobRole ? jobLevels.filter((jobLevel) => isValidJobRoleLevel(jobLevel, jobRole)) : jobLevels,
  });
};

export const useJobTypeLevels = (scenarioId: number) => {
  return useQuery(queries.scenarios.detail(scenarioId)._ctx.jobTypeLevels);
};
