import ACTIONS from '../constants/actions';

export const setActivityLogDataStale = () => ({
  type: ACTIONS.ACTIVITY_LOG_STALE_DATA,
  data: { stale: true },
});

export const setActivityLogDataUpdated = () => ({
  type: ACTIONS.ACTIVITY_LOG_STALE_DATA,
  data: { stale: false },
});
