export default {
  view: {
    plans: 'Upgrade Plans View',
  },
  select: {
    seatBucket: 'Upgrade Seat Bucket Select',
  },
  click: {
    addSeats: 'Upgrade Add Seats Click',
    contactUs: 'Upgrade Contact Us Click',
    purchase: 'Upgrade Purchase Click',
    link: 'Upgrade Link Click',
  },
  cancel: {
    purchase: 'Upgrade Purchase Cancel',
  },
  complete: {
    purchase: 'Upgrade Purchase Complete',
  },
};
