import { Scenario } from './scenario';

export enum SettingsType {
  TotalRewards = 'total_rewards',
  Offers = 'offers',
}

export type OffersSettings = {
  hideSimilarCandidateNames?: boolean;
  preferredPrice?: boolean;
  strikePrice?: boolean;
  showExpectedValuations?: boolean;
  showExpectedAdditionalDilutions?: boolean;
  showTargetAnnualBonus?: boolean;
  vestingSchedule?: boolean;
  paySalaryMin?: number;
  paySalaryMax?: number;
  payEquityMin?: number;
  payEquityMax?: number;
  showLogo?: boolean;
  showEstimatedStartDate?: boolean;
  showStrategyParagraph?: boolean;
  welcomeMessage?: string | null;
  equityParagraph?: string | null;
  strategyParagraph?: string | null;
  showEquityValue?: boolean;
  showValuation?: boolean;
  showTotalFundsRaised?: boolean;
  showHeadquarterLocation?: boolean;
  showNumberOfEmployees?: boolean;
  showCurrentFundingStage?: boolean;
  showBenefits?: boolean;
  skipApprovals?: boolean;
};

export type TotalRewardsSettings = {
  welcomeMessage?: string | null;
  showWelcomeMessage?: boolean;
  showLogo?: boolean;
  showSalaryRange?: boolean;
  showBenchmarking?: boolean;
  showBenefits?: boolean;
  showHeadquarterLocation?: boolean;
  showNumberOfEmployees?: boolean;
  showTotalFundsRaised?: boolean;
  showCurrentFundingStage?: boolean;
  showTargetAnnualBonus?: boolean;
  showTargetAnnualCommission?: boolean;
  showEquityValue?: boolean;
  showExpectedValuations?: boolean;
  showValuation?: boolean;
  preferredPrice?: boolean;
  expectedValuations?: number[];
  equityParagraph?: string | null;
};

export type EquityDetailsSettings = Pick<
  Scenario,
  | 'outstandingShares'
  | 'optionPool'
  | 'equityType'
  | 'strikePrice'
  | 'preferredPrice'
  | 'totalVestingPeriod'
  | 'initialVestingAmount'
  | 'initialVestingPeriod'
  | 'remainingVestingPeriod'
>;

export type ScenarioSettingsByType = OffersSettings | TotalRewardsSettings;

export type ScenarioSettings<TSettings extends ScenarioSettingsByType> = {
  id: number;
  settings: TSettings;
  scenarioId: number;
  settingsType: SettingsType;
};
