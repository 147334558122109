import { FormApi } from 'final-form';
import { useEffect, useState } from 'react';
import { Form } from 'react-final-form';

import { invite } from 'js/actions/scenarios';
import { disableSpinner, enableSpinner } from 'js/actions/spinners';
import { workEmailValidation } from 'js/components-legacy/form/validations';
import BulkUserInviteCTA from 'js/components/bulk-user-invite/BulkUserInviteCTA';
import { ButtonType } from 'js/design-system/Button/types';
import useAccess from 'js/hooks/useAccess';
import useAppDispatch from 'js/hooks/useAppDispatch';
import useAppSelector from 'js/hooks/useAppSelector';
import { getById as companySelector } from 'js/selectors/companies';
import {
  Employee,
  Scenario,
  InviteStatus,
  RevokeStatus,
  UserPermission,
  UserRole,
  InviteScenarioUserFormData,
} from 'types';

import {
  ActionableModal,
  ActionableModalProps,
} from '../common/modal/ActionableModal/ActionableModal';
import AddUserFields from './add-user/AddUserFields';
import UserToggleList from './user-list/UserToggleList';

import styles from './ShareModal.module.scss';

export interface ShareModalProps extends Pick<ActionableModalProps, 'open' | 'onHide'> {
  scenario: Scenario;
  companyId: number;
  onInviteUser?: (user: UserPermission) => void;
  expandUsers?: boolean;
  disabledRoles?: UserRole[];
  defaultEmployee?: Employee;
}

const ShareModal = ({
  scenario,
  companyId,
  onInviteUser,
  expandUsers = true,
  disabledRoles,
  defaultEmployee,
  // ActionableModal props
  open,
  onHide,
}: ShareModalProps) => {
  const dispatch = useAppDispatch();
  const inviteStatus = useAppSelector((state) => state.companyUsers.inviteStatus);
  const revokeStatus = useAppSelector((state) => state.scenarioUsers.revokeStatus);
  const company = useAppSelector((state) => companySelector(state, companyId));
  const { shareScenarioAccessible } = useAccess({ company }); // TODO - the trigger should be hidden instead
  const [isExpanded, setIsExpanded] = useState(expandUsers);

  useEffect(() => {
    if (inviteStatus === InviteStatus.Succeeded || revokeStatus === RevokeStatus.Succeeded) {
      if (onHide) onHide();
    }
  }, [inviteStatus, onHide, revokeStatus]);

  if (!company) return null;

  const validateForm = (form: FormApi<InviteScenarioUserFormData>) => {
    const { role, employeeSearch, email } = form.getState().values;
    return (
      (role === UserRole.TeamLeader && employeeSearch === 'non-employee') ||
      workEmailValidation(email) !== undefined
    );
  };

  const handleSubmit = async (values: InviteScenarioUserFormData) => {
    dispatch(enableSpinner());
    await dispatch(
      invite(
        {
          email: values.email,
          role: values.role,
          scenarioId: scenario.id,
          ...(values.employeesUser?.employeeId && { employeeId: values.employeesUser?.employeeId }),
        },
        onInviteUser,
      ),
    );
    dispatch(disableSpinner());
  };

  const initialValues: Partial<InviteScenarioUserFormData> = {
    role: UserRole.OrganizationLeader,
    disabledRoles,
  };

  if (defaultEmployee) initialValues.employeeSearch = `${defaultEmployee.id}`;

  return (
    <Form onSubmit={handleSubmit} initialValues={initialValues}>
      {({ form, handleSubmit }) => {
        const { invalid, pristine, submitting } = form.getState();
        const disabled = submitting || pristine || invalid || validateForm(form);

        return (
          <ActionableModal
            data-testid="share-modal"
            open={open}
            onHide={onHide}
            title="Share scenario"
            description={
              <div>
                Invite an existing user to this scenario, or invite a new user to OpenComp.{' '}
                <BulkUserInviteCTA enableScenarioSelect />
              </div>
            }
            confirmBtnProps={{
              label: 'Invite',
              type: ButtonType.Submit,
              onClick: handleSubmit,
              disabled,
            }}
            contentClassName={isExpanded ? '' : styles.noScroll}
          >
            <AddUserFields scenario={scenario} company={company} />
            <UserToggleList
              scenario={scenario}
              company={company}
              expandUsers={expandUsers || !shareScenarioAccessible}
              onExpanded={setIsExpanded}
            />
          </ActionableModal>
        );
      }}
    </Form>
  );
};

export default ShareModal;
