import { useState } from 'react';

import { GeneralSearchPayRangesEventDetails } from 'js/analytics/types/general';
import useTrackEvent from 'js/analytics/useTrackEvent';
import { Form } from 'js/components-legacy/form';
import Button from 'js/design-system/Button/Button';
import { ButtonSize, ButtonVariant } from 'js/design-system/Button/types';
import Text from 'js/design-system/Text/Text';
import { logInfo } from 'js/utils/logger';
import { JobRoleWithLevels, Scenario } from 'types';

import { DataLookupSource } from '../../DataLookup';
import { fetchPayRangeJobPost, fetchRecentQuery } from '../api';
import { GeoTiers } from '../common/GeoTiers';
import { JobRole } from '../common/JobRole';
import { RecentQueriesMenu } from '../common/RecentQueriesMenu';
import { RangeLookupData } from '../types';

import styles from 'js/components/data-lookup/common/Forms.module.scss';

interface RangesLookupFormProps {
  scenario: Scenario;
  payRangeId: number | undefined;
  onSubmit: (data: RangeLookupData) => void;
  setSubmitting: (val: boolean) => void;
}

type FormData = {
  jobRoleId: number;
  jobRole: JobRoleWithLevels;
  tierIds: number;
};

export const RangesLookupForm = ({
  scenario,
  payRangeId,
  onSubmit,
  setSubmitting,
}: RangesLookupFormProps) => {
  const SOURCE = DataLookupSource.ViewPayRanges;
  const [initialValues, setInitialValues] = useState<Partial<FormData>>();
  const { trackEvent } = useTrackEvent<GeneralSearchPayRangesEventDetails>();

  const handleSubmit = async ({ jobRoleId, tierIds }: { jobRoleId: number; tierIds: number }) => {
    setSubmitting(true);
    const data = await fetchPayRangeJobPost({
      scenarioId: scenario.id,
      source: SOURCE,
      jobRoleId,
      tierIds: [tierIds],
    });
    if (data) {
      trackEvent('general.search.payRanges');
      onSubmit({ ...data, jobRoleId });
    }
  };

  const handleSelect = async (queryId: number) => {
    setSubmitting(true);
    const data = await fetchRecentQuery(scenario.id, queryId);
    setInitialValues({
      jobRoleId: data.jobRole.id,
      jobRole: data.jobRole,
      tierIds: data.selectedTiers.map((t) => t.id)[0],
    });

    onSubmit({ ...data, jobRoleId: data.jobRole.id });
  };

  if (!scenario) {
    logInfo('Fetching data...');
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.formHeader}>
        <div className={styles.headerText}>
          <Text variant="text-body-main-bold" color="platform-gray-900">
            View Pay Ranges
          </Text>
          <Text color="platform-gray-700">
            Use the following fields to view a role&apos;s ranges across all levels for a selected
            geographic tier.
          </Text>
        </div>
        <div>
          <RecentQueriesMenu source={SOURCE} scenarioId={scenario.id} onSelect={handleSelect} />
        </div>
      </div>
      <hr className={styles.divider} />
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        renderActions={(disabled, form) => {
          const { values, valid } = form.getState();
          return (
            <Button
              type="submit"
              variant={ButtonVariant.Filled}
              size={ButtonSize.Medium}
              disabled={disabled || !valid || !values.tierIds}
            >
              Search
            </Button>
          );
        }}
        contentClassName={styles.formContent}
        actionsClassName={styles.formActions}
      >
        <JobRole scenario={scenario} payRangeId={payRangeId} />
        <GeoTiers scenarioId={scenario.id} companyId={scenario.companyId} />
      </Form>
    </div>
  );
};
