import { useEffect, useRef } from 'react';

import Text from 'js/design-system/Text/Text';

import FieldContainer from '../FieldContainer';

import styles from './Checkbox.module.scss';

export interface CheckboxProps {
  value: string;
  label?: string;
  /** Allows the `checked` state to be controlled from a parent component. */
  checked?: boolean;
  /** Allows the `indeterminate` state to be controlled from a parent component. */
  indeterminate?: boolean;
  disabled?: boolean;
  required?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  className?: string;
}

export const Checkbox = ({
  value,
  label,
  checked,
  disabled,
  indeterminate,
  required,
  onChange,
  className,
}: CheckboxProps) => {
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.indeterminate = !!indeterminate;
    }
  }, [indeterminate]);

  return (
    <FieldContainer
      name={value}
      label={<Text variant="text-misc-input">{label}</Text>}
      labelPosition="right"
      required={required}
      className={className}
    >
      <input
        type="checkbox"
        className={styles.checkbox}
        id={value}
        value={value}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        ref={ref}
        data-testid={`checkbox-${value}`}
      />
    </FieldContainer>
  );
};
