import { useCallback } from 'react';

import { ScenarioChannel } from 'js/components/channels/ScenarioChannel';
import ACTIONS from 'js/constants/actions';
import useAppDispatch from 'js/hooks/useAppDispatch';
import { WebsocketsConsumer } from 'js/websockets';
import { Department } from 'types';

interface ScenarioWebsocketsConsumerProps {
  scenarioId: number;
}

const ScenarioWebsocketsConsumer = ({ scenarioId }: ScenarioWebsocketsConsumerProps) => {
  const dispatch = useAppDispatch();

  const onReceivedDepartments = useCallback(
    ({ departments }: { departments: Department[] }) => {
      dispatch({ type: ACTIONS.RECEIVE_DEPARTMENTS, data: departments });
    },
    [dispatch],
  );

  return (
    <>
      <ScenarioChannel scenarioId={scenarioId} />
      <WebsocketsConsumer
        channel="DepartmentsChannel"
        id={scenarioId}
        onReceived={onReceivedDepartments}
      />
    </>
  );
};

export default ScenarioWebsocketsConsumer;
