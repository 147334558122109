import { createQueryKeys } from '@lukemorales/query-key-factory';

import { fetchLocation, fetchLocations } from 'js/api/locations';

const locations = createQueryKeys('locations', {
  list: {
    queryKey: null,
    queryFn: fetchLocations,
  },
  detail: (id: number) => ({
    queryKey: [id],
    queryFn: () => (id ? fetchLocation(id) : null),
  }),
});

export default locations;
