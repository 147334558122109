import { useDispatch, useSelector } from 'react-redux';

import { ScenarioFilterEventDetails } from 'js/analytics/types/scenario';
import useTrackEvent from 'js/analytics/useTrackEvent';
import { getEventSourceValueByCurrentRoute } from 'js/analytics/utils';
import RawGenderFilter from 'js/components/common/filter/RawGenderFilter';
import ACTIONS from 'js/constants/actions';
import scenarioViewSelector from 'js/selectors/scenarioView';
import { Gender } from 'types';

import styles from './GenderFilter.module.scss';

const GenderFilter = () => {
  const dispatch = useDispatch();
  const { genderFilter } = useSelector(scenarioViewSelector);
  const { trackEvent } = useTrackEvent<ScenarioFilterEventDetails>();

  const filterGender = (values: Gender[]) => {
    dispatch({ type: ACTIONS.SET_EMPLOYEE_FILTER, filterType: 'gender', filter: values });
    const source = getEventSourceValueByCurrentRoute();
    trackEvent('scenario.filter', { type: 'gender', source });
  };

  return <RawGenderFilter value={genderFilter} onChange={filterGender} className={styles.filter} />;
};

export default GenderFilter;
