import {
  ApiOnFailure,
  ApiOnSuccess,
  CognitoUserData,
  CreateUserResponse,
  PromoUtmParams,
} from 'types';

import api from './api';

export const createUser = ({
  userData,
  promoUtmData,
  onSuccess,
  onFailure,
}: {
  userData: CognitoUserData;
  promoUtmData?: PromoUtmParams | null;
  onSuccess?: ApiOnSuccess<CreateUserResponse>;
  onFailure?: ApiOnFailure;
}) => {
  const params = { user: userData, ...promoUtmData };

  return api.execute<CreateUserResponse>({
    call: () => api.post('/users', { params }),
    onSuccess,
    onFailure,
    defaultError: 'Failed to create user',
  });
};
