import { formatError } from '../components-legacy/common/AlertMessage';
import api from './api';

export const fetchTemplatePreview = async (scenarioId: number, templateId: number) => {
  const { success, data } = await api.get<GeoTier[]>(
    `/scenarios/${scenarioId}/strategy_templates/${templateId}/geo_tier_previews`,
  );

  if (success) {
    return data;
  }

  throw new Error('Failed to fetch geo tiers.');
};

// Tiers
export type GeoTier = {
  benchmarkLocationId: number;
  cashAdjustment: number;
  displayOrder: number;
  equityAdjustment: number;
  finalCashAdjustment: number;
  finalEquityAdjustment: number;
  geoTierLocations: GeoTierLocation[];
  headcountDetails: GeoTierHeadcount;
  id: number;
  isCustom: boolean;
  name: string;
  scenarioId: number;
  acceptsUnassigned: boolean;
  jobAreaOverrides?: JobAreaOverride[];
  jobTypeOverrides?: JobTypeOverride[];
};

export enum HeadcountType {
  Total = 'total',
  Active = 'active',
  Future = 'future',
  Terminated = 'terminated',
  International = 'international',
  Contractor = 'contractor',
  PartTime = 'partTime',
  Intern = 'intern',
  Temp = 'temp',
  Seasonal = 'seasonal',
  Executive = 'executive',
}

export type GeoTierHeadcount = {
  [key in HeadcountType]?: number;
};

export type GeoTierLocation = {
  geoTierId: number;
  id: number;
  locationId: number;
  name: string;
};

export type UnassignedLocation = {
  id: number;
  locationId: number;
  name: string;
  scenarioId: number;
  createdAt: string;
  updatedAt: string;
};

export type GeoTierParams = {
  name?: string;
  benchmarkLocationId?: number;
  cashAdjustment?: number;
  equityAdjustment?: number;
  acceptsUnassigned?: boolean;
};

export const fetchGeoTiers = async (scenarioId: number) => {
  const { success, data } = await api.get<GeoTier[]>(`/scenarios/${scenarioId}/pay_tiers`);

  if (success) {
    return data;
  }

  throw new Error('Failed to fetch geo tiers.');
};

export const fetchDefaultPayTier = async (scenarioId: number) => {
  const { success, data } = await api.get<GeoTier | null>(
    `/scenarios/${scenarioId}/default_pay_tier`,
  );

  if (success) {
    return data;
  }

  throw new Error('Failed to fetch default pay tier.');
};

export const createGeoTier = async (scenarioId: number, params: GeoTierParams) => {
  const { success, data, error } = await api.post<GeoTier>(`/scenarios/${scenarioId}/pay_tiers`, {
    params: { geo_tier: params },
  });
  if (success) {
    return data;
  }

  throw new Error(`Failed to create geo tier. ${formatError(error)}`);
};

export const updateGeoTier = async (geoTierId: number, params: GeoTierParams) => {
  const { success, data, error } = await api.put<GeoTier>(`/pay_tiers/${geoTierId}`, {
    params: { geo_tier: params },
  });

  if (success) {
    return data;
  }

  throw new Error(`Failed to update geo tier: ${geoTierId}. ${formatError(error)}`);
};

export const deleteGeoTier = async (geoTierId: number) => {
  const { success, data } = await api.delete(`/pay_tiers/${geoTierId}`);
  if (success) {
    return data;
  }

  throw new Error(`Failed to delete geo tier.`);
};

export const fetchGeoTierLocations = async (geoTierId: number) => {
  const { success, data } = await api.get<GeoTierLocation[]>(
    `/pay_tiers/${geoTierId}/pay_tier_rules`,
  );
  if (success) {
    return data;
  }

  throw new Error(`Failed to fetch locations for geo tier.`);
};

export const fetchUnassignedLocations = async (scenarioId: number) => {
  const { success, data } = await api.get<UnassignedLocation[]>(
    `/scenarios/${scenarioId}/unassigned_geo_tier_locations`,
  );

  if (success) {
    return data;
  }

  throw new Error('Failed to fetch unassigned geo tier locations.');
};

// Overrides
export interface Override {
  isCustom: boolean;
  geoTierId: number | null;
  geoTierName: string | null;
  headcount: number;
}

export interface JobAreaOverride extends Override {
  jobAreaId: number;
  jobAreaName: string;
  jobAreaOverrideId: number | null;
}

export interface JobTypeOverride extends Override {
  jobTypeId: number;
  jobTypeName: string;
  jobTypeOverrideId: number | null;
}

export type OverrideParams = {
  geoTierId: number;
  jobAreaId?: number;
  jobTypeId?: number;
};

export type PayTierRule = {
  payTierId: number;
  locationId?: number;
  jobAreaId?: number;
  jobTypeId?: number;
};

export type NewPayTierRuleParams = {
  locationId?: number;
  jobAreaId?: number;
  jobTypeId?: number;
};

export type EditPayTierRuleParams = {
  payTierId?: number;
  locationId?: number;
  jobAreaId?: number;
  jobTypeId?: number;
};

export const createPayTierRule = async (payTierId: number, params: NewPayTierRuleParams) => {
  const { success, data, error } = await api.post<PayTierRule>(
    `/pay_tiers/${payTierId}/pay_tier_rules`,
    {
      params: { pay_tier_rule: params },
    },
  );
  if (success) {
    return data;
  }

  throw new Error(`Failed to create pay tier rule: ${error}`);
};

export const updatePayTierRule = async (id: number, params: EditPayTierRuleParams) => {
  const { success, data, error } = await api.put<OverrideParams>(`/pay_tier_rules/${id}`, {
    params: { pay_tier_rule: params },
  });
  if (success) {
    return data;
  }

  throw new Error(`Failed to update pay tier rule: ${error}`);
};

export const deletePayTierRule = async (id: number) => {
  const { success, data, error } = await api.delete(`/pay_tier_rules/${id}`);
  if (success) {
    return data;
  }

  throw new Error(`Failed to delete pay tier rule: ${error}`);
};

// Job Area
export const fetchJobAreaOverrides = async (scenarioId: number) => {
  const { success, data, error } = await api.get<JobAreaOverride[]>(
    `/scenarios/${scenarioId}/job_area_overrides`,
  );

  if (success) {
    return data;
  }

  throw new Error(`Failed to fetch job area overrides: ${error}`);
};

export const fetchJobAreaOverridePreviews = async (scenarioId: number, templateId: number) => {
  const { success, data, error } = await api.get<JobAreaOverride[]>(
    `/scenarios/${scenarioId}/strategy_templates/${templateId}/job_area_override_previews`,
  );

  if (success) {
    return data;
  }

  throw new Error(`Failed to fetch job area overrides: ${error}`);
};

// Job Type
export const fetchJobTypeOverrides = async (scenarioId: number) => {
  const { success, data, error } = await api.get<JobTypeOverride[]>(
    `/scenarios/${scenarioId}/job_type_overrides`,
  );

  if (success) {
    return data;
  }

  throw new Error(`Failed to fetch job type overrides: ${error}`);
};

export const fetchJobTypeOverridePreviews = async (scenarioId: number, templateId: number) => {
  const { success, data, error } = await api.get<JobTypeOverride[]>(
    `/scenarios/${scenarioId}/strategy_templates/${templateId}/job_type_override_previews`,
  );

  if (success) {
    return data;
  }

  throw new Error(`Failed to fetch job type overrides: ${error}`);
};
