export type ProductPermission =
  | 'add_scenarios'
  | 'view_market'
  | 'view_hiring'
  | 'view_diversity'
  | 'change_targets'
  | 'change_geo'
  | 'customize_positioning'
  | 'customize_width'
  | 'customize_geo'
  | 'have_ranges'
  | 'download_ranges'
  | 'have_offers'
  | 'comp_cycles'
  | 'total_rewards'
  | 'unlimited_employees_ranges';

export type Product = {
  id: number;
  name: string;
  tier: ProductTier;
  active: boolean;
  default: boolean;
  visible: boolean;
  selfService: boolean;
  billingPeriod: string | null;
  billingPrice: number | null;
  createdAt: string;
  description: string;
  displayOrder: number;
  permissions: ProductPermission[];
  features: string[];
  maxSeats: number | null;
  paymentType: string | null;
  period: string | null;
  popular: boolean;
  price: number;
  pricingModel: string;
  servicePlan: string;
};

export enum ProductTier {
  Team = 'Team',
  Business = 'Business',
  Professional = 'Professional',
  Enterprise = 'Enterprise',
}
