import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { getById } from 'js/selectors/companies';
import { currentScenarioCompanyIdSelector } from 'js/selectors/scenarios';
import { PlayBook } from 'types';

import useAccess from './useAccess';
import useAppSelector from './useAppSelector';
import useChat from './useChat';

const useUpgrade = () => {
  const { startChat } = useChat();
  const navigate = useNavigate();
  const companyId = useAppSelector(currentScenarioCompanyIdSelector);
  const company = useAppSelector((state) => getById(state, companyId));
  const { upgradePageAccessible } = useAccess({ company });

  const openUpgrade = useMemo(() => {
    if (upgradePageAccessible) {
      return () => navigate(`/companies/${companyId}/upgrade`);
    }

    return () => startChat(PlayBook.ContactUs);
  }, [startChat, navigate, companyId, upgradePageAccessible]);

  return { openUpgrade };
};

export default useUpgrade;
