import { CaretDown } from '@phosphor-icons/react';
import classNames from 'classnames';
import { useState, ReactNode, MouseEvent } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import FormControl, { FormControlProps } from 'react-bootstrap/FormControl';

import ListNavigation from 'js/components-legacy/common/ListNavigation';
import Icon from 'js/design-system/Icon';
import Tooltip from 'js/design-system/Tooltip/Tooltip';
import { FormFieldProps, DropDownValue } from 'types';

import Field from './Field';

import styles from './SingleSelect.module.scss';

export type Option<TValue> = {
  value: TValue;
  label: string;
  disabled?: boolean;
  displayedSelectedLabel?: ReactNode;
  displayedLabel?: ReactNode;
  tooltipContent?: string;
};

type CustomInputProps = {
  autofocus?: boolean;
} & Pick<FormControlProps, 'onClick' | 'onKeyDown'>;

interface SingleSelectProps<TValue, TOption extends Option<TValue>>
  extends FormFieldProps<TValue, TOption> {
  customInput?: ({ autofocus, onClick, onKeyDown }: CustomInputProps) => ReactNode;
  options: TOption[];
  defaultDisplayOption?: TOption;
  resetable?: boolean;
  dropdownClassName?: string;
  optionClassName?: string;
  toggleClassName?: string;
  disabledClassName?: string;
  menuFooter?: ReactNode;
}

const SingleSelect = <TValue, TOption extends Option<TValue> = Option<TValue>>({
  disabled,
  placeholder,
  options,
  customInput,
  inputClassName,
  dropdownClassName,
  optionClassName,
  toggleClassName,
  resetable = false,
  defaultDisplayOption,
  disabledClassName,
  menuFooter,
  ...props
}: SingleSelectProps<TValue, TOption>) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const keepCustomInput = (e: MouseEvent<HTMLInputElement>) => {
    if (expanded) {
      e.stopPropagation();
    }
  };

  const displayedSelectedLabel = (value: DropDownValue) => {
    const option =
      options.find((option) => String(option.value) === String(value)) || defaultDisplayOption;
    return option?.displayedSelectedLabel || <span>{option?.label || placeholder}</span>;
  };

  return (
    <ListNavigation
      items={options}
      onOpen={() => setExpanded(true)}
      searchableByFirstChar={!customInput}
    >
      {(itemsContainerRef: React.RefObject<HTMLDivElement>) => (
        <Field {...props} inputClassName={classNames(styles.input, inputClassName)}>
          {({ invalid, onChange, onBlur, value }) => (
            <FormControl
              as={Dropdown}
              isInvalid={invalid}
              className={classNames(styles.wrapper, dropdownClassName, {
                [styles.disabled]: disabled,
                [disabledClassName || '']: disabled,
                [styles.hidden]: expanded,
              })}
              onToggle={toggleExpanded}
              show={expanded}
            >
              <Dropdown.Toggle
                variant="default"
                disabled={disabled}
                aria-label={String(props.label || '')}
                className={classNames(styles.toggle, toggleClassName, {
                  [styles.placeholder]: placeholder && !value,
                })}
              >
                <span>{displayedSelectedLabel(value)}</span>
                {!props.inputAppend && (!resetable || !value) && (
                  <Icon icon={CaretDown} size={16} />
                )}
              </Dropdown.Toggle>

              {customInput &&
                expanded &&
                customInput({
                  autofocus: expanded,
                  onClick: keepCustomInput,
                  onKeyDown: () => {},
                })}
              <Dropdown.Menu
                className={styles.menu}
                ref={itemsContainerRef as React.RefObject<HTMLDivElement>}
                flip={false}
              >
                <div className={styles.menuInner}>
                  {options.map((option) => (
                    <Tooltip content={option.tooltipContent} key={String(option.value)}>
                      <div>
                        <Dropdown.Item
                          className={classNames(styles.option, optionClassName, {
                            [styles.active]: option.value === value,
                            [styles.disabled]: option.disabled,
                          })}
                          onClick={() => {
                            onBlur();
                            onChange(option.value, option);
                          }}
                          disabled={option.disabled}
                        >
                          {option.displayedLabel || <span>{option.label}</span>}
                        </Dropdown.Item>
                      </div>
                    </Tooltip>
                  ))}
                </div>
                {menuFooter && <div className={styles.menuFooter}>{menuFooter}</div>}
              </Dropdown.Menu>
            </FormControl>
          )}
        </Field>
      )}
    </ListNavigation>
  );
};

export default SingleSelect;
