import { KeyboardEvent, ReactNode } from 'react';

import { FieldProps } from '../ConnectedField';

export interface TextFieldStyle {
  placeholder?: string;
  autoComplete?: string;
  maxLength?: number;
  alertBox?: {
    text: string;
    showIcon?: boolean;
  };
  inputPrepend?: ReactNode;
  inputPrependWrapperClassName?: string;
  inputAppend?: ReactNode;
  inputAppendWrapperClassName?: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onFocus?: () => void;
  onKeyDown?: (e: KeyboardEvent) => void;
  onReset?: () => void;
  helpText?: ReactNode;
  resetable?: boolean;
  showCounter?: boolean;
  lowercase?: boolean;
  trim?: boolean;
}

export type TextFieldValue = string | number | null;

export interface TextFieldProps extends FieldProps<TextFieldValue>, TextFieldStyle {
  type?: `${TextFieldTypes}`;
  min?: string;
}

export enum TextFieldTypes {
  Text = 'text',
  Password = 'password',
  Search = 'search',
  Date = 'date',
  Datetime = 'datetime-local',
}
