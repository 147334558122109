import { Variant as TextVariant } from 'js/design-system/Text/types';

export interface AvatarProps {
  avatarSrc?: string;
  name?: string;
  variant?: Variant;
  colorIndex?: string | number;
  tooltip?: string;
  className?: string;
  wrapperClassName?: string;
}

export type Variant = 'chip' | 'small' | 'medium' | 'large' | 'xlarge';

interface VariantProps {
  size: string;
  textVariant: TextVariant;
}

export const variants: Record<Variant, VariantProps> = {
  chip: { size: '24', textVariant: 'text-misc-help' },
  small: { size: '32', textVariant: 'text-misc-help' },
  medium: { size: '40', textVariant: 'text-body-main' },
  large: { size: '48', textVariant: 'text-body-main' },
  xlarge: { size: '112', textVariant: 'text-subheading-large' },
};
