import classNames from 'classnames';

import Button from 'js/design-system/Button/Button';
import { ButtonProps, ButtonSize, ButtonVariant } from 'js/design-system/Button/types';
import Text from 'js/design-system/Text/Text';
import { Variant } from 'js/design-system/Text/types';

import styles from './SettingsHeader.module.scss';

export interface SettingsHeaderProps {
  topHeader?: React.ReactNode;
  heading: string;
  HeadingElement?: keyof JSX.IntrinsicElements;
  subheading?: React.ReactNode;
  headingAppend?: React.ReactNode;
  actionContent?: React.ReactNode;
  actionButtonProps?: Partial<ButtonProps>;
  secondaryActionContent?: React.ReactNode;
  secondaryActionButtonProps?: Partial<ButtonProps>;
  contentClassName?: string;
  headingContainerClassName?: string;
  headingVariantOverride?: Variant;
  actionContainerClassName?: string;
  additionalContent?: React.ReactNode;
}

export const headingVariant = 'text-heading-h6';
export const headingColor = 'platform-gray-900';

const SettingsHeader = ({
  topHeader,
  heading,
  HeadingElement = 'div',
  subheading,
  headingAppend,
  actionContent,
  actionButtonProps,
  secondaryActionContent,
  secondaryActionButtonProps,
  contentClassName,
  headingContainerClassName,
  headingVariantOverride,
  actionContainerClassName,
  additionalContent,
}: SettingsHeaderProps) => {
  return (
    <div className={styles.settingsHeader}>
      {topHeader && <div className={styles.topHeader}>{topHeader}</div>}
      <div className={classNames(styles.mainContent, contentClassName)}>
        <div className={styles.headingAndSubheading}>
          <div className={classNames(styles.headingContainer, headingContainerClassName)}>
            <Text
              variant={headingVariantOverride || headingVariant}
              color={headingColor}
              Element={HeadingElement}
            >
              {heading}
            </Text>
            {headingAppend && <div>{headingAppend}</div>}
          </div>
          {subheading && (
            <>
              {typeof subheading === 'string' ? (
                <div className={styles.subheading}>{subheading}</div>
              ) : (
                subheading
              )}
            </>
          )}
        </div>
        {(secondaryActionContent || actionContent) && (
          <div className={classNames(styles.actionButtonContainer, actionContainerClassName)}>
            {typeof secondaryActionContent === 'string' ? (
              <Button
                variant={ButtonVariant.Outlined}
                size={ButtonSize.Medium}
                {...secondaryActionButtonProps}
              >
                {secondaryActionContent}
              </Button>
            ) : (
              secondaryActionContent
            )}
            {typeof actionContent === 'string' ? (
              <Button
                variant={ButtonVariant.Filled}
                size={ButtonSize.Medium}
                {...actionButtonProps}
              >
                {actionContent}
              </Button>
            ) : (
              actionContent
            )}
          </div>
        )}

        {additionalContent && <div className={styles.additionalContent}>{additionalContent}</div>}
      </div>
    </div>
  );
};

export default SettingsHeader;
