import { Stakeholder } from 'types';

import api from './api';

export const fetchStakeholders = async (args: { companyId?: number; scenarioId?: number }) => {
  let url;
  if (args.companyId) {
    url = `/companies/${args.companyId}/stakeholders`;
  }
  if (args.scenarioId) {
    url = `/scenarios/${args.scenarioId}/stakeholders`;
  }

  if (!url) {
    throw new Error(`Failed to load stakeholders: No route defined`);
  }

  const { success, data, error } = await api.get<Stakeholder[]>(url);

  if (success) {
    return data;
  }

  throw new Error(`Failed to load stakeholders: ${error}`);
};
