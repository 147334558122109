import { createSelector } from 'reselect';

import { AppState, FetchStatus, UserPermission, UserId } from 'types';

export const getAll = createSelector(
  (state: AppState) => state.companyUsers.allUserIds,
  (state: AppState) => state.companyUsers.byUserId,
  (allUserIds, byUserId): UserPermission[] => allUserIds.map((id: number) => byUserId[id]),
);

export const getByUserId = (state: AppState, userId?: UserId): UserPermission | undefined =>
  userId ? state.companyUsers.byUserId[userId] : undefined;

export const status = (state: AppState, companyId?: number): FetchStatus =>
  state.companyUsers.companyId === companyId ? state.companyUsers.status : FetchStatus.Idle;

export const statusSucceeded = (state: AppState, companyId?: number): boolean =>
  state.companyUsers.companyId === companyId && state.companyUsers.status === FetchStatus.Succeeded;
