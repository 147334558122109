import { useEffect, useState, useRef } from 'react';

import { debounce } from 'js/utils/debounce';

const useWindowSize = () => {
  const mounted = useRef(false);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    const debounceHandleResize = debounce(() => {
      if (mounted.current) {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
    });

    window.addEventListener('resize', debounceHandleResize);
    debounceHandleResize();
    return () => window.removeEventListener('resize', debounceHandleResize);
  }, []);
  return windowSize;
};

export default useWindowSize;
