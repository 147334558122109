export type Approval = {
  id: number;
  status: ApprovalStatus;
  stages: ApprovalStage[];
  currentStageId: number;
  approvalTemplateId?: number;
};

export type ApprovalStage = {
  id: number;
  position: number;
  status: ApprovalStageStatus;
  requiredApproverCount: number;
  approvers: Approver[];
};

export type Approver = {
  id: number;
  email: string;
  fullName: string;
  ownableId: number;
  ownableType: string;
  notes: string;
  reason: string;
  response: ApproverResponse | null;
};

export enum ApproverResponse {
  Accepted = 'accepted',
  Rejected = 'rejected',
}

export enum ApprovalStatus {
  Approved = 'approved',
  Denied = 'denied',
  Pending = 'pending',
}

export enum ApprovalStageStatus {
  Approved = 'approved',
  Denied = 'denied',
  Pending = 'pending',
}

export enum ApprovalStageComputedStatus {
  Waiting = 'waiting',
  Discarded = 'discarded',
}

export type ApprovalListStageStatus = ApprovalStageStatus | ApprovalStageComputedStatus;

export type GenericApprovalFormData = {
  name?: string;
  newName?: string;
  approvalTemplateId?: number;
  stages: GenericApprovalStage[];
};

export type GenericApprovalStage = {
  key: number;
  requiredApproverCount: number;
  approvers: ApproverFormData[];
};

export type ApproverFormData = {
  email: string;
  firstName?: string | null;
  lastName?: string | null;
  jobTitle?: string | null;
};
