import { Question } from '@phosphor-icons/react';
import { useSelector } from 'react-redux';

import { GeneralClickHelpEventDetails } from 'js/analytics/types/general';
import useTrackEvent from 'js/analytics/useTrackEvent';
import IconButton from 'js/design-system/IconButton/IconButton';
import Tooltip from 'js/design-system/Tooltip/Tooltip';
import useChat from 'js/hooks/useChat';
import { currentUserSelector } from 'js/selectors/currentUser';
import { PlayBook } from 'types';

import styles from './NavBar.module.scss';

interface HelpButtonProps {
  playbook?: PlayBook;
}

const HelpButton = ({ playbook = PlayBook.Help }: HelpButtonProps) => {
  const { startChat } = useChat();
  const user = useSelector(currentUserSelector);
  const { trackEvent } = useTrackEvent<GeneralClickHelpEventDetails>();

  const handleOnClick = () => {
    startChat(playbook);
    trackEvent('general.click.help');
  };

  if (!user?.id) return null;

  return (
    <Tooltip content="Need help?">
      <IconButton
        icon={Question}
        size="medium"
        onClick={handleOnClick}
        className={styles.iconButton}
      />
    </Tooltip>
  );
};

export default HelpButton;
