import ConnectedField, { ConnectedFieldProps } from 'js/design-system/Form/ConnectedField';
import { ImagePickerProps } from 'js/design-system/Form/ImagePicker/types';

import ImagePicker from './ImagePicker';

const ConnectedTextField = (props: ConnectedFieldProps<ImagePickerProps>) => (
  <ConnectedField
    fieldType="input"
    renderField={(fieldProps: ImagePickerProps) => <ImagePicker {...fieldProps} />}
    {...props}
  />
);

export default ConnectedTextField;
