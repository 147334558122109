import { Info } from '@phosphor-icons/react';

import Icon from 'js/design-system/Icon';
import LoadingPlaceholder from 'js/design-system/LoadingPlaceholder/LoadingPlaceholder';
import Tooltip from 'js/design-system/Tooltip/Tooltip';
import { getStatByType, STAT_TYPES } from 'js/services/compensations/statService';
import { CompensationType, Employee } from 'types';

import styles from './BenchmarkingTable.module.scss';

interface DepartmentPercentileCellProps {
  employees: Employee[];
  loading: boolean;
}

export const DepartmentPercentileCell = ({ employees, loading }: DepartmentPercentileCellProps) => {
  if (loading) {
    return <LoadingPlaceholder className={styles.loadingCellDepartment} />;
  }

  const deptPercentile = getStatByType(
    CompensationType.SalaryOte,
    employees,
    STAT_TYPES.midPercentile,
  );

  return (
    <div className={styles.deptPercentile}>
      <span>{deptPercentile.formatted} Avg</span>
      <Tooltip content="Average market percentile for all employees in this department">
        <Icon icon={Info} size={16} color="platform-gray-500" />
      </Tooltip>
    </div>
  );
};
