import { useJobRoles } from 'js/api/jobRoles';
import JobRoleSuggest from 'js/components/common/job-roles/JobRoleSuggest';
import { JobRoleSuggestProps } from 'js/components/common/job-roles/types';
import { Scenario } from 'types';

interface PayRangeJobRoleSuggestProps
  extends Omit<JobRoleSuggestProps, 'name' | 'benchmarkId' | 'scenarioId'> {
  scenario: Scenario;
  payRangeId: number | undefined;
}

const PayRangeJobRoleSuggest = ({ scenario, payRangeId, ...rest }: PayRangeJobRoleSuggestProps) => {
  const { data: rangeJobRoles = [], isSuccess: jobRolesReady } = useJobRoles(
    scenario.benchmarkId,
    scenario.id,
    {
      payRangeId,
    },
  );

  if (!jobRolesReady) {
    return null;
  }

  return (
    <JobRoleSuggest
      name="jobRoleId"
      benchmarkId={scenario.benchmarkId}
      label="Benchmark Role"
      labelPosition="top"
      helpTextState="hidden"
      hideSubLabel
      hideRoleDescription
      allowedValues={rangeJobRoles.map(({ id }) => id)}
      placeholder="Choose a benchmark role"
      {...rest}
    />
  );
};

export default PayRangeJobRoleSuggest;
