import Auth from '@aws-amplify/auth';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { fetchCashConnection } from 'js/actions/cashConnections';
import { fetchOne as fetchCompany, update as updateCompany } from 'js/actions/companies';
import { loadUser } from 'js/actions/currentUser';
import { fetchUserPermissions, receiveUserPermissions } from 'js/actions/permissions';
import { fetchAll as fetchAllScenarios } from 'js/actions/scenarios';
import { identify } from 'js/analytics';
import { getIntegrations } from 'js/analytics/utils';
import { FeatureFlags } from 'js/config/feature-flags';
import ACTIONS from 'js/constants/actions';
import useAppDispatch from 'js/hooks/useAppDispatch';
import useAppSelector from 'js/hooks/useAppSelector';
import useFeatureFlag from 'js/hooks/useFeatureFlag';
import useNumberParams from 'js/hooks/useNumberParams';
import { getById as companySelector } from 'js/selectors/companies';
import {
  currentUserRoleSelector,
  currentUserSelector,
  userFetchedStatusSelector,
} from 'js/selectors/currentUser';
import { getById as scenarioSelector } from 'js/selectors/scenarios';
import { logError } from 'js/utils/logger';
import { WebsocketsConsumer, WebsocketsProvider } from 'js/websockets';
import { CognitoUserSession, Permission, User, UserRole } from 'types';

const Root = () => {
  const { scenarioId } = useNumberParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [token, setToken] = useState('');

  const scenario = useAppSelector((state) => scenarioSelector(state, scenarioId));
  const companyId = scenario?.companyId;
  const user = useAppSelector(currentUserSelector);
  const userFetched = useAppSelector(userFetchedStatusSelector);
  const currentUserRole = useAppSelector((state) => currentUserRoleSelector(state, companyId));
  const userId = user?.id;
  const company = useAppSelector((state) => companySelector(state, companyId));

  const { enabled: hrisMergeToggleEnabled } = useFeatureFlag(
    FeatureFlags.hrisMergeToggle,
    companyId,
  );

  const getUser = useCallback(async () => {
    try {
      const response = (await Auth.currentSession()) as CognitoUserSession;
      if (response && userFetched !== 'succeeded') {
        dispatch(loadUser());
      }
      if (response.idToken) setToken(response.idToken.jwtToken);
    } catch (e) {
      logError(e);
      navigate('/login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, userFetched]); // Note: adding navigate as dependency will cause full page refreshes

  useEffect(() => {
    getUser();
  }, [getUser]);

  useEffect(() => {
    if (companyId) {
      dispatch(fetchCompany(companyId));
      dispatch(fetchAllScenarios(companyId));
    }
  }, [companyId, dispatch]);

  useEffect(() => {
    if (companyId && currentUserRole && currentUserRole !== UserRole.RewardsViewer) {
      dispatch(fetchCashConnection(companyId, hrisMergeToggleEnabled ? 'merge' : 'finch'));
    }
  }, [companyId, currentUserRole, dispatch, hrisMergeToggleEnabled]);

  useEffect(() => {
    const options: SegmentAnalytics.SegmentOpts = {
      integrations: getIntegrations(),
    };
    if (!user || !user?.id) {
      window.analytics?.identify({}, options);
    } else if (company) {
      identify(user, company);
    } else {
      window.analytics?.identify(user.id.toString(), undefined, options, undefined);
    }
  }, [user, company]);

  useEffect(() => {
    if (userId) {
      dispatch(fetchUserPermissions(userId));
    }
  }, [dispatch, userId]);

  const onReceiveUser = useCallback(
    ({ attrs, permissions }: { attrs: User; permissions: Permission[] }) => {
      if (attrs && company && attrs.landingCompanyId === company?.id) {
        dispatch(updateCompany({ id: company?.id, landingScenarioId: attrs.landingScenarioId }));
      }

      if (permissions) {
        dispatch(receiveUserPermissions(permissions));
      }

      if (attrs) {
        dispatch({ type: ACTIONS.USER_UPDATED, data: attrs });
      }
    },
    [dispatch, company],
  );

  const userChannel = useMemo(
    () =>
      user?.id ? (
        <WebsocketsConsumer
          channel="UserChannel"
          id={user.id}
          onReceived={onReceiveUser}
          onRejected={getUser}
          onDisconnected={getUser}
        />
      ) : null,
    [user?.id, onReceiveUser, getUser],
  );

  if (!userId) return null;

  return (
    <WebsocketsProvider url={`${process.env.REACT_APP_WS_URL}?token=${token}`}>
      <>
        <Outlet />
        {userChannel}
      </>
    </WebsocketsProvider>
  );
};

export default Root;
