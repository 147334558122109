import { Field } from 'react-final-form';

import { ConnectedFieldProps } from '../ConnectedField';
import { Checkbox, CheckboxProps } from './Checkbox';

export const ConnectedCheckbox = ({
  name,
  ...checkboxProps
}: ConnectedFieldProps<CheckboxProps & { name: string }>) => (
  <Field type="checkbox" name={name} value={checkboxProps.value} id={checkboxProps.value}>
    {({ input }) => <Checkbox {...checkboxProps} onChange={input.onChange} />}
  </Field>
);
