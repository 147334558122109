import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { FeatureFlag } from 'types/featureFlag';

import { fetchFeatureFlags } from 'js/api/feature-flags';

/**
 * Get a feature flag
 * @param featureName The feature name
 * @param companyId (optional) The company ID trying to access the feature
 * @returns \{status: React Query status string (idle | loading | error | success),
 *  enabled: true/false (whether or not the feature is enabled)}
 *
 * For status use, see https://react-query-v3.tanstack.com/reference/useQuery
 */

const useFeatureFlag = (
  featureName: string,
  companyId: number | null | undefined,
  options?: UseQueryOptions<FeatureFlag[]>,
) => {
  const { data: featureFlags = [], status = '' } = useQuery<FeatureFlag[]>(
    [companyId, 'feature-flags'],
    () => fetchFeatureFlags(companyId),
    options,
  );
  const featureFlag = featureFlags.find((feature) => feature.name === featureName);

  return { status, enabled: featureFlag?.enabled ?? false };
};

export default useFeatureFlag;
