import { ElementType, HTMLAttributes, ReactNode } from 'react';
import { Color } from 'types/designSystem';

import { Weight } from 'js/design-system/Icon/PhosphorIcon';

export enum ButtonVariant {
  Filled = 'filled',
  Outlined = 'outlined',
  Ghost = 'ghost',
}

export enum ButtonSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export enum ButtonShape {
  Circle = 'circle',
  Square = 'square',
}

export enum ButtonType {
  Button = 'button',
  Submit = 'submit',
  Reset = 'reset',
}

export interface ButtonProps extends Pick<HTMLAttributes<HTMLElement>, 'title'> {
  type?: `${ButtonType}`;
  leftIcon?: ElementType;
  rightIcon?: ElementType;
  variant: ButtonVariant;
  size?: `${ButtonSize}`;
  children?: ReactNode;
  disabled?: boolean;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onMouseEnter?: (event: React.MouseEvent<HTMLElement>) => void;
  onMouseDown?: (event: React.MouseEvent<HTMLElement>) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  isLoading?: boolean;
  isActive?: boolean;
}

export enum IconButtonVariant {
  Gray = 'gray',
  Default = 'default',
}

export type IconButtonProps = Pick<
  ButtonProps,
  'type' | 'size' | 'onClick' | 'className' | 'disabled' | 'title'
> & {
  icon: ElementType;
  iconColor?: Color;
  iconWeight?: Weight;
  shape?: `${ButtonShape}`;
  variant?: `${IconButtonVariant}`;
};
