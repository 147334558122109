import { useMemo } from 'react';

import useGetRange from 'js/components/pay-strategy/hooks/useGetRange';
import compare from 'js/utils/compare';
import { PayRangeGroup } from 'types';

export const NOT_USING_RANGES_PAY_RANGE_GROUP_ID = -2;
export const NO_BENCHMARK_ROLE_PAY_RANGE_GROUP_ID = -1;

const useGetRangeGroups = () => {
  const { data: payRange } = useGetRange();

  const rangeGroups = useMemo(() => {
    if (!payRange) return [];

    const groups = [...payRange.payRangeGroups].sort((a, b) => compare(a.name, b.name));

    return [
      ...groups,
      {
        id: NO_BENCHMARK_ROLE_PAY_RANGE_GROUP_ID,
        jobAreaId: 0,
        name: 'Missing benchmark role',
      } as PayRangeGroup,
      {
        id: NOT_USING_RANGES_PAY_RANGE_GROUP_ID,
        jobAreaId: 0,
        name: 'Not using ranges',
      } as PayRangeGroup,
    ];
  }, [payRange]);

  return rangeGroups;
};

export default useGetRangeGroups;
