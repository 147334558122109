import { AnyAction } from 'redux';

import { Company, ConnectionStatus, FetchStatus } from 'types';

import ACTIONS from '../constants/actions';

export enum WelcomeStatus {
  Idle = 'idle',
  Welcoming = 'welcoming',
}

type CompanyState = {
  allIds: number[];
  byId: Record<number, Company>;
  welcomeStatus: WelcomeStatus;
  readStatus: Record<number, FetchStatus>;
};

const initialState: CompanyState = {
  allIds: [],
  byId: {},
  welcomeStatus: WelcomeStatus.Idle,
  readStatus: {},
};

export default (state = initialState, action: AnyAction = { type: null }): CompanyState => {
  switch (action.type) {
    case ACTIONS.CREATE_COMPANY:
      return {
        ...state,
        allIds: [...state.allIds, action.data.id],
        byId: { ...state.byId, [action.data.id]: action.data },
      };
    case ACTIONS.DELETE_COMPANY:
      return { ...state, allIds: state.allIds.filter((id) => id !== +action.data.companyId) };
    case ACTIONS.RECEIVE_COMPANY: {
      const company = action.data;
      return {
        ...state,
        byId: { ...state.byId, [company.id]: { ...state.byId[company.id], ...company } },
        readStatus: { ...state.readStatus, [company.id]: FetchStatus.Succeeded },
      };
    }
    case ACTIONS.UPDATE_COMPANY_LANDING_SCENARIO: {
      const company = action.data;
      return {
        ...state,
        byId: {
          ...state.byId,
          [company.id]: { ...state.byId[company.id], landingScenarioId: company.landingScenarioId },
        },
      };
    }
    case ACTIONS.WELCOME: {
      return {
        ...state,
        welcomeStatus: action.data.welcomeStatus,
      };
    }
    case ACTIONS.RECEIVE_CASH_CONNECTION: {
      const { data: connectionData, id: companyId } = action;
      return {
        ...state,
        byId: {
          ...state.byId,
          [companyId]: {
            ...state.byId[companyId],
            hasCashConnection: connectionData?.status === ConnectionStatus.Active,
          },
        },
      };
    }
    case ACTIONS.RESET_STATE:
      return initialState;
    default:
      return state;
  }
};
