export enum UserRole {
  Analyst = 'analyst',
  Administrator = 'administrator',
  OrganizationLeader = 'organization_leader',
  TeamLeader = 'team_leader',
  Approver = 'approver',
  Recruiter = 'recruiter',
  RewardsViewer = 'rewards_viewer',
  Pending = 'pending',
}

export enum Ability {
  BenchmarksView = 'benchmarks:view',
  CompulatorView = 'compulator:view',
  EmployeesCreate = 'employees:create',
  EmployeesJobRolesEdit = 'employees:job_roles:edit',
  EmployeesView = 'employees:view',
  JobRolesViewAll = 'job_roles:view_all',
  PayTierRulesCreate = 'pay_tier_rules:create',
  PayTierRulesDelete = 'pay_tier_rules:delete',
  PayTierRulesEdit = 'pay_tier_rules:edit',
  RangesUpdate = 'ranges:update',
  RewardsView = 'rewards:view',
  BenchmarkQueriesPopularView = 'benchmark_queries:popular:view',
}

export enum PermissionObjectableType {
  Scenario = 'Scenario',
  Company = 'Company',
  Employee = 'Employee',
  Department = 'Department',
  Offer = 'Offer',
}

export type Permission = {
  id: number;
  userId: number;
  objectableId: number;
  objectableType: PermissionObjectableType;
  role: UserRole; // TODO: Remove once all checks for role come from Role or Ability
  updatedAt: string;
  createdAt: string;
};

export type Role = {
  name: string;
  abilities: Partial<Record<Ability, boolean>>;
};
