import { AppDispatch, UserPermission } from 'types';

import ACTIONS from '../constants/actions';

export const fetching = (scenarioId: number) => (dispatch: AppDispatch) => {
  dispatch({ type: ACTIONS.FETCHING_SCENARIO_USERS, scenarioId });
};

export const receiveAll =
  (scenarioId: number, data: UserPermission[]) => (dispatch: AppDispatch) => {
    dispatch({ type: ACTIONS.RECEIVE_SCENARIO_USERS, data, scenarioId });
  };
