import { useQueryClient } from '@tanstack/react-query';

import { forcedFetchAll as forceFetchAllCompanyScenarios } from 'js/actions/scenarios';
import useAppDispatch from 'js/hooks/useAppDispatch';
import queries from 'js/queries';
import { benchmarkQueryCreditsQueryKeys } from 'js/queries/benchmarkQueryCredits';
import { CompProviderCred, ConnectApiProps, DisconnectApiProps } from 'types';

import { ResyncApiProps } from '../../types/connection';
import api from './api';

export const connect = async ({
  code,
  companyId,
  onSuccess,
  onFailure,
  useBasicAuthHeader = true,
  integrationProxyName,
}: ConnectApiProps) => {
  await api.execute<CompProviderCred>({
    call: () =>
      api.post(`/companies/${companyId}/cash_connection`, {
        params: { code, companyId, integrationProxyName, useBasicAuthHeader },
      }),
    onSuccess,
    onFailure,
    defaultError: 'Failed to connect HRIS.',
  });
};

export const disconnect = async ({ companyId, onSuccess, onFailure }: DisconnectApiProps) => {
  await api.execute<CompProviderCred | null>({
    call: () => api.delete(`/companies/${companyId}/cash_connection`),
    onSuccess,
    onFailure,
    defaultError: 'Failed to disconnect HRIS',
  });
};

export const resync = async ({
  companyId,
  integrationProxyName,
  onSuccess,
  onFailure,
}: ResyncApiProps) => {
  await api.execute<CompProviderCred>({
    call: () =>
      api.put(`/companies/${companyId}/cash_connection`, {
        params: { integrationProxyName },
      }),
    onSuccess,
    onFailure,
    defaultError: 'Failed to resync HRIS',
  });
};

export const useUpdateCashConnection = (companyId: number) => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  return () => {
    queryClient.invalidateQueries(queries.companies.detail(companyId)._ctx.lastCashConnectionSync);
    queryClient.invalidateQueries(queries.companies.detail(companyId)._ctx.notifications);
    queryClient.invalidateQueries(queries.companies.detail(companyId)._ctx.payrollProviders._def);
    queryClient.invalidateQueries(benchmarkQueryCreditsQueryKeys.list(companyId));
    dispatch(forceFetchAllCompanyScenarios(companyId));
  };
};
