import { useQueryClient } from '@tanstack/react-query';
import { memo } from 'react';

import { Action } from 'js/channels/types';
import queries from 'js/queries';
import { benchmarkQueryCreditsQueryKeys } from 'js/queries/benchmarkQueryCredits';
import { WebsocketsConsumer } from 'js/websockets';

const ExternalSyncChannel = ({ id }: { id: string }) => {
  const queryClient = useQueryClient();
  const onReceived = ({
    action,
    id,
    scenarioId,
    companyId,
  }: {
    action: Action;
    id: string;
    scenarioId: number;
    companyId: number;
  }) => {
    if (action === 'update') {
      queryClient.invalidateQueries(queries.externalSyncs.detail(id));
      queryClient.invalidateQueries(
        queries.companies.detail(companyId)._ctx.lastCashConnectionSync,
      );
      queryClient.invalidateQueries(
        queries.companies.detail(companyId)._ctx.lastEquityConnectionSync,
      );
      queryClient.invalidateQueries(queries.scenarios.detail(scenarioId)._ctx.lastEmployeesSync);
      queryClient.invalidateQueries(benchmarkQueryCreditsQueryKeys.list(companyId));
      queryClient.invalidateQueries(queries.scenarios.detail(scenarioId)._ctx.lastEmployeesSync);
    }
  };

  return <WebsocketsConsumer channel="ExternalSyncChannel" id={id} onReceived={onReceived} />;
};

export default memo(ExternalSyncChannel);
