import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useQuery } from '@tanstack/react-query';

import { JobArea } from 'types';

import api from './api';

export const fetchJobAreas = async () => {
  const { success, data, error } = await api.get<JobArea[]>('/job_areas');

  if (success) {
    return data;
  }

  throw new Error(`Failed to fetch job areas: ${error}`);
};

export const queryKeys = createQueryKeys('jobAreas', {
  list: () => ({
    queryKey: ['list'],
    queryFn: fetchJobAreas,
  }),
});

export const useJobAreas = () => {
  return useQuery(queryKeys.list());
};
