import { formatError } from 'js/components-legacy/common/AlertMessage';
import { BulkUserInviteFormData, Scenario } from 'types';

import api from './api';

export const fetchScenario = async (scenarioId: number) => {
  const { success, data, error } = await api.get<Scenario>(`/scenarios/${scenarioId}`);

  if (success) {
    return data;
  }

  throw new Error(`Failed to fetch scenario: ${error}`);
};

export const updateScenario = async (scenarioId: number, newParams: Partial<Scenario>) => {
  const { success, data, error } = await api.put<Scenario>(`/scenarios/${scenarioId}`, {
    params: { scenario: newParams },
  });

  if (success) {
    return data;
  }

  throw new Error(`Failed to update scenario: ${error}`);
};

export const bulkUserInvite = async (scenarioId: number, params: BulkUserInviteFormData) => {
  const { success, data, error } = await api.post(`/scenarios/${scenarioId}/bulk_invite`, {
    params,
  });
  if (success) {
    return data;
  }

  throw new Error(formatError(error) || 'Failed to invite employees.');
};
