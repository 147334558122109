import { ElementType, ReactNode } from 'react';

import Text from 'js/design-system/Text/Text';

import ComplexAlertIcon from './ComplexAlertIcon';

import styles from './ComplexAlert.module.scss';

interface ComplexAlertProps {
  icon: ElementType;
  iconColor: string;
  ellipseColor: string;
  title: ReactNode;
  subtitle: ReactNode;
}

const ComplexAlert = ({ icon, title, subtitle, iconColor, ellipseColor }: ComplexAlertProps) => (
  <div className={styles.complexAlertContainer}>
    <ComplexAlertIcon icon={icon} iconColor={iconColor} ellipseColor={ellipseColor} />
    <div className={styles.contentContainer}>
      <Text variant="text-subheading-small" color="platform-gray-900" className={styles.title}>
        {title}
      </Text>
      <Text color="platform-gray-700" className={styles.subtitle}>
        {subtitle}
      </Text>
    </div>
  </div>
);

export default ComplexAlert;
