import { useQueryClient, useMutation } from '@tanstack/react-query';

import { createPayTierRule, deletePayTierRule, updatePayTierRule } from 'js/api/geo-strategy';
import { alertError } from 'js/components-legacy/common/AlertMessage';
import queries from 'js/queries';

export const useCreateJobAreaOverride = (scenarioId: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ payTierId, attrs }: { payTierId: number; attrs: { jobAreaId: number } }) =>
      createPayTierRule(payTierId, attrs),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queries.scenarios.detail(scenarioId)._ctx.jobAreaOverrides);
      },
      onError: (error) => {
        alertError(error);
      },
    },
  );
};

export const useUpdateJobAreaOverride = (scenarioId: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ id, attrs }: { id: number; attrs: { payTierId: number; jobAreaId: number } }) =>
      updatePayTierRule(id, attrs),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queries.scenarios.detail(scenarioId)._ctx.jobAreaOverrides);
      },
      onError: (error) => {
        alertError(error);
      },
    },
  );
};

export const useDeleteJobAreaOverride = (scenarioId: number) => {
  const queryClient = useQueryClient();
  return useMutation(deletePayTierRule, {
    onSuccess: () => {
      queryClient.invalidateQueries(queries.scenarios.detail(scenarioId)._ctx.jobAreaOverrides);
    },
    onError: (error) => {
      alertError(error);
    },
  });
};
