import { GenericObject } from 'types';

import { createAnalyticsEventConfig } from '../utils';
import activityLog from './activityLog';
import benefit from './benefit';
import candidateExperience from './candidateExperience';
import compCycle from './compCycle';
import dataLookup from './dataLookup';
import department from './department';
import employee from './employee';
import equity from './equity';
import general from './general';
import hris from './hris';
import oauth from './oauth';
import offers from './offers';
import onboard from './onboard';
import ranges from './ranges';
import scenario from './scenario';
import scenarioSettings from './scenarioSettings';
import share from './share';
import upgrade from './upgrade';
import user from './user';

export const rawEvents = {
  offers,
  activityLog,
  dataLookup,
  compCycle,
  scenario,
  department,
  employee,
  ranges,
  upgrade,
  share,
  onboard,
  candidateExperience,
  user,
  general,
  hris,
  equity,
  benefit,
  scenarioSettings,
  oauth,
};

const processedEvents = Object.entries(rawEvents).reduce((acc: GenericObject, [k, v]) => {
  acc[k] = createAnalyticsEventConfig(k, v);
  return acc;
}, {});

export default processedEvents;
