import { useContext, useEffect } from 'react';

import Context from './context';

interface ConsumerProps {
  channel: string;
  id?: number | string;
  onConnected?: () => void;
  onDisconnected?: () => void;
  onInitialized?: () => void;
  onReceived: (arg: any) => void;
  onRejected?: () => void;
}

export const Consumer = ({
  channel,
  id,
  onConnected = () => {},
  onDisconnected = () => {},
  onInitialized = () => {},
  onReceived = () => {},
  onRejected = () => {},
}: ConsumerProps) => {
  const cable = useContext(Context);
  useEffect(() => {
    const subscription = cable?.subscriptions?.create(
      { channel, id },
      {
        connected: onConnected,
        disconnected: onDisconnected,
        initialized: onInitialized,
        received: onReceived,
        rejected: onRejected,
      },
    );

    return () => {
      subscription?.unsubscribe();
    };
  }, [cable, channel, id, onConnected, onDisconnected, onInitialized, onReceived, onRejected]);

  return null;
};
