import { useMemo, useState } from 'react';

import TabsContext, { tabsContextParams } from './TabsContext';
import { TabsContextProps, TabsContextProviderProps } from './types';

const TabsContextProvider = <T,>({ onTabSelect, children }: TabsContextProviderProps<T>) => {
  const [activeTabKey, setActiveTabKey] = useState<TabsContextProps<T>['activeTabKey']>(
    tabsContextParams.activeTabKey,
  );

  const settings = useMemo(
    () => ({
      activeTabKey,
      setActiveTabKey: (key: TabsContextProps<T>['activeTabKey']) => {
        setActiveTabKey(key);
        if (onTabSelect) onTabSelect(key);
      },
    }),
    [activeTabKey, onTabSelect],
  );

  return <TabsContext.Provider value={settings}>{children}</TabsContext.Provider>;
};

export default TabsContextProvider;
