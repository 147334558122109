export default {
  view: {
    oldConnectRoute: 'Old Connect Route Visited',
  },
  verify: {
    email: 'Onboarding Email Verify',
  },
  add: {
    company: 'Onboard Company Add',
    employee: 'Onboard Employee Add', // Manual-add flow: user has added at least one employee
    team: 'Onboard Team Add', // Manual-add flow: user has added at least 5 employees in the same department
    department: 'Onboard Department Add', // Manual-add flow: user added more than one department
  },
  connect: {
    hris: 'Onboard HRIS Connect',
  },
  skip: {
    hris: 'Onboard HRIS Skip',
  },
  complete: true, // Onboard Complete
  unconfirmed: {
    employeesBenchmarkPercentage: 'Onboard Employees Benchmark Percentage Unconfirmed',
  },
  benchmark: {
    employee: 'Onboard Employee Benchmark',
    employeesPercent: 'Onboard Employees Percent Benchmarked',
  },
};
