import { CaretRight, Gear } from '@phosphor-icons/react';
import classNames from 'classnames';
import { search } from 'country-js';
import { ReactNode, useRef, useState } from 'react';

import GLOBALS from 'js/config/globals';
import Button from 'js/design-system/Button/Button';
import { ButtonSize, ButtonVariant } from 'js/design-system/Button/types';
import Icon from 'js/design-system/Icon';
import Menu, { MenuItem } from 'js/design-system/Menu/Menu';
import Tooltip from 'js/design-system/Tooltip/Tooltip';
import { useClickOutside } from 'js/hooks/useClickOutside';
import { CurrencyCode } from 'js/utils/formatters/currency';

import { CompType } from '../types';

import styles from './ResultsHeader.module.scss';
import menuStyles from 'js/design-system/Menu/Menu.module.scss';

interface ResultsHeaderProps {
  tierText: string;
  levelText: string;
  tierTooltip?: ReactNode;
  selectedCurrency: string;
  onCurrencyChange?: (val: CurrencyCode) => void;
  selectedEquity?: CompType;
  onEquityChange?: (val: CompType) => void;
}

export const ResultsHeader = ({
  tierText,
  levelText,
  tierTooltip,
  selectedCurrency,
  onCurrencyChange,
  selectedEquity,
  onEquityChange,
}: ResultsHeaderProps) => {
  const equityOptions: MenuItem<CompType>[] = [
    {
      value: 'shares',
      label: 'Shares',
      default: selectedEquity === 'shares',
    },
    {
      value: 'percent',
      label: 'Percent Ownership',
      default: selectedEquity === 'percent',
    },
  ];

  const currencyOptions = GLOBALS.countryOptions
    .map(({ code }) => {
      const [countryInfo] = search(code);
      return countryInfo.currency;
    })
    .filter((val, index, arr) => {
      const firstIndex = arr.findIndex((findVal) => val.currencyCode === findVal.currencyCode);
      return index === firstIndex;
    })
    .map(({ currencyCode, currencySymbol, currencyName }) => ({
      value: currencyCode,
      label: `${currencyName}  -  ${currencySymbol}`,
      default: currencyCode === selectedCurrency,
    }));

  const ref = useRef<HTMLDivElement>(null);

  const [open, setOpen] = useState(false);
  const [currencyMenuOpen, setCurrencyMenuOpen] = useState(false);

  const onCurrencySelect = (val: string) => {
    if (onCurrencyChange) {
      onCurrencyChange(val as CurrencyCode);
    }
    setOpen(false);
  };

  const onEquitySelect = (val: CompType) => {
    if (onEquityChange) onEquityChange(val);
    setOpen(false);
  };

  useClickOutside(ref, () => !currencyMenuOpen && setOpen(false));

  return (
    <div className={styles.tiersAndLevels}>
      <div className={styles.left}>
        <Tooltip content={tierTooltip}>
          <span>{tierText}</span>
        </Tooltip>
        <div className={styles.circle} />
        <span>{levelText}</span>
      </div>

      {onCurrencyChange && (
        <div className={classNames(styles.right, menuStyles.menuContainer)} ref={ref}>
          <Button
            variant={ButtonVariant.Ghost}
            size={ButtonSize.Small}
            leftIcon={Gear}
            onClick={() => setOpen(!open)}
            className={classNames(menuStyles.triggerContainer, { [styles.open]: open })}
          >
            Settings
          </Button>

          <div
            className={classNames(styles.menu, menuStyles.menu, menuStyles.hasNested, {
              [menuStyles.open]: open,
            })}
          >
            <Menu
              trigger={
                <div className={menuStyles.item}>
                  <div>Currency: {selectedCurrency}</div>
                  <Icon icon={CaretRight} size={16} className={menuStyles.nestedIcon} />
                </div>
              }
              openOnHover
              menuItems={currencyOptions}
              onSelect={onCurrencySelect}
              placement="left-start"
              highlightSelectedItem
              filterable
              placeholder="Filter by currency name"
              menuContainerClassName={styles.subMenuContainer}
              onOpen={() => setCurrencyMenuOpen(true)}
              onClose={() => setCurrencyMenuOpen(false)}
            />

            {onEquityChange && (
              <Menu
                trigger={
                  <div className={menuStyles.item}>
                    <div>Equity as: {selectedEquity}</div>
                    <Icon icon={CaretRight} size={16} className={menuStyles.nestedIcon} />
                  </div>
                }
                openOnHover
                menuItems={equityOptions}
                onSelect={onEquitySelect}
                placement="left-start"
                highlightSelectedItem
                menuContainerClassName={styles.subMenuContainer}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};
