import { sentenceCase } from 'change-case';
import { toast } from 'react-toastify';

const formatError = (error) => {
  if (error?.constructor === Error) {
    return error.message;
  }
  if (error?.constructor === Object) {
    return Object.keys(error)
      .map((key) => `${sentenceCase(key)} ${error[key]}`)
      .join(', ');
  }
  if (Array.isArray(error)) {
    return error.join(', ');
  }
  return error;
};

const alertMessage = (type, body, options = {}) =>
  toast[type](formatError(body), { ...{ position: toast.POSITION.TOP_LEFT }, ...options });
const alertInfo = (body, options = {}) => alertMessage('info', body, options);
const alertSuccess = (body, options = {}) => alertMessage('success', body, options);
const alertError = (body, options = {}) => alertMessage('error', body, options);

export { formatError, alertSuccess, alertError, alertInfo };
