import { CaretRight, Plus } from '@phosphor-icons/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { groupUserToCompany } from 'js/analytics';
import { fetchCompanies } from 'js/api/companies';
import { alertError } from 'js/components-legacy/common/AlertMessage';
import Button from 'js/design-system/Button/Button';
import { ButtonSize, ButtonVariant } from 'js/design-system/Button/types';
import Icon from 'js/design-system/Icon';
import Menu, { MenuItem, MenuProps } from 'js/design-system/Menu/Menu';
import useAppSelector from 'js/hooks/useAppSelector';
import { isAnalystSelector } from 'js/selectors/currentUser';
import { Company } from 'types';

import styles from './CompanyMenu.module.scss';
import menuStyles from 'js/design-system/Menu/Menu.module.scss';

const getScenarioLink = (landingCompanyId?: number, landingScenarioId?: number) =>
  landingScenarioId
    ? `/scenarios/${landingScenarioId}`
    : `/companies/${landingCompanyId}/scenarios/new`;

interface CompanyMenuProps extends Pick<MenuProps<number>, 'onOpen' | 'onClose'> {
  company: Company;
}

const CompanyMenu = ({ company, onOpen, onClose }: CompanyMenuProps) => {
  const isAnalyst = useAppSelector(isAnalystSelector);
  const navigate = useNavigate();
  const [companyOptions, setCompanyOptions] = useState<MenuItem<number>[]>([]);

  useEffect(() => {
    fetchCompanies().then((res) => {
      if (res.success && res.data) {
        const options = res.data
          .sort((a, b) => (a.companyName.toUpperCase() > b.companyName.toUpperCase() ? 1 : -1))
          .map((c) => ({
            value: c.id,
            label: c.companyName,
            default: c.id === company.id,
            data: c,
          }));

        setCompanyOptions(options);
      } else {
        alertError(res.error);
      }
    });
  }, [company.id]);

  // Only Analysts or users with more than 1 company should see this component.
  if (!isAnalyst && companyOptions.length <= 1) return null;

  const navigateToCompany = (companyId: number) => {
    const companyOption = companyOptions.find((c) => c.value === companyId);
    const companyData = companyOption?.data as Company;

    groupUserToCompany(companyData);

    const { id, landingScenarioId } = companyData;
    const url = getScenarioLink(id, landingScenarioId);

    navigate(url);
  };

  return (
    <Menu
      trigger={
        <div className={menuStyles.item}>
          <div className={styles.triggerTextContainer}>
            <span>{company.companyName}</span>
            <span className={styles.triggerSubtext}>Change company</span>
          </div>
          <Icon icon={CaretRight} size={16} className={menuStyles.nestedIcon} />
        </div>
      }
      menuItems={companyOptions}
      onSelect={navigateToCompany}
      filterable
      placement="left-start"
      openOnHover
      actionButton={
        isAnalyst ? (
          <Button
            variant={ButtonVariant.Outlined}
            size={ButtonSize.Medium}
            leftIcon={Plus}
            onClick={() => navigate('/onboarding/company')}
          >
            New company
          </Button>
        ) : undefined
      }
      menuContainerClassName={styles.menuContainer}
      highlightSelectedItem
      onOpen={onOpen}
      onClose={onClose}
    />
  );
};

export default CompanyMenu;
