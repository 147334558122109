import { ForwardedRef, forwardRef } from 'react';

import { noop } from 'js/utils/common';

import TextFieldContainer from './TextFieldContainer';
import { TextFieldProps, TextFieldTypes } from './types';

const TextField = forwardRef(
  (
    {
      id,
      name,
      type = TextFieldTypes.Text,
      label,
      subLabel,
      labelPosition = 'left',
      className,
      labelClassName,
      placeholder,
      autoComplete = 'off',
      value,
      onChange = noop,
      onBlur = noop,
      onFocus = noop,
      onKeyDown = noop,
      onReset = noop,
      validation,
      wrapperClassName,
      ariaLabel,
      maxLength,
      disabled = false,
      readonly = false,
      required = false,
      dataTestId,
      helpText,
      alertBox,
      inputAppend,
      inputAppendWrapperClassName,
      inputPrepend,
      inputPrependWrapperClassName,
      resetable = false,
      hideError = false,
      tabIndex,
      showCounter = false,
      lowercase = false,
      trim = false,
      min,
    }: TextFieldProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    return (
      <TextFieldContainer
        id={id}
        name={name}
        disabled={disabled}
        readonly={readonly}
        label={label}
        subLabel={subLabel}
        labelPosition={labelPosition}
        value={value}
        onChange={onChange}
        onReset={onReset}
        validation={validation}
        className={className}
        wrapperClassName={wrapperClassName}
        helpText={helpText}
        alertBox={alertBox}
        inputPrepend={inputPrepend}
        inputAppendWrapperClassName={inputAppendWrapperClassName}
        inputAppend={inputAppend}
        inputPrependWrapperClassName={inputPrependWrapperClassName}
        required={required}
        resetable={resetable}
        hideError={hideError}
        labelClassName={labelClassName}
        maxLength={maxLength}
        showCounter={showCounter}
      >
        {(onInputChange, inputClassName, cssProperties, inputValue) => (
          <input
            ref={ref}
            id={id || name}
            type={type}
            className={inputClassName}
            style={cssProperties}
            value={inputValue === null ? undefined : inputValue}
            placeholder={placeholder}
            autoComplete={autoComplete}
            onChange={(event) => {
              let value = event.target.value || '';
              if (trim) {
                value = value.trim();
              }
              if (lowercase) {
                value = value.toLowerCase();
              }
              onInputChange(value);
            }}
            onBlur={onBlur}
            onFocus={onFocus}
            onKeyDown={onKeyDown}
            maxLength={maxLength}
            readOnly={readonly}
            disabled={disabled}
            aria-label={ariaLabel || (typeof label === 'string' && label) || placeholder}
            tabIndex={tabIndex}
            data-testid={dataTestId || name}
            min={min}
          />
        )}
      </TextFieldContainer>
    );
  },
);

TextField.displayName = 'TextField';

export default TextField;
