import { useQueryClient } from '@tanstack/react-query';

const useReactQueryCacheClear = () => {
  const queryClient = useQueryClient();

  return () => {
    queryClient.invalidateQueries();
    queryClient.clear();
  };
};

export default useReactQueryCacheClear;
