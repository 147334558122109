import { PromoStatusResponse } from 'types/promotion';

import api from './api';

export const applyPromo = async (code: string, companyId: number) => {
  const params = { code };
  const { success, data, error } = await api.post(`companies/${companyId}/promos/apply`, {
    params,
  });

  return { success, data, error: error || (!success ? 'Failed to apply promotion' : null) };
};

export const checkPromoStatus = async (code: string, companyId: number) => {
  const params = { code };
  const { success, data } = await api.post<PromoStatusResponse>(
    `companies/${companyId}/promos/status`,
    {
      params,
    },
  );

  if (success) return data;

  throw new Error('Failed to check promotion status.');
};
