import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Field from './Field';
import RawMultiSelect from './RawMultiSelect';
import ValidationWrapper from './ValidationWrapper';

import styles from './MultiSelect.module.scss';

const MultiSelect = (props) => {
  const {
    options,
    disabled,
    openUp,
    searchable,
    placeholder,
    displayedValue,
    allOption,
    className,
    ...rest
  } = props;

  return (
    <Field {...rest} type="select">
      {(input) => (
        <ValidationWrapper invalid={input.invalid}>
          <RawMultiSelect
            {...input}
            disabled={disabled}
            options={options}
            searchable={searchable}
            placeholder={placeholder}
            displayedValue={displayedValue}
            allOption={allOption}
            className={classNames(className, styles.dropdown, { [styles.openUp]: openUp })}
          />
        </ValidationWrapper>
      )}
    </Field>
  );
};

MultiSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.node,
  validate: PropTypes.func,
  inputPrepend: PropTypes.node,
  inputAppend: PropTypes.node,
  groupClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  hintClassName: PropTypes.string,
  feedbackClassName: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  preProcessValue: PropTypes.func,
  renderHint: PropTypes.func,
  disabled: PropTypes.bool,
  openUp: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
      disabled: PropTypes.bool,
    }),
  ).isRequired,
  searchable: PropTypes.bool,
  placeholder: PropTypes.string,
  displayedValue: PropTypes.func,
  allOption: PropTypes.instanceOf(Object),
};

MultiSelect.defaultProps = {
  label: null,
  validate: null,
  inputPrepend: null,
  inputAppend: null,
  groupClassName: null,
  inputClassName: null,
  hintClassName: null,
  feedbackClassName: null,
  className: null,
  onChange: null,
  preProcessValue: (value) => value,
  renderHint: null,
  disabled: false,
  openUp: false,
  searchable: false,
  placeholder: 'None Specified',
  displayedValue: (values) => values,
  allOption: null,
};

export default MultiSelect;
