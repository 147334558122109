import Auth from '@aws-amplify/auth';
import { nanoid } from 'nanoid';

import { logError } from 'js/utils/logger';

const LEVELS = {
  PRIVATE: 'private',
  PROTECTED: 'protected',
  PUBLIC: 'public',
  UPLOAD: 'upload',
  COMPANY: 'company',
};

async function s3Upload(file, level = LEVELS.PRIVATE, folder = '') {
  return Auth.currentAuthenticatedUser().then(async () => {
    const fileExtension = file.name.split('.').pop();
    const folderName = folder === '' ? '' : `${folder}/`;
    return import('@aws-amplify/storage')
      .then(({ Storage }) =>
        Storage.put(`${folderName}${nanoid()}.${fileExtension}`, file, {
          contentType: file.type,
          level,
        }),
      )
      .then(async (response) =>
        Auth.currentUserCredentials().then(async (credentials) => ({
          s3Path: `${level}/${credentials.identityId}/${response.key}`,
          filename: response.key,
        })),
      )
      .catch((err) => {
        logError(err);
      });
  });
}

export { LEVELS, s3Upload };
