import { TrafficCone } from '@phosphor-icons/react';
import { ElementType, ReactNode } from 'react';

import Icon from 'js/design-system/Icon';
import Text from 'js/design-system/Text/Text';

import styles from './EmptyState.module.scss';

type EmptyStateProps = {
  icon?: ElementType;
  heading: ReactNode;
  subHeading: ReactNode;
};

const EmptyState = ({ icon, heading, subHeading }: EmptyStateProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.container__inner}>
        <Icon
          icon={icon ?? TrafficCone}
          size={72}
          color="primary-gray-400"
          className={styles.icon}
        />
        <Text variant="text-heading-h5" className={styles.heading}>
          {heading}
        </Text>
        <Text color="platform-gray-700" className={styles.subheading}>
          {subHeading}
        </Text>
      </div>
    </div>
  );
};

export default EmptyState;
