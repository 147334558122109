import { CaretDown, CaretUp } from '@phosphor-icons/react';
import classNames from 'classnames';
import { KeyboardEvent, ReactNode, cloneElement, useEffect, useRef, useState } from 'react';

import ListNavigation from 'js/components-legacy/common/ListNavigation';
import { ButtonProps } from 'js/design-system/Button/types';
import { isButtonElement } from 'js/design-system/Menu/helpers';
import { useClickOutside } from 'js/hooks/useClickOutside';
import buttonize from 'js/utils/buttonize';
import { logError } from 'js/utils/logger';

import Icon from '../../Icon';
import { FieldProps } from '../ConnectedField/types';
import FieldContainer from '../FieldContainer';
import { TextField } from '../TextField';

import styles from './Select.module.scss';

const sortSticky = <T,>(a: SelectOption<T>, b: SelectOption<T>) => {
  if (a.sticky && !b.sticky) return -1;
  if (!a.sticky && b.sticky) return 1;
  return 0;
};

export type SelectXPosition = 'left' | 'right';
export type SelectYPosition = 'top' | 'bottom' | 'auto';

export type SelectOption<T> = {
  value: T;
  // A human-readable equivalent to value, used for display and filtering.
  // For example, if `value` is an employee ID, `label` would be the employee's name.
  label: string;
  // Allows for displaying options as HTML.
  // If provided, this will be used instead of `label` in the option list.
  // (However, `label` will still be used for filtering and will be shown in the trigger when the option is selected).
  displayLabel?: ReactNode;
  // If an option should be selected by default
  default?: boolean;
  // If an option should be disabled
  disabled?: boolean;
  // The sticky option will be sorted to the top of the list.
  sticky?: boolean;
  // A string used to extend filtering beyond just the label of the option.
  filterContext?: string;
};

export interface SelectProps<T> extends FieldProps<T> {
  options: SelectOption<T>[];
  positionX?: SelectXPosition;
  positionY?: SelectYPosition;
  menuClassName?: string;
  filterable?: boolean;
  actionButton?: React.ReactElement<ButtonProps>;
  // props for TextField
  placeholder?: string;
  helpText?: ReactNode;
  inputAppend?: ReactNode;
  inputPrepend?: ReactNode;
  onToggle?: () => void;
}

export const Select = <T extends number | string>({
  options,
  positionX = 'left',
  positionY = 'auto',
  menuClassName,
  filterable = true,
  actionButton,
  placeholder = 'Select',
  helpText,
  inputAppend,
  inputPrepend,
  onToggle,
  ...connectedFieldProps
}: SelectProps<T>) => {
  const ref = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [open, setOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [displayMenuTop, setDisplayMenuTop] = useState(false);
  // Keep track of both `selected` and `inputValue` so we can fall back to the last selected option if the user closes the listbox mid-filter.
  const [selected, setSelected] = useState(options.find((option) => option.default));
  const [inputValue, setInputValue] = useState(selected?.label);

  useEffect(() => {
    onToggle?.();
  }, [onToggle, open, filteredOptions.length]);

  useEffect(() => {
    setDisplayMenuTop(positionY === 'top');
  }, [positionY]);

  useEffect(() => {
    const selectedOption = options.find((option) => option.default);

    setSelected(selectedOption);
    setInputValue(selectedOption?.label);
    setFilteredOptions(options);
  }, [options]);

  const onItemClick = (option: SelectOption<T>) => {
    connectedFieldProps.onChange?.(option.value);

    setSelected(option);
    setInputValue(option.label);
    setOpen(false);
  };

  const onActionButtonClick = (e: React.MouseEvent<HTMLElement>) => {
    setOpen(false);
    const upstreamClick = actionButton?.props.onClick;
    if (upstreamClick) upstreamClick(e);
  };

  const filter = (val: string) => {
    setInputValue(val);
    const searchValue = val.trim().toLowerCase();
    const filteredOptions = options.filter((option) =>
      option.filterContext
        ? option.filterContext.toLowerCase().includes(searchValue)
        : option.label.toLowerCase().includes(searchValue),
    );

    setFilteredOptions(filteredOptions);
  };

  const handleMenuOpen = () => {
    if (connectedFieldProps.disabled) return;

    if (positionY === 'auto' && ref.current) {
      try {
        const spaceBelow = window.innerHeight - ref.current.getBoundingClientRect().bottom;
        const requiredHeight = ref.current.offsetHeight * 2;

        // Show the menu on top only if there isn't enough space to show at least 2 options
        setDisplayMenuTop(spaceBelow < requiredHeight);
      } catch (err) {
        logError('Select component positioning error', err);
      }
    }

    setOpen(!open);
  };

  const onClose = () => {
    if (open) {
      setOpen(false);
      setFilteredOptions(options);
      // On close, `inputValue` should always revert to `selected.label`.
      // If there was no prior selection, setting it to `undefined` is like "resetting" the control.
      setInputValue(selected?.label);
    }
  };

  const onKeyDown = (e: KeyboardEvent) => {
    if (e.shiftKey && e.key === 'Tab') {
      onClose();
    }
  };

  useClickOutside(ref, onClose);

  if (actionButton && !isButtonElement(actionButton)) {
    logError('`actionButton` must be a `Button` component');
    return null;
  }

  return (
    <FieldContainer
      name={connectedFieldProps.name}
      label={connectedFieldProps.label}
      subLabel={connectedFieldProps.subLabel}
      labelPosition={connectedFieldProps.labelPosition}
      required={connectedFieldProps.required}
      labelClassName={connectedFieldProps.labelClassName}
      className={connectedFieldProps.className}
    >
      <div className={styles.selectContainer} ref={ref}>
        <TextField
          onFocus={handleMenuOpen}
          onKeyDown={onKeyDown}
          name={connectedFieldProps.name}
          placeholder={placeholder}
          value={inputValue}
          inputPrepend={inputPrepend}
          inputAppend={
            inputAppend || (
              <Icon size={16} color="primary-gray-600" icon={open ? CaretUp : CaretDown} />
            )
          }
          inputAppendWrapperClassName={styles.appendIcon}
          className={classNames(styles.select, connectedFieldProps.wrapperClassName)}
          readonly={!filterable}
          onChange={(val) => filter(String(val))}
          validation={connectedFieldProps.validation}
          disabled={connectedFieldProps.disabled}
          helpText={helpText}
          ref={inputRef}
        />
        <ListNavigation items={filteredOptions} onClose={onClose}>
          {(itemsContainerRef: React.RefObject<HTMLDivElement>) => (
            <div
              style={!displayMenuTop ? { top: inputRef.current?.offsetHeight } : {}}
              className={classNames(styles.menu, menuClassName, {
                [styles.open]: open,
                [styles.top]: displayMenuTop,
                [styles.right]: positionX === 'right',
              })}
            >
              <div className={styles.itemsContainer} ref={itemsContainerRef}>
                {filteredOptions.length === 0 ? (
                  <div className={classNames(styles.option, styles.disabled)}>No results</div>
                ) : (
                  filteredOptions.sort(sortSticky).map((option, i) => (
                    <div
                      key={`${String(i)}-${option.value}`}
                      className={classNames({ [styles.sticky]: option.sticky })}
                    >
                      <div
                        key={String(option.value)}
                        className={classNames(styles.option, {
                          [styles.disabled]: option.disabled,
                          [styles.selected]: selected?.value === option.value,
                        })}
                        {...buttonize(() => onItemClick(option))}
                      >
                        {option.displayLabel || option.label}
                      </div>
                      {option.sticky && <div className={styles.divider} />}
                    </div>
                  ))
                )}
              </div>

              {actionButton && (
                <>
                  <div className={styles.divider} />
                  <div className={styles.actionButton}>
                    {cloneElement(actionButton, {
                      onClick: onActionButtonClick,
                    })}
                  </div>
                </>
              )}
            </div>
          )}
        </ListNavigation>
      </div>
    </FieldContainer>
  );
};
