import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';

import { FeatureFlags } from 'js/config/feature-flags';
import useAppSelector from 'js/hooks/useAppSelector';
import queries from 'js/queries';
import { currentUserSelector } from 'js/selectors/currentUser';
import { Ability, Company, UserRole, UserType } from 'types';

import useFeatureFlag from './useFeatureFlag';

interface UseAccessProps {
  company?: Company;
}

// TODO: Remove once we stop checking role names
const rolePermissions: Record<UserRole, Set<UserRole>> = {
  [UserRole.Analyst]: new Set([UserRole.Analyst]),
  [UserRole.Administrator]: new Set([UserRole.Analyst, UserRole.Administrator]),
  [UserRole.OrganizationLeader]: new Set([
    UserRole.Analyst,
    UserRole.Administrator,
    UserRole.OrganizationLeader,
  ]),
  [UserRole.TeamLeader]: new Set(Object.values(UserRole)),
  [UserRole.Recruiter]: new Set([
    UserRole.Analyst,
    UserRole.Administrator,
    UserRole.OrganizationLeader,
    UserRole.TeamLeader,
    UserRole.Recruiter,
  ]),
  [UserRole.Approver]: new Set(Object.values(UserRole)),
  [UserRole.RewardsViewer]: new Set(Object.values(UserRole)),
  [UserRole.Pending]: new Set(Object.values(UserRole)),
};

const useAccess = ({ company }: UseAccessProps) => {
  const user = useAppSelector(currentUserSelector);
  const { data: role } = useQuery({
    ...queries.currentUser.role(company?.id as number),
    enabled: !!company?.id,
  });
  const { enabled: selfServiceEnabled } = useFeatureFlag(FeatureFlags.selfService, company?.id);

  // TODO: Remove once we stop checking role names
  /**
   * @deprecated Deprecated in favor of `userHasAbility`
   */
  const userHasRolePermission = useCallback(
    (roleName: UserRole) => {
      if (!user) return false;
      if (user.userType === UserType.Analyst) return true;
      if (!role) return false;
      if (rolePermissions[roleName].has(role.name as UserRole)) return true;
      return false;
    },
    [user, role],
  );

  const userHasAbility = useCallback(
    (ability: Ability) => {
      if (!user) return false;
      if (user.userType === UserType.Analyst) return true;
      if (!role) return false;
      return !!role.abilities[ability];
    },
    [user, role],
  );

  // A role is only present if the company is present
  // However, a user might still have an analyst user type
  if (!user || (company && !role)) {
    return {
      useAccessReady: false,
    };
  }

  return {
    useAccessReady: true,
    adminConsoleAccessible: userHasRolePermission(UserRole.Administrator),
    analystConsoleAccessible: userHasRolePermission(UserRole.Analyst),
    purchaseAccessible: userHasRolePermission(UserRole.TeamLeader),
    upgradePageAccessible: userHasRolePermission(UserRole.TeamLeader) && selfServiceEnabled,
    settingScenarioAccessible: userHasRolePermission(UserRole.OrganizationLeader),
    syncScenarioAccessible: userHasRolePermission(UserRole.Administrator),
    newScenarioAccessible: userHasRolePermission(UserRole.OrganizationLeader),
    importScenarioAccessible: userHasRolePermission(UserRole.Administrator),
    geoAdjustmentAccessible: userHasRolePermission(UserRole.OrganizationLeader),
    shareScenarioAccessible: userHasRolePermission(UserRole.OrganizationLeader),
    editDepartmentAccessible: userHasRolePermission(UserRole.OrganizationLeader),
    newDepartmentAccessible: userHasRolePermission(UserRole.OrganizationLeader),
    finchBannerAccessible: userHasRolePermission(UserRole.Administrator),
    equityBannerAccessible: userHasRolePermission(UserRole.Administrator),
    upgradeBannerAccessible: userHasRolePermission(UserRole.OrganizationLeader),
    hiringEquityInfoAccessible: userHasRolePermission(UserRole.OrganizationLeader),
    editEquityAccessible: userHasRolePermission(UserRole.OrganizationLeader),
    sharedToAdminAccessible: userHasRolePermission(UserRole.Administrator),
    revokeScenarioUserAccessible: userHasRolePermission(UserRole.OrganizationLeader),
    nullReportToAccessible: userHasRolePermission(UserRole.OrganizationLeader),
    emptyDepartmentAccessible: userHasRolePermission(UserRole.OrganizationLeader),
    providerConnectAccessible: userHasRolePermission(UserRole.Administrator),
    updateRangeAccessible: userHasAbility(Ability.RangesUpdate),
    importRangeAccessible: userHasRolePermission(UserRole.Analyst),
    updateScenarioBenchmarkAccessible: userHasRolePermission(UserRole.Analyst),
    offerAccessible: userHasRolePermission(UserRole.Approver),
    createApprovalTemplateAccessible: userHasRolePermission(UserRole.OrganizationLeader),
    offerSettingsAccessible: userHasRolePermission(UserRole.OrganizationLeader),
    manageCompCyclesAccessible: userHasRolePermission(UserRole.Administrator),
    createOfferAccessible: userHasRolePermission(UserRole.Recruiter),
    totalRewardsUpgradePreviewAccessible: userHasAbility(Ability.EmployeesView),
    editEmployeesJobRolesAccessible: userHasAbility(Ability.EmployeesJobRolesEdit),
    viewAllJobRolesAccessible: userHasAbility(Ability.JobRolesViewAll),
    duplicateEmployeeAccessible: userHasAbility(Ability.EmployeesCreate),
    persistentBenchmarkingAccessible: userHasRolePermission(UserRole.OrganizationLeader),
    onboardMarketAccessible: userHasRolePermission(UserRole.OrganizationLeader),
  };
};

export default useAccess;
