/* eslint-disable @typescript-eslint/naming-convention */
import { PartialExcept, PlayBook, User } from 'types';

type ChatServiceUser = PartialExcept<User, 'firstName' | 'lastName' | 'email'> & {
  userId?: number;
  companyName?: string;
};

export type HSEventsLastActivity = {
  'open-change': {
    isOpen?: boolean;
    isUser?: boolean;
  };
};

type HSEventsLastActivityPayload = {
  type: keyof HSEventsLastActivity;
  data: HSEventsLastActivity['open-change'];
};

type HSConversationsWidgetRefreshProps = {
  openToNewThread?: boolean;
};

export type HSConversations = {
  clear: () => void;
  debug: () => void;
  off: () => void;
  on: () => void;
  resetAndReloadWidget: () => void;
  widget: {
    close: () => void;
    load: () => void;
    open: () => void;
    refresh: (props?: HSConversationsWidgetRefreshProps) => void;
    remove: () => void;
    status: () => { loaded: boolean; pending: boolean };
  };
};

const identifyUser = (user: ChatServiceUser) => {
  if (!user) return;
  // eslint-disable-next-line no-multi-assign
  const _hsq = (window._hsq = window._hsq || []);

  // Identify User
  _hsq.push([
    'identify',
    {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      ...(user.companyName && { company: user.companyName }),
    },
  ]);
};

const trackPageView = () => {
  // eslint-disable-next-line no-multi-assign
  const _hsq = (window._hsq = window._hsq || []);
  const currentPagePath = window.location.pathname;

  // Update page path
  _hsq.push(['setPath', currentPagePath]);

  // Track page view
  _hsq.push(['trackPageView']);
};

const observeHubSpotEvents = () => {
  // eslint-disable-next-line no-multi-assign
  const _hsela: HSEventsLastActivity = (window._hsEventsLastActivity =
    window._hsEventsLastActivity || {});
  const eventsToRecord = ['open-change'];

  if (Object.keys(_hsela).length === 0) {
    window.addEventListener('message', (event) => {
      if (event.origin === 'https://app.hubspot.com') {
        try {
          const { type, data }: HSEventsLastActivityPayload = JSON.parse(event.data);

          if (eventsToRecord.includes(type)) {
            _hsela[type] = data;
          }
        } catch (err) {
          // do nothing
        }
      }
    });
  }
};

const widgetOpen = () => {
  const checkWidgetOpen = setInterval(() => {
    if (window?._hsEventsLastActivity['open-change']?.isOpen) {
      clearInterval(checkWidgetOpen);
      return;
    }

    window?.HubSpotConversations?.widget.open();
  }, 1000);
};

const startInteraction = (playbook: PlayBook, user: ChatServiceUser) => {
  const playbookParam = playbook || PlayBook.ContactUs;

  if (!window.HubSpotConversations) {
    return false;
  }

  // Identify User
  identifyUser(user);

  // Track page view
  trackPageView();

  // Observe Hub spot conversations events
  observeHubSpotEvents();

  // Prevent pushing same playbook browser history state
  if (!window.location.search.includes(playbookParam)) {
    window.history.pushState({}, playbookParam, `?${playbookParam}=true`);
  }

  const { loaded } = window.HubSpotConversations.widget.status();
  if (loaded) {
    if (window?._hsActivePlaybook !== playbook) {
      window.HubSpotConversations.widget.refresh({ openToNewThread: true });
    }
  } else {
    window.HubSpotConversations.widget.load();
  }

  // Open chat window
  widgetOpen();

  // Update current chat playbook
  window._hsActivePlaybook = playbook;

  return true;
};

const refreshChatbot = (user: ChatServiceUser) => {
  if (!window.HubSpotConversations) {
    return false;
  }

  // Identify User
  identifyUser(user);

  // Track page view
  trackPageView();

  // Observe Hub spot conversations events
  observeHubSpotEvents();

  const { loaded } = window.HubSpotConversations.widget.status();
  if (loaded && window?._hsEventsLastActivity['open-change']?.isOpen === false) {
    window.HubSpotConversations.widget.refresh();
  } else if (!loaded) {
    window.HubSpotConversations.widget.load();
  }

  return true;
};

export { startInteraction, refreshChatbot };
