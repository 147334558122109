import { createSelector } from 'reselect';

import { navigationItems } from 'js/config/globals-ts';
import { ScenarioViewState } from 'js/reducers/scenarioView';
import { getCashCompType, getEquityCompType } from 'js/services/compensations/compService';
import { WORK_PERIOD } from 'js/services/employeeFilterService';
import {
  AppState,
  CompensationType,
  EmployeeFilters,
  MarketEmployeeSortBy,
  RangesEmployeeSortBy,
} from 'types';

type ScenarioView = ScenarioViewState & {
  departmentFilter?: EmployeeFilters['department'];
  levelFilter?: EmployeeFilters['level'];
  ethnicityFilter?: EmployeeFilters['ethnicity'];
  genderFilter?: EmployeeFilters['gender'];
  rangeGroupFilter?: EmployeeFilters['rangeGroup'];
  midPercentileFilter?: EmployeeFilters['midPercentile'];
  percentileGroupFilter?: EmployeeFilters['percentileGroup'];
  midRangePositionFilter?: EmployeeFilters['midRangePosition'];
  geoTierFilter?: EmployeeFilters['geoTier'];
  workPeriodFilter?: string[];
  employeeSort?: MarketEmployeeSortBy | RangesEmployeeSortBy;
  cashCompType: CompensationType | null;
  equityCompType: CompensationType | null;
};

const currentScenarioViewSelector = (state: AppState): ScenarioViewState => state.scenarioView;

const scenarioViewSelector = createSelector(
  currentScenarioViewSelector,
  (scenarioView: ScenarioViewState): ScenarioView => {
    if (!scenarioView) {
      return scenarioView;
    }

    const { mode, hiddenEmployeeTypes, cashDisplayType, equityDisplayType } = scenarioView;

    const employeeFilters = scenarioView.employeeFilters[mode];
    const isHiring = mode === navigationItems.hiring.name;
    const workPeriodFilter = [WORK_PERIOD.current];
    if (isHiring || !hiddenEmployeeTypes.includes('future'))
      workPeriodFilter.push(WORK_PERIOD.future);
    if (isHiring || !hiddenEmployeeTypes.includes('terminated'))
      workPeriodFilter.push(WORK_PERIOD.past);

    return {
      ...scenarioView,
      departmentFilter: employeeFilters.department,
      levelFilter: employeeFilters.level,
      ethnicityFilter: employeeFilters.ethnicity,
      genderFilter: employeeFilters.gender,
      rangeGroupFilter: employeeFilters.rangeGroup,
      midPercentileFilter: employeeFilters.midPercentile,
      percentileGroupFilter: employeeFilters.percentileGroup,
      midRangePositionFilter: employeeFilters.midRangePosition,
      geoTierFilter: employeeFilters.geoTier,
      workPeriodFilter,
      employeeSort: scenarioView.employeeSortBy[mode],
      cashCompType: getCashCompType(cashDisplayType),
      equityCompType: getEquityCompType(equityDisplayType),
    };
  },
);

export default scenarioViewSelector;
