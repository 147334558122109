import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { receiveScenario } from 'js/actions/scenarios';
import { disableSpinner, enableSpinner } from 'js/actions/spinners';
import { updateScenario as requestUpdateScenario } from 'js/api/scenarios';
import { alertError } from 'js/components-legacy/common/AlertMessage';
import queries from 'js/queries';
import { Scenario } from 'types';

import useAppDispatch from './useAppDispatch';

interface UseUpdateScenarioProps {
  scenarioId: number;
}

interface UpdateScenarioOptions {
  onAfterSuccess?: () => void;
  skipRefetchRelatedQueries?: boolean;
}

const useUpdateScenario = ({ scenarioId }: UseUpdateScenarioProps) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const updateScenarioMutation = useMutation(async (updateScenarioParams: Partial<Scenario>) => {
    const updatedScenario = await requestUpdateScenario(scenarioId, updateScenarioParams);

    return updatedScenario;
  });

  const updateScenario = useCallback(
    async (updateScenarioParams: Partial<Scenario>, options?: UpdateScenarioOptions) => {
      dispatch(enableSpinner());

      return updateScenarioMutation.mutateAsync(updateScenarioParams, {
        onSuccess: (scenario) => {
          if (!options?.skipRefetchRelatedQueries) {
            queryClient.invalidateQueries(queries.scenarios.detail(scenario.id));
          }

          dispatch(receiveScenario(scenario));
          options?.onAfterSuccess?.();
        },
        onError: (error) => {
          alertError(error);
        },
        onSettled: () => {
          dispatch(disableSpinner());
        },
      });
    },
    [dispatch, queryClient, updateScenarioMutation],
  );

  return { updateScenarioMutation, updateScenario };
};

export default useUpdateScenario;
