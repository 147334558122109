import { CONFIG as NUMBERS_CONFIG } from 'js/config/numbers';

export const isValidNumber = (input: unknown) =>
  typeof input === 'number' && !Number.isNaN(input) && Number.isFinite(input);

type RoundValueOptions = {
  to?: number;
  mode?: 'ceil' | 'round' | 'floor';
};

export const round = (value: number, options: RoundValueOptions = {}) => {
  if (value === 0) return 0;
  const isNegative = value < 0;
  const absValue = Math.abs(value);
  const { to = NUMBERS_CONFIG.to, mode = NUMBERS_CONFIG.mode } = options;

  if (!isValidNumber(to)) return value;

  const roundedValue = Number((Math[mode](absValue / to) * to).toPrecision(15));
  // Handles negative zero
  if (isNegative && roundedValue !== 0) {
    return -roundedValue;
  }

  return roundedValue;
};

export function calculatePercentage(value: number, total: number, to = 0.1) {
  return total ? round((100 * value) / total, { to }) : 0;
}

export function getMeanRaw(arr: number[]) {
  if (arr.length === 0) {
    return null;
  }
  const total = arr.reduce((accum, value) => accum + value, 0);
  return total / arr.length;
}

export function getMean(arr: number[]) {
  const mean = getMeanRaw(arr);
  return mean !== null ? round(mean) : null;
}

export function getMedian(arr: number[]) {
  if (!arr.length) {
    return null;
  }

  arr.sort((a, b) => a - b);

  const mid = arr.length / 2;
  return mid % 1 ? arr[mid - 0.5] : (arr[mid - 1] + arr[mid]) / 2;
}

export function getPercentageDiff(v1: number, v2: number) {
  if (v2 || v2 === 0) {
    return ((v2 - v1) / v1) * 100;
  }
  return 0;
}

export function getSum(arr: number[]) {
  return arr.reduce((sum, value) => sum + value, 0);
}

export const getSuffix = (num: number) => {
  const englishOrdinalRules = new Intl.PluralRules('en', { type: 'ordinal' });

  const suffixes: Record<string, string> = {
    one: 'st',
    two: 'nd',
    few: 'rd',
    other: 'th',
  };

  const category = englishOrdinalRules.select(num);
  const suffix = suffixes[category];
  return suffix;
};

export const getNumberWithOrdinal = (number: number) => number + getSuffix(number);
