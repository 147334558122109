import { User, Permission } from 'types';

import api from '../api';

export const fetchAnalysts = async () => {
  const { success, data } = await api.get<User[]>('/analyst/users', {
    urlParams: { user_type: 'analyst' },
  });
  if (success) {
    return data;
  }

  throw new Error('Failed to load users');
};

export const fetchAllUsers = async (query: string) => {
  const { success, data } = await api.get<User[]>('/analyst/users', {
    urlParams: { query },
  });
  if (success) return data;

  throw new Error('Failed to load users');
};

export const createPermissions = async (
  userId: number,
  companyId: number,
  scenarioIds: number[] = [],
) => {
  const { success, data } = await api.post<Permission[]>('/analyst/permissions', {
    params: {
      userId,
      companyId,
      scenarioIds,
    },
  });
  if (success) {
    return data;
  }

  throw new Error('Failed to create permissions');
};
