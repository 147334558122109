/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable react-hooks/exhaustive-deps */
import classnames from 'classnames';
import { path } from 'ramda';
import { FormEvent, useEffect, useState, Suspense } from 'react';
import {
  AutosuggestPropsBase,
  ChangeEvent,
  RenderInputComponentProps,
  SuggestionSelectedEventData,
  GetSuggestionValue,
} from 'react-autosuggest';
import FormControl, { FormControlProps } from 'react-bootstrap/FormControl';
import { useFormState } from 'react-final-form';
import { FormFieldProps } from 'types/form';

import lazyImport from 'js/utils/lazy-import';

import Field from './Field';
import ValidationWrapper from './ValidationWrapper';

import styles from './AutosuggestSelect.module.scss';

const Autosuggest = lazyImport(() => import('react-autosuggest'));

const defaultGetSuggestions = <TOption,>(
  value: string,
  getSuggestionValue: (option: TOption) => string,
  options: TOption[] = [],
  appendOption?: (value: string) => TOption,
): TOption[] => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  const filteredOptions: TOption[] =
    inputLength === 0
      ? options
      : options.filter((option) => {
          const optionValue = getSuggestionValue(option);

          if (optionValue.toLowerCase().slice(0, inputLength) === inputValue) {
            return true;
          }
          if (optionValue.toLowerCase().includes(inputValue)) {
            return true;
          }

          const splitTitle = optionValue.split(' ');
          for (let i = 0; i < splitTitle.length; i += 1) {
            if (splitTitle[i].toLowerCase().slice(0, inputLength) === inputValue) {
              return true;
            }
          }

          return false;
        });

  return appendOption && value ? [...filteredOptions, appendOption(value)] : filteredOptions;
};

interface AutosuggestSelectProps<TOption>
  extends FormFieldProps<string>,
    Omit<
      AutosuggestPropsBase<TOption>,
      'getSuggestionValue' | 'inputProps' | 'onSuggestionsFetchRequested'
    > {
  customTheme?: Record<string, string>;
  options?: TOption[];
  optionMap?: any;
  appendOption?: (value: string) => TOption;
  hasClearBtn?: boolean;
  forceSelect?: boolean;
  getSuggestionValue?: GetSuggestionValue<TOption>;
  getSuggestions?: <TOption>(
    value: string,
    getSuggestionValue: (option: TOption) => string,
    options: TOption[],
    appendOption?: (value: string) => TOption,
  ) => TOption[] | Promise<TOption[]>;
}

const AutosuggestSelect = <TOption,>({
  customTheme,
  options = [],
  optionMap = {},
  renderSuggestion = (suggestion) => <>{suggestion}</>,
  disabled = false,
  placeholder,
  appendOption,
  getSuggestionValue = (option) => String(option),
  hasClearBtn = true,
  forceSelect = false,
  renderInputComponent,
  getSuggestions = defaultGetSuggestions,
  ...props
}: AutosuggestSelectProps<TOption>) => {
  const { values } = useFormState();
  const [searchValue, setSearchValue] = useState<string>('');
  const [suggestions, setSuggestions] = useState<TOption[]>([]);

  useEffect(() => {
    const inputValue = path(props.name.split(/[.[\]]+/), values) || '';

    if (['reportsToId', 'locationId'].includes(props.name)) {
      const option = optionMap.find((e: any) => e.id === inputValue);
      setSearchValue(option?.name || '');
    } else {
      setSearchValue(String(inputValue));
    }
  }, [values, props.name]);

  const theme = customTheme
    ? Object.keys(styles).reduce((styleValues: { [key: string]: string }, key) => {
        styleValues[key] = `${styles[key]} ${customTheme[key] || ''}`;
        return styleValues;
      }, {})
    : styles;

  const onSuggestionsFetchRequested = async ({ value = '' }) => {
    const newSuggestions = await getSuggestions(value, getSuggestionValue, options, appendOption);
    setSuggestions(newSuggestions);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const shouldRenderSuggestions = () => true;

  return (
    <Field {...props}>
      {({ invalid, ...input }) => {
        const handleChange = (newValue: string) => {
          let value = newValue?.trim();
          if (forceSelect && options.every((o) => typeof o !== 'string' || o !== value)) {
            ({ value } = input);
          }
          input.onBlur();
          input.onChange(value);
        };

        const inputProps = {
          value: searchValue,
          spellCheck: false,
          disabled,
          placeholder,
          onChange: (_: FormEvent<HTMLElement>, { newValue }: ChangeEvent) => {
            setSearchValue(newValue);
          },
          onBlur: () => {
            handleChange(searchValue);
          },
        };

        return (
          <ValidationWrapper invalid={invalid}>
            <Suspense fallback={<></>}>
              <Autosuggest
                theme={theme}
                onSuggestionSelected={(
                  _: FormEvent<unknown>,
                  { suggestionValue }: SuggestionSelectedEventData<TOption>,
                ) => {
                  handleChange(suggestionValue);
                }}
                suggestions={suggestions}
                shouldRenderSuggestions={shouldRenderSuggestions}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
                renderInputComponent={
                  renderInputComponent
                    ? (inputProps: Omit<RenderInputComponentProps, 'size'>) =>
                        renderInputComponent({
                          isInvalid: invalid,
                          ...inputProps,
                        } as RenderInputComponentProps)
                    : (inputProps: Omit<RenderInputComponentProps, 'size'>) => (
                        <div className={classnames(styles.inputContainer, styles.inputClassName)}>
                          <FormControl {...(inputProps as FormControlProps)} isInvalid={invalid} />
                          {hasClearBtn && searchValue && (
                            <button
                              type="button"
                              className={styles.clearBtn}
                              data-testid="clear-autosuggest"
                              onClick={() => {
                                handleChange('');
                              }}
                              disabled={disabled}
                            >
                              &times;
                            </button>
                          )}
                        </div>
                      )
                }
              />
            </Suspense>
          </ValidationWrapper>
        );
      }}
    </Field>
  );
};

export default AutosuggestSelect;
