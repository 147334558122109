import TabSliderContent from './TabSliderContent';
import { TabsContextProvider, TabsContextProviderProps } from './context';
import { TabSliderContentProps } from './types';

interface TabSliderProps<T>
  extends TabSliderContentProps<T>,
    Pick<TabsContextProviderProps<T>, 'onTabSelect'> {}

const TabSlider = <T,>({ onTabSelect, ...props }: TabSliderProps<T>) => {
  return (
    <TabsContextProvider onTabSelect={onTabSelect}>
      <TabSliderContent {...props} />
    </TabsContextProvider>
  );
};

export default TabSlider;
