import { useQueryClient, useMutation } from '@tanstack/react-query';

import { createGeoTier, deleteGeoTier, updateGeoTier, GeoTierParams } from 'js/api/geo-strategy';
import { alertError } from 'js/components-legacy/common/AlertMessage';
import queries from 'js/queries';

export const useCreatePayTier = (scenarioId: number) => {
  const queryClient = useQueryClient();
  return useMutation((attrs: GeoTierParams) => createGeoTier(scenarioId, attrs), {
    onSuccess: () => {
      queryClient.invalidateQueries(queries.scenarios.detail(scenarioId)._ctx.payTiers);
      queryClient.invalidateQueries(queries.scenarios.detail(scenarioId)._ctx.unassignedLocations);
    },
    onError: (error) => {
      alertError(error);
    },
  });
};

export const useUpdatePayTier = (scenarioId: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ id, attrs }: { id: number; attrs: GeoTierParams }) => updateGeoTier(id, attrs),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queries.scenarios.detail(scenarioId)._ctx.payTiers);
        queryClient.invalidateQueries(
          queries.scenarios.detail(scenarioId)._ctx.unassignedLocations,
        );
      },
      onError: (error) => {
        alertError(error);
      },
    },
  );
};

export const useDeletePayTier = (scenarioId: number) => {
  const queryClient = useQueryClient();
  return useMutation(deleteGeoTier, {
    onSuccess: () => {
      queryClient.invalidateQueries(queries.scenarios.detail(scenarioId)._ctx.payTiers);
      queryClient.invalidateQueries(queries.scenarios.detail(scenarioId)._ctx.unassignedLocations);
      queryClient.invalidateQueries(queries.scenarios.detail(scenarioId)._ctx.jobAreaOverrides);
      queryClient.invalidateQueries(queries.scenarios.detail(scenarioId)._ctx.jobTypeOverrides);
    },
    onError: (error) => {
      alertError(error);
    },
  });
};
