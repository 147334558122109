import { createQueryKeys } from '@lukemorales/query-key-factory';

import { fetchApprovalTemplates } from 'js/api/approval-template';
import { fetchBenefits } from 'js/api/benefits';
import { fetchEmployeeCount } from 'js/api/employeeCount';
import { fetchAllEmployees } from 'js/api/employees';
import { fetchExternalSyncs } from 'js/api/externalSyncs';
import {
  fetchJobAreaOverrides,
  fetchJobAreaOverridePreviews,
  fetchJobTypeOverrides,
  fetchJobTypeOverridePreviews,
  fetchUnassignedLocations,
  fetchGeoTiers,
  fetchTemplatePreview,
  fetchDefaultPayTier,
} from 'js/api/geo-strategy';
import { fetchJobLevels } from 'js/api/job-levels';
import { fetchJobTypeLevels } from 'js/api/job-type-levels';
import { fetchScenarioJobRoles } from 'js/api/jobRoles';
import {
  fetchDefaultMarketDataLookup,
  fetchPopularSearchesMarketDataLookup,
} from 'js/api/marketDataLookup';
import { fetchPayMix } from 'js/api/payMix';
import { fetchScenarioNotifications } from 'js/api/scenarioNotifications';
import { fetchStakeholderSecurities } from 'js/api/stakeholderSecurities';
import { fetchStrategyTemplates } from 'js/api/strategyTemplates';
import { StrategyType } from 'types';

const scenarios = createQueryKeys('scenarios', {
  detail: (id: number) => ({
    queryKey: [id],
    contextQueries: {
      approvalTemplates: {
        queryKey: null,
        queryFn: () => fetchApprovalTemplates(id),
      },
      benefits: {
        queryKey: null,
        queryFn: () => fetchBenefits(id),
      },
      employees: (visibleOnly = true) => ({
        queryKey: [visibleOnly],
        queryFn: () => fetchAllEmployees(id, visibleOnly),
      }),
      employeeCount: {
        queryKey: null,
        queryFn: () => fetchEmployeeCount(id),
      },
      lastEmployeesSync: {
        queryKey: null,
        queryFn: async () => {
          const externalSyncs = await fetchExternalSyncs({
            scenarioId: id,
            filters: { data_type: 'employees' },
            pagination: { order: 'started_at DESC', limit: 1 },
          });
          return externalSyncs?.[0] || null;
        },
      },
      notifications: {
        queryKey: null,
        queryFn: () => fetchScenarioNotifications(id),
      },
      payTiers: {
        queryKey: null,
        queryFn: () => fetchGeoTiers(id),
      },
      payMix: (jobRoleId: number) => ({
        queryKey: [jobRoleId],
        queryFn: () => fetchPayMix(id, jobRoleId),
      }),
      defaultMarketDataLookup: {
        queryKey: null,
        queryFn: () => fetchDefaultMarketDataLookup({ scenarioId: id }),
      },
      popularSearchesMarketDataLookup: {
        queryKey: null,
        queryFn: () => fetchPopularSearchesMarketDataLookup({ scenarioId: id }),
      },
      defaultPayTier: {
        queryKey: null,
        queryFn: () => fetchDefaultPayTier(id),
      },
      previewTiers: (previewTemplateId: number) => ({
        queryKey: [previewTemplateId],
        queryFn: () => fetchTemplatePreview(id, previewTemplateId),
      }),
      jobAreaOverrides: {
        queryKey: null,
        queryFn: () => fetchJobAreaOverrides(id),
      },
      jobAreaOverridePreviews: (previewTemplateId: number) => ({
        queryKey: [previewTemplateId],
        queryFn: () => fetchJobAreaOverridePreviews(id, previewTemplateId),
      }),
      jobTypeOverrides: {
        queryKey: null,
        queryFn: () => fetchJobTypeOverrides(id),
      },
      jobTypeOverridePreviews: (previewTemplateId: number) => ({
        queryKey: [previewTemplateId],
        queryFn: () => fetchJobTypeOverridePreviews(id, previewTemplateId),
      }),
      unassignedLocations: {
        queryKey: null,
        queryFn: () => fetchUnassignedLocations(id),
      },
      geoStrategyTemplates: {
        queryKey: null,
        queryFn: () => fetchStrategyTemplates(id, { strategyType: StrategyType.Geo }),
      },
      stakeholderSecurities: {
        queryKey: null,
        queryFn: () => fetchStakeholderSecurities({ scenarioId: id }),
      },
      jobRoles: (filters: { employeeJobTitle: string | null }) => ({
        queryKey: [filters],
        queryFn: () => fetchScenarioJobRoles(id, filters),
      }),
      jobLevels: (filter: { jobTypeId?: number } = {}) => ({
        queryKey: [filter],
        queryFn: () => fetchJobLevels(id, filter),
      }),
      jobTypeLevels: {
        queryKey: null,
        queryFn: () => fetchJobTypeLevels(id),
      },
    },
  }),
});

export default scenarios;
