import { useSearchParams } from 'react-router-dom';

import useNumberParams from 'js/hooks/useNumberParams';
import { CompMode } from 'types';

import OverLimitEmployeeBanner from './OverLimitEmployeeBanner';
import OverLimitScenarioBanner from './OverLimitScenarioBanner';

const OverLimitBanner = () => {
  const { employeeId, scenarioId } = useNumberParams();
  const [searchParams] = useSearchParams();
  const modeParam = (searchParams.get('mode') || CompMode.Market) as CompMode;

  if (employeeId) {
    return (
      <OverLimitEmployeeBanner employeeId={employeeId} scenarioId={scenarioId} mode={modeParam} />
    );
  }

  if (scenarioId) {
    return <OverLimitScenarioBanner scenarioId={scenarioId} />;
  }

  return null;
};

export default OverLimitBanner;
