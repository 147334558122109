// import classNames from 'classnames';
import { Lightning } from '@phosphor-icons/react';
import { useNavigate } from 'react-router-dom';

import Button from 'js/design-system/Button/Button';
import { ButtonSize, ButtonVariant } from 'js/design-system/Button/types';
import useAccess from 'js/hooks/useAccess';
import useAppSelector from 'js/hooks/useAppSelector';
import useNumberParams from 'js/hooks/useNumberParams';
import { getById as companySelector } from 'js/selectors/companies';
import { currentUserSelector } from 'js/selectors/currentUser';
import { getById as scenarioSelector } from 'js/selectors/scenarios';
import hrisProviderNameMapping from 'js/utils/hris-providers';
import { ScenarioType } from 'types';

import styles from './DemoBanner.module.scss';

const DemoBanner = () => {
  const navigate = useNavigate();
  const { scenarioId } = useNumberParams();
  const scenario = useAppSelector((state) => scenarioSelector(state, scenarioId));
  const company = useAppSelector((state) => companySelector(state, scenario?.companyId));
  const { providerConnectAccessible } = useAccess({ company });
  const showHRISConnection = !company?.hasCashConnection && providerConnectAccessible;
  const user = useAppSelector(currentUserSelector);
  const hrisProviderKey = user?.hrisProvider;

  let hrisProviderName;

  if (hrisProviderKey) {
    const hrisProviderInfo = hrisProviderNameMapping[hrisProviderKey];
    hrisProviderName = hrisProviderInfo.name;
  }

  const goToSyncHRIS = () => {
    navigate(`/companies/${company?.id}/hris`);
  };

  if (scenario?.scenarioType !== ScenarioType.Demo) return null;

  return (
    <div className={styles.demoBannerBackground}>
      <div className="container-xl">
        <div className={styles.demoBannerWrap}>
          <div className={styles.columnOne} />
          <div className={styles.columnTwo}>
            <div className={styles.header}>
              You are currently viewing the Dunder Mifflin Example Company.
            </div>
            {showHRISConnection ? (
              <div className={styles.text}>
                Connect {hrisProviderName || 'your HRIS'} to see how your company compares to the
                market.
              </div>
            ) : null}
          </div>

          <div className={styles.columnThree}>
            {showHRISConnection ? (
              <Button
                variant={ButtonVariant.Filled}
                size={ButtonSize.Medium}
                leftIcon={Lightning}
                className={styles.cta}
                onClick={() => goToSyncHRIS()}
              >
                Connect {hrisProviderName || 'HRIS'}
              </Button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DemoBanner;
