import { WarningCircle, X } from '@phosphor-icons/react';
import classNames from 'classnames';
import { useMemo, useRef, useLayoutEffect, useState, useEffect, CSSProperties } from 'react';

import FieldContainer from 'js/design-system/Form/FieldContainer';
import Icon from 'js/design-system/Icon';
import useElementSize from 'js/hooks/useElementSize';
import buttonize from 'js/utils/buttonize';
import { noop } from 'js/utils/common';
import { isEmpty } from 'js/utils/value';

import { TextFieldProps, TextFieldValue } from './types';

import styles from './TextFieldContainer.module.scss';

interface TextFieldContainerProps extends Omit<TextFieldProps, 'type'> {
  children: (
    onInputChange: (value: TextFieldValue) => void,
    className: string,
    cssProperties: CSSProperties | undefined,
    inputValue?: TextFieldValue,
  ) => React.ReactNode;
}

const TextFieldContainer = ({
  children,
  id,
  name,
  className,
  disabled,
  readonly,
  label,
  subLabel,
  labelPosition = 'left',
  labelClassName,
  value,
  onChange = noop,
  onReset = noop,
  validation,
  wrapperClassName,
  helpText,
  alertBox,
  inputAppend,
  inputAppendWrapperClassName,
  inputPrepend,
  inputPrependWrapperClassName,
  maxLength,
  required = false,
  resetable = false,
  hideError = false,
  showCounter = false,
}: TextFieldContainerProps) => {
  const inputFieldItemsLeftRef = useRef<HTMLDivElement>(null);
  const inputFieldItemsRightRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const containerSize = useElementSize(containerRef);
  const [inputItemsPadding, setInputItemsPadding] = useState({});
  const normalizeValue = (value: TextFieldContainerProps['value']) =>
    value || value === 0 ? value : '';
  const [inputValue, setInputValue] = useState<TextFieldValue>(normalizeValue(value));
  const validationClass = useMemo(() => {
    if (!validation) return '';
    const { status } = validation;

    return status ? `inputField--${status}` : '';
  }, [validation]);

  const onInputChange = (value: TextFieldValue) => {
    setInputValue(value);
    onChange(value);
  };

  const onInputReset = (event: React.SyntheticEvent<Element>) => {
    event.stopPropagation();
    setInputValue('');
    onChange('');
    onReset();
  };

  useEffect(() => {
    setInputValue(normalizeValue(value));
  }, [value]);

  const resetElement = (
    <span
      {...buttonize(onInputReset)}
      className={classNames('d-inline-flex', {
        'd-none': isEmpty(inputValue),
      })}
    >
      <Icon icon={X} size={16} color="primary-gray-500" />
    </span>
  );

  const subTextElement = useMemo(() => {
    if (validation?.text && hideError) return null;

    if (validation?.text) {
      return (
        <div
          className={classNames(styles.inputField__subText, {
            [styles.inputField__error]: validation?.status === 'error' && validation.text,
          })}
        >
          {validation.text}
        </div>
      );
    }

    if (helpText || (maxLength && showCounter)) {
      return (
        <div className={styles.inputField__subText}>
          {helpText}
          {!!maxLength && showCounter && (
            <div className={styles.inputField__subText__counterText}>
              {(inputValue || '').toString().length}/{maxLength}
            </div>
          )}
        </div>
      );
    }

    return null;
  }, [
    helpText,
    hideError,
    maxLength,
    validation?.status,
    validation?.text,
    inputValue,
    showCounter,
  ]);

  useLayoutEffect(() => {
    const { offsetWidth: leftWidth = 0, offsetLeft: inputOffset = 0 } =
      (inputFieldItemsLeftRef.current as HTMLDivElement) || {};
    const { offsetWidth: rightWidth = 0 } =
      (inputFieldItemsRightRef.current as HTMLDivElement) || {};

    const extraPaddingLeft = leftWidth === 0 ? 0 : inputOffset / 2;
    const extraPaddingRight = rightWidth === 0 ? 0 : inputOffset / 2;

    const padding = {
      paddingLeft: inputOffset + leftWidth + extraPaddingLeft,
      paddingRight: inputOffset + rightWidth + extraPaddingRight,
    };

    setInputItemsPadding(padding);
  }, [inputFieldItemsLeftRef, inputFieldItemsRightRef, inputAppend, inputPrepend, containerSize]);

  return (
    <FieldContainer
      name={id || name}
      label={label}
      subLabel={subLabel}
      labelPosition={labelPosition}
      className={wrapperClassName}
      labelClassName={labelClassName}
      required={required}
    >
      <div className={classNames(styles.inputWrapper, styles[validationClass])} ref={containerRef}>
        <div className={styles.inputWrapperContent}>
          <div
            className={classNames(styles.inputField__itemsLeft, inputPrependWrapperClassName)}
            ref={inputFieldItemsLeftRef}
          >
            {inputPrepend}
          </div>

          <div
            className={classNames(styles.inputField__itemsRight, inputAppendWrapperClassName)}
            ref={inputFieldItemsRightRef}
          >
            {resetable && resetElement}
            {inputAppend}
          </div>

          {children(
            onInputChange,
            classNames(className, styles.inputField, {
              [styles['inputField--readonly']]: readonly,
              [styles['inputField--disabled']]: disabled,
              [styles['inputField--error']]: validation?.status === 'error',
            }),
            { ...inputItemsPadding },
            inputValue,
          )}
        </div>

        {subTextElement}

        {alertBox && (
          <div
            className={classNames(styles.inputField__alertBox, {
              [styles['inputField__alertBox--withIcon']]: !!alertBox?.showIcon,
            })}
          >
            {alertBox?.showIcon && (
              <Icon icon={WarningCircle} className={styles.inputField__alertBoxIcon} size={16} />
            )}
            {alertBox?.text && (
              <span className={styles.inputField__alertBoxText}>{alertBox.text}</span>
            )}
          </div>
        )}
      </div>
    </FieldContainer>
  );
};

export default TextFieldContainer;
