import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { formatError } from 'js/components-legacy/common/AlertMessage';
import { UserPermission } from 'types';

import api from './api';

export const fetchCompanyUsers = async (companyId?: number | string) => {
  if (!companyId) return [];

  const { success, data, error } = await api.get<UserPermission[]>(`/companies/${companyId}/users`);
  if (success) {
    return data;
  }

  throw new Error(formatError(error) || "Failed to fetch company's users.");
};

export const queryKeys = createQueryKeys('companyUsers', {
  list: (companyId) => ({
    queryKey: [companyId],
    queryFn: () => fetchCompanyUsers(companyId),
  }),
});

export const useCompanyUsers = (companyId?: number | string) => {
  return useQuery(queryKeys.list(companyId));
};

export const useUpdateCompanyUsers = (companyId?: number | string) => {
  const queryClient = useQueryClient();

  return (companyUsers?: UserPermission[]) => {
    if (companyUsers) {
      queryClient.setQueryData(queryKeys.list(companyId).queryKey, companyUsers);
    } else {
      queryClient.invalidateQueries(queryKeys.list(companyId).queryKey);
    }
  };
};
