import { CircleNotch } from '@phosphor-icons/react';
import classNames from 'classnames';
import { forwardRef } from 'react';

import Icon, { Size as IconSize } from 'js/design-system/Icon';

import { ButtonProps, ButtonSize } from './types';

import styles from './Button.module.scss';
import textTheme from 'js/design-system/Text/Text.module.scss';

const btnIconSizeMap: Record<ButtonSize, IconSize> = {
  [ButtonSize.Small]: 16,
  [ButtonSize.Medium]: 20,
  [ButtonSize.Large]: 24,
};

const calcPadding = ({
  hasLeftIcon,
  hasRightIcon,
  size,
}: {
  hasLeftIcon: boolean;
  hasRightIcon: boolean;
  size?: `${ButtonSize}`;
}) => {
  const isLarge = size === ButtonSize.Large;
  const hasIcon = hasLeftIcon || hasRightIcon;
  const defaultPadding = isLarge ? 24 : 16;

  return {
    paddingLeft: !hasIcon ? defaultPadding : defaultPadding / 2 + (!hasLeftIcon ? 4 : 0),
    paddingRight: !hasIcon ? defaultPadding : defaultPadding / 2 + (!hasRightIcon ? 4 : 0),
  };
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      type = 'button',
      disabled = false,
      variant,
      leftIcon,
      rightIcon,
      size,
      children,
      className,
      onClick,
      isLoading = false,
      isActive = false,
      ...props
    },
    ref,
  ) => {
    const classes = classNames(
      styles.button,
      styles[variant],
      size && styles[size],
      textTheme[`text-button-${size}`],
      className,
      { [styles.active]: isActive },
    );
    const { paddingLeft, paddingRight } = calcPadding({
      hasLeftIcon: !!leftIcon,
      hasRightIcon: !!rightIcon,
      size,
    });
    const iconSize = size && btnIconSizeMap[size];

    return (
      <button
        ref={ref}
        type={type}
        className={classes}
        style={{ paddingLeft, paddingRight }}
        disabled={isLoading || disabled}
        onClick={onClick}
        {...props}
      >
        {!isLoading ? (
          <>
            {leftIcon && <Icon icon={leftIcon} size={iconSize} />}
            {children}
            {rightIcon && <Icon icon={rightIcon} size={iconSize} />}
          </>
        ) : (
          <div className={styles.spinnerContainer}>
            <div className={styles.spinnerContent}>{children}</div>
            <div className={styles.spinnerIcon}>
              <Icon icon={CircleNotch} className={styles.spinner} />
            </div>
          </div>
        )}
      </button>
    );
  },
);

Button.displayName = 'Button';

export default Button;
