import storage from 'local-storage-fallback';

const setItem = (key, value) => storage.setItem(key, value);
const removeItem = (key) => storage.removeItem(key);
const getItem = (key) => storage.getItem(key);
const clear = () => storage.clear();

const localStorageSafe = {
  setItem,
  removeItem,
  getItem,
  clear,
};

export default localStorageSafe;
