import { useQuery } from '@tanstack/react-query';

import queries from 'js/queries';
import { Ability } from 'types';

const useAbility = (companyId?: number, any?: Ability[], all?: Ability[]) => {
  const { data: role, isSuccess: useAccessReady } = useQuery({
    ...queries.currentUser.role(companyId as number),
    enabled: !!companyId,
  });

  const noAccess =
    !role ||
    (all && !all.every((ability: Ability) => role.abilities[ability])) ||
    (any && !any.some((ability: Ability) => role.abilities[ability]));

  return {
    hasAccess: !noAccess,
    useAccessReady,
  };
};

export default useAbility;
