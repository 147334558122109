import ConnectedField, { ConnectedFieldProps } from 'js/design-system/Form/ConnectedField';
import { TextareaProps } from 'js/design-system/Form/Textarea/types';

import Textarea from './Textarea';

const ConnectedTextarea = (props: ConnectedFieldProps<TextareaProps>) => (
  <ConnectedField
    fieldType="textarea"
    renderField={(fieldProps: TextareaProps) => <Textarea {...fieldProps} />}
    {...props}
  />
);

export default ConnectedTextarea;
