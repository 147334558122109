import { AppDispatch, EmployeeUser } from 'types';

import ACTIONS from '../constants/actions';

export const fetching = (scenarioId: number) => (dispatch: AppDispatch) => {
  dispatch({ type: ACTIONS.FETCHING_SCENARIO_EMPLOYEES_USERS, scenarioId });
};

export const receiveAll = (scenarioId: number, data: EmployeeUser[]) => (dispatch: AppDispatch) => {
  dispatch({ type: ACTIONS.RECEIVE_SCENARIO_EMPLOYEES_USERS, data, scenarioId });
};
