import { createSelector } from 'reselect';

import { AppState, UserRole, User, UserType, FetchStatus } from 'types';

import getUsername from '../utils/user-name';
import {
  getByCompanyId as getUserPermissionsByCompanyId,
  getByScenarioId as getUserPermissionsByScenarioId,
} from './permissions';

export const currentUserSelector = createSelector(
  (state: AppState) => state.userInfo?.data,
  (user): User | undefined => {
    if (!user) return undefined;

    return {
      ...user,
      name: getUsername(user),
    };
  },
);

export const currentUserRoleSelector = createSelector(
  (state: AppState) => currentUserSelector(state),
  (state: AppState, companyId?: number) => getUserPermissionsByCompanyId(state, companyId),
  (state: AppState, companyId?: number, scenarioId?: number) =>
    getUserPermissionsByScenarioId(state, scenarioId),
  (currentUser, companyPermissions, scenarioPermissions): UserRole | undefined => {
    if (currentUser?.userType === UserType.Analyst) return UserRole.Analyst;
    const permission = [...companyPermissions, ...scenarioPermissions].find(
      ({ userId }) => userId === currentUser?.id,
    );
    return permission?.role;
  },
);

export const isAnalystSelector = createSelector(currentUserSelector, (currentUser): boolean => {
  return currentUser?.userType === UserType.Analyst;
});

export const isICPOneSelector = createSelector(
  currentUserSelector,
  (user): boolean => user?.segmentGroup === 1,
);

export const userFetchedStatusSelector = (state: AppState): FetchStatus =>
  state.userInfo.indexStatus;
