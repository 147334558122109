import { FormApi } from 'final-form';
import { FormSpy, useForm } from 'react-final-form';

type FormAfterSucceededSubmitProps<TFormValues> = {
  afterSucceededSubmit: (form: FormApi<TFormValues>) => void;
};

const FormAfterSucceededSubmit = <TFormValues,>({
  afterSucceededSubmit,
}: FormAfterSucceededSubmitProps<TFormValues>) => {
  const form = useForm<TFormValues>();

  return (
    <FormSpy
      subscription={{ submitSucceeded: true }}
      onChange={(formState) => {
        const { submitSucceeded } = formState;

        if (!submitSucceeded) return;

        afterSucceededSubmit(form);
      }}
    />
  );
};

export default FormAfterSucceededSubmit;
