import {
  Approval,
  Compensation,
  Employee,
  JobRoleWithLevels,
  NullJobRole,
  UserPermission,
  QuantityUnit,
  Scenario,
  CompCycle,
  BudgetAllocation,
  JobLevel,
} from 'types';

export type EmployeeAdjustment = {
  id: number;
  employeeId: number;
  preservedEmployee: PreservedEmployee;
  compCycleId: number | null;
  createdBy: Pick<UserPermission, 'avatar' | 'fullName' | 'id'>;
  jobTitle: string | null;
  jobRoleId: number | null;
  jobRole: JobRoleWithLevels | NullJobRole;
  jobLevelId: number | null;
  jobLevel?: JobLevel | null;
  notes: string;
  salaryReason: string | null;
  shareReason: string | null;
  baseSalaryIncrease: number | null;
  baseSalaryIncreaseInPercentage: number | null;
  commissionsIncrease: number | null;
  commissionsIncreaseInPercentage: number | null;
  targetAnnualBonusIncrease: number | null;
  targetAnnualBonusIncreaseInPercentage: number | null;
  bonus: number | null;
  bonusInPercentage: number | null;
  shareCountIncrease: number | null;
  shareCountIncreaseInPercentage: number | null;
  payRangeId: number | null;
  benchmarkId: number | null;
  updatedAt: string;
  compensation: Compensation;
  preservedExchangeRate: number;
  preservedPeers: EmployeePeer[];
  recommendedBaseSalaryIncrease: number | null;
  recommendedCommissionsIncrease: number | null;
  recommendedTargetAnnualBonusIncrease: number | null;
};

export type PreservedEmployee = Employee;

export type JobLevelChangeOption = {
  id: number;
  name: string;
  value: string;
  label: string;
};

export type EmployeeAdjustmentFormData = Pick<
  EmployeeAdjustment,
  | 'jobRoleId'
  | 'jobRole'
  | 'jobLevelId'
  | 'jobLevel'
  | 'jobTitle'
  | 'notes'
  | 'salaryReason'
  | 'shareReason'
  | 'baseSalaryIncrease'
  | 'baseSalaryIncreaseInPercentage'
  | 'commissionsIncrease'
  | 'commissionsIncreaseInPercentage'
  | 'targetAnnualBonusIncrease'
  | 'targetAnnualBonusIncreaseInPercentage'
  | 'bonus'
  | 'bonusInPercentage'
  | 'shareCountIncrease'
  | 'shareCountIncreaseInPercentage'
  | 'compensation'
  | 'preservedExchangeRate'
  | 'preservedEmployee'
  | 'benchmarkId'
  | 'payRangeId'
  | 'recommendedBaseSalaryIncrease'
  | 'recommendedCommissionsIncrease'
  | 'recommendedTargetAnnualBonusIncrease'
> & {
  jobLevelChange?: JobLevelChangeOption;
  salaryIncreaseUnit?: QuantityUnit;
  commissionsIncreaseUnit?: QuantityUnit;
  targetAnnualBonusIncreaseUnit?: QuantityUnit;
  shareCountIncreaseUnit?: QuantityUnit;
  bonusUnit?: QuantityUnit;
  baseSalary?: number;
  commissions?: number;
  shareCount?: number;
  targetAnnualBonus?: number;
};

export type EditEmployeeAdjustmentFormData = EmployeeAdjustmentFormData & {
  id: number;
  employeeId: number;
  preservedEmployee: PreservedEmployee;
  updatedAt: string;
};

export type EmployeeAdjustmentProposal = {
  id: number;
  name: string;
  reason: string;
  dateSubmitted: string | null;
  effectiveDate: string | null;
  notes: string;
  compCycleId: number | null;
  scenarioId: number;
  createdById: number;
  status: EmployeeAdjustmentProposalStatus;
  createdBy: Pick<UserPermission, 'avatar' | 'fullName' | 'id'>;
  budgetOwner: Pick<UserPermission, 'fullName' | 'id'> | null;
  approval: Approval | null;
};

export type EmployeeAdjustmentProposalWithAdjustments = EmployeeAdjustmentProposal & {
  employeeAdjustments: EmployeeAdjustment[];
};

export type AwaitingApprovalEmployeeAdjustmentProposal =
  EmployeeAdjustmentProposalWithAdjustments & {
    compCycle: Pick<CompCycle, 'id' | 'name' | 'compCycleType'> | null;
    budgetAllocation: Pick<BudgetAllocation, 'equityBudget'> | null;
  };

export enum EmployeeAdjustmentProposalStatus {
  NotStarted = 'not_started',
  Draft = 'draft',
  AwaitingApproval = 'awaiting_approval',
  Approved = 'approved',
  Rejected = 'rejected',
  Finalized = 'finalized',
}

export type EmployeeAdjustmentProposalFormData = Partial<
  Pick<EmployeeAdjustmentProposal, 'name' | 'reason' | 'effectiveDate' | 'notes' | 'status'>
> & {
  id?: number;
  employeeAdjustments: EditEmployeeAdjustmentFormData[];
};

type EmployeePeer = {
  id: number;
  type: string;
  name: string;
  baseSalary: number;
  ote: number;
  currencyCode: string | null;
  equity: number | null;
  startDate: string;
  baseSalaryPercentile: number | null;
  totalCashPercentile: number | null;
  equityPercentile: number | null;
  cashRangePosition: number | null;
  equityRangePosition: number | null;
  exchangeRate?: number;
};

export type EmployeeAdjustmentTableFieldProps = {
  employeeAdjustment: EmployeeAdjustment | EditEmployeeAdjustmentFormData;
  scenario?: Scenario;
};

export type EmployeeAdjustmentTableFooterProps = {
  proposal: EmployeeAdjustmentProposalWithAdjustments | EmployeeAdjustmentProposalFormData;
  scenario: Scenario;
};
