import { useQuery } from '@tanstack/react-query';
import { ReactNode } from 'react';

import { Multiselect, Select } from 'js/design-system/Form';
import Text from 'js/design-system/Text/Text';
import useAccess from 'js/hooks/useAccess';
import useAppSelector from 'js/hooks/useAppSelector';
import queries from 'js/queries';
import { getById as companySelector } from 'js/selectors/companies';

import styles from './GeoTiers.module.scss';

interface GeoTiersProps {
  scenarioId: number;
  companyId: number;
  helpText?: ReactNode;
  multi?: boolean;
}
export const GeoTiers = ({ scenarioId, companyId, helpText, multi }: GeoTiersProps) => {
  const company = useAppSelector((state) => companySelector(state, companyId));
  const { geoAdjustmentAccessible } = useAccess({ company });

  const { data: tierOptions = [] } = useQuery({
    ...queries.scenarios.detail(scenarioId)._ctx.payTiers,
    select: (tiers) =>
      tiers.map((t) => {
        const locations = t.geoTierLocations.map((l, i, self) => {
          let str = l.name;
          if (i < self.length - 1) {
            str += '; ';
          }
          return str;
        });
        return {
          value: t.id,
          label: t.name,
          filterContext: `${t.name}${locations}`,
          displayLabel: (
            <div className={styles.label}>
              <Text variant="text-misc-input-bold">{t.name}</Text>
              <Text variant="text-misc-help" color="platform-gray-700">
                {locations}
              </Text>
            </div>
          ),
        };
      }),
  });

  const props = {
    name: 'tierIds',
    options: tierOptions,
    label: `Geo Tier${multi ? '(s)' : ''}`,
    subLabel: geoAdjustmentAccessible ? (
      <a href={`/scenarios/${scenarioId}/pay-strategy`} target="_blank" rel="noopener noreferrer">
        Geo Strategy Settings
      </a>
    ) : undefined,
    required: true,
    placeholder: 'Select',
    helpText,
    topAlignedCheckbox: multi ? true : undefined,
  };

  return multi ? (
    <Multiselect
      {...props}
      labelPosition="left"
      validate={(value) => {
        if (!value || (Array.isArray(value) && value.length === 0)) {
          return 'This is a required field';
        }
        return null;
      }}
    />
  ) : (
    <Select {...props} labelPosition="left" />
  );
};
