import { FeatureFlag } from 'types/featureFlag';

import api from './api';

export const fetchFeatureFlags = async (companyId: number | null | undefined) => {
  let urlParams = {};
  if (companyId) {
    urlParams = { companyId };
  }
  const { success, data } = await api.get<FeatureFlag[]>('feature_flags', { urlParams });

  if (success) {
    return data;
  }

  throw new Error('Failed to load feature flags.');
};
