export default {
  view: {
    ranges: 'Ranges View Ranges',
  },
  set: {
    geoStrategy: 'Ranges Onboarding Set Geo Strategy',
    marketPosition: 'Ranges Onboarding Set Market Position',
    width: 'Ranges Onboarding Set Width',
    grouping: 'Ranges Set Grouping',
  },
  update: {
    geoStrategy: 'Ranges Update Set Geo Strategy',
    marketPosition: 'Ranges Update Market Position',
    width: 'Ranges Update Width',
    complete: 'Ranges Update Complete',
    strategy: 'Ranges Update Strategy',
  },
  complete: {
    onboarding: 'Ranges Onboarding Complete',
  },
};
