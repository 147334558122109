import RawMultiSelect, { compactValues } from 'js/components-legacy/form/RawMultiSelect';

type Option<TValue> = {
  value: TValue;
  label: string;
};

interface RawDepartmentFilterProps<TValue> {
  options: Option<TValue>[];
  value: TValue[] | undefined;
  onChange: (value: TValue[]) => void;
  className?: string;
}

const RawDepartmentFilter = <TValue extends number | null = number>({
  options,
  value,
  onChange,
  className,
}: RawDepartmentFilterProps<TValue>) => {
  return (
    <RawMultiSelect
      options={options}
      onChange={onChange}
      value={value}
      className={className}
      placeholder="All Departments"
      allOption={{
        value: 0,
        label: 'All Departments',
      }}
      displayedValue={compactValues('Departments')}
    />
  );
};

export default RawDepartmentFilter;
