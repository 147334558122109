import { Benchmark } from 'types';

import api from './api';

export const fetchBenchmarks = async (args?: { companyId?: number; jobRoleId?: number }) => {
  let url = '/benchmarks';
  if (args) {
    if (args.companyId) {
      url = `/companies/${args.companyId}/benchmarks`;
    }
    if (args.jobRoleId) {
      url = `/job_roles/${args.jobRoleId}/benchmarks`;
    }
  }

  const { success, data } = await api.get<Benchmark[]>(url);
  if (success) {
    return data;
  }

  throw new Error('Failed to load benchmarks');
};
