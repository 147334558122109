export default {
  show: {
    window: 'Share Window Show',
  },
  invite: {
    user: 'Share Invite User',
  },
  revoke: {
    user: 'Share Revoke User',
  },
};
