import { useEffect, useState, useRef } from 'react';

import { debounce } from 'js/utils/debounce';
import { ElementSize } from 'types';

const useElementMutationSize = <T extends HTMLElement>(ref: React.RefObject<T | null>) => {
  const mounted = useRef(false);
  const [elementSize, setElementSize] = useState<ElementSize>({});
  const [observer, setObserver] = useState<MutationObserver | null>(null);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    const element = ref.current;

    if (element) {
      const debounceHandleChange = debounce(() => {
        if (mounted.current) {
          setElementSize({
            scrollWidth: element.scrollWidth,
            scrollHeight: element.scrollHeight,
            offsetWidth: element.offsetWidth,
            offsetHeight: element.offsetHeight,
            offsetLeft: element.offsetLeft,
          });
        }
      });

      if (window.MutationObserver && !observer) {
        setObserver(new MutationObserver(debounceHandleChange));
      }

      if (observer) {
        observer.observe(element, {
          childList: true,
          subtree: true,
        });
        debounceHandleChange();
        return () => observer.disconnect();
      }
    }

    return undefined;
  }, [ref, observer]);

  return elementSize;
};

export default useElementMutationSize;
