import Auth from '@aws-amplify/auth';
import Cookies from 'js-cookie';

import { updateUser as updateUserAction } from 'js/actions/currentUser';
import api from 'js/api/api';
import { createUser } from 'js/api/users';
import {
  alertError,
  alertInfo,
  alertSuccess,
  formatError,
} from 'js/components-legacy/common/AlertMessage';
import GLOBALS from 'js/config/globals';
import ACTIONS from 'js/constants/actions';
import {
  PROMO_COOKIE_KEY,
  UTM_COOKIE_KEY,
  UTM_COOKIE_KEY2,
  getPromoUtmParams,
  getSecondaryUtmParams,
  utmParamsAreDifferent,
} from 'js/services/promoUtmService';
import Store from 'js/store';
import localStorageSafe from 'js/utils/local-storage-safe';
import { logInfo, logWarn, logError } from 'js/utils/logger';

import { getErrorMessage, messages } from './message-mapping';

const { authFlowSteps, domainName } = GLOBALS;

const isEmbedded = window.self !== window.top;

const addSignInCookie = () => {
  if (!domainName) return;
  // Cognito refresh token has a 30 days expiration
  Cookies.set('is_logged_in', 'true', { expires: 30, domain: domainName });
};

const removeUtmCookies = () => {
  Cookies.remove(UTM_COOKIE_KEY);
  Cookies.remove(UTM_COOKIE_KEY2);
};

const removeSignInCookie = () => {
  Cookies.remove(PROMO_COOKIE_KEY);
  removeUtmCookies();

  if (!domainName) return;
  Cookies.remove('is_logged_in', { domain: domainName });
};

const deleteLocalStorage = () => {
  // HACK: keep until we remove persisted data
  localStorageSafe.removeItem('savedBurnThrough');
};

const updateUserPromoUtm = () => {
  const params = getPromoUtmParams();
  if (params) {
    updateUserAction({ promoUtmData: params })(Store.dispatch);
  }

  if (utmParamsAreDifferent()) {
    const utm = getSecondaryUtmParams();
    if (utm) {
      updateUserAction({ promoUtmData: utm })(Store.dispatch);
    }
  }

  removeUtmCookies();
};

const isUserAlreadyConfirmedError = (error: any) =>
  error.message?.includes('Current status is CONFIRMED');

const sanitizeEmail = (email: string) => email?.trim().toLowerCase();

const completeSignup = async (email: string, hrisProvider: string | null) => {
  addSignInCookie();

  const currentSession = await Auth.currentSession();
  const idToken = currentSession.getIdToken();
  const { payload } = idToken;

  const promoUtmData = getPromoUtmParams() || getSecondaryUtmParams();

  return createUser({
    userData: {
      email: sanitizeEmail(email),
      jobTitle: payload['custom:job_title'],
      ssoId: payload.sub, // "subject"
      verified: payload.email_verified,
      firstName: payload.given_name,
      lastName: payload.family_name,
      hrisProvider,
    },
    promoUtmData,
    onSuccess: (user) => {
      const { invitation } = user;

      updateUserPromoUtm();
      removeUtmCookies();

      if (isEmbedded && window.top) {
        const url = `${window.location.protocol}//${window.location.host}/`;

        if (invitation && invitation.purpose === 'hris_connect') {
          window.top.location.href = `${url}onboarding/${user.landingCompanyId}/employees`;
        } else {
          window.top.location.href = url;
        }
      }
    },
    onFailure: (error) => {
      logError('auth error create user', error);
      const message = formatError(error);
      throw new Error(message);
    },
  });
};

const confirmVerification = async (
  email: string,
  password: string | undefined,
  code: string,
  hrisProviderKey: string | null,
) => {
  const sanitizedEmail = sanitizeEmail(email);
  try {
    try {
      await Auth.confirmSignUp(sanitizedEmail, code.trim());
    } catch (error) {
      // Ignore already confirmed error in case verification form is submitted again to fix wrong password
      // if user tries to reconfirm a confirmed user on purpose, it will throw error at creating user
      if (!isUserAlreadyConfirmedError(error)) {
        throw error;
      }
    }

    await Auth.signIn(sanitizedEmail, password);
    return await completeSignup(email, hrisProviderKey);
  } catch (error) {
    logError('auth error confirm verification', error);
    throw new Error(getErrorMessage(error, 'verification'));
  }
};

const resendCode = async (
  email: string,
  password: string | undefined,
  callback: (email: string, password: string | undefined, nextAction: string) => void,
) => {
  const sanitizedEmail = sanitizeEmail(email);
  try {
    await Auth.resendSignUp(sanitizedEmail);
    callback(sanitizedEmail, password, authFlowSteps.verification);
    return 'Please check your email for a new security code.';
  } catch (error) {
    logError('auth error send code', error);
    throw new Error(getErrorMessage(error, 'verification'));
  }
};

const forgotPass = (
  email: string,
  callback: (email: string, password: string | undefined, nextAction: string) => void,
  authFlowStep?: string,
) => {
  const sanitizedEmail = sanitizeEmail(email);
  return Auth.forgotPassword(sanitizedEmail)
    .then(() => {
      Store.dispatch({ type: ACTIONS.REQUEST_COMPLETED });
      const message =
        authFlowStep === authFlowSteps.resetPass
          ? messages.password.info?.reset
          : messages.password.info?.forget;
      alertInfo(message, { autoClose: false });

      const flowStep = authFlowStep || authFlowSteps.forgotPassConfirm;
      callback(sanitizedEmail, undefined, flowStep);
    })
    .catch((error) => {
      Store.dispatch({ type: ACTIONS.REQUEST_COMPLETED });
      switch (error.code) {
        case 'InvalidParameterException':
          // TODO: better typing when convert this file to ts
          resendCode(sanitizedEmail, undefined, callback);
          break;
        default:
          logError('auth error forgot password', error);
          alertError(getErrorMessage(error, 'password'));
          break;
      }
    });
};

const signIn = async (
  username: string,
  password: string,
  callback: (email: string, password: string | undefined, nextAction: string) => void,
) => {
  const trimmedUsername = username.trim();
  return Auth.signIn(trimmedUsername, password)
    .then(() => {
      addSignInCookie();
      updateUserPromoUtm();
      return true;
    })
    .catch((error) => {
      if (error.code === 'UserNotConfirmedException') {
        resendCode(trimmedUsername, password, callback);
      } else if (error.code === 'PasswordResetRequiredException') {
        forgotPass(trimmedUsername, callback, authFlowSteps.resetPass);
      } else {
        logWarn('auth error sign in', error);
        alertError(getErrorMessage(error, 'signIn'));
      }
      return false;
    });
};

const FIRST_NAME_MISSING = 'Please provide your first name.';
const LAST_NAME_MISSING = 'Please provide your last name.';
const signUp = async (
  email: string,
  password: string,
  firstName: string,
  lastName: string,
  callback: (email: string, password: string | undefined, nextAction: string) => void,
) => {
  if (!firstName || firstName.trim().length === 0) {
    throw new Error(FIRST_NAME_MISSING);
  }
  if (!lastName || lastName.trim().length === 0) {
    throw new Error(LAST_NAME_MISSING);
  }
  const sanitizedEmail = sanitizeEmail(email);
  try {
    const res = await Auth.signUp({
      username: sanitizedEmail,
      password,
      attributes: {
        email: sanitizedEmail,
        given_name: firstName,
        family_name: lastName,
      },
    });

    if (!res.userConfirmed) {
      const nextStep = isEmbedded ? authFlowSteps.verificationEmbedded : authFlowSteps.verification;
      callback(sanitizedEmail, password, nextStep);
    } else {
      callback(sanitizedEmail, password, authFlowSteps.signIn);
    }
  } catch (e) {
    if (!e || typeof e !== 'object' || !('code' in e) || !('message' in e)) {
      throw new Error(messages.signUp.defaultError);
    }
    if (e.code === 'UserLambdaValidationException' && typeof e.message === 'string') {
      if (e.message.endsWith('is not a company email.')) {
        throw new Error(
          'It looks like this is a personal email address. Please provide your work email.',
        );
      }
      if (e.message.endsWith('is missing a given name.')) {
        throw new Error(FIRST_NAME_MISSING);
      }
      if (e.message.endsWith('is missing a family name.')) {
        throw new Error(LAST_NAME_MISSING);
      }
    }
    logWarn('auth error sign up', e);
    throw new Error(getErrorMessage(e, 'signUp'));
  }
};

const checkCurrentSession = async () => {
  return Auth.currentSession()
    .then((response) => {
      updateUserPromoUtm();
      const idToken = response.getIdToken();
      const jwtToken = idToken.getJwtToken();
      if (jwtToken) {
        return true;
      }
      return false;
    })
    .catch((error) => {
      if (error === 'No current user') {
        logInfo(error);
      } else {
        logError(error);
      }
      return false;
    });
};

const forgotPasswordSubmit = (
  email: string,
  code: string,
  password: string,
  callback: (email: string, password: string | undefined, nextAction: string) => void,
) => {
  const sanitizedEmail = email.trim();
  const trimmedCode = code.trim();
  return Auth.forgotPasswordSubmit(sanitizedEmail, trimmedCode, password)
    .then(() => {
      alertSuccess(messages.password.confirm?.success);
      return signIn(sanitizedEmail, password, callback);
    })
    .catch((error) => {
      logError('auth error forgot password submit', error);
      alertError(getErrorMessage(error, 'password'));
      return false;
    });
};

const changePassword = async (oldpassword: string, newpassword: string) =>
  Auth.currentAuthenticatedUser()
    .then((response) => {
      return Auth.changePassword(response, oldpassword, newpassword)
        .then(() => {
          return true;
        })
        .catch((error) => {
          alertError(getErrorMessage(error, 'password'));
          return false;
        });
    })
    .catch((error) => {
      logError('auth error change password', error);
      alertError(getErrorMessage(error, 'password'));
      return false;
    });

const updateUser = async (
  firstName: string,
  lastName: string,
  jobTitle: string,
  avatarFileName: string,
) => {
  return Auth.currentAuthenticatedUser()
    .then((cognitoUser) => {
      return Auth.updateUserAttributes(cognitoUser, {
        given_name: firstName,
        family_name: lastName,
        'custom:job_title': jobTitle,
      }).then(() => {
        return updateUserAction({
          userData: { firstName, lastName, jobTitle, avatar: avatarFileName },
          onSuccess: () => {
            alertSuccess('Profile updated');
          },
          onFailure: alertError,
        })(Store.dispatch);
      });
    })
    .catch((error) => alertError(getErrorMessage(error)));
};

// TODO - this can replace `updateUser` when we have the referencing files converted to DS 2.0.
const updateUserNoAlerts = (
  firstName: string,
  lastName: string,
  jobTitle: string,
  avatarFileName: string,
  userSegment: string,
) => {
  return Auth.currentAuthenticatedUser()
    .then((cognitoUser) => {
      Auth.updateUserAttributes(cognitoUser, {
        given_name: firstName,
        family_name: lastName,
        'custom:job_title': jobTitle,
      }).then(() => {
        updateUserAction({
          userData: { firstName, lastName, jobTitle, userSegment, avatar: avatarFileName },
          onSuccess: () => {},
          onFailure: (error) => {
            throw new Error(getErrorMessage(error));
          },
        })(Store.dispatch);
      });
    })
    .catch((error) => {
      throw new Error(getErrorMessage(error));
    });
};

const signOut = (onSuccess?: () => void) => {
  api.delete('/current_user');
  Auth.signOut({ global: true })
    .then(() => {
      if (onSuccess) onSuccess();
      removeSignInCookie();
      deleteLocalStorage();
      Store.dispatch({ type: ACTIONS.USER_SIGNOUT });
    })
    .catch((error) => {
      logError('auth error sign out', error);
      // If there is any error signing out, clear localStorage and redirect to login
      localStorageSafe.clear();
      const url = `${window.location.protocol}//${window.location.host}/login`;
      if (window.top) {
        window.top.location.href = url;
      }
    });
};

const redirToGoogleOAuthEmbedded = () => {
  if (isEmbedded && window.top) {
    const referrerUrl = encodeURIComponent(document.referrer); // referrerpolicy="unsafe-url" needs to be set on the iframe
    const url = `${window.location.protocol}//${window.location.host}/signup?action=continue-with-google-oauth&referrer=${referrerUrl}`;

    window.top.location.href = url;
  }
};

const SIGNUP_CONTEXT_KEY = 'SIGNUP_CONTEXT';
export type SignupType = 'google' | 'email';
export type SignUpContext = {
  embedded: boolean;
  type: SignupType;
};

const setSignUpContext = (signupType: SignupType) => {
  const context: SignUpContext = { embedded: isEmbedded, type: signupType };

  // avoid accidental overwrite of previously set context
  if (!localStorage.getItem(SIGNUP_CONTEXT_KEY)) {
    localStorage.setItem(SIGNUP_CONTEXT_KEY, JSON.stringify(context));
  }
};

const getSignUpContext = (): SignUpContext | null => {
  const contextJSON = localStorage.getItem(SIGNUP_CONTEXT_KEY);

  if (contextJSON) {
    try {
      const data = JSON.parse(contextJSON);
      return data;
    } catch (error) {
      logError('SIGNUP_CONTEXT_KEY values on localStorage is not a valid JSON', error);
      return null;
    }
  }

  return null;
};

const clearSignUpContext = () => {
  localStorage.removeItem(SIGNUP_CONTEXT_KEY);
};

export {
  changePassword,
  checkCurrentSession,
  clearSignUpContext,
  completeSignup,
  confirmVerification,
  forgotPass,
  forgotPasswordSubmit,
  getSignUpContext,
  redirToGoogleOAuthEmbedded,
  resendCode,
  setSignUpContext,
  signIn,
  signOut,
  signUp,
  updateUser,
  updateUserNoAlerts,
};
