import { UploadSimple, Trash } from '@phosphor-icons/react';
import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import Button from 'js/design-system/Button/Button';
import { ButtonVariant } from 'js/design-system/Button/types';

import FieldContainer from '../FieldContainer';
import ImagePickerPlaceholder from './ImagePickerPlaceholder';
import { ImagePickerProps, ImageType } from './types';

import helpTextStyle from '../TextField/TextFieldContainer.module.scss';
import styles from './ImagePicker.module.scss';

const ImagePicker = ({
  imageType = ImageType.Default,
  onChange,
  value,
  width = 256,
  height = 150,
  name,
  label,
  subLabel,
  labelPosition,
  required,
  labelClassName,
  helpText,
  buttonSize = 'medium',
}: Partial<ImagePickerProps>) => {
  const [imageSrc, setImageSrc] = useState<string>();
  const { getRootProps, getInputProps, open } = useDropzone({
    accept: { 'image/*': [] },
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (onChange) onChange(file);
    },
  });

  const removePhoto = () => {
    setImageSrc(undefined);
    if (onChange) onChange(null);
  };

  const loadImage = useCallback(async (image?: string | Blob | MediaSource | null) => {
    if (!image) return setImageSrc(undefined);

    if (typeof image === 'string') {
      const fetchedLogo = await import('@aws-amplify/storage').then(({ Storage }) =>
        Storage.get(image, { level: 'public' }),
      );

      return setImageSrc(fetchedLogo);
    }

    return setImageSrc(URL.createObjectURL(image));
  }, []);

  useEffect(() => {
    loadImage(value);
  }, [loadImage, value]);

  return (
    <FieldContainer
      name={name}
      label={label}
      subLabel={subLabel}
      labelPosition={labelPosition}
      required={required}
      labelClassName={labelClassName}
    >
      <div
        className={classNames({
          [styles.default]: imageType === ImageType.Default,
          [styles.avatar]: imageType === ImageType.Avatar,
          [styles.logo]: imageType === ImageType.Logo,
        })}
      >
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          {imageSrc ? (
            <img
              {...(imageType === ImageType.Default ? { style: { width, height } } : {})}
              className={styles.image}
              src={imageSrc}
              alt={imageType}
            />
          ) : (
            <ImagePickerPlaceholder imageType={imageType} width={width} height={height} />
          )}
        </div>
        <div className={styles.buttons}>
          <Button
            size={buttonSize}
            onClick={open}
            variant={ButtonVariant.Outlined}
            className={styles.button}
            leftIcon={UploadSimple}
          >
            {imageSrc ? 'Replace' : 'Upload'}
          </Button>
          {imageSrc && (
            <Button
              size={buttonSize}
              onClick={removePhoto}
              variant={ButtonVariant.Outlined}
              className={styles.button}
              leftIcon={Trash}
            >
              Remove
            </Button>
          )}
        </div>
        {helpText && <div className={helpTextStyle.inputField__subText}>{helpText}</div>}
      </div>
    </FieldContainer>
  );
};

export default ImagePicker;
