import { useParams } from 'react-router-dom';

const useNumberParams = () => {
  const params = useParams();

  const numberParams = Object.entries(params).reduce(
    (newParams: Record<string, number>, [key, value]) => ({
      ...newParams,
      [key]: Number(value),
    }),
    {},
  );

  return numberParams;
};

export default useNumberParams;
