import { IndustrySector } from 'types';

import api from './api';

export const fetchIndustrySectors = async () => {
  const { success, data } = await api.get<IndustrySector[]>('/industry_sectors');
  if (success) {
    return data;
  }

  throw new Error('Failed to load industry sectors');
};
