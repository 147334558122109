import { Fragment } from 'react';

import { CompType, RangeLookupData } from 'js/components/data-lookup/ranges/types';
import { formatCash, formatEquity } from 'js/services/compensations/formatter';
import { getLevelText } from 'js/services/levelService';
import { EquityDisplayType } from 'types';

import styles from './Breakdown.module.scss';

interface BreakdownProps {
  data: RangeLookupData;
  compType: CompType;
  outstandingShares: number;
  currencyCode: string;
  exchangeRate: number;
}

export const Breakdown = ({
  data,
  compType,
  outstandingShares,
  currencyCode,
  exchangeRate,
}: BreakdownProps) => {
  const MIN = compType === 'cash' ? data.minCash : data.minEquity;
  const MAX = compType === 'cash' ? data.maxCash : data.maxEquity;
  const equityDisplayType =
    // eslint-disable-next-line no-nested-ternary
    compType === 'shares'
      ? EquityDisplayType.NumOfShares
      : compType === 'percent'
      ? EquityDisplayType.PercentOwnership
      : EquityDisplayType.None;

  const rows = data.tiers
    .map((tier) => {
      const data = [...tier[compType === 'cash' ? 'cash' : 'equity']].sort(
        (a, b) => a.jobLevel.order - b.jobLevel.order,
      );

      const bands = data.map((d) => ({
        jobLevel: d.jobLevel,
        tier: tier.tierName,
        min: d.min,
        max: d.max,
        formattedMin:
          compType === 'cash'
            ? formatCash(d.min * exchangeRate, { currency: currencyCode })
            : formatEquity(d.min, equityDisplayType, outstandingShares, true),
        formattedMax:
          compType === 'cash'
            ? formatCash(d.max * exchangeRate, { currency: currencyCode })
            : formatEquity(d.max, equityDisplayType, outstandingShares, true),
      }));

      return bands;
    })
    .sort((tierA, tierB) => {
      if (tierA.length === 0) return 1;
      if (tierB.length === 0) return -1;

      return tierA[0].min - tierB[0].min;
    })
    .flat();

  return (
    <div className={styles.container}>
      {rows.map((r, i) => (
        <Fragment key={`${r.jobLevel.id}${r.tier}`}>
          {i > 0 && r.tier !== rows[i - 1].tier ? <div className={styles.divider} /> : null}
          <div className={styles.row}>
            <div>{`${getLevelText(r.jobLevel.name, { longFormat: true })}, ${r.tier}`}</div>
            <div>
              <span className={r.min === MIN ? styles.bold : ''}>{r.formattedMin}</span>
              <span> - </span>
              <span className={r.max === MAX ? styles.bold : ''}>{r.formattedMax}</span>
            </div>
          </div>
        </Fragment>
      ))}
    </div>
  );
};
