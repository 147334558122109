import api from './api';

type EmployeeCount = {
  total: number;
  hidden: {
    future: number;
    terminated: number;
    international: number;
    contractor: number;
    partTime: number;
    intern: number;
    temp: number;
    seasonal: number;
    executive: number;
  };
};

export const fetchEmployeeCount = async (id: number) => {
  const { success, data } = await api.get<EmployeeCount>(`/scenarios/${id}/employee_count`);
  if (success) {
    return data;
  }

  throw new Error('Failed to load employee count');
};
