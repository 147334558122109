import Auth from '@aws-amplify/auth';
import { useEffect, useState } from 'react';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';

import { createQueryParams } from 'js/api/api';
import Root from 'js/components/pages/Root';
import { logError } from 'js/utils/logger';

const PrivateRoute = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const { pathname } = useLocation();
  const [params] = useSearchParams();
  const code = params.get('code') as string;
  const redirectUri = params.get('redirect_uri') as string;

  useEffect(() => {
    Auth.currentSession()
      .then(() => {
        setIsAuthenticated(true);
        setIsAuthenticating(false);
      })
      .catch((error) => {
        logError(error);
        setIsAuthenticated(false);
        setIsAuthenticating(false);
      });
  }, []);

  if (isAuthenticating) {
    return null;
  }

  if (!isAuthenticated) {
    const searchParams = createQueryParams({
      continue: pathname,
      code,
      redirectUri,
    });
    return <Navigate to={`/login?${searchParams}`} />;
  }

  return <Root />;
};

export default PrivateRoute;
