import { useEffect, useMemo } from 'react';

import RawNestedSelect from 'js/components-legacy/form/RawNestedSelect';
import { useJobTypeLevels } from 'js/queries/jobLevels';
import { toLevelFilterOptions } from 'js/services/levelService';
import { NestedFilterData } from 'types';

interface RawJobLevelFilterProps {
  scenarioId: number;
  value: NestedFilterData | null | undefined;
  onChange: (selectedOptions: NestedFilterData) => void;
  onInit?: (options: NestedFilterData) => void;
  className?: string;
}
const RawJobLevelFilter = ({
  scenarioId,
  value,
  onChange,
  onInit,
  className,
}: RawJobLevelFilterProps) => {
  const { data: jobTypeLevels } = useJobTypeLevels(scenarioId);

  const options = useMemo(
    () => (jobTypeLevels ? toLevelFilterOptions(jobTypeLevels) : null),
    [jobTypeLevels],
  );

  useEffect(() => {
    if (onInit && options) {
      onInit(options);
    }
  }, [onInit, options]);

  if (!options) return null;

  return (
    <RawNestedSelect
      dropdownClassName={className}
      options={options}
      onChange={onChange}
      label="Levels"
      placeholder="All Levels"
      value={value ?? undefined}
      allOptionName="All Levels"
    />
  );
};

export default RawJobLevelFilter;
