import keymirror from 'key-mirror';

const ACTIONS = keymirror({
  DO_NOTHING: null,

  COMPDATA_UPDATE_REQUIRED: null,

  GET_USER_START: null,
  USER_LOADED: null,
  USER_UPDATED: null,
  USER_STATUS_UPDATED: null,
  RELEASE_DETECTED: null,
  SLOW_REQUEST_STARTED: null,
  REQUEST_FAILED: null,
  REQUEST_COMPLETED: null,

  LOAD_PERMISSIONS: null,
  RESET_PERMISSIONS: null,
  ENABLE_FEATURE: null,
  DISABLE_FEATURE: null,

  AMMEND_SCENARIOS: null,
  SCENARIO_CREATED: null,
  SCENARIO_CREATED_FAILURE: null,
  SCENARIO_UPDATED: null,
  SCENARIO_UPDATED_FAILURE: null,
  SCENARIO_FAILURE_RESET: null,
  CLEAR_SCENARIOS: null,

  CLEAR_SCENARIO_JOB: null,
  SCENARIO_JOB_SUCCEEDED: null,
  SCENARIO_JOB_FAILED: null,

  LOAD_COMPANY: null,
  CLEAR_COMPANY: null,
  COMPANY_CREATED: null,
  COMPANY_CREATED_FAILURE: null,
  COMPANY_UPDATED: null,
  COMPANY_UPDATED_FAILURE: null,
  COMPANY_DELETED: null,
  COMPANY_INVITES_SENT: null,
  COMPANY_REVOKED_INVITES: null,
  WELCOME: null,

  SHOW_SVB_MODAL: null,
  SHOW_SVB_MODAL_ERROR: null,
  SHOW_SVB_ACCOUNTS_MODAL: null,
  SHOW_REMOVE_SVB_MODAL: null,
  SVB_STARTED: null,
  LOAD_SVB: null,
  REMOVE_SVB: null,
  GET_SVB_BANK_ACCOUNTS: null,
  SET_DEFAULT_SVB_ACCOUNT: null,
  SET_SVB_BANK_ID: null,
  SET_SVB_PIN: null,
  SVB_SUBMIT: null,
  ERROR: null,
  INVITES_SENT: null,
  REVOKED_INVITES: null,

  DEPARTMENT_CREATED: null,
  EMPLOYEE_UPDATED: null,
  EMPLOYEE_SORT: null,
  EMPLOYEE_FORM_SCROLL_TO_FIELD: null,
  SET_CASH_DISPLAY_TYPE: null,
  SET_EQUITY_DISPLAY_TYPE: null,
  SET_HIDDEN_EMPLOYEE_TYPES: null,
  SET_INITIAL_DEPARTMENT_FILTERS: null,
  SET_INITIAL_JOB_TYPE_LEVELS_FILTERS: null,
  SET_INITIAL_RANGE_GROUP_FILTERS: null,
  CLEAR_ALL_FILTERS: null,
  SET_CURRENT_SCENARIO_VIEW_MODE: null,
  SET_EMPLOYEE_FILTER: null,
  SET_DIVERSITY_GROUP_TYPE: null,
  SET_COMP_DISPLAY_TYPE: null,
  SET_CURRENCY_DISPLAY: null,
  SET_TIMELINE_CHART_TYPE: null,

  USER_SIGNIN_SET_EMAIL: null,
  USER_SIGNIN_SET_PASSWORD: null,
  USER_SIGNIN_SET_ERROR: null,
  CLEAR_SESSION_FORMS: null,
  USER_SIGNUP_SET_EMAIL: null,
  USER_SIGNUP_SET_PASSWORD: null,
  USER_SIGNUP_SET_ERROR: null,
  RESET_PASSWORD_SET_EMAIL: null,
  RESET_PASSWORD_SET_PASSWORD: null,
  RESET_PASSWORD_SET_ERROR: null,
  USER_SIGNOUT: null,
  LOAD_PORTFOLIO: null,
  LOAD_PRODUCTS: null,
  SHOW_RENAME_SCENARIO: null,
  CREATE_SCENARIO_SET_NAME: null,
  CREATE_SCENARIO_SET_STAGE: null,
  CREATE_SCENARIO_SET_OUTSTANDING_SHARES: null,
  CLOSE_MODAL: null,

  SET_SCENARIO_NAME: null,

  SHOW_CREATE_MILESTONE: null,
  CREATE_MILESTONE_SET_NAME: null,
  CREATE_MILESTONE_SET_DATE: null,
  MILESTONE_CREATED: null,
  SET_MILESTONE_NAME: null,
  SET_MILESTONE_DATE: null,
  DELETE_MILESTONE: null,

  SET_CATEGORY_NAME: null,
  SET_CATEGORY_SHOW_FLAG: null,
  ADD_CATEGORY: null,
  SET_CATEGORY_ID: null,
  DELETE_CATEGORY: null,

  SHOW_EXPENSES_CATEGORIES: null,
  SHOW_EXPENSES_SPREADSHEET: null,
  SHOW_EXPENSES_INCOME: null,
  SHOW_TAB: null,
  EXPAND_CATEGORY: null,
  CACHE_SPREADSHEET: null,

  SET_CATEGORY_ITEM_ID: null,
  SET_CATEGORY_ITEM_ENABLED: null,
  SET_CATEGORY_ITEM_NAME: null,
  SET_CACHED_CATEGORY_ITEM_NAME: null,
  SET_CATEGORY_ITEM_AMOUNT: null,
  SET_CACHED_CATEGORY_ITEM_AMOUNT: null,
  SET_CATEGORY_ITEM_GROWTH: null,
  SET_CACHED_CATEGORY_ITEM_GROWTH: null,
  SET_CATEGORY_ITEM_REPEATS: null,
  SET_CATEGORY_ITEM_PRIORITY: null,
  SET_CATEGORY_ITEM_START_DATE: null,
  SET_CATEGORY_ITEM_END_DATE: null,
  ADD_CATEGORY_ITEM: null,
  DELETE_CATEGORY_ITEM: null,
  DELETE_CACHED_CATEGORY_ITEM: null,
  SET_CATEGORY_ITEM_SPREADSHEET_UNEDIT: null,
  LOAD_SCENARIO: null,
  CLEAR_SCENARIO: null,

  SET_SPREADSHEET_CELL: null,

  GRAPH_ZOOM_IN: null,
  GRAPH_ZOOM_OUT: null,

  LOAD_NOTICES: null,
  VIEW_NOTICE: null,

  HIDE_SALARY_PLANNER: null,
  OPEN_SALARY_PLANNER_STEP_1: null,
  OPEN_SALARY_PLANNER_STEP_2: null,
  FETCHING_SALARY_PLANNER_COMPENSATION_DATA: null,
  GOT_SALARY_PLANNER_COMPENSATION_DATA: null,
  COMPENSATION_DATA_FAILED: null,
  FETCHING_SALARY_PLANNER_JOB_PARAMETERS: null,
  UPDATE_CUSTOM_TEXT_LABEL: null,

  CLEAR_DISPLAYED_JOB_ROLE_NAME: null,
  UPDATE_COMPANY_LOCATION: null,
  UPDATE_FUNDING_STAGE: null,
  UPDATE_JOB_LEVEL: null,
  UPDATE_JOB_ROLE_NAME: null,
  UPDATE_OVERHEAD_PERCENTAGE: null,
  UPDATE_PREFILLED_SALARY_OPTION: null,
  UPDATE_SALARY: null,
  UPDATE_START_DATESTRING: null,

  SUBSCRIPTION_CREATED: null,
  SUBSCRIPTION_UPDATED: null,
  SUBSCRIPTION_DELETED: null,

  CREATING_COMPANY: null,
  CREATE_COMPANY: null,
  DELETE_COMPANY: null,
  DELETING_COMPANY: null,
  FAILED_COMPANIES_FETCH: null,
  FAILED_COMPANY_CREATE: null,
  FAILED_COMPANY_DELETE: null,
  FAILED_COMPANY_FETCH: null,
  FAILED_COMPANY_UPDATE: null,
  FETCHING_COMPANY: null,
  RECEIVE_COMPANY: null,
  INVITE_USER_TO_COMPANY: null,
  REVOKE_USER_FROM_COMPANY: null,
  UPDATE_COMPANY_LANDING_SCENARIO: null,
  RESET_INVITE_STATUS: null,

  CREATE_DEPARTMENT: null,
  DELETE_DEPARTMENT: null,
  RECEIVE_DEPARTMENTS: null,
  UPDATE_DEPARTMENT: null,

  CREATE_EMPLOYEE: null,
  DELETE_EMPLOYEE: null,
  DELETE_EMPLOYEES: null,
  RECEIVE_EMPLOYEE: null,
  FETCHING_EMPLOYEES: null,

  FAILED_JOB_ROLE_FETCH: null,
  FETCHING_JOB_ROLES: null,
  RECEIVE_JOB_ROLES: null,

  FAIL_LOCATION_FETCH: null,
  FETCHING_LOCATIONS: null,
  RECEIVE_LOCATIONS: null,

  INVITE_USER_TO_SCENARIOS: null,
  REVOKE_USER_FROM_SCENARIOS: null,
  CREATE_SCENARIO: null,
  DELETE_SCENARIO: null,
  FAILED_SCENARIOS_FETCH: null,
  FETCHING_SCENARIOS: null,
  RECEIVE_SCENARIOS: null,
  UPDATING_SCENARIO: null,

  FAILED_SCENARIO_FETCH: null,
  FETCHING_SCENARIO: null,
  RECEIVE_SCENARIO: null,

  FAILED_EXCHANGE_RATES_FETCH: null,
  FETCHING_EXCHANGE_RATES: null,
  RECEIVE_EXCHANGE_RATES: null,

  FAILED_BENCHMARK_FETCH: null,
  FETCHING_BENCHMARKS: null,
  RECEIVE_BENCHMARKS: null,

  RECEIVE_FEATURE_FLAGS: null,

  RESET_STATE: null,

  FAILED_PRODUCT_FETCH: null,
  FETCHING_PRODUCTS: null,

  RECEIVE_CASH_CONNECTION: null,

  RESET_COMPANY_NOTIFICATIONS: null,
  FETCHING_COMPANY_NOTIFICATIONS: null,
  RECEIVE_COMPANY_NOTIFICATIONS: null,
  DELETE_COMPANY_NOTIFICATION: null,

  RECEIVE_BANNER: null,

  UPDATE_USERS_ACCESSIBLE_SCENARIOS: null,

  SHOW_CONFETTI: null,
  HIDE_CONFETTI: null,

  RECEIVE_PERMISSIONS: null,
  FETCHING_COMPANY_USERS: null,
  RECEIVE_COMPANY_USERS: null,
  RECEIVE_COMPANY_USER: null,
  UPDATE_INVITE_STATUS: null,
  UPDATE_REVOKE_STATUS: null,
  FETCHING_SCENARIO_USERS: null,
  RECEIVE_SCENARIO_USERS: null,
  RECEIVE_SCENARIO_USER: null,
  FETCHING_SCENARIO_EMPLOYEES_USERS: null,
  RECEIVE_SCENARIO_EMPLOYEES_USERS: null,
  UNI_UPDATE_EMPLOYEES_USERS: null,

  RECEIVE_GEO_TIERS: null,
  FETCHING_GEO_TIERS: null,
  FAIL_GEO_TIERS_FETCH: null,

  RECEIVE_ADJUSTMENTS: null,
  FETCHING_ADJUSTMENTS: null,
  FAIL_ADJUSTMENTS_FETCH: null,

  SET_RANGE_DISPLAY_TYPE: null,
  SET_RANGE_POSITION_METRIC_TYPE: null,

  RECEIVE_STRATEGY_TEMPLATES: null,
  FETCHING_STRATEGY_TEMPLATES: null,
  FAIL_STRATEGY_TEMPLATES_FETCH: null,

  RESET_RANGE_BANDS: null,
  FETCHING_GROUP_OF_RANGE_BANDS: null,
  RECEIVE_GROUP_OF_RANGE_BANDS: null,
  FAIL_FETCH_GROUP_OF_RANGE_BANDS: null,

  FAIL_JOBAREAS_FETCH: null,
  FETCHING_JOBAREAS: null,
  RECEIVE_JOBAREAS: null,

  FAIL_JOB_FAMILIES_FETCH: null,
  FETCHING_JOB_FAMILIES: null,
  RECEIVE_JOB_FAMILIES: null,
  RECEIVE_JOB_FAMILY: null,
  CREATE_JOB_FAMILY: null,

  CREATE_API_CLIENT: null,
  FETCH_API_CLIENT: null,
  DESTROY_API_CLIENT: null,

  SET_NAVBAR_SELECTION: null,
  SET_NAVBAR_SIZE: null,
  SET_STICKY_HEADER_SIZE: null,
  SET_GROUP_HEADER_SIZE: null,
  SET_BANNERS_SIZE: null,

  FAILED_INDUSTRY_SECTOR_FETCH: null,
  FETCHING_INDUSTRY_SECTORS: null,
  RECEIVE_INDUSTRY_SECTORS: null,

  RECEIVE_OFFERS: null,
  RECEIVE_OFFER: null,

  ADD_SNACKBAR: null,
  REMOVE_SNACKBAR: null,

  ACTIVITY_LOG_STALE_DATA: null,
});

export default ACTIONS;
