import classNames from 'classnames';
import { useMemo } from 'react';

import { getLevelOptions } from 'js/components/common/level/utils';
import { Select, SelectYPosition } from 'js/design-system/Form/Select/Select';
import LoadingPlaceholder from 'js/design-system/LoadingPlaceholder/LoadingPlaceholder';
import { useJobLevels } from 'js/queries/jobLevels';
import { shouldVerify, SHOULD_VERIFY } from 'js/services/employeeService';
import { findJobLevel } from 'js/services/levelService';
import { Employee, JobLevel } from 'types';

import styles from './BenchmarkingTable.module.scss';

interface LevelDropdownProps {
  employee: Employee;
  onSelect: (jobLevel: JobLevel | null) => void;
  name?: string;
  loading?: boolean;
  positionY?: SelectYPosition;
  onToggle?: () => void;
}

export const LevelDropdown = ({
  employee,
  onSelect,
  name,
  loading,
  positionY = 'bottom',
  onToggle,
}: LevelDropdownProps) => {
  const notVerified = shouldVerify(employee, SHOULD_VERIFY.level);
  const { jobRole, scenarioId } = employee;

  const { data: jobLevels, isSuccess: isJobLevelsFetched } = useJobLevels(scenarioId, jobRole);
  const options = useMemo(
    () =>
      getLevelOptions(jobLevels, {
        defaultJobLevelId: employee.jobLevelId,
        selectedOptionLongFormat: false,
      }),
    [jobLevels, employee.jobLevelId],
  );

  if (loading || !isJobLevelsFetched) {
    return <LoadingPlaceholder />;
  }

  return (
    <Select
      name={name || `${employee?.id}-jobRoleId`}
      placeholder="Choose level"
      onChange={(jobLevelId) => onSelect(findJobLevel(jobLevels, jobLevelId, 'id'))}
      options={options}
      wrapperClassName={notVerified ? styles.verifyBorder : ''}
      menuClassName={styles.levelMenu}
      disabled={options.length === 1}
      positionY={positionY}
      onToggle={onToggle}
      inputPrepend={
        notVerified ? (
          <span className={classNames(styles.verifyCircle, styles.inputPrepend)} />
        ) : null
      }
    />
  );
};
