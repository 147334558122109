import { indexBy, prop } from 'ramda';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ScenarioFilterEventDetails } from 'js/analytics/types/scenario';
import useTrackEvent from 'js/analytics/useTrackEvent';
import { getEventSourceValueByCurrentRoute } from 'js/analytics/utils';
import RawMultiSelect, { compactValues } from 'js/components-legacy/form/RawMultiSelect';
import useGetRangeGroups from 'js/components/pay-strategy/hooks/useGetRangeGroups';
import ACTIONS from 'js/constants/actions';
import scenarioViewSelector from 'js/selectors/scenarioView';
import { toSelectOptions } from 'js/utils/dropdowns';

import styles from './RangeGroupFilter.module.scss';

const RangeGroupFilter = () => {
  const dispatch = useDispatch();
  const { rangeGroupFilter } = useSelector(scenarioViewSelector);
  const rangeGroups = useGetRangeGroups();
  const { trackEvent } = useTrackEvent<ScenarioFilterEventDetails>();

  const filterRangeGroup = (values: number[]) => {
    dispatch({ type: ACTIONS.SET_EMPLOYEE_FILTER, filterType: 'rangeGroup', filter: values });
    const source = getEventSourceValueByCurrentRoute();
    trackEvent('scenario.filter', { type: 'rangeGroup', source });
  };

  const options = useMemo(() => {
    const rangeGroupIds = rangeGroups.map(({ id }) => id);
    const rangeGroupsById = indexBy(prop('id'), rangeGroups);

    return toSelectOptions(rangeGroupIds, (id: number) => rangeGroupsById[id].name);
  }, [rangeGroups]);

  useEffect(() => {
    const rangeGroupIds = rangeGroups.map(({ id }) => id);
    dispatch({
      type: ACTIONS.SET_INITIAL_RANGE_GROUP_FILTERS,
      data: { rangesFilter: rangeGroupIds },
    });
  }, [dispatch, rangeGroups]);

  return (
    <RawMultiSelect
      className={styles.filter}
      options={options}
      onChange={filterRangeGroup}
      placeholder="All Range Groups"
      value={rangeGroupFilter}
      allOption={{
        value: -100,
        label: 'All Range Groups',
      }}
      displayedValue={compactValues('Range Groups')}
    />
  );
};

export default RangeGroupFilter;
