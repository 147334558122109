import classNames from 'classnames';
import { useEffect, useRef, ReactNode } from 'react';

import { setStickyHeaderSize } from 'js/actions/stickyHeader';
import useAppDispatch from 'js/hooks/useAppDispatch';
import useAppSelector from 'js/hooks/useAppSelector';
import useElementMutationSize from 'js/hooks/useElementMutationSize';
import useElementSize, { defaultElementSize } from 'js/hooks/useElementSize';

import { DashboardHeaderProps } from '../header/DashboardHeader';

import styles from './Layout.module.scss';

interface LayoutProps {
  heading?: ReactNode;
  body?: ReactNode;
  dashboardHeader?: React.ReactElement<DashboardHeaderProps>;
  headingClassName?: string;
  stickyOffset?: number;
  stickyDisabled?: boolean;
}

const Layout = ({
  dashboardHeader,
  heading = null,
  body = null,
  headingClassName = undefined,
  stickyOffset = 0,
  stickyDisabled = false,
}: LayoutProps) => {
  const stickyRef = useRef(null);
  const dispatch = useAppDispatch();
  const stickySize = useElementSize(stickyRef);
  const stickyMutationSize = useElementMutationSize(stickyRef);

  const { navbar } = useAppSelector((state) => state.elementSizes);

  useEffect(() => {
    dispatch(setStickyHeaderSize(stickySize));

    return () => {
      dispatch(setStickyHeaderSize(defaultElementSize));
    };
  }, [dispatch, stickySize]);

  useEffect(() => {
    dispatch(setStickyHeaderSize(stickyMutationSize));

    return () => {
      dispatch(setStickyHeaderSize(defaultElementSize));
    };
  }, [dispatch, stickyMutationSize]);

  const topPosition = (navbar.size?.offsetHeight || 0) + stickyOffset;
  const position = stickyDisabled ? {} : { top: `${topPosition}px` };

  return (
    <div className={styles.layout}>
      <div ref={stickyRef} className={styles.stickyHeaderContainer} style={position}>
        <div className="container-xl">{dashboardHeader}</div>
      </div>

      {heading && (
        <div className={classNames(styles.heading, 'container-xl', headingClassName)}>
          {heading}
        </div>
      )}

      <div className={styles.body}>{body}</div>
    </div>
  );
};

export default Layout;
