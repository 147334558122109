/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { DataLookupViewEventDetails } from 'js/analytics/types/dataLookup';
import useTrackEvent from 'js/analytics/useTrackEvent';
import { fetchJobRole } from 'js/api/jobRoles';
import useGetRange from 'js/components/pay-strategy/hooks/useGetRange';
import LoadingPlaceholder from 'js/design-system/LoadingPlaceholder/LoadingPlaceholder';
import { TabHeader } from 'js/design-system/TabHeader/TabHeader';
import Text from 'js/design-system/Text/Text';
import TextButton from 'js/design-system/TextButton/TextButton';
import useAppSelector from 'js/hooks/useAppSelector';
import useNumberParams from 'js/hooks/useNumberParams';
import { currentUserRoleSelector } from 'js/selectors/currentUser';
import { getById as scenarioSelector } from 'js/selectors/scenarios';
import { JobRoleWithLevels, PayRangeStatus, UserRole } from 'types';

import { DataLookupSource } from '../DataLookup';
import { Illustration } from '../common/Illustration';
import { Layout } from '../common/Layout';
import { NoActiveRangeCta } from './common/NoActiveRangeCta';
import { ResultTitle } from './common/ResultTitle';
import JobPostRangeForm from './job-post-range/JobPostRangeForm';
import { JobPostRangeResults } from './job-post-range/JobPostRangeResults';
import { RangesLookupForm } from './ranges-lookup/RangesLookupForm';
import { RangesLookupResults } from './ranges-lookup/RangesLookupResults';
import { RangeLookupData } from './types';

import styles from './ViewMyRanges.module.scss';

export const ViewMyRanges = () => {
  const { scenarioId } = useNumberParams();
  const scenario = useAppSelector((state) => scenarioSelector(state, scenarioId));
  const {
    data: payRange,
    isSuccess: payRangeFetched,
    hasRange: hasActiveRange,
  } = useGetRange(PayRangeStatus.Active);

  const [activeTab, setActiveTab] = useState<DataLookupSource>(DataLookupSource.ViewPayRanges);
  const [submitting, setSubmitting] = useState(false);
  const [data, setData] = useState<RangeLookupData>();
  const [jobRole, setJobRole] = useState<JobRoleWithLevels>();
  const { trackEvent } = useTrackEvent<DataLookupViewEventDetails>();
  const navigate = useNavigate();

  const currentUserRole = useAppSelector((state) =>
    currentUserRoleSelector(state, scenario?.companyId),
  );

  const handleSubmit = (data: RangeLookupData) => {
    setData(data);
    setSubmitting(false);
  };

  useEffect(() => {
    trackEvent('dataLookup.view');
  }, [trackEvent]);

  useEffect(() => {
    setData(undefined);
  }, [activeTab, scenarioId]);

  useEffect(() => {
    if (data) {
      fetchJobRole(data.jobRoleId, scenarioId).then(setJobRole);
    }
  }, [data, scenarioId]);

  if (!scenario || !payRangeFetched) {
    return null;
  }

  return (
    <Layout
      showOverlay={!hasActiveRange}
      header="View My Ranges"
      subHeader="Access your company's approved pay ranges for current and future roles."
      leftContent={
        <>
          <TabHeader<DataLookupSource>
            tabs={[
              { value: DataLookupSource.ViewPayRanges, title: 'View Pay Ranges' },
              { value: DataLookupSource.GetJobPostRange, title: 'Get Job Post Range' },
            ]}
            activeTab={activeTab}
            setTab={setActiveTab}
            className={styles.tabHeader}
          />
          {activeTab === DataLookupSource.GetJobPostRange ? (
            <JobPostRangeForm
              setSubmitting={setSubmitting}
              scenario={scenario}
              payRangeId={payRange?.id}
              onSubmit={handleSubmit}
            />
          ) : (
            <RangesLookupForm
              setSubmitting={setSubmitting}
              scenario={scenario}
              payRangeId={payRange?.id}
              onSubmit={handleSubmit}
            />
          )}
        </>
      }
      rightContent={
        submitting ? (
          <LoadingPlaceholder />
        ) : data ? (
          <div className={styles.resultsContainer}>
            <Text
              variant="text-misc-eyebrow"
              color="platform-gray-500"
              className={styles.resultsText}
            >
              Results
            </Text>
            <div className={styles.resultsTitleContainer}>
              <ResultTitle jobRole={jobRole} />
            </div>
            {activeTab === DataLookupSource.GetJobPostRange ? (
              <JobPostRangeResults data={data} scenario={scenario} />
            ) : (
              <RangesLookupResults data={data} scenario={scenario} />
            )}
            {currentUserRole !== UserRole.Recruiter && (
              <Text
                variant="text-body-main"
                color="platform-gray-900"
                className={styles.footerText}
              >
                <Text variant="text-body-main-italic" Element="span">
                  To see employees and their range positions, view{' '}
                </Text>
                <TextButton
                  onClick={() =>
                    navigate(`/scenarios/${scenarioId}/ranges/${data.payRangeGroupId}`)
                  }
                >
                  Range Detail
                </TextButton>
                .
              </Text>
            )}
          </div>
        ) : hasActiveRange ? (
          <Illustration source={activeTab} />
        ) : (
          <div className={styles.ctaContainer}>
            <NoActiveRangeCta />
          </div>
        )
      }
    />
  );
};
