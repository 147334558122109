import { useCallback, useEffect, useMemo, useState } from 'react';

import { debounce } from 'js/utils/debounce';
import { ElementSize } from 'types';

type ElementScrollValues = ElementSize & {
  scrollTop?: number;
  scrollLeft?: number;
};

const useElementScroll = <T extends HTMLElement>(
  shouldDebounce = true,
): [ElementScrollValues | undefined, (node: T) => void] => {
  const [elementScrollValues, setElementScrollValues] = useState<ElementScrollValues>();
  const [domNode, setDomNode] = useState<T | null>(null);

  const onRefChange = useCallback((node: T) => {
    setDomNode(node);
  }, []);
  const set = useCallback(() => {
    if (domNode)
      setElementScrollValues({
        scrollTop: domNode.scrollTop,
        scrollLeft: domNode.scrollLeft,
        scrollWidth: domNode.scrollWidth,
        scrollHeight: domNode.scrollHeight,
        offsetWidth: domNode.offsetWidth,
        offsetHeight: domNode.offsetHeight,
      });
  }, [domNode]);
  const handleEvent = useMemo(() => (shouldDebounce ? debounce(set) : set), [set, shouldDebounce]);

  useEffect(() => {
    handleEvent();
  }, [handleEvent]);

  useEffect(() => {
    if (!domNode) return undefined;

    window.addEventListener('scroll', handleEvent, true);
    return () => window.removeEventListener('scroll', handleEvent, true);
  }, [domNode, handleEvent]);

  return [elementScrollValues, onRefChange];
};

export default useElementScroll;
