import { useDispatch, useSelector } from 'react-redux';

import { ScenarioFilterEventDetails } from 'js/analytics/types/scenario';
import useTrackEvent from 'js/analytics/useTrackEvent';
import { getEventSourceValueByCurrentRoute } from 'js/analytics/utils';
import RawEthnicityFilter from 'js/components/common/filter/RawEthnicityFilter';
import ACTIONS from 'js/constants/actions';
import scenarioViewSelector from 'js/selectors/scenarioView';

import styles from './EthnicityFilter.module.scss';

const EthnicityFilter = () => {
  const dispatch = useDispatch();
  const { ethnicityFilter } = useSelector(scenarioViewSelector);
  const { trackEvent } = useTrackEvent<ScenarioFilterEventDetails>();

  const filterEthnicity = (values: string[]) => {
    dispatch({ type: ACTIONS.SET_EMPLOYEE_FILTER, filterType: 'ethnicity', filter: values });
    const source = getEventSourceValueByCurrentRoute();
    trackEvent('scenario.filter', { type: 'ethnicity', source });
  };

  return (
    <RawEthnicityFilter
      value={ethnicityFilter}
      onChange={filterEthnicity}
      className={styles.filter}
    />
  );
};

export default EthnicityFilter;
