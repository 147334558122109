import classNames from 'classnames';
import {
  forwardRef,
  useMemo,
  ChangeEvent,
  useCallback,
  useEffect,
  ForwardedRef,
  useRef,
  useState,
} from 'react';

import FieldContainer from 'js/design-system/Form/FieldContainer';
import { mergeRefs, noop } from 'js/utils/common';

import { TextareaProps } from './types';

import styles from './Textarea.module.scss';

const Textarea = forwardRef(
  (
    {
      id,
      name,
      label,
      subLabel,
      labelPosition = 'left',
      className,
      placeholder,
      value,
      onChange = noop,
      onBlur = noop,
      onFocus = noop,
      onKeyDown = noop,
      validation,
      wrapperClassName,
      ariaLabel,
      maxLength,
      disabled = false,
      readonly = false,
      required = false,
      dataTestId,
      helpText,
      minHeight = 104,
      maxHeight = 296,
      showCounter = false,
    }: TextareaProps,
    ref: ForwardedRef<HTMLTextAreaElement>,
  ) => {
    const localRef = useRef<HTMLTextAreaElement | null>(null);
    const [inputValue, setInputValue] = useState<TextareaProps['value']>(value);
    const updateHeight = useCallback(() => {
      if (localRef.current) {
        localRef.current.style.height = 'auto';
        const height = !localRef.current.value ? minHeight : localRef.current.scrollHeight;
        localRef.current.style.height = `${height}px`;
      }
    }, [minHeight]);

    const handleChange = useCallback(
      (event: ChangeEvent<HTMLTextAreaElement>) => {
        updateHeight();
        onChange(event.target.value);
        setInputValue(event.target.value);
      },
      [onChange, updateHeight],
    );

    const validationClass = useMemo(() => {
      if (!validation) return '';
      const { status } = validation;

      return status ? `inputField--${status}` : '';
    }, [validation]);

    useEffect(() => {
      updateHeight();
    });

    const subTextElement = useMemo(() => {
      if (validation?.text) {
        return (
          <div
            className={classNames(styles.inputField__subText, {
              [styles.inputField__error]: validation?.status === 'error' && validation.text,
            })}
          >
            {validation.text}
          </div>
        );
      }

      if (helpText || (maxLength && showCounter)) {
        return (
          <div className={styles.inputField__subText}>
            {helpText && <div>{helpText}</div>}
            {!!maxLength && showCounter && (
              <div className={styles.inputField__subText__counterText}>
                {(inputValue || '').length}/{maxLength}
              </div>
            )}
          </div>
        );
      }

      return null;
    }, [helpText, maxLength, validation?.status, validation?.text, inputValue, showCounter]);

    return (
      <FieldContainer
        name={id || name}
        label={label}
        subLabel={subLabel}
        labelPosition={labelPosition}
        className={wrapperClassName}
        required={required}
      >
        <div className={classNames(styles.inputWrapper, styles[validationClass])}>
          <textarea
            ref={mergeRefs([localRef, ref])}
            id={id || name}
            className={classNames(className, styles.inputField, {
              [styles['inputField--disabled']]: disabled,
              [styles['inputField--readonly']]: readonly,
              [styles['inputField--error']]: validation?.status === 'error',
            })}
            value={value === null ? undefined : value}
            placeholder={placeholder}
            onChange={handleChange}
            onBlur={onBlur}
            onFocus={onFocus}
            onKeyDown={onKeyDown}
            maxLength={maxLength}
            disabled={disabled}
            readOnly={readonly}
            aria-label={ariaLabel || (typeof label === 'string' && label) || placeholder}
            data-testid={dataTestId || name}
            style={{ minHeight, maxHeight, height: minHeight }}
          />
          {subTextElement}
        </div>
      </FieldContainer>
    );
  },
);

Textarea.displayName = 'Textarea';

export default Textarea;
