import { Eye, EyeSlash } from '@phosphor-icons/react';
import classnames from 'classnames';
import { useState } from 'react';

import Icon from 'js/design-system/Icon';
import { FormFieldProps } from 'types';

import Input from './Input';
import styles from './PasswordInput.module.css';

const PasswordInput = ({ ...props }: FormFieldProps<string>) => {
  const [show, setShow] = useState(false);

  const toggleEye = () => {
    setShow(!show);
  };

  const { size } = props;

  return (
    <Input
      {...props}
      type={show ? 'text' : 'password'}
      inputClassName="position-relative"
      inputAppend={
        <button
          type="button"
          onClick={toggleEye}
          className={classnames(styles.toggle, styles[size || 'md'])}
          data-testid={show ? 'hide' : 'show'}
        >
          <Icon icon={show ? Eye : EyeSlash} size={20} color="primary-gray-600" />
        </button>
      }
    />
  );
};

export default PasswordInput;
