/* eslint-disable no-nested-ternary */
import { Check, Info } from '@phosphor-icons/react';
import { useLoadScript } from '@react-google-maps/api';
import { useQueryClient, useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { snackbar } from 'js/actions/snackbars';
import { disableSpinner, enableSpinner } from 'js/actions/spinners';
import { OnboardBenchmarkEmployeeEventDetails } from 'js/analytics/types/onboard';
import useTrackEvent from 'js/analytics/useTrackEvent';
import { bulkUpdateEmployees } from 'js/api/employees';
import { fetchJobRole, useJobRolesById } from 'js/api/jobRoles';
import { SuggestJobRole } from 'js/components/common/job-roles/types';
import GLOBALS from 'js/config/globals';
import { TextField, TextFieldValue } from 'js/design-system/Form/TextField';
import Icon from 'js/design-system/Icon';
import { Tab } from 'js/design-system/TabSlider';
import { Table } from 'js/design-system/Table';
import { Row, RowGroup } from 'js/design-system/Table/types';
import Text from 'js/design-system/Text/Text';
import Tooltip from 'js/design-system/Tooltip/Tooltip';
import useAppDispatch from 'js/hooks/useAppDispatch';
import useElementRect from 'js/hooks/useElementRect';
import useNumberParams from 'js/hooks/useNumberParams';
import queries from 'js/queries';
import { useJobTypeLevels } from 'js/queries/jobLevels';
import { getAll as departmentsSelector } from 'js/selectors/departments';
import { filterEmployees, WORK_PERIOD } from 'js/services/employeeFilterService';
import {
  getVerifyValue,
  groupEmployees as groupEmployeesByField,
  verified,
  VERIFIED,
  getVerifiedFields,
} from 'js/services/employeeService';
import { getJobLevels, isValidJobLevelId } from 'js/services/levelService';
import { Department, Employee, EmployeeBenchmarkForm, GoogleMapLibraries, JobLevel } from 'types';

import ConfirmButton from './ConfirmButton';
import { DepartmentPercentileCell } from './DepartmentPercentileCell';
import { EmployeeCell } from './EmployeeCell';
import { EmployeePercentileCell } from './EmployeePercentileCell';
import { JobRoleInput } from './JobRoleInput';
import { LevelDropdown } from './LevelDropdown';
import { LocationNameInput } from './LocationNameInput';
import MultiStatesBulkEditDrawer, { BulkEditForm } from './MultiStatesBulkEditDrawer';
import { getDepartmentHash } from './utils';

import styles from './BenchmarkingTable.module.scss';

export interface ExtendedEmployee extends Employee {
  confirmed: boolean;
}

interface BenchmarkTableProps {
  benchmarkId: number;
  selection: Set<string>;
  setSelection: React.Dispatch<React.SetStateAction<Set<string>>>;
  setEmployeesConfirmed?: React.Dispatch<React.SetStateAction<EmployeeBenchmarkForm[]>>;
  contentContainerRef?: React.RefObject<HTMLDivElement>;
  drawerClassName?: string;
  noHeader?: boolean;
}

const BenchmarkingTable = ({
  selection,
  setSelection,
  benchmarkId,
  setEmployeesConfirmed = () => {},
  contentContainerRef,
  drawerClassName,
  noHeader,
}: BenchmarkTableProps) => {
  const { scenarioId } = useNumberParams();
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const { data: allEmployees = [], isSuccess: employeesLoaded } = useQuery(
    queries.scenarios.detail(scenarioId)._ctx.employees(),
  );
  const { data: jobTypeLevels } = useJobTypeLevels(scenarioId);
  const departments = useSelector(departmentsSelector);
  const departmentsById = useMemo(
    () =>
      departments.reduce(
        (acc: Record<number, string>, dept: Department) => ({
          ...acc,
          [dept.id]: dept.name,
        }),
        {},
      ),
    [departments],
  );

  const { data: jobRolesById = [], isLoading: jobRolesLoading } = useJobRolesById(
    benchmarkId,
    scenarioId,
  );

  const [currentTab, setCurrentTab] = useState('unconfirmed');
  // The table's source of truth. Changing this re-renders the table.
  const [employeesById, setEmployeesById] = useState<Record<string, ExtendedEmployee>>({});
  // Formatted employee data used to render the table.
  const [data, setData] = useState<RowGroup[]>([]);
  // A filtered copy of `data`. Filtered based on user choices.
  const [filteredData, setFilteredData] = useState<RowGroup[]>([]);
  // Keep track of which employees have been interacted with.
  // Needed to preserve "unconfirmed" status of rows where varify = 0 but user has not clicked "confirm".
  const [employeesInteracted, setEmployeesInteracted] = useState<EmployeeBenchmarkForm[]>([]);
  // Keep track of which employees are being updated. Displays a loading state on the percentile cell.
  const [employeesUpdating, setEmployeesUpdating] = useState<number[]>([]);
  // Keep track of which employees are being confirmed. Displays a loading state on all cells.
  const [employeesConfirming, setEmployeesConfirming] = useState<number[]>([]);
  const [headerRect, headerRef] = useElementRect<HTMLDivElement>(false);
  const headerHeight = headerRect?.height ?? 0;
  const tableContentRef = useRef<HTMLDivElement | null>(null);
  const backupVerifyEmployeeDataRef = useRef<{ [key: string]: number }>({});

  const { trackEvent } = useTrackEvent<OnboardBenchmarkEmployeeEventDetails>();

  const tabNumbers = Object.values(employeesById).reduce(
    (acc: { confirmed: number; unconfirmed: number }, employee: ExtendedEmployee) => {
      if (employee.confirmed) {
        acc.confirmed += 1;
      } else {
        acc.unconfirmed += 1;
      }

      return acc;
    },
    { confirmed: 0, unconfirmed: 0 },
  );

  const tabs: Tab<string>[] = noHeader
    ? [{ key: 'confirmed', name: undefined }]
    : [
        {
          key: 'unconfirmed',
          name: (
            <div className={styles.tabContent}>
              <span>Needs review ({tabNumbers.unconfirmed})</span>
            </div>
          ),
        },
        {
          key: 'confirmed',
          name: `Confirmed (${tabNumbers.confirmed})`,
        },
        {
          key: 'all',
          name: `Show all (${tabNumbers.confirmed + tabNumbers.unconfirmed})`,
        },
      ];

  const updateEmployees = useCallback(
    async (scenarioId: number, params: EmployeeBenchmarkForm[]) => {
      const employees = await bulkUpdateEmployees(scenarioId, params);
      queryClient.invalidateQueries(queries.scenarios.detail(scenarioId)._ctx.employees._def);
      return employees;
    },
    [queryClient],
  );

  const filterByJobTitle = (val: TextFieldValue) => {
    const rowFilter = (row: Row) => {
      const employee = employeesById[row.id];

      if (!employee.jobTitle) return false;

      const normalizedVal = String(val).trim().toLowerCase();
      const normalizedJobTitle = employee.jobTitle.toLowerCase();

      return normalizedJobTitle.includes(normalizedVal);
    };

    const filtered = data
      .filter((rowGroup) => rowGroup.rows.filter(rowFilter).length > 0)
      .map((rowGroup) => ({ ...rowGroup, rows: rowGroup.rows.filter(rowFilter) }));

    setFilteredData(filtered);
  };

  const filterByConfirmed = (data: RowGroup[], currentTab: string) => {
    const confirmedFilter = (row: Row) => {
      const employee = employeesById[row.id];
      if (currentTab === 'confirmed' && employee.confirmed) {
        return true;
      }
      if (currentTab === 'unconfirmed' && !employee.confirmed) {
        return true;
      }
      if (currentTab === 'all') {
        return true;
      }
      return false;
    };

    const filtered = data
      .filter((rowGroup) => rowGroup.rows.filter(confirmedFilter).length > 0)
      .map((rowGroup) => ({ ...rowGroup, rows: rowGroup.rows.filter(confirmedFilter) }));

    setFilteredData(filtered);
  };

  const handleTabClick = (tab: string) => {
    setCurrentTab(tab);
    filterByConfirmed(data, tab);
    setSelection(new Set());
  };

  const updateEmployee = async (
    employeeId: number,
    field: keyof EmployeeBenchmarkForm,
    value: string | number,
  ) => {
    setEmployeesUpdating((curr) => [...curr.filter((id) => id !== employeeId), employeeId]);

    const newEmployee = { ...employeesById[employeeId], [field]: value };

    // update the in memory object to update table real-time
    setEmployeesById((curr) => ({
      ...curr,
      [newEmployee.id]: newEmployee,
    }));

    // determine the fields to verify
    const fieldName =
      field === 'jobRoleId' ? 'jobRole' : field === 'locationName' ? 'location' : field;

    const newVerifiedFields = {
      ...getVerifiedFields(newEmployee),
      [fieldName]: true,
      ...(newEmployee.verify !== VERIFIED ? { confirmed: false } : {}),
    };

    // set new jobRole from jobRoleId
    // update level and newVerifiedFields if appropriate
    if (field === 'jobRoleId') {
      const numVal = value as number;
      const newJobRole = jobRolesById[numVal] || (await fetchJobRole(numVal, scenarioId));
      newEmployee.jobRole = newJobRole;

      const jobLevels = getJobLevels(jobTypeLevels, newJobRole);
      if (!isValidJobLevelId(jobLevels, newEmployee.jobLevelId)) {
        newEmployee.jobLevelId = jobLevels[0]?.id || null;
      }
    }

    // calculate the new verify number
    newEmployee.verify = getVerifyValue(newVerifiedFields);

    const payload = {
      id: newEmployee.id,
      jobRoleId: newEmployee.jobRoleId,
      jobLevelId: newEmployee.jobLevelId,
      locationName: newEmployee.locationName,
      verify: newEmployee.verify,
    };

    setEmployeesInteracted((curr) => [...curr.filter((p) => p.id !== newEmployee.id), payload]);

    updateEmployees(scenarioId, [payload]).then(() => {
      setEmployeesUpdating((curr) => [...curr.filter((id) => id !== employeeId)]);
    });
  };

  const performUpdateEmployees = useCallback(
    async (selectedEmployees: ExtendedEmployee[], payload: EmployeeBenchmarkForm[]) => {
      const ids = selectedEmployees.map((e) => e.id);
      setEmployeesConfirming((curr) => [...curr.filter((id) => !ids.includes(id)), ...ids]);
      setEmployeesInteracted((curr) => curr.filter((p) => !ids.includes(p.id)));
      setEmployeesConfirmed((curr) => [
        ...curr.filter((e) => !ids.includes(e.id)),
        ...selectedEmployees,
      ]);
      await updateEmployees(scenarioId, payload);

      setEmployeesConfirming((curr) => [...curr.filter((id) => !selection.has(String(id)))]);
    },
    [scenarioId, selection, setEmployeesConfirmed, updateEmployees],
  );

  const bulkEdit = async (values: BulkEditForm) => {
    const newJobRoleId = values.jobRole?.value as number;
    const newJobLevelId = values.jobLevelId;
    const newLocationName = values.locationName;
    const selectedEmployees = [...selection].map((employeeId) => employeesById[Number(employeeId)]);

    const payload = selectedEmployees.map((employee) => ({
      id: employee.id,
      jobRoleId: newJobRoleId ? Number(newJobRoleId) : employee.jobRoleId,
      jobLevelId: !newJobLevelId || newJobLevelId === -1 ? employee.jobLevelId : newJobLevelId,
      locationName: newLocationName || employee.locationName,
      verify: employee.verify,
    }));

    await performUpdateEmployees(selectedEmployees, payload);

    dispatch(
      snackbar({
        message: 'Employee changes applied.',
      }),
    );

    trackEvent('onboard.benchmark.employee', { action: 'bulk_edit' });
  };

  const undoBulkConfirm = useCallback(async () => {
    const selectedEmployees: ExtendedEmployee[] = [];
    const payload = Object.entries(backupVerifyEmployeeDataRef.current).map(
      ([employeeId, verify]) => {
        const employee = employeesById[Number(employeeId)];
        selectedEmployees.push(employee);

        return {
          id: employee.id,
          verify,
        };
      },
    );

    backupVerifyEmployeeDataRef.current = {};

    await performUpdateEmployees(selectedEmployees, payload);

    dispatch(
      snackbar({
        message: 'Benchmarks unconfirmed.',
        showCloseButton: true,
      }),
    );
  }, [dispatch, employeesById, performUpdateEmployees]);

  const bulkConfirm = useCallback(async () => {
    backupVerifyEmployeeDataRef.current = [...selection].reduce(
      (dict, employeeId) => ({ ...dict, [employeeId]: employeesById[Number(employeeId)].verify }),
      {},
    );

    const selectedEmployees = [...selection].map((employeeId) => employeesById[Number(employeeId)]);
    const payload = selectedEmployees.map((employee) => ({
      id: employee.id,
      verify: VERIFIED,
    }));

    await performUpdateEmployees(selectedEmployees, payload);

    setSelection(new Set());

    dispatch(
      snackbar({
        message: 'Benchmarks confirmed.',
        actionText: 'Undo',
        icon: Check,
        showCloseButton: true,
        actionHandler: (closeHandler) => {
          undoBulkConfirm();
          closeHandler();
        },
      }),
    );

    trackEvent('onboard.benchmark.employee', { action: 'bulk_confirm' });
  }, [
    dispatch,
    employeesById,
    performUpdateEmployees,
    selection,
    setSelection,
    trackEvent,
    undoBulkConfirm,
  ]);

  // Individual form control handlers
  const onJobRoleSelect = async (roleId: number, employeeId: number) => {
    await updateEmployee(employeeId, 'jobRoleId', roleId);
    trackEvent('onboard.benchmark.employee', { action: 'role' });
  };

  const onLevelSelect = (jobLevel: JobLevel | null, employeeId: number) => {
    if (!jobLevel) {
      return;
    }
    updateEmployee(employeeId, 'jobLevelId', jobLevel.id);
    trackEvent('onboard.benchmark.employee', { action: 'level' });
  };

  const onLocationSelect = (locationName: string, employeeId: number) => {
    updateEmployee(employeeId, 'locationName', locationName);
    trackEvent('onboard.benchmark.employee', { action: 'location' });
  };

  const confirmEmployee = (employeeId: number) => {
    const employee = { ...employeesById[employeeId], verify: VERIFIED };
    setEmployeesConfirming((curr) => [...curr.filter((id) => id !== employee.id), employee.id]);
    setEmployeesInteracted((curr) => curr.filter((p) => p.id !== employee.id));
    setEmployeesConfirmed((curr) => [...curr.filter((e) => e.id !== employee.id), employee]);

    updateEmployees(scenarioId, [
      {
        id: employee.id,
        jobRoleId: employee.jobRoleId,
        jobLevelId: employee.jobLevelId,
        locationName: employee.locationName,
        verify: employee.verify,
      },
    ]).then(() => setEmployeesConfirming((curr) => [...curr.filter((id) => id !== employee.id)]));

    trackEvent('onboard.benchmark.employee', { action: 'confirm' });
  };

  const location = useLocation();
  const scrollToDepartmentHash = useRef(location.hash);

  const handleToggleDropdown = useCallback(() => {
    if (tableContentRef.current) {
      tableContentRef.current.style.height = 'unset';

      const newHeight = tableContentRef.current.scrollHeight + 15;
      tableContentRef.current.style.height = `${newHeight}px`;
    }
  }, []);

  const getData = () => {
    const employeesByDepartment = groupEmployeesByField(
      Object.values(employeesById),
      'departmentId',
    ) as Record<number, ExtendedEmployee[]>;

    return Object.entries(employeesByDepartment)
      .map(([departmentId, employees]) => {
        return {
          headerStyle: { top: `${headerHeight}px` },
          headerClassName: styles.tableHeader,
          headerRef: (ref: HTMLDivElement) => {
            let departmentHeader;
            if (getDepartmentHash(departmentId) === scrollToDepartmentHash.current) {
              departmentHeader = ref;
            }

            if (departmentHeader) {
              contentContainerRef?.current?.scrollTo({
                // REVIEW: Why does this number work?
                // I'm not 100% sure, but as far as I can tell, it's
                // + 36 for the table header
                // + 30 for the department header
                // - 15 for half of the department header, which ends up being scrolled halfway off the top
                // + 65 for the first employee in the department
                top: departmentHeader.offsetTop - 116,
                behavior: 'smooth',
              });

              scrollToDepartmentHash.current = '';
            }
          },
          groupRow: {
            label: {
              className: styles.name,
              content: <>{departmentsById[Number(departmentId)]}</>,
            },
            percentile: {
              className: styles.percentile,
              content: (
                <DepartmentPercentileCell
                  employees={employees}
                  loading={
                    employeesUpdating.some((id) => employees.map((e) => e.id).includes(id)) ||
                    employeesConfirming.some((id) => employees.map((e) => e.id).includes(id))
                  }
                />
              ),
            },
            jobRole: { className: styles.jobRole },
            level: { className: styles.level },
            location: { className: styles.location },
            contextMenu: { className: styles.contextMenu },
          },
          rows: employees.map((employee) => {
            const isEmployeeBeingConfirmed = employeesConfirming.includes(employee.id);
            const isEmployeeBeingUpdated = employeesUpdating.includes(employee.id);

            return {
              id: String(employee.id),
              name: {
                className: styles.name,
                content: <EmployeeCell employee={employee} />,
              },
              percentile: {
                className: styles.percentile,
                content: (
                  <EmployeePercentileCell
                    employee={employee}
                    loading={isEmployeeBeingUpdated || isEmployeeBeingConfirmed}
                  />
                ),
              },
              jobRole: {
                className: styles.jobRole,
                content: (
                  <JobRoleInput
                    benchmarkId={benchmarkId}
                    employee={employee}
                    onSelect={(roleId) => onJobRoleSelect(roleId, employee.id)}
                    loading={isEmployeeBeingConfirmed}
                    onToggle={handleToggleDropdown}
                  />
                ),
              },
              level: {
                className: styles.level,
                content: (
                  <LevelDropdown
                    employee={employee}
                    onSelect={(jobLevel) => onLevelSelect(jobLevel, employee.id)}
                    loading={isEmployeeBeingConfirmed}
                    onToggle={handleToggleDropdown}
                  />
                ),
              },
              location: {
                className: styles.location,
                content: (
                  <LocationNameInput
                    employee={employee}
                    onSelect={(locationName) => onLocationSelect(locationName, employee.id)}
                    loading={isEmployeeBeingConfirmed}
                    onToggle={handleToggleDropdown}
                  />
                ),
              },
              contextMenu: {
                className: styles.contextMenu,
                content: (
                  <ConfirmButton
                    confirmed={employee.confirmed}
                    loading={isEmployeeBeingConfirmed}
                    onClick={() => confirmEmployee(employee.id)}
                  />
                ),
              },
            };
          }),
        };
      })
      .sort((a, b) => {
        if (a.groupRow.label > b.groupRow.label) return 1;
        if (a.groupRow.label < b.groupRow.label) return -1;
        return 0;
      });
  };

  // update employeesById when app state changes
  useEffect(() => {
    setEmployeesById((curr) => {
      return filterEmployees(allEmployees, { workPeriodFilter: [WORK_PERIOD.current] }).reduce(
        (res: Record<number, ExtendedEmployee>, employee: ExtendedEmployee) => {
          const previousChanges = employeesInteracted.find((p) => p.id === employee.id);
          const confirmed = previousChanges ? curr[employee.id].confirmed : verified(employee);

          return {
            ...res,
            [employee.id]: {
              ...employee,
              confirmed,
            },
          };
        },
        {},
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allEmployees]);

  // regenerate table data when employeesById changes
  useEffect(() => {
    if (employeesLoaded && !jobRolesLoading) {
      const data = getData();
      if (data) {
        dispatch(disableSpinner());
      }
      setData(data);
      filterByConfirmed(data, currentTab);
    } else {
      dispatch(enableSpinner());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    departmentsById,
    employeesById,
    employeesConfirming,
    employeesLoaded,
    employeesUpdating,
    jobRolesById,
    jobRolesLoading,
    headerHeight,
  ]);

  // maps API has to be loaded before we can render any location fields
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
    libraries: GLOBALS.googleMapLibraries as GoogleMapLibraries,
  });

  if (loadError || !isLoaded) return null;
  const selectionJobLevelIds = new Set(
    [...selection].map((employeeId) => employeesById[Number(employeeId)].jobLevelId),
  );
  const initialJobLevelId =
    selectionJobLevelIds.size === 1 ? [...selectionJobLevelIds][0] : undefined;
  const selectionLocationNames = new Set(
    [...selection].map((employeeId) => employeesById[Number(employeeId)].locationName || undefined),
  );

  const selectionRoleIds = new Set(
    [...selection].map((employeeId) => employeesById[Number(employeeId)].jobRoleId || undefined),
  );
  const initialLocationName =
    selectionLocationNames.size === 1 ? [...selectionLocationNames][0] : undefined;

  const jobRole =
    selectionRoleIds.size === 1 ? employeesById[Number([...selection][0])].jobRole : undefined;

  let initialJobRole: SuggestJobRole | undefined;
  if (jobRole) {
    initialJobRole = {
      id: jobRole.id || 0,
      name: jobRole.name || '',
      jobType: jobRole.jobType || undefined,
      validLevels: jobRole.validLevels || undefined,
    };
  }

  return (
    <>
      <Table
        columns={[
          { id: 'name', display: 'Employee Name', size: 'large', className: styles.name },
          { id: 'percentile', display: 'Salary / OTE %ile', className: styles.percentile },
          { id: 'jobRole', display: 'Benchmark Role', size: 'large', className: styles.jobRole },
          { id: 'level', display: 'Benchmark Level', className: styles.level },
          {
            id: 'location',
            display: 'Employee Location',
            size: 'large',
            className: styles.location,
          },
          {
            id: 'contextMenu',
            display: <ConfirmButton confirmed={false} loading={false} onClick={() => null} />,
            size: 'medium',
            hidden: true,
            className: styles.contextMenu,
          },
        ]}
        data={filteredData}
        selection={selection}
        setSelection={setSelection}
        filters={
          noHeader ? undefined : (
            <TextField
              name="jobTitle"
              placeholder="Type to filter"
              label="Job title"
              labelPosition="top"
              onChange={filterByJobTitle}
            />
          )
        }
        tableLegend={
          noHeader ? undefined : (
            <Text color="platform-gray-700" className={styles.tableLegend}>
              <>
                <div className={styles.verifyCircle} />= Low confidence
                <Tooltip content="Fields marked low confidence are ones in which we’ve attempted to make an informed match to our database of job roles, but we could not make a match with high certainty. We recommend reviewing low confidence fields before confirming an employee.">
                  <Icon
                    icon={Info}
                    size={16}
                    color="platform-gray-700"
                    className={styles.infoIcon}
                  />
                </Tooltip>
              </>
            </Text>
          )
        }
        tabs={tabs}
        handleTabClick={handleTabClick}
        className={classNames(styles.benchmarkingTable, {
          [styles.bottomMargin]: selection.size >= 2,
        })}
        tableContentRef={tableContentRef}
        tableContentClassName={styles.tableContent}
        headerRowGroupClassName={styles.headerRowGroup}
        headerRowGroupRef={headerRef}
        rowGroupClassName={styles.rowGroup}
        rowClassName={styles.row}
        checkboxClassName={styles.rowCheckbox}
      />

      <MultiStatesBulkEditDrawer
        benchmarkId={benchmarkId}
        selected={selection}
        setSelected={setSelection}
        onChangeAll={bulkEdit}
        onConfirmAll={bulkConfirm}
        initialJobLevelId={initialJobLevelId}
        initialLocationName={initialLocationName}
        initialJobRole={initialJobRole}
        className={drawerClassName}
      />
    </>
  );
};

export default BenchmarkingTable;
