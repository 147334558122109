import classnames from 'classnames';
import React from 'react';

import styles from './Section.module.scss';

interface SectionProps {
  header?: React.ReactNode;
  subHeader?: React.ReactNode;
  children: React.ReactNode;
  containerClassName?: string;
}

const Section = ({ header, subHeader, children, containerClassName }: SectionProps) => {
  return (
    <div className={classnames(styles.section, containerClassName)}>
      <h4 className={styles.header}>{header}</h4>
      {subHeader && <div className={styles.subHeader}>{subHeader}</div>}
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default Section;
