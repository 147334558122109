import { CaretLeft, CaretRight } from '@phosphor-icons/react';
import classNames from 'classnames';
import { ReactNode, useEffect, useState } from 'react';

import Icon from 'js/design-system/Icon';

import styles from './Carousel.module.scss';

interface CarouselProps {
  items: ReactNode[];
  className: string;
}

const iconSize = 16;
const iconColor = 'platform-gray-500';

const Carousel = ({ items, className }: CarouselProps) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  useEffect(() => {
    setCurrentIndex((prev) => (items.length < prev ? 0 : prev));
  }, [items]);

  const onPrevClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + items.length) % items.length);
  };

  const onNextClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
  };

  return (
    <div className={classNames(className, { [styles.visibleCarousel]: items.length > 0 })}>
      <div>
        {items.map((item, index) => (
          <div
            className={classNames(styles.item, {
              [styles.activeItem]: Number(index) === currentIndex,
            })}
            /* eslint-disable-next-line react/no-array-index-key */
            key={index}
          >
            {item}
          </div>
        ))}
      </div>
      {items.length > 1 && (
        <div className={styles.indicators}>
          <button className={classNames(styles.prev)} onClick={onPrevClick}>
            <Icon icon={CaretLeft} size={iconSize} color={iconColor} />
          </button>
          <div className={styles.dots}>
            {items.map((item, index) => (
              <button
                className={classNames(styles.dot, { [styles.activeDot]: index === currentIndex })}
                onClick={() => setCurrentIndex(index)}
                /* eslint-disable-next-line react/no-array-index-key */
                key={index}
              >
                {' '}
              </button>
            ))}
          </div>
          <button className={classNames(styles.next)} onClick={onNextClick}>
            <Icon icon={CaretRight} size={iconSize} color={iconColor} />
          </button>
        </div>
      )}
    </div>
  );
};

export default Carousel;
