import { X } from '@phosphor-icons/react';
import classNames from 'classnames';
import { ElementType, useRef, useState } from 'react';
import { useForm } from 'react-final-form';

import { EmployeeUploadEventDetails } from 'js/analytics/types/employee';
import useTrackEvent from 'js/analytics/useTrackEvent';
import { getEventSourceValueByCurrentRoute } from 'js/analytics/utils';
import { FileInput } from 'js/components-legacy/form';
import { ButtonSize } from 'js/design-system/Button/types';
import Icon from 'js/design-system/Icon';

import styles from './FileUploadButton.module.scss';

interface FileUploadButtonProps {
  label: React.ReactNode;
  onChange?(value: File | null): void;
  importErrors?: string[];
  buttonSize?: ButtonSize;
  leftIcon?: ElementType;
  rightIcon?: ElementType;
  className?: string;
  uploadBtnClassName?: string;
  onClear?(): void;
}

const FileUploadButton = ({
  label,
  onChange = () => {},
  importErrors = [],
  buttonSize,
  leftIcon,
  rightIcon,
  className,
  uploadBtnClassName,
  onClear = () => {},
}: FileUploadButtonProps) => {
  const form = useForm();
  const [file, setFile] = useState<File | null>();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { trackEvent } = useTrackEvent<EmployeeUploadEventDetails>();

  const handleFileChange = (aFile: File | null) => {
    if (aFile) {
      trackEvent('employee.upload', { source: getEventSourceValueByCurrentRoute() });
    }
    setFile(aFile);
    onChange(aFile);
  };

  const clearFile = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    event.preventDefault();
    handleFileChange(null);
    form.change('importFile', null);
    if (inputRef.current) inputRef.current.value = '';
    onClear();
  };

  return (
    <FileInput
      name="importFile"
      accept=".csv, .xlsx"
      leftIcon={!file ? leftIcon : undefined}
      buttonSize={buttonSize}
      chooseFileLabel={
        file ? (
          <span className={styles.clearable}>
            <span className={styles.clearableFileName}>{file.name}</span>
            <div
              role="button"
              className={styles.clearButton}
              onClick={clearFile}
              onKeyPress={undefined}
              tabIndex={0}
            >
              <Icon icon={rightIcon || X} size={24} color="platform-gray-700" />
            </div>
          </span>
        ) : (
          label
        )
      }
      uploadBtnClassName={uploadBtnClassName}
      groupClassName={classNames(styles.inputGroup, className)}
      inputClassName={styles.fileInput}
      fileBoxClassName={styles.fileBox}
      onChange={handleFileChange}
      errors={importErrors}
      inputRef={inputRef}
    />
  );
};

export default FileUploadButton;
