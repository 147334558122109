import { CaretDown, Copy } from '@phosphor-icons/react';
import classNames from 'classnames';
import { useState } from 'react';

import { snackbar } from 'js/actions/snackbars';
import { GeneralCopyJobPostEventDetails } from 'js/analytics/types/general';
import useTrackEvent from 'js/analytics/useTrackEvent';
import { RangeLookupData, RangeBand, CompType } from 'js/components/data-lookup/ranges/types';
import ComplexAlert from 'js/design-system/Alert/ComplexAlert';
import Button from 'js/design-system/Button/Button';
import { ButtonSize, ButtonVariant } from 'js/design-system/Button/types';
import Menu, { MenuItem } from 'js/design-system/Menu/Menu';
import Text from 'js/design-system/Text/Text';
import TextButton from 'js/design-system/TextButton/TextButton';
import useAppDispatch from 'js/hooks/useAppDispatch';
import useShowEquity from 'js/hooks/useShowEquity';
import { useExchangeRate } from 'js/queries/benchmarks';
import { formatCash, formatEquity } from 'js/services/compensations/formatter';
import { getLevelRangeText } from 'js/services/levelService';
import { CurrencyCode } from 'js/utils/formatters/currency';
import { isNumber } from 'js/utils/value';
import { EquityDisplayType, Scenario } from 'types';

import { NoDataAlert } from '../common/NoDataAlert';
import { ResultsHeader } from '../common/ResultsHeader';
import { AtRiskEmployeesModal } from './AtRiskEmployeesModal';
import { Breakdown } from './Breakdown';
import { ConsiderationsIcon } from './ConsiderationsIcon';
import { WhatCanIDo } from './WhatCanIDo';
import { useConsiderations } from './useConsiderations';

import styles from './JobPostRangeResults.module.scss';

export const JobPostRangeResults = ({
  data,
  scenario,
}: {
  data: RangeLookupData;
  scenario: Scenario;
}) => {
  const dispatch = useAppDispatch();
  const { trackEvent } = useTrackEvent<GeneralCopyJobPostEventDetails>();

  const menuItems: MenuItem<CompType>[] = [
    {
      value: 'cash',
      label: data.commissionBased ? 'OTE' : 'Base Salary',
      data: { shortLabel: data.commissionBased ? 'OTE' : 'Base Salary' },
    },
    {
      value: 'shares',
      label: 'Equity as Number of Shares',
      data: { shortLabel: 'Equity as Shares' },
    },
    {
      value: 'percent',
      label: 'Equity as Percent Ownership',
      data: { shortLabel: 'Equity as Percent' },
    },
  ];

  const [selectedItem, setSelectedItem] = useState<MenuItem<CompType>>(menuItems[0]);
  const [toggleMenuOpen, setToggleMenuOpen] = useState(false);
  const [breakdownOpen, setBreakdownOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [currencyCode, setCurrencyCode] = useState<CurrencyCode>('USD');
  const exchangeRate = useExchangeRate(scenario.benchmarkId, currencyCode) ?? 1;

  const jobLevels = data.tiers[0].cash
    .sort((a, b) => a.jobLevel.order - b.jobLevel.order)
    .map((band: RangeBand) => band.jobLevel);

  const {
    attritionWarning,
    levelWarning,
    spreadWarning,
    allClear,
    numConsiderations,
    CONSIDERATIONS,
  } = useConsiderations(jobLevels, data.spread, data.atRiskEmployees);

  const showEquity = useShowEquity();

  const RANGE_MIN = selectedItem.value === 'cash' ? data.minCash : data.minEquity;
  const RANGE_MAX = selectedItem.value === 'cash' ? data.maxCash : data.maxEquity;

  if (!isNumber(RANGE_MIN) || !isNumber(RANGE_MAX)) {
    return <NoDataAlert />;
  }

  const equityDisplayType =
    // eslint-disable-next-line no-nested-ternary
    selectedItem.value === 'shares'
      ? EquityDisplayType.NumOfShares
      : selectedItem.value === 'percent'
      ? EquityDisplayType.PercentOwnership
      : EquityDisplayType.None;

  const formattedMin =
    selectedItem.value === 'cash'
      ? formatCash(RANGE_MIN * exchangeRate, { currency: currencyCode })
      : formatEquity(RANGE_MIN, equityDisplayType, scenario.outstandingShares, true);

  const formattedMax =
    selectedItem.value === 'cash'
      ? formatCash(RANGE_MAX * exchangeRate, { currency: currencyCode })
      : formatEquity(RANGE_MAX, equityDisplayType, scenario.outstandingShares, true);

  const rangeText = `${formattedMin} – ${formattedMax}`;
  const toCopyText = `${formattedMin} – ${formattedMax}. This pay range is OpenComp-verified. That means it uses fresh data, is specific to our industry & company size, and applies to this role and your location.`;

  return (
    <div className={styles.container}>
      <ResultsHeader
        tierText={data.tiers.length === 1 ? '1 Tier' : `${data.tiers.length} Tiers`}
        tierTooltip={
          <span style={{ whiteSpace: 'pre' }}>{data.tiers.map((t) => t.tierName).join('\n')}</span>
        }
        levelText={getLevelRangeText(jobLevels)}
        selectedCurrency={currencyCode}
        onCurrencyChange={setCurrencyCode}
      />

      <div className={styles.resultsCard}>
        <div className={styles.topRow}>
          <Text variant="text-subheading-large">{rangeText}</Text>

          <ConsiderationsIcon {...CONSIDERATIONS[numConsiderations > 0 ? 'warn' : 'ok']} />

          <Button
            variant={ButtonVariant.Outlined}
            size={ButtonSize.Small}
            leftIcon={Copy}
            onClick={() => {
              trackEvent('general.copy.jobPostClick');
              navigator.clipboard.writeText(toCopyText);
              dispatch(snackbar({ message: 'Range values copied to clipboard.', icon: Copy }));
            }}
          >
            Copy
          </Button>

          {showEquity && (
            <Menu
              trigger={
                <Button
                  variant={ButtonVariant.Ghost}
                  size={ButtonSize.Medium}
                  rightIcon={CaretDown}
                  className={classNames({ [styles.active]: toggleMenuOpen })}
                >
                  {selectedItem.data?.shortLabel as string}
                </Button>
              }
              menuItems={[
                ...menuItems.map((item) => ({
                  ...item,
                  default: selectedItem.value === item.value,
                })),
              ]}
              onSelect={(val, item) => setSelectedItem(item as MenuItem<CompType>)}
              onOpen={() => setToggleMenuOpen(true)}
              onClose={() => setToggleMenuOpen(false)}
              placement="bottom-end"
              highlightSelectedItem
              menuContainerClassName={styles.toggle}
            />
          )}
        </div>

        <div className={styles.copyText}>Copy and paste this pay range into your job posting.</div>

        {(data.tiers.length > 1 || jobLevels.length > 1) && (
          <>
            <div className={styles.divider} />
            <TextButton onClick={() => setBreakdownOpen((curr) => !curr)}>
              {breakdownOpen ? 'Hide' : 'See'} range breakdown
            </TextButton>
            <div
              className={classNames(styles.breakdownContainer, { [styles.open]: breakdownOpen })}
            >
              <Breakdown
                data={data}
                compType={selectedItem.value}
                outstandingShares={scenario.outstandingShares}
                currencyCode={currencyCode}
                exchangeRate={exchangeRate}
              />
            </div>
          </>
        )}
      </div>

      {attritionWarning && (
        <ComplexAlert
          {...CONSIDERATIONS.warn}
          title="Employee attrition risk"
          subtitle={
            <div>
              We found&nbsp;
              <TextButton onClick={() => setShowModal(true)}>
                {data.atRiskEmployees.length === 1
                  ? '1 person who is'
                  : `${data.atRiskEmployees.length} people who are`}
              </TextButton>
              &nbsp;paid below the range displayed above. Posting this range publicly may reduce
              employee engagement.&nbsp;
              <WhatCanIDo text="Consider adjusting employee pay before publishing your job post." />
            </div>
          }
        />
      )}

      {levelWarning && (
        <ComplexAlert
          {...CONSIDERATIONS.warn}
          title="Candidate acceptance risk"
          subtitle={
            <div>
              <span style={{ fontWeight: '700' }}>{jobLevels.length}</span> levels were selected.
              Our compensation specialists recommend that a single job post include no more than 2
              levels. A wide job post range can erode candidate trust.&nbsp;
              <WhatCanIDo text="Create separate job posts with distinct job titles, each including up to 2 levels." />
            </div>
          }
        />
      )}

      {spreadWarning && (
        <ComplexAlert
          {...CONSIDERATIONS.warn}
          title="Candidate acceptance risk"
          subtitle={
            <div>
              The spread of the selected geo tiers is&nbsp;
              <span style={{ fontWeight: '700' }}>{data.spread.value}%</span>. Our compensation
              specialists recommend no more than 25%. A wide job post range can erode candidate
              trust.&nbsp;
              <WhatCanIDo text="Choose tiers that are relatively close in cost of labor, such as those spanning a single country (India) or region (Central America)." />
            </div>
          }
        />
      )}

      {allClear && (
        <ComplexAlert
          {...CONSIDERATIONS.ok}
          title="Ready to publish"
          subtitle={
            <div>
              This range is expected to be compliant, increase candidate trust, and maintain
              employee retention. It is specific to your company and appropriately sized. Plus, all
              employees that fit your selected criteria are paid at or above this range.
            </div>
          }
        />
      )}

      <AtRiskEmployeesModal
        employeeIds={data.atRiskEmployees}
        rangeMin={RANGE_MIN}
        commissionBased={data.commissionBased}
        show={showModal}
        onClick={() => setShowModal(false)}
      />
    </div>
  );
};
