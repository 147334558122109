import { createQueryKeys } from '@lukemorales/query-key-factory';

import {
  fetchActiveRange,
  fetchDraftRange,
  fetchRangePreviews,
  fetchRangeJobFamilies,
} from 'js/api/ranges';
import { PayRangeStatus } from 'types';

const payRanges = createQueryKeys('payRanges', {
  oneByScenarioAndStatus: (scenarioId: number, status: PayRangeStatus) => ({
    queryKey: [scenarioId, status],
    queryFn: () =>
      (status === PayRangeStatus.Active ? fetchActiveRange : fetchDraftRange)(scenarioId),
  }),
  detail: (id: number) => ({
    queryKey: [id],
    contextQueries: {
      jobFamilies: {
        queryKey: null,
        queryFn: () => fetchRangeJobFamilies(id),
      },
      previews: {
        queryKey: null,
        queryFn: () => fetchRangePreviews(id),
      },
    },
  }),
});

export default payRanges;
