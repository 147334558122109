import { createQueryKeys } from '@lukemorales/query-key-factory';
import {
  QueryFunction,
  QueryKey,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';

import { formatError } from 'js/components-legacy/common/AlertMessage';
import { EmployeeUser } from 'types';

import api from './api';

export const fetchScenarioEmployeesUsers = async (scenarioId: number | string) => {
  const { success, data, error } = await api.get<EmployeeUser[]>(
    `/scenarios/${scenarioId}/employees_users`,
  );
  if (success) {
    return data;
  }

  throw new Error(formatError(error) || 'Failed to fetch employees users.');
};

export const queryKeys = createQueryKeys('employees_users', {
  list: (scenarioId: number) => ({
    queryKey: [scenarioId],
    queryFn: () => fetchScenarioEmployeesUsers(scenarioId),
  }),
});

export const useScenarioEmployeesUsers = (
  scenarioId: number,
  options?: UseQueryOptions<EmployeeUser[]>,
) => {
  return useQuery<EmployeeUser[]>(
    queryKeys.list(scenarioId).queryKey,
    queryKeys.list(scenarioId).queryFn as QueryFunction<EmployeeUser[], QueryKey>,
    options,
  );
};

export const useUpdateScenarioEmployeesUsersQueries = (scenarioId: number) => {
  const queryClient = useQueryClient();

  return () => {
    queryClient.invalidateQueries(queryKeys.list(scenarioId).queryKey);
  };
};
