import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';

import { disableSpinner, enableSpinner } from 'js/actions/spinners';
import { ScenarioViewDefaultEventDetails } from 'js/analytics/types/scenario';
import useTrackEvent from 'js/analytics/useTrackEvent';
import DashboardBanner from 'js/components/banners/dashboard/DashboardBanner';
import ScenarioNavBar from 'js/components/navbar/ScenarioNavBar';
import useNumberParams from 'js/hooks/useNumberParams';
import queries from 'js/queries';

interface MainScenarioTabProps {
  showBanner?: boolean;
}

const MainScenarioTab = ({ showBanner = true }: MainScenarioTabProps) => {
  const { scenarioId } = useNumberParams();
  const { isSuccess: employeesLoaded } = useQuery(
    queries.scenarios.detail(scenarioId)._ctx.employees(),
  );

  const { trackEvent } = useTrackEvent<ScenarioViewDefaultEventDetails>();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(employeesLoaded ? disableSpinner() : enableSpinner());
  }, [employeesLoaded, dispatch]);

  useEffect(() => {
    trackEvent('scenario.view.default', { scenarioId });
  }, [scenarioId, trackEvent]);

  return (
    <>
      <ScenarioNavBar />
      <DashboardBanner show={showBanner} />
      <Outlet />
    </>
  );
};

export default MainScenarioTab;
