import { AnyAction, combineReducers } from 'redux';

import ACTIONS from '../constants/actions';
import activityLogReducer from './activityLog';
import apiClientsReducer from './apiClients';
import bannerReducer from './banners';
import cashConnectionReducer from './cashConnections';
import companiesReducer from './companies';
import companyUsersReducer from './companyUsers';
import confettiReducer from './confetti';
import departmentsReducer from './departments';
import elementSizesReducer from './elementSizes';
import employeeFormReducer from './employeeForm';
import employeesReducer from './employees';
import exchangeRatesReducer from './exchangeRates';
import industrySectors from './industrySectors';
import jobAreasReducer from './jobAreas';
import locationsReducer from './locations';
import offersReducer from './offers';
import permissionsReducer from './permissions';
import rangeBandsReducer from './rangeBands';
import scenarioEmployeesUsersReducer from './scenarioEmployeesUsers';
import scenarioUsersReducer from './scenarioUsers';
import scenarioViewReducer from './scenarioView';
import scenariosReducer from './scenarios';
import sessionReducer from './sessions';
import snackbarsReducer from './snackbars';
import spinnerReducer from './spinners';
import strategyTemplatesReducer from './strategyTemplates';

const childReducers = {
  userInfo: sessionReducer,
  companies: companiesReducer,
  scenarios: scenariosReducer,
  spinner: spinnerReducer,
  departments: departmentsReducer,
  employees: employeesReducer,
  employeeForm: employeeFormReducer,
  locations: locationsReducer,
  exchangeRates: exchangeRatesReducer,
  industrySectors,
  scenarioView: scenarioViewReducer,
  cashConnection: cashConnectionReducer,
  banners: bannerReducer,
  confetti: confettiReducer,
  permissions: permissionsReducer,
  companyUsers: companyUsersReducer,
  scenarioUsers: scenarioUsersReducer,
  scenarioEmployeesUsers: scenarioEmployeesUsersReducer,
  rangeBands: rangeBandsReducer,
  strategyTemplates: strategyTemplatesReducer,
  jobAreas: jobAreasReducer,
  apiClients: apiClientsReducer,
  offers: offersReducer,
  snackbars: snackbarsReducer,
  activityLog: activityLogReducer,
  elementSizes: elementSizesReducer,
};

const appReducer = combineReducers({ ...childReducers });
export type ReceivedState = Parameters<typeof appReducer>[0];
type ReturnedState = ReturnType<typeof appReducer>;

const reducer = (state: ReceivedState, action: AnyAction = { type: null }): ReturnedState => {
  switch (action.type) {
    case ACTIONS.USER_SIGNOUT:
    case ACTIONS.RELEASE_DETECTED:
      return appReducer(undefined, action); // reset combined reducers to their initialStates
    default:
      return appReducer(state, action);
  }
};

export default reducer;
