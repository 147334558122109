import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useQuery } from '@tanstack/react-query';

import { fetchBenchmark } from 'js/api/analyst/benchmarks';
import { fetchBenchmarks } from 'js/api/benchmarks';
import { fetchExchangeRates } from 'js/api/exchangeRates';
import { fetchBenchmarkLocations } from 'js/api/locations';
import { fetchBenchmarkSegmentGroups } from 'js/api/segmentGroups';
import { CurrencyCode } from 'js/utils/formatters/currency';
import { isNumber } from 'js/utils/value';

const benchmarks = createQueryKeys('benchmarks', {
  list: {
    queryKey: null,
    queryFn: () => fetchBenchmarks(),
  },
  detail: (id: number) => ({
    queryKey: [id],
    queryFn: () => fetchBenchmark(id),
    contextQueries: {
      exchangeRates: {
        queryKey: null,
        queryFn: () => fetchExchangeRates(id),
      },
      locations: {
        queryKey: null,
        queryFn: () => fetchBenchmarkLocations(id),
      },
      segmentGroups: {
        queryKey: null,
        queryFn: () => fetchBenchmarkSegmentGroups(id),
      },
    },
  }),
});

export const useExchangeRate = (
  benchmarkId: number | null | undefined,
  currencyCode: CurrencyCode | null | undefined,
) => {
  const { data: exchangeRates } = useQuery({
    ...benchmarks.detail(Number(benchmarkId))._ctx.exchangeRates,
    enabled: isNumber(benchmarkId),
  });

  const code = currencyCode || 'USD';

  return exchangeRates?.find((er) => er.currencyTo === code)?.exchangeRate || null;
};

export default benchmarks;
