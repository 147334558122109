import { createSelector } from 'reselect';

import { AppState, Scenario } from 'types';

export const getAll = createSelector(
  (state: AppState) => state.scenarios.allIds,
  (state: AppState) => state.scenarios.byId,
  (allIds, byId) => allIds.map((id) => byId[id]),
);

export const getById = (state: AppState, id?: number): Scenario | undefined =>
  state.scenarios?.byId[Number(id)];

export const getByIdOrBase = (state: AppState, id?: number): Scenario | undefined => {
  const scenario = state.scenarios.byId[Number(id)];
  if (scenario) return scenario;

  const allScenarios = state.scenarios.allIds.map((scenarioId) => state.scenarios.byId[scenarioId]);
  return allScenarios.find((s) => s.scenarioType === 'base');
};

export const currentScenarioCompanyIdSelector = createSelector(
  (state: AppState) => state.scenarios.companyId,
  (companyId) => companyId,
);
