import { useEffect, useMemo } from 'react';
import { useForm, useFormState } from 'react-final-form';

import LevelMatrixLink from 'js/components/benchmarking/LevelMatrixLink';
import { getLevelOptions } from 'js/components/common/level/utils';
import { Multiselect } from 'js/design-system/Form';
import { ConnectedFieldProps } from 'js/design-system/Form/ConnectedField';
import { MultiselectProps } from 'js/design-system/Form/Multiselect/Multiselect';
import { useJobLevels } from 'js/queries/jobLevels';
import { isValidJobLevelId } from 'js/services/levelService';
import { JobLevel } from 'types';

interface JobLevelMultiSelectorProps
  extends Partial<Omit<ConnectedFieldProps<MultiselectProps<number>>, 'onChange'>> {
  onChange?: (jobLevelIds: number[]) => void;
  scenarioId: number;
  fieldName?: string;
  jobRoleFieldName?: string;
}

const JobLevelMultiSelector = ({
  onChange,
  scenarioId,
  fieldName = 'jobLevelIds',
  jobRoleFieldName = 'jobRole',
  labelPosition = 'left',
  ...rest
}: JobLevelMultiSelectorProps) => {
  const form = useForm();
  const { [fieldName]: jobLevelIds, [jobRoleFieldName]: jobRole } = useFormState().values;

  const { data: jobLevels, isSuccess: isJobLevelsFetched } = useJobLevels(scenarioId, jobRole);
  const options = useMemo(
    () => getLevelOptions(jobLevels, { selectedOptionLongFormat: false }),
    [jobLevels],
  );

  const handleValidate = (jobLevelIds: any) => {
    if (!jobLevelIds || (Array.isArray(jobLevelIds) && jobLevelIds.length === 0)) {
      return 'This is a required field';
    }

    const sortAndMapToIds = (jobLevels: JobLevel[]) =>
      jobLevels
        .sort((a, b) => a.order - b.order)
        .map((jobLevel) => jobLevel.id)
        .join(',');

    if (Array.isArray(jobLevelIds) && isJobLevelsFetched) {
      const selectedJobLevels = jobLevels.filter((jobLevel) => jobLevelIds.includes(jobLevel.id));

      if (!sortAndMapToIds(jobLevels).includes(sortAndMapToIds(selectedJobLevels))) {
        return 'Levels must be sequential';
      }
    }

    return null;
  };

  useEffect(() => {
    if (
      isJobLevelsFetched &&
      jobLevelIds?.some((jobLevelId: number) => !isValidJobLevelId(jobLevels, jobLevelId))
    ) {
      const newJobLevelIds = jobLevels.map(({ id }) => id);
      form.change(fieldName, newJobLevelIds);
      onChange?.(newJobLevelIds);
    }
  }, [fieldName, form, isJobLevelsFetched, jobLevelIds, jobLevels, jobRole, onChange]);

  return (
    <Multiselect
      name={fieldName}
      label="Level"
      labelPosition={labelPosition}
      subLabel={<LevelMatrixLink text={<span>Level Matrix</span>} />}
      placeholder="Choose level"
      onChange={(val) => onChange?.(val || [])}
      options={options}
      topAlignedCheckbox
      validate={handleValidate}
      {...rest}
    />
  );
};

export default JobLevelMultiSelector;
