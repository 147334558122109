import { AnyAction } from 'redux';

import ACTIONS from '../constants/actions';

type EmployeeFormState = {
  scrolledToField?: string;
};

const initialState: EmployeeFormState = {
  scrolledToField: undefined,
};

export default (
  state: EmployeeFormState = initialState,
  action: AnyAction = { type: null },
): EmployeeFormState => {
  switch (action.type) {
    case ACTIONS.EMPLOYEE_FORM_SCROLL_TO_FIELD: {
      const { scrollToField } = action.data;
      return { ...state, scrolledToField: scrollToField };
    }
    default:
      return state;
  }
};
