import { createQueryKeys } from '@lukemorales/query-key-factory';

import { fetchIndustrySectors } from 'js/api/industrySectors';

const industrySectors = createQueryKeys('industrySectors', {
  list: {
    queryKey: null,
    queryFn: fetchIndustrySectors,
  },
});

export default industrySectors;
