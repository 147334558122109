import api from './api';

export const connect = async (companyId: number, code: string, provider: string) => {
  const { success, data, error } = await api.post(`/companies/${companyId}/equity_connection`, {
    params: { code, provider },
  });
  if (success) {
    return data;
  }

  throw new Error(`Failed to connect equity: ${error}`);
};

export const disconnect = async (companyId: number) => {
  const { success, data, error } = await api.delete(`/companies/${companyId}/equity_connection`);
  if (success) {
    return data;
  }

  throw new Error(`Failed to disconnect equity connection: ${error}`);
};

export type ExternalSyncImport = {
  id: number;
  externalSyncId: number;
};

export const importCartaCsv = async (
  companyId: number,
  params: { importFileS3Path?: string; importFileFilename?: string },
) => {
  const { success, data } = await api.post<ExternalSyncImport>(
    `/companies/${companyId}/external_sync_imports`,
    {
      params,
    },
  );

  if (success) {
    return data;
  }

  throw new Error('Failed to import carta csv');
};
