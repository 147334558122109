import { useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import { ReactNode, useEffect, useMemo, useState } from 'react';

import { queryKeys as jobRoleQueryKeys } from 'js/api/jobRoles';
import RoleDescriptionsLink from 'js/components/benchmarking/RoleDescriptionsLink';
import Badge from 'js/design-system/Badge';
import { Suggest, Suggestion } from 'js/design-system/Form/Suggest/Suggest';
import Text from 'js/design-system/Text/Text';
import TextButton from 'js/design-system/TextButton/TextButton';
import useNumberParams from 'js/hooks/useNumberParams';
import { JobRoleWithLevels } from 'types';

import { JobRoleSuggestProps, SuggestJobRole } from './types';

import styles from './JobRoleSuggest.module.scss';

const JobRoleSuggest = ({
  onChange,
  benchmarkId,
  appendedOption,
  defaultValue,
  clearable = false,
  hideSubLabel,
  hideRoleDescription,
  hideSelectedBadges,
  allowedValues,
  needsVerification = false,
  helpText,
  helpTextState = 'visible',
  disabled = false,
  suggestedJobRoles,
  ...rest
}: JobRoleSuggestProps) => {
  const queryClient = useQueryClient();
  const { scenarioId } = useNumberParams();
  const [suggestions, setSuggestions] = useState<Suggestion<number>[]>([]);
  const [queriedJobRoles, setQueriedJobRoles] = useState<SuggestJobRole[]>([]);
  const [jobRoleDescription, setJobRoleDescription] = useState<ReactNode>();
  const [inputAppend, setInputAppend] = useState<ReactNode>();
  const [loading, setLoading] = useState<boolean | null>(null);
  const [open, setOpen] = useState(false);

  const renderDescription = () => {
    if (helpTextState === 'hidden' || !jobRoleDescription) return null;

    const description = <Text variant="text-misc-help">{jobRoleDescription}</Text>;

    if (helpTextState === 'visible') return description;

    return (
      <div className={classNames(styles.description, { [styles.collapsed]: !open })}>
        {open && description}
        <TextButton onClick={() => setOpen((curr) => !curr)}>
          {open ? 'Hide' : 'Show'} description
        </TextButton>
      </div>
    );
  };

  const getJobRoleBadges = (jobRole?: SuggestJobRole | null) =>
    jobRole && (
      <div className={styles.badges}>
        {jobRole.jobArea && <Badge size="medium">{jobRole.jobArea.code}</Badge>}
        {jobRole.jobType && <Badge size="medium">{jobRole.jobType.code}</Badge>}
      </div>
    );

  const setJobRoleInfo = (jobRole?: SuggestJobRole | null) => {
    setJobRoleDescription(jobRole?.description);
    setInputAppend(getJobRoleBadges(jobRole));
  };

  const buildSuggestion = (jobRole: SuggestJobRole) => {
    return {
      value: Number(jobRole.id),
      label: String(jobRole.name),
      displayLabel: (
        <div className={styles.suggestion}>
          <div className={styles.topSection}>
            <Text
              color="platform-gray-900"
              variant={hideRoleDescription ? 'text-misc-input' : 'text-misc-input-bold'}
            >
              {jobRole.name}
            </Text>
            {getJobRoleBadges(jobRole)}
          </div>
          {!hideRoleDescription && jobRole.description && (
            <Text color="platform-gray-900" variant="text-misc-help">
              {jobRole.description}
            </Text>
          )}
        </div>
      ),
    };
  };

  const appendedJobRole = useMemo(
    () =>
      appendedOption && {
        id: appendedOption.value,
        name: appendedOption.label,
      },
    [appendedOption],
  );

  const appendedSuggestion = appendedJobRole && buildSuggestion(appendedJobRole);

  const setSuggestionsFromJobRoles = (jobRoles: JobRoleWithLevels[]) => {
    const options = jobRoles
      .filter((role) => (allowedValues ? allowedValues.includes(role.id) : true))
      .map(buildSuggestion);
    setQueriedJobRoles(appendedJobRole ? [appendedJobRole, ...jobRoles] : jobRoles);
    setSuggestions(appendedSuggestion ? [appendedSuggestion, ...options] : options);
  };

  const handleSelect = (jobRoleId: number | null) => {
    const selectedJobRole = queriedJobRoles.find((jobRole) => jobRole.id === jobRoleId) || null;
    onChange?.(selectedJobRole);
    setJobRoleInfo(selectedJobRole);
  };

  const getSuggestions = async (searchTerm: string) => {
    // when user clears input
    if (clearable && !searchTerm && loading !== null) {
      handleSelect(null);
    }

    setLoading(true);

    const term = searchTerm === appendedOption?.label ? '' : searchTerm;

    let jobRoles;
    if (!searchTerm && suggestedJobRoles) {
      jobRoles = suggestedJobRoles;
    } else {
      const query = jobRoleQueryKeys.list(benchmarkId, scenarioId, {
        jobTitle: term.trim().toLowerCase(),
      });
      jobRoles = await queryClient.fetchQuery(query.queryKey, query.queryFn);
    }

    setSuggestionsFromJobRoles(jobRoles);

    if (term === defaultValue) {
      const selectedJobRole = jobRoles.find((jobRole) => jobRole.name === defaultValue);
      setJobRoleInfo(selectedJobRole);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (suggestedJobRoles) {
      setSuggestionsFromJobRoles(suggestedJobRoles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suggestedJobRoles]);

  return (
    <Suggest
      defaultValue={defaultValue}
      suggestions={suggestions}
      subLabel={!hideSubLabel && <RoleDescriptionsLink text={<span>Role Descriptions</span>} />}
      onType={getSuggestions}
      onChange={handleSelect}
      forceSelection
      helpText={helpText || renderDescription()}
      inputAppend={!hideSelectedBadges ? inputAppend : null}
      inputPrepend={needsVerification ? <span className={styles.verifyCircle} /> : null}
      loading={!!loading}
      labelPosition="left"
      disabled={disabled}
      {...rest}
    />
  );
};

export default JobRoleSuggest;
