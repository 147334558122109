import Button from '../Button/Button';
import { ButtonProps } from '../Button/types';
import { MenuItem } from './Menu';

export const isSectioned = <T extends string | number>(
  items: Array<MenuItem<T> | Array<MenuItem<T>>>,
): items is Array<Array<MenuItem<T>>> => Array.isArray((items as Array<Array<MenuItem<T>>>)[0]);

export const hasNested = <T extends string | number>(
  items: Array<MenuItem<T> | Array<MenuItem<T>>>,
) => items.flat().some((item) => item.nestedItems);

export const deepFilter = <T extends string | number>(
  val: string,
  items: MenuItem<T>[],
): MenuItem<T>[] => {
  return items.reduce((filteredItems: MenuItem<T>[], item: MenuItem<T>) => {
    const isItemMatched = item.label.toLowerCase().includes(val.trim().toLowerCase());

    const matchedNestedItems = item.nestedItems && deepFilter(val, item.nestedItems);
    const isNestedItemsMatched = matchedNestedItems && matchedNestedItems.length > 0;

    if (isItemMatched || isNestedItemsMatched) {
      return [
        ...filteredItems,
        isNestedItemsMatched
          ? {
              ...item,
              nestedItems: matchedNestedItems,
            }
          : item,
      ];
    }

    return filteredItems;
  }, []);
};

export const findDefaultItem = <T extends string | number>(
  items: MenuItem<T>[],
): MenuItem<T> | undefined => {
  let defaultItem: MenuItem<T> | undefined;

  items.forEach((item) => {
    if (item.default === true) {
      defaultItem = item;
    }

    if (item.nestedItems) {
      const nestedDefaultItem = findDefaultItem(item.nestedItems);
      if (nestedDefaultItem) {
        defaultItem = nestedDefaultItem;
      }
    }
  });

  return defaultItem;
};

export const isButtonElement = (
  element: React.ReactNode,
): element is React.ReactElement<ButtonProps> => {
  return (element as React.ReactElement)?.type === Button;
};
