import { UserCircle } from '@phosphor-icons/react';

import Text from 'js/design-system/Text/Text';

import LegendHover from './LegendHover';
import { HeadcountHoverProps } from './types';

const HeadcountHover = ({ count, subtitle, ...props }: HeadcountHoverProps) => {
  const { text: subtitleText, color: subtitleColor } = subtitle || {};

  return (
    <LegendHover
      header={
        <Text variant="text-badge-large" color="platform-gray-500">
          {`${count.toLocaleString()} ${count === 1 ? 'Employee' : 'Employees'}`}
        </Text>
      }
      headerIcon={UserCircle}
      subHeader={
        typeof subtitleText === 'string' ? (
          <Text variant="text-body-main-bold" color={subtitleColor || 'platform-gray-700'}>
            {subtitleText}
          </Text>
        ) : (
          subtitleText
        )
      }
      {...props}
    />
  );
};

export default HeadcountHover;
