import Text from 'js/design-system/Text/Text';
import { getLevelText } from 'js/services/levelService';
import { JobLevel } from 'types';

import styles from './JobLevelOption.module.scss';

interface JobLevelOptionProps {
  jobLevel: JobLevel;
}

const JobLevelOption = ({ jobLevel }: JobLevelOptionProps) => {
  return (
    <div className={styles.levelOption}>
      <Text color="platform-gray-900" variant="text-misc-input-bold">
        {getLevelText(jobLevel.name, { longFormat: true })}
      </Text>
      <Text color="platform-gray-900" variant="text-misc-help">
        {jobLevel.description}
      </Text>
    </div>
  );
};

export default JobLevelOption;
