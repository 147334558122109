import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useQuery } from '@tanstack/react-query';

import { JobRoleWithLevels } from 'types';

import api from './api';

export const fetchJobRole = async (id: number, scenarioId: number) => {
  const { success, data } = await api.get<JobRoleWithLevels>(`/job_roles/${id}`, {
    urlParams: { scenarioId },
  });
  if (success) {
    return data;
  }

  throw new Error('Failed to load job role');
};

export const fetchJobRoles = async (benchmarkId: number, scenarioId: number, filters = {}) => {
  const { success, data } = await api.get<JobRoleWithLevels[]>(`/job_roles`, {
    urlParams: { benchmarkId, scenarioId, filters },
  });

  if (success) {
    return data;
  }

  throw new Error('Failed to load job roles');
};

export const fetchScenarioJobRoles = async (scenarioId: number, filters = {}) => {
  const { success, data } = await api.get<JobRoleWithLevels[]>(
    `/scenarios/${scenarioId}/job_roles`,
    {
      urlParams: { filters },
    },
  );

  if (success) {
    return data;
  }

  throw new Error('Failed to load job roles');
};

export const queryKeys = createQueryKeys('jobRoles', {
  list: (benchmarkId: number, scenarioId: number, filters = {}) => ({
    queryKey: [{ benchmarkId, scenarioId, filters }],
    queryFn: () => fetchJobRoles(benchmarkId, scenarioId, filters),
  }),
  detail: (id: number, scenarioId: number) => ({
    queryKey: [id],
    queryFn: () => fetchJobRole(id, scenarioId),
  }),
});

export const useJobRoles = (
  benchmarkId: number,
  scenarioId: number,
  filters: { payRangeId?: number } = {},
) => {
  const { queryKey, queryFn } = queryKeys.list(benchmarkId, scenarioId, filters);
  return useQuery({ queryKey, queryFn, enabled: Boolean(scenarioId) });
};

export const useJobRolesById = (benchmarkId: number, scenarioId: number, jobTitle?: string) => {
  const { queryKey, queryFn } = queryKeys.list(benchmarkId, scenarioId, jobTitle);
  return useQuery({
    queryKey,
    queryFn,
    select: (jobRoles) =>
      jobRoles.reduce((acc: Record<number, JobRoleWithLevels>, jobRole) => {
        acc[jobRole.id] = jobRole;
        return acc;
      }, {}),
  });
};
