import classnames from 'classnames';

interface ValidationWrapperProps {
  children?: React.ReactNode;
  invalid?: boolean;
  className?: string;
}

const ValidationWrapper = ({ children, invalid = false, className }: ValidationWrapperProps) => (
  <div className={classnames(className, { 'is-invalid': invalid })}>{children}</div>
);

export default ValidationWrapper;
