import { useQuery } from '@tanstack/react-query';

import useNumberParams from 'js/hooks/useNumberParams';
import queries from 'js/queries';
import { PayRangeStatus } from 'types';

const useGetRange = (status: PayRangeStatus = PayRangeStatus.Active) => {
  const { scenarioId } = useNumberParams();

  const getRangeQuery = useQuery(queries.payRanges.oneByScenarioAndStatus(scenarioId, status));

  return {
    ...getRangeQuery,
    hasRange: !!getRangeQuery.data,
  };
};

export default useGetRange;
