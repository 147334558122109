import { FormSpy } from 'react-final-form';

const FormScrollToError = () => {
  return (
    <FormSpy
      subscription={{ submitFailed: true, dirtySinceLastSubmit: true }}
      onChange={(formState) => {
        const { submitFailed, dirtySinceLastSubmit } = formState;

        if (submitFailed && dirtySinceLastSubmit) {
          return;
        }

        setTimeout(() => {
          const el = document.querySelector("[class*='--error']");

          if (el) {
            el.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        }, 0);
      }}
    />
  );
};

export default FormScrollToError;
