import Auth from '@aws-amplify/auth';
import axios from 'axios';
import { snakeCase } from 'change-case';
import QS from 'qs';

import { alertError } from 'js/components-legacy/common/AlertMessage';
import { deepMapKeys } from 'js/utils/deep-map-keys';
import {
  ApiOnFailure,
  ApiOnSuccess,
  ApiRequestOptions,
  ApiRequestProps,
  ApiResponseError,
  ApiResponseProps,
  ErrorData,
  GenericObject,
  PrimitiveGenericObject,
} from 'types';

import URLS from '../config/urls';
import { signOut } from '../services/auth/actions';
import { logError } from '../utils/logger';

const DEFAULT_ERROR = 'API call failed.';

const createQueryParams = (params: PrimitiveGenericObject) =>
  Object.keys(params)
    .map((k) => `${snakeCase(k)}=${encodeURIComponent(params[k])}`)
    .join('&');

const addUrlParams = (url: string, urlParams?: GenericObject) => {
  if (urlParams && Object.keys(urlParams).length > 0) {
    const params = deepMapKeys(urlParams, snakeCase);
    return `${url}?${QS.stringify(params, { arrayFormat: 'brackets' })}`;
  }
  return url;
};

const defaultConfig = {
  baseURL: URLS.API_URL,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
  },
};

const apiInstances = {
  private: axios.create(defaultConfig),
  public: axios.create(defaultConfig),
};

apiInstances.private.interceptors.request.use(
  (config) =>
    Auth.currentSession()
      .then((response) => {
        const requestConfig = { ...config };

        const idToken = response.getIdToken();
        const jwtToken = idToken.getJwtToken();

        if (requestConfig.headers) requestConfig.headers.Authorization = `Bearer ${jwtToken}`;
        if (
          requestConfig.headers &&
          requestConfig.url?.substr(requestConfig.url.length - 4) === '.csv'
        ) {
          requestConfig.headers.Accept = 'text/csv';
        }

        return Promise.resolve(requestConfig);
      })
      .catch((error) => {
        logError(error);
      }),
  (error) => Promise.reject(error),
);

apiInstances.private.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error),
);

const callApi = async <ResponseData, ResponseError = ApiResponseError>({
  url,
  method,
  options,
}: ApiRequestProps): Promise<ApiResponseProps<ResponseData, ResponseError>> => {
  const { params, urlParams, skipAuthorization } = options || {};

  try {
    const urlLink = addUrlParams(url, urlParams);
    const instanceType = skipAuthorization ? 'public' : 'private';
    const { data, status } = await apiInstances[instanceType][method]<ResponseData>(
      urlLink,
      params,
    );

    return { success: true, status, data };
  } catch (error) {
    let errorMsg;
    let status;

    if (axios.isAxiosError(error) && error.response) {
      const { status, data } = error.response;
      if (data && Object.prototype.hasOwnProperty.call(data, 'error')) {
        errorMsg = (data as ErrorData<ResponseError>).error;
      } else if (data && Object.prototype.hasOwnProperty.call(data, 'errors')) {
        errorMsg = (data as ErrorData<ResponseError>).errors;
      }

      if (status === 0) {
        alertError(error.message);
      } else if (status === 401) {
        alertError(errorMsg || 'Invalid access!');
        signOut();
      } else if (status === 429) {
        alertError('Too many requests. Please try again later');
      } else if (process.env.NODE_ENV !== 'test') {
        logError(errorMsg || DEFAULT_ERROR, error);
      }
    }

    return { success: false, status, error: errorMsg || DEFAULT_ERROR };
  }
};

const get = async <ResponseData, ResponseError = ApiResponseError>(
  url: string,
  options?: ApiRequestOptions,
) => callApi<ResponseData, ResponseError>({ url, method: 'get', options });

const put = async <ResponseData, ResponseError = ApiResponseError>(
  url: string,
  options?: ApiRequestOptions,
) => callApi<ResponseData, ResponseError>({ url, method: 'put', options });

const post = async <ResponseData, ResponseError = ApiResponseError>(
  url: string,
  options?: ApiRequestOptions,
) => callApi<ResponseData, ResponseError>({ url, method: 'post', options });

const del = async <ResponseData, ResponseError = ApiResponseError>(
  url: string,
  options?: ApiRequestOptions,
) => callApi<ResponseData, ResponseError>({ url, method: 'delete', options });

const patch = async <ResponseData, ResponseError = ApiResponseError>(
  url: string,
  options?: ApiRequestOptions,
) => callApi<ResponseData, ResponseError>({ url, method: 'patch', options });

const execute = async <ResponseData>({
  call,
  onSuccess,
  onFailure,
  defaultError,
}: {
  call: () => Promise<ApiResponseProps<ResponseData>>;
  onSuccess?: ApiOnSuccess<ResponseData>;
  onFailure?: ApiOnFailure;
  defaultError?: string;
}) => {
  const { success, data, status, error } = await call();

  if (success && onSuccess) {
    onSuccess(data);
  } else if (!success && onFailure) {
    onFailure(error || defaultError || DEFAULT_ERROR);
  }
  return { success, data, status, error };
};

export { createQueryParams, apiInstances };
export default { get, put, post, delete: del, patch, execute };
