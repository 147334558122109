import { nanoid } from 'nanoid';

import { SnackbarOptions, SnackbarProps } from 'js/design-system/Snackbar/types';
import { AppDispatch } from 'types';

import ACTIONS from '../constants/actions';

export const snackbar = (options: SnackbarOptions) => (dispatch: AppDispatch) => {
  const removeSnackbar = (id: string) => {
    dispatch({ type: ACTIONS.REMOVE_SNACKBAR, snackbarId: id });
  };

  const newSnackbar: SnackbarProps = {
    ...options,
    id: nanoid(),
    onClose: removeSnackbar,
  };

  dispatch({ type: ACTIONS.ADD_SNACKBAR, snackbar: newSnackbar });

  return newSnackbar.id;
};
