import classNames from 'classnames';

import Icon from 'js/design-system/Icon';
import Text from 'js/design-system/Text/Text';

import { ColorPillProps } from './types';

import styles from './ColorPill.module.scss';

const ColorPill = ({
  text,
  pillColor = 'green',
  leftElement,
  rightElement,
  'data-testid': dataTestId,
  className,
}: ColorPillProps) => {
  return (
    <div
      className={classNames(
        styles.colorPill,
        styles[pillColor],
        { [styles.withLeftElement]: !!leftElement, [styles.withRightElement]: !!rightElement },
        className,
      )}
      data-testid={dataTestId}
    >
      {!!leftElement && (
        <Icon
          icon={leftElement.value}
          size={16}
          weight={leftElement.weight}
          color={leftElement.color}
        />
      )}
      <Text variant="text-misc-caption" style={{ color: 'currentColor' }}>
        {text}
      </Text>
      {!!rightElement && (
        <Icon
          icon={rightElement.value}
          size={16}
          weight={rightElement.weight}
          color={rightElement.color}
        />
      )}
    </div>
  );
};

export default ColorPill;
