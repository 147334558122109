import { ReactNode } from 'react';

import useAbility from 'js/hooks/useAbility';
import { Ability } from 'types';

interface Props {
  companyId?: number;
  any?: Ability[];
  all?: Ability[];
  children: ReactNode;
}

const RequireAbility = ({ companyId, any, all, children }: Props) => {
  const { hasAccess } = useAbility(companyId, any, all);
  return hasAccess ? <>{children}</> : null;
};

export default RequireAbility;
