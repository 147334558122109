import { createQueryKeys } from '@lukemorales/query-key-factory';

import { fetchScenarioSnapshot } from 'js/api/scenarioSnapshots';

const scenarioSnapshots = createQueryKeys('scenarioSnapshots', {
  detail: (id: number) => ({
    queryKey: [id],
    queryFn: () => fetchScenarioSnapshot(id),
  }),
});

export default scenarioSnapshots;
