import { useParams } from 'react-router-dom';

import GustoOauthCallback from './gustoOauthCallback';
import PulleyOauthCallback from './pulleyOauthCallback';

const oauthHandlers: Record<string, () => void> = {
  gusto: GustoOauthCallback,
  pulley: PulleyOauthCallback,
};

const IntegrationsOauthCallback = () => {
  const { integration } = useParams();
  if (integration && oauthHandlers[integration]) {
    oauthHandlers[integration]();
  } else {
    // render 404?
  }
};

export default IntegrationsOauthCallback;
