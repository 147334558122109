import { useEffect, useState } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

import { updateUser } from 'js/actions/currentUser';
import { fetchActiveRange } from 'js/api/ranges';
import { navigationItems } from 'js/config/globals-ts';
import useAppDispatch from 'js/hooks/useAppDispatch';
import useAppSelector from 'js/hooks/useAppSelector';
import useNumberParams from 'js/hooks/useNumberParams';
import { currentUserSelector } from 'js/selectors/currentUser';
import { PayRangeStatus } from 'types';

export const HomeTabRedirect = () => {
  const { scenarioId } = useNumberParams();
  const dispatch = useAppDispatch();
  const user = useAppSelector(currentUserSelector);
  const hasRangesGoal = user?.goals?.find((goal) => goal.onboardingRoute === 'ranges');

  const [searchParams] = useSearchParams();

  const [hasActiveRange, setHasActiveRange] = useState<boolean>();

  useEffect(() => {
    fetchActiveRange(scenarioId).then((range) =>
      setHasActiveRange(range?.status === PayRangeStatus.Active),
    );
  }, [scenarioId]);

  if (hasActiveRange === undefined || !user) return null;

  const homeTab = navigationItems[hasActiveRange || hasRangesGoal ? 'ranges' : 'market'];

  dispatch(updateUser({ userData: { homeTab } }));

  return <Navigate to={{ pathname: homeTab.url, search: searchParams.toString() }} />;
};
