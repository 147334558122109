import classnames from 'classnames';
import { FormApi, Mutator, SubmissionErrors, ValidationErrors } from 'final-form';
import { SyntheticEvent } from 'react';
import { Form as FinalForm } from 'react-final-form';

import FormAfterSucceededSubmit from './FormAfterSucceededSubmit';
import FormScrollToError from './FormScrollToError';

import styles from './Form.module.scss';

interface FormProps<TFormValues> {
  formRef?: React.MutableRefObject<FormApi<TFormValues> | undefined>;
  children: React.ReactNode;
  mutators?: {
    [key: string]: Mutator<TFormValues, Partial<TFormValues>>;
  };
  onSubmit: (
    formData: TFormValues,
    form: FormApi<TFormValues>,
    callback?: (errors?: SubmissionErrors) => void,
  ) => SubmissionErrors | Promise<SubmissionErrors> | void | Promise<void>;
  onChange?: (event: SyntheticEvent<HTMLFormElement>) => void;
  validate?: (value: Partial<TFormValues>) => ValidationErrors | Promise<ValidationErrors>;
  afterSucceededSubmit?: (form: FormApi<TFormValues>) => void;
  initialValues?: Partial<TFormValues>;
  renderActions?: (disabled: boolean, form: FormApi<TFormValues>) => React.ReactNode;
  enablePristineCheck?: boolean;
  formClassName?: string;
  contentClassName?: string;
  actionsClassName?: string;
  contentStyle?: React.CSSProperties;
  actionsStyle?: React.CSSProperties;
  keepDirtyOnReinitialize?: boolean;
  scrollToError?: boolean;
}

const Form = <TFormValues extends Record<string, unknown>>({
  formRef,
  mutators,
  onSubmit,
  onChange,
  validate,
  afterSucceededSubmit,
  initialValues = {},
  children,
  enablePristineCheck = true,
  renderActions,
  formClassName,
  contentClassName,
  actionsClassName,
  contentStyle,
  actionsStyle,
  keepDirtyOnReinitialize = false,
  scrollToError = false,
}: FormProps<TFormValues>) => {
  return (
    <FinalForm
      mutators={mutators}
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      keepDirtyOnReinitialize={keepDirtyOnReinitialize}
      render={({
        form,
        handleSubmit,
        submitting,
        modifiedSinceLastSubmit,
        pristine,
        submitSucceeded,
      }) => {
        if (formRef) formRef.current = form;

        return (
          <form onSubmit={handleSubmit} className={formClassName} onChange={onChange}>
            <div className={classnames(styles.formContent, contentClassName)} style={contentStyle}>
              {children}
            </div>
            {renderActions && (
              <div className={classnames(styles.actions, actionsClassName)} style={actionsStyle}>
                {renderActions(
                  submitting ||
                    (enablePristineCheck &&
                      (pristine ||
                        (submitSucceeded && keepDirtyOnReinitialize && !modifiedSinceLastSubmit))),
                  form,
                )}
              </div>
            )}
            {scrollToError && <FormScrollToError />}
            {afterSucceededSubmit && (
              <FormAfterSucceededSubmit afterSucceededSubmit={afterSucceededSubmit} />
            )}
          </form>
        );
      }}
    />
  );
};

export default Form;
