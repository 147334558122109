import { mergeQueryKeys } from '@lukemorales/query-key-factory';

import benchmarks from './benchmarks';
import companies from './companies';
import currentUser from './currentUser';
import employees from './employees';
import externalSyncs from './externalSyncs';
import goals from './goals';
import industrySectors from './industrySectors';
import jobRoles from './jobRoles';
import locations from './locations';
import offers from './offers';
import payRangeGroups from './payRangeGroups';
import payRanges from './payRanges';
import products from './products';
import scenarioSnapshots from './scenarioSnapshots';
import scenarios from './scenarios';
import segmentGroups from './segmentGroups';
import strategyTemplates from './strategyTemplates';
import users from './users';

export default mergeQueryKeys(
  benchmarks,
  externalSyncs,
  goals,
  companies,
  currentUser,
  employees,
  scenarios,
  industrySectors,
  jobRoles,
  locations,
  offers,
  strategyTemplates,
  products,
  segmentGroups,
  payRanges,
  payRangeGroups,
  users,
  scenarioSnapshots,
);
