import {
  ArrowsClockwise,
  ChartPie,
  ChartBar,
  ClipboardText,
  Rocket,
  UserCircle,
  Wallet,
} from '@phosphor-icons/react';
import classNames from 'classnames';
import React, { forwardRef } from 'react';

import Badge from 'js/design-system/Badge';
import Icon, { CustomIcon } from 'js/design-system/Icon';
import { Color } from 'types';

import styles from './NavigationTab.module.scss';

const iconSize = 20;

const icons: Record<string, React.FunctionComponent> = {
  launchpad: () => <Icon icon={Rocket} size={iconSize} />,
  market: () => <Icon icon={ChartBar} size={iconSize} />,
  ranges: () => <CustomIcon name="rangesIcon" size={iconSize} />,
  hiring: () => <Icon icon={UserCircle} size={iconSize} />,
  diversity: () => <Icon icon={ChartPie} size={iconSize} />,
  offers: () => <Icon icon={ClipboardText} size={iconSize} />,
  compCycles: () => <Icon icon={ArrowsClockwise} size={iconSize} />,
  totalRewards: () => <Icon icon={Wallet} size={iconSize} />,
};

interface Props {
  name: string;
  label: string;
  selected?: boolean;
  isNew?: boolean;
  beta?: boolean;
  upgrade?: boolean;
  hasBanner?: boolean;
  featured?: boolean;
}

const NavigationTab = forwardRef<HTMLSpanElement, Props>(
  ({ selected, name, label, isNew, beta, upgrade, hasBanner, featured }, ref) => {
    const NavigationTabIcon = icons[name];
    return (
      <span
        ref={ref}
        className={classNames(styles.tab, styles[name], {
          [styles.selected]: selected,
          [styles.new]: isNew,
          [styles.beta]: beta,
          [styles.upgrade]: upgrade,
          [styles.withBanner]: hasBanner,
          [styles.featured]: featured,
        })}
      >
        <NavigationTabIcon />
        <span className={styles.label}>{label}</span>
        {isNew && !beta ? (
          <Badge
            size="small"
            className={styles.badge}
            backgroundColor={'unset' as Color}
            color={'unset' as Color}
          >
            NEW!
          </Badge>
        ) : null}
        {beta ? (
          <Badge
            size="small"
            className={styles.badge}
            backgroundColor={'unset' as Color}
            color={'unset' as Color}
          >
            BETA
          </Badge>
        ) : null}
      </span>
    );
  },
);

NavigationTab.displayName = 'NavigationTab';
export default NavigationTab;
