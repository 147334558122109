import { ReactNode } from 'react';
import { Outlet } from 'react-router-dom';

import { FeatureFlags } from 'js/config/feature-flags';
import useAppSelector from 'js/hooks/useAppSelector';
import useFeatureFlag from 'js/hooks/useFeatureFlag';
import useNumberParams from 'js/hooks/useNumberParams';
import { getById as scenarioSelector } from 'js/selectors/scenarios';
import { FetchStatus } from 'types';

interface ProtectedRouteProps {
  featureFlag: FeatureFlags;
  noAccessComponent?: ReactNode;
}

const FeatureFlagProtectedRoute = ({ featureFlag, noAccessComponent }: ProtectedRouteProps) => {
  const { scenarioId, companyId } = useNumberParams();
  const scenario = useAppSelector((state) => scenarioSelector(state, scenarioId));

  const { enabled: flagEnabled, status: featureFlagStatus } = useFeatureFlag(
    featureFlag,
    companyId || scenario?.companyId,
  );

  if (featureFlagStatus === FetchStatus.Loading) {
    return null;
  }

  return flagEnabled ? <Outlet /> : <>{noAccessComponent}</>;
};

export default FeatureFlagProtectedRoute;
