import classNames from 'classnames';
import { ElementType } from 'react';

import Icon from 'js/design-system/Icon';
import Text from 'js/design-system/Text/Text';
import buttonize from 'js/utils/buttonize';

import styles from './Tab.module.scss';

interface TabProps {
  active: boolean;
  text: string;
  icon?: ElementType;
  onClick: () => void;
}

export const Tab = ({ active, text, icon, onClick }: TabProps) => (
  <div
    {...buttonize(onClick)}
    className={classNames(styles.tab, {
      [styles.active]: active,
    })}
  >
    {icon ? <Icon icon={icon} size={16} color="currentColor" /> : null}
    <Text variant="text-misc-eyebrow" color="currentColor">
      {text}
    </Text>
  </div>
);
