import { AnyAction } from 'redux';

import ACTIONS from '../constants/actions';

type SpinnerState = {
  shown: boolean;
};

const initialState: SpinnerState = {
  shown: false,
};

const startCalls = [ACTIONS.SLOW_REQUEST_STARTED, ACTIONS.SVB_SUBMIT];
const endCalls = [
  ACTIONS.USER_SIGNIN_SET_ERROR,
  ACTIONS.USER_SIGNUP_SET_ERROR,
  ACTIONS.CLEAR_SESSION_FORMS,
  ACTIONS.ERROR,
  ACTIONS.REQUEST_COMPLETED,
  ACTIONS.REQUEST_FAILED,
];

export default (
  state: SpinnerState = initialState,
  action: AnyAction = { type: null },
): SpinnerState => {
  if (startCalls.includes(action.type)) {
    return state.shown ? state : { shown: true };
  }

  if (endCalls.includes(action.type)) {
    return state.shown ? { shown: false } : state;
  }

  return state;
};
