import { Company } from 'types';

import GLOBALS from '../config/globals';

export const companyUrl = (company?: Company, option = { upgradePageAccessible: true }) => {
  if (company?.id) {
    if (company.landingScenarioId && company.onboardStatus === GLOBALS.onboardStatus.complete) {
      return `/scenarios/${company.landingScenarioId}`;
    }
    if (company.onboardStatus === GLOBALS.onboardStatus.complete) {
      if (!option.upgradePageAccessible) {
        return '/';
      }
      return `/companies/${company.id}/scenarios/new`;
    }
    if (company.onboardStatus === GLOBALS.onboardStatus.started) {
      return `/onboarding/${company.id}/employees`;
    }
    if (company.onboardStatus === GLOBALS.onboardStatus.employeesAdded) {
      return `/onboarding/scenarios/${company.baseScenarioId}/benchmarking-workshop`;
    }
  }
  return '/';
};
