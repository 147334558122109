import { ReactNode } from 'react';

import useAppSelector from 'js/hooks/useAppSelector';
import useNumberParams from 'js/hooks/useNumberParams';
import { currentUserRoleSelector } from 'js/selectors/currentUser';
import { getById as scenarioSelector } from 'js/selectors/scenarios';
import { UserRole } from 'types';

interface HideForRoleProps {
  roles: UserRole[];
  children: ReactNode;
}

const HideForRole = ({ roles, children }: HideForRoleProps) => {
  const { scenarioId } = useNumberParams();
  const scenario = useAppSelector((state) => scenarioSelector(state, scenarioId));
  const userRole = useAppSelector((state) => currentUserRoleSelector(state, scenario?.companyId));

  if (!userRole || roles.includes(userRole)) {
    return null;
  }
  return <>{children}</>;
};

export default HideForRole;
