/* eslint-disable no-nested-ternary */

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import classNames from 'classnames';
import { useMemo, useState } from 'react';

import { Table } from 'js/design-system/Table';
import { Column } from 'js/design-system/Table/types';
import useShowEquity from 'js/hooks/useShowEquity';
import { useExchangeRate } from 'js/queries/benchmarks';
import { formatCash, formatEquity } from 'js/services/compensations/formatter';
import { getLevelRangeText, getLevelText } from 'js/services/levelService';
import { CurrencyCode } from 'js/utils/formatters/currency';
import { EquityDisplayType, Scenario } from 'types';

import { NoDataAlert } from '../common/NoDataAlert';
import { ResultsHeader } from '../common/ResultsHeader';
import { CompType, RangeBand, RangeLookupData } from '../types';

import styles from './RangesLookupResults.module.scss';

export const RangesLookupResults = ({
  data,
  scenario,
}: {
  data: RangeLookupData;
  scenario: Scenario;
}) => {
  const [currencyCode, setCurrencyCode] = useState<CurrencyCode>('USD');
  const [selectedEquity, setSelectedEquity] = useState<CompType>(
    scenario.equityDisplayType === EquityDisplayType.NumOfShares ? 'shares' : 'percent',
  );

  const equityDisplayType =
    selectedEquity === 'shares'
      ? EquityDisplayType.NumOfShares
      : selectedEquity === 'percent'
      ? EquityDisplayType.PercentOwnership
      : EquityDisplayType.None;

  const showEquity = useShowEquity();
  const exchangeRate = useExchangeRate(scenario.benchmarkId, currencyCode) ?? 1;

  const jobLevels = data.tiers[0].cash
    .sort((a, b) => a.jobLevel.order - b.jobLevel.order)
    .map((band: RangeBand) => band.jobLevel);

  const columns: Column[] = [
    { id: 'level', display: 'Job Level' },
    { id: 'min', display: `Min (${data.commissionBased ? 'OTE' : 'Base Salary'})` },
    { id: 'mid', display: `Mid (${data.commissionBased ? 'OTE' : 'Base Salary'})` },
    { id: 'max', display: `Max (${data.commissionBased ? 'OTE' : 'Base Salary'})` },
  ];

  const rows = useMemo(
    () =>
      jobLevels.map((jobLevel) => {
        const {
          min: cashMin,
          mid: cashMid,
          max: cashMax,
        } = data.tiers[0].cash.find((b) => b.jobLevel.id === jobLevel.id)!;
        const {
          min: equityMin,
          mid: equityMid,
          max: equityMax,
        } = data.tiers[0].equity.find((b) => b.jobLevel.id === jobLevel.id)!;

        return {
          jobLevel,
          cashMin: formatCash(cashMin * exchangeRate, {
            currency: currencyCode,
          }),
          cashMid: formatCash(cashMid * exchangeRate, {
            currency: currencyCode,
          }),
          cashMax: formatCash(cashMax * exchangeRate, {
            currency: currencyCode,
          }),
          equityMin: formatEquity(equityMin, equityDisplayType, scenario.outstandingShares, true),
          equityMid: formatEquity(equityMid, equityDisplayType, scenario.outstandingShares, true),
          equityMax: formatEquity(equityMax, equityDisplayType, scenario.outstandingShares, true),
        };
      }),
    [
      currencyCode,
      data.tiers,
      equityDisplayType,
      exchangeRate,
      jobLevels,
      scenario.outstandingShares,
    ],
  );

  const tableData = rows.map((row) => ({
    id: `${row.jobLevel.id}`,
    level: getLevelText(row.jobLevel.name),
    min: (
      <div className={styles.cell}>
        <span>{row.cashMin}</span>
        {showEquity && (
          <span className={styles.equity}>
            {row.equityMin} {selectedEquity === 'shares' && selectedEquity}
          </span>
        )}
      </div>
    ),
    mid: (
      <div className={styles.cell}>
        <span>{row.cashMid}</span>
        {showEquity && (
          <span className={styles.equity}>
            {row.equityMid} {selectedEquity === 'shares' && selectedEquity}
          </span>
        )}
      </div>
    ),
    max: (
      <div className={styles.cell}>
        <span>{row.cashMax}</span>
        {showEquity && (
          <span className={styles.equity}>
            {row.equityMax} {selectedEquity === 'shares' && selectedEquity}
          </span>
        )}
      </div>
    ),
  }));

  if (rows.length === 0) return <NoDataAlert />;

  return (
    <>
      <ResultsHeader
        tierText={data.tiers[0].tierName}
        levelText={getLevelRangeText(jobLevels)}
        selectedCurrency={currencyCode}
        onCurrencyChange={setCurrencyCode}
        selectedEquity={selectedEquity}
        onEquityChange={setSelectedEquity}
      />
      <Table
        className={styles.table}
        headerRowClassName={styles.headerRow}
        rowClassName={classNames(styles.row, { [styles.noEquity]: !showEquity })}
        columns={columns}
        data={tableData}
      />
    </>
  );
};
