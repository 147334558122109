import {
  FloatingArrow,
  FloatingPortal,
  arrow,
  autoPlacement,
  autoUpdate,
  flip,
  offset,
  shift,
  useDismiss,
  useFloating,
  useHover,
  useInteractions,
  useRole,
  useTransitionStyles,
} from '@floating-ui/react';
import colors from 'css/_colors.scss';
import { cloneElement, useRef, useState } from 'react';

import styles from './Tooltip.module.scss';

export const DELAY = 500;

export enum TooltipPlacement {
  Left = 'left',
  LeftEnd = 'left-end',
  LeftStart = 'left-start',
  Right = 'right',
  RightEnd = 'right-end',
  RightStart = 'right-start',
  Top = 'top',
  TopEnd = 'top-end',
  TopStart = 'top-start',
  Bottom = 'bottom',
  BottomEnd = 'bottom-end',
  BottomStart = 'bottom-start',
}

export interface TooltipProps {
  children: React.ReactElement;
  content?: React.ReactNode;
  delay?: boolean;
  /** ***Warning - this should only be used in special circumstances.***
   *
   * Overrides autoplacement behavior by restricting to one or more specific placements.
   */
  allowedPlacements?: `${TooltipPlacement}`[];
}

const Tooltip = ({ children, content, delay, allowedPlacements }: TooltipProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const arrowRef = useRef(null);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      offset(8),
      shift(),
      allowedPlacements ? autoPlacement({ allowedPlacements }) : flip(),
      arrow({
        element: arrowRef,
      }),
    ],
    whileElementsMounted: autoUpdate,
  });

  const { styles: transitionStyles } = useTransitionStyles(context);

  const hover = useHover(context, {
    delay: {
      open: delay ? DELAY : 0,
      close: 0,
    },
    move: false,
  });

  const dismiss = useDismiss(context, {
    referencePress: true,
  });

  const role = useRole(context, {
    role: 'tooltip',
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([hover, dismiss, role]);

  if (!content) return children;

  const anchor = cloneElement(children, {
    ref: refs.setReference,
    ...getReferenceProps(),
  });

  return (
    <>
      {anchor}
      {isOpen && (
        <FloatingPortal>
          <div
            ref={refs.setFloating}
            className={styles.tooltip}
            style={{ ...floatingStyles, ...transitionStyles }}
            {...getFloatingProps()}
          >
            <FloatingArrow
              ref={arrowRef}
              context={context}
              height={4}
              width={8}
              strokeWidth={1}
              fill={colors['neutral-full-black']}
              stroke={colors['platform-gray-600']}
            />
            <div>{content}</div>
          </div>
        </FloatingPortal>
      )}
    </>
  );
};

export default Tooltip;
