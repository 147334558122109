export default {
  view: true,
  cancel: true,
  delete: true,
  copy: true,
  create: true,
  update: true,
  toggle: {
    market: true,
  },
  import: 'Employees Imported',
  upload: 'Employees Uploaded',
  benchmark: {
    role: 'Employee Benchmark Role',
    level: 'Employee Benchmark Level',
    location: 'Employee Benchmark Location',
  },
};
