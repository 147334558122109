import classNames from 'classnames';
import colorsTheme from 'css/_colors.scss';
import siteVariables from 'css/_site-variables.scss';
import { Color } from 'types/designSystem';

import { ReactComponent as BusinessPlan } from 'js/design-system/_custom-icons/business-plan.svg';
import { ReactComponent as Charity } from 'js/design-system/_custom-icons/charity.svg';
import { ReactComponent as EnterprisePlan } from 'js/design-system/_custom-icons/enterprise-plan.svg';
import { ReactComponent as GoogleLogo } from 'js/design-system/_custom-icons/google-logo.svg';
import { ReactComponent as PaidTeaser } from 'js/design-system/_custom-icons/paid-teaser.svg';
import { ReactComponent as Plus } from 'js/design-system/_custom-icons/plus.svg';
import { ReactComponent as ProfessionalPlan } from 'js/design-system/_custom-icons/professional-plan.svg';
import { ReactComponent as RangesIcon } from 'js/design-system/_custom-icons/ranges-icon.svg';
import { ReactComponent as TeamPlan } from 'js/design-system/_custom-icons/team-plan.svg';
import { ReactComponent as Tooth } from 'js/design-system/_custom-icons/tooth.svg';
import { ReactComponent as UserCircleCheck } from 'js/design-system/_custom-icons/user-circle-check.svg';
import { ReactComponent as UserCircle } from 'js/design-system/_custom-icons/user-circle.svg';

import styles from './CustomIcon.module.scss';

const colors = { ...siteVariables, ...colorsTheme };

const icons = {
  paidTeaser: PaidTeaser,
  plus: Plus,
  userCircle: UserCircle,
  tooth: Tooth,
  charity: Charity,
  rangesIcon: RangesIcon,
  userCircleCheck: UserCircleCheck,
  teamPlan: TeamPlan,
  businessPlan: BusinessPlan,
  professionalPlan: ProfessionalPlan,
  enterprisePlan: EnterprisePlan,
  googleLogo: GoogleLogo,
};

export type Name = keyof typeof icons;

interface Props {
  className?: string;
  name: Name;
  size?: number;
  color?: Color | 'currentColor' | 'transparent';
  colorApplication?: 'fill' | 'stroke';
}

export const names = Object.keys(icons) as Name[];
export const defaultSize = 40;

const CustomIcon = ({
  className,
  name,
  size = defaultSize,
  color = 'currentColor',
  colorApplication = 'fill',
}: Props) => {
  const Icon = icons[name];

  const colorVar = color && (colors[color] || color);

  return (
    <div className={classNames(styles.wrapper, className)} style={{ color: colorVar }}>
      <Icon
        height={size}
        width={size}
        fill={colorApplication === 'fill' ? colorVar : 'none'}
        stroke={colorApplication === 'stroke' ? colorVar : 'none'}
      />
    </div>
  );
};

export default CustomIcon;
