import { ApiOnSuccess, ApiOnFailure } from 'types';

export enum ConnectionType {
  None = 'none',
  Cash = 'cash',
  Equity = 'equity',
  Both = 'both',
}

export enum CashConnectionKind {
  Salary = 'salary',
  Equity = 'equity',
}

export enum ConnectionProvider {
  Finch = 'finch',
  Pulley = 'pulley',
  Carta = 'carta',
  Gusto = 'gusto',
  Merge = 'merge',
  Rippling = 'rippling',
}

export enum ConnectionStatus {
  Active = 'active',
  Inactive = 'inactive',
  Disconnected = 'disconnected',
}

export enum SyncStatus {
  Idle = 'idle',
  Success = 'success',
  InProgress = 'in_progress',
  Loading = 'loading',
  Failed = 'failed',
}

export enum PayrollProviderKey {
  Bob = 'bob',
  Insperity = 'insperity',
  Rippling = 'rippling',
  Paycor = 'paycor',
  PaychexFlex = 'paychex_flex',
  Gusto = 'gusto',
  SequoiaOne = 'sequoia_one',
  AdpRun = 'adp_run',
  Paylocity = 'paylocity',
  AdpWorkforceNow = 'adp_workforce_now',
  BambooHr = 'bamboo_hr',
  Namely = 'namely',
  Justworks = 'justworks',
  Paycom = 'paycom',
  Zenefits = 'zenefits',
  Trinet = 'trinet',
  Finch = 'finch',
}

export type CompProviderCred = {
  id: number;
  kind: CashConnectionKind;
  providerKey: PayrollProviderKey;
  provider: ConnectionProvider;
  status: ConnectionStatus;
  companyExternalSyncId: string;
  employeesExternalSyncId: string;
  lastRunAt: string;
  updatedAt: string;
};

export type CashConnection = CompProviderCred & {
  integrationProxyName: string;
};
export type EquityConnection = {
  id: number;
  provider: ConnectionProvider;
  status: ConnectionStatus;
  lastRunAt: string;
  updatedAt: string;
};

export interface ConnectApiProps {
  code: string;
  companyId: number;
  provider?: ConnectionProvider;
  useBasicAuthHeader?: boolean;
  onSuccess?: ApiOnSuccess<CompProviderCred>;
  onFailure?: ApiOnFailure;
  integrationProxyName: string;
}

export interface DisconnectApiProps {
  companyId: number;
  onSuccess?: ApiOnSuccess<CompProviderCred | null>;
  onFailure?: ApiOnFailure;
}

export interface ResyncApiProps {
  companyId: number;
  integrationProxyName: string;
  onSuccess?: ApiOnSuccess<CompProviderCred>;
  onFailure?: ApiOnFailure;
}
