export type JobType = {
  id: number;
  name: `${JobTypeName}`;
  code: `${JobTypeCode}`;
};

// TODO: Update places where job type code is used
export enum JobTypeCode {
  Executive = 'EXEC',
  Management = 'MGMT',
  IndividualContributor = 'IC',
  Support = 'SPT',
}

export enum JobTypeName {
  Executive = 'Executive',
  Management = 'Management',
  IndividualContributor = 'Individual Contributor',
  Support = 'Support',
}
