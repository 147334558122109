const toSelectOptions = (items, getLabel, labelAll = null) => {
  if (!items) return [];

  const packedItems = labelAll ? [{ value: -1, label: labelAll }] : [];

  // check if items is an object or an array
  const arr = typeof items === 'object' && !Array.isArray(items) ? Object.keys(items) : items;

  const selectOptions = arr.map((id) => ({
    value: id,
    label: getLabel(id, items),
  }));

  return [...packedItems, ...selectOptions];
};

export { toSelectOptions };
