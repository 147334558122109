import { navigationItems } from 'js/config/globals-ts';

import { Goal } from './goal';

export enum UserType {
  Basic = 'basic',
  Analyst = 'analyst',
}

type UserLastViewed = {
  companyId: number;
};

export type User = {
  id: number;
  email: string;
  name?: string;
  firstName: string;
  lastName: string;
  jobTitle: string | null;
  landingCompanyId: number;
  landingScenarioId: number;
  isPending: boolean;
  segmentGroup: number;
  ssoId: string;
  userSegment: string;
  userType: UserType;
  avatar: string | null;
  createdAt: string;
  updatedAt: string;
  // Extended props
  indexStatus?: string;
  invitation?: { inviterName: string; purpose?: string };
  hrisProvider?: string | null;
  lastViewed?: UserLastViewed;
  goals?: Goal[];
  homeTab?: (typeof navigationItems)[keyof typeof navigationItems];
};

export type CreateUserResponse = User;
