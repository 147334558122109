import { AnyAction } from 'redux';

import { Scenario, FetchStatus } from 'types';

import ACTIONS from '../constants/actions';

enum ReadStatus {
  Loading = 'loading',
  Updating = 'updating',
  Succeeded = 'succeeded',
}

interface ScenarioState {
  allIds: number[];
  byId: Record<number, Scenario>;
  indexStatus: FetchStatus;
  readStatus: Record<number, ReadStatus>;
  companyId?: number;
}

const initialState: ScenarioState = {
  allIds: [],
  byId: {},
  indexStatus: FetchStatus.Idle,
  readStatus: {},
};

export default (state = initialState, action: AnyAction = { type: null }): ScenarioState => {
  switch (action.type) {
    case ACTIONS.CREATE_COMPANY:
    case ACTIONS.DELETE_COMPANY:
      return initialState;
    case ACTIONS.FETCHING_SCENARIO:
      return {
        ...state,
        readStatus: { ...state.readStatus, [action.id]: ReadStatus.Loading },
      };
    case ACTIONS.CREATE_SCENARIO:
      return {
        ...state,
        allIds: [...state.allIds, action.data.id],
        byId: { ...state.byId, [action.data.id]: action.data },
      };
    case ACTIONS.DELETE_SCENARIO:
      return { ...state, allIds: state.allIds.filter((id) => id !== Number(action.id)) };
    case ACTIONS.FETCHING_SCENARIOS:
      return { ...state, indexStatus: FetchStatus.Loading };
    case ACTIONS.UPDATING_SCENARIO:
      return {
        ...state,
        readStatus: { ...state.readStatus, [action.id]: ReadStatus.Updating },
      };
    case ACTIONS.RECEIVE_SCENARIOS: {
      const { scenarios } = action.data;
      const sortedScenarios = scenarios.sort((a: Scenario, b: Scenario) => {
        if (a.scenarioType === 'base') return -1;
        if (b.scenarioType === 'base') return 1;

        return a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1;
      });

      return {
        ...state,
        allIds: sortedScenarios.map((datum: Scenario) => datum.id),
        byId: sortedScenarios.reduce(
          (reducedScenarios: Record<number, Scenario>, scenario: Scenario) => {
            reducedScenarios[scenario.id] = { ...state.byId[scenario.id], ...scenario };
            return reducedScenarios;
          },
          {},
        ),
        indexStatus: FetchStatus.Succeeded,
        companyId: action.companyId,
      };
    }
    case ACTIONS.RECEIVE_SCENARIO: {
      const scenario = action.data;
      const newAllIds = [...state.allIds];
      if (!newAllIds.includes(scenario.id)) newAllIds.push(scenario.id);

      return {
        ...state,
        allIds: newAllIds,
        byId: { ...state.byId, [scenario.id]: { ...state.byId[scenario.id], ...scenario } },
        readStatus: { ...state.readStatus, [scenario.id]: ReadStatus.Succeeded },
      };
    }
    default:
      return state;
  }
};
