import { Popover } from 'js/design-system/Popover/Popover';
import Text from 'js/design-system/Text/Text';
import TextButton from 'js/design-system/TextButton/TextButton';

import styles from './WhatCanIDo.module.scss';

export const WhatCanIDo = ({ text }: { text: string }) => (
  <Popover
    triggerOnClick
    closeButtonText="Got it"
    content={
      <Text variant="text-misc-input" className={styles.popoverContent}>
        {text}
      </Text>
    }
  >
    <span>
      <TextButton>What can I do?</TextButton>
    </span>
  </Popover>
);
