import classNames from 'classnames';

import Text from 'js/design-system/Text/Text';
import Tooltip from 'js/design-system/Tooltip/Tooltip';

import { ExtendedEmployee } from './BenchmarkingTable';

import styles from './BenchmarkingTable.module.scss';

const TRUNCATE_LENGTH = 35;

export const EmployeeCell = ({ employee }: { employee: ExtendedEmployee }) => {
  const { jobTitle, name, confirmed } = employee;
  const nameTooltip = name.length > TRUNCATE_LENGTH ? name : undefined;
  const jobTitleTooltip = jobTitle && jobTitle.length > TRUNCATE_LENGTH ? jobTitle : undefined;
  const tooltipContent =
    nameTooltip || jobTitleTooltip ? (
      <>
        {nameTooltip && <div>{nameTooltip}</div>}
        {jobTitleTooltip && <div>{jobTitleTooltip}</div>}
      </>
    ) : undefined;

  return (
    <div className={styles.employeeCellWrapper}>
      <div className={classNames(styles.verifyCircle, { [styles.hidden]: confirmed })} />
      <Tooltip content={tooltipContent} allowedPlacements={['right']}>
        <div className={styles.employeeCell}>
          <Text variant="text-body-main-bold" className={styles.employeeCellText}>
            {name}
          </Text>
          <Text
            variant="text-misc-help"
            color="platform-gray-600"
            className={styles.employeeCellText}
          >
            {jobTitle}
          </Text>
        </div>
      </Tooltip>
    </div>
  );
};
