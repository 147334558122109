import { useQueryClient, useMutation } from '@tanstack/react-query';

import queries from 'js/queries';

import api from './api';

export enum NotificationName {
  NewUnassignedGeoTierLocations = 'new_unassigned_geo_tier_locations',
  AddEmployees = 'add_employees',
}

export type ScenarioNotification = {
  id: number;
  active: true;
  name: NotificationName;
  createdAt: Date;
  updatedAt: Date;
};

export const fetchScenarioNotifications = async (scenarioId: number) => {
  const { success, data } = await api.get<ScenarioNotification[]>(
    `/scenarios/${scenarioId}/notifications`,
  );
  if (success) {
    return data;
  }

  throw new Error('Failed to load scenario notifications');
};

const dismiss = async (scenarioId: number, notificationId: number) => {
  const { success } = await api.put<undefined>(
    `/scenarios/${scenarioId}/notifications/${notificationId}`,
    {
      params: { userNotification: { dismissedAt: new Date() } },
    },
  );

  if (success) {
    return;
  }

  throw new Error('Failed to load scenario notifications');
};

export const useDismissScenarioNotification = (scenarioId: number) => {
  const queryClient = useQueryClient();
  const query = queries.scenarios.detail(scenarioId)._ctx.notifications;
  return useMutation((notificationId: number) => dismiss(scenarioId, notificationId), {
    onMutate: (notificationId: number) => {
      const previous = queryClient.getQueryData(query.queryKey);
      queryClient.setQueryData(query.queryKey, (old: ScenarioNotification[] = []) =>
        old.filter((notification) => notification.id !== notificationId),
      );
      return previous;
    },
    onError: (_err, _notificationId, previous) => {
      queryClient.setQueryData(query.queryKey, previous);
    },
    onSettled: () => {
      queryClient.invalidateQueries(query);
    },
  });
};
