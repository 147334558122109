import { AnyAction } from 'redux';

import { Employee, FetchStatus, keys } from 'types';

import ACTIONS from '../constants/actions';

export type EmployeeState = {
  allIds: number[];
  byId: Record<number, Employee>;
  error?: string;
  status: FetchStatus;
};

const initialState: EmployeeState = {
  allIds: [],
  byId: {},
  status: FetchStatus.Idle,
};

export default (
  state: EmployeeState = initialState,
  action: AnyAction = { type: null },
): EmployeeState => {
  switch (action.type) {
    case ACTIONS.CREATE_COMPANY:
    case ACTIONS.DELETE_COMPANY:
      return initialState;
    case ACTIONS.CREATE_EMPLOYEE: {
      if (state.allIds.includes(action.data.id)) {
        return state;
      }
      return {
        ...state,
        allIds: [...state.allIds, action.data.id],
        byId: { ...state.byId, [action.data.id]: action.data },
      };
    }
    case ACTIONS.DELETE_EMPLOYEE: {
      const { [action.id]: deletedEmployee, ...otherEmployees } = state.byId;
      return {
        ...state,
        allIds: state.allIds.filter((id) => id !== Number(action.id)),
        byId: otherEmployees,
      };
    }
    case ACTIONS.DELETE_EMPLOYEES: {
      return {
        ...state,
        allIds: state.allIds.filter((id) => !action.ids.includes(id)),
        byId: keys(state.byId).reduce((newById: Record<number, Employee>, id: number) => {
          if (!action.ids.includes(+id)) {
            newById[id] = state.byId[id];
          }
          return newById;
        }, {}),
      };
    }
    default:
      return state;
  }
};
