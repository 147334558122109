import { ReactNode } from 'react';

import Text from 'js/design-system/Text/Text';

import styles from './Layout.module.scss';

interface LayoutProps {
  showOverlay?: boolean;
  header: ReactNode;
  subHeader: ReactNode;
  leftContent: ReactNode;
  rightContent: ReactNode;
  secondaryContent?: ReactNode;
}

export const Layout = ({
  showOverlay,
  header,
  subHeader,
  leftContent,
  rightContent,
  secondaryContent,
}: LayoutProps) => {
  return (
    <>
      <div className={styles.left}>
        {showOverlay && <div className={styles.overlay} />}

        <div className={styles.header}>
          {typeof header === 'string' ? (
            <Text variant="text-heading-h5" color="platform-gray-900">
              {header}
            </Text>
          ) : (
            header
          )}
          {typeof subHeader === 'string' ? (
            <Text color="platform-gray-700">{subHeader}</Text>
          ) : (
            subHeader
          )}
        </div>

        <div className={styles.leftContentContainer}>{leftContent}</div>

        {secondaryContent}
      </div>

      <div className={styles.divider} />

      <div className={styles.right}>{rightContent}</div>
    </>
  );
};
