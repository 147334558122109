import { useState } from 'react';

import Button from 'js/design-system/Button/Button';
import { ButtonSize, ButtonVariant } from 'js/design-system/Button/types';
import Modal from 'js/design-system/Modal';
import Text from 'js/design-system/Text/Text';

import { TextField, TextFieldValue } from '../Form/TextField';
import { ConfirmationModalProps } from './types';

import styles from './ConfirmationModal.module.scss';

const ConfirmationModal = ({
  title,
  children,
  show = false,
  confirmationText,
  confirmLabel = 'Confirm',
  cancelLabel = 'Cancel',
  onConfirm,
  onCancel,
  onHide,
  confirmBtnProps = {},
}: ConfirmationModalProps) => {
  const [confirmDisabled, setConfirmDisabled] = useState<boolean>(!!confirmationText);

  const handleInputConfirmationChange = (value: TextFieldValue) =>
    setConfirmDisabled(value?.toString()?.toLowerCase() !== confirmationText?.toLowerCase());

  const header = (
    <Text variant="text-subheading-medium" color="platform-gray-900">
      {title}
    </Text>
  );

  const footer = (
    <>
      <Button variant={ButtonVariant.Outlined} size={ButtonSize.Medium} onClick={onCancel}>
        {cancelLabel}
      </Button>
      <Button
        variant={ButtonVariant.Filled}
        size={ButtonSize.Medium}
        onClick={onConfirm}
        disabled={confirmDisabled}
        {...confirmBtnProps}
      >
        {confirmLabel}
      </Button>
    </>
  );

  return (
    <Modal
      header={header}
      footer={footer}
      show={show}
      onHide={onHide}
      className={styles.modal}
      dialogClassName={styles.wrapper}
      headerClassName={styles.header}
      footerClassName={styles.footer}
      contentClassName={styles.content}
      backdropClassName={styles.backdrop}
    >
      {children}
      {!!confirmationText && (
        <TextField
          name="confirmation"
          label={`Type "${confirmationText}" to confirm`}
          labelPosition="top"
          onChange={handleInputConfirmationChange}
          wrapperClassName={styles.confirmationInputWrapper}
        />
      )}
    </Modal>
  );
};

export default ConfirmationModal;
