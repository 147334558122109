import { createQueryKeys } from '@lukemorales/query-key-factory';
import {
  useQuery,
  useQueryClient,
  UseQueryOptions,
  QueryFunction,
  QueryKey,
} from '@tanstack/react-query';

import { formatError } from 'js/components-legacy/common/AlertMessage';
import { UserPermission } from 'types';

import api from './api';

export const fetchScenarioUsers = async (scenarioId: number | string) => {
  const { success, data, error } = await api.get<UserPermission[]>(
    `/scenarios/${scenarioId}/users`,
  );
  if (success) {
    return data;
  }

  throw new Error(formatError(error) || "Failed to fetch scenario's users.");
};

export const queryKeys = createQueryKeys('scenario_users', {
  list: (scenarioId: number) => ({
    queryKey: [scenarioId],
    queryFn: () => fetchScenarioUsers(scenarioId),
  }),
});

export const useScenarioUsers = (
  scenarioId: number,
  options?: UseQueryOptions<UserPermission[]>,
) => {
  return useQuery<UserPermission[]>(
    queryKeys.list(scenarioId).queryKey,
    queryKeys.list(scenarioId).queryFn as QueryFunction<UserPermission[], QueryKey>,
    options,
  );
};

export const useUpdateScenarioUsersQueries = (scenarioId: number) => {
  const queryClient = useQueryClient();

  return () => {
    queryClient.invalidateQueries(queryKeys.list(scenarioId).queryKey);
  };
};
