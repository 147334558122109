import { useQuery } from '@tanstack/react-query';
import { camelCase, capitalCase } from 'change-case';
import classNames from 'classnames';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { GeneralClickEventDetails } from 'js/analytics/types/general';
import useTrackEvent from 'js/analytics/useTrackEvent';
import HideForRole from 'js/components/user-permissions/HideForRole';
import Avatar, { AvatarProps } from 'js/design-system/Avatar';
import Badge from 'js/design-system/Badge';
import Text from 'js/design-system/Text/Text';
import useAccess from 'js/hooks/useAccess';
import useAmplifyStorage from 'js/hooks/useAmplifyStorage';
import useAppSelector from 'js/hooks/useAppSelector';
import useChat from 'js/hooks/useChat';
import { useClickOutside } from 'js/hooks/useClickOutside';
import useReactQueryCacheClear from 'js/hooks/useReactQueryCacheClear';
import queries from 'js/queries';
import {
  currentUserRoleSelector,
  currentUserSelector,
  isAnalystSelector,
} from 'js/selectors/currentUser';
import scenarioViewSelector from 'js/selectors/scenarioView';
import { signOut } from 'js/services/auth/actions';
import buttonize from 'js/utils/buttonize';
import { openInNewTab } from 'js/utils/common';
import getUsername from 'js/utils/user-name';
import { Company, PlayBook, User, UserRole } from 'types';

import CompanyMenu from './CompanyMenu';

import styles from './UserMenu.module.scss';
import menuStyles from 'js/design-system/Menu/Menu.module.scss';

interface UserMenuProps {
  company: Company;
  avatarProps?: AvatarProps;
}

const UserMenu = ({ company, avatarProps = {} }: UserMenuProps) => {
  const { mode } = useAppSelector(scenarioViewSelector);
  const currentUser = useAppSelector(currentUserSelector);
  const isAnalyst = useAppSelector(isAnalystSelector);
  const userRole = useAppSelector((state) => currentUserRoleSelector(state, company?.id));
  const { data: product } = useQuery(queries.products.detail(company.productId));
  const { id, name, avatar, userType } = currentUser || ({} as Partial<User>);
  const userRoleOrType = userRole || userType;
  const avatarSrc = useAmplifyStorage(avatar);
  const { adminConsoleAccessible } = useAccess({ company });
  const { startChat } = useChat();
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement>(null);
  const { trackEvent } = useTrackEvent<GeneralClickEventDetails>();
  const clearQueryCache = useReactQueryCacheClear();

  const [open, setOpen] = useState(false);
  const [companyMenuOpen, setCompanyMenuOpen] = useState(false);

  const onHelp = () => {
    startChat(PlayBook.Help);
    trackEvent('general.click.help');
    setOpen(false);
  };

  const goToHelpCenter = () => {
    openInNewTab('https://help.opencomp.com/en/knowledge');
    trackEvent('general.click.helpCenter', { source: mode });
    setOpen(false);
  };

  const goToTrustCenter = () => {
    openInNewTab('https://trust.opencomp.com');
    trackEvent('general.click.trustCenter', { source: mode });
    setOpen(false);
  };

  const handleSignOut = () => {
    signOut(() => {
      clearQueryCache();
    });
  };

  useClickOutside(ref, () => !companyMenuOpen && setOpen(false));

  if (!id) return null;

  return (
    <div className={menuStyles.menuContainer} ref={ref}>
      <div {...buttonize(() => setOpen(!open))} className={menuStyles.triggerContainer}>
        <Avatar
          name={getUsername(currentUser)}
          avatarSrc={avatarSrc}
          variant="medium"
          colorIndex={currentUser?.id}
          {...avatarProps}
        />
      </div>

      <div
        className={classNames(styles.menu, menuStyles.menu, menuStyles.hasNested, {
          [menuStyles.open]: open,
        })}
      >
        <div className={classNames(styles.itemsContainer, menuStyles.itemsContainer)}>
          {/* USER NAME & ROLE */}
          <div className={classNames(styles.userInfo, product && styles[camelCase(product.tier)])}>
            <Text variant="text-body-main-bold">{name}</Text>
            {userRoleOrType && <Text variant="text-misc-help">{capitalCase(userRoleOrType)}</Text>}
          </div>

          {/* ACCOUNT PLAN */}
          {product && (
            <HideForRole roles={[UserRole.RewardsViewer]}>
              <div
                className={classNames(
                  menuStyles.item,
                  styles.subscription,
                  styles[camelCase(product.name)],
                )}
              >
                <div className={styles.planName}>{product.name} Plan</div>
                <Badge>{company.paid ? 'PAID' : 'FREE'}</Badge>
              </div>
            </HideForRole>
          )}

          {/* ADMIN CONSOLE */}
          {adminConsoleAccessible && (
            <div
              className={classNames(menuStyles.item, styles.adminConsole)}
              {...buttonize(() => navigate(`/companies/${company.id}/edit`))}
            >
              Admin console
            </div>
          )}

          {/* ANALYST CONSOLE */}
          {isAnalyst && (
            <div
              className={menuStyles.item}
              {...buttonize(() => navigate('/analyst-console/external-syncs'))}
            >
              Analyst console
            </div>
          )}

          {/* COMPANY MENU */}
          <CompanyMenu
            company={company}
            onOpen={() => setCompanyMenuOpen(true)}
            onClose={() => setCompanyMenuOpen(false)}
          />

          {(isAnalyst || adminConsoleAccessible) && <div className={menuStyles.divider} />}

          <div className={menuStyles.item} {...buttonize(() => navigate('/profile'))}>
            My account
          </div>

          <div
            className={menuStyles.item}
            {...buttonize(() => openInNewTab('https://www.opencomp.com/privacy'))}
          >
            Privacy policy
          </div>

          <HideForRole roles={[UserRole.RewardsViewer]}>
            <div className={menuStyles.item} {...buttonize(goToHelpCenter)}>
              Help Center
            </div>
          </HideForRole>

          <div className={menuStyles.item} {...buttonize(goToTrustCenter)}>
            SOC 2 Type II compliance
          </div>

          <div className={menuStyles.item} {...buttonize(onHelp)}>
            Need help?
          </div>

          <div className={menuStyles.divider} />

          <div className={menuStyles.item} {...buttonize(handleSignOut)}>
            Sign out
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserMenu;
