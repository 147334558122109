import { useQuery } from '@tanstack/react-query';

import ExternalSyncChannel from 'js/channels/ExternalSyncChannel';
import queries from 'js/queries';

const ScenarioExternalSync = ({ scenarioId }: { scenarioId: number }) => {
  const { data: externalSync } = useQuery(
    queries.scenarios.detail(scenarioId)._ctx.lastEmployeesSync,
  );

  if (!externalSync) return null;

  return <ExternalSyncChannel id={externalSync.id} />;
};

export default ScenarioExternalSync;
