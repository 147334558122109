export default {
  view: {
    default: 'Scenario View',
    details: 'Scenario Details View',
    change: 'Scenario View Change',
    equityDetails: 'Scenario Equity Details View',
    importEmployees: 'Scenario Import Employees View',
    geoStrategy: 'Scenario Geo Strategy View',
    userPermissions: 'Scenario User Permissions View',
    benchmarkData: 'Scenario Benchmark Data View',
    customizeRanges: 'Scenario Customize Ranges View',
    benefits: 'Scenario Benefits View',
    payStrategy: 'Scenario Pay Strategy View',
    totalRewards: 'Scenario Total Rewards View',
  },
  create: true,
  update: true,
  copy: true,
  filter: true,
  change: {
    view: 'Scenario Change View',
    cut: 'Scenario Cut Change',
  },
  sort: true,
  export: true,
  import: true,
  add: {
    access: 'Scenario Access Add',
  },
  revoke: {
    access: 'Scenario Access Revoke',
  },
};
