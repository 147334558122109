import { ReactNode, useEffect } from 'react';
import { useForm, useFormState } from 'react-final-form';

import { workEmailValidation } from 'js/components-legacy/form/validations';
import { Select, TextField } from 'js/design-system/Form';
import { ConnectedFieldProps } from 'js/design-system/Form/ConnectedField';
import { TextFieldProps, TextFieldValue } from 'js/design-system/Form/TextField';
import useTotalRewardsAccess from 'js/hooks/useTotalRewardsAccess';
import { RoleOption, roles } from 'js/services/permissionService';
import { InviteScenarioUserFormData, UserRole } from 'types';

import RoleHint from './RoleHint';

import styles from './EmployeeEmailWithRole.module.scss';

interface EmployeeEmailWithRoleProps
  extends Pick<ConnectedFieldProps<TextFieldProps>, 'validate' | 'helpText'> {
  defaultRole?: UserRole;
  onChange: (value: TextFieldValue) => void;
  sharedToAdminAccessible?: boolean;
  shareScenarioAccessible?: boolean;
  roleDisabled?: boolean;
  isUserFound?: boolean;
  // Optionally pass a custom validator. If not provided, the default validator (`workEmailValidation`) will be used.
  validate?: (value: TextFieldValue | undefined) => string | undefined;
  helpText?: ReactNode;
}

export const EmployeeEmailWithRole = ({
  defaultRole,
  onChange,
  sharedToAdminAccessible = true,
  shareScenarioAccessible = true,
  roleDisabled,
  isUserFound,
  validate,
  helpText,
}: EmployeeEmailWithRoleProps) => {
  const { canManage: canManageTotalRewards } = useTotalRewardsAccess();
  const form = useForm();
  const { role, disabledRoles } = useFormState<InviteScenarioUserFormData>().values;

  const makeOption = (userRole: UserRole) => ({
    ...roles[userRole],
    label: userRole === UserRole.Administrator ? roles[userRole].label : roles[userRole].shortLabel,
  });

  let options: RoleOption[] = [
    makeOption(UserRole.OrganizationLeader),
    makeOption(UserRole.TeamLeader),
  ];

  if (sharedToAdminAccessible) {
    options.unshift(makeOption(UserRole.Administrator));
  }

  if (shareScenarioAccessible) {
    options.push(makeOption(UserRole.Recruiter));
  }

  if (role === UserRole.Approver) {
    options.push(makeOption(UserRole.Approver));
  }

  if (canManageTotalRewards) {
    options.push(makeOption(UserRole.RewardsViewer));
  }

  options = options.map((option) => ({
    ...option,
    disabled: option.disabled || disabledRoles?.includes(option.value),
  }));

  useEffect(() => {
    if (!defaultRole || !Object.values(UserRole).includes(defaultRole)) return;
    form.change('role', defaultRole);
  }, [defaultRole, form]);

  return (
    <TextField
      name="email"
      label="Employee's Work Email and User Type"
      labelPosition="top"
      validate={(value) =>
        validate ? validate(value) : workEmailValidation(value ? `${value}` : null)
      }
      isInvalid={(meta) => !meta.valid && !meta.active && !!meta.touched}
      placeholder="email@company.org"
      helpText={helpText || <RoleHint role={role} isUserFound={isUserFound} />}
      onChange={onChange}
      inputAppend={
        <div className={styles.select}>
          <Select
            name="role"
            options={options}
            filterable={false}
            wrapperClassName={styles.selectWrapper}
            menuClassName={styles.selectMenu}
            positionX="right"
            positionY="top"
            disabled={roleDisabled}
          />
        </div>
      }
    />
  );
};
