import { getIn } from 'final-form';
import { useFormState } from 'react-final-form';

import ConnectedField, { ConnectedFieldProps } from 'js/design-system/Form/ConnectedField';

import { Select, SelectProps } from './Select';

export const ConnectedSelect = <T extends number | string>({
  options,
  ...rest
}: ConnectedFieldProps<SelectProps<T>>) => {
  const { values: formValues } = useFormState();

  const formValue = getIn(formValues, rest.name);

  const hasDefault = !!options.find((o) => 'default' in o);

  // If a default is not explicitly given, set it based on form values
  const connectedOptions =
    rest.value || hasDefault
      ? options
      : options.map((option) => ({
          ...option,
          default: formValue === option.value,
        }));

  return (
    <ConnectedField
      fieldType="select"
      renderField={(fieldProps) => <Select {...fieldProps} options={connectedOptions} />}
      {...rest}
    />
  );
};
