import {
  HTMLAttributes,
  KeyboardEvent,
  ReactNode,
  RefObject,
  useEffect,
  useRef,
  useState,
} from 'react';

interface ListNavigationProps<
  TItem extends Record<string, any>,
  TContainerElement extends HTMLElement,
> extends Omit<HTMLAttributes<HTMLDivElement>, 'children'> {
  children: (ref: RefObject<TContainerElement>, currentIndex: number) => ReactNode;
  items?: TItem[];
  itemDisplayField?: keyof TItem;
  onOpen?: () => void;
  onClose?: () => void;
  onItemSelect?: (item: TItem) => void;
  onCharKeyDown?: (key: string) => void;
  searchableByFirstChar?: boolean;
}

const ListNavigation = <TItem extends Record<string, any>, TContainerElement extends HTMLElement>({
  items = [],
  children,
  itemDisplayField = 'label',
  onOpen = () => {},
  onClose = () => {},
  onItemSelect = () => {},
  onCharKeyDown,
  searchableByFirstChar = false,
  ...props
}: ListNavigationProps<TItem, TContainerElement>) => {
  const controlKeys = ['Tab', 'ArrowUp', 'ArrowDown', 'Escape', 'Enter'];
  const itemsContainerRef = useRef<TContainerElement>(null);
  const [currentIndex, setCurrentIndex] = useState<number>(-1);

  useEffect(() => {
    if (itemsContainerRef.current) {
      (itemsContainerRef.current.children[currentIndex] as HTMLDivElement)?.focus();
    }
  }, [currentIndex]);

  const handleCharKeyDown = (e: KeyboardEvent) => {
    if (onCharKeyDown) {
      onCharKeyDown(e.key);
      return;
    }

    if (!searchableByFirstChar) {
      return;
    }

    e.preventDefault();

    const firstCharCode = (s: string) => s.toLowerCase().charCodeAt(0);
    const keyCode = firstCharCode(e.key);

    const suggestedIndex = items.reduce((prev, _, index) => {
      const currentValue = firstCharCode(String(items[index][itemDisplayField]));
      const prevValue = firstCharCode(String(items[prev][itemDisplayField]));
      return Math.abs(currentValue - keyCode) < Math.abs(prevValue - keyCode) ? index : prev;
    }, 0);

    setCurrentIndex(suggestedIndex);
  };

  const handleControlKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Tab' || e.key === 'Escape') {
      setCurrentIndex(-1);
      onClose();
      return;
    }

    if (e.key === 'Enter') {
      if (items[currentIndex]) {
        onItemSelect(items[currentIndex]);
      }
      return;
    }

    e.preventDefault();
    onOpen();

    setCurrentIndex((curr) => {
      let nextIndex;
      if (e.key === 'ArrowUp') {
        nextIndex = curr ?? items.length;
        nextIndex = nextIndex > 0 ? nextIndex - 1 : items.length - 1;
      } else if (curr === -1) {
        nextIndex = 1;
      } else {
        nextIndex = curr ?? -1;
        nextIndex = nextIndex < items.length - 1 ? nextIndex + 1 : 0;
      }
      return nextIndex;
    });
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (controlKeys.includes(e.key)) {
      handleControlKeyDown(e);
    } else {
      handleCharKeyDown(e);
    }
  };

  return (
    <div tabIndex={-1} {...props} onKeyDown={handleKeyDown} role="menu">
      {children(itemsContainerRef, currentIndex)}
    </div>
  );
};

export default ListNavigation;
