import classNames from 'classnames';
import { ReactNode } from 'react';

import styles from './Label.module.scss';

export interface LabelProps {
  label: ReactNode;
  subLabel?: ReactNode;
  htmlFor?: string;
  className?: string;
  required?: boolean;
}

const Label = ({ label, subLabel, htmlFor, className, required = false }: LabelProps) => (
  <div className={classNames(styles.labelWrapper, className)}>
    <label htmlFor={htmlFor} className={styles.label}>
      {label}
      {required ? <span className={styles.asterisk}>*</span> : null}
    </label>
    {subLabel ? <div className={styles.subLabel}>{subLabel}</div> : null}
  </div>
);

export default Label;
