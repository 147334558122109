import { AnyAction } from 'redux';

import { ApiClient } from 'types';

import ACTIONS from '../constants/actions';

type ApiClientState = {
  byCompanyId: Record<number, ApiClient>;
};

const initialState: ApiClientState = {
  byCompanyId: {},
};

export default (
  state: ApiClientState = initialState,
  action: AnyAction = { type: null },
): ApiClientState => {
  switch (action.type) {
    case ACTIONS.CREATE_API_CLIENT:
    case ACTIONS.FETCH_API_CLIENT: {
      const { companyId, ...data } = action.data;

      return {
        ...state,
        byCompanyId: {
          ...state.byCompanyId,
          [companyId]: data,
        },
      };
    }
    case ACTIONS.DESTROY_API_CLIENT: {
      const { [action.data.companyId]: companyId, ...byCompanyId } = state.byCompanyId;

      return {
        ...state,
        byCompanyId,
      };
    }
    default:
      return state;
  }
};
