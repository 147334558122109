import diff from 'object-diff';

import { trackEvent } from 'js/analytics';
import { alertError } from 'js/components-legacy/common/AlertMessage';
import { isFutureDate } from 'js/utils/dates';
import { getFormattedISODate } from 'js/utils/formatters/dates';
import { Gender, QuantityUnit } from 'types';

import { buildCompensation } from '../api/employees';
import GLOBALS from '../config/globals';
import { VERIFIED } from './employeeService';
import { getJobRoleDefaultLevel, getJobLevels, isValidJobLevelId } from './levelService';
import { hasCashConnection } from './scenarioService';

const DEFAULT_LEVELS = [1, 2, 3, 4, 5, 6];
const ANCHOR_LOCATION_ID = 12;

const NEW_EMPLOYEE_DEFAULTS = {
  jobTitle: null,
  jobRoleId: null,
  jobLevelId: null,
  jobLevel: null,
  name: '',
  startDate: getFormattedISODate(new Date()),
  includedInTotals: true,
  needUpgrade: { market: false, ranges: false },
  locationId: ANCHOR_LOCATION_ID,
  locationName: null,
  gender: Gender.None,
  ethnicity: [],
  noPool: false,
  notes: null,
  currencyCode: 'USD',
  useMarketData: false,
  cashAdjustment: null,
  equityAdjustment: null,
  baseSalary: null,
  annualBonus: null,
  annualBonusUnit: QuantityUnit.Number,
  commissions: null,
  shareCount: null,
  totalCash: null,
  employeeType: 'employee',
  employeeSubType: 'full_time',
  compensation: { totalCash: null, totalCashUsd: null, ranges: {} },
};

const NEED_UPGRADE_FIELDS = {
  ranges: ['ranges'],
  market: [
    'percentiles',
    'baseSalaryTargetUsd',
    'baseSalaryPercentile',
    'totalCashTargetUsd',
    'totalCashPercentile',
    'shareCountTarget',
    'ownershipPercentageTarget',
    'equityPercentile',
  ],
  useMarketData: [
    'annualBonusUsd',
    'commissionsUsd',
    'baseSalary',
    'baseSalaryUsd',
    'totalCash',
    'totalCashUsd',
    'ownershipPercentage',
    'shareCount',
  ],
};

const sanitizeCompensation = (compensation, employee) => {
  if (!compensation || Object.keys(compensation).length === 0) {
    return NEW_EMPLOYEE_DEFAULTS.compensation;
  }

  let hiddenFields = [];
  if (employee.needUpgrade.market) hiddenFields += NEED_UPGRADE_FIELDS.market;
  if (employee.needUpgrade.ranges) hiddenFields += NEED_UPGRADE_FIELDS.ranges;
  if (employee.useMarketData && employee.needUpgrade.ranges && employee.needUpgrade.market)
    hiddenFields += NEED_UPGRADE_FIELDS.useMarketData;

  return Object.entries(compensation).reduce((sanitizedCompensation, [key, value]) => {
    if (hiddenFields.includes(key)) return sanitizedCompensation;
    return { ...sanitizedCompensation, [key]: value };
  }, {});
};

const initialFormData = (employee) => {
  const { compensation = {}, ...employeeAttrs } = employee;
  const data = employeeAttrs.id
    ? employeeAttrs
    : {
        ...NEW_EMPLOYEE_DEFAULTS,
        ...employeeAttrs,
      };

  data.jobRoleName = data.jobRole?.name;
  data.startDate = getFormattedISODate(data.startDate);
  data.compensation = sanitizeCompensation(compensation, data);

  return data;
};

const getJobRoleRelatedData = (employee, jobRole, jobTypeLevels) => {
  const { useMarketData, jobLevel } = employee;

  const jobLevels = getJobLevels(jobTypeLevels, jobRole);
  let newJobLevel = jobLevel;
  if (!jobLevel || !isValidJobLevelId(jobLevels, jobLevel.id)) {
    newJobLevel = getJobRoleDefaultLevel(jobLevels, jobRole);
  }

  return {
    jobRoleId: jobRole?.id || null,
    jobRoleName: jobRole?.name || '',
    jobRole,
    jobLevelId: newJobLevel?.id || null,
    jobLevel: newJobLevel,
    useMarketData: jobRole ? useMarketData : false,
  };
};

const fetchCompensation = async (scenarioId, employee, source) => {
  if (!employee.jobRoleId || !employee.locationId) {
    return {};
  }
  const { data, error } = await buildCompensation(scenarioId, employee, source);
  if (data) {
    return {
      ...data,
      compensation: sanitizeCompensation(data.compensation, employee),
    };
  }

  alertError(error || 'Failed to fetch compensation data');
  return {};
};

const updateEmployee = async (employee, updatedValues, scenario, update) => {
  const {
    appliedGeoAdjustment,
    compensation,
    jobRole,
    jobRoleName,
    jobLevel,
    department,
    location,
    reportsTo,
    ...changedFields
  } = diff(employee, {
    ...updatedValues,
    verify: VERIFIED,
  });

  const checkLockedFields = Object.keys(changedFields).filter((field) =>
    GLOBALS.employeeLockedFields.includes(field),
  );

  if (
    checkLockedFields.length > 0 &&
    hasCashConnection(scenario) &&
    scenario.scenarioType === 'base'
  ) {
    alertError('Cannot save read-only fields');
  } else {
    await update(changedFields);
    trackEvent('employee.update', {
      scenarioId: scenario.id,
      employeeId: employee.id,
      companyId: scenario.companyId,
    });
  }
};

const validateForm = (values) => {
  const errors = {};
  const { startDate, endDate } = values;

  if (startDate && endDate && startDate > endDate) {
    errors.startDate = 'Start date cannot be after end date';
    errors.endDate = 'End date cannot be before start date';
  }

  return errors;
};

const checkFormConfirm = (form, setPrompt) => {
  const { values, dirtyFields } = form.getState();

  const isPast = values.endDate ? !isFutureDate(values.endDate) : false;
  if (dirtyFields.endDate && isPast) {
    setPrompt(
      'Changing end date to the past will terminate the employee. Are you sure you want to proceed?',
    );
    return true;
  }

  return false;
};

export {
  ANCHOR_LOCATION_ID,
  DEFAULT_LEVELS,
  initialFormData,
  fetchCompensation,
  getJobRoleRelatedData,
  updateEmployee,
  validateForm,
  checkFormConfirm,
};
