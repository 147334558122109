import { PayMix } from 'types';

import api from './api';

export const fetchPayMix = async (scenarioId: number, jobRoleId: number) => {
  const { success, data } = await api.get<PayMix>(`/scenarios/${scenarioId}/pay_mix`, {
    urlParams: { jobRoleId },
  });

  if (success) {
    return data;
  }

  throw new Error('Failed to load pay mix');
};
