import { AppState, EmployeeUser, FetchStatus } from 'types';

export const byScenarioId = (state: AppState): Record<number, EmployeeUser[]> =>
  state.scenarioEmployeesUsers.byScenarioId;

export const getByScenarioId = (state: AppState, scenarioId?: number): EmployeeUser[] =>
  state.scenarioEmployeesUsers.byScenarioId[Number(scenarioId)] || [];

export const statusByScenarioId = (state: AppState, scenarioId?: number): FetchStatus =>
  state.scenarioEmployeesUsers.statusByScenarioId[Number(scenarioId)] || FetchStatus.Idle;
