import { useQuery } from '@tanstack/react-query';

import useRangeBuilder from 'js/components/pay-strategy/hooks/useRangeBuilder';
import useAppSelector from 'js/hooks/useAppSelector';
import useNumberParams from 'js/hooks/useNumberParams';
import queries from 'js/queries';
import { getById as scenarioSelector } from 'js/selectors/scenarios';
import { keys, PayStrategy } from 'types';

const useOnboardRangesCompletion = () => {
  const { scenarioId } = useNumberParams();
  const { data: employees = [] } = useQuery(
    queries.scenarios.detail(scenarioId)._ctx.employees(false),
  );
  const scenario = useAppSelector((state) => scenarioSelector(state, scenarioId));
  const {
    payRange: draftRange,
    hasActiveRange,
    ready: rangesReady,
    canUpdateRange,
  } = useRangeBuilder();

  const employeesAdded = employees.length > 0;

  const benchmarkConfirmed = !!draftRange || hasActiveRange || !!scenario?.benchmarkConfirmed;

  const rangesFineTuned =
    canUpdateRange &&
    employeesAdded &&
    benchmarkConfirmed &&
    draftRange?.visitedSteps.length === keys(PayStrategy).length;

  return { rangesReady, employeesAdded, benchmarkConfirmed, rangesFineTuned };
};

export default useOnboardRangesCompletion;
