import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useState, memo, useCallback, useEffect, useRef } from 'react';

import { showConfetti } from 'js/actions/confetti';
import { EquityConnectDefaultEventDetails } from 'js/analytics/types/equity';
import useTrackEvent from 'js/analytics/useTrackEvent';
import SyncBanner, { BannerVariant } from 'js/components-legacy/common/SyncBanner';
import useAppDispatch from 'js/hooks/useAppDispatch';
import useAppSelector from 'js/hooks/useAppSelector';
import useNumberParams from 'js/hooks/useNumberParams';
import queries from 'js/queries';
import { getIdFromScenarioOrCompany } from 'js/selectors/companies';
import { WebsocketsConsumer } from 'js/websockets';
import { EquityChannelReceive } from 'types';

const text = (status: string) => {
  switch (status) {
    case 'in_progress':
      return 'Employee data sync in progress.';
    case 'success':
      return 'Your employee equity data is now up to date.';
    case 'failed':
      return 'Import failed.';
    case 'disconnected':
      return 'Your equity provider has been disconnected.';
    default:
      return '';
  }
};

const EquitySyncBanner = ({ companyId }: { companyId: number }) => {
  const queryClient = useQueryClient();
  const [message, setMessage] = useState<
    BannerVariant | 'in_progress' | 'success' | 'failed' | null
  >(null);
  const dispatch = useAppDispatch();
  const { trackEvent } = useTrackEvent<EquityConnectDefaultEventDetails>();
  const { data: externalSync } = useQuery(
    queries.companies.detail(companyId)._ctx.lastEquityConnectionSync,
  );
  const dismissed = useRef(false);

  useEffect(() => {
    if (externalSync) {
      if (externalSync.status !== 'succeeded' && externalSync.status !== 'failed') {
        setMessage('in_progress');
      } else {
        dismissed.current = false;
      }
    }
  }, [externalSync]);

  const onCtaClick = () => {
    if (externalSync) {
      dismissed.current = externalSync.status !== 'succeeded' && externalSync.status !== 'failed';
    }
    setMessage(null);
  };

  const onStatusReceived = useCallback(
    ({ message }: EquityChannelReceive) => {
      queryClient.invalidateQueries(
        queries.companies.detail(companyId)._ctx.lastEquityConnectionSync,
      );
      setMessage(message);
      if (message === 'success' || message === 'failed') {
        dismissed.current = false;
      }
      if (message === 'success') {
        dispatch(showConfetti());
        trackEvent('equity.connect.default');
      }
    },
    [companyId, queryClient, dispatch, trackEvent],
  );

  return (
    <>
      {!dismissed.current && message && (
        <SyncBanner variant={message} text={text(message)} cta="Dismiss" onCtaClick={onCtaClick} />
      )}
      <WebsocketsConsumer channel="EquityChannel" onReceived={onStatusReceived} />
    </>
  );
};

const EquitySyncBannerWithCompanyId = () => {
  const { scenarioId, companyId: companyIdParam } = useNumberParams();
  const companyId = useAppSelector((state) =>
    getIdFromScenarioOrCompany(state, companyIdParam, scenarioId),
  );

  if (!companyId) {
    return null;
  }

  return <EquitySyncBanner companyId={companyId} />;
};

export default memo(EquitySyncBannerWithCompanyId);
