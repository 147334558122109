import { Amplify } from '@aws-amplify/core';
import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './js/App';
import Store from './js/store';

const {
  REACT_APP_AWS_IDENTITY_POOL_ID,
  REACT_APP_AWS_REGION,
  REACT_APP_AWS_USER_POOL_ID,
  REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
  REACT_APP_AWS_BUCKET,
  REACT_APP_OAUTH_GOOGLE_DOMAIN,
} = process.env;

Amplify.configure({
  aws_project_region: REACT_APP_AWS_REGION,
  aws_cognito_region: REACT_APP_AWS_REGION,
  aws_appsync_region: REACT_APP_AWS_REGION,
  Auth: {
    identityPoolId: REACT_APP_AWS_IDENTITY_POOL_ID,
    region: REACT_APP_AWS_REGION,
    userPoolId: REACT_APP_AWS_USER_POOL_ID,
    userPoolWebClientId: REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
  },
  Storage: {
    AWSS3: {
      bucket: REACT_APP_AWS_BUCKET,
      region: REACT_APP_AWS_REGION,
    },
  },
  oauth: {
    domain: REACT_APP_OAUTH_GOOGLE_DOMAIN,
    redirectSignIn: `${window.location.origin}/oauth/sign-in/`,
    redirectSignOut: `${window.location.origin}/login`,
    scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    responseType: 'code',
  },
});

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(<App store={Store} />);
