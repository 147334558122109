export enum UpgradeEventSource {
  OfferWorkflow = 'offer workflow',
  DownloadRanges = 'download ranges',
  OverLimitScenarioBanner = 'over limit scenario banner',
  OverLimitEmployeeDetails = 'over limit employee details',
  GeoStrategyCustom = 'geo strategy',
  RangePositionCustom = 'range market position customization',
  RangeWidthCustom = 'range width customization',
  RangeGroupCustom = 'job family grouping customization',
  Navbar = 'navbar',
  OnboardRanges = 'onboard ranges',
  RangesTopScheduleDemo = 'ranges top schedule demo',
  RangesBottomGetStarted = 'ranges bottom get started',
  Compulator = 'compulator',
  CompCycle = 'comp cycle',
  NewScenario = 'new scenario',
  TotalRewards = 'total rewards',
  HeadcountPlan = 'headcount plan',
  DEI = 'dei',
  DataLookupRanges = 'data look up ranges',
  EmployeeForm = 'employee form',
}
