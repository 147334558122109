export default {
  view: {
    signUp: 'User Sign Up View',
    signUpEmbedded: 'User Sign Up Embedded View',
    signIn: 'User Sign In View',
    forgotPassword: 'User Forgot Password View',
    forgotPasswordConfirm: 'User Forgot Password Confirm View',
    resetPassword: 'User Reset Password View',
    emailVerification: 'User Email Verification View',
    emailVerificationEmbedded: 'User Email Verification View Embedded',
  },
  change: {
    role: 'User Role Change',
  },
  send: {
    invite: 'User Invite Resend',
  },
  set: {
    goals: 'User Goals Set',
  },
  action: {
    signUp: 'User Sign Up',
  },
};
