import { useQuery } from '@tanstack/react-query';
import Modal from 'react-bootstrap/Modal';

import queries from 'js/queries';

import Pulley from './Pulley';

import styles from './ConnectEquity.module.scss';

interface ConnectEquityModalProps {
  show: boolean;
  onHide: () => void;
  companyId: number;
}

const ConnectEquityModal = ({ show, onHide, companyId }: ConnectEquityModalProps) => {
  const { data: connection } = useQuery(queries.companies.detail(companyId)._ctx.equityConnection);

  return (
    <Modal
      className={styles.equityConnections}
      contentClassName={styles.equityConnectionsContent}
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton className={styles.header}>
        <Modal.Title>
          <div className={styles.header}>
            <div>Select your equity provider</div>
          </div>
        </Modal.Title>
      </Modal.Header>
      {!connection || connection.provider === 'pulley' ? (
        <Pulley closeParent={onHide} companyId={companyId} />
      ) : null}
    </Modal>
  );
};

export default ConnectEquityModal;
