import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import EmployeesCount from 'js/components/common/EmployeesCount';
import HeadcountHover from 'js/components/common/HeadcountHover';
import { Popover } from 'js/design-system/Popover/Popover';
import TextButton from 'js/design-system/TextButton/TextButton';
import useNumberParams from 'js/hooks/useNumberParams';

import styles from './EmployeesCountHover.module.scss';

interface EmployeesCountHoverProps {
  activeCount: number;
  excludedCount: number;
  hiddenCount: number;
}

const EmployeesCountHover = ({
  activeCount,
  excludedCount,
  hiddenCount,
}: EmployeesCountHoverProps) => {
  const navigate = useNavigate();
  const { scenarioId } = useNumberParams();

  const renderSummaryCountTag = (showText: boolean) => (
    <EmployeesCount
      count={activeCount}
      showText={showText}
      breakpoint={undefined}
      tooltip={null}
      className={styles.employeeCount}
    />
  );

  const helpText = useMemo(
    () => (
      <>
        <TextButton
          variant="text-misc-help"
          onClick={(event) => {
            event.stopPropagation();
            navigate(`/scenarios/${scenarioId}/edit`);
          }}
        >
          Configure
        </TextButton>{' '}
        which employees are hidden.
      </>
    ),
    [scenarioId, navigate],
  );

  const bodyData = [
    {
      label: 'Active',
      value: activeCount,
    },
    {
      label: 'Excluded from totals',
      value: excludedCount,
    },
    {
      label: 'Hidden via Scenario Settings',
      value: hiddenCount,
    },
  ];

  return (
    <Popover
      content={<HeadcountHover count={activeCount} helpText={helpText} bodyData={bodyData} />}
      delay
    >
      <div className={styles.summaryCountTag}>{renderSummaryCountTag(false)}</div>
    </Popover>
  );
};

export default EmployeesCountHover;
