import classNames from 'classnames';

import { TableAlign, TableRole } from '../types';

import styles from '../BaseTable.module.scss';

interface TableCellProps {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  role?: `${TableRole}`;
  align?: `${TableAlign}`;
  size?: 'small' | 'medium-small' | 'medium' | 'medium-large' | 'large';
  dataTestId?: string;
}

const TableCell = ({
  children,
  className,
  style,
  role = 'cell',
  align = 'start',
  size = 'medium',
  dataTestId,
}: TableCellProps) => (
  <div
    role={role}
    className={classNames(styles.cell, `text-${align}`, styles[size], className)}
    style={style}
    data-testid={dataTestId}
  >
    {children}
  </div>
);

export default TableCell;
