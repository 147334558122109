import { alertError } from 'js/components-legacy/common/AlertMessage';
import { AppDispatch, CashConnection, GetState } from 'types';

import api from '../api/api';
import ACTIONS from '../constants/actions';

export const fetchCashConnection =
  (companyId: number, integrationProxyName: 'merge' | 'finch', forced = false) =>
  async (dispatch: AppDispatch, getState: GetState) => {
    const { fetched, companyId: oldId } = getState().cashConnection;
    if (!forced && fetched && oldId === companyId) {
      return;
    }
    const { success, data, error } = await api.get<CashConnection>(
      `/companies/${companyId}/cash_connection`,
      {
        urlParams: {
          integrationProxyName,
        },
      },
    );
    if (success) {
      dispatch({ type: ACTIONS.RECEIVE_CASH_CONNECTION, data, id: companyId });
    } else {
      alertError(error);
    }
  };

export const forcedFetchCashConnection = (id: number, integrationProxyName: 'finch' | 'merge') =>
  fetchCashConnection(id, integrationProxyName, true);
