import { useCallback, useEffect, useState } from 'react';

const useAmplifyStorage = (url?: string | null) => {
  const [avatarSrc, setAvatarSrc] = useState<string | undefined>(undefined);

  const loadAvatar = useCallback(async (url?: string | null) => {
    if (url) {
      try {
        const fetchedAvatar = await import('@aws-amplify/storage').then(({ Storage }) =>
          Storage.get(url, { level: 'public' }),
        );
        setAvatarSrc(fetchedAvatar);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        setAvatarSrc(undefined);
      }
    } else {
      setAvatarSrc(undefined);
    }
  }, []);

  useEffect(() => {
    loadAvatar(url);
  }, [loadAvatar, url]);

  return avatarSrc;
};

export default useAmplifyStorage;
