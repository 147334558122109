import { useQuery } from '@tanstack/react-query';

import { navigationItems } from 'js/config/globals-ts';
import useAppSelector from 'js/hooks/useAppSelector';
import queries from 'js/queries';
import scenarioViewSelector from 'js/selectors/scenarioView';
import { filterEmployees, getIncludedInTotalsEmployees } from 'js/services/employeeFilterService';
import { denormalizeHiddenEmployeesBit, HIDDEN_EMPLOYEES_BIT } from 'js/services/scenarioService';

const useScenarioEmployeeCount = (scenarioId: number) => {
  const employeeViewConfig = useAppSelector(scenarioViewSelector);
  const isHiring = employeeViewConfig.mode === navigationItems.hiring.name;

  const { data: allEmployees = [] } = useQuery(
    queries.scenarios.detail(scenarioId)._ctx.employees(false),
  );
  const hiddenEmployeesBit = denormalizeHiddenEmployeesBit(employeeViewConfig.hiddenEmployeeTypes);
  const visibleEmployees = isHiring
    ? allEmployees.filter(
        (e) =>
          (e.hidable & hiddenEmployeesBit) === 0 ||
          (e.hidable & hiddenEmployeesBit) === HIDDEN_EMPLOYEES_BIT.future ||
          (e.hidable & hiddenEmployeesBit) === HIDDEN_EMPLOYEES_BIT.terminated,
      )
    : allEmployees.filter((e) => (e.hidable & hiddenEmployeesBit) === 0);

  const filteredEmployees = filterEmployees(visibleEmployees, employeeViewConfig);
  const filteredIncludedInTotalsEmployees = getIncludedInTotalsEmployees(filteredEmployees);

  return {
    activeCount: filteredIncludedInTotalsEmployees.length,
    excludedCount: filteredEmployees.length - filteredIncludedInTotalsEmployees.length,
    hiddenCount: allEmployees.length - visibleEmployees.length,
  };
};

export default useScenarioEmployeeCount;
