import classNames from 'classnames';
import { useContext, useEffect } from 'react';

import { isEmpty } from 'js/utils/value';

import Tab from './Tab';
import { TabsContext } from './context';
import { TabSliderContentProps } from './types';

import styles from './TabSliderContent.module.scss';

const TabSliderContent = <T,>({ tabs, activeKey, className }: TabSliderContentProps<T>) => {
  const { activeTabKey, setActiveTabKey } = useContext(TabsContext);

  useEffect(() => {
    if (isEmpty(activeTabKey) && isEmpty(activeKey)) setActiveTabKey(tabs[0].key);
  }, [activeKey, activeTabKey, setActiveTabKey, tabs]);

  useEffect(() => {
    if (activeKey) setActiveTabKey(activeKey);
  }, [activeKey, setActiveTabKey]);

  return (
    <div className={classNames(styles.container, className)}>
      {tabs.map((tab) => (
        <Tab key={String(tab.key)} tab={tab} />
      ))}
    </div>
  );
};

export default TabSliderContent;
