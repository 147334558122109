import { AnyAction } from 'redux';

import { Banner } from 'types';

import ACTIONS from '../constants/actions';

interface BannerState {
  allIds: string[];
  byId: {
    [id: string]: Banner;
  };
}

const initialState: BannerState = {
  allIds: [],
  byId: {},
};

export default (state = initialState, action: AnyAction = { type: null }): BannerState => {
  switch (action.type) {
    case ACTIONS.RECEIVE_BANNER: {
      let { allIds } = state;
      if (!state.allIds.includes(action.data.id)) {
        allIds = [...allIds, action.data.id];
      }

      return {
        ...state,
        allIds,
        byId: { ...state.byId, [action.data.id]: action.data },
      };
    }
    default:
      return state;
  }
};
