import classNames from 'classnames';
import { ForwardedRef, forwardRef } from 'react';

import styles from '../BaseTable.module.scss';

interface TableRowGroupProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}

const TableRowGroup = forwardRef(
  ({ children, style, className }: TableRowGroupProps, ref: ForwardedRef<HTMLDivElement>) => (
    <div ref={ref} role="rowgroup" style={style} className={classNames(styles.rowgroup, className)}>
      {children}
    </div>
  ),
);

TableRowGroup.displayName = 'TableRowGroup';

export default TableRowGroup;
