import { createQueryKeys } from '@lukemorales/query-key-factory';

import { fetchAnalysts } from 'js/api/analyst/users';

const users = createQueryKeys('users', {
  analysts: {
    queryKey: null,
    queryFn: fetchAnalysts,
  },
});

export default users;
