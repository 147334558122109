const buttonize = (
  onClick: (e: React.SyntheticEvent<Element, Event>) => void,
  onKeyDown?: (e: React.KeyboardEvent<HTMLElement>) => void,
  triggerKey = 'Enter',
) => {
  if (onClick || onKeyDown) {
    return {
      tabIndex: 0,
      role: 'button',
      onClick,
      onKeyDown: (event: React.KeyboardEvent<HTMLElement>) => {
        if (event.key === triggerKey) {
          const handleKeyDown = onKeyDown || onClick;
          handleKeyDown(event);
        }
      },
    };
  }

  return {};
};

export default buttonize;
