import { AnyAction } from 'redux';

import { FetchStatus, Location } from 'types';

import ACTIONS from '../constants/actions';

type LocationState = {
  allIds: number[];
  byId: Record<number, Location>;
  error?: string;
  status: FetchStatus;
};

const initialState: LocationState = {
  allIds: [],
  byId: {},
  status: FetchStatus.Idle,
};

export default (
  state: LocationState = initialState,
  action: AnyAction = { type: null },
): LocationState => {
  switch (action.type) {
    case ACTIONS.FAIL_LOCATION_FETCH:
      return { ...state, error: action.error, status: FetchStatus.Failed };
    case ACTIONS.FETCHING_LOCATIONS:
      return { ...state, status: FetchStatus.Loading };
    case ACTIONS.RECEIVE_LOCATIONS:
      return {
        ...state,
        allIds: action.data.map((datum: Location) => datum.id),
        byId: action.data.reduce((locations: Record<number, Location>, loc: Location) => {
          locations[loc.id] = loc;
          return locations;
        }, {}),
        status: FetchStatus.Succeeded,
      };
    default:
      return state;
  }
};
