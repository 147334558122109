import { AnyAction } from 'redux';

import { FetchStatus, User } from 'types';

import ACTIONS from '../constants/actions';

type SessionState = {
  data?: User;
  loading: boolean;
  indexStatus: FetchStatus;
};

export const initialState: SessionState = {
  loading: false,
  indexStatus: FetchStatus.Idle,
};

export default (
  state: SessionState = initialState,
  action: AnyAction = { type: null },
): SessionState => {
  switch (action.type) {
    case ACTIONS.GET_USER_START:
      return {
        ...state,
        loading: true,
      };
    case ACTIONS.USER_LOADED:
      return {
        ...state,
        data: action.data,
        loading: false,
        indexStatus: FetchStatus.Succeeded,
      };
    case ACTIONS.USER_UPDATED: {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.data,
        },
      };
    }
    case ACTIONS.USER_STATUS_UPDATED: {
      return {
        ...state,
        indexStatus: action.data.indexStatus,
      };
    }
    case ACTIONS.CREATE_COMPANY: {
      if (!state.data) return state;

      return {
        ...state,
        data: {
          ...state.data,
          landingScenarioId: action.data.landingScenarioId,
          landingCompanyId: action.data.id,
        },
      };
    }
    case ACTIONS.DELETE_COMPANY: {
      if (!state.data) return state;

      return {
        ...state,
        data: {
          ...state.data,
          landingCompanyId: action.data.landingCompanyId,
          landingScenarioId: action.data.landingScenarioId,
        },
      };
    }
    case ACTIONS.CREATE_SCENARIO: {
      if (!state.data) return state;

      return {
        ...state,
        data: {
          ...state.data,
          landingScenarioId: action.data.id,
        },
      };
    }
    default:
      return state;
  }
};
