import { CurrencyCode } from 'js/utils/formatters/currency';
import {
  AppliedGeoAdjustment,
  Compensation,
  CompMode,
  Department,
  JobLevel,
  JobRoleWithLevels,
  Location,
  NullJobRole,
  PerformanceScore,
  QuantityUnit,
} from 'types';

export enum Gender {
  None = 'none',
  Female = 'female',
  Male = 'male',
  Nonconforming = 'nonconforming',
  SomethingElse = 'somethingelse',
}

export enum Ethnicity {
  BlackOrAfricanAmerican = 'black_or_african_american',
  HispanicOrLatino = 'hispanic_or_latino',
  NativeAmericanOrAlaskaNative = 'native_american_or_alaska_native',
  NativeHawaiianOrPacificIslander = 'native_hawaiian_or_pacific_islander',
  White = 'white',
  Asian = 'asian',
  TwoOrMore = 'two_or_more',
}

export enum BenchmarkQuerySource {
  Compulator = 'Compulator',
  CompulatorSecondaryFormChange = 'Compulator Secondary Form Change',
  CompulatorRecentQuery = 'Compulator Recent Query',
  Adjustments = 'Adjustments',
  Offers = 'Offers',
}

type NeedUpgrade = Record<CompMode, boolean>;

export type EmployeeDepartment = Pick<
  Department,
  'id' | 'name' | 'increase' | 'overhead' | 'recruiting' | 'setup'
>;

export type Employee = {
  annualBonus: number | null;
  annualBonusUnit: QuantityUnit;
  appliedGeoAdjustment: AppliedGeoAdjustment | null;
  baseSalary: number | null;
  cashAdjustment: number | null;
  commissions: number | null;
  compensation: Compensation;
  countryCode: string | null;
  createdAt: string;
  currencyCode: CurrencyCode | null;
  employeeNumber: string | null;
  deleted: boolean;
  departmentId: number;
  department: EmployeeDepartment;
  personalEmail: string | null;
  workEmail: string | null;
  employeeSubType: string | null;
  employeeType: string | null;
  includedInTotals: boolean;
  endDate: string | null;
  equityAdjustment: number | null;
  ethnicity: Ethnicity[];
  hidable: number;
  finchId: number | null;
  gender: Gender;
  id: number;
  isCurrent: boolean;
  isFuture: boolean;
  isPast: boolean;
  jobRole: JobRoleWithLevels | NullJobRole;
  jobRoleId: number | null;
  jobTitle: string | null;
  jobLevelId: number | null;
  jobLevel: JobLevel | null;
  locationId: number;
  locationName: string | null;
  location: Pick<Location, 'id' | 'name'> | null;
  name: string;
  needUpgrade: NeedUpgrade;
  noPool: boolean;
  notes: string | null;
  officeId: number | null;
  payRangeGroupId: number | null;
  performanceScore: PerformanceScore | null;
  reportsToId: number | null;
  reportsTo: Pick<Employee, 'id' | 'name'> | null;
  scenarioId: number;
  shareCount: number | null;
  stakeholderId: number | null;
  startDate: string;
  updatedAt: string;
  useMarketData: boolean;
  verify: number;
  segmentIds?: number[];
  integrationSource?: 'finch' | 'gusto' | 'merge' | 'csv_import';
};

export type EmployeeWithDepartment = Employee & { department: Department };

export type EmployeeMatch = Pick<
  Employee,
  | 'id'
  | 'name'
  | 'jobRoleId'
  | 'jobRole'
  | 'jobTitle'
  | 'jobLevelId'
  | 'jobLevel'
  | 'locationName'
  | 'locationId'
> & {
  subordinatesCount: number;
  usersEmployeeId: number | null;
};

export type EmployeePreviewResponse = Pick<
  Employee,
  'compensation' | 'payRangeGroupId' | 'appliedGeoAdjustment'
>;

export type EmployeeBenchmarkForm = {
  id: number;
  jobRoleId?: number | null;
  jobLevelId?: number | null;
  locationName?: string | null;
  verify?: number;
};
