import { getMean, getMeanRaw, getMedian, getSum, isValidNumber } from 'js/utils/numbers';
import { isEmpty, isNumber } from 'js/utils/value';

import { getCompTypeDetails, getCostToTarget } from './compService';
import { getCompaRatio, getPercentile, getRangePosition } from './employeeService';
import { formatCompaRatio, formatPercentile, formatRangePosition, formatValue } from './formatter';
import { getPositionPercentDistribution } from './rangeService';

const STAT_TYPES = {
  totalValue: 'totalValue',
  meanValue: 'meanValue',
  medianValue: 'medianValue',
  costToTarget: 'costToTarget',
  midPercentile: 'midPercentile',
  meanPercentile: 'meanPercentile',
  medianPercentile: 'medianPercentile',
  meanLevel: 'meanLevel',
  meanRangePosition: 'meanRangePosition',
  costToRangeMinimum: 'costToRangeMinimum',
  costAboveRangeMaximum: 'costAboveRangeMaximum',
  rangePositionPercentDistribution: 'rangePositionPercentDistribution',
  rangePosition: 'rangePosition',
  meanCompaRatio: 'meanCompaRatio',
  meanPerformance: 'meanPerformance',
};

const STAT_TYPE_DETAILS = {
  [STAT_TYPES.totalValue]: {
    getSingleValue: (employee, typeDetails) => employee.compensation[typeDetails.valueKey],
    aggregate: () => getSum,
    formatStat: formatValue,
  },
  [STAT_TYPES.meanValue]: {
    getSingleValue: (employee, typeDetails) => employee.compensation[typeDetails.valueKey],
    aggregate: () => getMean,
    formatStat: formatValue,
  },
  [STAT_TYPES.medianValue]: {
    getSingleValue: (employee, typeDetails) => employee.compensation[typeDetails.valueKey],
    aggregate: () => getMedian,
    formatStat: formatValue,
  },
  [STAT_TYPES.costToTarget]: {
    getSingleValue: (employee, typeDetails) => {
      const value = employee.compensation[typeDetails.valueKey];
      const targetValue = employee.compensation[typeDetails.targetValueKey];
      return getCostToTarget(value, targetValue);
    },
    aggregate: () => getSum,
    formatStat: formatValue,
  },
  [STAT_TYPES.midPercentile]: {
    getSingleValue: getPercentile,
    aggregate: (typeDetails) => typeDetails.midPointHandler,
    formatStat: formatPercentile,
  },
  [STAT_TYPES.meanPercentile]: {
    getSingleValue: getPercentile,
    aggregate: () => getMean,
    formatStat: formatPercentile,
  },
  [STAT_TYPES.medianPercentile]: {
    getSingleValue: getPercentile,
    aggregate: () => getMedian,
    formatStat: formatPercentile,
  },
  [STAT_TYPES.meanLevel]: {
    getSingleValue: (employee) => (!employee.jobLevel ? 9 : Number(employee.jobLevel.name)),
    aggregate: () => getMeanRaw,
    formatStat: (value) => value,
  },
  [STAT_TYPES.meanRangePosition]: {
    getSingleValue: getRangePosition,
    aggregate: () => getMean,
    formatStat: formatRangePosition,
  },
  [STAT_TYPES.costToRangeMinimum]: {
    getSingleValue: (employee, typeDetails) => {
      const value = employee.compensation[typeDetails.valueKey];
      const targetValue = employee.compensation.ranges[typeDetails.rangeKey]?.min;
      return getCostToTarget(value, targetValue);
    },
    aggregate: () => getSum,
    formatStat: formatValue,
  },
  [STAT_TYPES.costAboveRangeMaximum]: {
    getSingleValue: (employee, typeDetails) => {
      const value = employee.compensation.ranges[typeDetails.rangeKey]?.max;
      const targetValue = employee.compensation[typeDetails.valueKey];
      return getCostToTarget(value, targetValue);
    },
    aggregate: () => getSum,
    formatStat: formatValue,
  },
  [STAT_TYPES.rangePositionPercentDistribution]: {
    getSingleValue: getRangePosition,
    aggregate: () => (arr) => getPositionPercentDistribution(arr, [0, 101]),
    formatStat: (value) => ({
      below: value[Number.NEGATIVE_INFINITY],
      within: value[0],
      above: value[101],
    }),
  },
  [STAT_TYPES.rangePosition]: {
    getSingleValue: getRangePosition,
    aggregate: () => (arr) => arr,
    formatStat: (value) => value,
  },
  [STAT_TYPES.meanCompaRatio]: {
    getSingleValue: getCompaRatio,
    aggregate: () => getMeanRaw,
    formatStat: formatCompaRatio,
  },
  [STAT_TYPES.meanPerformance]: {
    getSingleValue: (employee) => employee.performanceScore?.position,
    aggregate: () => getMeanRaw,
    formatStat: (value) => value,
  },
};

const getStatsByType = (
  type,
  employees,
  statTypes,
  formatOptions = {},
  currencyExchangeRate = 1,
) => {
  // stats = { statType1: [], statType2: [] }
  const initialStats = statTypes.reduce((data, key) => {
    data[key] = [];
    return data;
  }, {});

  // stats = { statType1: [x1, x2..], statType2: [y1, y2...] }
  const stats = employees.reduce((data, employee) => {
    const typeDetails = type && getCompTypeDetails(type, employee);
    statTypes.forEach((statType) => {
      const { getSingleValue } = STAT_TYPE_DETAILS[statType];
      const value = getSingleValue(employee, typeDetails);
      if (!isEmpty(value)) {
        const parsedValue = isNumber(value) ? parseFloat(value) : value;
        data[statType].push(parsedValue);
      }
    });
    return data;
  }, initialStats);

  // stats = { statType1: sumX, statType2: avgY }
  const typeDetails = type && getCompTypeDetails(type);
  statTypes.forEach((statType) => {
    const { aggregate, formatStat } = STAT_TYPE_DETAILS[statType];
    const statValue = aggregate(typeDetails)(stats[statType]);
    if (typeDetails && typeDetails.cash && isValidNumber(statValue)) {
      stats[statType] = statValue * currencyExchangeRate;
    } else {
      stats[statType] = statValue;
    }
    stats[statType] = formatStat(stats[statType], typeDetails?.formatter, formatOptions[statType]);
  });

  return stats;
};

const getStatByType = (type, employees, statType, formatOptions = {}) => {
  const stats = getStatsByType(type, employees, [statType], formatOptions);
  return stats[statType];
};

export { STAT_TYPES, getStatsByType, getStatByType };
