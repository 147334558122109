import api from './api';

export type ExternalSync = {
  id: string;
  scenarioId: number;
  userId: number;
  dataType: 'company' | 'employees' | 'stakeholders';
  endedAt: string;
  startedAt: string;
  run: 'initial' | 'manual' | 'reconnect' | 'nightly' | 'visit';
  integrationProxyName: 'finch' | 'merge' | 'csv_import';
  reasonForFailure: string;
  status:
    | 'initialized'
    | 'ingested'
    | 'transformed'
    | 'saved'
    | 'preprocecessed'
    | 'postprocessing'
    | 'failed'
    | 'succeeded';
};

export const fetchExternalSyncs = async ({
  companyId,
  scenarioId,
  filters,
  pagination,
}: {
  companyId?: number;
  scenarioId?: number;
  filters: {
    integrationProxyName?: string | string[];
    data_type: 'company' | 'employees' | 'stakeholders';
  };
  pagination: object;
}) => {
  let url = '/external_syncs';

  if (companyId) {
    url = `/companies/${companyId}${url}`;
  } else if (scenarioId) {
    url = `/scenarios/${scenarioId}${url}`;
  }
  const { success, data } = await api.get<ExternalSync[]>(url, {
    urlParams: { filters, pagination },
  });
  if (success) {
    return data;
  }

  throw new Error('Failed to load external syncs');
};

export const fetchExternalSync = async (id: string) => {
  const { success, data } = await api.get<ExternalSync>(`/external_syncs/${id}`);
  if (success) {
    return data;
  }

  throw new Error('Failed to load external sync');
};
