import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

import pulleyLogo from 'img/integrations/pulley-logo.svg';
import Button from 'js/design-system/Button/Button';
import { ButtonSize, ButtonVariant } from 'js/design-system/Button/types';

import styles from './ConnectEquity.module.scss';

interface PulleyProps {
  closeParent: () => void;
  companyId: number;
}

const Pulley = ({ closeParent, companyId }: PulleyProps) => {
  const [show, setShow] = useState(false);
  const state = { companyId, provider: 'pulley' };
  const encodedState = btoa(JSON.stringify(state));
  const href = `${process.env.REACT_APP_PULLEY_URL}/oauth2/authorize?response_type=code&client_id=${process.env.REACT_APP_PULLEY_CLIENT}&redirect_uri=${window.location.origin}/integrations/pulley/oauth/callback&scope=all&state=${encodedState}`;

  const hideModal = () => setShow(false);

  return (
    <>
      <Modal className={styles.equityConnections} show={show} onHide={hideModal}>
        <button className={styles.cancelButton} onClick={hideModal}>
          &times;
        </button>
        <Modal.Title className={styles.titleContainer}>
          <img alt="Pulley logo" className={styles.providerIcon} src={pulleyLogo} />
          Pulley Login
        </Modal.Title>
        <Modal.Body>
          <p>
            You will now be redirected to the Pulley Login screen. Please return to this page once
            you’re done.
          </p>
        </Modal.Body>
        <a href={href}>
          <Button
            variant={ButtonVariant.Filled}
            size={ButtonSize.Medium}
            onClick={() => {
              closeParent();
              hideModal();
            }}
            className={styles.equityConfirm}
          >
            Continue
          </Button>
        </a>
      </Modal>

      <button onClick={() => setShow(true)} className={styles.equityButton}>
        <img alt="Pulley logo" src={pulleyLogo} />
        Pulley
      </button>
    </>
  );
};

export default Pulley;
