export const CONFIG = Object.freeze({
  defaultDecimalPlaces: 2,
  defaultCurrencyCode: 'USD',
  defaultShortFormatThreshold: 10 ** 8,
  defaultLocale: 'en-US',
});

// NOTE: this alphabetical list is copying on the backend, changes have to be made in both places
export const CURRENCY_CODES = [
  'ARS',
  'AUD',
  'BGN',
  'BRL',
  'CAD',
  'CHF',
  'CNY',
  'COP',
  'CZK',
  'DKK',
  'DZD',
  'EGP',
  'EUR',
  'GBP',
  'HKD',
  'IDR',
  'ILS',
  'INR',
  'JPY',
  'KES',
  'KRW',
  'KZT',
  'MXN',
  'NGN',
  'NOK',
  'NZD',
  'PHP',
  'PLN',
  'RON',
  'SAR',
  'SEK',
  'SGD',
  'THB',
  'TND',
  'TRY',
  'TWD',
  'UAH',
  'USD',
  'VND',
] as const;
