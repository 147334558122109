import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { formatError } from 'js/components-legacy/common/AlertMessage';
import { JobRoleParams, JobRoleWithMarketDataIndicator } from 'types';

import api from '../api';

export type JobRoleDetail = {
  id: number;
  jobFamilyId: number;
  jobFamilyName: number;
  name: string;
  createdAt: string;
  updatedAt: string;
  code: string;
  userSegment: string;
  description: string;
  hasMarketData: boolean;
  commissionBased: boolean;
  jobTypeName: number;
};

export const fetchJobRole = async (id: number) => {
  const { success, data } = await api.get<JobRoleDetail>(`/analyst/job_roles/${id}`);

  if (success) {
    return data;
  }

  throw new Error(`Failed to fetch job role`);
};

export const createJobRole = async (
  params: JobRoleParams,
): Promise<JobRoleWithMarketDataIndicator> => {
  const { success, data, error } = await api.post<JobRoleWithMarketDataIndicator>(
    '/analyst/job_roles',
    {
      params,
    },
  );
  if (success && data) {
    return data;
  }

  throw new Error(formatError(error) || 'Failed to create job role');
};

export const updateJobRole = async (
  id: number,
  params: JobRoleParams,
): Promise<JobRoleWithMarketDataIndicator> => {
  const { success, data, error } = await api.put<JobRoleWithMarketDataIndicator>(
    `/analyst/job_roles/${id}`,
    {
      params,
    },
  );
  if (success && data) {
    return data;
  }

  throw new Error(formatError(error) || 'Failed to update job role');
};

export const fetchJobRoles = async (filters = {}) => {
  const { success, data } = await api.get<JobRoleWithMarketDataIndicator[]>('/analyst/job_roles', {
    urlParams: { filters },
  });
  if (success) {
    return data;
  }

  throw new Error('Failed to load job roles');
};

export const queryKeys = createQueryKeys('analystJobRoles', {
  list: (filters = {}) => ({
    queryKey: [filters],
    queryFn: () => fetchJobRoles(filters),
  }),
  detail: (id: number) => ({
    queryKey: [id],
    queryFn: () => fetchJobRole(id),
  }),
});

export const useJobRoles = (filters: { jobFamilyId?: number } = {}) => {
  return useQuery(queryKeys.list(filters));
};

export const useJobRole = (id: number) => {
  return useQuery(queryKeys.detail(id));
};

export const useCreateJobRole = () => {
  const queryClient = useQueryClient();
  return useMutation((params: JobRoleParams) => createJobRole(params), {
    onSettled: () => {
      queryClient.invalidateQueries(queryKeys.list());
    },
  });
};
