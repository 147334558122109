import { useQueryClient, useMutation } from '@tanstack/react-query';

import { createPayRangeGroup } from 'js/api/pay-range-groups';
import { alertError } from 'js/components-legacy/common/AlertMessage';
import queries from 'js/queries';
import { PayRange } from 'types';

export const useCreatePayRangeGroup = (
  scenarioId: number,
  {
    onMutate = () => {},
    onSettled = () => {},
    onSuccess = () => {},
  }: {
    onMutate?: () => void;
    onSettled?: () => void;
    onSuccess?: () => void;
  },
) => {
  const queryClient = useQueryClient();
  return useMutation(
    (attrs: { jobFamilyId: number; payRange: PayRange }) =>
      createPayRangeGroup(attrs.payRange.id, attrs.jobFamilyId),
    {
      onMutate,
      onSuccess: (_data, variables) => {
        onSuccess();
        queryClient.invalidateQueries(
          queries.payRanges.oneByScenarioAndStatus(scenarioId, variables.payRange.status),
        );
        queryClient.invalidateQueries(
          queries.payRanges.detail(variables.payRange.id)._ctx.jobFamilies,
        );
      },
      onError: (error) => {
        alertError(error);
      },
      onSettled,
    },
  );
};
