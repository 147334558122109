import { Check, Info } from '@phosphor-icons/react';
import classNames from 'classnames';
import { useCallback } from 'react';

import { scrollToField } from 'js/actions/employeeForm';
import Icon from 'js/design-system/Icon';
import TextButton from 'js/design-system/TextButton/TextButton';
import useAppDispatch from 'js/hooks/useAppDispatch';

import styles from './EditEmployeeBanner.module.scss';

interface EditEmployeeBannerProps {
  message: string;
  variant: string;
  verifyFields?: string[];
  animate?: string;
}

const EditEmployeeBanner = ({
  message,
  variant,
  verifyFields = [],
  animate = '',
}: EditEmployeeBannerProps) => {
  const dispatch = useAppDispatch();

  const onClickVerifyField = useCallback(
    (field: string) => {
      dispatch(scrollToField(field));
    },
    [dispatch],
  );

  const buildVerifyFieldComponent = (field: string, index: number, arr: string[]) => {
    const { length } = arr;
    let postfix = '';

    if (length <= 1) {
      postfix = '';
    } else if (index > 0 && index < length - 1) {
      postfix = ', ';
    } else if (index === length - 1) {
      postfix = 'and ';
    }

    return (
      <span key={field}>
        <span>&nbsp;{postfix}</span>
        <TextButton color="neutral-full-white" onClick={() => onClickVerifyField(field)}>
          {field}
        </TextButton>
      </span>
    );
  };
  return (
    <div
      className={classNames(
        styles.employeeVerify,
        styles[variant],
        styles[`${animate}BackgroundAnimation`],
      )}
    >
      <span className={classNames('container-xl', styles.text, styles[`${animate}TextAnimation`])}>
        <Icon className={styles.icon} icon={variant === 'toVerify' ? Info : Check} size={16} />
        {message}
        {verifyFields.map(buildVerifyFieldComponent)}
      </span>
    </div>
  );
};

export default EditEmployeeBanner;
