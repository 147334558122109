import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';

import ColorPill from 'js/design-system/ColorPill/ColorPill';
import Text from 'js/design-system/Text/Text';
import Tooltip from 'js/design-system/Tooltip/Tooltip';
import queries from 'js/queries';
import { getLevelText } from 'js/services/levelService';
import { Employee } from 'types';

import styles from './EmployeeSearchOption.module.scss';

interface EmployeeSearchOptionProps {
  employee: Partial<Employee>;
  tooltipContent?: string;
  disabled?: boolean;
}

export const EmployeeSearchOption = ({
  employee,
  tooltipContent,
  disabled,
}: EmployeeSearchOptionProps) => {
  const { data: locationsById = {} } = useQuery({
    ...queries.locations.list,
    select: (locations) => Object.fromEntries(locations.map((l) => [l.id, l])),
  });

  return (
    <Tooltip content={tooltipContent}>
      <div className={styles.label}>
        <Text
          variant="text-misc-input-bold"
          className={classNames({ [styles.disabled]: disabled })}
        >
          {employee.name}
        </Text>
        <div className={styles.employeeData}>
          <span className={styles.jobRole}>
            {employee.jobTitle || employee.jobRole?.name || '-'}
          </span>
          {employee.jobLevel ? (
            <ColorPill
              pillColor="gray"
              text={
                employee.jobRole?.jobType?.code !== 'EXEC'
                  ? getLevelText(employee.jobLevel.name)
                  : 'EXEC'
              }
              className={classNames({ [styles.disabled]: disabled })}
            />
          ) : (
            '-'
          )}
          <span className={styles.location}>
            {employee.locationName ||
              (employee.locationId && locationsById[employee.locationId]?.name) ||
              '-'}
          </span>
        </div>
      </div>
    </Tooltip>
  );
};
