export default {
  view: true,
  close: true,
  click: {
    payStrategySettings: 'Data Lookup Pay Strategy Settings Click',
    popularSearches: true,
    connectHRIS: true,
  },
  hit: {
    queryLimit: true,
  },
};
