import { createQueryKeys } from '@lukemorales/query-key-factory';

import { fetchBenchmarks } from 'js/api/benchmarks';
import { fetchEquityConnection } from 'js/api/companies';
import { fetchCompanyNotifications } from 'js/api/companyNotifications';
import { fetchCompanyUsers } from 'js/api/companyUsers';
import { fetchConnectedCompanies } from 'js/api/connectedCompanies';
import { fetchExternalSyncs } from 'js/api/externalSyncs';
import { fetchPayrollProviders } from 'js/api/payrollProviders';
import { checkPromoStatus } from 'js/api/promos';
import { fetchCompanySegmentGroups } from 'js/api/segmentGroups';
import { fetchStakeholderSecurities } from 'js/api/stakeholderSecurities';
import { fetchStakeholders } from 'js/api/stakeholders';

const companies = createQueryKeys('companies', {
  detail: (id: number) => ({
    queryKey: [id],
    contextQueries: {
      benchmarks: {
        queryKey: null,
        queryFn: () => fetchBenchmarks({ companyId: id }),
      },
      equityConnection: {
        queryKey: null,
        queryFn: () => fetchEquityConnection(id),
      },
      segmentGroups: {
        queryKey: null,
        queryFn: () => fetchCompanySegmentGroups(id),
      },
      connectedCompanies: {
        queryKey: null,
        queryFn: () => fetchConnectedCompanies(id),
      },
      lastCashConnectionSync: {
        queryKey: null,
        queryFn: async () => {
          const externalSyncs = await fetchExternalSyncs({
            companyId: id,
            filters: {
              integrationProxyName: ['finch', 'merge', 'gusto', 'rippling'],
              data_type: 'employees',
            },
            pagination: { order: 'started_at DESC', limit: 1 },
          });
          return externalSyncs?.[0] || null;
        },
      },
      lastEquityConnectionSync: {
        queryKey: null,
        queryFn: async () => {
          const externalSyncs = await fetchExternalSyncs({
            companyId: id,
            filters: {
              integrationProxyName: ['pulley', 'carta'],
              data_type: 'stakeholders',
            },
            pagination: { order: 'started_at DESC', limit: 1 },
          });
          return externalSyncs?.[0] || null;
        },
      },
      notifications: {
        queryKey: null,
        queryFn: () => fetchCompanyNotifications(id),
      },
      payrollProviders: (name: string) => ({
        queryKey: [name],
        queryFn: () => fetchPayrollProviders(id, name),
      }),
      promoStatus: (code: string) => ({
        queryKey: [code],
        queryFn: () => checkPromoStatus(code, id),
      }),
      stakeholders: {
        queryKey: null,
        queryFn: () => fetchStakeholders({ companyId: id }),
      },
      stakeholderSecurities: {
        queryKey: null,
        queryFn: () => fetchStakeholderSecurities({ companyId: id }),
      },
      users: {
        queryKey: null,
        queryFn: () => fetchCompanyUsers(id),
      },
    },
  }),
});

export default companies;
