import Confetti from 'react-confetti';

import { hideConfetti } from 'js/actions/confetti';
import useAppDispatch from 'js/hooks/useAppDispatch';
import useAppSelector from 'js/hooks/useAppSelector';
import useWindowSize from 'js/hooks/useWindowSize';
import { AppState } from 'types';

import styles from './ConfettiAnimation.module.scss';

interface ConfettiAnimationProps {
  repeat?: boolean;
  amount?: number;
  color?: string[];
}

const ConfettiAnimation = ({
  repeat = false,
  amount = 200,
  color = ['#E6F5F2', '#079D7D'],
}: ConfettiAnimationProps) => {
  const { width, height } = useWindowSize();
  const shown = useAppSelector((state: AppState) => state.confetti.shown);
  const dispatch = useAppDispatch();

  if (!shown) return null;

  return (
    <div className={styles.confetti}>
      <Confetti
        width={width}
        height={height}
        run
        recycle={repeat}
        numberOfPieces={amount} // number of pieces to show, 200 is default
        colors={color} // array of strings eg: ["#E6F5F2","#079D7D"]
        onConfettiComplete={() => {
          dispatch(hideConfetti());
        }}
      />
    </div>
  );
};

export default ConfettiAnimation;
