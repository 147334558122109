import { UserRole } from 'types';

export type RoleOption = {
  hint: string;
  label: string;
  shortLabel: string;
  value: UserRole;
  disabled?: boolean;
};

export const roles: {
  [key in UserRole]: RoleOption;
} = {
  [UserRole.Administrator]: {
    hint: 'User has access to the Admin Console and can add themselves to any scenario',
    label: 'Administrator',
    shortLabel: 'Admin',
    value: UserRole.Administrator,
  },
  [UserRole.OrganizationLeader]: {
    hint: 'User has access to all employees within selected scenarios',
    label: 'Organization Leader',
    shortLabel: 'Org Leader',
    value: UserRole.OrganizationLeader,
  },
  [UserRole.TeamLeader]: {
    hint: 'User has access to employees under them, only in selected scenarios',
    label: 'Team Leader',
    shortLabel: 'Team Leader',
    value: UserRole.TeamLeader,
  },
  [UserRole.Analyst]: {
    disabled: true,
    hint: '',
    label: 'Analyst',
    shortLabel: 'Analyst',
    value: UserRole.Analyst,
  },
  [UserRole.Approver]: {
    hint: 'User has access only to approvals that require their input',
    label: 'Approver',
    shortLabel: 'Approver',
    value: UserRole.Approver,
  },
  [UserRole.Recruiter]: {
    hint: 'User has access only to Intelligent Offers',
    label: 'Recruiter',
    shortLabel: 'Recruiter',
    value: UserRole.Recruiter,
  },
  [UserRole.RewardsViewer]: {
    hint: 'User has access only to their own total rewards statement.',
    label: 'Rewards Viewer',
    shortLabel: 'Rewards Viewer',
    value: UserRole.RewardsViewer,
  },
  [UserRole.Pending]: {
    hint: 'User access is pending',
    label: 'Pending',
    shortLabel: 'Pending',
    value: UserRole.Pending,
  },
};
