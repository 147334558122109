import { AnyAction } from 'redux';

import { defaultElementSize } from 'js/hooks/useElementSize';
import { ElementSize } from 'types';

import ACTIONS from '../constants/actions';

type ElementSizeProps = {
  tabName?: string;
  size: ElementSize;
};

export interface ElementSizeState {
  stickyHeader: ElementSizeProps;
  groupHeader: ElementSizeProps;
  navbar: ElementSizeProps;
  banners: ElementSizeProps;
}

export const initialState: ElementSizeState = {
  stickyHeader: {
    size: defaultElementSize,
  },
  groupHeader: {
    size: defaultElementSize,
  },
  navbar: {
    tabName: '',
    size: defaultElementSize,
  },
  banners: {
    size: defaultElementSize,
  },
};

export default (
  state: ElementSizeState = initialState,
  action: AnyAction = { type: null },
): ElementSizeState => {
  switch (action.type) {
    case ACTIONS.SET_STICKY_HEADER_SIZE:
      return { ...state, stickyHeader: { size: action.size } };
    case ACTIONS.SET_GROUP_HEADER_SIZE:
      return { ...state, groupHeader: { size: action.size } };
    case ACTIONS.SET_NAVBAR_SIZE:
      return { ...state, navbar: { ...state.navbar, size: action.size } };
    case ACTIONS.SET_NAVBAR_SELECTION:
      return { ...state, navbar: { ...state.navbar, tabName: action.tabName } };
    case ACTIONS.SET_BANNERS_SIZE:
      return { ...state, banners: { ...state.banners, size: action.size } };
    default:
      return state;
  }
};
