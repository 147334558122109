import { Info, Lightning, SpinnerGap } from '@phosphor-icons/react';
import classNames from 'classnames';
import { ReactNode } from 'react';

import Icon from 'js/design-system/Icon';

import styles from './SyncBanner.module.scss';

export type BannerVariant = 'in_progress' | 'failed' | 'success' | 'idle';

interface SyncBannerProps {
  text: ReactNode;
  onCtaClick: () => void;
  cta: ReactNode;
  variant: BannerVariant;
}
const SyncBanner = ({ text, onCtaClick, cta, variant }: SyncBannerProps) => {
  if (variant === 'idle') {
    return null;
  }

  const iconSelector = (variant: BannerVariant) => {
    if (variant === 'success') {
      return Lightning;
    }
    if (variant === 'failed') {
      return Info;
    }
    return SpinnerGap;
  };

  return (
    <div className={classNames(styles.syncBannerBackground, styles[variant])}>
      <div className="container-xl">
        <div className={styles.syncBannerWrap}>
          <div className={styles.columnOne}>
            <Icon
              className={classNames(styles.icon, styles[`${variant}Animation`])}
              icon={iconSelector(variant)}
              weight={variant === 'success' ? 'fill' : undefined}
              size={variant === 'success' ? 16 : undefined}
            />
            <div className={styles.text}>{text}</div>
          </div>
          <div className={styles.columnTwo}>
            <button className={styles.cta} onClick={onCtaClick} type="button">
              {cta}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SyncBanner;
