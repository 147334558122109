import { formatError } from 'js/components-legacy/common/AlertMessage';
import { ScenarioSnapshot } from 'types';

import api from './api';

export const fetchScenarioSnapshot = async (id: number) => {
  const { success, data, error } = await api.get<ScenarioSnapshot>(`/scenario_snapshots/${id}`);

  if (success) return data;

  throw new Error(`Failed to fetch scenario snapshot. ${formatError(error)}`);
};
