import { createStore, applyMiddleware, compose, StoreEnhancer } from 'redux';
import thunk from 'redux-thunk';

import reducer from './reducers/reducer';
import { HSConversations, HSEventsLastActivity } from './services/chatService';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    _hsq?: unknown[];
    _hsEventsLastActivity: HSEventsLastActivity;
    HubSpotConversations?: HSConversations;
    _hsActivePlaybook?: string;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// TODO need to check the returned type of this
const storeEnhancer = (): StoreEnhancer => {
  if (process.env.NODE_ENV === 'development') {
    return compose(applyMiddleware(thunk), composeEnhancers());
  }
  return compose(applyMiddleware(thunk));
};

export const setupStore = () => createStore(reducer, storeEnhancer());

export default setupStore();
