import adpLogo from 'img/integrations/adp-logo.svg';
import bambooLogo from 'img/integrations/bamboo-logo.svg';
import gustoLogo from 'img/integrations/gusto-logo.svg';
import ripplingLogo from 'img/integrations/rippling-logo.svg';
import zenefitsLogo from 'img/integrations/zenefits-logo.svg';

const hrisProviderNameMapping: any = {
  gusto: {
    name: 'Gusto',
    icon: gustoLogo,
  },
  bamboo_hr: {
    name: 'Bamboo',
    icon: bambooLogo,
  },
  rippling: {
    name: 'Rippling',
    icon: ripplingLogo,
  },
  zenefits: {
    name: 'Zenefits',
    icon: zenefitsLogo,
  },
  adp_run: {
    name: 'ADP',
    icon: adpLogo,
  },
};

export default hrisProviderNameMapping;
