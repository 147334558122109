import { WarningCircle } from '@phosphor-icons/react';

import Icon from 'js/design-system/Icon';
import Text from 'js/design-system/Text/Text';
import TextButton from 'js/design-system/TextButton/TextButton';
import { roles } from 'js/services/permissionService';
import { UserRole } from 'types';

import styles from './RoleHint.module.scss';

interface RoleHintProps {
  role?: UserRole;
  isUserFound?: boolean;
  onHide?: () => void;
}

const RoleHint = ({ role, isUserFound = false, onHide = () => {} }: RoleHintProps) => {
  const renderPermissionLink = () => <TextButton onClick={onHide}>User Permissions</TextButton>;

  return role ? (
    <div className={styles.hint}>
      <Icon icon={WarningCircle} size={20} />
      <Text variant="text-misc-caption">
        <span>{isUserFound ? 'User found' : roles[role].label}</span>
        {isUserFound ? <span>.&nbsp;</span> : <span>&nbsp;-&nbsp;</span>}
        {isUserFound ? (
          <>
            <span>Go to </span>
            {renderPermissionLink()}
            <span> to update this user</span>
          </>
        ) : (
          <span>{roles[role].hint}</span>
        )}
      </Text>
    </div>
  ) : null;
};

export default RoleHint;
