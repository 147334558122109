import { Image as ImageIcon, UserCircle, Camera } from '@phosphor-icons/react';
import { memo } from 'react';

import Icon from 'js/design-system/Icon';

import { ImageType } from './types';

import styles from './ImagePickerPlaceholder.module.scss';

interface ImagePickerPlaceholderProps {
  imageType?: ImageType;
  width?: number;
  height?: number;
}

const ImagePickerPlaceholder = ({
  imageType = ImageType.Default,
  width = 256,
  height = 150,
}: ImagePickerPlaceholderProps) => {
  if (imageType === ImageType.Default) {
    return (
      <div style={{ width, height }} className={styles.defaultPlaceholder}>
        <Icon icon={Camera} size={24} color="platform-gray-500" />
      </div>
    );
  }

  return (
    <Icon
      icon={imageType === ImageType.Avatar ? UserCircle : ImageIcon}
      size={imageType === ImageType.Avatar ? 56 : 72}
      color="primary-gray-500"
    />
  );
};

export default memo(ImagePickerPlaceholder);
