import classNames from 'classnames';
import { ForwardedRef, forwardRef } from 'react';
import BoostrapModal from 'react-bootstrap/Modal';

import { ModalProps } from './types';

import styles from './Modal.module.scss';

const Modal = forwardRef(
  (
    {
      header,
      footer,
      show = false,
      onHide,
      children,
      wrapperClassName,
      headerClassName,
      footerClassName,
      contentClassName,
      ...rest
    }: ModalProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => (
    <BoostrapModal
      contentClassName={classNames(styles.content, wrapperClassName)}
      show={show}
      onHide={onHide}
      centered
      onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}
      {...rest}
    >
      {header && <header className={classNames(styles.header, headerClassName)}>{header}</header>}

      <div className={contentClassName} ref={ref}>
        {children}
      </div>

      {footer && <footer className={classNames(styles.footer, footerClassName)}>{footer}</footer>}
    </BoostrapModal>
  ),
);

Modal.displayName = 'Modal';

export default Modal;
