import { createQueryKeys } from '@lukemorales/query-key-factory';
import { QueryFunction, QueryKey, UseQueryOptions, useQuery } from '@tanstack/react-query';

import { fetchBenchmarkQueryCredits } from 'js/api/benchmarkQueryCredits';
import { BenchmarkQueryCredit } from 'types';

export const benchmarkQueryCreditsQueryKeys = createQueryKeys('benchmarkQueryCredits', {
  list: (companyId: number) => ({
    queryKey: [companyId],
    queryFn: () => fetchBenchmarkQueryCredits(companyId),
  }),
});

export const useBenchmarkQueryCredits = (
  companyId: number,
  options?: UseQueryOptions<BenchmarkQueryCredit[]>,
) => {
  return useQuery<BenchmarkQueryCredit[]>(
    benchmarkQueryCreditsQueryKeys.list(companyId).queryKey,
    benchmarkQueryCreditsQueryKeys.list(companyId).queryFn as QueryFunction<
      BenchmarkQueryCredit[],
      QueryKey
    >,
    options,
  );
};
