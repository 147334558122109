import { UserPermission, Scenario, EmployeeUser, Employee, UserRole } from 'types';

export enum RevokeStatus {
  Idle = 'idle',
  Succeeded = 'succeeded',
}

export enum InviteStatus {
  Idle = 'idle',
  Succeeded = 'succeeded',
}

export type InviteResponse = {
  user: UserPermission;
  companyId: number;
  scenarioIds: number[];
  message: string;
};

export type RevokeResponse = {
  user: UserPermission;
  companyId: number;
};

export type InviteCompanyUserFormData = {
  scenarios: InvitedCompanyScenario[];
  email: string;
  role: UserRole;
  employeesUser?: Partial<EmployeeUser>;
  employeeSearch?: string;
};

export type InvitedCompanyScenario = {
  id: number;
  hasPermission?: boolean;
  employeesUser?: Partial<EmployeeUser>;
  employees?: Employee[];
} & Scenario;

export type InviteScenarioUserFormData = {
  scenarioId: number;
  employeeId?: number;
  employeesUser?: EmployeeUser;
  email: string;
  role: UserRole;
  employeeSearch?: string;
  disabledRoles?: UserRole[];
};
