import { ReactNode, useMemo, useState } from 'react';

import NavigatorContextProvider from 'js/components/common/confirm-navigator/NavigatorContextProvider';

import PayStrategyContext from './PayStrategyContext';

interface PayStrategyContextProviderProps {
  children: ReactNode;
}

const PayStrategyContextProvider = ({ children }: PayStrategyContextProviderProps) => {
  const [rangeDirty, setRangeDirty] = useState(false);
  const [rangeRecalculated, setRangeRecalculated] = useState(false);
  const [rangeEditable, setRangeEditable] = useState(true);

  const contextValue = useMemo(
    () => ({
      rangeDirty,
      setRangeDirty,
      rangeRecalculated,
      setRangeRecalculated,
      rangeEditable,
      setRangeEditable,
    }),
    [rangeDirty, rangeRecalculated, rangeEditable],
  );

  return (
    <NavigatorContextProvider>
      <PayStrategyContext.Provider value={contextValue}>{children}</PayStrategyContext.Provider>
    </NavigatorContextProvider>
  );
};

export default PayStrategyContextProvider;
