import classNames from 'classnames';
import { useContext, useRef } from 'react';

import Text from 'js/design-system/Text/Text';
import buttonize from 'js/utils/buttonize';

import { TabsContext } from './context';
import { TabProps, TabTextProps } from './types';

import styles from './Tab.module.scss';

const Tab = <T,>({ tab }: TabProps<T>) => {
  const { activeTabKey, setActiveTabKey } = useContext(TabsContext);
  const ref = useRef<HTMLDivElement>(null);
  const active = activeTabKey === tab.key;
  const textProps: Required<
    Pick<TabTextProps, 'color' | 'activeColor' | 'variant' | 'activeVariant'>
  > = {
    color: 'platform-gray-700',
    activeColor: 'oc-primary',
    variant: 'text-misc-nav-tabs',
    activeVariant: 'text-misc-nav-tabs',
    ...tab.textProps,
  };

  const onClick = () => {
    setActiveTabKey(tab.key);
  };

  return (
    <div
      {...buttonize(onClick)}
      ref={ref}
      className={classNames(styles.tab, tab.className, { [styles.active]: active })}
    >
      <Text
        variant={active ? textProps.activeVariant : textProps.variant}
        color={active ? textProps.activeColor : textProps.color}
      >
        {tab.name}
      </Text>
    </div>
  );
};

export default Tab;
