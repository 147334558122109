import { useNavigate } from 'react-router-dom';

import Button from 'js/design-system/Button/Button';
import { ButtonSize, ButtonVariant } from 'js/design-system/Button/types';
import { changePassword } from 'js/services/auth/actions';

import { Form, PasswordHint, PasswordInput } from '../../components-legacy/form';
import { passwordValidation, required } from '../../components-legacy/form/validations';

const ChangePassword = () => {
  const navigate = useNavigate();

  const handleSubmit = async (values: { oldPassword: string; newPassword: string }) => {
    const { oldPassword, newPassword } = values;
    const success = await changePassword(oldPassword, newPassword);
    if (success) {
      navigate('/');
    }
  };

  const renderActions = (disabled: boolean) => (
    <Button
      type="submit"
      variant={ButtonVariant.Filled}
      size={ButtonSize.Medium}
      className="w-100"
      disabled={disabled}
    >
      Change Password
    </Button>
  );

  return (
    <Form onSubmit={handleSubmit} renderActions={renderActions}>
      <PasswordInput
        label="Old Password"
        name="oldPassword"
        validate={(value) => required('Please provide your current password.', value)}
      />
      <PasswordInput
        label="New Password"
        name="newPassword"
        validate={passwordValidation}
        renderHint={(meta) => <PasswordHint meta={meta} />}
      />
    </Form>
  );
};

export default ChangePassword;
