import classNames from 'classnames';
import { CSSProperties } from 'react';

import styles from './LoadingPlaceholder.module.scss';

const LoadingPlaceholder = ({
  className,
  style,
}: {
  className?: string;
  style?: CSSProperties;
}) => (
  <div className={classNames(styles.placeholder, className)} style={style}>
    text
  </div>
);

export default LoadingPlaceholder;
