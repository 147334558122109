import { Auth } from '@aws-amplify/auth';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { trackEvent } from 'js/analytics';
import { OAuthSignInEventDetails, OAuthUserSignUpEventDetails } from 'js/analytics/types/oauth';
import useTrackEvent from 'js/analytics/useTrackEvent';
import { retrieveOauthSignupQueryParams } from 'js/components/auth/GoogleOAuth';
import { clearSignUpContext, completeSignup, getSignUpContext } from 'js/services/auth/actions';
import { storePromoUtmParams } from 'js/services/promoUtmService';
import { logInfo } from 'js/utils/logger';
import { CognitoUserSession } from 'types';

const createUserIfOauthSignup = async (currentSession: CognitoUserSession) => {
  const { idToken } = currentSession;
  if (!idToken) throw Error('idToken missing');
  const { email } = idToken.payload;
  if (!email) throw Error('User email missing');

  const oauthSignupQueryParams = retrieveOauthSignupQueryParams();
  const urlSearchParams = new URLSearchParams(oauthSignupQueryParams || '');
  storePromoUtmParams(urlSearchParams);
  const hrisProvider = urlSearchParams.get('hris_provider');

  // idempotent endpoint safe to call more than once
  // as it is difficult to differentiate between oauth login and oauth signup
  logInfo(
    `Completing oauth signup with hris provider: ${hrisProvider} and query params ${oauthSignupQueryParams}`,
  );
  const { data, status } = await completeSignup(email, hrisProvider);

  if (status === 200) {
    logInfo('Existing user sign in');
  } else if (status === 201) {
    logInfo('New user signup');
    const eventDetails: OAuthUserSignUpEventDetails = data
      ? { companyId: data.landingCompanyId, scenarioId: data.landingScenarioId }
      : { skipCompany: true };
    const context = getSignUpContext();
    trackEvent<OAuthUserSignUpEventDetails>('user.action.signUp', { ...context, ...eventDetails });
    clearSignUpContext();
  }
  logInfo('Oauth signup complete');
  return data;
};

/**
 * This is the route that Cognito will redirect to after an oauth authentication (eg Google, Rippling, etc)
 */
export const OauthSignInCallback: React.FC = () => {
  logInfo('Oauth Sign In');
  const navigate = useNavigate();
  const { trackEvent } = useTrackEvent<OAuthSignInEventDetails>();

  React.useEffect(() => {
    trackEvent('oauth.signin');
    Auth.currentSession()
      .then((session) => createUserIfOauthSignup(session as CognitoUserSession))
      .then((user) => {
        if (!user) {
          throw new Error(`User is undefined`);
        } else if (user.isPending) {
          navigate('/pending');
        } else {
          navigate('/?completeSignUp=true');
        }
      });
  });

  return null;
};
