import { Role, User } from 'types';

import api from './api';

export const fetchCurrentUser = async () => {
  const { success, data, error } = await api.get<User>('/current_user');

  if (success) {
    return data;
  }

  throw new Error(`Failed to fetch current user: ${error}`);
};

export const fetchCurrentUserRole = async (companyId: number) => {
  const { success, data, error } = await api.get<Role>(
    `/current_user/role?company_id=${companyId}`,
  );

  if (success) {
    return data;
  }

  throw new Error(`Failed to fetch current user role: ${error}`);
};
