import { AnyAction } from 'redux';

import { SNACKBAR_LIMIT } from 'js/design-system/Snackbar/constants';
import { SnackbarProps } from 'js/design-system/Snackbar/types';

import ACTIONS from '../constants/actions';

interface SnackbarState {
  snackbarList: SnackbarProps[];
}

const initialState: SnackbarState = {
  snackbarList: [],
};

export default (state = initialState, action: AnyAction = { type: null }): SnackbarState => {
  switch (action.type) {
    case ACTIONS.ADD_SNACKBAR:
      return {
        ...state,
        snackbarList: [...state.snackbarList, action.snackbar].slice(-SNACKBAR_LIMIT),
      };

    case ACTIONS.REMOVE_SNACKBAR:
      return {
        ...state,
        snackbarList: state.snackbarList.filter((s) => s.id !== action.snackbarId),
      };
    default:
      return state;
  }
};
