import {
  Employee,
  EmployeeAdjustmentProposalWithAdjustments,
  EmployeeAdjustmentProposalStatus,
  UserPermission,
  QuantityUnit,
  Approval,
  GenericApprovalFormData,
  NestedFilterData,
  LetterOptions,
} from 'types';

export type LetterTemplate = {
  id?: number;
  publishLetterAt?: string;
  publishedAt?: string;
  options: LetterOptions;
};

export type CompCycle = {
  id: number;
  scenarioId: number;
  scenarioSnapshotId: number | null;
  name: string;
  compCycleType: CompCycleType;
  startDate: string | null;
  endDate: string | null;
  effectiveDate: string | null;
  salaryBudget: number;
  equityBudget: number;
  instructions: string | null;
  status: CompCycleStatus;
  archived: boolean;
  createdBy: Pick<UserPermission, 'avatar' | 'fullName' | 'id' | 'email'>;
  budgetAllocations: BudgetAllocation[];
  baseApproval: Approval | null;
  settings: CompCycleSettings;
  eligibility: Eligibility;
  performanceScaleSteps: PerformanceScaleStep[];
  distributionPercentages: DistributionPercentage[];
  letterTemplate: LetterTemplate | null;
  letterDelivered: boolean;
};

export type SetCompCycleApproversFormData = {
  approval: GenericApprovalFormData;
};

export type SetCompCycleBaseApprovalsFormData = {
  approvals: {
    compCycleBaseApproval?: GenericApprovalFormData;
    budgetAllocationBaseApprovals: BudgetAllocationBaseApprovals;
  };
  settings?: Pick<CompCycleSettings, 'approverOverrideDisabled'>;
};

export type BudgetAllocationBaseApprovals = Array<{
  ownerEmail: string;
  baseApproval: GenericApprovalFormData | null;
}>;

export type OwnerBaseApproval = {
  owner: BudgetAllocation['owner'] | BudgetAllocationFormData['owner'];
  baseApproval?: Approval | null;
};

export enum CompCycleType {
  Bonus = 'bonus',
  Merit = 'merit',
}

export const cycleTypeShortNames = {
  [CompCycleType.Bonus]: 'Bonus',
  [CompCycleType.Merit]: 'Merit',
};

export const cycleTypeNames = (Object.keys(cycleTypeShortNames) as CompCycleType[]).reduce(
  (names, compCycleType) => {
    return {
      ...names,
      [compCycleType]: `${cycleTypeShortNames[compCycleType]} Cycle`,
    };
  },
  {} as typeof cycleTypeShortNames,
);

export enum CompCycleStatus {
  Draft = 'draft',
  Upcoming = 'upcoming',
  Open = 'open',
  Finalized = 'finalized',
  Closed = 'closed',
}

export type CompCycleSettings = {
  autoDistributeBudgetEnabled?: boolean;
  performanceScoreDisabled?: boolean;
  positionInRangeDisabled?: boolean;
  jobChangeDisabled?: boolean;
  targetAnnualBonusIncreaseDisabled?: boolean;
  overBudgetDisabled?: boolean;
  deiDisabled?: boolean;
  compensationSalaryDisabled?: boolean;
  compensationTargetCommissionDisabled?: boolean;
  compensationEquityDisabled?: boolean;
  compensationCashDisabled?: boolean;
  minimumDefaultPercentage?: number;
  maximumDefaultPercentage?: number;
  approverOverrideDisabled?: boolean;
};

export type Eligibility = {
  tenureLimitType?: TenureLimitType;
  tenureTimeScope?: TenureTimeScope;
  tenureDate?: string | null;
  departmentIds?: number[] | null;
  managerIds?: number[] | null;
  rangeQuartiles?: RangeQuartile[] | null;
  jobRoleIds?: number[] | null;
  performanceScore?: number[] | null;
  jobTypeIds?: number[] | null;
};

export enum CycleStatusTab {
  Current = 'current',
  Previous = 'previous',
  Drafts = 'drafts',
  Archived = 'archived',
}

export enum CycleSummaryTab {
  Details = 'details',
  ExecutiveSummary = 'executive_summary',
}

export type CycleFilter = {
  status: string[];
  createdBy: number[];
};

export type BudgetAllocation = {
  id: number;
  baseApproval?: Approval | null;
  owner: BudgetAllocationOwner;
  salaryBudget: number;
  salaryBudgetPercent: number | null;
  equityBudget: number;
  equityBudgetPercent: number | null;
  recipients: Employee[];
  proposals: EmployeeAdjustmentProposalWithAdjustments[];
};

export type BudgetAllocationOwner = {
  id: number;
  type: string;
  email: string;
  firstName: string;
  fullName: string;
  jobTitle: string | null;
  avatar: string | null;
};

export type CompCycleTableFieldProps = {
  budgetAllocation: BudgetAllocation;
};

export type CompCycleSubmitData = Partial<
  Pick<
    CompCycle,
    | 'name'
    | 'compCycleType'
    | 'startDate'
    | 'endDate'
    | 'effectiveDate'
    | 'salaryBudget'
    | 'equityBudget'
    | 'instructions'
    | 'status'
    | 'settings'
    | 'eligibility'
  >
> &
  Pick<CompCycle, 'scenarioId'> & {
    budgetAllocationsAttributes: BudgetAllocationAttribute[];
    performanceScaleStepsAttributes?: PerformanceScaleStep[];
    distributionPercentagesAttributes?: DistributionPercentage[];
  };

export type CycleTableFieldProps = {
  cycle: CompCycle;
};

type BudgetAllocationAttribute = {
  _destroy?: boolean;
  id?: number;
  email: string;
  equityBudget?: number;
  equityBudgetPercent?: number;
  salaryBudget?: number;
  salaryBudgetPercent?: number;
  recipientIds: number[];
};

export type CompCycleFormData = Omit<CompCycleSubmitData, 'budgetAllocationsAttributes'> & {
  id?: number;
  budgetAllocations: BudgetAllocationFormData[];
  ownerEmailToRecipients: OwnerEmailToRecipients;
  performanceByRange?: number[][];
  recipientIdToOwnerEmail: RecipientIdToOwnerEmail;
};

export type BudgetAllocationFormData = {
  owner: UserPermission;
  equityBudget?: number;
  equityBudgetPercent?: number;
  equityBudgetUnit: QuantityUnit;
  salaryBudget?: number;
  salaryBudgetPercent?: number;
  salaryBudgetUnit: QuantityUnit;
};

export type ArchiveCompCycleFormData = {
  archived: boolean;
};

export type OrgNode = {
  reportee?: OrgNode;
  directReporters: OrgNode[];
  employee: Employee;
  user?: UserPermission;
};

export type OrgTree = Record<number, OrgNode>;

export type OwnerEmailToRecipients = Record<string, Employee[] | undefined>;
export type RecipientIdToOwnerEmail = Record<number, string | undefined>;

export type CompCycleSummaryFilter = {
  status: EmployeeAdjustmentProposalStatus[];
};

export enum CompCycleSummarySortBy {
  BudgetHolderName = 'budget_holder_name',
  Status = 'status',
  NumberOfEmployees = 'number_of_employees',
  CashBudget = 'cash_budget',
  EquityBudget = 'equity_budget',
  DateSubmitted = 'date_submitted',
}

export enum CycleSortBy {
  CycleName = 'cycleName',
  Status = 'status',
  CreatedBy = 'createdBy',
  EndDate = 'endDate',
  CashBudget = 'cashBudget',
  EquityBudget = 'equityBudget',
}

export enum EmployeeAdjustmentsProposalSortBy {
  Level = 'job_level',
  Tenure = 'tenure',
  BaseSalary = 'base_salary',
  BaseSalaryIncrease = 'base_salary_increase',
  TotalCash = 'total_cash',
  CashIncrease = 'cash_increase',
  CashRangePosition = 'cash_range_position',
  TotalEquity = 'total_equity',
  EquityIncrease = 'equity_increase',
  EquityRangePosition = 'equity_range_position',
  CashBonus = 'cash_bonus',
  TargetAnnualBonus = 'target_annual_bonus',
  EquityRefresh = 'equity_refresh',
}

export type EmployeeAdjustmentsProposalFilter = {
  levelJobTypes: NestedFilterData | null;
  roles: number[];
  jobChange?: string;
  outOfRange?: string;
};

export enum TenureLimitType {
  Include = 'include',
  Exclude = 'exclude',
}

export enum TenureTimeScope {
  Before = 'before',
  After = 'after',
}

export enum RangeQuartile {
  Below = -25,
  First = 0,
  Second = 25,
  Third = 50,
  Fourth = 75,
  Above = 100,
}

export type BudgetType = {
  cash: {
    allocated: number;
    totalBudget: number;
  };
  equity: {
    allocated: number;
    totalBudget: number;
  };
};

export type DEIAverage = {
  genderName: string;
  cashPercentage: number;
  cash: number | null;
  equityPercentage: number;
  equity: number | null;
};

export enum BaseApprovalFlowStaticOptionValue {
  Default = -1,
  Custom = -2,
  CreateTemplate = -3,
}

type PerformanceScaleStep = {
  position: number;
  label: string;
};

export type DistributionPercentage = {
  id?: number;
  performanceScaleStepPosition?: number;
  all?: number | null;
  belowMin?: number | null;
  q1?: number | null;
  q2?: number | null;
  q3?: number | null;
  q4?: number | null;
  aboveMax?: number | null;
};
