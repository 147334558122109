import { Outlet } from 'react-router-dom';

import useAppSelector from 'js/hooks/useAppSelector';
import useNumberParams from 'js/hooks/useNumberParams';
import { currentUserRoleSelector } from 'js/selectors/currentUser';
import { UserRole } from 'types';

import MainScenarioTab from './MainScenarioTab';

interface ScenarioTabProps {
  showBanner?: boolean;
}

const ScenarioTab = ({ showBanner = true }: ScenarioTabProps) => {
  const { scenarioId } = useNumberParams();
  const currentUserRole = useAppSelector((state) =>
    currentUserRoleSelector(state, undefined, scenarioId),
  );

  switch (currentUserRole) {
    case UserRole.RewardsViewer:
    case null:
    case undefined:
      return <Outlet />;
    default:
      return <MainScenarioTab showBanner={showBanner} />;
  }
};

export default ScenarioTab;
