import { memo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import BackButton from 'js/components/common/BackButton';
import { ButtonVariant } from 'js/design-system/Button/types';
import useAppSelector from 'js/hooks/useAppSelector';
import useNumberParams from 'js/hooks/useNumberParams';
import { getById as companySelector } from 'js/selectors/companies';

import styles from './BackToScenarioButton.module.scss';

interface BackToScenarioButtonProps {
  onClick?: () => void;
}

const BackToScenarioButton = ({ onClick }: BackToScenarioButtonProps) => {
  const { scenarioId, companyId } = useNumberParams();
  const company = useAppSelector((state) => companySelector(state, companyId));
  const [resolvedScenarioId, setResolvedScenarioId] = useState<number>(0);
  const navigate = useNavigate();

  useEffect(() => {
    setResolvedScenarioId(scenarioId || company?.landingScenarioId || 0);
  }, [scenarioId, company]);

  const handleClick = () => {
    onClick?.();
    navigate(`/scenarios/${resolvedScenarioId}`);
  };

  return (
    <BackButton
      variant={ButtonVariant.Ghost}
      label="Back to scenario"
      className={styles.backBtn}
      onClick={handleClick}
    />
  );
};

export default memo(BackToScenarioButton);
