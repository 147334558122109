import { AppDispatch, GetState, ScenarioViewMode } from 'types';

import ACTIONS from '../constants/actions';

export const setViewMode = (viewMode: ScenarioViewMode) => (dispatch: AppDispatch) => {
  dispatch({ type: ACTIONS.SET_CURRENT_SCENARIO_VIEW_MODE, viewMode });
};

export const setIntialDepartmentFilters = () => (dispatch: AppDispatch, getState: GetState) => {
  const departments = getState().departments.allIds;
  const data = {
    marketDepartmentFilter: departments,
    rangesDepartmentFilter: departments,
    diversityDepartmentFilter: departments,
  };
  dispatch({ type: ACTIONS.SET_INITIAL_DEPARTMENT_FILTERS, data });
};

export const setNewDepartmentFilters = () => (dispatch: AppDispatch, getState: GetState) => {
  const departments = getState().departments.allIds;
  const { employeeFilters } = getState().scenarioView;
  const marketDepartmentFilter = employeeFilters.market.department;
  const rangesDepartmentFilter = employeeFilters.ranges.department;
  const diversityDepartmentFilter = employeeFilters.diversity.department;

  const checkLength = (filters: number[]) => filters.length === departments.length - 1;

  const data = {
    marketDepartmentFilter: checkLength(marketDepartmentFilter)
      ? departments
      : marketDepartmentFilter,
    rangesDepartmentFilter: checkLength(rangesDepartmentFilter)
      ? departments
      : rangesDepartmentFilter,
    diversityDepartmentFilter: checkLength(diversityDepartmentFilter)
      ? departments
      : diversityDepartmentFilter,
  };
  dispatch({ type: ACTIONS.SET_INITIAL_DEPARTMENT_FILTERS, data });
};
