import Form from 'react-bootstrap/Form';

import { FormFieldProps } from 'types';

import Field from './Field';

const Input = ({
  placeholder,
  size,
  disabled = false,
  type = 'text',
  ...props
}: FormFieldProps<string>) => (
  <Field {...props} type={type}>
    {({ invalid, onChange, ...input }) => (
      <Form.Control
        {...input}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
        disabled={disabled}
        isInvalid={invalid}
        placeholder={placeholder}
        type={type}
        size={size}
        onClick={(e: React.MouseEvent<HTMLInputElement>) => e.stopPropagation()}
      />
    )}
  </Field>
);

export default Input;
