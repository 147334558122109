import { CaretLeft } from '@phosphor-icons/react';
import { useCallback, MouseEventHandler, FC } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from 'js/design-system/Button/Button';
import { ButtonSize, ButtonVariant } from 'js/design-system/Button/types';

interface BackButtonProps {
  label?: string;
  className?: string;
  onClick?: MouseEventHandler<HTMLElement>;
  variant?: ButtonVariant;
}

const BackButton: FC<BackButtonProps> = ({
  label = 'Back',
  className,
  onClick,
  variant = ButtonVariant.Ghost,
}) => {
  const navigate = useNavigate();

  const defaultClickHandler = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <Button
      onClick={onClick || defaultClickHandler}
      variant={variant}
      size={ButtonSize.Medium}
      leftIcon={CaretLeft}
      className={className}
    >
      <span className="d-none d-md-block">{label}</span>
    </Button>
  );
};

export default BackButton;
