import { parse } from 'qs';
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { alertError } from 'js/components-legacy/common/AlertMessage';
import { useCreateEquityConnection } from 'js/mutations';

const PulleyOauthCallback = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = parse(location.search.substring(1));

  const stateString = query.state?.toString() as string;
  const state = JSON.parse(atob(stateString));
  const { companyId } = state;

  const createMutation = useCreateEquityConnection(companyId);

  useEffect(() => {
    if (companyId && query.code) {
      createMutation.mutateAsync({ code: query.code as string, provider: state.provider });
      navigate(`/companies/${state.companyId}/equity`);
    } else {
      alertError('Error syncing equity provider.');
    }
  }, [companyId, createMutation, query, state, navigate]);
};

export default PulleyOauthCallback;
