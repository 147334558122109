import colors from 'css/_colors.scss';
import { ElementType } from 'react';

import Icon from 'js/design-system/Icon';

import styles from './ComplexAlert.module.scss';

interface ComplexAlertIconProps {
  icon: ElementType;
  iconColor: string;
  ellipseColor: string;
}

const ComplexAlertIcon = ({ icon, iconColor, ellipseColor }: ComplexAlertIconProps) => (
  <div
    className={styles.iconContainer}
    style={{ color: colors[iconColor], backgroundColor: colors[ellipseColor] }}
  >
    <Icon icon={icon} size={16} />
  </div>
);

export default ComplexAlertIcon;
