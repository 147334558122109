import {
  useFloating,
  useClick,
  useDismiss,
  useRole,
  useInteractions,
  FloatingOverlay,
  FloatingPortal,
} from '@floating-ui/react';
import { useState, useEffect, ReactNode } from 'react';

import styles from './Modal.module.scss';

export interface ModalProps {
  open?: boolean;
  onHide?: () => void;
  children: ReactNode;
  /** Unique ID for the heading element of the dialog,
   * so that the content of the dialog is announced by screen readers with wide compatibility.
   */
  labelId?: string;
  /** Unique ID for the description element of the dialog,
   * so that the content of the dialog is announced by screen readers with wide compatibility.
   */
  descriptionId?: string;
}

export const Modal = ({ open, onHide, children, labelId, descriptionId }: ModalProps) => {
  const [isOpen, setIsOpen] = useState(open);

  const { refs, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
  });

  const click = useClick(context);

  const dismiss = useDismiss(context, {
    outsidePressEvent: 'mousedown',
  });

  const role = useRole(context);

  const { getFloatingProps } = useInteractions([click, dismiss, role]);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  useEffect(() => {
    if (!isOpen && onHide) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <FloatingPortal>
          <FloatingOverlay lockScroll className={styles.backdrop}>
            <div
              ref={refs.setFloating}
              className={styles.modal}
              aria-labelledby={labelId}
              aria-describedby={descriptionId}
              {...getFloatingProps()}
            >
              {children}
            </div>
          </FloatingOverlay>
        </FloatingPortal>
      )}
    </>
  );
};
