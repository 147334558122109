import { Auth, CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import React from 'react';

import { trackEvent } from 'js/analytics';
import Button from 'js/design-system/Button/Button';
import { ButtonSize, ButtonVariant } from 'js/design-system/Button/types';
import { CustomIcon } from 'js/design-system/Icon';
import { redirToGoogleOAuthEmbedded, setSignUpContext } from 'js/services/auth/actions';

// We want to store and retrieve any query params that existed on the URL
// prior to redirecting to Google.
// Otherwise we lose HRIS Provider and UTM tracking on oauth signup
const OAUTH_SIGNUP_QUERY_PARAMS = 'OAUTH_SIGNUP_QUERY_PARAMS';
export const retrieveOauthSignupQueryParams = () =>
  sessionStorage.getItem(OAUTH_SIGNUP_QUERY_PARAMS);

const storeOauthSignupQueryParams = () => {
  if (!retrieveOauthSignupQueryParams()) {
    sessionStorage.setItem(OAUTH_SIGNUP_QUERY_PARAMS, window.location.search);
  }
};

/**
 * linking native cognito and oauth accounts causes a known error
 * "Error handling auth response. Error: Already+found+an+entry+for+username+..."
 * calling federatedSignIn a second time fixes the issue
 * see:
 * - https://github.com/aws-amplify/amplify-flutter/issues/1716
 * - https://bobbyhadz.com/blog/aws-cognito-amplify-bad-bugged#oauth-registration-with-amplify
 */
const googleSignIn = () => {
  Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
};

interface Props {
  source: 'login' | 'signup' | 'embedded signup';
  isEmbedded?: boolean;
}
export const GoogleOAuth: React.FunctionComponent<Props> = ({ source, isEmbedded }) => {
  return process.env.REACT_APP_OAUTH_GOOGLE === 'true' ? (
    <Button
      onClick={() => {
        if (source === 'login') {
          trackEvent('general.click.signInButton', { skipCompany: true });
        } else {
          setSignUpContext('google');
          trackEvent('general.click.signUpButton', { skipCompany: true });
        }

        storeOauthSignupQueryParams();

        if (isEmbedded) {
          redirToGoogleOAuthEmbedded();
        } else {
          googleSignIn();
        }
      }}
      variant={ButtonVariant.Outlined}
      size={ButtonSize.Medium}
      leftIcon={() => <CustomIcon name="googleLogo" size={20} />}
    >
      {source === 'login' ? 'Log in' : 'Sign up'} with Google
    </Button>
  ) : null;
};

export const ContinueWithGoogleOAuth = () => {
  googleSignIn();
  return null;
};
