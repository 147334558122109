/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';

/**
 * @param ref The clickable element.
 * @param onClickOutside A callback function that runs when a click event occurs on something other than `ref`.
 */
export const useClickOutside = (ref: React.RefObject<HTMLDivElement>, onClickOutside: any) => {
  const clickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      onClickOutside();
    }
  };

  useEffect(() => {
    document.addEventListener('click', clickOutside, true);
    return () => {
      document.removeEventListener('click', clickOutside, true);
    };
  });
};
