import React from 'react';
import { useSelector } from 'react-redux';

import styles from './Spinner.module.scss';

const Spinner = () => {
  const shown = useSelector((state) => state.spinner.shown);

  if (!shown) {
    return null;
  }

  return (
    <div className={styles.spinner} data-testid="spinner">
      <div className={styles.loader} />
    </div>
  );
};

export default Spinner;
