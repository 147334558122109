import { ReactNode } from 'react';
import { BreakPoint } from 'types/designSystem';

interface ResponsiveContentProps extends React.HTMLAttributes<HTMLSpanElement> {
  largeContent?: ReactNode;
  smallContent?: ReactNode;
  breakpoint?: BreakPoint;
}

const ResponsiveContent = ({
  largeContent,
  smallContent,
  breakpoint = BreakPoint.lg,
  ...props
}: ResponsiveContentProps) => {
  if (!breakpoint) {
    return <span {...props}>{largeContent}</span>;
  }

  return (
    <>
      <span className={`d-none d-${breakpoint}-inline-block`} {...props}>
        {largeContent}
      </span>
      <span className={`d-inline-block d-${breakpoint}-none`} {...props}>
        {smallContent}
      </span>
    </>
  );
};

export default ResponsiveContent;
