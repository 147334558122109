import classnames from 'classnames';
import Form from 'react-bootstrap/Form';
import { Field as FinalFormField, FieldMetaState, FieldInputProps } from 'react-final-form';

import { FormFieldProps } from 'types';

import SubLabel from './SubLabel';

import styles from './Field.module.scss';

interface RenderChildrenAttrs<TValue, TValueExtraInfo>
  extends Omit<FieldInputProps<TValue | undefined>, 'onChange'> {
  invalid: boolean;
  meta: FieldMetaState<TValue | null>;
  onChange(changeData: TValue | null, extraInfo?: TValueExtraInfo): void;
}

type RenderChildren<TValue, TValueExtraInfo> = (
  attrs: RenderChildrenAttrs<TValue, TValueExtraInfo>,
) => React.ReactNode;

interface FieldProps<TValue, TValueExtraInfo> extends FormFieldProps<TValue, TValueExtraInfo> {
  children: RenderChildren<TValue, TValueExtraInfo>;
}

const Field = <TValue, TValueExtraInfo = unknown>({
  name,
  children,
  label,
  subLabel,
  type,
  validate,
  validationType = 'onSubmit',
  inputPrepend,
  inputAppend,
  groupClassName,
  inputClassName,
  labelClassName,
  hintClassName,
  feedbackClassName,
  onChange,
  preProcessValue = (value) => value,
  renderHint,
}: FieldProps<TValue, TValueExtraInfo>) => {
  return (
    <FinalFormField
      name={name}
      type={type}
      validate={validate}
      parse={(value) => (typeof value === 'string' && value === '' ? null : value)}
    >
      {({ input, meta }) => {
        const hintContent = renderHint && renderHint(meta);

        let invalid;
        switch (validationType) {
          case 'onBlur':
            invalid = meta.touched && !meta.active && meta.invalid;
            break;
          case 'onChange':
            invalid = meta.dirty && meta.invalid;
            break;
          case 'onSubmit':
          default:
            invalid =
              meta.touched &&
              !meta.active &&
              meta.invalid &&
              !meta.dirtySinceLastSubmit &&
              (meta.submitFailed || !!meta.submitError);
        }
        invalid = !!invalid;

        const handleChange = (data: TValue | null, optionValue?: TValueExtraInfo) => {
          // TODO fix any type here, by making sure only value is passed to data and not event, see Input.tsx
          const changeData: any = data;
          const value = preProcessValue(changeData?.target ? changeData.target.value : changeData);
          input.onChange(value);
          if (onChange) {
            onChange(value, optionValue);
          }
        };

        return (
          <Form.Group controlId={name} className={classnames(styles.formField, groupClassName)}>
            {label || subLabel ? (
              <div className={classnames(styles.formLabel, labelClassName)}>
                {label && <Form.Label>{label}</Form.Label>}
                {subLabel ? <SubLabel>{subLabel}</SubLabel> : null}
              </div>
            ) : null}
            <div className={classnames(styles.formInput, inputClassName)}>
              <>
                {inputPrepend}
                {children({ invalid, meta, ...input, onChange: handleChange })}
                {inputAppend}
                {invalid && (
                  <Form.Control.Feedback
                    type="invalid"
                    className={classnames(styles.feedback, feedbackClassName)}
                  >
                    {meta.error || meta.submitError}
                  </Form.Control.Feedback>
                )}
                {hintContent && (
                  <Form.Text className={classnames(styles.formText, hintClassName)}>
                    {hintContent}
                  </Form.Text>
                )}
              </>
            </div>
          </Form.Group>
        );
      }}
    </FinalFormField>
  );
};

export default Field;
