import { ReactElement, memo, useEffect, useState } from 'react';

import actioncable from './actioncable';
import Context, { ProviderContextProps } from './context';

interface ProviderProps {
  url: string;
  children?: ReactElement;
}

// eslint-disable-next-line react/display-name
export const Provider = memo(({ url, ...props }: ProviderProps) => {
  const [cable, setCable] = useState<ProviderContextProps>(null);

  useEffect(() => {
    if (url) {
      setCable(actioncable.createConsumer(url));
    }
    return () => {
      cable?.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, setCable]);

  return <Context.Provider value={cable} {...props} />;
});
