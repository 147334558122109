import { Binoculars } from '@phosphor-icons/react';
import classNames from 'classnames';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import Badge from 'js/design-system/Badge';
import Button from 'js/design-system/Button/Button';
import { ButtonSize, ButtonVariant } from 'js/design-system/Button/types';
import Menu from 'js/design-system/Menu/Menu';
import Text from 'js/design-system/Text/Text';
import useFeatureAccess from 'js/hooks/useFeatureAccess';
import useNumberParams from 'js/hooks/useNumberParams';
import { UpgradeEventSource } from 'types';

import styles from './DataLookupMenu.module.scss';

const DataLookupMenu = () => {
  const { scenarioId } = useNumberParams();
  const [open, setOpen] = useState(false);
  const { hasFeatureAccess, openUpgradePrompt } = useFeatureAccess();
  const canHaveRanges = hasFeatureAccess('have_ranges');

  if (!scenarioId) return null;

  const viewRangesItem = (
    <>
      <div className={styles.viewRangesTitle}>
        <Text variant="text-misc-input-bold" className={styles.text}>
          View My Ranges
        </Text>
        {!canHaveRanges && (
          <Badge color="neutral-full-black" backgroundColor="system-yellow-600">
            Click to upgrade
          </Badge>
        )}
      </div>
      <Text variant="text-misc-help" className={styles.text}>
        Access your company&apos;s approved pay ranges for current and future roles
      </Text>
    </>
  );

  return (
    <Menu
      trigger={
        <Button
          variant={ButtonVariant.Outlined}
          size={ButtonSize.Medium}
          leftIcon={Binoculars}
          className={classNames(styles.trigger, { [styles.open]: open })}
        >
          Data Lookup
        </Button>
      }
      menuItems={[
        {
          value: 'market',
          label: 'market',
          displayLabel: (
            <Link to={`/scenarios/${scenarioId}/data-lookup/market`} className={styles.item}>
              <Text variant="text-misc-input-bold">Explore Market Data</Text>
              <Text variant="text-misc-help">
                Discover what companies like yours are paying for a given role and location
              </Text>
            </Link>
          ),
        },
        {
          value: 'ranges',
          label: 'ranges',
          displayLabel: canHaveRanges ? (
            <Link to={`/scenarios/${scenarioId}/data-lookup/ranges`} className={styles.item}>
              {viewRangesItem}
            </Link>
          ) : (
            <div className={classNames(styles.item, styles.needUpgrade)}>{viewRangesItem}</div>
          ),
        },
      ]}
      menuClassName={styles.menu}
      onSelect={(value) => {
        if (value === 'ranges' && !canHaveRanges)
          openUpgradePrompt(UpgradeEventSource.DataLookupRanges);
      }}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
    />
  );
};

export default DataLookupMenu;
