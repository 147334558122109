import {
  NOT_USING_RANGES_PAY_RANGE_GROUP_ID,
  NO_BENCHMARK_ROLE_PAY_RANGE_GROUP_ID,
} from 'js/components/pay-strategy/hooks/useGetRangeGroups';
import { Employee, JobRoleWithLevels, keys } from 'types';

export const DEFAULT_EMPLOYEE_DEPARTMENT = {
  increase: 0,
};

export const MAX_BASE_SALARY = 10 ** 12;
export const MAX_ANNUAL_BONUS = 10 ** 12;
export const MAX_COMMISSION = 10 ** 12;
export const MAX_EQUITY = 10 ** 8;
export const MIN_ADJUSTMENT = 0.01;
export const MAX_ADJUSTMENT = 2;

export const SHOULD_VERIFY = {
  level: 1,
  jobRole: 2,
  location: 4,
  confirmed: 1024,
};

const VERIFICATIONS = [
  { name: 'level', value: SHOULD_VERIFY.level },
  { name: 'job role', value: SHOULD_VERIFY.jobRole },
  { name: 'location', value: SHOULD_VERIFY.location },
];

export const VERIFIED = 0;

// Ex.: const isLevelVerified = shouldVerify(employee, SHOULD_VERIFY.level);
export const shouldVerify = (employee: Pick<Employee, 'verify'> | undefined, verify: number) => {
  if (!employee) return false;

  return (verify & employee?.verify) === verify;
};

/** Returns an object where they keys are the benchmarking field names and the value is the field's verified state.
 * @returns `{ level: boolean, jobRole: boolean, location: boolean }` */
export const getVerifiedFields = (employee: Employee) => ({
  level: !shouldVerify(employee, SHOULD_VERIFY.level),
  jobRole: !shouldVerify(employee, SHOULD_VERIFY.jobRole),
  location: !shouldVerify(employee, SHOULD_VERIFY.location),
});

/** Calculate the expected value of `employee.verify` given all benchmarking field states.
 * @param verifiedFields the result of the `getVerifiedFields` function on line 33.
 * @returns number
 */
export const getVerifyValue = (verifiedFields: ReturnType<typeof getVerifiedFields>) =>
  keys(verifiedFields)
    .filter((k) => verifiedFields[k] === false)
    .map((fieldName) => SHOULD_VERIFY[fieldName])
    .reduce((sum, next) => sum + next, 0);

export const verified = (employee: Employee) => employee?.verify === VERIFIED;

export const getVerifyFields = (employee: Employee) =>
  VERIFICATIONS.filter((v) => shouldVerify(employee, v.value)).map((v) => v.name);

export const concatVerifyNames = (employee: Employee) => {
  const concatVerifyFields = (fields: string[]) => {
    if (fields.length === 0) return '';
    if (fields.length === 1) return fields[0];
    if (fields.length === 2) return fields.join(' and ');

    const lastField = fields[fields.length - 1];

    return `${fields.slice(0, -1).join(', ')} and ${lastField}`;
  };

  const verifyFields = getVerifyFields(employee);
  return concatVerifyFields(verifyFields);
};

export const getPayRangeGroupId = (employee: Employee) => {
  if (employee.payRangeGroupId) return employee.payRangeGroupId;
  if (!employee.jobRoleId) return NO_BENCHMARK_ROLE_PAY_RANGE_GROUP_ID;
  return NOT_USING_RANGES_PAY_RANGE_GROUP_ID;
};

export const groupEmployees = (employees: Employee[], groupField: string) =>
  employees?.reduce((groups, employee) => {
    const group =
      groupField === 'payRangeGroupId'
        ? getPayRangeGroupId(employee)
        : (employee[groupField as keyof Employee] as string | number);
    groups[group] ||= [];
    groups[group].push(employee);

    return groups;
  }, {} as Record<string | number, Employee[]>);

export const getReportsRecursively = (
  managerId: number,
  reportsByManagerId: Record<number, Employee[]>,
  traveledSet = new Set(),
) => {
  traveledSet.add(managerId);
  let reports = reportsByManagerId[managerId] || [];

  reports.forEach((report) => {
    if (!traveledSet.has(report.id)) {
      reports = [...reports, ...getReportsRecursively(report.id, reportsByManagerId, traveledSet)];
    }
  });

  return reports;
};

export const getAllReports = (employeeId: number, employeeById: Record<number, Employee>) => {
  const reportsByManagerId = Object.values(employeeById).reduce(
    (byManagerId: Record<number, Employee[]>, employee) => {
      if (employee.reportsToId) {
        if (byManagerId[employee.reportsToId]) {
          byManagerId[employee.reportsToId].push(employee);
        } else {
          byManagerId[employee.reportsToId] = [employee];
        }
      }

      return byManagerId;
    },
    {},
  );

  return getReportsRecursively(employeeId, reportsByManagerId);
};

export const getTierHint = (
  cashAdjustment: number,
  locationName: string,
  score?: number,
  jobRole?: JobRoleWithLevels,
) => {
  const overrideMessages: Record<number, string> = {
    10: 'Executive override applied',
    20: `Job area override applied (${jobRole?.jobArea.name})`,
    30: '', // default - the locationId belongs to an existing tier
    40: '', // no tier is associated to this locationId
  };

  let hintText = '';

  const percentage = cashAdjustment * 100;
  const percentageText = percentage === 100 ? '' : `${percentage}% of`;
  hintText = `Benchmarked to ${percentageText} ${locationName}`;

  if (score) {
    hintText = `${hintText}\n${overrideMessages[score]}`;
  }

  return hintText;
};

export const getFirstNameApostrophe = (employee: Employee) => {
  const firstName = employee.name.split(' ')[0];
  return `${firstName}'s`;
};
