import { PrimitiveGenericObject, Scenario } from 'types';

export enum CompensationType {
  SalaryOte = 'salaryOte',
  BaseSalary = 'baseSalary',
  TotalCash = 'totalCash',
  EquityOwnershipPercent = 'equityOwnershipPercent',
  EquityShareCount = 'equityShareCount',
}

export enum RangePerformance {
  Within = 'within',
  Above = 'above',
  Below = 'below',
}

export enum CompMode {
  Market = 'market',
  Ranges = 'ranges',
}

export enum TitleCompMode {
  Market = 'Market',
  Ranges = 'Ranges',
}

export type Percentile = Record<number, number>;

export type Percentiles = {
  baseSalaryPercentiles: Percentile;
  ownershipPercentPercentiles: Percentile;
  shareCountPercentiles: Percentile;
  totalCashPercentiles: Percentile;
};

export interface EmployeeRangeBand {
  max: number;
  mid: number;
  min: number;
  position: number | null;
  compaRatio: number;
}

export type Range = {
  cash: EmployeeRangeBand | null;
  equityOwnershipPercent: EmployeeRangeBand | null;
  equityShareCount: EmployeeRangeBand | null;
};

export type Compensation = {
  annualBonusUsd: number | null;
  appliedGeoAdjustmentId: number | null;
  baseSalary: number | null;
  baseSalaryPercentile: number | null;
  baseSalaryTargetPercentile: number;
  baseSalaryTargetUsd: number | null;
  baseSalaryUsd: number | null;
  commissionsUsd: number | null;
  employeeId: number | null;
  equityPercentile: number | null;
  equityTargetPercentile: number;
  id: number;
  ownershipPercentage: number | null;
  ownershipPercentageTarget: number | null;
  percentiles: Percentiles;
  ranges: Range;
  shareCount: number | null;
  shareCountTarget: number;
  totalCash: number | null;
  totalCashPercentile: number | null;
  totalCashTargetPercentile: number;
  totalCashTargetUsd: number | null;
  totalCashUsd: number | null;
  lastCashChangePercentage: number | null;
  lastEquityChangePercentage: number | null;
  cashChangedAt: string | null;
  equityChangedAt: string | null;
};

export type Formatter = (value?: number | null, options?: PrimitiveGenericObject) => string | null;

export interface CompensationTypeDetails {
  name: 'Salary / OTE' | 'Equity' | 'Base Salary' | 'Total Cash';
  longName:
    | 'Salary / OTE'
    | 'Equity as Percent'
    | 'Equity as Shares'
    | 'Base Salary'
    | 'Total Cash';
  valueKey?: 'baseSalaryUsd' | 'totalCashUsd' | 'ownershipPercentage' | 'shareCount';
  localValueKey?: 'totalCash' | 'baseSalary' | 'totalCash' | null;
  percentileKey?: 'baseSalaryPercentile' | 'totalCashPercentile' | 'equityPercentile';
  targetValueKey?:
    | 'baseSalaryTargetUsd'
    | 'totalCashTargetUsd'
    | 'ownershipPercentageTarget'
    | 'shareCountTarget';
  targetPercentileKey?:
    | 'baseSalaryTargetPercentile'
    | 'totalCashTargetPercentile'
    | 'equityTargetPercentile';
  percentilesKey?:
    | 'baseSalaryPercentiles'
    | 'totalCashPercentiles'
    | 'ownershipPercentPercentiles'
    | 'shareCountPercentiles';
  rangeKey: 'cash' | 'equityOwnershipPercent' | 'equityShareCount';
  adjustmentKey: 'cashAdjustment' | 'equityAdjustment';
  convertDisplayValue: (value: number, scenario?: Scenario) => number | void;
  formatter: Formatter;
  midPointHandler: (arr: number[]) => number | null;
  midPointDescription: 'mean' | 'median';
  cash: boolean;
}

export type CompensationTypeDetailsMapping = Record<CompensationType, CompensationTypeDetails>;

export enum CompensationTab {
  Cash = 'Cash',
  Equity = 'Equity',
}
