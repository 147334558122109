import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useQuery } from '@tanstack/react-query';

import api from './api';

interface ShowUpdateAvailable {
  showUpdateAvailable: boolean;
}

const fetchShowUpdateAvailable = async (scenarioId: number) => {
  const { success, data } = await api.get<ShowUpdateAvailable>(
    `/scenarios/${scenarioId}/pay_ranges/show_update_available`,
  );

  if (success) {
    return data;
  }

  throw new Error('Failed to load showUpdateAvailable');
};

export const queryKeys = createQueryKeys('showUpdateAvailable', {
  detail: (scenarioId: number) => ({
    queryKey: [{ scenarioId }],
    queryFn: () => fetchShowUpdateAvailable(scenarioId),
  }),
});

export const useShowUpdateAvailable = (scenarioId: number) => {
  return useQuery(queryKeys.detail(scenarioId));
};
