import Text from 'js/design-system/Text/Text';

interface RoleDescriptionsLinkProps {
  text?: React.ReactNode;
  className?: string;
}

const LevelMatrixLink = ({ text = 'Level Matrix', className }: RoleDescriptionsLinkProps) => {
  return (
    <a
      href={`${process.env.REACT_APP_JOB_LEVEL_MATRIX_FILE_URL}`}
      target="_blank"
      rel="noreferrer"
      className={className}
    >
      {typeof text !== 'object' ? (
        <Text variant="text-body-main-underline" color="platform-gray-500" Element="span">
          {text}
        </Text>
      ) : (
        text
      )}
    </a>
  );
};

export default LevelMatrixLink;
