import { MapPinLine } from '@phosphor-icons/react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { useDismissScenarioNotification } from 'js/api/scenarioNotifications';
import Button from 'js/design-system/Button/Button';
import { ButtonSize, ButtonVariant } from 'js/design-system/Button/types';
import InformationalModal from 'js/design-system/InformationalModal';
import queries from 'js/queries';

const UnassignedLocationsModal = ({ scenarioId }: { scenarioId: number }) => {
  const navigate = useNavigate();
  const { data: notifications = [] } = useQuery(
    queries.scenarios.detail(scenarioId)._ctx.notifications,
  );
  const notification = notifications.find((n) => n.name === 'new_unassigned_geo_tier_locations');

  const dismiss = useDismissScenarioNotification(scenarioId);

  if (!notification) {
    return null;
  }

  const onPrimaryClick = () => {
    navigate(`/scenarios/${scenarioId}/pay-strategy`);
  };

  return (
    <InformationalModal
      title="New locations found"
      description="Review your Geo Strategy to add the Closest Available Markets to a geo tier"
      icon={MapPinLine}
      iconWeight="fill"
      primaryLabel={
        <Button variant={ButtonVariant.Filled} size={ButtonSize.Medium} onClick={onPrimaryClick}>
          Review Geo Strategy
        </Button>
      }
      secondaryLabel="Skip"
      onSecondaryClick={() => dismiss.mutate(notification.id)}
      show
      onHide={() => dismiss.mutate(notification.id)}
    />
  );
};

export default UnassignedLocationsModal;
