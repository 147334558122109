import { Company } from './company';
import { EmployeeRangeBand } from './compensation';
import { Employee } from './employee';
import { Scenario } from './scenario';
import { TotalRewardsSettings } from './scenarioSettings';

export type TotalRewardsStatements = {
  employee: Partial<
    Pick<
      Employee,
      | 'name'
      | 'currencyCode'
      | 'baseSalary'
      | 'commissions'
      | 'jobTitle'
      | 'jobLevelId'
      | 'jobLevel'
      | 'shareCount'
      | 'annualBonus'
      | 'locationName'
      | 'startDate'
    > & {
      salaryOte: number;
      benefitsValue: number;
      jobRole: {
        commissionBased: boolean;
        name?: string;
        jobType?: {
          name: string;
        };
      };
    }
  >;
  company: Partial<
    Pick<Company, 'companyName' | 'numEmployees'> & {
      headquartersLocation: string;
      stage: string;
      totalFundsRaised: Company['fundsRaised'];
    }
  >;
  scenario: Partial<
    Pick<
      Scenario,
      | 'logo'
      | 'numEmployees'
      | 'preferredPrice'
      | 'strikePrice'
      | 'equityType'
      | 'stage'
      | 'benefits'
    > &
      Pick<
        TotalRewardsSettings,
        | 'showEquityValue'
        | 'showValuation'
        | 'showExpectedValuations'
        | 'expectedValuations'
        | 'welcomeMessage'
        | 'equityParagraph'
      > & {
        showPreferredPrice: TotalRewardsSettings['preferredPrice'];
        equityTypeText?: Scenario['equityType'];
        currentValuation: number;
      }
  >;
  rangeBand: EmployeeRangeBand | null;
};

export enum TotalRewardsSection {
  TopDetailsSection = 'TopDetailsSection',
  TermsSection = 'TermsSection',
  SalaryRangeSection = 'SalaryRangeSection',
  EquitySection = 'EquitySection',
  ValuationsSection = 'ValuationsSection',
  BenefitsSection = 'BenefitsSection',
  AboutUsSection = 'AboutUsSection',
}
