import { createSelector } from 'reselect';

import { AppState, Department } from 'types';

export const getAll = createSelector(
  (state: AppState) => state.departments.allIds,
  (state: AppState) => state.departments.byId,
  (allIds, byId): Department[] => allIds.map((id) => byId[id]),
);

export const getById = (state: AppState, id: number): Department => state.departments.byId[id];
