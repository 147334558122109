import { useQueryClient, useMutation } from '@tanstack/react-query';

import { updateConnectedCompany } from 'js/api/connectedCompanies';
import queries from 'js/queries';

export const useSetActiveConnectedCompany = (companyId: number) => {
  const queryClient = useQueryClient();
  const setActiveConnectedCompany = (id: number) => updateConnectedCompany(id, { active: true });
  return useMutation(setActiveConnectedCompany, {
    onSuccess: () => {
      queryClient.invalidateQueries(queries.companies.detail(companyId)._ctx.connectedCompanies);
      queryClient.invalidateQueries(
        queries.companies.detail(companyId)._ctx.lastCashConnectionSync,
      );
    },
  });
};
