import { AnyAction } from 'redux';

import ACTIONS from '../constants/actions';

type ActivityLogState = {
  stale: boolean;
};

const initialState: ActivityLogState = {
  stale: false,
};

export default (
  state: ActivityLogState = initialState,
  action: AnyAction = { type: null },
): ActivityLogState => {
  switch (action.type) {
    case ACTIONS.ACTIVITY_LOG_STALE_DATA: {
      const { stale } = action.data;
      return { ...state, stale };
    }
    default:
      return state;
  }
};
