import api from 'js/api/api';

import { DataLookupSource } from '../DataLookup';
import { RangeLookupData, RecentQuery, RecentQueryFormData } from './types';

type FetchPayRangeJobPostParams = {
  scenarioId: number;
  source: DataLookupSource;
  jobRoleId: number;
  jobLevelIds?: number[];
  tierIds: number[];
};

export const fetchPayRangeJobPost = async ({
  scenarioId,
  source,
  jobRoleId,
  jobLevelIds,
  tierIds,
}: FetchPayRangeJobPostParams) => {
  const { status, data } = await api.post<RangeLookupData>(
    `/scenarios/${scenarioId}/pay_ranges/job_post`,
    {
      params: {
        jobRoleId,
        source,
        jobLevelIds,
        tierIds,
      },
    },
  );

  if (status === 200) {
    return data;
  }

  throw new Error(status === 204 ? 'No ranges found for role.' : 'Failed to fetch job post range.');
};

export const fetchRecentQueries = async (scenarioId: number, source: string) => {
  const { success, data } = await api.get<RecentQueryFormData[]>(
    `/scenarios/${scenarioId}/pay_range_queries`,
    {
      urlParams: {
        source,
      },
    },
  );

  if (success) {
    return data;
  }

  throw new Error('Failed to fetch recent queries.');
};

export const fetchRecentQuery = async (scenarioId: number, queryId: number) => {
  const { success, data } = await api.get<RecentQuery>(
    `/scenarios/${scenarioId}/pay_range_queries/${queryId}`,
  );

  if (success) {
    return data;
  }

  throw new Error('Failed to fetch recent queries.');
};

export const clearRecentQueries = async (scenarioId: number, source: DataLookupSource) => {
  const { success, data } = await api.post<Record<'result', string>>(
    `/scenarios/${scenarioId}/pay_range_queries/clear`,
    {
      params: {
        source,
      },
    },
  );

  if (success) {
    return data;
  }

  throw new Error('Failed to fetch recent queries.');
};
