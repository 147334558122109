import { ArrowLineRight } from '@phosphor-icons/react';
import classNames from 'classnames';
import { ForwardedRef, forwardRef, useEffect, useState } from 'react';

import { ActivityLogViewEventDetails } from 'js/analytics/types/activityLog';
import useTrackEvent from 'js/analytics/useTrackEvent';
import IconButton from 'js/design-system/IconButton/IconButton';
import Text from 'js/design-system/Text/Text';
import Tooltip from 'js/design-system/Tooltip/Tooltip';
import buttonize from 'js/utils/buttonize';
import { noop } from 'js/utils/common';

import ActivityLogCommentField from './ActivityLogCommentField';
import ActivityLogFilter from './ActivityLogFilter';
import ActivityLogList from './ActivityLogList';
import { ActivityLogProps, ActiveTab, ActivityLogListSource } from './types';

import styles from './ActivityLog.module.scss';

const filterLogsByType = <TActivityAction extends string, TActivityDetail>(
  data: ActivityLogListSource<TActivityAction, TActivityDetail>[],
  type: ActivityLogListSource<TActivityAction, TActivityDetail>['type'],
) => data.filter((v) => v?.type === type);

const ActivityLog = forwardRef(
  <TActivityAction extends string, TActivityDetail>(
    {
      initialActiveTab = ActiveTab.All,
      onClose,
      source,
      user,
      className,
      analytics,
    }: ActivityLogProps<TActivityAction, TActivityDetail>,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const [activeTab, setActiveTab] = useState<ActiveTab>(initialActiveTab);
    const [allLogData, setAllLogData] = useState<
      ActivityLogListSource<TActivityAction, TActivityDetail>[]
    >([]);
    const [commentsLogData, setCommentsLogData] = useState<
      ActivityLogListSource<TActivityAction, TActivityDetail>[]
    >([]);
    const hasComments = commentsLogData.length > 0;
    const { trackEvent } = useTrackEvent<ActivityLogViewEventDetails>();

    useEffect(() => {
      if (Array.isArray(source)) {
        setCommentsLogData(filterLogsByType(source, 'comment'));
        setAllLogData(source);
      }
    }, [source]);

    const handleTabChange = (tabKey: ActiveTab) => {
      if (tabKey !== activeTab) {
        trackEvent('activityLog.view', analytics);
      }
      setActiveTab(tabKey);
    };

    const header = (
      <header className={styles.header}>
        <div className={styles.closeBtnWrapper}>
          {onClose && (
            <Tooltip content="Hide Activity Log">
              <IconButton
                icon={ArrowLineRight}
                size="small"
                shape="square"
                iconColor="primary-gray-500"
                onClick={onClose}
              />
            </Tooltip>
          )}
        </div>
        <Text variant="text-misc-eyebrow">Activity Log</Text>
        <div className={styles.filterBtnWrapper}>
          {hasComments && (
            <ActivityLogFilter handleTabChange={handleTabChange} activeTab={activeTab} />
          )}
        </div>
      </header>
    );

    const footer = (
      <footer className={styles.footer}>
        <ActivityLogCommentField user={user} />
      </footer>
    );

    return (
      <div
        className={classNames(styles.wrapper, className)}
        ref={ref}
        {...buttonize((e) => e.stopPropagation())}
        tabIndex={0}
        onKeyDown={noop}
        role="textbox"
      >
        {header}
        <ActivityLogList source={activeTab === ActiveTab.All ? allLogData : commentsLogData} />
        {footer}
      </div>
    );
  },
);

ActivityLog.displayName = 'ActivityLog';

export default ActivityLog;
