import { X } from '@phosphor-icons/react';
import classnames from 'classnames';

import IconButton from 'js/design-system/IconButton/IconButton';
import Modal from 'js/design-system/Modal';
import Text from 'js/design-system/Text/Text';

import { EducationalModalProps } from './types';

import styles from './EducationalModal.module.scss';

const EducationalModal = ({
  title,
  show = false,
  bulleted = true,
  items,
  onHide,
}: EducationalModalProps) => {
  const renderItems = () => {
    if (!items) return null;

    if (items.length === 1) {
      return (
        <div className={styles.singleItem}>
          <Text color="platform-gray-700">{items[0]}</Text>
        </div>
      );
    }

    return (
      <ul
        className={classnames(styles.list, {
          [styles.noBullets]: !bulleted,
        })}
      >
        {items.map((item, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <li key={i} className={styles.item}>
            <Text color="platform-gray-700">{item}</Text>
          </li>
        ))}
      </ul>
    );
  };
  return (
    <Modal
      show={show}
      onHide={onHide}
      dialogClassName={styles.wrapper}
      contentClassName={styles.content}
    >
      <IconButton
        icon={X}
        size="small"
        shape="circle"
        className={styles.closeButton}
        onClick={onHide}
      />
      <Text Element="h5" variant="text-heading-h5" className={styles.title}>
        {title}
      </Text>

      {renderItems()}
    </Modal>
  );
};

export default EducationalModal;
