import { ReactNode, useCallback } from 'react';
import { Field as FinalFormField } from 'react-final-form';

import { isEmpty } from 'js/utils/value';

import { ValidationProp } from '../ErrorText';
import { ConnectedFieldProps, FieldProps } from './types';

interface ConnectedFieldComponentProps<TValue> extends ConnectedFieldProps<FieldProps<TValue>> {
  fieldType: string;
  renderField: <TFieldProps extends FieldProps<TValue>>(props: TFieldProps) => ReactNode;
}

const ConnectedField = <TValue,>({
  fieldType,
  renderField,
  validate,
  onChange,
  isInvalid,
  inputRef,
  ...rest
}: ConnectedFieldComponentProps<TValue>) => {
  const handleChange = useCallback(
    (value: TValue | null, inputOnChange: (v: TValue | null) => void) => {
      inputOnChange(value);
      if (onChange) onChange(value);
    },
    [onChange],
  );

  const parse = (value: TValue) => (isEmpty(value) ? null : value);

  return (
    <FinalFormField name={rest.name} type={fieldType} validate={validate} parse={parse}>
      {({ input: { type, onChange: inputOnChange, ...inputRest }, meta }) => {
        const { touched, active, invalid, dirtySinceLastSubmit, submitFailed, submitError, error } =
          meta;

        const isFieldInvalid = isInvalid
          ? isInvalid(meta)
          : !!(
              touched &&
              !active &&
              invalid &&
              !dirtySinceLastSubmit &&
              (submitFailed || !!submitError)
            );

        let validation: ValidationProp | undefined;
        if (isFieldInvalid) {
          validation = {
            status: 'error',
            text: error,
          };
        }

        return renderField({
          ...inputRest,
          ...rest,
          validation,
          ref: inputRef,
          onChange: (value: TValue | null) => handleChange(value, inputOnChange),
        });
      }}
    </FinalFormField>
  );
};

export default ConnectedField;
