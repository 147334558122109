import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import useTrackEvent from 'js/analytics/useTrackEvent';

export const useTrackPurchase = (currentProductId?: number) => {
  const { trackEvent } = useTrackEvent();
  const [searchParams, setSearchParams] = useSearchParams();
  const action = searchParams.get('action');
  const newProductId = searchParams.get('product_id');

  useEffect(() => {
    if (newProductId && action === 'subscribe' && currentProductId === Number(newProductId)) {
      trackEvent('upgrade.complete.purchase', { productId: newProductId });
      setSearchParams({}, { replace: true });
    }
  }, [action, currentProductId, newProductId, setSearchParams, trackEvent]);
};
