import {
  ShieldPlus,
  Eyeglasses,
  Bank,
  ForkKnife,
  Airplane,
  FaceMask,
  Money,
  Train,
  Briefcase,
  CirclesThree,
  HouseLine,
} from '@phosphor-icons/react';
import { ElementType } from 'react';
import { Color } from 'types/designSystem';

import { CustomIcon } from 'js/design-system/Icon';

type BenefitCategoryMapValue = {
  icon: ElementType;
  label: string;
};

export const benefitsCategoryMap = (customIconSize: number, customIconColor: Color) =>
  new Map<string, BenefitCategoryMapValue>([
    ['medical', { icon: ShieldPlus, label: 'Medical' }],
    [
      'dental',
      {
        icon: () => (
          <CustomIcon
            name="tooth"
            size={customIconSize}
            color={customIconColor}
            colorApplication="stroke"
          />
        ),
        label: 'Dental',
      },
    ],
    ['vision', { icon: Eyeglasses, label: 'Vision' }],
    ['retirement', { icon: Bank, label: 'Retirement' }],
    ['family_leave', { icon: HouseLine, label: 'Family Leave' }],
    [
      'charitable',
      {
        icon: () => (
          <CustomIcon
            name="charity"
            size={customIconSize}
            color={customIconColor}
            colorApplication="stroke"
          />
        ),
        label: 'Charitable',
      },
    ],
    ['food', { icon: ForkKnife, label: 'Food' }],
    ['paid_time_off', { icon: Airplane, label: 'Paid Time Off' }],
    ['sick_time', { icon: FaceMask, label: 'Sick Time' }],
    ['stipend', { icon: Money, label: 'Stipend' }],
    ['commuter', { icon: Train, label: 'Commuter' }],
    ['training', { icon: Briefcase, label: 'Training' }],
    ['other', { icon: CirclesThree, label: 'Other' }],
  ]);

export type Benefit = {
  category: string;
  title: string;
  description: string;
  dollarValue?: number;
  percentBaseSalary?: number;
  enabled?: boolean;
  key?: number;
  _destroy?: boolean;
  onEditClick?: () => void;
  onDeleteClick?: () => void;
  onChange?: (value: boolean) => void;
  id?: number;
};

export type BenefitsFormData = {
  benefits: Benefit[] | undefined;
};

export const benefitsFieldName = 'benefits';
