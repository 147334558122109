import { AnyAction } from 'redux';

import { FetchStatus, StrategyTemplate } from 'types';

import ACTIONS from '../constants/actions';

type StrategyTemplateState = {
  byId: Record<number, StrategyTemplate>;
  allIds: number[];
  status: FetchStatus;
  error?: string;
};

const initialState: StrategyTemplateState = {
  byId: {},
  allIds: [],
  status: FetchStatus.Idle,
};

export default (
  state: StrategyTemplateState = initialState,
  action: AnyAction = { type: null },
): StrategyTemplateState => {
  switch (action.type) {
    case ACTIONS.FETCHING_STRATEGY_TEMPLATES:
      return { ...state, status: FetchStatus.Loading };
    case ACTIONS.RECEIVE_STRATEGY_TEMPLATES:
      return {
        ...state,
        allIds: action.data.map((datum: StrategyTemplate) => datum.id),
        byId: action.data.reduce(
          (templateById: Record<number, StrategyTemplate>, template: StrategyTemplate) => {
            if (template.id) {
              templateById[template.id] = template;
              return templateById;
            }
            return null;
          },
          {},
        ),
        status: FetchStatus.Succeeded,
      };
    case ACTIONS.FAIL_STRATEGY_TEMPLATES_FETCH:
      return { ...state, error: action.error, status: FetchStatus.Failed };
    default:
      return state;
  }
};
