import { AnyAction } from 'redux';

import { FetchStatus, JobArea } from 'types';

import ACTIONS from '../constants/actions';

type JobAreaState = {
  allIds: number[];
  byId: Record<number, JobArea>;
  error?: string;
  status: FetchStatus;
};

const initialState: JobAreaState = {
  allIds: [],
  byId: {},
  status: FetchStatus.Idle,
};

export default (
  state: JobAreaState = initialState,
  action: AnyAction = { type: null },
): JobAreaState => {
  switch (action.type) {
    case ACTIONS.FAIL_JOBAREAS_FETCH:
      return { ...state, error: action.error, status: FetchStatus.Failed };
    case ACTIONS.FETCHING_JOBAREAS:
      return { ...state, status: FetchStatus.Loading };
    case ACTIONS.RECEIVE_JOBAREAS:
      return {
        ...state,
        allIds: action.data.map((datum: JobArea) => datum.id),
        byId: action.data.reduce((jobAreas: Record<number, JobArea>, jobArea: JobArea) => {
          jobAreas[jobArea.id] = jobArea;
          return jobAreas;
        }, {}),
        status: FetchStatus.Succeeded,
      };
    default:
      return state;
  }
};
