import { useCallback, useMemo } from 'react';

import useAppSelector from 'js/hooks/useAppSelector';
import { getById as companySelector } from 'js/selectors/companies';
import { currentUserSelector } from 'js/selectors/currentUser';
import { currentScenarioCompanyIdSelector } from 'js/selectors/scenarios';
import { startInteraction, refreshChatbot } from 'js/services/chatService';
import { PlayBook } from 'types';

const useChat = () => {
  const {
    id: userId,
    firstName = '',
    lastName = '',
    email = '',
  } = useAppSelector(currentUserSelector) || {};
  const companyId = useAppSelector(currentScenarioCompanyIdSelector);
  const { companyName } = useAppSelector((state) => companySelector(state, companyId)) || {};

  const user = useMemo(
    () => ({ userId, firstName, lastName, email, companyName }),
    [userId, firstName, lastName, email, companyName],
  );

  const startChat = useCallback(
    (playbook: PlayBook) => {
      const canStartChat = startInteraction(playbook, user);

      // If HubSpot conversations widget is not available then fallback to contact us form link
      if (!canStartChat) {
        const urlParams = Object.entries({
          firstname: user.firstName,
          lastname: user.lastName,
          email: user.email,
        })
          .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
          .join('&');

        const contactUsUrl = `${process.env.REACT_APP_CONTACT_US_URL}${
          user.userId ? `?${urlParams}` : ``
        }`;
        window.open(contactUsUrl, '_blank');
      }
    },
    [user],
  );

  const refreshChat = useCallback(() => {
    if (user.userId) refreshChatbot(user);
  }, [user]);

  return {
    startChat,
    refreshChat,
  };
};

export default useChat;
