import Badge from 'js/design-system/Badge';
import Tooltip from 'js/design-system/Tooltip/Tooltip';
import { Scenario } from 'types';

import styles from './CustomBenchmarkBadge.module.scss';

interface CustomBenchmarkBadgeProps {
  scenario: Scenario;
}

const CustomBenchmarkBadge = ({ scenario }: CustomBenchmarkBadgeProps) =>
  scenario.customBenchmark ? (
    <Tooltip content={scenario.benchmarkLabel}>
      <Badge
        size="large"
        backgroundColor="platform-gray-500"
        color="neutral-full-white"
        className={styles.badge}
      >
        3RD PARTY MKT DATA
      </Badge>
    </Tooltip>
  ) : null;

export default CustomBenchmarkBadge;
