import { ForwardedRef, forwardRef } from 'react';
import NumberFormat, { NumberFormatValues } from 'react-number-format';

import { noop } from 'js/utils/common';

import TextFieldContainer from '../TextFieldContainer';
import { NumberTextFieldProps } from './types';

const NumberTextField = forwardRef(
  (
    {
      id,
      name,
      min,
      max,
      precision,
      fixedDecimalScale,
      allowNegative,
      format,
      parse,
      prefix,
      suffix,
      defaultEmptyValue = null,
      label,
      subLabel,
      labelPosition = 'left',
      className,
      labelClassName,
      placeholder,
      autoComplete = 'off',
      value,
      onChange = noop,
      onBlur = noop,
      onFocus = noop,
      onKeyDown = noop,
      validation,
      wrapperClassName,
      ariaLabel,
      maxLength,
      disabled = false,
      readonly = false,
      required = false,
      dataTestId,
      helpText,
      alertBox,
      inputAppend,
      inputPrepend,
      resetable = false,
      hideError = false,
    }: NumberTextFieldProps,
    ref: ForwardedRef<NumberFormat<unknown>>,
  ) => {
    const validateValueRange = ({ floatValue }: NumberFormatValues) => {
      if (!floatValue && floatValue !== 0) {
        return true;
      }

      const defaultMin = Number.MIN_SAFE_INTEGER;
      const defaultMax = Number.MAX_SAFE_INTEGER;

      const minValue = min || (maxLength ? 1 - 10 ** maxLength : defaultMin);
      const maxValue = max || (maxLength ? 10 ** maxLength - 1 : defaultMax);

      return floatValue <= maxValue && floatValue >= minValue;
    };

    return (
      <TextFieldContainer
        id={id}
        name={name}
        disabled={disabled}
        readonly={readonly}
        label={label}
        subLabel={subLabel}
        labelPosition={labelPosition}
        value={value}
        onChange={(val) => onChange(typeof val === 'number' ? val : null)}
        validation={validation}
        wrapperClassName={wrapperClassName}
        helpText={helpText}
        alertBox={alertBox}
        className={className}
        labelClassName={labelClassName}
        inputPrepend={inputPrepend}
        inputAppend={inputAppend}
        required={required}
        resetable={resetable}
        hideError={hideError}
      >
        {(onInputChange, inputClassName, cssProperties, inputValue) => (
          <NumberFormat
            ref={ref}
            style={cssProperties}
            value={inputValue}
            onBlur={onBlur}
            className={inputClassName}
            autoComplete={autoComplete}
            aria-label={ariaLabel || (typeof label === 'string' && label) || placeholder}
            isAllowed={validateValueRange}
            thousandSeparator=","
            onValueChange={({ floatValue }: NumberFormatValues, { source }) => {
              if (source === 'event') {
                onInputChange(floatValue || floatValue === 0 ? floatValue : defaultEmptyValue);
              }
            }}
            onFocus={onFocus}
            onKeyDown={onKeyDown}
            format={format}
            removeFormatting={parse}
            disabled={disabled}
            decimalScale={precision}
            fixedDecimalScale={fixedDecimalScale}
            placeholder={placeholder}
            readOnly={readonly}
            allowNegative={allowNegative}
            allowEmptyFormatting={false}
            prefix={prefix}
            suffix={suffix}
            data-testid={dataTestId || name}
          />
        )}
      </TextFieldContainer>
    );
  },
);

NumberTextField.displayName = 'NumberTextField';

export default NumberTextField;
