import { JobRoleWithLevels } from 'types';

import api from './api';

type MarketDataLookup = {
  jobRoleId: number;
  jobRoleName: string;
  jobRole: JobRoleWithLevels;
  jobLevelId: number | null;
  jobLevelName: string | null;
  locationName: string;
  locationId: number;
};

export type MarketDataLookupPopularSearches = MarketDataLookup;

export const fetchDefaultMarketDataLookup = async (args: { scenarioId: number }) => {
  const url = `/scenarios/${args.scenarioId}/market_data_lookup/defaults`;

  const { success, data } = await api.get<MarketDataLookup>(url);
  if (success) {
    return data;
  }

  return null;
};

export const fetchPopularSearchesMarketDataLookup = async (args: { scenarioId: number }) => {
  const url = `/scenarios/${args.scenarioId}/market_data_lookup/popular_searches`;

  const { success, data } = await api.get<MarketDataLookupPopularSearches[]>(url);
  if (success) {
    return data;
  }

  return null;
};
