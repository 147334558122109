import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import Store from 'js/store';

export enum FetchStatus {
  Idle = 'idle',
  Loading = 'loading',
  Succeeded = 'succeeded',
  Failed = 'failed',
}

export type AppState = ReturnType<typeof Store.getState>;

export type AppDispatch = ThunkDispatch<AppState, undefined, AnyAction>;

export type GetState = () => AppState;

export type SetLocalState<T> = (newState: T | ((oldState: T) => T)) => void;
