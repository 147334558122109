export type Department = {
  id: number;
  name: string;
  increase: number;
  overhead: number;
  recruiting: number;
  setup: number;
  scenarioId: number;
  createdAt: string;
  updatedAt: string;
};

export enum DepartmentTarget {
  salary,
  totalCash,
  equity,
}
