import { AnyAction } from 'redux';

import { FetchStatus, Permission } from 'types';

import ACTIONS from '../constants/actions';

type UserPermissionState = {
  data: Permission[];
  status: FetchStatus;
};

const initialState: UserPermissionState = {
  data: [],
  status: FetchStatus.Idle,
};

export default (
  state: UserPermissionState = initialState,
  action: AnyAction = { type: null },
): UserPermissionState => {
  switch (action.type) {
    case ACTIONS.RECEIVE_PERMISSIONS:
      return {
        data: action.data,
        status: FetchStatus.Succeeded,
      };
    default:
      return state;
  }
};
