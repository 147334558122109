import { forwardRef, ForwardedRef } from 'react';
import NumberFormat from 'react-number-format';

import { Select } from '..';
import { NumberTextField } from '../TextField/NumberTextField';
import { NumberTextFieldWithDropdownProps } from './types';

import styles from './NumberTextFieldWithDropdown.module.scss';

const NumberTextFieldWithDropdown = forwardRef(
  (
    {
      className,
      dropdownProps = {
        name: 'dropdown',
        options: [],
      },
      ...rest
    }: NumberTextFieldWithDropdownProps,
    ref: ForwardedRef<NumberFormat<unknown>>,
  ) => {
    const { disabled, inputAppend } = rest;
    const localDropdownProps = { ...dropdownProps, disabled };

    return (
      <NumberTextField
        {...rest}
        ref={ref}
        className={className}
        wrapperClassName={styles.container}
        inputAppend={
          <>
            {inputAppend}
            <div className={styles.select}>
              <Select
                {...localDropdownProps}
                positionX="right"
                positionY="top"
                filterable={false}
                wrapperClassName={styles.selectWrapper}
                menuClassName={styles.selectMenu}
              />
            </div>
          </>
        }
      />
    );
  },
);

NumberTextFieldWithDropdown.displayName = 'NumberTextFieldWithDropdown';

export default NumberTextFieldWithDropdown;
