import { StorageAccessLevel } from '@aws-amplify/storage';
import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Button from 'js/design-system/Button/Button';
import { ButtonSize, ButtonVariant } from 'js/design-system/Button/types';
import { updateUser } from 'js/services/auth/actions';

import { LEVELS, s3Upload } from '../../components-legacy/common/S3Uploader';
import { Form, Input, AvatarInput } from '../../components-legacy/form';
import { required } from '../../components-legacy/form/validations';
import { currentUserSelector } from '../../selectors/currentUser';

const Account = () => {
  const navigate = useNavigate();
  const currentUser = useSelector(currentUserSelector);

  if (!currentUser) {
    return null;
  }

  const removeOldAvatar = () => {
    if (currentUser.avatar) {
      import('@aws-amplify/storage').then(({ Storage }) =>
        Storage.remove(currentUser.avatar as string, {
          level: LEVELS.PUBLIC as StorageAccessLevel,
        }),
      );
    }
  };

  const handleSubmit = async (values: {
    firstName: string;
    lastName: string;
    jobTitle: string;
    avatar: string;
  }) => {
    const { firstName, lastName, jobTitle, avatar } = values;

    let newAvatar = '';

    if (avatar && avatar !== currentUser.avatar) {
      removeOldAvatar();
      const uploadedAvatar = await s3Upload(avatar, LEVELS.PUBLIC);
      if (uploadedAvatar) {
        newAvatar = uploadedAvatar.filename;
      }
    } else if (!avatar) {
      removeOldAvatar();
      newAvatar = '';
    } else {
      newAvatar = currentUser.avatar || '';
    }

    const success = await updateUser(firstName, lastName, jobTitle, newAvatar);
    if (success) {
      navigate('/');
    }
  };

  const renderActions = (disabled: boolean) => (
    <Button
      variant={ButtonVariant.Filled}
      size={ButtonSize.Medium}
      type="submit"
      className="w-100"
      disabled={disabled}
    >
      Update account
    </Button>
  );

  const initialValues = {
    avatar: currentUser.avatar || '',
    firstName: currentUser.firstName,
    lastName: currentUser.lastName,
    jobTitle: currentUser.jobTitle || '',
  };

  return (
    <Form onSubmit={handleSubmit} renderActions={renderActions} initialValues={initialValues}>
      <Row>
        <Col sm={8}>
          <Input
            label="First Name"
            data-testid="first-name"
            name="firstName"
            validate={(value) => required('Please provide your first name.', value)}
          />
          <Input
            label="Last Name"
            data-testid="last-name"
            name="lastName"
            validate={(value) => required('Please provide your last name.', value)}
          />
          <Input
            label="Job Title"
            data-testid="job-title"
            name="jobTitle"
            validate={(value) => required('Please provide your job title.', value)}
          />
        </Col>
        <Col sm={4}>
          <AvatarInput name="avatar" />
        </Col>
      </Row>
    </Form>
  );
};

export default Account;
