import { UserCircle } from '@phosphor-icons/react';
import classNames from 'classnames';
import { forwardRef, ReactNode } from 'react';
import { BreakPoint } from 'types/designSystem';

import ResponsiveContent from 'js/components-legacy/common/ResponsiveContent';
import Icon from 'js/design-system/Icon';
import Tooltip from 'js/design-system/Tooltip/Tooltip';

import styles from './EmployeesCount.module.scss';

interface EmployeesCountProps {
  count: number;
  breakpoint?: BreakPoint;
  showText?: boolean;
  showIcon?: boolean;
  className?: string;
  tooltip?: ReactNode;
}

const EmployeesCount = forwardRef<HTMLDivElement, EmployeesCountProps>(
  (
    {
      count,
      tooltip = 'Headcount',
      breakpoint = BreakPoint.lg,
      showText = false,
      showIcon = true,
      className,
    }: EmployeesCountProps,
    ref,
  ) => (
    <Tooltip content={tooltip}>
      <div ref={ref} className={classNames(styles.employeeCount, className)}>
        {showIcon && <Icon className={styles.employeeIcon} icon={UserCircle} size={20} />}
        <ResponsiveContent
          largeContent={
            showText
              ? `${count.toLocaleString()} ${count === 1 ? 'Employee' : 'Employees'}`
              : count.toLocaleString()
          }
          smallContent={count.toLocaleString()}
          breakpoint={breakpoint}
          data-testid="employee-count"
        />
      </div>
    </Tooltip>
  ),
);

EmployeesCount.displayName = 'EmployeesCount';

export default EmployeesCount;
