import ConnectedField, { ConnectedFieldProps } from 'js/design-system/Form/ConnectedField';
import { NumberTextFieldProps } from 'js/design-system/Form/TextField/NumberTextField/types';

import NumberTextField from './NumberTextField';

const ConnectedNumberTextField = (props: ConnectedFieldProps<NumberTextFieldProps>) => (
  <ConnectedField
    fieldType="input"
    renderField={(fieldProps: NumberTextFieldProps) => <NumberTextField {...fieldProps} />}
    {...props}
  />
);

export default ConnectedNumberTextField;
