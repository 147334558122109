import Avatar from 'js/design-system/Avatar/Avatar';
import Text from 'js/design-system/Text/Text';
import useAmplifyStorage from 'js/hooks/useAmplifyStorage';
import { getFormattedTimeAgo } from 'js/utils/formatters/dates';
import getUsername from 'js/utils/user-name';

import { ListCommentProps } from '../types';

import mainStyles from './ActivityLogList.module.scss';
import selfStyles from './ListComment.module.scss';

const styles = { ...mainStyles, ...selfStyles };

const ListComment = ({ comment, user, createdAt }: ListCommentProps) => {
  const name = getUsername(user);
  const avatarSrc = useAmplifyStorage(user?.avatar);
  const formattedCreatedAt = getFormattedTimeAgo(createdAt);

  return (
    <div className={styles.activity}>
      <div className={styles.activity__left}>
        <Avatar
          name={name}
          colorIndex={user.id}
          variant="small"
          className={styles.avatar}
          avatarSrc={avatarSrc}
        />
      </div>
      <div className={styles.activity__right}>
        <div className={styles.heading}>
          <Text variant="text-body-main-bold" Element="span">
            {name}
          </Text>
          {formattedCreatedAt && (
            <Text variant="text-misc-caption" color="platform-gray-500" Element="span">
              {formattedCreatedAt}
            </Text>
          )}
        </div>
        <div className={styles.text_comment}>{comment}</div>
      </div>
    </div>
  );
};

export default ListComment;
