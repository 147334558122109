import { batch } from 'react-redux';

import { trackEvent } from 'js/analytics';
import { alertError } from 'js/components-legacy/common/AlertMessage';
import { AppDispatch, Department, GetState, ResultOkResponse } from 'types';

import api from '../api/api';
import ACTIONS from '../constants/actions';
import { setIntialDepartmentFilters, setNewDepartmentFilters } from './scenarioView';
import { updateScenarioNumEmployees } from './scenarios';
import { disableSpinner, enableSpinner } from './spinners';

export const create =
  (scenarioId: number, department: Department) =>
  async (dispatch: AppDispatch, getState: GetState) => {
    const scenario = getState().scenarios.byId[scenarioId];
    const company = getState().companies.byId[scenario.companyId];
    const { success, data, error } = await api.post<Department>(
      `/scenarios/${scenarioId}/departments`,
      {
        params: { department },
      },
    );
    if (success) {
      dispatch({ type: ACTIONS.CREATE_DEPARTMENT, data });
      dispatch(setNewDepartmentFilters());
      trackEvent('general.add.department', { scenarioId, companyId: company?.id });
    } else {
      alertError(error || 'Failed to create department');
    }
  };

export const updateDepartmentNumEmployees =
  (departmentId: number, diff: number) => async (dispatch: AppDispatch, getState: GetState) => {
    const department = getState().departments.byId[departmentId];

    if (!department) return;

    dispatch(updateScenarioNumEmployees(department.scenarioId, diff));
  };

export const destroy = (id: number) => async (dispatch: AppDispatch, getState: GetState) => {
  dispatch(enableSpinner());
  const { success, error } = await api.delete<ResultOkResponse>(`/departments/${id}`);
  if (success) {
    const departmentEmployeeIds = getState().employees.allIds.filter(
      (employeeId) => getState().employees.byId[employeeId].departmentId === id,
    );

    batch(() => {
      dispatch({ type: ACTIONS.DELETE_DEPARTMENT, id });
      dispatch({ type: ACTIONS.DELETE_EMPLOYEES, ids: departmentEmployeeIds });
      dispatch(updateDepartmentNumEmployees(id, -departmentEmployeeIds.length));
    });
  } else {
    alertError(error || 'Failed to delete department');
  }
  dispatch(disableSpinner());
};

export const receiveAll = (data: Department[]) => ({
  data,
  type: ACTIONS.RECEIVE_DEPARTMENTS,
});

export const fetchDepartments = (scenarioId: number) => async (dispatch: AppDispatch) => {
  const { success, data, error } = await api.get<Department[]>(
    `/scenarios/${scenarioId}/departments`,
  );
  if (success) {
    dispatch(receiveAll(data));
    dispatch(setIntialDepartmentFilters());
  } else {
    alertError(error || 'Failed to fetch departments');
  }
};

export const update =
  (id: number, attrs: Partial<Department>, onSuccess = () => {}) =>
  async (dispatch: AppDispatch) => {
    const { success, data, error } = await api.put<Department>(`/departments/${id}`, {
      params: { department: attrs },
    });
    if (success) {
      dispatch({ type: ACTIONS.UPDATE_DEPARTMENT, data });
      onSuccess();
    } else {
      alertError(error || 'Failed to update department');
    }
  };
