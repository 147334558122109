import classnames from 'classnames';
import { ReactNode } from 'react';

import Button from 'js/design-system/Button/Button';
import { ButtonSize, ButtonVariant } from 'js/design-system/Button/types';

import styles from './Banner.module.scss';

interface BannerProps {
  onConfirm?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onDismiss: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  shown: boolean;
  title?: ReactNode;
  text?: ReactNode;
  cta?: string;
  dismissText?: string;
  image?: string;
  confirmButtonClassName?: string;
}

const Banner = ({
  shown,
  onConfirm = () => {},
  onDismiss,
  title,
  text,
  cta,
  image,
  dismissText = 'Remind me later',
  confirmButtonClassName,
}: BannerProps) => {
  if (!shown) {
    return null;
  }

  return (
    <div className={styles.bannerBorder}>
      <div className={styles.banner}>
        <div className={styles.leftContainer}>
          <div className={styles.textGroup}>
            <h4 className={styles.title}>{title}</h4>
            <div className={styles.text}>{text}</div>
          </div>
          <div className={styles.buttonGroup}>
            {!cta ? null : (
              <Button
                className={classnames(styles.confirmButton, confirmButtonClassName)}
                onClick={onConfirm}
                variant={ButtonVariant.Filled}
                size={ButtonSize.Medium}
              >
                {cta}
              </Button>
            )}
            <Button
              className={styles.cancelButton}
              onClick={onDismiss}
              variant={ButtonVariant.Ghost}
              size={ButtonSize.Medium}
            >
              {dismissText}
            </Button>
          </div>
        </div>
        <div className={styles.rightContainer}>
          <div className={styles.bannerImage}>
            <img src={image} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
