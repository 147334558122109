/* eslint-disable no-nested-ternary */
import { Navigate, Outlet } from 'react-router-dom';

import CompanyChannel from 'js/channels/CompanyChannel';
import { useTrackPurchase } from 'js/components-legacy/products/hooks/useTrackPurchase';
import { CompProviderCredChannel } from 'js/components/channels/CompProviderCredChannel';
import ReconnectHRISModal from 'js/components/hris/ReconnectHRISModal';
import NavBar from 'js/components/navbar/NavBar';
import WelcomeNewUser from 'js/components/onboarding/welcome/WelcomeNewUser';
import UnassignedLocationsModal from 'js/components/pay-strategy/geo-tier/UnassignedLocationsModal';
import ScenarioExternalSync from 'js/components/scenario/channels/ExternalSyncChannel';
import ScenarioWebsocketsConsumer from 'js/components/scenario/channels/ScenarioWebsocketsConsumer';
import GLOBALS from 'js/config/globals';
import useAppSelector from 'js/hooks/useAppSelector';
import useScenarioEntry from 'js/hooks/useScenarioEntry';

const MainScenarioPage = () => {
  const { scenario } = useScenarioEntry();
  const company = useAppSelector((state) => state.companies.byId[scenario?.companyId ?? 0]);

  useTrackPurchase(company?.productId);

  if (!scenario || !company) return null;

  return company.onboardStatus === GLOBALS.onboardStatus.started ? (
    <Navigate to={`/onboarding/${company.id}/employees`} />
  ) : company.onboardStatus === GLOBALS.onboardStatus.employeesAdded ? (
    <Navigate to={`/onboarding/scenarios/${scenario.id}/benchmarking-workshop`} />
  ) : (
    <>
      <WelcomeNewUser companyId={scenario.companyId} />
      <ReconnectHRISModal companyId={scenario.companyId} />
      <NavBar companyId={scenario.companyId} />
      <Outlet />
      <CompanyChannel id={scenario.companyId} />
      <CompProviderCredChannel companyId={scenario.companyId} />

      <UnassignedLocationsModal scenarioId={scenario.id} />
      <ScenarioWebsocketsConsumer scenarioId={scenario.id} />

      <ScenarioExternalSync scenarioId={scenario.id} />
    </>
  );
};

export default MainScenarioPage;
