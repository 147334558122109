import { uniqBy } from 'ramda';

import { SuggestJobRole } from 'js/components/common/job-roles/types';
import { isNumber } from 'js/utils/value';
import { NestedFilterData, JobTypeLevel, JobLevel, JobRoleWithLevels } from 'types';

export const getLevelText = (
  jobLevel: string | number | null | undefined,
  { isExec = false, longFormat = false } = {},
) => {
  if (!jobLevel && jobLevel !== 0) return '';

  let prefix;
  if (longFormat) {
    prefix = isExec ? 'Exec ' : 'Level ';
  } else {
    prefix = isExec ? 'E' : 'L';
  }

  // TODO remove number type
  if (typeof jobLevel === 'number' || jobLevel.match(/^[0-9]+$/)) return `${prefix}${jobLevel}`;

  return jobLevel;
};

export const getLevelRangeText = (jobLevels: JobLevel[]) => {
  if (jobLevels.length > 1) {
    return `Levels ${jobLevels[0].name} - ${jobLevels[jobLevels.length - 1].name}`;
  }
  return getLevelText(jobLevels[0]?.name, { longFormat: true });
};

export const toLevelFilterOptions = (jobTypeLevels: JobTypeLevel[]): NestedFilterData | null => {
  if (!jobTypeLevels.length) return null;

  return {
    ...jobTypeLevels.reduce(
      (jobTypeOptions: NestedFilterData, { jobLevel, jobType }: JobTypeLevel) => {
        const { nestedOptions } = jobTypeOptions[jobType.name] || {};

        jobTypeOptions[jobType.name] = {
          label: jobType.name,
          expanded: true,
          selected: true,
          nestedOptions: {
            ...(nestedOptions || {}),
            [jobLevel.id]: {
              label: getLevelText(jobLevel.name, { longFormat: true }),
              selected: true,
            },
          },
        };

        return jobTypeOptions;
      },
      {
        'All Levels': {
          label: 'All Levels',
          selected: true,
        },
      },
    ),
    'Custom Role': {
      label: 'Custom Role',
      selected: true,
    },
  };
};

export const findJobLevel = (
  jobLevels: JobLevel[] | undefined,
  query: string | number | null | undefined,
  field: keyof JobLevel,
) => {
  if ((!query && query !== 0) || !jobLevels || !jobLevels.length) return null;

  return jobLevels.find((jobLevel) => jobLevel[field] === query) || null;
};

export const isValidJobRoleLevel = (
  jobLevel: JobLevel,
  jobRole: { validLevels?: number[] | null } | null,
) =>
  isNumber(jobLevel.scenarioId) ||
  !jobRole?.validLevels ||
  jobRole.validLevels.includes(Number(jobLevel.name));

export const isValidJobLevelId = (jobLevels: JobLevel[], jobLevelId: number | null) =>
  typeof jobLevelId === 'number' && jobLevels.some((jobLevel) => jobLevel.id === jobLevelId);

export const getJobLevels = (
  jobTypeLevels?: JobTypeLevel[] | null,
  jobRole?: JobRoleWithLevels | SuggestJobRole | null,
) => {
  if (!jobTypeLevels) return [];

  if (!jobRole)
    return uniqBy(({ jobLevel }) => jobLevel.id, jobTypeLevels).map(({ jobLevel }) => jobLevel);

  return jobTypeLevels.reduce((jobLevels: JobLevel[], jobTypeLevel) => {
    const { jobLevel, jobType, description } = jobTypeLevel;

    if (jobType.id === jobRole.jobType?.id && isValidJobRoleLevel(jobLevel, jobRole)) {
      return [...jobLevels, { ...jobLevel, description }];
    }

    return jobLevels;
  }, []);
};

export const getJobRoleDefaultLevel = (
  jobLevels?: JobLevel[] | null,
  jobRole?: JobRoleWithLevels | SuggestJobRole | null,
) => {
  if (!jobLevels || !jobLevels.length || !jobRole?.validLevels) return null;

  const rawMiddleIndex = (jobRole.validLevels.length - 1) / 2;
  // default level: non-exec = 3, exec = rounded middle level
  const middleIndex =
    jobRole.validLevels.length >= 6 ? Math.floor(rawMiddleIndex) : Math.round(rawMiddleIndex);
  const newJobLevelName = String(jobRole.validLevels[middleIndex]);
  return findJobLevel(jobLevels, newJobLevelName, 'name') || jobLevels[0];
};
