import Auth from '@aws-amplify/auth';
import { useEffect, useState } from 'react';

import SettingsHeader from 'js/components/common/SettingsHeader/SettingsHeader';
import Container from 'js/components/layout/Container';
import Section from 'js/components/layout/Section';
import NavBar from 'js/components/navbar/NavBar';
import { CognitoUserSession } from 'types';

import Account from './Account';
import ChangePassword from './ChangePassword';

import styles from './Profile.module.scss';

const Profile = () => {
  const [showChangePassword, setShowChangePassword] = useState(false);
  useEffect(() => {
    Auth.currentSession().then((session) => {
      const { idToken } = session as CognitoUserSession;
      const payload = idToken?.payload;
      // don't show Change Password feature to oauth users
      if (payload && !payload.identities?.find((i) => i.providerType === 'Google')) {
        setShowChangePassword(true);
      }
    });
  });
  return (
    <>
      <NavBar />
      <Container
        header={<SettingsHeader heading="My Account" HeadingElement="h1" />}
        headerClassName={styles.heading}
        contentClassName={styles.container}
      >
        <Section header="Update profile">
          <Account />
        </Section>
        {showChangePassword ? (
          <Section header="Change Password">
            <ChangePassword />
          </Section>
        ) : null}
      </Container>
    </>
  );
};

export default Profile;
