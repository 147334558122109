import { CurrencyCode } from 'js/utils/formatters/currency';
import {
  Ethnicity,
  Gender,
  JobRoleWithLevels,
  Compensation,
  Scenario,
  Location,
  UserPermission,
  NestedFilterData,
  Department,
  Employee,
  RangePerformance,
  CompensationType,
  Approval,
  ApproverResponse,
  GenericApprovalFormData,
  OffersSettings,
  JobLevel,
} from 'types';

export enum SponsorshipStatus {
  H1B = 'H1B',
  H1BTransfer = 'H1B Transfer',
  TN = 'TN',
  O1 = 'O1',
  L1 = 'L1',
  F1 = 'F1',
  NotRequired = 'Not Required',
}

export enum SourceOfHire {
  Referral = 'Referral',
  Agency = 'Agency',
  InboundCandidate = 'Inbound Candidate',
  RecruiterOutreach = 'Recruiter Outreach',
  Other = 'Other',
}

export enum OfferStatus {
  Draft = 'draft',
  AwaitingApproval = 'awaiting_approval',
  Approved = 'approved',
  Denied = 'denied',
  SentToCandidate = 'sent_to_candidate',
  Accepted = 'accepted',
  Rejected = 'rejected',
  Closed = 'closed',
}

export enum QuantityUnit {
  Number = 'number',
  Percentage = 'percentage',
}

export enum OfferEquityUnit {
  EquityOwnershipPercent = CompensationType.EquityOwnershipPercent,
  EquityShareCount = CompensationType.EquityShareCount,
}

export type Offer = {
  id: number;
  uuid: string;
  scenarioId: number;
  departmentId: number | null;
  status: OfferStatus;
  jobTitle: string;
  jobRoleId: number;
  jobLevelId: number | null;
  jobLevel: JobLevel | null;
  locationId: number;
  locationName: string | null;
  currencyCode: CurrencyCode;
  baseSalary: number;
  equityOwnershipPercent: number | null;
  equityShareCount: number | null;
  salaryOte: number;
  annualBonusCash: number | null;
  annualBonusRatio: number | null;
  signOnBonus: number | null;
  payMix: number | null;
  payRangeId: number | null;
  benchmarkId: number | null;
  managerId: number;
  estimatedStartDate: string | null;
  startDate: string | null;
  closingDate: string | null;
  updatedAt: string | null;
  salaryReason: string | null;
  equityReason: string | null;
  notes: string;
  archived: boolean;
  cashRangeLevel: RangePerformance | null;
  equityRangeLevel: RangePerformance | null;
  candidate: Candidate | null;
  jobRole: JobRoleWithLevels;
  createdBy: Pick<UserPermission, 'avatar' | 'fullName' | 'id'>;
  location?: Pick<Location, 'id' | 'name'> | null;
  manager?: Pick<Employee, 'id' | 'name'>;
  department?: Pick<Department, 'id' | 'name'>;
  commentsCount?: number;
  approval: Approval | null;
  notificationRecipients?: Pick<
    NotificationRecipient,
    'id' | 'ownableId' | 'ownableType' | 'email'
  >[];
  preservedCompensation: Compensation;
  preservedExchangeRate: number;
  preservedSimilarCandidates: SimilarOffer[];
  preservedHireZones: Pick<
    OffersSettings,
    'paySalaryMin' | 'paySalaryMax' | 'payEquityMin' | 'payEquityMax'
  >;
};

export type Candidate = {
  id: number;
  name: string;
  email: string;
  linkedinUrl: string | null;
  currentCompany: string | null;
  gender: Gender | null;
  ethnicity: Ethnicity | null;
  sponsorshipStatus: string | null;
  sourceOfHire: string | null;
  atsUrl: string | null;
};

export type SimilarOffer = {
  id: number;
  type: string;
  name: string;
  decision: OfferStatus.Accepted | OfferStatus.Rejected | OfferStatus.Closed | null;
  baseSalary: number;
  ote: number;
  currencyCode: CurrencyCode | null;
  equity: number | null;
  startDate: string;
  baseSalaryPercentile: number | null;
  totalCashPercentile: number | null;
  equityPercentile: number | null;
  cashRangePosition: number | null;
  equityRangePosition: number | null;
  reason: string | null;
  exchangeRate?: number;
};

type NotificationRecipient = {
  id: number;
  notifiableId: number;
  notifiableType: string;
  ownableId: number;
  ownableType: string;
  email: string;
};

export type OfferFormData = Pick<
  Offer,
  | 'scenarioId'
  | 'departmentId'
  | 'jobTitle'
  | 'jobLevelId'
  | 'locationId'
  | 'locationName'
  | 'currencyCode'
  | 'baseSalary'
  | 'salaryOte'
  | 'equityOwnershipPercent'
  | 'equityShareCount'
  | 'annualBonusCash'
  | 'annualBonusRatio'
  | 'signOnBonus'
  | 'payRangeId'
  | 'benchmarkId'
  | 'managerId'
  | 'estimatedStartDate'
  | 'notes'
> & {
  candidateAttributes: Pick<
    Candidate,
    | 'name'
    | 'email'
    | 'linkedinUrl'
    | 'currentCompany'
    | 'gender'
    | 'ethnicity'
    | 'sponsorshipStatus'
    | 'sourceOfHire'
    | 'atsUrl'
  > | null;
} & {
  jobRole?: JobRoleWithLevels;
  payRangeGroupId?: number;
  compensation?: Compensation;
  similarOffers?: SimilarOffer[];
  jobRoleId: number;
  annualBonusUnit?: QuantityUnit;
  equityUnit?: OfferEquityUnit;
};

export type OfferGetStartedFormData = Pick<
  OfferFormData,
  'jobTitle' | 'jobLevelId' | 'locationId' | 'jobRoleId'
>;

export type NotificationRecipientFormData = { email: string };

export type SetApproversFormData = {
  approval: GenericApprovalFormData;
  notificationRecipients: NotificationRecipientFormData[];
};

export type OfferTableFieldProps = {
  offer: Offer;
  scenario?: Scenario;
};

export type OfferFilter = {
  roles: number[];
  levelJobTypes: NestedFilterData | null;
  status: string[];
  departments: (number | null)[];
  createdBy: number[];
  locations: number[];
};

export enum OfferSortBy {
  CandidateName = 'candidateName',
  JobTitle = 'jobTitle',
  Level = 'level',
  Cash = 'cash',
  Equity = 'equity',
  UpdatedAt = 'updatedAt',
  CreatedBy = 'createdBy',
  Status = 'status',
}

export enum OfferStatusTab {
  Open = 'open',
  Closed = 'closed',
  All = 'all',
  Archived = 'archived',
  Draft = 'draft',
}

type CandidateAction = {
  id: number;
  notes: string;
  reason: string;
  response: ApproverResponse | null;
  offerId: number;
};

export type CandidateRejectAndReviseFormData = Pick<CandidateAction, 'reason' | 'notes'>;

export type CloseOfferFormData = {
  closingDate: string;
  startDate: string;
};

export type ArchiveOfferFormData = {
  archived: boolean;
};

export enum CandidateExpSection {
  TopDetailsSection = 'TopDetailsSection',
  StrategyParagraphSection = 'StrategyParagraphSection',
  TermsSection = 'TermsSection',
  EquitySection = 'EquitySection',
  ValuationsSection = 'ValuationsSection',
  AboutUsSection = 'AboutUsSection',
  BenefitsSection = 'BenefitsSection',
}
