import { useQuery } from '@tanstack/react-query';
import { memo, useEffect, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';

import { showConfetti } from 'js/actions/confetti';
import {
  CompanyNotification,
  NotificationName,
  useDismissCompanyNotification,
} from 'js/api/companyNotifications';
import SyncBanner from 'js/components-legacy/common/SyncBanner';
import useAppDispatch from 'js/hooks/useAppDispatch';
import useAppSelector from 'js/hooks/useAppSelector';
import useNumberParams from 'js/hooks/useNumberParams';
import queries from 'js/queries';
import { getById as companySelector, getIdFromScenarioOrCompany } from 'js/selectors/companies';
import { currentUserSelector } from 'js/selectors/currentUser';
import { Company, User } from 'types';

const failureNotifications = [
  NotificationName.HrisSyncFailed,
  NotificationName.HrisSyncFailedToConnect,
  NotificationName.HrisSyncFailedToSendData,
];

const text = (
  notificationName: NotificationName,
  company: Company,
  dismissAction: (event: MouseEvent<HTMLElement>, linkTo: string) => void,
  user: User,
  currentScenarioId?: number,
) => {
  if (notificationName === NotificationName.HrisSyncInProgress) {
    return 'Your HRIS data is being synced.';
  }
  if (notificationName === NotificationName.HrisSyncInProgressSlow) {
    return 'Your HRIS data is currently being synced and is taking a little longer than usual. We will send an email notification when it is complete.';
  }
  if (notificationName === NotificationName.HrisSyncSucceeded) {
    const linkTo = `/scenarios/${company.baseScenarioId}`;
    return (
      <div>
        HRIS data synced!
        {currentScenarioId !== company.baseScenarioId && (
          <a
            href={linkTo}
            onClick={(event) => dismissAction(event, linkTo)}
          >{`View ${company?.companyName} Baseline`}</a>
        )}
      </div>
    );
  }

  if (failureNotifications.includes(notificationName)) {
    let subject;
    switch (notificationName) {
      case NotificationName.HrisSyncFailedToSendData:
        subject = 'Failed to sync data from HRIS';
        break;
      default:
        subject = 'Failed to connect to HRIS';
        break;
    }

    const urlParams = Object.entries({
      firstname: user.firstName,
      lastname: user.lastName,
      email: user.email,
      subject,
    })
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join('&');

    const linkTo = `https://help.opencomp.com/en/knowledge/kb-tickets/new?${urlParams}`;

    return (
      <div>
        <span>{subject}</span>
        <span> </span>
        <a
          href={linkTo}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(event) => dismissAction(event, linkTo)}
        >
          Click here to create a support ticket
        </a>
      </div>
    );
  }

  return '';
};

const variantFromNotification = (notification: CompanyNotification) => {
  if (notification.name === NotificationName.HrisSyncSucceeded) {
    return 'success';
  }

  if (failureNotifications.includes(notification.name)) {
    return 'failed';
  }

  const inProgressNotifications = [
    NotificationName.HrisSyncInProgress,
    NotificationName.HrisSyncInProgressSlow,
  ];

  if (inProgressNotifications.includes(notification.name)) {
    return 'in_progress';
  }

  return 'idle';
};

const HrisSyncBanner = ({ company, scenarioId }: { company: Company; scenarioId?: number }) => {
  const { data: notifications = [] } = useQuery(
    queries.companies.detail(company.id)._ctx.notifications,
  );
  const notification = notifications.find((n) => n.name.startsWith('hris_sync'));
  const dismiss = useDismissCompanyNotification(company.id);
  const currentUser = useAppSelector(currentUserSelector);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (notification?.name === NotificationName.HrisSyncSucceeded) {
      dispatch(showConfetti());
    }
  }, [dispatch, notification]);

  if (!notification || !company || !currentUser) {
    return null;
  }

  const viewSyncedScenario = (event: MouseEvent<HTMLElement>, linkTo: string) => {
    event.preventDefault();
    dismiss.mutate(notification.id);
    navigate(linkTo);
  };

  const variant = variantFromNotification(notification);

  return (
    <>
      <SyncBanner
        variant={variant}
        text={text(notification.name, company, viewSyncedScenario, currentUser as User, scenarioId)}
        cta="Dismiss"
        onCtaClick={() => dismiss.mutate(notification.id)}
      />
    </>
  );
};

const HrisSyncBannerWithCompanyId = () => {
  const { scenarioId, companyId: companyIdParam } = useNumberParams();
  const companyId = useAppSelector((state) =>
    getIdFromScenarioOrCompany(state, companyIdParam, scenarioId),
  );
  const company = useAppSelector((state) => companySelector(state, companyId));

  if (!company?.id) {
    return null;
  }

  return <HrisSyncBanner company={company} scenarioId={scenarioId} />;
};

export default memo(HrisSyncBannerWithCompanyId);
