import { Hash } from '@phosphor-icons/react';
import { useEffect, useState } from 'react';

import { hexCodeValidation } from 'js/components-legacy/form/validations';
import Icon from 'js/design-system/Icon';

import { FieldProps } from '../../ConnectedField';
import TextField from '../TextField';
import { TextFieldStyle, TextFieldValue } from '../types';

import styles from './ColorField.module.scss';

export interface ColorFieldProps extends FieldProps<TextFieldValue>, TextFieldStyle {
  withPicker?: boolean;
}

export const ColorField = ({ withPicker, onChange = () => {}, ...props }: ColorFieldProps) => {
  const [backgroundColor, setBackgroundColor] = useState<string | null>(null);

  useEffect(() => {
    setBackgroundColor(props.value as string);
  }, [props.value]);

  const onInputChange = (val: string) => {
    if (hexCodeValidation(val) === undefined) {
      setBackgroundColor(val);
      onChange(val);
    } else if (val === '') {
      setBackgroundColor(null);
      onChange(val);
    }
  };

  return (
    <TextField
      {...props}
      value={backgroundColor}
      inputPrepend={<Icon icon={Hash} size={16} color="platform-gray-700" />}
      inputAppend={
        <input
          type="color"
          value={`#${backgroundColor || 'dddddd'}`}
          disabled={!withPicker}
          className={styles.picker}
          onChange={(e) => onInputChange(e.target.value.substring(1))}
        />
      }
      onChange={(val) => onInputChange(val as string)}
    />
  );
};
