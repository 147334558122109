import { useQuery } from '@tanstack/react-query';

import image from 'img/banners/over-seat-limit.svg';
import { UpgradeClickAddSeatsEventDetails } from 'js/analytics/types/upgrade';
import useTrackEvent from 'js/analytics/useTrackEvent';
import TextButton from 'js/design-system/TextButton/TextButton';
import useChat from 'js/hooks/useChat';
import useUpgrade from 'js/hooks/useUpgrade';
import queries from 'js/queries';
import { Company, PlayBook } from 'types';

import Banner from './Banner';

import styles from './OverSeatLimitBanner.module.scss';

interface OverSeatLimitBannerProps {
  company: Company;
  dismiss(): void;
}

const OverSeatLimitBanner = ({ company, dismiss }: OverSeatLimitBannerProps) => {
  const { data: product } = useQuery(queries.products.detail(company.productId));
  const { startChat } = useChat();
  const { openUpgrade } = useUpgrade();
  const { trackEvent } = useTrackEvent<UpgradeClickAddSeatsEventDetails>();

  const handleClick = () => {
    trackEvent('upgrade.click.addSeats', { action: 'chat' });
    if (product?.pricingModel === 'per-seat' && (product?.billingPrice || 0) > 0) {
      startChat(PlayBook.AddSeats);
    } else {
      openUpgrade();
    }
  };

  const text =
    (product?.price || 0) > 0 && product?.pricingModel === 'per-seat'
      ? 'Add seats to view all'
      : 'Upgrade to view all';
  const cta =
    (product?.price || 0) > 0 && product?.pricingModel === 'per-seat' ? 'Add seats' : 'Upgrade';

  return (
    <Banner
      shown
      onConfirm={() => handleClick()}
      onDismiss={dismiss}
      title="You've reached your employee limit"
      text={
        <>
          Your company&apos;s baseline scenario is limited to {company.seatLimit} employees.&nbsp;
          {text}.&nbsp;
          <TextButton onClick={handleClick}>Learn more</TextButton>
        </>
      }
      cta={cta}
      image={image}
      confirmButtonClassName={styles.confirmButton}
    />
  );
};

export default OverSeatLimitBanner;
