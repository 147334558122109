import { Check } from '@phosphor-icons/react';

import Icon from 'js/design-system/Icon';

import styles from './PasswordHint.module.scss';

interface PasswordHintProps {
  meta: {
    valid?: boolean;
    dirty?: boolean;
    active?: boolean;
  };
}

const PasswordHint = (props: PasswordHintProps) => {
  const { valid, dirty, active } = props.meta;

  if (valid && dirty) {
    return (
      <span className={styles.checkedLabel}>
        <Icon className={styles.strongPasswordIcon} icon={Check} size={16} color="green-600" />
        Nice work. This is an excellent password.
      </span>
    );
  }

  if (active) {
    return (
      <span>
        Your password must contain at least 8 characters, one number, one uppercase, one lowercase,
        and one special case character
      </span>
    );
  }

  return null;
};

export default PasswordHint;
