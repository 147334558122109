import { createQueryKeys } from '@lukemorales/query-key-factory';

import { fetchCurrentUser, fetchCurrentUserRole } from 'js/api/currentUser';

const currentUser = createQueryKeys('currentUser', {
  detail: {
    queryKey: null,
    queryFn: fetchCurrentUser,
  },
  role: (companyId: number) => ({
    queryKey: ['role', companyId],
    queryFn: () => fetchCurrentUserRole(companyId),
  }),
});

export default currentUser;
