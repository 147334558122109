export * from './react-month-picker/picker';
export * from './user';
export * from './employeeUsers';
export * from './company';
export * from './benchmark';
export * from './connection';
export * from './scenario';
export * from './state';
export * from './permission';
export * from './jobArea';
export * from './jobFamily';
export * from './aws';
export * from './form';
export * from './dropdown';
export * from './compensation';
export * from './jobRole';
export * from './employee';
export * from './subscription';
export * from './invitation';
export * from './location';
export * from './stakeholder';
export * from './promoUtm';
export * from './api';
export * from './appliedGeoAdjustment';
export * from './apiClient';
export * from './userPermission';
export * from './department';
export * from './exchangeRate';
export * from './industrySector';
export * from './product';
export * from './payRange';
export * from './payRangeGroup';
export * from './payRangeJobFamily';
export * from './payRangeBand';
export * from './strategyTemplate';
export * from './approvalTemplate';
export * from './approval';
export * from './scenarioView';
export * from './banner';
export * from './filter';
export * from './compType';
export * from './validation';
export * from './elementSize';
export * from './offer';
export * from './totalRewards';
export * from './employeeAdjustment';
export * from './dataExport';
export * from './place';
export * from './googleMapLibraries';
export * from './dataDisplay';
export * from './jobLevel';
export * from './jobType';
export * from './address';
export * from './promotion';
export * from './rangeBuilder';
export * from './events';
export * from './hiring';
export * from './rangeDisplayType';
export * from './compCycle';
export * from './hubspot';
export * from './recharts';
export * from './benefit';
export * from './riskAssessment';
export * from './designSystem';
export * from './scenarioSettings';
export * from './performanceScore';
export * from './bulkUserInvite';
export * from './goal';
export * from './benchmarkQueryCredit';
export * from './letter';
export * from './scenarioSnapshot';

export type GenericObject = {
  [key: string]: string | number | boolean | unknown;
};

export type PrimitiveGenericObject = {
  [key: string]: string | number | boolean;
};

export type FullNestedPartial<T> = {
  [attr in keyof T]?: FullNestedPartial<T[attr]>;
};

export type PartiallyPartial<T, K extends keyof T> = {
  [attr in keyof T]?: attr extends K ? Partial<T[attr]> : T[attr];
};

export type PartialExcept<T, K extends keyof T> = Pick<T, K> & Partial<Omit<T, K>>;

export type Nullable<T, K extends keyof T = keyof T> = Omit<T, K> & { [R in K]: T[R] | null };

export type CamelToSnake<T extends string, P extends string = ''> = string extends T
  ? string
  : T extends `${infer C0}${infer R}`
  ? CamelToSnake<R, `${P}${C0 extends Lowercase<C0> ? '' : '_'}${Lowercase<C0>}`>
  : P;

export type ResultOkResponse = {
  result: 'ok';
};

export type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

export const keys = <T extends object>(object: T) => Object.keys(object) as (keyof T)[];

export const entries = <T extends object>(object: T) =>
  Object.entries(object) as [keyof T, T[keyof T]][];

export const truthyFilter = <T>(element: T | null | undefined): element is T => {
  return !!(element as T);
};
