import { CaretDown, CheckSquare, Icon as PhosphorIcon, Square } from '@phosphor-icons/react';
import classNames from 'classnames';
import { Fragment, useEffect, useRef, useState } from 'react';

import { useClickOutside } from 'js/hooks/useClickOutside';
import buttonize from 'js/utils/buttonize';
import { logError } from 'js/utils/logger';

import Icon from '../../Icon';
import FieldContainer from '../FieldContainer';
import { SelectOption, SelectProps } from '../Select/Select';
import { TextField } from '../TextField';

import styles from '../Select/Select.module.scss';
import multiselectStyles from './Multiselect.module.scss';

export interface MultiselectProps<T>
  extends Omit<SelectProps<T[]>, 'options'>,
    Pick<SelectProps<T>, 'options'> {
  topAlignedCheckbox?: boolean;
}

export const Multiselect = <T extends number | string>({
  options,
  placeholder = 'All',
  positionY = 'bottom',
  topAlignedCheckbox,
  helpText,
  ...connectedFieldProps
}: MultiselectProps<T>) => {
  const ref = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(new Set<T>());
  const [selectedLabels, setSelectedLabels] = useState<string[]>([]);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [displayMenuTop, setDisplayMenuTop] = useState(false);

  useEffect(() => {
    setDisplayMenuTop(positionY === 'top');
  }, [positionY]);

  useEffect(() => {
    const newSelectedLabels = options.filter((o) => selected.has(o.value)).map((o) => o.label);
    setSelectedLabels(newSelectedLabels);
  }, [options, selected]);

  useEffect(() => {
    setSelected(new Set(options.filter((o) => !!o.default).map((o) => o.value)));
    setFilteredOptions(options);
  }, [options]);

  const handleMenuOpen = () => {
    if (connectedFieldProps.disabled) return;

    if (positionY === 'auto' && ref.current) {
      try {
        const spaceBelow = window.innerHeight - ref.current.getBoundingClientRect().bottom;
        const requiredHeight = ref.current.offsetHeight * 2;

        // Show the menu on top only if there isn't enough space to show at least 2 options
        setDisplayMenuTop(spaceBelow < requiredHeight);
      } catch (err) {
        logError('Multiselect component positioning error', err);
      }
    }

    setOpen(!open);
  };

  const onItemClick = ({ value }: SelectOption<T>) => {
    if (selected.has(value)) selected.delete(value);
    else selected.add(value);

    setSelected(new Set(selected));

    if (connectedFieldProps.onChange) {
      connectedFieldProps.onChange(Array.from(selected));
    }
  };

  const filter = (val: string) => {
    const searchValue = val.trim().toLowerCase();
    const filteredOptions = options.filter((o) =>
      o.filterContext
        ? o.filterContext.toLowerCase().includes(searchValue)
        : o.label.toLowerCase().includes(searchValue),
    );
    setFilteredOptions(filteredOptions);
  };

  const InputIcon = ({ icon }: { icon: PhosphorIcon }) => (
    <Icon size={16} color="primary-gray-600" icon={icon} />
  );

  const onClickOutside = () => {
    setOpen(false);
    setFilteredOptions(options);
  };

  useClickOutside(ref, onClickOutside);

  return (
    <FieldContainer
      name={connectedFieldProps.name}
      label={connectedFieldProps.label}
      subLabel={connectedFieldProps.subLabel}
      labelPosition={connectedFieldProps.labelPosition}
      required={connectedFieldProps.required}
      labelClassName={connectedFieldProps.labelClassName}
    >
      <div
        {...buttonize(() => !connectedFieldProps.disabled && setOpen(true))}
        className={styles.selectContainer}
        ref={ref}
      >
        <TextField
          name={connectedFieldProps.name}
          placeholder={placeholder}
          value={open ? '' : selectedLabels.join(', ')}
          inputAppend={<InputIcon icon={CaretDown} />}
          className={classNames(styles.select, connectedFieldProps.wrapperClassName)}
          readonly={!open}
          onChange={(val) => filter(String(val))}
          validation={connectedFieldProps.validation}
          disabled={connectedFieldProps.disabled}
          helpText={helpText}
          onFocus={handleMenuOpen}
          ref={inputRef}
        />
        <div
          className={classNames(styles.menu, { [styles.open]: open, [styles.top]: displayMenuTop })}
          style={!displayMenuTop ? { top: inputRef.current?.offsetHeight } : {}}
        >
          <div className={styles.itemsContainer}>
            {filteredOptions.length === 0 ? (
              <div className={classNames(multiselectStyles.multiselectOption, styles.disabled)}>
                No results
              </div>
            ) : (
              filteredOptions.map((option) => {
                const optionSelected = selected.has(option.value);

                return (
                  <Fragment key={option.value}>
                    <div
                      key={String(option.value)}
                      className={classNames(
                        multiselectStyles.multiselectOption,
                        {
                          [multiselectStyles.topAligned]: topAlignedCheckbox,
                        },
                        {
                          [styles.disabled]: option.disabled,
                        },
                      )}
                      data-selected={optionSelected}
                      {...buttonize(() => onItemClick(option))}
                    >
                      <div className={multiselectStyles.iconContainer}>
                        {optionSelected ? (
                          <Icon icon={CheckSquare} size={20} color="oc-primary" weight="fill" />
                        ) : (
                          <Icon icon={Square} size={20} color="primary-gray-500" />
                        )}
                      </div>
                      {option.displayLabel || option.label}
                    </div>
                  </Fragment>
                );
              })
            )}
          </div>
        </div>
      </div>
    </FieldContainer>
  );
};
