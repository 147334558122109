import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import MainScenarioPage from 'js/components/scenario/pages/MainScenarioPage';
import RewardsViewerScenarioPage from 'js/components/total-rewards/pages/RewardsViewerScenarioPage';
import ACTIONS from 'js/constants/actions';
import useAppDispatch from 'js/hooks/useAppDispatch';
import useAppSelector from 'js/hooks/useAppSelector';
import useNumberParams from 'js/hooks/useNumberParams';
import { currentUserRoleSelector } from 'js/selectors/currentUser';
import { getById as scenarioSelector } from 'js/selectors/scenarios';
import { UserRole } from 'types';

const ScenarioPage = () => {
  const dispatch = useAppDispatch();
  const { scenarioId } = useNumberParams();
  const scenario = useAppSelector((state) => scenarioSelector(state, scenarioId));
  const currentUserRole = useAppSelector((state) =>
    currentUserRoleSelector(state, undefined, scenarioId),
  );

  useEffect(() => {
    if (!scenario) return;

    dispatch({
      type: ACTIONS.UPDATE_COMPANY_LANDING_SCENARIO,
      data: {
        id: scenario.companyId,
        landingScenarioId: scenario.id,
      },
    });
  }, [dispatch, scenario]);

  switch (currentUserRole) {
    case UserRole.RewardsViewer:
      return <RewardsViewerScenarioPage />;
    case null:
    case undefined:
      return <Outlet />;
    default:
      return <MainScenarioPage />;
  }
};

export default ScenarioPage;
