import { Goal } from 'types';

import api from './api';

export const fetchGoals = async () => {
  const { success, data } = await api.get<Goal[]>('/goals');

  if (success) {
    return data;
  }

  throw new Error('Failed to fetch goals.');
};

export const createUserGoals = async (userId: number, goalIds: number[]) => {
  const { success, data } = await api.post(`users/${userId}/user_goals`, {
    params: { goalIds },
  });

  if (success) {
    return data;
  }

  throw new Error('Failed to set goals.');
};
