import { Employee, PayRangeGroup } from 'types';

export enum PayRangeStatus {
  Active = 'active',
  Inactive = 'inactive',
  Draft = 'draft',
}

export enum PayStrategy {
  Geo = 'geo',
  RangePosition = 'position',
  RangeGroup = 'job_family',
  RangeWidth = 'width',
}

export enum PayRangeBuildType {
  Auto = 'auto',
  ImportedWithoutTiers = 'imported_without_tiers',
  ImportedWithTiers = 'imported_with_tiers',
}

export interface CurrentPayRangePosition {
  id: number;
  name: string;
  cashCurrent?: number | null;
  equityCurrent?: number | null;
}

export interface PayRangePosition {
  id: number;
  cashTarget: number;
  equityTarget: number;
  jobAreaId: number | null;
  jobAreaName: string | null;
}

export type PayRangeWidth = {
  id: number;
  cashWidth: number;
  equityWidth: number;
};

export type PayRangeWidthResponse = {
  id: number;
  cashWidth: number;
  equityWidth: number;
  payRangeId: number;
  createdAt: string;
  updatedAt: string;
};

export type PayRangePositionResponse = Pick<PayRangePosition, 'id'>;

export type PayRangeImportFile = {
  id: number;
  fileName: string;
  filePath: string;
  updatedAt: string;
};

export type PayRange = {
  id: number;
  status: PayRangeStatus;
  buildType: PayRangeBuildType;
  payRangePositions: PayRangePosition[];
  payRangeWidth: PayRangeWidth;
  payRangeGroups: PayRangeGroup[];
  payRangeImportFile: PayRangeImportFile | null;
  notes: string | null;
  createdById: number | null;
  payRangePositionTemplateId: number | null;
  payRangeWidthTemplateId: number | null;
  visitedSteps: PayStrategy[];
  smartGroup: boolean;
  scenarioId: number;
};

export enum StepStatus {
  WithData = 'with_data',
  NoData = 'no_data',
}

export type RangeStepStatus = {
  rangePositionStatus: StepStatus;
  rangeWidthStatus: StepStatus;
};

export type RangePreviewsResponse = {
  employees: Employee[];
  adjustedEmployees: Employee[];
};

export type ImportRangeParams = {
  importFileS3Path: string;
  importFileFilename: string;
  importFileOriginalFilename: string;
};

export type ImportRangeGroupingParams = Omit<ImportRangeParams, 'importFileOriginalFilename'>;

export type RangeGroupingWarning = {
  jobFamily: string;
  jobRoles: string[];
  rangeGroups: string[];
};

export type ImportRangeGroupingResponse = {
  payRange: PayRange;
  warnings: RangeGroupingWarning[];
};
