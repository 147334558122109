import { createQueryKeys } from '@lukemorales/query-key-factory';

import { fetchBenchmarks } from 'js/api/benchmarks';

const jobRoles = createQueryKeys('jobRoles', {
  detail: (id: number) => ({
    queryKey: [id],
    contextQueries: {
      benchmarks: {
        queryKey: null,
        queryFn: () => fetchBenchmarks({ jobRoleId: id }),
      },
    },
  }),
});

export default jobRoles;
