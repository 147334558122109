import { useQuery } from '@tanstack/react-query';

import useNumberParams from 'js/hooks/useNumberParams';
import queries from 'js/queries';
import { calculatePercentComplete } from 'js/services/benchmarkService';
import { getIncludedInTotalsEmployees } from 'js/services/employeeFilterService';

const useVerifiedEmployees = () => {
  const { scenarioId } = useNumberParams();
  const { data: employees = [] } = useQuery(queries.scenarios.detail(scenarioId)._ctx.employees());
  const verifiedEmployeesPercent = calculatePercentComplete(
    getIncludedInTotalsEmployees(employees),
  );
  const areAllEmployeesVerified = verifiedEmployeesPercent === 100;

  return { verifiedEmployeesPercent, areAllEmployeesVerified };
};

export default useVerifiedEmployees;
