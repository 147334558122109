import { CheckCircle, WarningCircle } from '@phosphor-icons/react';

import { JobLevel } from 'types';

import { Consideration, Spread } from '../types';

export const useConsiderations = (
  jobLevels: JobLevel[],
  spread: Spread,
  atRiskEmployees: number[],
) => {
  const attritionWarning = atRiskEmployees.length > 0;
  const levelWarning = !!(jobLevels.length > 2);
  const spreadWarning = !!spread.warning;
  const allClear = !attritionWarning && !levelWarning && !spreadWarning;
  const numConsiderations = Number(attritionWarning) + Number(levelWarning) + Number(spreadWarning);

  const CONSIDERATIONS: Record<string, Consideration> = {
    warn: {
      icon: WarningCircle,
      iconColor: 'yellow-800',
      ellipseColor: 'yellow-300',
      tooltipText: `${numConsiderations} consideration${
        numConsiderations === 1 ? '' : 's'
      } found for this range. See details below.`,
    },
    ok: {
      icon: CheckCircle,
      iconColor: 'green-800',
      ellipseColor: 'green-300',
      tooltipText: 'This range is ready to post. See details below.',
    },
  };

  return {
    attritionWarning,
    levelWarning,
    spreadWarning,
    allClear,
    numConsiderations,
    CONSIDERATIONS,
  };
};
