import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { ScenarioFilterEventDetails } from 'js/analytics/types/scenario';
import useTrackEvent from 'js/analytics/useTrackEvent';
import { getEventSourceValueByCurrentRoute } from 'js/analytics/utils';
import RawJobLevelFilter from 'js/components/common/filter/RawJobLevelFilter';
import ACTIONS from 'js/constants/actions';
import useAppDispatch from 'js/hooks/useAppDispatch';
import scenarioViewSelector from 'js/selectors/scenarioView';
import { NestedFilterData, Scenario } from 'types';

import styles from './LevelFilter.module.scss';

interface LevelFilterProps {
  scenario: Scenario;
}
const LevelFilter = ({ scenario }: LevelFilterProps) => {
  const dispatch = useAppDispatch();
  const { levelFilter } = useSelector(scenarioViewSelector);
  const { trackEvent } = useTrackEvent<ScenarioFilterEventDetails>();

  const handleInit = useCallback(
    (options: NestedFilterData) => {
      dispatch({ type: ACTIONS.SET_INITIAL_JOB_TYPE_LEVELS_FILTERS, options });
    },
    [dispatch],
  );

  const handleFilter = (values: NestedFilterData) => {
    dispatch({ type: ACTIONS.SET_EMPLOYEE_FILTER, filterType: 'level', filter: values });
    const source = getEventSourceValueByCurrentRoute();
    trackEvent('scenario.filter', { type: 'level', source });
  };

  return (
    <RawJobLevelFilter
      scenarioId={scenario.id}
      onInit={handleInit}
      onChange={handleFilter}
      value={levelFilter}
      className={styles.filter}
    />
  );
};

export default LevelFilter;
