export enum BannerPlace {
  EmployeeEdit = 'employeeEdit',
  EmployeeShelf = 'employeeShelf',
}

export type Banner = {
  [bannerPlace in BannerPlace]?: boolean;
} & {
  id: string;
  category: string;
  message: string;
  verifyFields?: string[];
  animate: string;
  variant: string;
};
