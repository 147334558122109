import classNames from 'classnames';
import { forwardRef } from 'react';

import styles from './BaseTable.module.scss';

export interface BaseTableProps {
  /**
   * Composed node children (`TableRowGroup`, `TableRow`, `TableCell`)
   */
  children: React.ReactNode;
  className?: string;
}

const BaseTable = forwardRef(
  ({ children, className, ...rest }: BaseTableProps, ref: React.ForwardedRef<HTMLDivElement>) => (
    <div role="table" className={classNames(styles.container, className)} {...rest} ref={ref}>
      {children}
    </div>
  ),
);

BaseTable.displayName = 'BaseTable';

export default BaseTable;
