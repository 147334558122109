import { camelCase } from 'change-case';

import { getMean, getMedian } from 'js/utils/numbers';
import {
  CamelToSnake,
  CashDisplayType,
  CompensationTab,
  CompensationType,
  CompensationTypeDetailsMapping,
  Employee,
  EquityDisplayType,
  Nullable,
  Scenario,
} from 'types';

import { formatOwnershipPercent, formatShareCount, formatCash } from './formatter';

export const COMPENSATION_TAB_TO_COMPENSATION_TYPE = {
  [CompensationTab.Cash]: CompensationType.SalaryOte,
  [CompensationTab.Equity]: CompensationType.EquityOwnershipPercent,
};

const COMPENSATION_TYPE_DETAILS: CompensationTypeDetailsMapping = {
  [CompensationType.SalaryOte]: {
    name: 'Salary / OTE',
    longName: 'Salary / OTE',
    rangeKey: 'cash',
    adjustmentKey: 'cashAdjustment',
    convertDisplayValue: (value: number) => value,
    formatter: formatCash,
    midPointHandler: getMean,
    midPointDescription: 'mean',
    cash: true,
  },
  [CompensationType.BaseSalary]: {
    name: 'Base Salary',
    longName: 'Base Salary',
    valueKey: 'baseSalaryUsd',
    localValueKey: 'baseSalary',
    percentileKey: 'baseSalaryPercentile',
    targetValueKey: 'baseSalaryTargetUsd',
    targetPercentileKey: 'baseSalaryTargetPercentile',
    percentilesKey: 'baseSalaryPercentiles',
    rangeKey: 'cash',
    adjustmentKey: 'cashAdjustment',
    convertDisplayValue: (value: number) => value,
    formatter: formatCash,
    midPointHandler: getMean,
    midPointDescription: 'mean',
    cash: true,
  },
  [CompensationType.TotalCash]: {
    name: 'Total Cash',
    longName: 'Total Cash',
    valueKey: 'totalCashUsd',
    localValueKey: 'totalCash',
    percentileKey: 'totalCashPercentile',
    targetValueKey: 'totalCashTargetUsd',
    targetPercentileKey: 'totalCashTargetPercentile',
    percentilesKey: 'totalCashPercentiles',
    rangeKey: 'cash',
    adjustmentKey: 'cashAdjustment',
    convertDisplayValue: (value: number) => value,
    formatter: formatCash,
    midPointHandler: getMean,
    midPointDescription: 'mean',
    cash: true,
  },
  [CompensationType.EquityOwnershipPercent]: {
    name: 'Equity',
    longName: 'Equity as Percent',
    valueKey: 'ownershipPercentage',
    localValueKey: null,
    percentileKey: 'equityPercentile',
    targetValueKey: 'ownershipPercentageTarget',
    targetPercentileKey: 'equityTargetPercentile',
    percentilesKey: 'ownershipPercentPercentiles',
    rangeKey: 'equityOwnershipPercent',
    adjustmentKey: 'equityAdjustment',
    convertDisplayValue: (value: number) => value,
    formatter: formatOwnershipPercent,
    midPointHandler: getMedian,
    midPointDescription: 'median',
    cash: false,
  },
  [CompensationType.EquityShareCount]: {
    name: 'Equity',
    longName: 'Equity as Shares',
    valueKey: 'shareCount',
    localValueKey: null,
    percentileKey: 'equityPercentile',
    targetValueKey: 'shareCountTarget',
    targetPercentileKey: 'equityTargetPercentile',
    percentilesKey: 'shareCountPercentiles',
    rangeKey: 'equityShareCount',
    adjustmentKey: 'equityAdjustment',
    convertDisplayValue: (value: number, scenario?: Scenario) =>
      scenario?.outstandingShares ? (value * scenario.outstandingShares) / 100 : 0,
    formatter: formatShareCount,
    midPointHandler: getMedian,
    midPointDescription: 'median',
    cash: false,
  },
};

export const getCompType = (
  type: CompensationType | CamelToSnake<CompensationType> | null | undefined,
  employee?: Partial<Nullable<Employee>>,
): CompensationType => {
  if (!type) return CompensationType.SalaryOte;

  const camelCasedType = camelCase(type) as CompensationType;

  if (employee && camelCasedType === CompensationType.SalaryOte) {
    return employee.jobRole?.commissionBased
      ? CompensationType.TotalCash
      : CompensationType.BaseSalary;
  }

  return camelCasedType;
};

export const getCashCompType = (cashDisplayType?: CashDisplayType | null) => {
  switch (cashDisplayType) {
    case CashDisplayType.BaseSalary:
      return CompensationType.BaseSalary;
    case CashDisplayType.TotalCash:
      return CompensationType.TotalCash;
    case CashDisplayType.SalaryOte:
      return CompensationType.SalaryOte;
    default:
      return null;
  }
};

export const getEquityCompType = (equityDisplayType?: EquityDisplayType | null) => {
  switch (equityDisplayType) {
    case EquityDisplayType.PercentOwnership:
      return CompensationType.EquityOwnershipPercent;
    case EquityDisplayType.NumOfShares:
      return CompensationType.EquityShareCount;
    default:
      return null;
  }
};

export const getCompTypeDetails = (
  type: CompensationType | null | undefined,
  employee?: Partial<Nullable<Employee>>,
) => {
  const compType = getCompType(type, employee);

  return COMPENSATION_TYPE_DETAILS[compType];
};

export const getCostToTarget = (value?: number | null, targetValue?: number | null) => {
  if ((!value && value !== 0) || (!targetValue && targetValue !== 0)) return 0;

  const difference = targetValue - value;

  return difference < 0 ? 0 : difference;
};

export const getDifferenceToRange = (
  value?: number | null,
  min?: number | null,
  max?: number | null,
) => {
  if ((!value && value !== 0) || (!min && min !== 0) || (!max && max !== 0)) return null;

  if (value < min) return min - value;

  if (value > max) return value - max;

  return 0;
};

export const calcValuation = ({
  outstandingShares,
  preferredPrice,
}: {
  outstandingShares: number;
  preferredPrice: number | null;
}) => {
  return outstandingShares * (preferredPrice || 0);
};

export const calcTargetBonus = (
  baseSalary: number,
  bonusCash: number | null | undefined,
  bonusRatio: number | null | undefined,
) => (bonusRatio ? bonusRatio * baseSalary : bonusCash);
