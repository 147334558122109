import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ScenarioFilterEventDetails } from 'js/analytics/types/scenario';
import useTrackEvent from 'js/analytics/useTrackEvent';
import { getEventSourceValueByCurrentRoute } from 'js/analytics/utils';
import RawDepartmentFilter from 'js/components/common/filter/RawDepartmentFilter';
import ACTIONS from 'js/constants/actions';
import useAppSelector from 'js/hooks/useAppSelector';
import scenarioViewSelector from 'js/selectors/scenarioView';
import { toSelectOptions } from 'js/utils/dropdowns';

import styles from './DepartmentFilter.module.scss';

const DepartmentFilter = () => {
  const dispatch = useDispatch();
  const { departmentFilter } = useSelector(scenarioViewSelector);
  const departmentsIds = useAppSelector((state) => state.departments.allIds);
  const departmentsById = useAppSelector((state) => state.departments.byId);
  const { trackEvent } = useTrackEvent<ScenarioFilterEventDetails>();

  const filterDepartment = (values: number[]) => {
    dispatch({ type: ACTIONS.SET_EMPLOYEE_FILTER, filterType: 'department', filter: values });
    const source = getEventSourceValueByCurrentRoute();
    trackEvent('scenario.filter', { type: 'department', source });
  };

  const options = useMemo(
    () => toSelectOptions(departmentsIds, (id: number) => departmentsById[id].name),
    [departmentsIds, departmentsById],
  );

  return (
    <RawDepartmentFilter
      options={options}
      onChange={filterDepartment}
      value={departmentFilter}
      className={styles.filter}
    />
  );
};

export default DepartmentFilter;
