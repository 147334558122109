import { fetching, receiveAll } from 'js/actions/scenarioEmployeesUsers';
import api from 'js/api/api';
import { statusByScenarioId } from 'js/selectors/scenarioEmployeesUsers';

export const fetchScenarioEmployeesUsers = (scenarioId) => async (dispatch, getState) => {
  if (statusByScenarioId(getState(), scenarioId) !== 'idle') {
    return;
  }
  dispatch(fetching(scenarioId));
  const { success, data } = await api.get(`/scenarios/${scenarioId}/employees_users`);
  if (success) {
    dispatch(receiveAll(scenarioId, data));
  }
};
