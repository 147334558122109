import ConnectedField, { ConnectedFieldProps } from 'js/design-system/Form/ConnectedField';
import { DateFieldProps } from 'js/design-system/Form/TextField/DateField/types';

import DateField from './DateField';

const ConnectedDateField = (props: ConnectedFieldProps<DateFieldProps>) => (
  <ConnectedField
    fieldType="input"
    renderField={(fieldProps: DateFieldProps) => <DateField {...fieldProps} />}
    {...props}
  />
);

export default ConnectedDateField;
