export type PerformanceColor =
  | 'performance-lowest'
  | 'performance-low'
  | 'performance-mid'
  | 'performance-high'
  | 'performance-highest';

export type SubscriptionColor =
  | 'subscription-team-primary'
  | 'subscription-team-secondary'
  | 'subscription-premium-primary'
  | 'subscription-premium-secondary'
  | 'subscription-professional-primary'
  | 'subscription-professional-secondary';

type TokenizedColor =
  | 'oc-primary'
  | 'text-light'
  | 'upgrade'
  | PerformanceColor
  | SubscriptionColor;

type PaletteColor =
  | 'platform-blue-100'
  | 'platform-blue-200'
  | 'platform-blue-300'
  | 'platform-blue-400'
  | 'platform-blue-500'
  | 'platform-blue-600'
  | 'platform-blue-700'
  | 'platform-blue-800'
  | 'platform-blue-900'
  | 'platform-gray-100'
  | 'platform-gray-200'
  | 'platform-gray-300'
  | 'platform-gray-400'
  | 'platform-gray-500'
  | 'platform-gray-600'
  | 'platform-gray-700'
  | 'platform-gray-800'
  | 'platform-gray-900'
  | 'red-100'
  | 'red-200'
  | 'red-300'
  | 'red-400'
  | 'red-500'
  | 'red-600'
  | 'red-700'
  | 'red-800'
  | 'red-900'
  | 'orange-100'
  | 'orange-200'
  | 'orange-300'
  | 'orange-400'
  | 'orange-500'
  | 'orange-600'
  | 'orange-700'
  | 'orange-800'
  | 'orange-900'
  | 'yellow-100'
  | 'yellow-200'
  | 'yellow-300'
  | 'yellow-400'
  | 'yellow-500'
  | 'yellow-600'
  | 'yellow-700'
  | 'yellow-800'
  | 'yellow-900'
  | 'green-100'
  | 'green-200'
  | 'green-300'
  | 'green-400'
  | 'green-500'
  | 'green-600'
  | 'green-700'
  | 'green-800'
  | 'green-900'
  | 'mint-100'
  | 'mint-200'
  | 'mint-300'
  | 'mint-400'
  | 'mint-500'
  | 'mint-600'
  | 'mint-700'
  | 'mint-800'
  | 'mint-900'
  | 'teal-100'
  | 'teal-200'
  | 'teal-300'
  | 'teal-400'
  | 'teal-500'
  | 'teal-600'
  | 'teal-700'
  | 'teal-800'
  | 'teal-900'
  | 'cyan-100'
  | 'cyan-200'
  | 'cyan-300'
  | 'cyan-400'
  | 'cyan-500'
  | 'cyan-600'
  | 'cyan-700'
  | 'cyan-800'
  | 'cyan-900'
  | 'indigo-100'
  | 'indigo-200'
  | 'indigo-300'
  | 'indigo-400'
  | 'indigo-500'
  | 'indigo-600'
  | 'indigo-700'
  | 'indigo-800'
  | 'indigo-900'
  | 'purple-100'
  | 'purple-200'
  | 'purple-300'
  | 'purple-400'
  | 'purple-500'
  | 'purple-600'
  | 'purple-700'
  | 'purple-800'
  | 'purple-900'
  | 'pink-100'
  | 'pink-200'
  | 'pink-300'
  | 'pink-400'
  | 'pink-500'
  | 'pink-600'
  | 'pink-700'
  | 'pink-800'
  | 'pink-900'
  | 'brown-100'
  | 'brown-200'
  | 'brown-300'
  | 'brown-400'
  | 'brown-500'
  | 'brown-600'
  | 'brown-700'
  | 'brown-800'
  | 'brown-900'
  | 'gray-100'
  | 'gray-200'
  | 'gray-300'
  | 'gray-400'
  | 'gray-500'
  | 'gray-600'
  | 'gray-700'
  | 'gray-800'
  | 'gray-900'
  | 'neutral-full-white'
  | 'neutral-full-black';

type OldPaletteColor =
  | 'primary-gray-25'
  | 'primary-gray-50'
  | 'primary-gray-100'
  | 'primary-gray-200'
  | 'primary-gray-300'
  | 'primary-gray-400'
  | 'primary-gray-500'
  | 'primary-gray-600'
  | 'primary-gray-700'
  | 'primary-gray-800'
  | 'primary-gray-900'
  | 'primary-green-500'
  | 'primary-green-600'
  | 'primary-green-700'
  | 'primary-green-800'
  | 'secondary-blue-300'
  | 'secondary-blue-500'
  | 'secondary-blue-600'
  | 'secondary-blue-700'
  | 'secondary-purple-300'
  | 'secondary-purple-400'
  | 'secondary-purple-600'
  | 'secondary-purple-700'
  | 'system-yellow-600'
  | 'system-orange-500'
  | 'system-orange-600'
  | 'system-indigo-600'
  | 'system-pink-600'
  | 'system-red-300'
  | 'system-red-500'
  | 'system-red-600'
  | 'system-teal-500'
  | 'system-teal-600'
  | 'system-mint-500'
  | 'system-cyan-600'
  | 'system-green-500'
  | 'system-success-600'
  | 'performance-ketchup-600'
  | 'performance-ketchup-700'
  | 'performance-ketchup-800'
  | 'performance-mustard-600'
  | 'performance-jewel-600';

export type Color = TokenizedColor | PaletteColor | OldPaletteColor | 'currentColor';

export type Spacing =
  | 'spacing-0'
  | 'spacing-1'
  | 'spacing-2'
  | 'spacing-4'
  | 'spacing-6'
  | 'spacing-8'
  | 'spacing-10'
  | 'spacing-12'
  | 'spacing-14'
  | 'spacing-16'
  | 'spacing-24'
  | 'spacing-32'
  | 'spacing-40'
  | 'spacing-48'
  | 'spacing-56'
  | 'spacing-64'
  | 'spacing-72'
  | 'spacing-80'
  | 'spacing-88'
  | 'spacing-96'
  | 'spacing-104'
  | 'spacing-112'
  | 'spacing-120'
  | 'spacing-128'
  | 'spacing-136'
  | 'spacing-144'
  | 'spacing-152';

export enum BreakPoint {
  xl = 'xl',
  lg = 'lg',
  md = 'md',
  sm = 'sm',
}
