import ACTIONS from '../constants/actions';

export const scrollToField = (field: string) => ({
  type: ACTIONS.EMPLOYEE_FORM_SCROLL_TO_FIELD,
  data: { scrollToField: field },
});

export const clearScrollToField = () => ({
  type: ACTIONS.EMPLOYEE_FORM_SCROLL_TO_FIELD,
  data: { scrollToField: undefined },
});
