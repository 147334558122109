import { AnyAction } from 'redux';

import { ExchangeRate, FetchStatus } from 'types';

import ACTIONS from '../constants/actions';

type ExchangeRateState = {
  byBenchmarkId: Record<number, Record<string, number>>;
  error?: string;
  status: FetchStatus;
};

const initialState: ExchangeRateState = {
  byBenchmarkId: {},
  status: FetchStatus.Idle,
};

export default (
  state: ExchangeRateState = initialState,
  action: AnyAction = { type: null },
): ExchangeRateState => {
  switch (action.type) {
    case ACTIONS.FETCHING_EXCHANGE_RATES:
      return { ...state, status: FetchStatus.Loading };
    case ACTIONS.RECEIVE_EXCHANGE_RATES:
      return {
        ...state,
        byBenchmarkId: {
          ...state.byBenchmarkId,
          [action.benchmarkId]: action.data.reduce(
            (map: Record<string, number>, obj: ExchangeRate) => {
              map[obj.currencyTo] = Number(obj.exchangeRate);
              return map;
            },
            {},
          ),
        },
        status: FetchStatus.Succeeded,
      };
    default:
      return state;
  }
};
