import { useState } from 'react';
import { Link } from 'react-router-dom';

import image from 'img/banners/equity.svg';
import { EquityConnectOpenEventDetails } from 'js/analytics/types/equity';
import useTrackEvent from 'js/analytics/useTrackEvent';
import ConnectEquityModal from 'js/components/equity/ConnectEquityModal';
import GLOBALS from 'js/config/globals';

import Banner from './Banner';

import styles from './Equity.module.scss';

interface EquityBannerProps {
  companyId: number;
  dismiss(): void;
}

const EquityBanner = ({ companyId, dismiss }: EquityBannerProps) => {
  const [showEquityModal, setShowEquityModal] = useState(false);
  const { trackEvent } = useTrackEvent<EquityConnectOpenEventDetails>();

  const onConfirm = () => {
    trackEvent('equity.connect.open', { source: 'banner' });
    setShowEquityModal(true);
  };

  return (
    <>
      <ConnectEquityModal
        companyId={companyId}
        show={showEquityModal}
        onHide={() => setShowEquityModal(false)}
      />
      <Banner
        shown
        onConfirm={onConfirm}
        onDismiss={dismiss}
        title="Connect your equity provider today!"
        text={
          <>
            <div>Sync your employee data between OpenComp and Pulley or Carta.</div>
            <div>
              Don&apos;t have access to your equity system?&nbsp;
              <Link
                className={styles.inviteLink}
                to={`/companies/${companyId}/users?role=${GLOBALS.roles.administrator}`}
                onClick={() => trackEvent('equity.connect.invite')}
              >
                Invite a coworker
              </Link>
              to help you get connected.
            </div>
          </>
        }
        cta="Connect Equity"
        image={image}
        confirmButtonClassName={styles.confirmButton}
      />
    </>
  );
};

export default EquityBanner;
