import JobLevelOption from 'js/components/common/level/JobLevelOption';
import { getLevelText } from 'js/services/levelService';
import { isNumber } from 'js/utils/value';
import { JobLevel } from 'types';

export const getLevelOptions = (
  jobLevels: JobLevel[] | undefined,
  {
    defaultJobLevelId,
    selectedOptionLongFormat = true,
    showOptionDescription = true,
  }: {
    defaultJobLevelId?: number | null;
    selectedOptionLongFormat?: boolean;
    showOptionDescription?: boolean;
  } = {},
) => {
  if (!jobLevels) return [];

  return jobLevels.map((jobLevel) => ({
    value: jobLevel.id,
    label: getLevelText(jobLevel.name, { longFormat: selectedOptionLongFormat }),
    ...(isNumber(defaultJobLevelId) ? { default: defaultJobLevelId === jobLevel.id } : {}),
    ...(showOptionDescription ? { displayLabel: <JobLevelOption jobLevel={jobLevel} /> } : {}),
  }));
};
