import colors from 'css/_colors.scss';
import { ElementType, forwardRef } from 'react';
import { Color } from 'types/designSystem';

export const sizes = [16, 20, 24, 32, 48, 56, 72] as const;
export type Size = (typeof sizes)[number];

export const weights = ['thin', 'light', 'regular', 'bold', 'fill', 'duotone'] as const;
export type Weight = (typeof weights)[number];

interface Props {
  className?: string;
  icon: ElementType;
  size?: Size;
  weight?: Weight;
  color?: Color;
}

export const defaultWeight: Weight = 'regular';
export const defaultSize: Size = 24;

const PhosphorIcon = forwardRef(
  ({ className, icon: Icon, size = defaultSize, weight = defaultWeight, color }: Props, ref) => {
    return (
      <Icon
        ref={ref}
        className={className}
        size={size}
        weight={weight}
        color={color && colors[color]}
      />
    );
  },
);

PhosphorIcon.displayName = 'Icon';

export default PhosphorIcon;
