import { batch } from 'react-redux';

import { fetchUserPermissions } from 'js/actions/permissions';
import { trackEvent } from 'js/analytics';
import { ScenarioCopyEventDetails, ScenarioImportEventDetails } from 'js/analytics/types/scenario';
import { ShareInviteUserEventDetails, ShareRevokeUserEventDetails } from 'js/analytics/types/share';
import api from 'js/api/api';
import { alertError, alertInfo } from 'js/components-legacy/common/AlertMessage';
import ACTIONS from 'js/constants/actions';
import { parseCSVErrors } from 'js/utils/string-format';
import {
  ApiOnFailure,
  ApiOnSuccess,
  AppDispatch,
  CashDisplayType,
  FetchStatus,
  GetState,
  InviteResponse,
  keys,
  ResultOkResponse,
  RevokeResponse,
  Scenario,
  ScenarioFormData,
  ScenarioImportResponseError,
  InviteScenarioUserFormData,
  InviteStatus,
  UserPermission,
  EquityDisplayType,
} from 'types';

import { forcedFetchOne as forcedFetchCompany } from './companies';
import { disableSpinner, enableSpinner } from './spinners';

export const receiveScenario = (scenario: Scenario) => (dispatch: AppDispatch) => {
  batch(() => {
    const cashDisplayTypeKey = keys(CashDisplayType).find(
      (key) => CashDisplayType[key] === scenario.cashDisplayType,
    );
    const cashDisplayType = cashDisplayTypeKey
      ? CashDisplayType[cashDisplayTypeKey]
      : CashDisplayType.SalaryOte;

    dispatch({
      type: ACTIONS.SET_CASH_DISPLAY_TYPE,
      data: cashDisplayType,
    });
    dispatch({
      type: ACTIONS.SET_EQUITY_DISPLAY_TYPE,
      data: scenario.equityDisplayType || EquityDisplayType.NumOfShares,
    });
    dispatch({
      type: ACTIONS.SET_HIDDEN_EMPLOYEE_TYPES,
      data: scenario.hiddenEmployeesBit,
    });
  });
};

export const postCopy =
  (scenario: Scenario) => async (dispatch: AppDispatch, getState: GetState) => {
    const company = getState().companies.byId[scenario.companyId];

    batch(() => {
      dispatch(receiveScenario(scenario));
      dispatch({
        type: ACTIONS.CREATE_SCENARIO,
        data: scenario,
      });
      dispatch({
        type: ACTIONS.RECEIVE_COMPANY,
        data: { id: company.id, numScenarios: company.numScenarios + 1 },
      });
      dispatch({
        type: ACTIONS.RECEIVE_DEPARTMENTS,
        data: [],
      });
    });

    trackEvent<ScenarioCopyEventDetails>('scenario.copy', {
      scenarioId: scenario.id,
      companyId: company.id,
    });
  };

export const create =
  (
    companyId: number,
    formData: ScenarioFormData,
    setImportErrors: (errors: string[]) => void = () => {},
  ) =>
  async (dispatch: AppDispatch, getState: GetState) => {
    dispatch(enableSpinner());

    const {
      success,
      data: scenario,
      error,
    } = await api.post<Scenario, ScenarioImportResponseError>(`/companies/${companyId}/scenarios`, {
      params: { scenario: formData },
    });

    if (success) {
      const company = getState().companies.byId[scenario.companyId];

      batch(() => {
        dispatch(receiveScenario(scenario));
        dispatch({
          type: ACTIONS.CREATE_SCENARIO,
          data: scenario,
        });
        dispatch({
          type: ACTIONS.RECEIVE_COMPANY,
          data: { id: company.id, numScenarios: company.numScenarios + 1 },
        });
        dispatch({
          type: ACTIONS.RECEIVE_DEPARTMENTS,
          data: [],
        });
      });
    } else if (typeof error !== 'string') {
      const { csvDataErrors, parsingError } = error;
      setImportErrors(parseCSVErrors(csvDataErrors));
      alertError(parsingError || error || 'Failed to create scenario');
    }

    dispatch(disableSpinner());
  };

export const destroy = (id: number) => async (dispatch: AppDispatch, getState: GetState) => {
  dispatch(enableSpinner());

  const { success, error } = await api.delete<ResultOkResponse>(`/scenarios/${id}`);
  if (success) {
    const { companyId } = getState().scenarios.byId[id];
    const company = getState().companies.byId[companyId];
    const scenarioIds = getState().scenarios.allIds;
    const user = getState().userInfo.data;
    const landingScenarioId =
      company.landingScenarioId !== id
        ? company.landingScenarioId
        : scenarioIds.find((scenarioId) => scenarioId !== id);

    batch(() => {
      if (user) dispatch(fetchUserPermissions(user.id));
      dispatch({ type: ACTIONS.DELETE_SCENARIO, id });
      dispatch({
        type: ACTIONS.RECEIVE_COMPANY,
        data: {
          id: company.id,
          numScenarios: company.numScenarios - 1,
          landingScenarioId,
        },
      });
    });
  } else {
    alertError(error || 'Failed to delete scenario');
  }

  dispatch(disableSpinner());
};

export const fetchAll =
  (companyId: number, forced = false) =>
  async (dispatch: AppDispatch, getState: GetState) => {
    const { indexStatus, companyId: oldId } = getState().scenarios;
    if (!forced && indexStatus === FetchStatus.Succeeded && oldId === companyId) return;

    dispatch({ type: ACTIONS.FETCHING_SCENARIOS });
    const { success, data, error } = await api.get<Scenario[]>(`/companies/${companyId}/scenarios`);
    if (success) {
      dispatch({
        type: ACTIONS.RECEIVE_SCENARIOS,
        data: {
          scenarios: data,
        },
        companyId,
      });
    } else {
      dispatch({
        type: ACTIONS.FAILED_SCENARIOS_FETCH,
        error,
      });
      alertError(error || 'Failed to load scenarios');
    }
  };

export const forcedFetchAll = (id: number) => fetchAll(id, true);

export const fetchOne =
  (id: number, onSuccess: ApiOnSuccess<Scenario> = () => {}, onFailure: ApiOnFailure = () => {}) =>
  async (dispatch: AppDispatch) => {
    if (!id) {
      return;
    }
    dispatch({ type: ACTIONS.FETCHING_SCENARIO, id });
    const { success, data: scenario, error } = await api.get<Scenario>(`/scenarios/${id}`);
    if (success) {
      batch(() => {
        dispatch({
          type: ACTIONS.RECEIVE_SCENARIO,
          data: scenario,
        });
        dispatch(receiveScenario(scenario));
      });

      onSuccess(scenario);
    } else {
      alertError(error || 'Failed to load scenario');
      onFailure(error);
    }
  };

export const update =
  (
    scenarioId: number,
    formData: ScenarioFormData,
    setImportErrors: (errors: string[]) => void = () => {},
    onSuccess = () => {},
    skipSpinner = false,
  ) =>
  async (dispatch: AppDispatch) => {
    if (!skipSpinner) dispatch(enableSpinner());
    dispatch({ type: ACTIONS.UPDATING_SCENARIO, id: scenarioId });

    const {
      success,
      data: scenario,
      error,
    } = await api.put<Scenario, ScenarioImportResponseError>(`/scenarios/${scenarioId}`, {
      params: { scenario: formData },
    });

    if (success) {
      const importEmployees = !!formData.importFileS3Path;
      batch(() => {
        dispatch({
          type: ACTIONS.RECEIVE_SCENARIO,
          data: scenario,
        });
        dispatch(receiveScenario(scenario));
        if (importEmployees) {
          trackEvent<ScenarioImportEventDetails>('scenario.import', {
            scenarioId: scenario.id,
            companyId: scenario.companyId,
          });
          dispatch(forcedFetchCompany(scenario.companyId)); // Re-fetch company to update seat limits, etc..
        }
      });
      onSuccess();
    } else if (typeof error !== 'string') {
      const { csvDataErrors, parsingError } = error;
      if (setImportErrors) setImportErrors(parseCSVErrors(csvDataErrors));
      alertError(parsingError || error || 'Failed to update scenario');
    }

    if (!skipSpinner) dispatch(disableSpinner());
  };

export const updateScenarioNumEmployees =
  (scenarioId: number, diff: number) => async (dispatch: AppDispatch, getState: GetState) => {
    const scenario = getState().scenarios.byId[scenarioId];

    if (!scenario) return;

    batch(() => {
      dispatch({
        type: ACTIONS.RECEIVE_SCENARIO,
        data: { id: scenario.id, numEmployees: scenario.numEmployees + diff },
      });
      // Refetch company when employee counts change
      // as this affects seat limits, etc...
      dispatch(forcedFetchCompany(scenario.companyId));
    });
  };

export const invite =
  (
    { email, role, scenarioId, employeeId }: InviteScenarioUserFormData,
    onInviteUser?: (user: UserPermission) => void,
  ) =>
  async (dispatch: AppDispatch, getState: GetState) => {
    const scenario = getState().scenarios.byId[scenarioId];
    const { success, data, error } = await api.post<InviteResponse>(
      `/scenarios/${scenarioId}/invite`,
      {
        params: {
          email,
          role,
          scenarioId,
          employeeId,
        },
      },
    );

    if (success) {
      trackEvent<ShareInviteUserEventDetails>('share.invite.user', {
        scenarioId: scenario.id,
        companyId: scenario.companyId,
      });

      // await will un-batch dispatch updates
      await dispatch({
        type: ACTIONS.INVITE_USER_TO_COMPANY,
        data: {
          user: data.user,
        },
      });
      dispatch({
        type: ACTIONS.RECEIVE_SCENARIO_USER,
        data: {
          scenarioId,
          user: data.user,
        },
      });

      alertInfo(data.message);
    } else {
      alertError(error || 'Failed to invite user');
    }

    dispatch({
      type: ACTIONS.UPDATE_INVITE_STATUS,
      data: InviteStatus.Idle,
    });

    if (success && onInviteUser) {
      onInviteUser(data.user);
    }
  };

export const revoke =
  (scenarioId: number, email: string) => async (dispatch: AppDispatch, getState: GetState) => {
    const scenario = getState().scenarios.byId[scenarioId];
    const { success, data, error } = await api.post<RevokeResponse>(
      `/scenarios/${scenarioId}/revoke`,
      {
        params: { email },
      },
    );

    if (success) {
      trackEvent<ShareRevokeUserEventDetails>('share.revoke.user', {
        scenarioId: scenario.id,
        companyId: scenario.companyId,
      });
      dispatch({
        type: ACTIONS.REVOKE_USER_FROM_SCENARIOS,
        data: {
          scenarioIds: [scenarioId],
          user: data.user,
        },
      });
      alertInfo('User has been removed from scenario successfully');
    } else {
      alertError(error || 'Failed to remove user');
    }

    dispatch({ type: ACTIONS.RESET_INVITE_STATUS });
    dispatch({ type: ACTIONS.UPDATE_REVOKE_STATUS, data: 'idle' });
  };
