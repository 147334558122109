export default {
  view: {
    exploreMarketData: 'Explore Market Data View',
    companyDetails: 'Company Details View',
    syncEmployees: 'Sync Employees View',
  },
  search: {
    exploreMarketData: 'Explore Market Data Search',
    payRanges: 'View Pay Ranges Search',
    getJobPost: 'Get Job Post Search',
  },
  copy: {
    jobPostClick: 'Copy Job Post Click',
  },
  click: {
    helpCenter: 'Help Center Click',
    trustCenter: 'Trust Center Click',
    help: 'Help Click',
    contactUs: 'Contact Us Click',
    openImperative: 'OPEN Imperative Click',
    signUpButton: 'Sign Up Button Click',
    signInButton: 'Sign In Button Click',
    continueWithGoogleOAuth: 'Redirect And Continue With Google OAuth',
  },
  add: {
    department: 'Department Add',
  },
};
