import classnames from 'classnames';
import { ElementType, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FormFieldProps } from 'types/form';

import Button from 'js/design-system/Button/Button';
import { ButtonSize, ButtonVariant } from 'js/design-system/Button/types';

import Field from './Field';

import styles from './FileInput.module.scss';

interface FileInputProps extends FormFieldProps<File> {
  noFileText?: string;
  chooseFileLabel?: React.ReactNode;
  errorLabel?: string;
  accept?: string;
  errors?: string[];
  fileBoxClassName?: string;
  uploadBtnClassName?: string;
  inputRef?: React.MutableRefObject<HTMLInputElement | null>;
  leftIcon?: ElementType;
  rightIcon?: ElementType;
  buttonSize?: ButtonSize;
}

const FileInput = ({
  disabled,
  noFileText = 'No file chosen',
  chooseFileLabel = 'Select file',
  errorLabel = 'Import errors',
  accept = '',
  errors = [],
  size,
  fileBoxClassName,
  uploadBtnClassName,
  inputRef,
  leftIcon,
  rightIcon,
  buttonSize = ButtonSize.Medium,
  ...rest
}: FileInputProps) => {
  const [boxText, setBoxText] = useState(noFileText);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const setFileInputRef = (element: HTMLInputElement) => {
    if (inputRef) inputRef.current = element;

    fileInputRef.current = element;
  };

  return (
    <Field {...rest}>
      {({ invalid, onChange }) => {
        const uploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
          const file = event.target.files && event.target.files[0];
          setBoxText(file?.name || noFileText);
          onChange(file);
        };

        return (
          <div className={styles.fieldWrapper}>
            <div>
              <Form.Control
                className={styles.rawInput}
                type="file"
                accept={accept}
                onChange={uploadFile}
                disabled={disabled}
                size={size}
                ref={setFileInputRef}
                data-testid="fileInput"
              />
              <Form.Label className={styles.fieldLabel}>
                <Form.Control
                  as="span"
                  isInvalid={invalid}
                  className={classnames(styles.fileBox, fileBoxClassName)}
                  disabled={disabled}
                  size={size}
                >
                  {boxText}
                </Form.Control>
                <Button
                  variant={ButtonVariant.Outlined}
                  size={buttonSize}
                  className={classnames(styles.uploadBtn, uploadBtnClassName)}
                  onClick={() => {
                    fileInputRef.current?.click();
                  }}
                  leftIcon={leftIcon}
                  rightIcon={rightIcon}
                >
                  {chooseFileLabel}
                </Button>
              </Form.Label>
            </div>
            {errors.length > 0 && (
              <div className={styles.errorContainer}>
                <div className={styles.errorLabel}>{errorLabel}</div>
                {errors.map((error) => (
                  <div key={error} className={styles.error}>
                    {error}
                  </div>
                ))}
              </div>
            )}
          </div>
        );
      }}
    </Field>
  );
};

export default FileInput;
