import { Fragment, useEffect, useRef } from 'react';

import { ActivityLogListProps } from '../types';
import ListActivity from './ListActivity';
import ListComment from './ListComment';

import styles from './ActivityLogList.module.scss';

const ActivityLogList = <TActivityAction extends string, TActivityDetail>({
  source,
}: ActivityLogListProps<TActivityAction, TActivityDetail>) => {
  const listRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (listRef.current && source.length) {
      listRef.current.scrollTo({ top: listRef.current.scrollHeight, behavior: 'smooth' });
    }
  }, [source]);

  return (
    <div ref={listRef} className={styles.list}>
      {source.map(({ type, data }, idx) => {
        const akey = `allc_${idx}`;
        return (
          <Fragment key={akey}>
            {type === 'comment' && <ListComment {...data} />}
            {type === 'activity' && <ListActivity {...data} />}
          </Fragment>
        );
      })}
    </div>
  );
};

export default ActivityLogList;
