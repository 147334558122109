import { AnyAction } from 'redux';

import { FetchStatus, InviteStatus, keys, UserId, UserPermission } from 'types';

import ACTIONS from '../constants/actions';

type CompanyUserState = {
  allUserIds: number[];
  byUserId: Record<UserId, UserPermission>;
  status: FetchStatus;
  inviteStatus: InviteStatus;
  companyId: number | null;
};

const initialState: CompanyUserState = {
  allUserIds: [],
  byUserId: {},
  status: FetchStatus.Idle,
  inviteStatus: InviteStatus.Idle,
  companyId: null,
};

export default (
  state: CompanyUserState = initialState,
  action: AnyAction = { type: null },
): CompanyUserState => {
  switch (action.type) {
    case ACTIONS.FETCHING_COMPANY_USERS:
      return { ...initialState, status: FetchStatus.Loading };
    case ACTIONS.RECEIVE_COMPANY_USERS: {
      const { users, companyId } = action.data;

      return {
        ...state,
        allUserIds: users.map((datum: UserPermission) => datum.userId),
        byUserId: users.reduce(
          (companyUsers: Record<UserId, UserPermission>, companyUser: UserPermission) => {
            companyUsers[companyUser.userId] = companyUser;
            return companyUsers;
          },
          {},
        ),
        status: FetchStatus.Succeeded,
        companyId,
      };
    }
    case ACTIONS.INVITE_USER_TO_COMPANY: {
      const { user } = action.data;
      const allUserIds = state.allUserIds.filter((id) => id !== user.userId);

      return {
        ...state,
        allUserIds: [...allUserIds, user.userId],
        byUserId: {
          ...state.byUserId,
          [user.userId]: user,
        },
        inviteStatus: InviteStatus.Succeeded,
      };
    }
    case ACTIONS.REVOKE_USER_FROM_COMPANY: {
      const { user } = action.data;
      return {
        ...state,
        allUserIds: state.allUserIds.filter((id) => id !== user.userId),
        byUserId: keys(state.byUserId).reduce(
          (byUserId: Record<UserId, UserPermission>, id: UserId) => {
            if (id !== user.userId) {
              byUserId[id] = state.byUserId[id];
            }
            return byUserId;
          },
          {},
        ),
      };
    }
    case ACTIONS.UPDATE_INVITE_STATUS:
      return {
        ...state,
        inviteStatus: action.data,
      };

    default:
      return state;
  }
};
