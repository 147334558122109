import { useQueryClient, useMutation } from '@tanstack/react-query';

import { connect, disconnect } from 'js/api/equity';
import { alertError } from 'js/components-legacy/common/AlertMessage';
import queries from 'js/queries';

export const useCreateEquityConnection = (companyId: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ code, provider }: { code: string; provider: string }) => connect(companyId, code, provider),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queries.companies.detail(companyId)._ctx.equityConnection);
        queryClient.invalidateQueries(
          queries.companies.detail(companyId)._ctx.lastEquityConnectionSync,
        );
      },
      onError: (error) => {
        alertError(error);
      },
    },
  );
};

export const useDeleteEquityConnection = (companyId: number) => {
  const queryClient = useQueryClient();
  return useMutation(() => disconnect(companyId), {
    onSuccess: () => {
      queryClient.invalidateQueries(queries.companies.detail(companyId)._ctx.equityConnection);
    },
    onError: (error) => {
      alertError(error);
    },
  });
};
