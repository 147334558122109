import { useQuery } from '@tanstack/react-query';
import { useCallback, useEffect } from 'react';

import { fetchOne as fetchScenario } from 'js/actions/scenarios';
import { UpgradeClickLinkEventDetails } from 'js/analytics/types/upgrade';
import useTrackEvent from 'js/analytics/useTrackEvent';
import useAppSelector from 'js/hooks/useAppSelector';
import useNumberParams from 'js/hooks/useNumberParams';
import useUpgrade from 'js/hooks/useUpgrade';
import queries from 'js/queries';
import { getById as companySelector } from 'js/selectors/companies';
import { getById as scenarioSelector } from 'js/selectors/scenarios';
import { ProductPermission, UpgradeEventSource } from 'types';

import useAppDispatch from './useAppDispatch';

const MAX_FREE_SCENARIO_COUNT = 3;

const useFeatureAccess = () => {
  const { scenarioId, companyId } = useNumberParams();
  const { trackEvent } = useTrackEvent<UpgradeClickLinkEventDetails>();
  const dispatch = useAppDispatch();

  const scenario = useAppSelector((state) => scenarioSelector(state, scenarioId));

  const company = useAppSelector((state) =>
    companySelector(state, companyId || scenario?.companyId),
  );
  const { data: product, isSuccess: useAccessReady } = useQuery({
    ...queries.products.detail(company?.productId as number),
    enabled: !!company,
  });

  useEffect(() => {
    if (scenario) return;
    dispatch(fetchScenario(scenarioId, () => {}));
  }, [dispatch, scenario, scenarioId]);

  const { openUpgrade } = useUpgrade();

  const hasFeatureAccess = useCallback(
    (feature: ProductPermission) => {
      if (!company || !product?.permissions) {
        return false;
      }
      if (feature === 'add_scenarios') {
        return (
          company.numScenarios < MAX_FREE_SCENARIO_COUNT || product.permissions.includes(feature)
        );
      }
      return product.permissions.includes(feature);
    },
    [company, product],
  );

  const openUpgradePrompt = useCallback(
    async (eventSource?: UpgradeEventSource) => {
      openUpgrade();
      if (eventSource) trackEvent('upgrade.click.link', { source: eventSource });
    },
    [openUpgrade, trackEvent],
  );

  const guardFeature = useCallback(
    async (
      proceedAction: () => unknown | Promise<unknown>,
      feature: ProductPermission,
      eventSource?: UpgradeEventSource,
    ) => {
      if (!useAccessReady) return;

      if (hasFeatureAccess(feature)) {
        await proceedAction();
      } else {
        openUpgradePrompt(eventSource);
      }
    },
    [useAccessReady, hasFeatureAccess, openUpgradePrompt],
  );

  return {
    useAccessReady,
    hasFeatureAccess,
    openUpgrade,
    guardFeature,
    openUpgradePrompt,
  };
};

export default useFeatureAccess;
