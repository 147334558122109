import { MagnifyingGlass } from '@phosphor-icons/react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import IconButton from 'js/design-system/IconButton/IconButton';
import Menu from 'js/design-system/Menu/Menu';
import queries from 'js/queries';

import { EmployeeSearchOption } from '../common/employee-search-list/EmployeeSearchOption';

import styles from './EmployeeSearchMenu.module.scss';
import navbarStyles from './NavBar.module.scss';

const EmployeeSearchMenu = ({ scenarioId }: { scenarioId: number }) => {
  const navigate = useNavigate();
  const { data: employees = [] } = useQuery(queries.scenarios.detail(scenarioId)._ctx.employees());

  const items = employees.map((e) => ({
    value: e.id,
    label: e.name,
    displayLabel: <EmployeeSearchOption employee={e} />,
  }));

  return (
    <Menu
      trigger={
        <IconButton icon={MagnifyingGlass} size="medium" className={navbarStyles.iconButton} />
      }
      menuItems={items}
      onSelect={(employeeId) =>
        navigate(`/scenarios/${scenarioId}/employees/${employeeId}`, {
          state: { source: 'SearchBox' },
        })
      }
      filterable
      placeholder="Search for an employee"
      menuClassName={styles.employeeMenu}
    />
  );
};

export default EmployeeSearchMenu;
