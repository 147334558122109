import { createQueryKeys } from '@lukemorales/query-key-factory';

import { fetchRangeGroupJobRoles } from 'js/api/pay-range-groups';
import { fetchRangeGroup } from 'js/api/ranges';

const payRangeGroups = createQueryKeys('payRangeGroups', {
  detail: (id: number) => ({
    queryKey: [id],
    queryFn: () => fetchRangeGroup(id),
    contextQueries: {
      jobRoles: {
        queryKey: null,
        queryFn: () => fetchRangeGroupJobRoles(id),
      },
    },
  }),
});

export default payRangeGroups;
