import ConnectedField, { ConnectedFieldProps } from 'js/design-system/Form/ConnectedField';
import { TextFieldProps } from 'js/design-system/Form/TextField/types';

import TextField from './TextField';

const ConnectedTextField = (props: ConnectedFieldProps<TextFieldProps>) => (
  <ConnectedField
    fieldType="input"
    renderField={(fieldProps: TextFieldProps) => <TextField {...fieldProps} />}
    {...props}
  />
);

export default ConnectedTextField;
