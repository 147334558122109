import { AppDispatch, FetchStatus, GetState, PayRangeBand } from 'types';

import api from '../api/api';
import ACTIONS from '../constants/actions';

const fetchRangeBands = (rangeGroupId: number) => async (dispatch: AppDispatch) => {
  dispatch({ type: ACTIONS.FETCHING_GROUP_OF_RANGE_BANDS, id: rangeGroupId });
  const { success, data, error } = await api.get<PayRangeBand[]>(
    `/pay_range_groups/${rangeGroupId}/pay_range_bands`,
  );
  if (success) {
    dispatch({
      type: ACTIONS.RECEIVE_GROUP_OF_RANGE_BANDS,
      id: rangeGroupId,
      data,
    });
  } else {
    dispatch({
      type: ACTIONS.FAIL_FETCH_GROUP_OF_RANGE_BANDS,
      error,
    });
  }
};

export const getRangeBands =
  (rangeGroupId: number) =>
  (dispatch: AppDispatch, getState: GetState): Promise<void> => {
    const { statusByGroupId } = getState().rangeBands;
    if (!statusByGroupId[rangeGroupId] || statusByGroupId[rangeGroupId] === FetchStatus.Idle) {
      return dispatch(fetchRangeBands(rangeGroupId));
    }

    return Promise.resolve();
  };

export const resetRangeBands = () => ({
  type: ACTIONS.RESET_RANGE_BANDS,
});
