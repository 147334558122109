import classNames from 'classnames';
import { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import logo from 'img/opencomp-logos/white.svg';
import { setNavbarSize } from 'js/actions/navbar';
import Banners from 'js/components/banners/navbar/Banners';
import HideForRole from 'js/components/user-permissions/HideForRole';
import RequireAbility from 'js/components/user-permissions/RequireAbility';
import useAccess from 'js/hooks/useAccess';
import useAppDispatch from 'js/hooks/useAppDispatch';
import useAppSelector from 'js/hooks/useAppSelector';
import useElementMutationSize from 'js/hooks/useElementMutationSize';
import useElementSize, { defaultElementSize } from 'js/hooks/useElementSize';
import useNumberParams from 'js/hooks/useNumberParams';
import { getById as companySelector } from 'js/selectors/companies';
import { getById as scenarioSelector } from 'js/selectors/scenarios';
import { companyUrl } from 'js/services/navigationService';
import { Ability, UserRole } from 'types';

import DataLookupMenu from './DataLookupMenu';
import EmployeeSearchMenu from './EmployeeSearchMenu';
import HelpButton from './HelpButton';
import ScenarioMenu from './ScenarioMenu';
import ScenarioSettings from './ScenarioSettings';
import ShareScenarioModal from './ShareScenarioModal';
import UserMenu from './UserMenu';

import styles from './NavBar.module.scss';

interface NavBarProps {
  companyId?: number;
}

const NavBar = ({ companyId }: NavBarProps) => {
  const { scenarioId } = useNumberParams();
  const dispatch = useAppDispatch();
  const scenario = useAppSelector((state) => scenarioSelector(state, scenarioId));
  const company = useAppSelector((state) => companySelector(state, companyId));
  const { upgradePageAccessible, settingScenarioAccessible } = useAccess({ company });

  const ref = useRef<HTMLDivElement | null>(null);
  const elementSize = useElementSize(ref);
  const elementMutationSize = useElementMutationSize(ref);

  useEffect(() => {
    dispatch(setNavbarSize(elementSize));

    return () => {
      dispatch(setNavbarSize(defaultElementSize));
    };
  }, [dispatch, elementSize]);

  useEffect(() => {
    dispatch(setNavbarSize(elementMutationSize));

    return () => {
      dispatch(setNavbarSize(defaultElementSize));
    };
  }, [dispatch, elementMutationSize]);

  return (
    <div ref={ref} className={styles.container}>
      <Banners />
      <div className={styles.navBar}>
        <div className={classNames('container-xl', styles.navContainer)}>
          <div className={styles.oneRow}>
            <Link
              to={companyUrl(company, { upgradePageAccessible: upgradePageAccessible || false })}
            >
              <img src={logo} alt="OpenComp logo" className={styles.logoMain} />
            </Link>

            {!scenarioId ? (
              <div className={styles.companyName}>{company?.companyName}</div>
            ) : (
              <div className={styles.scenarioPickerContainer}>
                <ScenarioMenu scenarioId={scenarioId} company={company} />
                {settingScenarioAccessible && <ScenarioSettings />}
                {companyId && scenario && (
                  <ShareScenarioModal scenario={scenario} companyId={companyId} />
                )}
              </div>
            )}
          </div>
          <div className={styles.oneRow}>
            <HideForRole roles={[UserRole.Approver, UserRole.Recruiter, UserRole.RewardsViewer]}>
              {scenarioId ? <EmployeeSearchMenu scenarioId={scenarioId} /> : null}
            </HideForRole>

            <HelpButton />

            <RequireAbility companyId={companyId} all={[Ability.CompulatorView]}>
              <DataLookupMenu />
            </RequireAbility>

            {company && <UserMenu company={company} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
