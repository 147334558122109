import { GeoTier } from 'js/api/geo-strategy';
import { CompensationType, Ethnicity, Gender, NestedFilterData } from 'types';

export enum ScenarioViewMode {
  Launchpad = 'launchpad',
  Market = 'market',
  Ranges = 'ranges',
  Hiring = 'hiring',
  Diversity = 'diversity',
  Offers = 'offers',
  CompCycles = 'compCycles',
  TotalRewards = 'totalRewards',
}

export enum MarketEmployeeSortBy {
  Name = 'name',
  JobTitle = 'jobTitle',
  Level = 'level',
  StartDate = 'startDate',
  Equity = 'equity',
  EquityPercentile = 'equityPercentile',
  Salary = 'salary',
  SalaryPercentile = 'salaryPercentile',
  Cash = 'cash',
  CashPercentile = 'cashPercentile',
}

export enum RangesEmployeeSortBy {
  Name = 'name',
  JobTitle = 'jobTitle',
  Level = 'level',
  StartDate = 'startDate',
  Equity = 'equity',
  EquityRangePosition = 'equityRangePosition',
  SalaryOte = 'salaryOte',
  SalaryOteRangePosition = 'salaryOteRangePosition',
}

export type EmployeeFilters = {
  department: number[];
  level: NestedFilterData | null;
  ethnicity: Ethnicity[];
  gender: Gender[];
  rangeGroup: number[];
  midPercentile: {
    type: CompensationType | null;
    midPercentile: number;
  } | null;
  percentileGroup: {
    type: CompensationType | null;
    percentileGroup: number;
  } | null;
  midRangePosition: {
    type: CompensationType | null;
    midRangePosition: number;
  } | null;
  geoTier: GeoTier | null;
};
