import classNames from 'classnames';
import { useContext, useMemo } from 'react';

import { getFormattedTimeAgo } from 'js/utils/formatters/dates';

import ActivityContext from '../ActivityContext/Context';
import { ListActivityProps } from '../types';

import mainStyles from './ActivityLogList.module.scss';
import selfStyles from './ListActivity.module.scss';

const styles = { ...mainStyles, ...selfStyles };

const ListActivity = <TActivityAction extends string, TActivityDetail>({
  action,
  createdAt,
  ...detail
}: ListActivityProps<TActivityAction, TActivityDetail>) => {
  const { templates: ActivityTemplates } = useContext(ActivityContext);
  const { detail: activityDetail }: any = detail;

  const display = useMemo(() => {
    const activity = ActivityTemplates[action];
    const date = getFormattedTimeAgo(createdAt) || '...';

    return {
      primaryContent: activity?.primaryTemplate({ ...detail, date }) || null,
      secondaryContent: activity?.secondaryTemplate
        ? activity.secondaryTemplate({ ...detail, date })
        : null,
      icon: activity?.icon,
    };
  }, [action, createdAt, detail, ActivityTemplates]);

  return (
    <div className={styles.activity}>
      <div className={styles.activity__content}>
        <div className={styles.activity__primaryContent}>
          <div className={classNames(styles.waypoint, styles[activityDetail?.type])}>
            {display?.icon || <div className={styles.waypoint__dot} />}
          </div>
          <div>{display.primaryContent}</div>
        </div>
        <div className={styles.activity__secondaryContent}>{display.secondaryContent}</div>
      </div>
    </div>
  );
};

export default ListActivity;
