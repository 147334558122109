import { useEffect } from 'react';

import { snackbar } from 'js/actions/snackbars';
import useAppDispatch from 'js/hooks/useAppDispatch';
import { logError } from 'js/utils/logger';

const ReleaseDetector = () => {
  const pollingInterval = 60 * 1000;
  const releaseVersion = process.env.REACT_APP_RELEASE_VERSION;
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!releaseVersion) return undefined;

    const intervalId = setInterval(async () => {
      try {
        const res = await fetch(`/version.json?cb=${Date.now()}`);
        const { version } = await res.json();
        if (version !== releaseVersion) {
          clearInterval(intervalId);
          dispatch(
            snackbar({
              message: 'Update available!',
              actionText: 'REFRESH',
              autoClose: false,
              actionHandler: () => {
                window.location.reload();
              },
            }),
          );
        }
      } catch (error) {
        logError('Failed to fetch version.json', error);
      }
    }, pollingInterval);

    return () => clearInterval(intervalId);
  }, [pollingInterval, releaseVersion, dispatch]);

  return <></>;
};

export default ReleaseDetector;
