import { formatError } from 'js/components-legacy/common/AlertMessage';
import { Segment, SegmentGroup } from 'types';

import api from './api';

export const fetchSegmentGroups = async () => {
  const { success, data, error } = await api.get<SegmentGroup[]>('/segment_groups');
  if (success) {
    return data;
  }

  throw new Error(formatError(error) || 'Failed to fetch segment groups');
};

export const fetchCompanySegmentGroups = async (companyId: number) => {
  const { success, data, error } = await api.get<SegmentGroup[]>(
    `/companies/${companyId}/segment_groups`,
  );
  if (success) {
    return data;
  }

  throw new Error(formatError(error) || 'Failed to fetch company segment groups');
};

export const fetchBenchmarkSegmentGroups = async (benchmarkId: number) => {
  const { success, data, error } = await api.get<SegmentGroup[]>(
    `/benchmarks/${benchmarkId}/segment_groups`,
  );
  if (success) {
    return data;
  }

  throw new Error(formatError(error) || 'Failed to fetch benchmark segment groups');
};

export const fetchSegmentGroup = async (id: number) => {
  const { success, data, error } = await api.get<SegmentGroup>(`/segment_groups/${id}`);
  if (success) {
    return data;
  }

  throw new Error(formatError(error) || 'Failed to fetch segment group');
};

export type CreateSegmentGroupParams = Pick<
  SegmentGroup,
  'companyId' | 'description' | 'name' | 'segmentableType'
> & {
  segmentsAttributes: CreateSegmentParams[];
};
export type CreateSegmentParams = Pick<Segment, 'name'>;

export const createSegmentGroup = async (params: CreateSegmentGroupParams) => {
  const { success, data, error } = await api.post<SegmentGroup>('/segment_groups', { params });
  if (success && data) {
    return data;
  }

  throw new Error(formatError(error) || 'Failed to create segment group');
};

export type UpdateSegmentGroupParams = Pick<
  SegmentGroup,
  'companyId' | 'description' | 'name' | 'segmentableType'
> & {
  segmentsAttributes: UpdateSegmentParams[];
};
export type UpdateSegmentParams = Pick<Segment, 'name'> & {
  _destroy?: boolean;
  id?: number;
};

export const updateSegmentGroup = async (
  segmentGroupId: number,
  params: UpdateSegmentGroupParams,
) => {
  const { success, data, error } = await api.put<SegmentGroup>(
    `/segment_groups/${segmentGroupId}`,
    { params },
  );
  if (success && data) {
    return data;
  }

  throw new Error(formatError(error) || 'Failed to update segment group');
};
