import { useForm, useFormState } from 'react-final-form';

import { required } from 'js/components-legacy/form/validations';
import PayRangeJobRoleSuggest from 'js/components/common/job-roles/PayRangeJobRoleSuggest';
import { SuggestJobRole } from 'js/components/common/job-roles/types';
import { JobRoleHelpText } from 'js/components/data-lookup/ranges/common/JobRoleHelpText';
import ConnectedField from 'js/design-system/Form/ConnectedField';
import { Scenario } from 'types';

interface JobRoleProps {
  scenario: Scenario;
  payRangeId?: number;
}

export const JobRole = ({ scenario, payRangeId }: JobRoleProps) => {
  const form = useForm();
  const defaultValue = useFormState().initialValues.jobRole?.name;

  const handleChange = async (jobRole: SuggestJobRole | null) => {
    form.change('jobRoleId', jobRole?.id);
    form.change('jobRoleName', jobRole?.name);
    form.change('jobRole', jobRole);
  };

  return (
    <ConnectedField
      fieldType="select"
      name="jobRoleId"
      validate={(val) => required('This is a required field', val)}
      required
      renderField={(fieldProps) => (
        <PayRangeJobRoleSuggest
          {...fieldProps}
          scenario={scenario}
          payRangeId={payRangeId}
          onChange={handleChange}
          positionX="right"
          clearable
          helpText={<JobRoleHelpText scenarioId={scenario.id} companyId={scenario.companyId} />}
          labelPosition="left"
          placeholder="Select"
          hideSubLabel={false}
          hideRoleDescription={false}
          defaultValue={defaultValue}
        />
      )}
    />
  );
};
