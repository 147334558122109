import { AppState, FetchStatus, Permission, PermissionObjectableType } from 'types';

export const getByCompanyId = (state: AppState, companyId?: number): Permission[] =>
  companyId
    ? state.permissions.data.filter(
        (permission) =>
          permission.objectableType === PermissionObjectableType.Company &&
          permission.objectableId === companyId,
      )
    : [];

export const getByScenarioId = (state: AppState, scenarioId?: number): Permission[] =>
  state.permissions.data.filter(
    (permission) =>
      permission.objectableType === PermissionObjectableType.Scenario &&
      permission.objectableId === scenarioId,
  );

export const getByScenarioUserId = (
  state: AppState,
  scenarioId: number,
  userId: number,
): Permission | undefined =>
  state.permissions.data.find(
    (permission) =>
      permission.objectableType === PermissionObjectableType.Scenario &&
      permission.objectableId === scenarioId &&
      permission.userId === userId,
  );

export const getByCompanyUserId = (
  state: AppState,
  companyId: number,
  userId: number,
): Permission | undefined =>
  state.permissions.data.find(
    (permission) =>
      permission.objectableType === PermissionObjectableType.Company &&
      permission.objectableId === companyId &&
      permission.userId === userId,
  );

export const permissionsFetchedStatusSelector = (state: AppState): FetchStatus =>
  state.permissions.status;
