import { useQueryClient, useMutation } from '@tanstack/react-query';

import queries from 'js/queries';

import api from './api';

export enum NotificationName {
  Hris = 'hris',
  SyncHris = 'sync_hris',
  Equity = 'equity',
  SyncEquity = 'sync_equity',
  NewBenchmark = 'new_benchmark',
  NewBenchmarkReleased = 'new_benchmark_released',
  OpenImperative = 'open_imperative',
  Ranges = 'ranges',
  HrisSyncInProgress = 'hris_sync_in_progress',
  HrisSyncInProgressSlow = 'hris_sync_in_progress_slow',
  HrisSyncSucceeded = 'hris_sync_succeeded',
  HrisSyncFailed = 'hris_sync_failed',
  HrisSyncFailedToConnect = 'hris_sync_failed_to_connect',
  HrisSyncFailedToSendData = 'hris_sync_failed_to_send_data',
}

export type CompanyNotification = {
  id: number;
  name: NotificationName;
};

export const fetchCompanyNotifications = async (companyId: number) => {
  const { success, data } = await api.get<CompanyNotification[]>(
    `/companies/${companyId}/notifications`,
  );
  if (success) {
    return data;
  }

  throw new Error('Failed to load company notifications');
};

const dismiss = async (companyId: number, notificationId: number) => {
  const { success } = await api.put<undefined>(
    `/companies/${companyId}/notifications/${notificationId}`,
    {
      params: { userNotification: { dismissedAt: new Date() } },
    },
  );

  if (success) {
    return;
  }

  throw new Error('Failed to load company notifications');
};

export const useDismissCompanyNotification = (companyId: number) => {
  const queryClient = useQueryClient();
  const query = queries.companies.detail(companyId)._ctx.notifications;
  return useMutation((notificationId: number) => dismiss(companyId, notificationId), {
    onMutate: (notificationId: number) => {
      const previous = queryClient.getQueryData(query.queryKey);
      queryClient.setQueryData(query.queryKey, (old: CompanyNotification[] = []) =>
        old.filter((notification) => notification.id !== notificationId),
      );
      return previous;
    },
    onError: (_err, _notificationId, previous) => {
      queryClient.setQueryData(query.queryKey, previous);
    },
    onSettled: () => {
      queryClient.invalidateQueries(query);
    },
  });
};
