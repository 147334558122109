import GLOBALS from 'js/config/globals';
import { isNumber } from 'js/utils/value';
import { CompensationType, Employee } from 'types';

import { getCompTypeDetails } from './compService';
import { getPercentile } from './employeeService';

export interface PercentileClusterBlockPayload {
  midPercentile: number;
  level: 1;
  groupLabel: string;
  employeeCount: 0;
  jobAreas: Record<string, number>;
  departments: Record<number, number>;
}

const normalizePercentile = (percentile: number | null) => {
  if (isNumber(percentile) && GLOBALS.roundPercentileToLimits) {
    return Math.min(
      Math.max(percentile, GLOBALS.compPercentileLimits[0]),
      GLOBALS.compPercentileLimits[1],
    );
  }

  return percentile;
};

const centerPoints = {
  '<25': 25 / 2,
  '25-45': 25 + (45 - 25) / 2,
  50: 50,
  '55-75': 55 + (75 - 55) / 2,
  '>75': 75 + (100 - 75) / 2,
};

const formatPercentileClusterLabel = (value: number) => {
  switch (value) {
    case centerPoints['<25']:
      return 'Below 25th';
    case centerPoints['25-45']:
      return '25th - 45th';
    case centerPoints['50']:
      return '45th - 55th';
    case centerPoints['55-75']:
      return '55th - 75th';
    case centerPoints['>75']:
      return 'Above 75th';
    default:
      return 'Unknown placement';
  }
};

const getPercentileMidpoint = (x: number) => {
  if (x < 25) {
    return centerPoints['<25'];
  }
  if (x >= 25 && x < 45) {
    return centerPoints['25-45'];
  }
  if (x >= 45 && x <= 55) {
    return centerPoints['50'];
  }
  if (x > 55 && x <= 75) {
    return centerPoints['55-75'];
  }
  if (x > 75) {
    return centerPoints['>75'];
  }

  return 0;
};

const getPercentileClusterByType = (type: CompensationType, employees: Employee[]) => {
  const clusters = employees.reduce((acc, employee) => {
    const typeDetails = getCompTypeDetails(type, employee);
    const percentile = getPercentile(employee, typeDetails);

    if (!isNumber(percentile)) return acc;

    const percentileMidpoint = getPercentileMidpoint(percentile);
    const jobArea = employee.jobRole?.jobArea;

    if (!acc[percentileMidpoint]) {
      acc[percentileMidpoint] = {
        midPercentile: percentileMidpoint,
        level: 1,
        groupLabel: formatPercentileClusterLabel(percentileMidpoint),
        employeeCount: 0,
        jobAreas: {},
        departments: {},
      };
    }

    acc[percentileMidpoint].employeeCount += 1;
    acc[percentileMidpoint].departments[employee.departmentId] ||= 0;
    acc[percentileMidpoint].departments[employee.departmentId] += 1;
    if (jobArea) {
      acc[percentileMidpoint].jobAreas[jobArea.name] ||= 0;
      acc[percentileMidpoint].jobAreas[jobArea.name] += 1;
    }

    return acc;
  }, {} as Record<number, PercentileClusterBlockPayload>);

  return Object.values(clusters);
};

const PERCENTILE_LEVELS = [
  {
    level: 'lowest',
    range: [-100, -26],
  },
  {
    level: 'low',
    range: [-25, -6],
  },
  {
    level: 'mid',
    range: [-5, 5],
  },
  {
    level: 'high',
    range: [6, 25],
  },
  {
    level: 'highest',
    range: [26, 100],
  },
];

const getPercentileLevel = (value: number | string | null, targetValue: number | null = 50) => {
  if (value === null || targetValue === null) return null;

  const normalizedValue = normalizePercentile(Number(value));

  if (!normalizedValue) return null;

  const difference = Math.round(normalizedValue - targetValue);
  const position = PERCENTILE_LEVELS.find(
    ({ range }) => difference >= range[0] && difference <= range[1],
  );

  return position?.level || null;
};

export {
  normalizePercentile,
  formatPercentileClusterLabel,
  getPercentileMidpoint,
  getPercentileClusterByType,
  getPercentileLevel,
};
