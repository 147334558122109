import Badge from 'js/design-system/Badge';
import Text from 'js/design-system/Text/Text';
import { JobRoleWithLevels, NullJobRole } from 'types';

import styles from './ResultTitle.module.scss';

interface ResultTitleProps {
  jobRole?: JobRoleWithLevels | NullJobRole;
}

export const ResultTitle = ({ jobRole }: ResultTitleProps) => {
  return (
    <div className={styles.resultsTitleContainer}>
      <Text variant="text-heading-h6">{jobRole?.name}</Text>
      <Badge backgroundColor="primary-gray-800">{jobRole?.jobType?.code}</Badge>
    </div>
  );
};
