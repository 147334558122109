import {
  formatDuration,
  intervalToDuration,
  formatDistance,
  format as dateFormat,
  formatISO,
} from 'date-fns';

import { parseDate } from 'js/utils/dates';

export const getFormattedISODate = (date: string | Date) => {
  const parsedDate = typeof date === 'string' ? parseDate(date) : date;
  return parsedDate ? formatISO(parsedDate, { representation: 'date' }) : '';
};

export const getFormattedDate = (date: string | Date, format: DateFormat) => {
  const parsedDate = typeof date === 'string' ? parseDate(date) : date;
  return parsedDate ? dateFormat(parsedDate, format) : '';
};

type LocaleTokenMap = {
  xMonths: string;
  xYears: string;
};

const formatDistanceLocaleTokenMap: LocaleTokenMap = {
  xMonths: '{{count}} mo',
  xYears: '{{count}} yr',
};

const pluralize = (text: string, count: number) => text + (Math.abs(count) === 1 ? '' : 's');

const formatDistanceLocale = (token: keyof LocaleTokenMap, count: string) =>
  pluralize(formatDistanceLocaleTokenMap[token].replace('{{count}}', count), +count as number);

export const getFormattedDuration = (startDate: string | null, endDate?: string | null) => {
  const start = parseDate(startDate);
  const end = parseDate(endDate) || new Date();
  if (!start) return null;

  const duration = intervalToDuration({ start, end });

  if (duration.years === 0 && duration.months === 0) {
    return '< 1 mo';
  }

  const formattedDuration = formatDuration(duration, {
    format: ['years', 'months'],
    locale: { formatDistance: formatDistanceLocale },
  });

  return formattedDuration;
};

export const getFormattedTimeAgo = (sinceDate: string) => {
  const start = parseDate(sinceDate);
  const end = new Date();

  if (!start) return null;

  const { months: monthsPassed = 0, years: yearsPassed = 0 } = intervalToDuration({
    start,
    end,
  });

  if (yearsPassed >= 1) {
    return dateFormat(start, 'LLL dd yyyy');
  }
  if (monthsPassed >= 1) {
    return dateFormat(start, 'LLL dd');
  }
  return formatDistance(start, end, { addSuffix: true });
};

// Date formats
export enum DateFormat {
  MonthYearComma = 'MMM, yyyy',
  MonthYear = 'MM/yyyy',
  MonthDayYearShort = 'M/d/yyyy',
  MonthDayYear = 'MM/dd/yyyy',
  MonthDayYearExtended = 'MMM dd, yyyy p',
  MonthYearQuote = `MMM ‘yy`,
  MonthShort = 'MMM',
  YearMonthFirstDay = 'yyyy-MM-01',
  YearMonthDayInput = "yyyy-MM-dd'T'HH:mm",
}
