import { createContext } from 'react';

interface PayStrategyContextProps {
  rangeDirty: boolean;
  setRangeDirty: React.Dispatch<React.SetStateAction<boolean>>;
  rangeRecalculated: boolean;
  setRangeRecalculated: React.Dispatch<React.SetStateAction<boolean>>;
  rangeEditable: boolean;
  setRangeEditable: React.Dispatch<React.SetStateAction<boolean>>;
}

const PayStrategyContext = createContext<PayStrategyContextProps>({
  rangeDirty: false,
  setRangeDirty: () => {},
  rangeRecalculated: false,
  setRangeRecalculated: () => {},
  rangeEditable: true,
  setRangeEditable: () => {},
});

export default PayStrategyContext;
