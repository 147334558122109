import LoadingPlaceholder from 'js/design-system/LoadingPlaceholder/LoadingPlaceholder';
import TextButton from 'js/design-system/TextButton/TextButton';

import styles from './ConfirmButton.module.scss';

interface ConfirmButtonProps {
  confirmed: boolean;
  loading: boolean;
  onClick: (event: React.SyntheticEvent<Element, Event>) => void;
}

const ConfirmButton = ({ confirmed, loading, onClick }: ConfirmButtonProps) => {
  if (confirmed) return null;
  return (
    <div className={styles.confirmButton}>
      {loading && <LoadingPlaceholder />}
      {!loading && <TextButton onClick={onClick}>Confirm</TextButton>}
    </div>
  );
};

export default ConfirmButton;
