import { CompCycle } from './compCycle';
import { Employee } from './employee';
import { EmployeeAdjustment } from './employeeAdjustment';
import { Scenario } from './scenario';

export type LetterOptions = {
  timezone: string;
  basicMessage?: string;
  showAlternateMessage?: boolean;
  alternateMessage?: string;
  brandColor?: string;
  showBenchmarking?: boolean;
  showEffectiveDate?: boolean;
  showCashRange?: boolean;
  showTargetBonus?: boolean;
  showBrandColor?: boolean;
};

export type Letter = {
  id: number;
  body: string;
  expiresAt?: string | null;
  sentAt?: string | null;
  letterTemplateId: number;
  options: LetterOptions;
  publishable?: Pick<CompCycle, 'name'>;
  openedAt?: string | null;
  downloadedAt?: string | null;
};

export type LetterStatements = Partial<
  Pick<Letter, 'id' | 'body' | 'sentAt' | 'openedAt' | 'downloadedAt'>
> & {
  scenario: Partial<Pick<Scenario, 'logo'>>;
  employee: Partial<Pick<Employee, 'name'>>;
  compCycle: Partial<
    Pick<
      CompCycle,
      'id' | 'compCycleType' | 'name' | 'effectiveDate' | 'settings' | 'letterTemplate'
    >
  >;
  employeeAdjustment: Partial<
    Pick<
      EmployeeAdjustment,
      | 'bonus'
      | 'jobTitle'
      | 'jobLevel'
      | 'jobRoleId'
      | 'compensation'
      | 'jobRole'
      | 'preservedEmployee'
      | 'baseSalaryIncrease'
      | 'targetAnnualBonusIncrease'
      | 'commissionsIncrease'
      | 'shareCountIncrease'
    >
  >;
  isPromoted: boolean;
  color?: string;
  options: Partial<LetterOptions>;
  showTargetBonus?: boolean;
};

export enum LetterSection {
  BenchmarkingSection = 'BenchmarkingSection',
  CashRangeSection = 'CashRangeSection',
  CompensationSection = 'CompensationSection',
  EffectiveDateSection = 'EffectiveDateSection',
}
