import { CaretDown, Check } from '@phosphor-icons/react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useMemo } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import FormControl from 'react-bootstrap/FormControl';

import Icon from 'js/design-system/Icon';

import ListNavigation from '../common/ListNavigation';
import SearchBox from '../common/SearchBox';

import styles from './RawMultiSelect.module.scss';

export const compactValues = (label) => (values) =>
  values.length === 1 ? values : [{ label: `(${values.length}) ${label}` }];

const RawMultiSelect = (props) => {
  const {
    value,
    options,
    onChange,
    invalid,
    onBlur,
    disabled,
    placeholder,
    className,
    searchable,
    displayedValue,
    allOption,
    menuAlign,
    customToggleContent,
    toggleClassName,
    menuClassName,
    closeOnSelect,
  } = props;
  const [expanded, setExpanded] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(value);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [allOptionSelected, setAllOptionSelected] = useState(false);
  const allOptions = useMemo(
    () => (allOption ? [allOption, ...options] : options),
    [options, allOption],
  );

  useEffect(() => {
    setSelectedOptions(value);
    setAllOptionSelected(allOption && value.length === options.length);
  }, [value, allOption, options]);

  useEffect(() => {
    setFilteredOptions(allOptions);
  }, [allOptions]);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const toggleOption = (event, value) => {
    event?.stopPropagation();

    let newSelectedSet = [...selectedOptions];
    const index = newSelectedSet.indexOf(value);

    if (allOption && value === allOption.value) {
      if (allOptionSelected) {
        newSelectedSet = [];
      } else {
        newSelectedSet = options.map((option) => option.value);
      }
    } else if (index >= 0) {
      newSelectedSet.splice(index, 1);
    } else {
      newSelectedSet.push(value);
    }

    setSelectedOptions(newSelectedSet);
    setAllOptionSelected(allOption && newSelectedSet.length === options.length);
    onChange(newSelectedSet);
    if (onBlur) {
      onBlur();
    }
    if (closeOnSelect) {
      setExpanded(false);
    }
  };

  const renderSearchBox = () => {
    const keepSearchBox = (e) => {
      if (expanded) {
        e.stopPropagation();
      }
    };

    return (
      <SearchBox
        items={allOptions}
        onFilter={setFilteredOptions}
        searchField="label"
        placeholder={placeholder}
        className={styles.searchBox}
        autofocus={expanded}
        onClick={keepSearchBox}
      />
    );
  };

  const renderSelectedOptions = () => {
    if (allOptionSelected) {
      return (
        <div className={styles.currentSelections}>
          <span className={styles.currentSelection}>{allOption.label}</span>
        </div>
      );
    }

    const displayedSelectedOptions = displayedValue(selectedOptions);

    return (
      <div className={styles.currentSelections}>
        {selectedOptions.length > 0
          ? displayedSelectedOptions.map((selectedOption, i) => (
              <span className={styles.currentSelection} key={selectedOption}>
                {selectedOption?.label ||
                  options.find((option) => option.value === selectedOption)?.label}
                {i < displayedSelectedOptions.length - 1 && <span>,&nbsp;</span>}
              </span>
            ))
          : placeholder}
      </div>
    );
  };

  const renderOptions = () => {
    if (filteredOptions.length > 0) {
      return filteredOptions.map((option) => {
        let selected = selectedOptions.indexOf(option.value) !== -1;
        if (allOptionSelected && option.value === allOption.value) selected = true;

        return (
          <Dropdown.Item
            onClick={(event) => toggleOption(event, option.value)}
            className={classnames(styles.option, {
              [styles.selected]: selected,
            })}
            variant="link"
            key={option.value}
          >
            <Icon className={styles.checkmark} icon={Check} size={20} color="oc-primary" />
            {option.label}
          </Dropdown.Item>
        );
      });
    }

    return <div className={styles.noOptions}>No options</div>;
  };

  const isSelectedOption =
    !searchable || (searchable && !expanded) || (!expanded && selectedOptions.length > 0);

  return (
    <Dropdown
      className={classnames(className, styles.dropdown)}
      onToggle={toggleExpanded}
      show={expanded}
      as={ListNavigation}
      items={filteredOptions}
      onOpen={() => setExpanded(true)}
      searchableByFirstChar={!searchable}
    >
      {(itemsContainerRef) => (
        <>
          <Dropdown.Toggle
            variant=""
            className={classnames(styles.toggle, toggleClassName)}
            disabled={disabled || expanded}
          >
            <FormControl
              as="div"
              className={classnames(styles.input, { [styles.formControl]: !isSelectedOption })}
              isInvalid={invalid}
              tabIndex={0}
            >
              {isSelectedOption
                ? customToggleContent || renderSelectedOptions()
                : renderSearchBox()}
              {!searchable && <Icon icon={CaretDown} size={16} />}
            </FormControl>
          </Dropdown.Toggle>
          <Dropdown.Menu
            className={classnames(styles.menu, menuClassName)}
            ref={itemsContainerRef}
            align={menuAlign}
          >
            {renderOptions()}
          </Dropdown.Menu>
        </>
      )}
    </Dropdown>
  );
};

RawMultiSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  onBlur: PropTypes.func,
  invalid: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  searchable: PropTypes.bool,
  displayedValue: PropTypes.func,
  allOption: PropTypes.instanceOf(Object),
  menuAlign: PropTypes.string,
  customToggleContent: PropTypes.node,
  toggleClassName: PropTypes.string,
  menuClassName: PropTypes.string,
  closeOnSelect: PropTypes.bool,
};

RawMultiSelect.defaultProps = {
  value: [],
  onBlur: null,
  invalid: false,
  disabled: false,
  placeholder: 'None Specified',
  className: null,
  searchable: false,
  displayedValue: (values) => values,
  allOption: null,
  menuAlign: null,
  customToggleContent: null,
  toggleClassName: null,
  menuClassName: null,
  closeOnSelect: false,
};

export default RawMultiSelect;
