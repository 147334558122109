import { JobLevel } from 'types';

import api from './api';

export const fetchJobLevels = async (scenarioId: number, filter: { jobTypeId?: number } = {}) => {
  const { success, data, error } = await api.get<JobLevel[]>(
    `/scenarios/${scenarioId}/job_levels`,
    {
      urlParams: filter,
    },
  );

  if (success) {
    return data;
  }

  throw new Error(`Failed to fetch job levels: ${error}`);
};
