import { CaretRight } from '@phosphor-icons/react';
import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';

import ActionableModal from 'js/design-system/ActionableModal';
import Avatar from 'js/design-system/Avatar';
import ColorPill from 'js/design-system/ColorPill/ColorPill';
import IconButton from 'js/design-system/IconButton/IconButton';
import Text from 'js/design-system/Text/Text';
import Tooltip from 'js/design-system/Tooltip/Tooltip';
import useNumberParams from 'js/hooks/useNumberParams';
import queries from 'js/queries';
import { Employee } from 'types';

import styles from './JobPostRangeResults.module.scss';

export const AtRiskEmployeesModal = ({
  employeeIds,
  rangeMin,
  commissionBased,
  show,
  onClick,
}: {
  employeeIds: number[];
  rangeMin: number;
  commissionBased: boolean;
  show: boolean;
  onClick: () => void;
}) => {
  const { scenarioId } = useNumberParams();
  const employeeIdsSet = new Set(employeeIds);
  const { data: employees = [] } = useQuery({
    ...queries.scenarios.detail(scenarioId)._ctx.employees(),
    select: (employees) => employees.filter((e: Employee) => employeeIdsSet.has(e.id)),
  });

  const costToMin = employees.reduce((sum, curr) => {
    const salary = Number(
      commissionBased ? curr.compensation.totalCashUsd : curr.compensation.baseSalaryUsd,
    );
    return sum + (rangeMin - salary);
  }, 0);

  const employeeCompString = (employee: Employee) =>
    commissionBased
      ? `OTE: $${employee.compensation.totalCashUsd?.toLocaleString()}`
      : `Salary: $${employee.compensation.baseSalaryUsd?.toLocaleString()}`;

  return (
    <ActionableModal
      show={show}
      title="Employees below range"
      confirmLabel="Got it"
      onHide={onClick}
      onConfirm={onClick}
      hideCancelButton
    >
      <div className={styles.modalContent}>
        <Text variant="text-body-large">The following employees are below the listed range:</Text>
        {employees.map((e) => (
          <div key={e.id} className={styles.employee}>
            <Avatar variant="medium" colorIndex={e.id} name={e.name} />
            <div className={styles.employeeData}>
              <Text variant="text-body-main-bold" color="platform-gray-900">
                {e.name}
              </Text>
              <div className={styles.salary}>
                <span>{employeeCompString(e)}</span>
                <ColorPill
                  text={`$${(
                    rangeMin - Number(e.compensation.baseSalaryUsd)
                  ).toLocaleString()} below listed range`}
                  pillColor="red"
                />
              </div>
            </div>
            <Tooltip content="Navigate to this employee" delay>
              <Link
                to={`/scenarios/${scenarioId}/employees/${e.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconButton icon={CaretRight} iconColor="platform-gray-500" />
              </Link>
            </Tooltip>
          </div>
        ))}
        <div className={styles.divider} />
        <div className={styles.bottomText}>
          Consider adjusting {employeeIds.length === 1 ? "this employee's" : "these employees'"} pay
          before publishing your job post. The combined cost to bring{' '}
          {employeeIds.length === 1 ? 'this employee' : `all ${employeeIds.length} employees`} to
          the job post minimum would be{' '}
          <span style={{ fontWeight: 700 }}>${costToMin.toLocaleString()}</span>.
        </div>
      </div>
    </ActionableModal>
  );
};
