import { fetching, receiveAll } from 'js/actions/companyUsers';
import api from 'js/api/api';
import { status } from 'js/selectors/companyUsers';
import { AppDispatch, GetState, UserPermission } from 'types';

export const fetchCompanyUsers =
  (companyId: number) => async (dispatch: AppDispatch, getState: GetState) => {
    if (status(getState(), companyId) !== 'idle') {
      return;
    }
    dispatch(fetching);
    const { success, data } = await api.get<UserPermission[]>(`/companies/${companyId}/users`);
    if (success) {
      dispatch(receiveAll(data, companyId));
    }
  };
