import { clone } from 'ramda';
import { AnyAction } from 'redux';

import { ETHNICITY_SELECT_OPTIONS, GENDER_OPTIONS } from 'js/config/globals-ts';
import ACTIONS from 'js/constants/actions';
import { normalizeHiddenEmployeesBit } from 'js/services/scenarioService';
import { ExtendedCurrencyCode } from 'js/utils/formatters/currency';
import {
  CashDisplayType,
  CompMode,
  DiversityGroupType,
  EmployeeFilters,
  EquityDisplayType,
  PartialExcept,
  RangeDisplayType,
  RangePositionMetric,
  ScenarioViewMode,
  TimelineChartType,
  keys,
  MarketEmployeeSortBy,
  RangesEmployeeSortBy,
} from 'types';

export type ScenarioViewState = {
  mode: ScenarioViewMode;
  employeeSortBy: {
    [key in ScenarioViewMode]?: MarketEmployeeSortBy | RangesEmployeeSortBy;
  };
  employeeFilters: {
    [ScenarioViewMode.Launchpad]: Partial<EmployeeFilters>;
    [ScenarioViewMode.Market]: PartialExcept<
      EmployeeFilters,
      'department' | 'level' | 'ethnicity' | 'gender' | 'midPercentile' | 'percentileGroup'
    >;
    [ScenarioViewMode.Ranges]: PartialExcept<
      EmployeeFilters,
      | 'department'
      | 'level'
      | 'ethnicity'
      | 'gender'
      | 'rangeGroup'
      | 'midRangePosition'
      | 'geoTier'
    >;
    [ScenarioViewMode.Diversity]: PartialExcept<EmployeeFilters, 'department' | 'level'>;
    [ScenarioViewMode.Hiring]: Partial<EmployeeFilters>;
    [ScenarioViewMode.Offers]: Partial<EmployeeFilters>;
    [ScenarioViewMode.CompCycles]: Partial<EmployeeFilters>;
    [ScenarioViewMode.TotalRewards]: Partial<EmployeeFilters>;
  };
  cashDisplayType: CashDisplayType;
  equityDisplayType: EquityDisplayType;
  diversityGroupType: DiversityGroupType;
  rangeDisplayType: RangeDisplayType;
  rangePositionMetric: RangePositionMetric;
  compDisplayType: CompMode;
  currencyDisplay: ExtendedCurrencyCode;
  hiddenEmployeeTypes: string[];
  timelineChartType: TimelineChartType;
};

const genders = keys(GENDER_OPTIONS);
const ethnicities = keys(ETHNICITY_SELECT_OPTIONS);

export const initialState: ScenarioViewState = {
  mode: ScenarioViewMode.Market,
  employeeSortBy: {
    [ScenarioViewMode.Launchpad]: MarketEmployeeSortBy.Name,
    [ScenarioViewMode.Market]: MarketEmployeeSortBy.Name,
    [ScenarioViewMode.Ranges]: MarketEmployeeSortBy.Name,
    [ScenarioViewMode.Hiring]: MarketEmployeeSortBy.Name,
    [ScenarioViewMode.Diversity]: MarketEmployeeSortBy.Name,
  },
  employeeFilters: {
    [ScenarioViewMode.Launchpad]: {},
    [ScenarioViewMode.Market]: {
      department: [],
      level: null,
      ethnicity: ethnicities,
      gender: genders,
      midPercentile: null,
      percentileGroup: null,
    },
    [ScenarioViewMode.Ranges]: {
      department: [],
      level: null,
      ethnicity: ethnicities,
      gender: genders,
      rangeGroup: [],
      midRangePosition: null,
      geoTier: null,
    },
    [ScenarioViewMode.Diversity]: {
      department: [],
      level: null,
    },
    [ScenarioViewMode.Hiring]: {},
    [ScenarioViewMode.Offers]: {},
    [ScenarioViewMode.CompCycles]: {},
    [ScenarioViewMode.TotalRewards]: {},
  },
  cashDisplayType: CashDisplayType.SalaryOte,
  equityDisplayType: EquityDisplayType.NumOfShares,
  diversityGroupType: DiversityGroupType.Gender,
  rangeDisplayType: RangeDisplayType.Departments,
  rangePositionMetric: RangePositionMetric.RangePosition,
  compDisplayType: CompMode.Market,
  currencyDisplay: 'USD',
  hiddenEmployeeTypes: [],
  timelineChartType: TimelineChartType.Burn,
};

function setViewMode(state: ScenarioViewState, action: AnyAction) {
  return { ...state, mode: action.viewMode };
}

function setEmployeeSort(state: ScenarioViewState, action: AnyAction) {
  return {
    ...state,
    employeeSortBy: {
      ...state.employeeSortBy,
      [state.mode]: action.data,
    },
  };
}

function setEmployeeFilter(state: ScenarioViewState, action: AnyAction) {
  return {
    ...state,
    employeeFilters: {
      ...state.employeeFilters,
      [state.mode]: {
        ...state.employeeFilters[state.mode],
        [action.filterType]: action.filter,
      },
    },
  };
}

function clearAllFilters(state: ScenarioViewState) {
  return {
    ...clone(initialState),
    mode: state.mode,
    diversityGroupType: state.diversityGroupType,
    rangeDisplayType: state.rangeDisplayType,
    compDisplayType: state.compDisplayType,
    timelineChartType: state.timelineChartType,
  };
}

function setIntialRangeGroupFilters(state: ScenarioViewState, action: AnyAction) {
  const newState = clone(state);
  newState.employeeFilters.ranges.rangeGroup = action.data.rangesFilter;
  return newState;
}

function setIntialDepartmentFilters(state: ScenarioViewState, action: AnyAction) {
  const newState = clone(state);

  newState.employeeFilters.market.department = action.data.marketDepartmentFilter;
  newState.employeeFilters.ranges.department = action.data.rangesDepartmentFilter;
  newState.employeeFilters.diversity.department = action.data.diversityDepartmentFilter;

  return newState;
}

function setIntialJobTypeLevelsFilters(state: ScenarioViewState, action: AnyAction) {
  const newState = clone(state);
  const { options } = action;

  keys(newState.employeeFilters).forEach((mode: ScenarioViewMode) => {
    newState.employeeFilters[mode].level = clone(options);
  });

  return newState;
}

function handleDepartmentDelete(state: ScenarioViewState, action: AnyAction) {
  const newState = clone(state);

  keys(newState.employeeFilters).forEach((mode: ScenarioViewMode) => {
    const departmentFilters = newState.employeeFilters[mode].department;
    if (departmentFilters) {
      newState.employeeFilters[mode].department = departmentFilters.filter(
        (id) => id !== action.id,
      );
    }
  });

  return newState;
}

function setDiversityGroupType(state: ScenarioViewState, action: AnyAction) {
  return { ...state, diversityGroupType: action.groupType };
}

function setRangeDisplayType(state: ScenarioViewState, action: AnyAction) {
  return { ...state, rangeDisplayType: action.data };
}

function setRangePositionMetricType(state: ScenarioViewState, action: AnyAction) {
  return { ...state, rangePositionMetric: action.data };
}

function setCompDisplayType(state: ScenarioViewState, action: AnyAction) {
  return { ...state, compDisplayType: action.data };
}

function setCurrencyDisplay(state: ScenarioViewState, action: AnyAction) {
  return { ...state, currencyDisplay: action.data };
}

function setCashDisplayType(state: ScenarioViewState, action: AnyAction) {
  return {
    ...state,
    cashDisplayType: action.data,
  };
}

function setEquityDisplayType(state: ScenarioViewState, action: AnyAction) {
  return { ...state, equityDisplayType: action.data };
}

function setHiddenEmployeeTypes(state: ScenarioViewState, action: AnyAction) {
  return { ...state, hiddenEmployeeTypes: normalizeHiddenEmployeesBit(action.data) };
}

function setTimelineChartType(state: ScenarioViewState, action: AnyAction) {
  return { ...state, timelineChartType: action.chartType };
}

export default (
  state: ScenarioViewState = initialState,
  action: AnyAction = { type: null },
): ScenarioViewState => {
  switch (action.type) {
    case ACTIONS.SET_CURRENT_SCENARIO_VIEW_MODE:
      return setViewMode(state, action);
    case ACTIONS.CLEAR_ALL_FILTERS:
      return clearAllFilters(state);
    case ACTIONS.DELETE_DEPARTMENT:
      return handleDepartmentDelete(state, action);
    case ACTIONS.SET_INITIAL_DEPARTMENT_FILTERS:
      return setIntialDepartmentFilters(state, action);
    case ACTIONS.SET_INITIAL_JOB_TYPE_LEVELS_FILTERS:
      return setIntialJobTypeLevelsFilters(state, action);
    case ACTIONS.SET_INITIAL_RANGE_GROUP_FILTERS:
      return setIntialRangeGroupFilters(state, action);
    case ACTIONS.SET_EMPLOYEE_FILTER:
      return setEmployeeFilter(state, action);
    case ACTIONS.EMPLOYEE_SORT:
      return setEmployeeSort(state, action);
    case ACTIONS.SET_DIVERSITY_GROUP_TYPE:
      return setDiversityGroupType(state, action);
    case ACTIONS.SET_RANGE_DISPLAY_TYPE:
      return setRangeDisplayType(state, action);
    case ACTIONS.SET_RANGE_POSITION_METRIC_TYPE:
      return setRangePositionMetricType(state, action);
    case ACTIONS.SET_COMP_DISPLAY_TYPE:
      return setCompDisplayType(state, action);
    case ACTIONS.SET_CURRENCY_DISPLAY:
      return setCurrencyDisplay(state, action);
    case ACTIONS.SET_CASH_DISPLAY_TYPE:
      return setCashDisplayType(state, action);
    case ACTIONS.SET_EQUITY_DISPLAY_TYPE:
      return setEquityDisplayType(state, action);
    case ACTIONS.SET_HIDDEN_EMPLOYEE_TYPES:
      return setHiddenEmployeeTypes(state, action);
    case ACTIONS.SET_TIMELINE_CHART_TYPE:
      return setTimelineChartType(state, action);
    default:
      return state;
  }
};
