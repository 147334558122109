import { Campaign } from 'js/components/common/Campaign';
import { Coin } from 'js/components/common/illustrations/Coin';

import { DataLookupSource } from '../DataLookup';

import styles from './Illustration.module.scss';

const headings = {
  [DataLookupSource.GetJobPostRange]:
    'Improve your job posts with ranges built for compliance and acceptance.',
  [DataLookupSource.ViewPayRanges]: 'Search and share pay ranges in seconds.',
  [DataLookupSource.MarketData]: 'See what companies like yours are paying for talent.',
  [DataLookupSource.MarketDataNoRemainingQueries]:
    'Upgrade your plan for total access to Market Pulse Data',
};

const mainText1s = {
  [DataLookupSource.GetJobPostRange]: 'Build compliant job post ranges',
  [DataLookupSource.ViewPayRanges]: 'View the ranges you need, when you need them',
  [DataLookupSource.MarketData]: 'View market rates for both cash and equity',
  [DataLookupSource.MarketDataNoRemainingQueries]: 'Gain unlimited market data searches',
};

const subText1s = {
  [DataLookupSource.GetJobPostRange]:
    'Create pay ranges that comply with pay transparency legislation. Share pay ranges that are specific to your company, the role and level, and the hiring location.',
  [DataLookupSource.ViewPayRanges]:
    'Find the pay ranges you need without searching through spreadsheets. Enter role, level, and location to gain a comprehensive view of the most up-to-date pay ranges for any job.',
  [DataLookupSource.MarketData]:
    'Know exactly what your market looks like with access to cash and equity rates that are specific to role, level, and location',
  [DataLookupSource.MarketDataNoRemainingQueries]:
    'Know exactly what your market looks like with access to cash and equity rates that are specific to role, level, and location',
};

const mainText2s = {
  [DataLookupSource.GetJobPostRange]: 'Reduce risk with predictive analytics',
  [DataLookupSource.ViewPayRanges]: 'Save time with recent searches',
  [DataLookupSource.MarketData]: 'Save and view your past searches',
  [DataLookupSource.MarketDataNoRemainingQueries]: 'Save and view your past searches',
};

const subText2s = {
  [DataLookupSource.GetJobPostRange]:
    'Increase candidate acceptance and employee retention with expert-vetted insights that help you choose an optimized job post range.',
  [DataLookupSource.ViewPayRanges]:
    'Make sharing your pay ranges easier with saved searches. Recall your recent searches with just the click of a button.',
  [DataLookupSource.MarketData]:
    'Save your searches and never worry about losing track of the data you need',
  [DataLookupSource.MarketDataNoRemainingQueries]:
    'Save your searches and never worry about losing track of the data you need',
};

export const Illustration = ({ source }: { source: DataLookupSource }) => (
  <div className={styles.marketingContainer}>
    <Campaign
      className={styles.campaign}
      illustration={<Coin />}
      heading={headings[source]}
      listItems={[
        {
          mainText: mainText1s[source],
          subText: subText1s[source],
        },
        {
          mainText: mainText2s[source],
          subText: subText2s[source],
        },
      ]}
      shortHeading={headings[source].length < 40}
    />
  </div>
);
