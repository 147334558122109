import { useState } from 'react';

import { GeneralSearchGetJobPostEventDetails } from 'js/analytics/types/general';
import useTrackEvent from 'js/analytics/useTrackEvent';
import { Form } from 'js/components-legacy/form';
import JobLevelMultiSelector from 'js/components/common/level/JobLevelMultiSelector';
import { RangeLookupData } from 'js/components/data-lookup/ranges/types';
import Button from 'js/design-system/Button/Button';
import { ButtonSize, ButtonVariant } from 'js/design-system/Button/types';
import Text from 'js/design-system/Text/Text';
import { JobRoleWithLevels, Scenario } from 'types';

import { DataLookupSource } from '../../DataLookup';
import { fetchPayRangeJobPost, fetchRecentQuery } from '../api';
import { GeoTiers } from '../common/GeoTiers';
import { JobRole } from '../common/JobRole';
import { RecentQueriesMenu } from '../common/RecentQueriesMenu';

import styles from 'js/components/data-lookup/common/Forms.module.scss';

interface JobPostRangeFormProps {
  scenario: Scenario;
  payRangeId: number | undefined;
  onSubmit: (data: RangeLookupData) => void;
  setSubmitting: (val: boolean) => void;
}

type FormData = {
  jobRoleId: number;
  jobRole: JobRoleWithLevels;
  jobLevelIds: number[];
  tierIds: number[];
};

const JobPostRangeForm = ({
  scenario,
  payRangeId,
  onSubmit,
  setSubmitting,
}: JobPostRangeFormProps) => {
  const SOURCE = DataLookupSource.GetJobPostRange;
  const [initialValues, setInitialValues] = useState<Partial<FormData>>();
  const { trackEvent } = useTrackEvent<GeneralSearchGetJobPostEventDetails>();

  const handleSubmit = async ({
    jobRoleId,
    jobLevelIds,
    tierIds,
  }: {
    jobRoleId: number;
    jobLevelIds: number[];
    tierIds: number[];
  }) => {
    setSubmitting(true);
    const data = await fetchPayRangeJobPost({
      scenarioId: scenario.id,
      source: SOURCE,
      jobRoleId,
      jobLevelIds,
      tierIds,
    });
    if (data) {
      trackEvent('general.search.getJobPost');
      onSubmit({ ...data, jobRoleId });
    }
  };

  const handleSelect = async (queryId: number) => {
    setSubmitting(true);
    const data = await fetchRecentQuery(scenario.id, queryId);
    setInitialValues({
      jobRoleId: data.jobRole.id,
      jobRole: data.jobRole,
      jobLevelIds: data.selectedJobLevelIds,
      tierIds: data.selectedTiers.map((t) => t.id),
    });

    onSubmit({ ...data, jobRoleId: data.jobRole.id });
  };

  return (
    <div className={styles.container}>
      <div className={styles.formHeader}>
        <div className={styles.headerText}>
          <Text
            variant="text-body-main-bold"
            color="platform-gray-900"
            className={styles.formHeaderTitle}
          >
            Get Job Post Range
          </Text>
          <Text color="platform-gray-700">
            Fill out the fields below to generate pay ranges that are ready to be included in your
            job posting.
          </Text>
        </div>
        <div>
          <RecentQueriesMenu source={SOURCE} scenarioId={scenario.id} onSelect={handleSelect} />
        </div>
      </div>
      <hr className={styles.divider} />
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        renderActions={(disabled, form) => {
          const { values } = form.getState();
          const submitDisabled =
            disabled || !values.jobRoleId || !values.jobLevelIds?.length || !values.tierIds?.length;

          return (
            <Button
              type="submit"
              variant={ButtonVariant.Filled}
              size={ButtonSize.Medium}
              className={styles.formSubmitButton}
              disabled={submitDisabled}
            >
              Search
            </Button>
          );
        }}
        contentClassName={styles.formContent}
        actionsClassName={styles.formActions}
      >
        <JobRole scenario={scenario} payRangeId={payRangeId} />
        <JobLevelMultiSelector
          scenarioId={scenario.id}
          helpText="Select all levels you are considering."
        />
        <GeoTiers
          scenarioId={scenario.id}
          companyId={scenario.companyId}
          multi
          helpText="Select all markets in which you will be hiring for this role."
        />
      </Form>
    </div>
  );
};

export default JobPostRangeForm;
