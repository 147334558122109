import { capitalCase } from 'change-case';
import Cookies from 'js-cookie';
import { path } from 'ramda';

import { EventSourceValue } from 'js/hooks/useURLParamEventSourceValue';
import { getPrimaryUtmCookie } from 'js/services/promoUtmService';
import { GenericObject } from 'types';

import { AnalyticEventConfigObject } from './types';

/**
 * returns integrations for Segment options
 * these are used to manually add data for Segment destinations
 */
export const getIntegrations = () => {
  const ga = Cookies.get('_ga');
  const gaParts = ga?.split('.') ?? [];
  const clientId = gaParts.length > 2 ? gaParts.slice(1).slice(1).join('.') : undefined;

  // session cookie key name looks like _ga_SN1DK0TWEQ where SN1DK0TWEQ is a GA4 measurement ID
  const sessionKey = Object.keys(Cookies.get()).find((cookie) => cookie.startsWith('_ga_')); // eg _ga_SN1DK0TWEQ
  const session = sessionKey ? Cookies.get(sessionKey) : '';
  const sessionParts = session?.split('.') ?? [];
  const sessionId = sessionParts.length > 2 ? sessionParts[2] : undefined;

  return {
    // universal analytics is deprecated in favor of GA4
    'Google Universal Analytics': {
      clientId,
      sessionId,
    },
    'Google Analytics 4': {
      clientId,
      sessionId,
    },
  };
};

/**
 * attaches properties to segment analytics tracking
 * GA4 client ID manually set using pattern from
 * https://tagticians.com/solving-ga4s-session-issue-in-segment/
 */
export const getOptions = (): SegmentAnalytics.SegmentOpts => {
  let options: SegmentAnalytics.SegmentOpts = {
    integrations: getIntegrations(),
  };

  const utmParams = getPrimaryUtmCookie();
  if (utmParams) {
    const { campaign, ...utm } = utmParams;

    options = {
      ...options,
      context: {
        campaign: {
          name: campaign,
          ...utm,
        },
      },
    };
  }

  return options;
};

export const resolveObjectValueFromStringPath = (
  literalPath: string,
  sourceObject: GenericObject,
) => {
  const properties = literalPath.split('.');
  return path<string>(properties, sourceObject);
};

const SOURCES: { [key: string]: RegExp } = {
  'Market Analysis': /\/scenarios\/[0-9]+(?:\/market)?$/,
  'Ranges Homepage': /\/scenarios\/[0-9]+\/ranges$/,
  'Ranges Detail Page': /\/scenarios\/[0-9]+\/ranges\/[0-9]+$/,
  Compulator: /\/scenarios\/[0-9]+\/compulator$/,
  'Employee Detail Page': /^\/scenarios\/[0-9]+\/employees\/[0-9]+$/,
  'Scenario Settings: Import Employees': /^\/scenarios\/[0-9]+\/import-employees$/,
  'Scenario Settings: Pay Strategy': /^\/scenarios\/[0-9]+\/pay-strategy$/,
  'Onboard Ranges': /\/scenarios\/[0-9]+\/onboarding\/.+?\?viewMode=ranges$/,
  'Onboard Market': /\/scenarios\/[0-9]+\/onboarding\/.+?\?viewMode=market$/,
  'User Onboarding': /\/onboarding\/scenarios\/[0-9]+\/.+?$/,
  'Add Employee Page': /\/scenarios\/[0-9]+\/employees\/new$/,
};

export const getEventSourceValueByCurrentRoute = (url?: string): EventSourceValue => {
  const path = url || `${window.location.pathname}${window.location.search}`;
  return Object.keys(SOURCES).find((source) => SOURCES[source].test(path)) || null;
};

const recurse = (object: GenericObject, path: string[]) => {
  Object.keys(object).forEach((key) => {
    const currentValue = object[key];
    const newPath = [...path];

    if (key !== 'default') newPath.push(key);

    if (currentValue === true) object[key] = capitalCase(newPath.join(' '));
    if (typeof currentValue === 'object') recurse(currentValue as GenericObject, newPath);
  });
};

export const createAnalyticsEventConfig = (name: string, config: AnalyticEventConfigObject) => {
  const configObject: GenericObject = { ...config };
  recurse(configObject, [name]);
  return configObject;
};
