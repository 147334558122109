import classNames from 'classnames';

import ConfirmationModal, { ConfirmationModalProps } from 'js/design-system/ConfirmationModal';

import styles from './ConfirmPopup.module.scss';

type ConfirmPopupProps = Omit<ConfirmationModalProps, 'title'> &
  Partial<Pick<ConfirmationModalProps, 'title'>>;

const ConfirmPopup = ({
  children,
  title = '',
  confirmLabel = "I'm sure",
  cancelLabel = 'Cancel',
  confirmBtnProps = {},
  ...rest
}: ConfirmPopupProps) => {
  const { className, ...confirmBtnPropsRest } = confirmBtnProps;

  return (
    <ConfirmationModal
      title={title}
      confirmLabel={confirmLabel}
      cancelLabel={cancelLabel}
      confirmBtnProps={{
        className: classNames(className, styles.confirmBtn),
        ...confirmBtnPropsRest,
      }}
      {...rest}
    >
      {children}
    </ConfirmationModal>
  );
};

export default ConfirmPopup;
