import { UserRole } from 'types';

import { roles } from '../../../services/permissionService';

import styles from './RoleLabelBadge.module.scss';

interface RoleLabelBadgeProps {
  role: UserRole;
}

const RoleLabelBadge = ({ role }: RoleLabelBadgeProps) => (
  <div className={styles.roleLabel}>{roles[role].shortLabel}</div>
);

export default RoleLabelBadge;
