import { Location } from 'types';

import api from './api';

export const fetchLocations = async () => {
  const { success, data } = await api.get<Location[]>('/locations');

  if (success) {
    return data;
  }

  throw new Error('Failed to fetch locations');
};

export const fetchBenchmarkLocations = async (benchmarkId: number) => {
  const { success, data } = await api.get<Location[]>(`/locations?benchmark_id=${benchmarkId}`);

  if (success) {
    return data;
  }

  throw new Error('Failed to fetch locations');
};

export const fetchLocation = async (id: number) => {
  const { success, data } = await api.get<Location>(`/locations/${id}`);

  if (success) {
    return data;
  }

  throw new Error('Failed to fetch location');
};

export const findLocation = async (locationName: string) =>
  api.get<Location>(`locations/find`, {
    urlParams: { locationName },
  });
