import ComplexAlertIcon from 'js/design-system/Alert/ComplexAlertIcon';
import Tooltip from 'js/design-system/Tooltip/Tooltip';

import { Consideration } from '../types';

export const ConsiderationsIcon = ({ tooltipText, ...iconProps }: Consideration) => {
  return (
    <Tooltip content={tooltipText}>
      <div>
        <ComplexAlertIcon {...iconProps} />
      </div>
    </Tooltip>
  );
};
