import { createQueryKeys } from '@lukemorales/query-key-factory';

import { fetchSegmentGroup, fetchSegmentGroups } from 'js/api/segmentGroups';

const segmentGroups = createQueryKeys('segmentGroups', {
  detail: (id: number) => ({
    queryKey: [id],
    queryFn: () => fetchSegmentGroup(id),
  }),
  list: {
    queryKey: null,
    queryFn: fetchSegmentGroups,
  },
});

export default segmentGroups;
