import { Stakeholder } from 'types';

import api from './api';

export const fetchStakeholderSecurities = async (args: {
  companyId?: number;
  scenarioId?: number;
}) => {
  let url;
  if (args.companyId) {
    url = `/companies/${args.companyId}/stakeholder_securities`;
  }
  if (args.scenarioId) {
    url = `/scenarios/${args.scenarioId}/stakeholder_securities`;
  }

  if (!url) {
    throw new Error(`Failed to load stakeholder securities: No route defined`);
  }

  const { success, data, error } = await api.get<Stakeholder[]>(url);

  if (success) {
    return data;
  }

  throw new Error(`Failed to load stakeholder securities: ${error}`);
};
