import { ActivityModules, CommentResponse } from 'js/components/activity-log';

import api from './api';

export const fetchComments = async (objectableType: ActivityModules, objectableId: number) => {
  const { success, data } = await api.get<CommentResponse[]>(
    `/comments?commentable_type=${objectableType}&commentable_id=${objectableId}`,
  );

  if (success) {
    return data;
  }

  throw new Error('Failed to load comments');
};
