/* eslint-disable react/no-array-index-key */
import { CheckCircle } from '@phosphor-icons/react';
import classNames from 'classnames';
import { ReactElement } from 'react';

import Icon from 'js/design-system/Icon';
import Text from 'js/design-system/Text/Text';

import styles from './Campaign.module.scss';

export type CampaignListItem = {
  mainText: string;
  subText: string;
};

interface CampaignProps {
  illustration: ReactElement;
  heading: string;
  listItems: CampaignListItem[];
  className?: string;
  // If the heading text is less than 40 characters,
  // apply special styles so it wraps nicely at responsive intervals.
  shortHeading?: boolean;
}

export const Campaign = ({
  illustration,
  heading,
  listItems,
  className,
  shortHeading,
}: CampaignProps) => {
  return (
    <div className={classNames(styles.campaign, className)}>
      <div className={styles.illustration}>{illustration}</div>
      <div className={styles.body}>
        <Text
          variant="text-heading-h5"
          className={classNames(styles.heading, { [styles.short]: shortHeading })}
        >
          {heading}
        </Text>
        {listItems.map((listItem, i) => (
          <div key={i} className={styles.listItem}>
            <div className={styles.iconContainer}>
              <Icon icon={CheckCircle} size={20} color="oc-primary" />
            </div>
            <div>
              <Text variant="text-subheading-small">{listItem.mainText}</Text>
              <Text color="platform-gray-700" className={styles.subText}>
                {listItem.subText}
              </Text>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
