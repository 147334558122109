import { CONFIG as CURRENCY_CONFIG } from 'js/config/currency';
import GLOBALS from 'js/config/globals';
import { getFormattedCurrency, CurrencyCode } from 'js/utils/formatters/currency';
import { round } from 'js/utils/numbers';
import { isNumber } from 'js/utils/value';
import { EquityDisplayType, Formatter, PrimitiveGenericObject } from 'types';

export const getNumShares = (val: number, outstandingShares: number) =>
  (val * outstandingShares) / 100;

export const formatCash = (
  value?: number | null,
  { shortFormat = false, decimalPlace = 0, currency = 'USD', round: roundingFactor = 0 } = {},
) => {
  if (!isNumber(value)) return null;
  const roundedValue = round(value, { to: 10 ** roundingFactor });

  return getFormattedCurrency(roundedValue, {
    currencyCode: currency as CurrencyCode,
    shortFormat,
    decimalPlaces: decimalPlace,
  });
};

export const formatOwnershipPercent = (
  value?: number | null,
  { trailingZero = true, decimalPlaces = 3 } = {},
) => {
  if (!isNumber(value)) return null;

  return `${
    trailingZero ? value.toFixed(decimalPlaces) : parseFloat(value.toFixed(decimalPlaces))
  }%`;
};

export const formatShareCount = (
  value?: number | null,
  { shortFormat = false, withSharesQualifier = false } = {},
) => {
  if (!isNumber(value)) return null;

  const formatter = new Intl.NumberFormat('en-US', shortFormat ? { notation: 'compact' } : {});
  const displayValue = formatter.format(Math.round(value));

  return withSharesQualifier ? `${displayValue} shares` : displayValue;
};

export const formatEquity = (
  val: number,
  displayType: EquityDisplayType,
  totalShares: number,
  shortShareCount?: boolean,
) =>
  displayType === EquityDisplayType.PercentOwnership
    ? formatOwnershipPercent(val)
    : formatShareCount(getNumShares(val, totalShares), {
        shortFormat: shortShareCount,
      });

export const formatPercentile = (percentile?: number | null) => {
  if (!isNumber(percentile)) return { raw: null, formatted: null };

  const roundPercentile = Math.round(percentile);
  const displayPercentile = Math.min(
    Math.max(roundPercentile, GLOBALS.compPercentileLimits[0]),
    GLOBALS.compPercentileLimits[1],
  );
  const oneDigit = displayPercentile % 10;
  const twoDigit = displayPercentile % 100;

  let ordinal = 'th';

  if (oneDigit === 1 && twoDigit !== 11) {
    ordinal = 'st';
  } else if (oneDigit === 2 && twoDigit !== 12) {
    ordinal = 'nd';
  } else if (oneDigit === 3 && twoDigit !== 13) {
    ordinal = 'rd';
  }

  const getPrefix = () => {
    if (roundPercentile < displayPercentile) return '< ';
    if (roundPercentile > displayPercentile) return '> ';
    return '';
  };

  return {
    raw: percentile,
    formatted: `${getPrefix()}${displayPercentile}${ordinal}`,
  };
};

export const formatRangePosition = (position?: number | null) => {
  if (!isNumber(position)) return { raw: null, formatted: null };

  return {
    raw: position,
    formatted: `${position}%`,
  };
};

export const formatCompaRatio = (compaRatioValue?: number | null) => {
  if ((!compaRatioValue && compaRatioValue !== 0) || !Number.isFinite(compaRatioValue))
    return { raw: null, formatted: null };

  return {
    raw: compaRatioValue,
    formatted: compaRatioValue.toFixed(2),
  };
};

export const formatValue = (
  value: number | null | undefined,
  formatter: Formatter,
  options?: PrimitiveGenericObject,
) => {
  if (!isNumber(value)) return { raw: null, formatted: null, shortFormatted: null };

  const formatted = formatter(value, { shortFormat: false, decimalPlace: 0, ...options });
  const shortFormatted = formatter(value, { shortFormat: true, decimalPlace: 1, ...options });

  return {
    raw: value,
    formatted,
    shortFormatted,
    getDisplayValue: (
      shouldSmartFormat = true,
      shortFormatThreshold = CURRENCY_CONFIG.defaultShortFormatThreshold,
    ) => {
      return shouldSmartFormat && value > shortFormatThreshold ? shortFormatted : formatted;
    },
  };
};
