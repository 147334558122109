export default {
  view: {
    performanceScale: 'Scenario Performance Scale View',
    offerSettings: 'Scenario Offer Settings View',
    approvalTemplates: 'Scenario Approval Templates Vew',
  },
  create: {
    approvalsTemplate: 'Create Approvals Template',
  },
  save: {
    performanceScale: 'Save Performance Scale',
  },
};
