import { StrategyTemplate, StrategyType } from 'types';

import api from './api';

export const fetchStrategyTemplates = async (
  scenarioId: number | null,
  filters?: { strategyType?: StrategyType },
) => {
  const url = scenarioId ? `/scenarios/${scenarioId}/strategy_templates` : '/strategy_templates';
  const { success, data } = await api.get<StrategyTemplate[]>(url, { urlParams: { filters } });

  if (success) {
    return data;
  }

  throw new Error('Failed to load strategy templates');
};
