import { useNavigate } from 'react-router-dom';

import { DataLookupClickPayStrategySettingsEventDetails } from 'js/analytics/types/dataLookup';
import useTrackEvent from 'js/analytics/useTrackEvent';
import TextButton from 'js/design-system/TextButton/TextButton';
import useAccess from 'js/hooks/useAccess';
import useAppSelector from 'js/hooks/useAppSelector';
import { getById as companySelector } from 'js/selectors/companies';
import { PayStrategy } from 'types';

export const JobRoleHelpText = ({
  scenarioId,
  companyId,
}: {
  scenarioId: number;
  companyId: number;
}) => {
  const navigate = useNavigate();
  const company = useAppSelector((state) => companySelector(state, companyId));
  const { geoAdjustmentAccessible, updateRangeAccessible } = useAccess({ company });
  const { trackEvent } = useTrackEvent<DataLookupClickPayStrategySettingsEventDetails>();

  const handleNavigateToViewMarketData = () => {
    navigate(`/scenarios/${scenarioId}/data-lookup/market`);
  };

  return geoAdjustmentAccessible && updateRangeAccessible ? (
    <span>
      Don&apos;t see a role? Get ranges for a new job family in&nbsp;
      <TextButton
        onClick={() => {
          trackEvent('dataLookup.click.payStrategySettings');
          navigate(`/scenarios/${scenarioId}/pay-strategy?card=${PayStrategy.RangeGroup}`);
        }}
      >
        Pay Strategy Settings
      </TextButton>
      .
    </span>
  ) : (
    <span>
      Don&rsquo;t see a role? Contact an Admin or{' '}
      <TextButton onClick={handleNavigateToViewMarketData}>View Market Data</TextButton> instead.
    </span>
  );
};
