import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { updateUserStatus } from 'js/actions/currentUser';
import { fetchDepartments } from 'js/actions/departments';
import { fetchOne as fetchScenario } from 'js/actions/scenarios';
import ACTIONS from 'js/constants/actions';
import useAppDispatch from 'js/hooks/useAppDispatch';
import useAppSelector from 'js/hooks/useAppSelector';
import useNumberParams from 'js/hooks/useNumberParams';
import { currentUserRoleSelector } from 'js/selectors/currentUser';
import { getById as scenarioSelector } from 'js/selectors/scenarios';
import { FetchStatus, UserRole } from 'types';

const useScenarioEntry = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { scenarioId } = useNumberParams();
  const scenario = useAppSelector((state) => scenarioSelector(state, scenarioId));
  const currentUserRole = useAppSelector((state) =>
    currentUserRoleSelector(state, scenario?.companyId),
  );

  useEffect(() => {
    dispatch({
      type: ACTIONS.CLEAR_ALL_FILTERS,
    });
    dispatch(
      fetchScenario(
        scenarioId,
        () => {},
        () => {
          dispatch(updateUserStatus({ indexStatus: FetchStatus.Idle }));
          navigate('/');
        },
      ),
    );
    // eslint-disable-next-line
  }, [dispatch, scenarioId]); // Note: adding navigate as dependency will cause full page refreshes

  useEffect(() => {
    if (currentUserRole && currentUserRole !== UserRole.RewardsViewer) {
      dispatch(fetchDepartments(scenarioId));
    }
  }, [dispatch, scenarioId, currentUserRole]);

  return { scenario };
};

export default useScenarioEntry;
