import api from './api';

export type ConnectedCompany = {
  active: boolean;
  id: number;
  name: string;
};

export const fetchConnectedCompanies = async (companyId: number) => {
  const { success, data } = await api.get<ConnectedCompany[]>(
    `/companies/${companyId}/connected_companies`,
  );
  if (success) {
    return data;
  }

  throw new Error('Failed to load connected companies');
};

export const updateConnectedCompany = async (id: number, params: object) => {
  const { success, data } = await api.put<ConnectedCompany[]>(`/connected_companies/${id}`, {
    params: { connected_company: params },
  });

  if (success) {
    return data;
  }

  throw new Error('Failed to load connected companies');
};
