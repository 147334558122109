export default {
  view: {
    default: true,
    inputPage: 'Offers Input Page Viewx',
    approvalsPage: true,
    rangeList: 'Offers Range List View',
    summary: 'Offers Summary View',
  },
  send: {
    approval: 'Offers Approval Send',
  },
  edit: {
    approver: 'Offers Edit Approver',
  },
  remove: {
    approver: 'Offers Approver Remove',
    notified: 'Offers Notified Remove',
  },
  skip: {
    approval: 'Skip Approval',
  },
  share: {
    viaApproval: 'Offers Via Approval Share',
  },
  filter: 'Offers Filter',
  sort: 'Offers Sort',
  action: 'Offers Action',
  toggle: {
    summaryRange: 'Offers Summary Range Toggle',
  },
};
