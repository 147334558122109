import { parse } from 'qs';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { HRISConnectSuccessEventDetails } from 'js/analytics/types/hris';
import useTrackEvent from 'js/analytics/useTrackEvent';
import { connect, useUpdateCashConnection } from 'js/api/cashConnection';
import { alertError } from 'js/components-legacy/common/AlertMessage';
import ACTIONS from 'js/constants/actions';
import useAppDispatch from 'js/hooks/useAppDispatch';
import useAppSelector from 'js/hooks/useAppSelector';
import { currentUserSelector } from 'js/selectors/currentUser';
import localStorageSafe from 'js/utils/local-storage-safe';
import { logError } from 'js/utils/logger';
import { CompProviderCred } from 'types';

import { ConnectionProvider } from '../../../types/connection';

const GustoOauthCallback = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const query = parse(location.search.substring(1));
  const navigate = useNavigate();
  const currentUser = useAppSelector(currentUserSelector);
  const { trackEvent } = useTrackEvent<HRISConnectSuccessEventDetails>();

  const companyId = currentUser?.lastViewed?.companyId;
  const updateCashConnection = useUpdateCashConnection(Number(companyId));
  const localStorageKey = `${companyId}_gusto_redirect_to`;
  const redirect =
    localStorageSafe.getItem(localStorageKey) || `/onboarding/${companyId}/employees`;
  localStorageSafe.removeItem(localStorageKey);

  useEffect(() => {
    if (query.code && companyId) {
      connect({
        integrationProxyName: ConnectionProvider.Gusto,
        code: query.code as string,
        companyId,
        provider: ConnectionProvider.Gusto,
        useBasicAuthHeader: false,
        onSuccess: (newConnection: CompProviderCred) => {
          dispatch({
            type: ACTIONS.RECEIVE_CASH_CONNECTION,
            data: newConnection,
            id: companyId,
          });
          updateCashConnection();
          trackEvent('hris.connect.success', { source: 'gusto' });
        },
        onFailure: (error: any) => {
          logError(`Failed cash connection for companyId ${companyId}, code ${query.code}`, error);
          alertError(error);
        },
      });
      navigate(redirect);
    } else {
      logError(`Invalid code or company. code ${query.code}, companyId ${companyId}`);
      alertError('Error syncing salary provider.');
    }
  });
};

export default GustoOauthCallback;
