import useGetRange from 'js/components/pay-strategy/hooks/useGetRange';
import useAccess from 'js/hooks/useAccess';
import useAppSelector from 'js/hooks/useAppSelector';
import useFeatureAccess from 'js/hooks/useFeatureAccess';
import useNumberParams from 'js/hooks/useNumberParams';
import { getById as companySelector } from 'js/selectors/companies';
import { getById as scenarioSelector } from 'js/selectors/scenarios';

import useOnboardRangesCompletion from './useOnboardRangesCompletion';

const useOnboardRangesAccess = () => {
  const { scenarioId } = useNumberParams();
  const scenario = useAppSelector((state) => scenarioSelector(state, scenarioId));
  const company = useAppSelector((state) => companySelector(state, scenario?.companyId));
  const { hasRange: hasActiveRange } = useGetRange();
  const { updateRangeAccessible, useAccessReady } = useAccess({ company });
  const { hasFeatureAccess } = useFeatureAccess();
  const canHaveRanges = hasFeatureAccess('have_ranges');
  const { benchmarkConfirmed, rangesReady } = useOnboardRangesCompletion();
  const showOnboardRanges = !hasActiveRange && updateRangeAccessible;
  const showPreviewRangesInsight = !canHaveRanges && showOnboardRanges && benchmarkConfirmed;

  return {
    showOnboardRanges,
    hasActiveRange,
    updateRangeAccessible,
    showPreviewRangesInsight,
    ready: useAccessReady && rangesReady,
  };
};

export default useOnboardRangesAccess;
