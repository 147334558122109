import { CurrencyViewInfo } from 'js/components/common/currency-view/useCurrencyView';
import { isEmpty } from 'js/utils/value';
import {
  CompensationType,
  CompensationTypeDetails,
  Employee,
  EmployeeRangeBand,
  Percentile,
  PrimitiveGenericObject,
  keys,
} from 'types';

import {
  getCompType,
  getCompTypeDetails,
  getCostToTarget,
  getDifferenceToRange,
} from './compService';
import { formatCompaRatio, formatPercentile, formatRangePosition, formatValue } from './formatter';
import { getPercentileLevel, normalizePercentile } from './percentileService';
import { getRangePositionPerformance } from './rangeService';

export const hasMarketData = (employee: Partial<Employee>) =>
  !!(employee.jobRoleId && employee.compensation?.baseSalaryTargetUsd);

export const hasRangeData = (employee: Partial<Employee>) => !!employee.compensation?.ranges?.cash;

export const getValueByType = (
  type: CompensationType,
  employee: Pick<Employee, 'compensation'>,
) => {
  const typeDetails = getCompTypeDetails(type, employee);

  if (!typeDetails.valueKey) return null;

  return employee.compensation[typeDetails.valueKey];
};

export const getPercentile = (employee: Employee, typeDetails: CompensationTypeDetails) => {
  if (!typeDetails.percentileKey) return null;

  const percentile = employee.compensation[typeDetails.percentileKey];

  return normalizePercentile(percentile);
};

export const getRangePosition = (
  employee: Partial<Pick<Employee, 'compensation'>>,
  typeDetails: CompensationTypeDetails,
) => employee.compensation?.ranges[typeDetails.rangeKey]?.position;

export const getCompaRatio = (employee: Employee, typeDetails: CompensationTypeDetails) => {
  if (!typeDetails.valueKey) return null;

  return employee.compensation?.ranges[typeDetails.rangeKey]?.compaRatio;
};

export const getCommissionLabel = (
  type: CompensationType | null | undefined,
  employee: Partial<Employee>,
) => (type === CompensationType.SalaryOte && employee.jobRole?.commissionBased ? 'OTE' : null);

export const getCompensationSummaryByType = (
  type: CompensationType | null | undefined,
  employee: Partial<Employee>,
  currencyInfo?: CurrencyViewInfo,
) => {
  const compType = getCompType(type, employee);
  const typeDetails = getCompTypeDetails(compType);
  const data = employee.compensation;
  const formatDisplayValue = (value: number | null, options?: PrimitiveGenericObject) =>
    formatValue(value, typeDetails.formatter, options);
  const applyExchangeRate = (value: number | null) =>
    typeDetails.cash && currencyInfo && value ? value * currencyInfo.exchangeRate : value;

  const percentile = typeDetails.percentileKey && data ? data[typeDetails.percentileKey] : null;
  const targetPercentile =
    typeDetails.targetPercentileKey && data ? data[typeDetails.targetPercentileKey] : null;
  const value = applyExchangeRate(typeDetails.valueKey && data ? data[typeDetails.valueKey] : null);
  const targetValue = applyExchangeRate(
    typeDetails.targetValueKey && data ? data[typeDetails.targetValueKey] : null,
  );
  const costToTarget = applyExchangeRate(getCostToTarget(value, targetValue));
  const localValue =
    typeDetails.localValueKey && data && data[typeDetails.localValueKey] !== value
      ? data[typeDetails.localValueKey]
      : null;
  const cashFormatterOptions =
    typeDetails.cash && currencyInfo
      ? {
          currency: currencyInfo.code,
        }
      : undefined;

  return {
    type,
    compType,
    percentile: formatPercentile(percentile),
    targetPercentile: formatPercentile(targetPercentile),
    value: formatDisplayValue(value, cashFormatterOptions),
    targetValue: formatDisplayValue(targetValue, cashFormatterOptions),
    costToTarget: formatDisplayValue(costToTarget, cashFormatterOptions),
    commissionLabel: getCommissionLabel(type, employee),
    targetPosition: getPercentileLevel(percentile, targetPercentile),
    localValue: formatDisplayValue(
      localValue,
      employee.currencyCode ? { currency: employee.currencyCode } : undefined,
    ),
  };
};

export const getRangeSummaryByType = (
  type: CompensationType | null | undefined,
  employee: Employee,
  formatOptions = {},
  exchangeRate: number | null = 1,
) => {
  const compType = getCompType(type, employee);
  const typeDetails = getCompTypeDetails(compType);
  const compData = employee.compensation;
  const rangesData = compData.ranges[typeDetails.rangeKey] || ({} as EmployeeRangeBand);
  const formatDisplayValue = (value: number | null, options = formatOptions) =>
    formatValue(value, typeDetails.formatter, options);
  const applyExchangeRate = (value: number | null) =>
    value && exchangeRate && !type?.includes('equity') ? value * exchangeRate : value;

  const {
    position,
    min: minValue,
    max: maxValue,
    mid: midValue,
    compaRatio: compaRatioValue,
  } = rangesData;
  const value = applyExchangeRate(typeDetails.valueKey ? compData[typeDetails.valueKey] : null);
  const differenceToRange = getDifferenceToRange(
    value,
    applyExchangeRate(minValue),
    applyExchangeRate(maxValue),
  );
  const localValue =
    typeDetails.localValueKey && compData[typeDetails.localValueKey] !== value
      ? compData[typeDetails.localValueKey]
      : null;

  return {
    type,
    compType,
    position: formatRangePosition(position),
    compaRatio: formatCompaRatio(compaRatioValue),
    value: formatDisplayValue(value),
    minValue: formatDisplayValue(minValue),
    midValue: formatDisplayValue(midValue),
    maxValue: formatDisplayValue(maxValue),
    differenceToRange: formatDisplayValue(differenceToRange),
    commissionLabel: getCommissionLabel(type, employee),
    performance: getRangePositionPerformance(position),
    localValue: formatDisplayValue(
      localValue,
      employee.currencyCode ? { currency: employee.currencyCode } : undefined,
    ),
  };
};

// NOTES: use the same method as MarketPercentiles.get_percentile
export const getPercentileByValue = (value: number, percentiles: Percentile) => {
  const percentilesHash: Percentile = { ...percentiles, 0: 0 };
  const percentileKeys = keys(percentilesHash)
    .sort((a, b) => a - b)
    .map((key) => Number(key));
  let percentile: number | undefined;

  percentileKeys.forEach((key, index) => {
    if (!isEmpty(percentile)) return;

    const nextKey = percentileKeys[index + 1];
    const currentValue = percentilesHash[key];
    const nextValue = percentilesHash[nextKey];

    if (!isEmpty(nextValue) && (currentValue > value || value >= nextValue)) {
      return;
    }

    const valueDiff = value - currentValue;
    let valueRange = isEmpty(nextValue) ? currentValue : nextValue - currentValue;
    if (valueRange === 0) valueRange = 1;

    const percentileRange = isEmpty(nextKey) ? key : nextKey - key;
    const percentileDiff = Math.round((valueDiff / valueRange) * percentileRange);

    percentile = Math.min(key + percentileDiff, 100);
  });

  return percentile;
};

// TODO: Use this for now. Thinking of a better solution
// NOTES: percent: 0 -> 100
export const getValueByPercent = (percent: number, percentiles: Percentile) => {
  if (percent <= 0) return 0;

  let percentileKeys = keys(percentiles)
    .sort((a, b) => a - b)
    .map((key) => Number(key));
  const percentilesLength = percentileKeys.length;
  const percentilesHash: Percentile = {
    ...percentiles,
    0: 0,
    100: 0,
  };
  const step =
    (percentiles[percentileKeys[percentilesLength - 1]] - percentiles[percentileKeys[0]]) / 1000;

  if (percent >= 100) return percentilesHash[100];

  percentileKeys = keys(percentilesHash)
    .sort((a, b) => a - b)
    .map((key) => Number(key));
  const position = percentileKeys.findIndex((key) => {
    return Number(key) >= percent;
  });
  const minValue = percentilesHash[percentileKeys[position - 1]];

  if (position === -1) return percentilesHash[100];

  let value = minValue;
  let percentile = Number(getPercentileByValue(value, percentiles));

  while (percentile < percent) {
    value += step;
    percentile = Number(getPercentileByValue(value, percentiles));
  }

  return value;
};
