import { createQueryKeys } from '@lukemorales/query-key-factory';

import { fetchStrategyTemplates } from 'js/api/strategyTemplates';

const strategyTemplates = createQueryKeys('strategyTemplates', {
  list: (filters) => ({
    queryKey: [filters],
    queryFn: () => fetchStrategyTemplates(null, filters),
  }),
});

export default strategyTemplates;
