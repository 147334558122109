import { createContext } from 'react';

import { TabsContextProps } from './types';

export const tabsContextParams: TabsContextProps<any> = {
  activeTabKey: undefined,
  setActiveTabKey: () => {},
};

const TabsContext = createContext<TabsContextProps<any>>(tabsContextParams);

export default TabsContext;
