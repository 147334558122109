import { Lightning } from '@phosphor-icons/react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { HRISClickButtonEventDetails } from 'js/analytics/types/hris';
import useTrackEvent from 'js/analytics/useTrackEvent';
import IconButton from 'js/design-system/IconButton/IconButton';
import Tooltip from 'js/design-system/Tooltip/Tooltip';
import useAccess from 'js/hooks/useAccess';
import useAppSelector from 'js/hooks/useAppSelector';
import queries from 'js/queries';
import { getById as companySelector } from 'js/selectors/companies';
import { getByIdOrBase as scenarioSelector } from 'js/selectors/scenarios';

import styles from './HRISButton.module.scss';

export const HRISButton = ({ scenarioId }: { scenarioId: number }) => {
  const navigate = useNavigate();
  const scenario = useAppSelector((state) => scenarioSelector(state, scenarioId));
  const company = useAppSelector((state) => companySelector(state, scenario?.companyId));
  const cashConnection = useAppSelector((state) => state.cashConnection.data);
  const { data: equityConnection } = useQuery(
    queries.companies.detail(scenario?.companyId as number)._ctx.equityConnection,
  );
  const { syncScenarioAccessible } = useAccess({ company });
  const { trackEvent } = useTrackEvent<HRISClickButtonEventDetails>();

  if (scenario?.scenarioType === 'base') {
    const connectHris = () => {
      trackEvent('hris.click.button');
      navigate(`/companies/${scenario.companyId}/hris`);
    };

    if (!cashConnection?.status) {
      if (!syncScenarioAccessible) return null;

      return (
        <Tooltip content="Connect your HRIS provider">
          <IconButton
            icon={Lightning}
            iconColor="platform-gray-300"
            size="small"
            onClick={connectHris}
          />
        </Tooltip>
      );
    }
    if (cashConnection.status === 'active') {
      return syncScenarioAccessible ? (
        <Tooltip content={equityConnection?.status === 'active' ? 'Synced' : 'HRIS Synced'}>
          <IconButton
            icon={Lightning}
            iconColor="green-500"
            iconWeight="fill"
            size="small"
            onClick={connectHris}
          />
        </Tooltip>
      ) : (
        <IconButton
          icon={Lightning}
          iconColor="green-500"
          iconWeight="fill"
          size="small"
          className={styles.unhoverable}
        />
      );
    }
    if (cashConnection.status === 'disconnected' && syncScenarioAccessible) {
      if (!syncScenarioAccessible) return null;

      return (
        <Tooltip content="Reconnect your HRIS provider">
          <IconButton
            icon={Lightning}
            iconColor="red-500"
            iconWeight="fill"
            size="small"
            onClick={connectHris}
          />
        </Tooltip>
      );
    }
  }

  return null;
};
