import { AppDispatch, ElementSize } from 'types';

import ACTIONS from '../constants/actions';

export const setNavbarSelection = (tabName: string) => (dispatch: AppDispatch) => {
  dispatch({ type: ACTIONS.SET_NAVBAR_SELECTION, tabName });
};

export const setNavbarSize = (size: ElementSize) => (dispatch: AppDispatch) => {
  dispatch({ type: ACTIONS.SET_NAVBAR_SIZE, size });
};
