import { useEffect, useState } from 'react';

import { debounce } from 'js/utils/debounce';
import { ElementSize } from 'types';

export const defaultElementSize: ElementSize = {
  scrollWidth: undefined,
  scrollHeight: undefined,
  offsetWidth: undefined,
  offsetHeight: undefined,
  offsetLeft: undefined,
};

const useElementSize = <T extends HTMLElement>(ref: React.RefObject<T | null> | null) => {
  const [elementSize, setElementSize] = useState<ElementSize>({});
  const [observer, setObserver] = useState<ResizeObserver | null>(null);

  useEffect(() => {
    const element = ref?.current;

    if (element) {
      const debounceHandleResize = debounce(() => {
        setElementSize({
          scrollWidth: element.scrollWidth,
          scrollHeight: element.scrollHeight,
          offsetWidth: element.offsetWidth,
          offsetHeight: element.offsetHeight,
          offsetLeft: element.offsetLeft,
        });
      });

      if (window.ResizeObserver && !observer) {
        setObserver(new ResizeObserver(debounceHandleResize));
      }

      if (observer) {
        observer.observe(element);
        debounceHandleResize();
        return () => {
          observer.disconnect();
        };
      }
    }

    return undefined;
  }, [ref, observer]);

  return elementSize;
};

export default useElementSize;
