export enum RiskType {
  Competitiveness = 'Competitiveness',
  InternalEquity = 'Internal equity',
  Overspend = 'Overspend',
}

export type RiskLevel = 'high' | 'medium' | 'low';

export interface RiskAssessmentData {
  name: RiskType;
  value: number;
  formattedValue: string | null;
  valueLabel: string;
  ctaText: string;
  ctaTitle: string;
  tooltipTitle: string;
  riskScaleDescription: string;
  getRiskScore: (value: number) => RiskLevel;
}
