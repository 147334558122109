import { CaretDown, Plus } from '@phosphor-icons/react';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { UpgradeClickLinkEventDetails } from 'js/analytics/types/upgrade';
import useTrackEvent from 'js/analytics/useTrackEvent';
import api from 'js/api/api';
import Badge from 'js/design-system/Badge';
import Button from 'js/design-system/Button/Button';
import { ButtonSize, ButtonVariant } from 'js/design-system/Button/types';
import Icon from 'js/design-system/Icon';
import Menu, { MenuItem } from 'js/design-system/Menu/Menu';
import useAccess from 'js/hooks/useAccess';
import useAppSelector from 'js/hooks/useAppSelector';
import useFeatureAccess from 'js/hooks/useFeatureAccess';
import useScenarioNavigation from 'js/hooks/useScenarioNavigation';
import useUpgrade from 'js/hooks/useUpgrade';
import { getAll as scenariosSelector, getById as scenarioSelector } from 'js/selectors/scenarios';
import { Company, UpgradeEventSource } from 'types';

import styles from './ScenarioMenu.module.scss';

const ScenarioMenu = ({ scenarioId, company }: { scenarioId: number; company?: Company }) => {
  const navigate = useNavigate();
  const scenarios = useAppSelector(scenariosSelector);
  const currentScenario = useAppSelector((state) => scenarioSelector(state, scenarioId));
  const scenariosStatus = useAppSelector((state) => state.scenarios.indexStatus);

  const { newScenarioAccessible } = useAccess({ company });
  const { hasFeatureAccess } = useFeatureAccess();
  const { getScenarioRedirectionURL } = useScenarioNavigation();
  const { openUpgrade } = useUpgrade();
  const { trackEvent } = useTrackEvent<UpgradeClickLinkEventDetails>();

  const [items, setItems] = useState<MenuItem<number>[][]>([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);

  const loadingItem = { value: 0, label: 'Loading...', disabled: true };

  useEffect(() => {
    setLoading(scenariosStatus === 'loading');

    if (scenariosStatus === 'succeeded') {
      setItems(() => {
        const items = scenarios.map((scenario) => ({
          value: scenario.id,
          label: scenario.name,
          default: scenario.id === scenarioId,
        }));

        return [
          items.filter((i) => i.value === company?.baseScenarioId),
          items.filter((i) => i.value !== company?.baseScenarioId),
        ];
      });
    }
  }, [company?.baseScenarioId, scenarioId, scenarios, scenariosStatus]);

  if (!currentScenario || !company) return null;

  const upgradeClick = () => {
    trackEvent('upgrade.click.link', { source: UpgradeEventSource.NewScenario });
    api.post('upgrade_click_event', {
      params: { source: UpgradeEventSource.NewScenario },
    });
    openUpgrade();
  };

  const trigger = (
    <div className={classNames(styles.trigger, { [styles.active]: open })}>
      <span>{currentScenario.name}</span>
      <Icon icon={CaretDown} size={16} />
    </div>
  );

  const newScenarioButton = hasFeatureAccess('add_scenarios') ? (
    <Button
      variant={ButtonVariant.Filled}
      size={ButtonSize.Medium}
      leftIcon={Plus}
      onClick={() => navigate(`/companies/${company.id}/scenarios/new`)}
    >
      New scenario
    </Button>
  ) : (
    <Button
      variant={ButtonVariant.Filled}
      size={ButtonSize.Medium}
      leftIcon={Plus}
      onClick={upgradeClick}
      className={styles.upgrade}
    >
      <span>New scenario</span>
      <Badge
        size="large"
        backgroundColor="upgrade"
        color="neutral-full-black"
        className={styles.badge}
      >
        Upgrade
      </Badge>
    </Button>
  );

  return (
    <Menu
      trigger={trigger}
      menuItems={loading ? [loadingItem] : items}
      onSelect={(scenarioId) => navigate(getScenarioRedirectionURL(scenarioId))}
      actionButton={newScenarioAccessible ? newScenarioButton : undefined}
      filterable
      resetOnSelect
      stickyFirstSection
      highlightSelectedItem
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
    />
  );
};

export default ScenarioMenu;
