import { useState } from 'react';

import ShareModal from 'js/components/admin-console/manage-scenarios/ShareModal';
import Button from 'js/design-system/Button/Button';
import { ButtonSize, ButtonVariant } from 'js/design-system/Button/types';
import Tooltip from 'js/design-system/Tooltip/Tooltip';
import { Scenario } from 'types';

import styles from './ShareScenarioModal.module.scss';

interface ShareScenarioModalProps {
  scenario: Scenario;
  companyId: number;
}

const ShareScenarioModal = ({ scenario, companyId }: ShareScenarioModalProps) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Tooltip content="Share this scenario with a coworker">
        <Button
          variant={ButtonVariant.Ghost}
          size={ButtonSize.Medium}
          onClick={() => setOpen(true)}
          className={styles.shareBtn}
        >
          <span>Share</span>
        </Button>
      </Tooltip>
      <ShareModal
        open={open}
        onHide={() => setOpen(false)}
        scenario={scenario}
        companyId={companyId}
        expandUsers={false}
      />
    </>
  );
};

export default ShareScenarioModal;
