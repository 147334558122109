import Form from 'react-bootstrap/Form';
import NumericInput, { FormatInputValueFunction, NumberFormatValues } from 'react-number-format';
import { FormFieldProps } from 'types/form';

import Field from './Field';
import ValidationWrapper from './ValidationWrapper';

import styles from './NumberInput.module.scss';

export const formatPercentage = (value: string): string => {
  const parsedValue = Math.round(Number(value) * 100);

  if (parsedValue === 0) return '';

  return `${parsedValue}%`;
};
export const parsePercentage = (formattedValue: string): string => {
  const parsedValue = +formattedValue.replace(/%/g, '') / 100;
  return parsedValue || parsedValue === 0 ? String(parsedValue) : '';
};

export interface NumberInputProps extends FormFieldProps<number> {
  min?: number;
  max?: number;
  maxLength?: number;
  precision?: number;
  fixedDecimalScale?: boolean;
  allowNegative?: boolean;
  format?: string | FormatInputValueFunction;
  parse?: (formattedValue: string) => string;
  prefix?: string;
  suffix?: string;
  defaultEmptyValue?: number | null;
  onBlur?: (value: number) => void;
  endAdornment?: React.ReactNode | null;
}

const NumberInput = ({
  disabled,
  min,
  max,
  maxLength,
  placeholder,
  precision,
  fixedDecimalScale = true,
  allowNegative = false,
  format,
  parse,
  size,
  prefix = '',
  suffix = '',
  defaultEmptyValue = null,
  onBlur = () => {},
  endAdornment = null,
  autoFocus = false,
  ...rest
}: NumberInputProps) => {
  const validateValueRange = ({ floatValue }: NumberFormatValues) => {
    if (!floatValue && floatValue !== 0) {
      return true;
    }

    const defaultMin = Number.MIN_SAFE_INTEGER;
    const defaultMax = Number.MAX_SAFE_INTEGER;

    const minValue = min || (maxLength ? 1 - 10 ** maxLength : defaultMin);
    const maxValue = max || (maxLength ? 10 ** maxLength - 1 : defaultMax);

    return floatValue <= maxValue && floatValue >= minValue;
  };

  return (
    <Field {...rest}>
      {({ invalid, onChange, ...input }) => (
        <ValidationWrapper invalid={invalid} className={styles.numberInputField}>
          <Form.Control
            as={NumericInput}
            {...input}
            onBlur={(e) => {
              onBlur(input.value);
              input.onBlur(e);
            }}
            isInvalid={invalid}
            isAllowed={validateValueRange}
            thousandSeparator=","
            onValueChange={({ floatValue }: NumberFormatValues) => {
              onChange(floatValue || floatValue === 0 ? floatValue : defaultEmptyValue);
            }}
            format={format}
            removeFormatting={parse}
            disabled={disabled}
            decimalScale={precision}
            fixedDecimalScale={fixedDecimalScale}
            placeholder={placeholder}
            allowNegative={allowNegative}
            allowEmptyFormatting={false}
            prefix={prefix}
            suffix={suffix}
            size={size}
            autoFocus={autoFocus}
          />
          {endAdornment}
        </ValidationWrapper>
      )}
    </Field>
  );
};

export default NumberInput;
