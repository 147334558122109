import classnames from 'classnames';
import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { setViewMode } from 'js/actions/scenarioView';
import HideForRole from 'js/components/user-permissions/HideForRole';
import { navigationItems } from 'js/config/globals-ts';
import ACTIONS from 'js/constants/actions';
import useAppDispatch from 'js/hooks/useAppDispatch';
import useAppSelector from 'js/hooks/useAppSelector';
import useNumberParams from 'js/hooks/useNumberParams';
import usePrevious from 'js/hooks/usePrevious';
import { currentUserSelector } from 'js/selectors/currentUser';
import { getById as scenarioSelector } from 'js/selectors/scenarios';
import { CashDisplayType, EquityDisplayType, ScenarioViewMode, UserRole } from 'types';

import NavigationMenu from './NavigationMenu';

import styles from './ScenarioNavBar.module.scss';

interface NavigationItem {
  name: string;
  url: string;
  activeTabRoutes?: string[];
  label: string;
  new?: boolean;
  beta?: boolean;
}

const ScenarioNavBar = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { scenarioId } = useNumberParams();
  const scenario = useAppSelector((state) => scenarioSelector(state, scenarioId));
  const previousScenario = usePrevious(scenario);
  const user = useAppSelector(currentUserSelector);

  const defaultNavItem = user?.homeTab ?? navigationItems.market;

  const setCurrentViewMode = useCallback(() => {
    const viewMode =
      Object.values(navigationItems).find((mode: NavigationItem) => {
        const slugMatches = [mode.url, ...(mode.activeTabRoutes || [])].join('|');
        const pathMatch = new RegExp(`/${slugMatches}(/.*)?$`, 'i');
        return pathMatch.test(location?.pathname);
      }) || defaultNavItem;
    dispatch(setViewMode(viewMode?.name as ScenarioViewMode));
  }, [defaultNavItem, dispatch, location?.pathname]);

  const setDefaultCompDisplayOption = useCallback(() => {
    if (scenario?.id === previousScenario?.id) return;

    dispatch({
      type: ACTIONS.SET_CASH_DISPLAY_TYPE,
      data: scenario?.cashDisplayType || CashDisplayType.SalaryOte,
    });

    dispatch({
      type: ACTIONS.SET_EQUITY_DISPLAY_TYPE,
      data: scenario?.equityDisplayType || EquityDisplayType.NumOfShares,
    });
  }, [dispatch, scenario, previousScenario]);

  useEffect(() => {
    setCurrentViewMode();
    setDefaultCompDisplayOption();
  }, [setCurrentViewMode, setDefaultCompDisplayOption]);

  return (
    <HideForRole roles={[UserRole.RewardsViewer]}>
      <div className={styles.navbarContainer}>
        <div className={classnames('container-xl', styles.navbar)}>
          <NavigationMenu />
        </div>
      </div>
    </HideForRole>
  );
};

export default ScenarioNavBar;
