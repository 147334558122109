import { formatError } from 'js/components-legacy/common/AlertMessage';
import { removeFalsyValuesFromObject } from 'js/utils/common';
import {
  Employee,
  EmployeeMatch,
  EmployeePreviewResponse,
  EmployeeBenchmarkForm,
  BenchmarkQuerySource,
  Segment,
} from 'types';

import api from './api';

export const getSuggestEmployees = async ({
  scenarioId,
  suggest,
}: {
  scenarioId: number;
  suggest: { name: string };
}) => {
  const query = `q=${suggest?.name || ''}`;
  return api.get<EmployeeMatch[]>(`/scenarios/${scenarioId}/employee_matches?${query}`);
};

export const buildCompensation = async (
  scenarioId: number,
  employeeData: Partial<Employee>,
  // TODO: revisit to only allow enum BenchmarkQuerySource
  source?: BenchmarkQuerySource | string,
  payRangeId?: number | null,
  benchmarkId?: number | null,
) =>
  api.post<EmployeePreviewResponse>(`/scenarios/${scenarioId}/compensations`, {
    params: { employee: employeeData, source },
    urlParams: removeFalsyValuesFromObject({ benchmarkId, payRangeId }),
  });

export const fetchAllEmployees = async (scenarioId: number, visibleOnly: boolean) => {
  const { success, data, error } = await api.get<Employee[]>(`/scenarios/${scenarioId}/employees`, {
    urlParams: {
      visibleOnly,
    },
  });

  if (success) {
    return data;
  }

  throw new Error(`Failed to fetch employees: ${error}`);
};

export const bulkUpdateEmployees = async (
  scenarioId: number,
  bulkUpdate: EmployeeBenchmarkForm[],
) => {
  const { success, data } = await api.post(`scenarios/${scenarioId}/employees/bulk_update`, {
    params: { bulkUpdate },
  });

  if (success) {
    return data;
  }

  throw new Error('Failed to bulk update employees.');
};

export const getGeoScore = async (employeeId: number) => {
  const { success, data } = await api.get<Record<'geoScore', number>>(
    `/employees/${employeeId}/geo_score`,
  );

  if (success) {
    return data.geoScore;
  }

  throw new Error('Failed to get geo score');
};

export const fetchEmployeeSegments = async (employeeId: number) => {
  if (!employeeId) return [];

  const { success, data, error } = await api.get<Segment[]>(`/employees/${employeeId}/segments`);
  if (success) {
    return data;
  }

  throw new Error(formatError(error) || 'Failed to fetch employee segments');
};
