import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useFormState } from 'react-final-form';

import Avatar from 'js/design-system/Avatar/Avatar';
import Button from 'js/design-system/Button/Button';
import { ButtonSize, ButtonType, ButtonVariant } from 'js/design-system/Button/types';
import { ConnectedTextarea as Textarea } from 'js/design-system/Form/Textarea';
import useAmplifyStorage from 'js/hooks/useAmplifyStorage';
import getUsername from 'js/utils/user-name';

import { ActivityLogCommentFieldProps } from '../types';

import styles from './ActivityLogCommentField.module.scss';

const ActivityLogCommentField = ({ user }: ActivityLogCommentFieldProps) => {
  const { values, submitting, submitSucceeded } = useFormState();
  const { comment } = values;
  const [expanded, setExpanded] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);

  const handleFocus = () => {
    setExpanded(true);
  };

  const handleBlur = () => {
    if (comment) return;

    if (!buttonClicked) {
      setExpanded(false);
    }
  };

  const handleMouseDown = () => {
    setButtonClicked(true);
  };

  useEffect(() => {
    if (submitSucceeded) {
      setExpanded(false);
      setButtonClicked(false);
    }
  }, [submitSucceeded]);

  return (
    <div
      className={classNames(styles.container, {
        [styles.expanded]: expanded,
        [styles.disabled]: submitting,
      })}
    >
      <Avatar
        name={getUsername(user)}
        avatarSrc={useAmplifyStorage(user?.avatar)}
        colorIndex={user.id}
        variant="small"
        className={styles.avatar}
      />
      <div className={styles.area}>
        <Textarea
          name="comment"
          className={styles.field}
          minHeight={expanded ? 96 : 32}
          maxHeight={264}
          placeholder="Add comment..."
          onFocus={handleFocus}
          onBlur={handleBlur}
          disabled={submitting}
        />
        <Button
          variant={ButtonVariant.Filled}
          size={ButtonSize.Small}
          className={styles.button}
          type={ButtonType.Submit}
          disabled={submitting}
          onMouseDown={handleMouseDown}
        >
          Add comment
        </Button>
      </div>
    </div>
  );
};

export default ActivityLogCommentField;
