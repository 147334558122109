import { formatError } from 'js/components-legacy/common/AlertMessage';
import { Product } from 'types';

import api from './api';

export const fetchProducts = async ({ filters = {} }) => {
  const { success, data, error } = await api.get<Product[]>('/products', {
    urlParams: { filters },
  });

  if (success) return data;

  throw new Error(`Failed to fetch products. ${formatError(error)}`);
};

export const fetchProduct = async (id: number) => {
  const { success, data } = await api.get<Product>(`/products/${id}`);

  if (success) return data;

  throw new Error(`Failed to fetch product`);
};
