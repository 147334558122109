import { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import SnackbarContainer from 'js/design-system/Snackbar/SnackbarContainer';
import useChat from 'js/hooks/useChat';
import { storePromoUtmParams } from 'js/services/promoUtmService';

import ConfettiAnimation from './ConfettiAnimation';
import Spinner from './Spinner';

interface ContainerProps {
  children: React.ReactNode;
}

const Container = ({ children }: ContainerProps) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { refreshChat } = useChat();

  useEffect(() => {
    if (!location.hash) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  useEffect(() => {
    refreshChat();
  }, [location, refreshChat]);

  useEffect(() => {
    storePromoUtmParams(searchParams);
  }, [searchParams]);

  return (
    <div>
      {children}
      <Spinner />
      <ConfettiAnimation />
      <SnackbarContainer />
    </div>
  );
};

export default Container;
