import { logError } from 'js/utils/logger';
import { Company, GenericObject, User } from 'types';

import events from './events';
import { AnalyticEventKey, BaseEventDetailsWithSkipCompany } from './types';
import { getIntegrations, getOptions, resolveObjectValueFromStringPath } from './utils';

const trackEvent = <TEventDetails extends BaseEventDetailsWithSkipCompany>(
  eventKey: AnalyticEventKey,
  eventDetails: TEventDetails,
): void => {
  if (!window.analytics) return;

  const { skipCompany, ...details } = eventDetails;
  const eventName = resolveObjectValueFromStringPath(eventKey, events);

  if (!eventName) {
    throw new Error('Invalid event name.');
  }

  window.analytics.track(eventName, details, getOptions());
};

const transformCompany = (company: Company) => ({
  createdAt: new Date(),
  name: company.companyName,
  industry: company.industrySectorName,
  location: company.location,
  onboardStatus: company.onboardStatus,
  employeesCountBaseline: company.numEmployees,
  numScenarios: company.numScenarios,
  isTest: company.isTest,
  isPaid: company.paid,
  subscriptionExpDate: company.subscriptionExpirationDate,
  plan: company.productName,
});

const identify = (user: User, company: Company): void => {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const ddRum = window.DD_RUM;
    if (ddRum) {
      ddRum.onReady(() => {
        ddRum.setUser({
          id: user.id,
          name: `${user.firstName} ${user.lastName[0]}`,
          companyId: company.id,
        });
      });
    }
  } catch (error) {
    logError(error);
  }

  if (!window.analytics) return;

  const { createdAt, firstName, ssoId, userSegment, userType, email } = user;
  const traits = {
    email,
    createdAt,
    firstName,
    ssoId,
    userSegment,
    userType,
    company: transformCompany(company),
  } as GenericObject;
  const options: SegmentAnalytics.SegmentOpts = {
    integrations: getIntegrations(),
  };

  window.analytics.identify(user.id.toString(), traits, options, undefined);
};

const groupUserToCompany = (company: Company) => {
  if (!window.analytics) return;
  window.analytics.group(company.id.toString(), transformCompany(company));
};

export { events, groupUserToCompany, identify, trackEvent };
