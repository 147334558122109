import { useState } from 'react';

import EducationalModal from 'js/design-system/EducationalModal';
import Text from 'js/design-system/Text/Text';
import useVerifiedEmployees from 'js/hooks/useVerifiedEmployees';
import buttonize from 'js/utils/buttonize';

import LevelMatrixLink from './LevelMatrixLink';
import RoleDescriptionsLink from './RoleDescriptionsLink';

import styles from './Headings.module.scss';

export const BenchmarkRolesLink = () => (
  <RoleDescriptionsLink text={<span>benchmark roles</span>} className={styles.link} />
);

export const BenchmarkLevelsLink = () => (
  <LevelMatrixLink text={<span>benchmark levels</span>} className={styles.link} />
);

interface WhatIsBenchmarkingModalProps {
  show: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const WhatIsBenchmarkingModal = ({ show, setShowModal }: WhatIsBenchmarkingModalProps) => (
  <EducationalModal
    show={show}
    onHide={() => setShowModal(false)}
    title="What is benchmarking?"
    items={[
      <>
        Compensation benchmarking is the process of mapping your company&apos;s roles to a set of
        standard roles used by companies of your size and industry. This allows you to accurately
        compare &quot;apples to apples&quot; to determine how competitively you&apos;re paying. This
        is a key step in compensation analysis because a title alone tells you very little about the
        role. The same job title at two different organizations might represent quite different
        roles and responsibilities. Read through our <BenchmarkRolesLink /> and{' '}
        <BenchmarkLevelsLink /> so you can choose those that fit best.
      </>,
    ]}
  />
);

export const BenchmarkingTitle = () => {
  const { verifiedEmployeesPercent } = useVerifiedEmployees();

  return (
    <Text variant="text-heading-h5" color="platform-gray-900">
      {verifiedEmployeesPercent}% of your employees have been benchmarked.
    </Text>
  );
};

export const BenchmarkingSubtitle = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div className={styles.subtitle}>
      <span>
        To discover how you&apos;re paying relative to the market and access professional-grade pay
        bands for your entire organization, help us benchmark the rest of your employees.{' '}
        <span className={styles.modalTrigger} {...buttonize(() => setShowModal(true))}>
          What is benchmarking?
        </span>
        <WhatIsBenchmarkingModal show={showModal} setShowModal={setShowModal} />
      </span>
      <span>
        Click to download our <BenchmarkRolesLink /> and <BenchmarkLevelsLink />.
      </span>
    </div>
  );
};
