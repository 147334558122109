import { AppState, Company, FetchStatus } from 'types';

export const getById = (state: AppState, id?: number): Company | undefined =>
  state.companies.byId[Number(id)];

export const getByScenarioId = (state: AppState, scenarioId: number): Company | undefined => {
  const { companyId } = state.scenarios.byId[scenarioId];
  return state.companies.byId[companyId];
};

export const getIdFromScenarioOrCompany = (
  state: AppState,
  companyId: number,
  scenarioId: number,
): number | undefined => {
  if (companyId) return state.companies.byId[companyId]?.id;
  if (scenarioId) return state.scenarios.byId[scenarioId]?.companyId;

  return undefined;
};

export const readStatus = (state: AppState, id: number): FetchStatus | undefined =>
  state.companies.readStatus[id];
