import { AnyAction } from 'redux';

import { EmployeeUser, EmployeeUserRequest, FetchStatus, keys } from 'types';

import ACTIONS from '../constants/actions';

type ScenarioEmployeesUserState = {
  byScenarioId: Record<number, EmployeeUser[]>;
  statusByScenarioId: Record<number, FetchStatus>;
};

const initialState: ScenarioEmployeesUserState = {
  byScenarioId: {},
  statusByScenarioId: {},
};

export default (
  state: ScenarioEmployeesUserState = initialState,
  action: AnyAction = { type: null },
): ScenarioEmployeesUserState => {
  switch (action.type) {
    case ACTIONS.FETCHING_SCENARIO_EMPLOYEES_USERS:
      return {
        ...state,
        statusByScenarioId: {
          ...state.statusByScenarioId,
          [action.scenarioId]: FetchStatus.Loading,
        },
      };
    case ACTIONS.RECEIVE_SCENARIO_EMPLOYEES_USERS: {
      const { scenarioId, data } = action;

      return {
        byScenarioId: {
          ...state.byScenarioId,
          [scenarioId]: data,
        },
        statusByScenarioId: { ...state.statusByScenarioId, [scenarioId]: FetchStatus.Succeeded },
      };
    }
    case ACTIONS.UNI_UPDATE_EMPLOYEES_USERS: {
      const data: Record<number, EmployeeUserRequest> = action?.data;
      if (!data) return state;

      const byScenarioId = keys(data).reduce(
        (_byScenarioId: Record<number, EmployeeUser[]>, scenarioId: number) => {
          const stateEmployeeUser = state.byScenarioId[scenarioId];
          const newEmployeesUser = data[scenarioId];
          const oldEmployeesUser = stateEmployeeUser.find(
            ({ userId }) => userId === newEmployeesUser.userId,
          );
          _byScenarioId[scenarioId] = stateEmployeeUser.filter(
            ({ userId }) => userId !== newEmployeesUser.userId,
          );

          if (newEmployeesUser.employeeId) {
            _byScenarioId[scenarioId].push({
              ...oldEmployeesUser,
              ...newEmployeesUser,
            });
          }

          return _byScenarioId;
        },
        {},
      );

      return {
        ...state,
        byScenarioId: {
          ...state.byScenarioId,
          ...byScenarioId,
        },
      };
    }
    default:
      return state;
  }
};
