import { createQueryKeys } from '@lukemorales/query-key-factory';

import { fetchProduct, fetchProducts } from 'js/api/products';

const products = createQueryKeys('products', {
  list: ({ filters = {} }: { filters?: object } = {}) => ({
    queryKey: [{ filters }],
    queryFn: () => fetchProducts({ filters }),
  }),
  detail: (id: number) => ({
    queryKey: [id],
    queryFn: () => fetchProduct(id),
  }),
});

export default products;
