import classNames from 'classnames';
import React from 'react';

import { Tab } from './Tab';

import styles from './TabHeader.module.scss';

interface ITab<T> {
  value: T;
  title: string;
  icon?: React.ElementType;
}

interface Props<T> {
  tabs: ITab<T>[];
  activeTab: T;
  setTab: (tab: T) => void;
  className?: string;
}

export const TabHeader = <T,>(props: Props<T>) => {
  const { tabs, activeTab, setTab, className } = props;
  return (
    <div className={classNames(styles.tabHeader, className)}>
      {tabs.map((tab) => (
        <Tab
          key={tab.title}
          active={activeTab === tab.value}
          text={tab.title}
          icon={tab.icon}
          onClick={() => {
            setTab(tab.value);
          }}
        />
      ))}
    </div>
  );
};
