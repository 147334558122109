import api from 'js/api/api';
import { BenchmarkPercentiles } from 'types';

export const fetchPercentiles = async (benchmarkId: number, groupByRole = false) => {
  const { success, data } = await api.get<BenchmarkPercentiles[]>(
    `/analyst/benchmarks/${benchmarkId}/percentiles`,
    groupByRole ? { urlParams: { group_by_role: true } } : {},
  );
  if (success) {
    return data;
  }

  throw new Error('Failed to load benchmark percentiles');
};

export const getLastUploadResults = async () => {
  const { success, data } = await api.get<BenchmarkPercentiles[]>(
    '/analyst/benchmarks/last-uploaded-validation',
  );
  if (success) {
    return data;
  }

  throw new Error('Failed to get last uploaded benchmark percentiles validation results');
};
