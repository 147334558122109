/* eslint-disable @typescript-eslint/no-explicit-any */
import { FunnelSimple } from '@phosphor-icons/react';
import classnames from 'classnames';
import { ChangeEvent, useEffect, useState } from 'react';
import { FormSpy } from 'react-final-form';

import { Form } from 'js/components-legacy/form';
import { ButtonSize, ButtonVariant } from 'js/design-system/Button/types';

import Button from '../Button/Button';
import { Checkbox } from '../Form/Checkbox';
import TabSlider, { Tab } from '../TabSlider';
import BaseTable from './BaseTable';
import TableCell from './common/TableCell';
import TableRow from './common/TableRow';
import TableRowGroup from './common/TableRowGroup';
import { Column, Row, RowGroup } from './types';
import { toCellObject } from './utils';

import styles from './Table.module.scss';

// NOTES: If the selectable is undefined, it means selectable. So we need to define it as false
const isSelectable = (row: Row) => row.selectable !== false;

export interface TableProps {
  columns: Column[];
  data: RowGroup[] | Row[];
  filters?: JSX.Element;
  tableLegend?: React.ReactNode;
  onFilter?: (formValues: any) => void;
  selection?: Set<string>;
  setSelection?: (selection: Set<string>) => void;
  tabs?: Tab<string>[];
  defaultTab?: string;
  handleTabClick?: (tabName: string) => void;
  className?: string;
  tableContentClassName?: string;
  rowGroupClassName?: string;
  rowClassName?: string;
  headerRowClassName?: string;
  headerRowGroupClassName?: string;
  headerRowGroupStyle?: React.CSSProperties;
  checkboxClassName?: string;
  headerRowGroupRef?: React.RefObject<HTMLDivElement> | ((node: HTMLDivElement) => void);
  tableContentRef?: React.RefObject<HTMLDivElement>;
}

// TODO:
// - componentize filters so they can be used independently of this Table
// - sorting (column header click or dropdown?)
// - pagination

const Table = ({
  columns,
  data,
  filters,
  tableLegend,
  onFilter,
  selection,
  setSelection,
  tabs,
  defaultTab,
  handleTabClick,
  className,
  tableContentClassName,
  rowGroupClassName,
  rowClassName,
  headerRowGroupClassName,
  headerRowGroupStyle,
  headerRowClassName,
  checkboxClassName,
  headerRowGroupRef,
  tableContentRef,
}: TableProps) => {
  const [showFilters, setShowFilters] = useState(false);
  const [selected, setSelected] = useState<Set<string>>(selection || new Set());
  const selectable = !!setSelection;

  const isGrouped = (data: RowGroup[] | Row[]): data is RowGroup[] => {
    const firstGroup = (data as RowGroup[])[0];
    return firstGroup?.groupRow !== undefined || !!firstGroup?.rows;
  };

  const numRows = isGrouped(data)
    ? data.reduce((total, datum) => total + datum.rows.filter(isSelectable).length, 0)
    : (data as Row[]).filter(isSelectable).length;

  const isAllSelected = () => selected.size === numRows;
  const isSomeSelected = () => selected.size > 0;

  const updateSelection = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      selected.add(e.target.value);
    } else {
      selected.delete(e.target.value);
    }

    setSelected(new Set(selected));
  };

  const masterToggle = () => {
    if (isAllSelected()) {
      setSelected(new Set());
    } else if (isSomeSelected()) {
      setSelected(new Set());
    } else {
      const allIds = isGrouped(data)
        ? data.flatMap((d) => d.rows.filter(isSelectable).map((e) => String(e.id)))
        : (data as Row[]).filter(isSelectable).map((e) => String(e.id));
      const uniqueIds = new Set([...selected, ...allIds]);
      setSelected(uniqueIds);
    }
  };

  // pass selected up to parent component
  useEffect(() => {
    if (setSelection) {
      setSelection(selected);
    }
  }, [selected, setSelection]);

  // update selected if changed from the parent
  useEffect(() => {
    if (selection) {
      setSelected(selection);
    }
  }, [selection]);

  const renderRows = (rows: Row[]) => (
    <>
      {rows.map((row) => (
        <TableRow
          key={row.id}
          className={classnames(rowClassName, {
            [styles.selected]: isSelectable(row) && selected.has(String(row.id)),
          })}
        >
          {selectable && (
            <TableCell size="small" className={checkboxClassName}>
              <Checkbox
                value={row.id}
                checked={selected.has(row.id)}
                onChange={updateSelection}
                className={classnames({ [styles.hidden]: !isSelectable(row) })}
              />
            </TableCell>
          )}
          {Object.keys(row).map((key) => {
            if (['id', 'selectable'].includes(key)) return null;

            const cellObject = toCellObject(row[key]);

            return (
              <TableCell
                key={`${row.id}-${key}`}
                size={columns.find((c) => c.id === key)?.size}
                className={cellObject.className}
              >
                {cellObject.content}
              </TableCell>
            );
          })}
        </TableRow>
      ))}
    </>
  );

  return (
    <BaseTable className={classnames(styles.default, className)}>
      {(filters || tabs) && (
        <>
          <div className={styles.tableControls}>
            {/* TABS */}
            {tabs && <TabSlider tabs={tabs} activeKey={defaultTab} onTabSelect={handleTabClick} />}
            {tableLegend}
            {filters && (
              <Button
                leftIcon={FunnelSimple}
                variant={ButtonVariant.Outlined}
                size={ButtonSize.Medium}
                isActive={showFilters}
                onClick={() => setShowFilters(!showFilters)}
              >
                Filter
              </Button>
            )}
          </div>
          {/* FILTER DRAWER */}
          <Form
            onSubmit={() => {}}
            contentClassName={classnames(styles.tableFilters, { [styles.open]: showFilters })}
            actionsStyle={{ display: 'none' }}
          >
            <FormSpy
              subscription={{ values: true }}
              onChange={({ values }) => (onFilter ? onFilter(values) : null)}
            />
            {filters}
          </Form>
        </>
      )}
      <div className={tableContentClassName} ref={tableContentRef}>
        {/* HEADER */}
        <TableRowGroup
          ref={headerRowGroupRef}
          style={headerRowGroupStyle}
          className={headerRowGroupClassName}
        >
          <TableRow
            className={classnames(headerRowClassName, styles.header, {
              [styles.notGrouped]: !isGrouped(data),
              [styles.selectable]: selectable,
            })}
          >
            {selectable && (
              <TableCell size="small" className={checkboxClassName}>
                <Checkbox
                  value="select-all"
                  checked={isAllSelected() && selected.size > 0}
                  indeterminate={!isAllSelected() && selected.size > 0}
                  onChange={masterToggle}
                />
              </TableCell>
            )}
            {columns.map((column) => (
              <TableCell
                key={column.id}
                role="columnheader"
                size={column.size}
                className={classnames({ [styles.hidden]: column.hidden }, column.className)}
              >
                {column.display || column.id}
              </TableCell>
            ))}
          </TableRow>
        </TableRowGroup>

        {/* ROWS */}
        {isGrouped(data)
          ? data.map((group: RowGroup, groupIndex: number) => {
              const groupLabelCellObject = toCellObject(group.groupRow.label);

              return (
                <TableRowGroup
                  key={`${group.groupRow.label}-${groupIndex * 10}`}
                  className={rowGroupClassName}
                >
                  {!group.groupRow.hidden && (
                    <TableRow
                      style={group.headerStyle}
                      className={classnames(
                        styles.header,
                        styles.rowgroupHeader,
                        group.headerClassName,
                      )}
                      ref={group.headerRef}
                    >
                      {selectable && (
                        <TableCell size="small" className={checkboxClassName}>
                          <Checkbox value="" className={styles.hidden} />
                        </TableCell>
                      )}
                      <TableCell size="large" className={groupLabelCellObject.className}>
                        {groupLabelCellObject.content}
                      </TableCell>
                      {group.groupRow &&
                        Object.keys(group.groupRow).map((key) => {
                          if (key === 'label') return null;

                          const cellObject = toCellObject(group.groupRow[key]);

                          return (
                            <TableCell
                              key={`${group.groupRow.label}-${key}`}
                              size={columns.find((c) => c.id === key)?.size}
                              className={cellObject.className}
                            >
                              {cellObject.content}
                            </TableCell>
                          );
                        })}
                    </TableRow>
                  )}
                  {renderRows(group.rows)}
                </TableRowGroup>
              );
            })
          : renderRows(data)}
      </div>
    </BaseTable>
  );
};

export default Table;
