import { createContext } from 'react';

type ChannelArgs = {
  id?: string | number;
  channel: string;
};

type Callbacks = {
  connected: () => void;
  disconnected: () => void;
  initialized: () => void;
  received: (arg: any) => void;
  rejected: () => void;
};

type Subscription = {
  unsubscribe: () => void;
};

type Subscriptions = {
  create: (arg1: ChannelArgs, arg2: Callbacks) => Subscription;
};

export type ProviderContextProps = {
  disconnect: () => void;
  subscriptions: Subscriptions;
} | null;

const Context = createContext<null | ProviderContextProps>(null);
export default Context;
