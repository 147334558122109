import { BenchmarkQueryCredit } from 'types';

import api from './api';

export const fetchBenchmarkQueryCredits = async (companyId: number) => {
  const { success, data } = await api.get<BenchmarkQueryCredit[]>(
    `/companies/${companyId}/benchmark_query_credits`,
  );
  if (success) {
    return data;
  }

  throw new Error('Failed to load benchmark query credits');
};
