import FieldContainer from 'js/design-system/Form/FieldContainer';
import RadioButton from 'js/design-system/Form/RadioButton/RadioButton';
import { noop } from 'js/utils/common';

import { RadioFieldProps, Option } from './types';

const RadioField = ({
  name,
  label,
  labelPosition = 'left',
  options,
  selectedValue,
  innerWrapperClassName = '',
  onChange = noop,
  required = false,
  helpText,
  disabled,
  labelClassName,
}: RadioFieldProps) => {
  const handleChange = (value: Option) => {
    if (onChange) onChange(value);
  };

  return (
    <FieldContainer
      name={name}
      label={label}
      subLabel={helpText}
      labelPosition={labelPosition}
      required={required}
      labelClassName={labelClassName}
    >
      <div className={innerWrapperClassName}>
        {options?.map((option) => {
          const key = option.name && option.id ? `${option.name}_${option.id}` : option.value;
          return (
            <RadioButton
              key={key}
              label={option.label}
              value={option.value}
              name={name}
              selected={selectedValue === option.value || option.selected}
              helpText={option.helpText}
              onChange={() => handleChange(option as Option)}
              disabled={option.disabled ?? disabled}
            />
          );
        })}
      </div>
    </FieldContainer>
  );
};

RadioField.displayName = 'RadioField';

export default RadioField;
