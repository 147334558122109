import { CheckCircle } from '@phosphor-icons/react';

import Button from 'js/design-system/Button/Button';
import { ButtonSize, ButtonVariant } from 'js/design-system/Button/types';
import Icon from 'js/design-system/Icon';
import Modal from 'js/design-system/Modal';
import Text from 'js/design-system/Text/Text';
import { noop } from 'js/utils/common';

import { InformationalModalProps } from './types';

import styles from './InformationalModal.module.scss';

const InformationalModal = ({
  title,
  description,
  show = false,
  primaryLabel,
  secondaryLabel,
  secondaryVariant = ButtonVariant.Ghost,
  onPrimaryClick = noop,
  onSecondaryClick = noop,
  onHide,
  icon = CheckCircle,
  iconColor = 'primary-gray-400',
  iconWeight = 'bold',
}: InformationalModalProps) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      dialogClassName={styles.wrapper}
      contentClassName={styles.content}
    >
      <Icon icon={icon} size={72} color={iconColor} weight={iconWeight} className={styles.icon} />
      <Text Element="h5" variant="text-heading-h5" className={styles.title}>
        {title}
      </Text>
      <Text variant="text-body-large" color="platform-gray-700" className={styles.description}>
        {description}
      </Text>

      <div className={styles.buttonWrapper}>
        {typeof primaryLabel === 'string' ? (
          <Button
            variant={ButtonVariant.Filled}
            size={ButtonSize.Large}
            onClick={onPrimaryClick}
            className={styles.button}
          >
            {primaryLabel}
          </Button>
        ) : (
          primaryLabel
        )}

        {secondaryLabel && (
          <Button
            variant={secondaryVariant}
            size={ButtonSize.Large}
            onClick={onSecondaryClick}
            className={styles.button}
          >
            {secondaryLabel}
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default InformationalModal;
