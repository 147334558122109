import { Plus } from '@phosphor-icons/react';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { useForm, useFormState } from 'react-final-form';

import { workEmailValidation } from 'js/components-legacy/form/validations';
import Button from 'js/design-system/Button/Button';
import { ButtonSize, ButtonVariant } from 'js/design-system/Button/types';
import useAccess from 'js/hooks/useAccess';
import useAppDispatch from 'js/hooks/useAppDispatch';
import useAppSelector from 'js/hooks/useAppSelector';
import queries from 'js/queries';
import { getAll as companyUsersSelector } from 'js/selectors/companyUsers';
import { getByScenarioId as scenarioEmployeesUsersSelector } from 'js/selectors/scenarioEmployeesUsers';
import { getByScenarioId as scenarioUsersSelector } from 'js/selectors/scenarioUsers';
import { fetchCompanyUsers } from 'js/services/companyUsers';
import { fetchScenarioEmployeesUsers } from 'js/services/scenarioEmployeesUsers';
import { EmployeeUser, Scenario, Company, UserId, UserPermission, UserRole } from 'types';

import { EmployeeEmailWithRole } from '../../common/EmployeeEmailWithRole';
import { InviteEmployeeSuggest } from '../../common/InviteEmployeeSuggest';
import { getEmailFromEmployee, getEmployeeById } from '../../common/utils';

import styles from './AddUserFields.module.scss';

interface AddUserFieldsProps {
  scenario: Scenario;
  company: Company;
}

const SEARCH_FIELD_NAME = 'employeeSearch';
const NON_EMPLOYEE_USER_VALUE = '-1';

const AddUserFields = ({ scenario, company }: AddUserFieldsProps) => {
  const dispatch = useAppDispatch();
  const scenarioUsers = useAppSelector((state) => scenarioUsersSelector(state, scenario.id));
  const scenarioUsersByEmail = useMemo(
    () =>
      scenarioUsers.reduce(
        (acc: Record<string, UserPermission>, user) => ({ ...acc, [user.email]: user }),
        {},
      ),
    [scenarioUsers],
  );
  const scenarioEmployeesUsers = useAppSelector((state) =>
    scenarioEmployeesUsersSelector(state, scenario.id),
  );
  const scenarioEmployeesUsersByUserId = useMemo(
    () =>
      scenarioEmployeesUsers.reduce(
        (acc: Record<UserId, EmployeeUser>, user) => ({ ...acc, [user.userId]: user }),
        {},
      ),
    [scenarioEmployeesUsers],
  );
  const { data: employees = [] } = useQuery(queries.scenarios.detail(scenario.id)._ctx.employees());
  const companyUsers = useAppSelector(companyUsersSelector);
  const companyUsersByEmail = useMemo(
    () =>
      companyUsers.reduce(
        (acc: Record<string, UserPermission>, user) => ({ ...acc, [user.email]: user }),
        {},
      ),
    [companyUsers],
  );

  const form = useForm();
  const { values, invalid, submitting } = useFormState();
  const { email } = values;
  const { sharedToAdminAccessible, shareScenarioAccessible } = useAccess({ company });
  const employeeSearchFieldValue = values[SEARCH_FIELD_NAME];

  useEffect(() => {
    const employee = getEmployeeById(employees, employeeSearchFieldValue);
    const employeeExists = !!employee;

    if (employeeExists) {
      const employeeEmail = getEmailFromEmployee(employee);

      if (!workEmailValidation(employeeEmail)) {
        form.change('email', employeeEmail);
      }
      form.change('employeesUser.employeeId', employee.id);
    }
  }, [employeeSearchFieldValue, form, employees]);

  useEffect(() => {
    if (email === null) {
      form.change('role', UserRole.OrganizationLeader);
    }
  }, [email, form]);

  useEffect(() => {
    dispatch(fetchCompanyUsers(scenario.companyId));
    dispatch(fetchScenarioEmployeesUsers(scenario.id));
  }, [dispatch, scenario]);

  const validate = (email: string | null) => {
    const error = workEmailValidation(email);
    if (error) return error;

    const scenarioUser = email && scenarioUsersByEmail[email];
    const scenarioEmployeeUser =
      scenarioUser && scenarioEmployeesUsersByUserId[scenarioUser.userId];
    const isNonEmployeeSelected = employeeSearchFieldValue === 'non-employee';

    if (scenarioUser && (scenarioEmployeeUser || isNonEmployeeSelected))
      return 'Scenario is already shared with this user';

    return undefined;
  };

  const addNonEmployeeUser = () => {
    form.change('employeeSearch', NON_EMPLOYEE_USER_VALUE);
    form.change('email', null);
    form.change('employeesUser', null);
  };

  const renderHint = () => {
    if (invalid) return null;

    const companyUser = companyUsersByEmail[email];
    const scenarioUser = scenarioUsersByEmail[email];
    const scenarioEmployeeUser =
      scenarioUser && scenarioEmployeesUsersByUserId[scenarioUser.userId];
    const isNonEmployeeSelected = employeeSearchFieldValue === 'non-employee';

    return (
      <>
        {companyUser && !scenarioUser && (
          <div>
            A user with this email address already exists. Click Invite to add them to this
            scenario.
          </div>
        )}
        {scenarioUser && !scenarioEmployeeUser && !isNonEmployeeSelected && (
          <div>
            Scenario is already shared with this user. Click Invite to match with selected employee.
          </div>
        )}
      </>
    );
  };

  const getExistingUser = (userEmail: string | null) =>
    companyUsers.find((companyUser) => companyUser.email === userEmail);

  const setExistingUser = (value: string | null) => {
    const companyUser = getExistingUser(value);
    if (companyUser) {
      form.change('role', companyUser.role);
    }
  };

  const existingUser = getExistingUser(email);
  const isUserSaved = !!existingUser && !submitting;

  const errorMessage =
    values.employeeSearch === NON_EMPLOYEE_USER_VALUE && UserRole.TeamLeader === values.role
      ? 'You must select an employee match for a Team Leader user.'
      : undefined;

  return (
    <>
      <InviteEmployeeSuggest
        scenarioId={scenario.id}
        name="employeeSearch"
        label="Employee"
        helpText={
          <div>
            {errorMessage && <div className={styles.searchErrorMessage}>{errorMessage}</div>}
            <div>Which employee are you inviting?</div>
          </div>
        }
        placeholder="Select"
        defaultValue={
          values.employeeSearch === NON_EMPLOYEE_USER_VALUE ? 'Non-employee user' : undefined
        }
        actionButton={
          <Button
            variant={ButtonVariant.Ghost}
            size={ButtonSize.Medium}
            leftIcon={Plus}
            onClick={addNonEmployeeUser}
          >
            Add a non-employee user
          </Button>
        }
      />
      {employeeSearchFieldValue && (
        <>
          <EmployeeEmailWithRole
            onChange={(email) => setExistingUser(email ? `${email}` : null)}
            helpText={renderHint()}
            validate={(val) => validate(val ? `${val}` : null)}
            roleDisabled={!!existingUser}
            isUserFound={isUserSaved}
            sharedToAdminAccessible={sharedToAdminAccessible}
            shareScenarioAccessible={shareScenarioAccessible}
          />
        </>
      )}
    </>
  );
};

export default AddUserFields;
