export const capitalize = (lower) => {
  if (typeof lower !== 'string') return '';
  return lower.charAt(0).toUpperCase() + lower.slice(1);
};

export const parseCSVErrors = (errors) => {
  if (!errors) {
    return [];
  }

  if (typeof errors === 'string') {
    return [errors];
  }

  return errors.reduce((acc, curr, index) => {
    if (curr) {
      if (acc.length > 0) {
        acc[acc.length - 1] = `${acc[acc.length - 1]}\n`;
      }
      acc.push(`Row ${index + 3} has errors:\n${curr.map((msg) => `- ${msg}`).join('\n')}\n`);
    }
    return acc;
  }, []);
};

export const pluralize = (word, pluralWord, count, inclusive = false) =>
  `${inclusive ? `${count} ` : ''}${count === 1 ? word : pluralWord}`;

export const removeUnderscores = (str) => str.replace(/_/g, ' ');
