import { useEffect, useRef } from 'react';

import { setBannersSize } from 'js/actions/banners';
import useAppDispatch from 'js/hooks/useAppDispatch';
import useElementMutationSize from 'js/hooks/useElementMutationSize';
import useElementSize, { defaultElementSize } from 'js/hooks/useElementSize';
import { BannerPlace } from 'types';

import DemoBanner from './DemoBanner';
import EquitySyncBanner from './EquitySyncBanner';
import HrisSyncBanner from './HrisSyncBanner';
import OverLimitBanner from './OverLimitBanner';
import VerifiedEmployeeBanner from './VerifiedEmployeeBanner';

import styles from './Banners.module.scss';

const Banners = () => {
  const ref = useRef<HTMLDivElement | null>(null);
  const elementSize = useElementSize(ref);
  const elementMutationSize = useElementMutationSize(ref);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setBannersSize(elementSize));

    return () => {
      dispatch(setBannersSize(defaultElementSize));
    };
  }, [dispatch, elementSize]);

  useEffect(() => {
    dispatch(setBannersSize(elementMutationSize));

    return () => {
      dispatch(setBannersSize(defaultElementSize));
    };
  }, [dispatch, elementMutationSize]);

  return (
    <div className={styles.container} ref={ref}>
      <HrisSyncBanner />
      <EquitySyncBanner />
      <VerifiedEmployeeBanner bannerPlace={BannerPlace.EmployeeEdit} />
      <DemoBanner />
      <OverLimitBanner />
    </div>
  );
};

export default Banners;
