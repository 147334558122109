import { CaretDown, CaretUp, UserCircle } from '@phosphor-icons/react';
import { useEffect, useState } from 'react';

import GLOBALS from 'js/config/globals';
import Button from 'js/design-system/Button/Button';
import { ButtonSize, ButtonVariant } from 'js/design-system/Button/types';
import Icon from 'js/design-system/Icon';
import Text from 'js/design-system/Text/Text';
import useAppDispatch from 'js/hooks/useAppDispatch';
import useAppSelector from 'js/hooks/useAppSelector';
import { getByScenarioId } from 'js/selectors/scenarioUsers';
import { fetchScenarioUsers } from 'js/services/scenarioUsers';
import { Scenario, Company } from 'types';

import UserCondensedList from './UserCondensedList';
import UserFullList from './UserFullList';

import styles from './UserToggleList.module.scss';

interface UserToggleListProps {
  scenario: Scenario;
  company?: Company;
  expandUsers?: boolean;
  onExpanded?: (expandedState: boolean) => void;
}

const UserToggleList = ({
  scenario,
  company,
  expandUsers,
  onExpanded = () => {},
}: UserToggleListProps) => {
  const dispatch = useAppDispatch();
  const [expand, setExpand] = useState(!!expandUsers);
  const scenarioId = scenario.id;
  const users = useAppSelector((state) => getByScenarioId(state, scenarioId));
  const nonPendingRoleUsers = users.filter((user) => user.role !== GLOBALS.roles.pending);

  useEffect(() => {
    dispatch(fetchScenarioUsers(scenarioId));
  }, [dispatch, scenarioId]);

  useEffect(() => {
    onExpanded(expand);
  }, [expand, onExpanded]);

  return (
    <>
      <div className={styles.userContainer}>
        <div className={styles.header}>
          {expand ? (
            <Text variant="text-badge-large" color="platform-gray-500" className={styles.userCount}>
              <Icon icon={UserCircle} size={20} />
              <span>{`${nonPendingRoleUsers.length} Users`}</span>
            </Text>
          ) : (
            <UserCondensedList users={nonPendingRoleUsers} />
          )}
          <Button
            onClick={() => setExpand(!expand)}
            variant={ButtonVariant.Ghost}
            size={ButtonSize.Small}
            rightIcon={expand ? CaretUp : CaretDown}
          >
            {expand ? 'Hide' : 'See'} current users
          </Button>
        </div>
        {expand && <UserFullList scenario={scenario} company={company} />}
      </div>
    </>
  );
};

export default UserToggleList;
