import { useCallback } from 'react';

import { trackEvent } from 'js/analytics';
import useAppSelector from 'js/hooks/useAppSelector';
import useNumberParams from 'js/hooks/useNumberParams';
import { getById as scenarioSelector } from 'js/selectors/scenarios';

import { AnalyticEventKey, BaseEventDetailsWithSkipCompany } from './types';

const useTrackEvent = <TEventDetails extends BaseEventDetailsWithSkipCompany>() => {
  const { scenarioId, companyId } = useNumberParams();
  const scenario = useAppSelector((state) => scenarioSelector(state, scenarioId));

  const handleTrackEvent = useCallback(
    (
      eventKey: AnalyticEventKey,
      eventDetails?: Omit<TEventDetails, 'scenarioId' | 'companyId' | 'skipCompany'>,
    ): void => {
      trackEvent(eventKey, {
        scenarioId: scenarioId || null,
        companyId: companyId || scenario?.companyId || null,
        ...eventDetails,
      });
    },
    [companyId, scenario?.companyId, scenarioId],
  );

  return { trackEvent: handleTrackEvent };
};

export default useTrackEvent;
