import useAppSelector from 'js/hooks/useAppSelector';

import Snackbar from './Snackbar';

import styles from './Snackbar.module.scss';

const SnackbarContainer = () => {
  const snackbarList = useAppSelector((state) => state.snackbars.snackbarList);

  return (
    <div className={styles.snackbarContainer}>
      {snackbarList.map((s) => (
        <Snackbar key={s.id} {...s} />
      ))}
    </div>
  );
};

export default SnackbarContainer;
