import classNames from 'classnames';
import Form from 'react-bootstrap/Form';
import { FormFieldProps } from 'types/form';

import Tooltip from 'js/design-system/Tooltip/Tooltip';

import Field from './Field';

import styles from './Checkbox.module.scss';

interface CheckboxProps extends FormFieldProps<string | boolean> {
  type?: 'checkbox' | 'switch';
  revertedLabel?: boolean;
  revertedChecked?: boolean;
  checked?: boolean;
  tooltipContent?: string;
}

const Checkbox = ({
  label,
  type,
  checked,
  disabled,
  revertedLabel = false,
  revertedChecked = false,
  inputClassName,
  tooltipContent,
  groupClassName,
  ...rest
}: CheckboxProps) => {
  const fieldLabel = revertedLabel ? label : null;
  const boxLabel = revertedLabel ? null : label;

  return (
    <Field
      {...rest}
      groupClassName={classNames(styles.checkboxGroup, groupClassName)}
      label={fieldLabel}
      type={type}
      inputClassName={classNames(inputClassName, { [styles.switchInput]: type === 'switch' })}
    >
      {({ invalid, onChange, ...input }) => {
        const transformCheckedValue = (value: boolean) => {
          return revertedChecked ? !value : value;
        };
        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
          onChange(transformCheckedValue(event.target.checked));
        };
        const inputChecked = type === 'checkbox' ? input.checked : input.value;

        return (
          <Tooltip content={tooltipContent}>
            <div>
              <Form.Check
                label={boxLabel}
                disabled={disabled}
                isInvalid={invalid}
                inline
                {...input}
                checked={transformCheckedValue(checked !== undefined ? checked : inputChecked)}
                onChange={handleChange}
              />
            </div>
          </Tooltip>
        );
      }}
    </Field>
  );
};

export default Checkbox;
