const getUsername = (user) => {
  if (!user || Object.entries(user).length === 0) {
    return '';
  }
  if (user.firstName) {
    return `${user.firstName}${user.lastName ? ` ${user.lastName}` : ''}`;
  }
  if (user.email) {
    return user.email;
  }
  return '';
};

export default getUsername;
