import { Clock } from '@phosphor-icons/react';
import classNames from 'classnames';
import { SyntheticEvent, useCallback, useEffect, useState } from 'react';

import Badge from 'js/design-system/Badge/Badge';
import Button from 'js/design-system/Button/Button';
import { ButtonSize, ButtonVariant } from 'js/design-system/Button/types';
import IconButton from 'js/design-system/IconButton/IconButton';
import Menu, { MenuItem } from 'js/design-system/Menu/Menu';
import Tooltip from 'js/design-system/Tooltip/Tooltip';
import { getLevelRangeText } from 'js/services/levelService';

import { DataLookupSource } from '../../DataLookup';
import { clearRecentQueries, fetchRecentQueries } from '../api';

import styles from './RecentQueries.module.scss';

export const RecentQueriesMenu = ({
  source,
  scenarioId,
  onSelect,
}: {
  source: DataLookupSource;
  scenarioId: number;
  onSelect: (val: number) => void;
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<MenuItem<number>[]>([]);

  const getOptions = useCallback(async () => {
    setLoading(true);
    const queries = await fetchRecentQueries(scenarioId, source);
    const options = queries
      .filter((q) => q.tierNames.length)
      .map((q) => ({
        value: q.id,
        label: '',
        displayLabel: (() => {
          return (
            <div className={styles.option}>
              <div className={styles.top}>
                <span>{q.jobRole.name}</span>
                <Badge>{q.jobRole.jobArea.code}</Badge>
                <Badge>{q.jobRole.jobType.code}</Badge>
              </div>
              <div className={styles.bottom}>
                <span>{q.tierNames.join(', ')}</span>
                {source === DataLookupSource.GetJobPostRange && (
                  <>
                    <div className={styles.circle} />
                    <span>{getLevelRangeText(q.jobLevels)}</span>
                  </>
                )}
              </div>
            </div>
          );
        })(),
      }));

    setOptions(options);
    setLoading(false);
  }, [scenarioId, source]);

  const onClear = async (e: SyntheticEvent) => {
    e.stopPropagation();
    const res = await clearRecentQueries(scenarioId, source);
    if (res.result === 'ok') {
      await getOptions();
    }
  };

  useEffect(() => {
    if (open) {
      getOptions();
    }
  }, [getOptions, open]);

  return (
    <Menu
      trigger={
        <Tooltip content="See recent searches">
          <IconButton
            icon={Clock}
            size="medium"
            className={classNames({ [styles.active]: open })}
          />
        </Tooltip>
      }
      menuItems={options}
      placement="bottom-end"
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onSelect={onSelect}
      menuClassName={styles.recentQueriesMenu}
      loading={loading}
      actionButton={
        options.length > 0 ? (
          <Button variant={ButtonVariant.Ghost} size={ButtonSize.Medium} onClick={onClear}>
            Clear all recent searches
          </Button>
        ) : undefined
      }
    />
  );
};
