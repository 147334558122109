import Cookies from 'js-cookie';

import { logError } from 'js/utils/logger';
import { UtmParams, PromoUtmParams } from 'types';

export const UTM_COOKIE_KEY = 'ocUtm'; // this key is set by www site and should be read-only
export const UTM_COOKIE_KEY2 = 'ocUtm2'; // this key is used to store any url params passed
export const PROMO_COOKIE_KEY = 'ocPromo';

const storePromoCodeParams = (searchParams: URLSearchParams): void => {
  const code = searchParams.get('promo');
  if (code) {
    Cookies.set(PROMO_COOKIE_KEY, code);
  }
};

const storeSecondaryUtmParams = (searchParams: URLSearchParams): void => {
  const paramList = Array.from(searchParams).reduce((params: string[], pair: [string, string]) => {
    const [key, value] = pair;
    if (key.startsWith('utm_')) {
      params.push([key.replace('utm_', ''), value].join('='));
    }
    return params;
  }, []);

  if (paramList.length > 0) {
    Cookies.set(UTM_COOKIE_KEY2, paramList.join('&'));
  }
};

const getPromoCodeParams = (): string | null => Cookies.get(PROMO_COOKIE_KEY) || null;

export const getPrimaryUtmCookie = (): UtmParams | null => {
  try {
    const ocUtm = Cookies.get(UTM_COOKIE_KEY);
    if (ocUtm) {
      return Object.fromEntries(ocUtm.split('&').map((pair) => pair.split('=')));
    }
  } catch (error) {
    logError(error);
  }

  return null;
};

export const getSecondaryUtmCookie = (): UtmParams | null => {
  try {
    const ocUtm = Cookies.get(UTM_COOKIE_KEY2);
    if (ocUtm) {
      return Object.fromEntries(ocUtm.split('&').map((pair) => pair.split('=')));
    }
  } catch (error) {
    logError(error);
  }

  return null;
};

export const storePromoUtmParams = (searchParams: URLSearchParams): void => {
  storePromoCodeParams(searchParams);
  storeSecondaryUtmParams(searchParams);
};

export const getPromoUtmParams = (): PromoUtmParams | null => {
  const promo = getPromoCodeParams();
  const utm = getPrimaryUtmCookie();
  const result: PromoUtmParams = {};

  if (promo) result.promo = promo;
  if (utm) result.utm = utm;

  return Object.keys(result).length ? result : null;
};

export const getSecondaryUtmParams = (): PromoUtmParams | null => {
  const promo = getPromoCodeParams();
  const utm = getSecondaryUtmCookie();
  const result: PromoUtmParams = {};

  if (promo) result.promo = promo;
  if (utm) result.utm = utm;

  return Object.keys(result).length ? result : null;
};

export const utmParamsAreDifferent = (): boolean => {
  const a = Cookies.get(UTM_COOKIE_KEY);
  const b = Cookies.get(UTM_COOKIE_KEY2);
  if (a && b) {
    return a !== b;
  }
  return false;
};
