import GLOBALS from 'js/config/globals';
import { isNumber } from 'js/utils/value';
import {
  CompensationType,
  Employee,
  JobLevel,
  JobTypeName,
  PayRangeBand,
  RangeBandByJobLevelId,
  Scenario,
  keys,
} from 'types';

import { isValidJobRoleLevel } from '../levelService';
import { getCompTypeDetails } from './compService';
import { getRangePosition } from './employeeService';
import { getNumShares } from './formatter';

export const getClusterMidPoint = (x: number) => {
  const mid = (a: number, b: number) => (a + b) / 2;

  if (x < 0) return mid(-25, 0);

  if (x >= 0 && x < 25) return mid(0, 25);

  if (x >= 25 && x < 50) return mid(25, 50);

  if (x >= 50 && x < 75) return mid(50, 75);

  if (x >= 75 && x <= 100) return mid(75, 100);

  if (x > 100) return mid(100, 125);

  return 0;
};

export const getPositionPercentDistribution = (
  positions: (number | null)[],
  separators: number[],
) => {
  const fullSeparators = [Number.NEGATIVE_INFINITY, ...separators.sort((a, b) => a - b)];
  const separatorCount = fullSeparators.length;

  const percents = fullSeparators.reduce((acc: Record<number, number>, separator) => {
    acc[separator] = 0;
    return acc;
  }, {});
  let totalCount = 0;

  positions.forEach((position) => {
    if (isNumber(position)) {
      const separator = fullSeparators.find((currentValue, index) => {
        const nextValue =
          index < separatorCount - 1 ? fullSeparators[index + 1] : Number.POSITIVE_INFINITY;
        return position >= currentValue && position < nextValue;
      });
      percents[separator as number]++;
      totalCount++;
    }
  });

  if (totalCount > 0) {
    keys(percents).forEach((key) => {
      percents[key] = Math.round((percents[key] / totalCount) * 100);
    });
  }

  return percents;
};

const RANGE_POSITION_GROUPS = [
  {
    label: 'Below',
    subLabel: 'Below Range',
    startPoint: -25,
  },
  {
    label: '1st',
    subLabel: 'in 1st Quartile',
    startPoint: 0,
  },
  {
    label: '2nd',
    subLabel: 'in 2nd Quartile',
    startPoint: 25,
  },
  {
    label: '3rd',
    subLabel: 'in 3rd Quartile',
    startPoint: 50,
  },
  {
    label: '4th',
    subLabel: 'in 4th Quartile',
    startPoint: 75,
  },
  {
    label: 'Above',
    subLabel: 'Above Range',
    startPoint: 100,
  },
];

export const getPositionMidPointLabel = (x: number) => {
  const group = RANGE_POSITION_GROUPS.find((groupData) => {
    const midPoint = groupData.startPoint + 25 / 2;
    return x === midPoint;
  });

  return group ? group.subLabel : null;
};

export type RangePositionClusterType = {
  key: string;
  midPosition: number;
  employeeCount: number;
  groupLabel: string;
  groupSubLabel: string;
  percentage: number;
  departments: Record<number, number>;
  rangeGroups: Record<number, number>;
};

export const getRangePositionClusterByType = (type: CompensationType, employees: Employee[]) => {
  let totalCount = 0;

  const initialData = RANGE_POSITION_GROUPS.reduce(
    (acc: Record<number, RangePositionClusterType>, groupData) => {
      const midPoint = groupData.startPoint + 25 / 2;
      acc[midPoint] = {
        key: groupData.label,
        midPosition: midPoint,
        employeeCount: 0,
        groupLabel: groupData.label,
        groupSubLabel: groupData.subLabel,
        percentage: 0,
        departments: {},
        rangeGroups: {},
      };
      return acc;
    },
    {},
  );

  const clusters = employees.reduce((data, employee) => {
    const typeDetails = getCompTypeDetails(type, employee);
    const position = getRangePosition(employee, typeDetails);

    if (!isNumber(position)) return data;

    totalCount++;
    const midPoint = getClusterMidPoint(position);
    data[midPoint].employeeCount += 1;
    data[midPoint].departments[employee.departmentId] ||= 0;
    data[midPoint].departments[employee.departmentId] += 1;
    if (employee.payRangeGroupId) {
      data[midPoint].rangeGroups[employee.payRangeGroupId] ||= 0;
      data[midPoint].rangeGroups[employee.payRangeGroupId] += 1;
    }

    return data;
  }, initialData);

  if (totalCount > 0) {
    keys(clusters).forEach((key) => {
      clusters[key].percentage = Math.round((clusters[key].employeeCount / totalCount) * 100);
    });
  }

  return Object.values(clusters);
};

const RANGE_POSITION_PERFORMANCE_LEVELS = [
  {
    level: 'below',
    range: [Number.NEGATIVE_INFINITY, -1],
  },
  {
    level: 'within',
    range: [0, 100],
  },
  {
    level: 'above',
    range: [101, Number.POSITIVE_INFINITY],
  },
];

export const getRangePositionPerformance = (value: number | null | undefined) => {
  if (!isNumber(value)) return null;

  const position = RANGE_POSITION_PERFORMANCE_LEVELS.find(
    ({ range }) => value >= range[0] && value <= range[1],
  );
  return position?.level || null;
};

export const getRangeBandsByJobLevelId = (
  rangeBands: PayRangeBand[],
  jobTypeName: JobTypeName,
  compType: CompensationType,
  scenario: Scenario,
  imported: boolean,
  jobLevels: JobLevel[],
) => {
  let filterRangeBands: PayRangeBand[] = [];

  switch (compType) {
    case CompensationType.SalaryOte:
      filterRangeBands = rangeBands
        .filter((band) => band.jobTypeName === jobTypeName && band.compType === 'cash')
        .map((rangeBand) => ({
          ...rangeBand,
          min: Number(rangeBand.min),
          max: Number(rangeBand.max),
          mid: Number(rangeBand.mid),
        }));
      break;
    case CompensationType.EquityOwnershipPercent:
      filterRangeBands = rangeBands
        .filter((band) => band.jobTypeName === jobTypeName && band.compType === 'equity')
        .map((rangeBand) => ({
          ...rangeBand,
          min: Number(rangeBand.min),
          max: Number(rangeBand.max),
          mid: Number(rangeBand.mid),
        }));
      break;
    case CompensationType.EquityShareCount:
      filterRangeBands = rangeBands
        .filter((band) => band.jobTypeName === jobTypeName && band.compType === 'equity')
        .map((rangeBand) => ({
          ...rangeBand,
          min: getNumShares(Number(rangeBand.min), scenario.outstandingShares),
          max: getNumShares(Number(rangeBand.max), scenario.outstandingShares),
          mid: getNumShares(Number(rangeBand.mid), scenario.outstandingShares),
        }));
      break;
    default:
  }

  // Only return range bands for VP level execs, unless the range has been imported.
  if (!imported && jobTypeName === JobTypeName.Executive) {
    const vpJobLevelId = jobLevels
      .filter((jobLevel) => isValidJobRoleLevel(jobLevel, { validLevels: GLOBALS.vpLevels }))
      .map(({ id }) => id);
    filterRangeBands = filterRangeBands.filter(({ jobLevelId }) =>
      vpJobLevelId.includes(jobLevelId),
    );
  }

  const max =
    filterRangeBands.length > 0 ? Math.max(...filterRangeBands.map((band) => band.max)) : null;
  const min =
    filterRangeBands.length > 0 ? Math.min(...filterRangeBands.map((band) => band.min)) : null;

  return {
    min,
    max,
    byJobLevelId: filterRangeBands.reduce(
      (byJobLevelId: Record<number, Pick<RangeBandByJobLevelId, 'min' | 'max'>>, band) => {
        byJobLevelId[band.jobLevelId] = band;

        return byJobLevelId;
      },
      {},
    ),
    empty: filterRangeBands.length === 0,
  } as RangeBandByJobLevelId;
};
