import { X } from '@phosphor-icons/react';
import { useState } from 'react';

import { revoke } from 'js/actions/scenarios';
import ConfirmPopup from 'js/components/common/ConfirmPopup';
import IconButton from 'js/design-system/IconButton/IconButton';
import Text from 'js/design-system/Text/Text';
import useAppDispatch from 'js/hooks/useAppDispatch';
import { Scenario, UserPermission } from 'types';

interface UserDeleteButtonProps {
  scenario: Scenario;
  user: UserPermission;
}

const UserDeleteButton = ({ scenario, user }: UserDeleteButtonProps) => {
  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = useState(false);

  const deleteUser = (user: UserPermission) => {
    dispatch(revoke(scenario.id, user.email));
    setShowModal(false);
  };

  return (
    <>
      <IconButton
        icon={X}
        iconColor="primary-gray-600"
        onClick={() => setShowModal(true)}
        data-testid="delete-user-btn"
      />
      <ConfirmPopup
        title="Remove user"
        show={showModal}
        onConfirm={() => deleteUser(user)}
        onHide={() => setShowModal(false)}
        onCancel={() => setShowModal(false)}
      >
        <Text color="platform-gray-900">
          Are you sure you want to remove {user.fullName} from your scenario?
        </Text>
      </ConfirmPopup>
    </>
  );
};

export default UserDeleteButton;
