/* eslint-disable jsx-a11y/label-has-associated-control */
import classNames from 'classnames';

import Text from 'js/design-system/Text/Text';

import { RadioButtonProps } from './types';

import styles from './RadioButton.module.scss';

const RadioButton = ({
  selected,
  label,
  helpText,
  value,
  name,
  onChange,
  disabled,
  inputClassName,
}: RadioButtonProps) => {
  const id = `${name}_${value}`;

  return (
    <div>
      <div className={styles.wrapper}>
        <label className={styles.label}>
          <input
            id={id}
            className={classNames(styles.input, inputClassName)}
            type="radio"
            checked={selected}
            value={value}
            name={name}
            onChange={(event) => {
              event.stopPropagation();
              if (onChange) onChange();
            }}
            disabled={disabled}
          />
          <Text
            variant="text-misc-input"
            color={disabled ? 'primary-gray-500' : 'platform-gray-900'}
          >
            {label}
          </Text>
        </label>
      </div>
      {helpText ? (
        <Text className={styles.helpText} variant="text-misc-help" color="platform-gray-700">
          {helpText}
        </Text>
      ) : null}
    </div>
  );
};

export default RadioButton;
