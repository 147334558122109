import { useQuery } from '@tanstack/react-query';

import queries from 'js/queries';
import { Employee } from 'types';

const useSeatLimit = (scenarioId: number) => {
  const { data: employees = [] } = useQuery(queries.scenarios.detail(scenarioId)._ctx.employees());
  const overLimit = !!employees.find((e: Employee) => e.needUpgrade?.market);

  return { overLimit };
};

export default useSeatLimit;
