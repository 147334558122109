export enum FeatureFlags {
  hrisMergeToggle = 'hris_merge_toggle',
  useGustoIntegrationProxy = 'use_gusto_integration_proxy',
  totalRewardsUpgradePreview = 'total_rewards_upgrade_preview',
  enableRipplingIntegration = 'enable_rippling_direct_integration',
  selfService = 'self_service',
  benchmarkQueryCredits = 'benchmark_query_credits',
  expandBusinessPlanEmployeeBucket = 'expand_business_plan_employee_bucket',
  cycleLetters = 'cycle_letters',
  matrixGradient = 'matrix_gradient',
  approverOverride = 'approver_override',
  executiveSummary = 'executive_summary',
  launchpad = 'launchpad',
}
