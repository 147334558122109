import { ButtonSize } from 'js/design-system/Button/types';
import { FieldProps } from 'js/design-system/Form/ConnectedField';

export interface ImagePickerProps extends FieldProps<File> {
  imageType?: ImageType;
  width?: number;
  height?: number;
  helpText?: string;
  buttonSize?: `${ButtonSize}`;
}

export enum ImageType {
  Default = 'Default',
  Avatar = 'Avatar',
  Logo = 'Logo',
}
