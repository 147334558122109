import image from 'img/banners/ranges.svg';

import Banner from './Banner';

interface RangesBannerProps {
  dismiss(): void;
}

const RangesBanner = ({ dismiss }: RangesBannerProps) => {
  const onConfirm = () => {
    window.open(process.env.REACT_APP_RANGES_KNOWLEDGE_URL);
  };

  return (
    <Banner
      shown
      onConfirm={onConfirm}
      onDismiss={dismiss}
      title="Professional-grade pay ranges in minutes"
      text="Create professional-grade pay ranges in just three clicks with OpenComp’s Range Builder. Operate smarter financially, sidestep the complexity of pay ranges, and adhere to emerging legislation, no cost or consultant required."
      cta="Learn more"
      dismissText="Dismiss"
      image={image}
    />
  );
};

export default RangesBanner;
