import classNames from 'classnames';

import { UserAvatar } from 'js/design-system/Avatar';
import { UserPermission } from 'types';

import styles from './UserCondensedList.module.scss';

const MAX_USERS_SHOWN = 4;

interface UserCondensedListProps {
  users: UserPermission[];
}

const UserCondensedList = ({ users }: UserCondensedListProps) => {
  const totalNumber = users.length || 0;
  const displayedNumber = Math.min(totalNumber, MAX_USERS_SHOWN);
  const displayedUsers = users.slice(0, displayedNumber);

  return (
    <div className={styles.userList}>
      {displayedUsers.map((user) => (
        <UserAvatar
          key={user.userId}
          userPermission={user}
          variant="small"
          colorIndex={user.userId}
          wrapperClassName={styles.avatar}
        />
      ))}
      {totalNumber > MAX_USERS_SHOWN && (
        <div className={classNames(styles.avatar, styles.more)}>
          +{totalNumber - displayedNumber}
        </div>
      )}
    </div>
  );
};

export default UserCondensedList;
