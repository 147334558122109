import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';

import { OnboardBenchmarkEventDetails } from 'js/analytics/types/onboard';
import useTrackEvent from 'js/analytics/useTrackEvent';
import { getEventSourceValueByCurrentRoute } from 'js/analytics/utils';
import api from 'js/api/api';
import queries from 'js/queries';
import { calculatePercentComplete } from 'js/services/benchmarkService';
import {
  filterEmployees,
  getIncludedInTotalsEmployees,
  WORK_PERIOD,
} from 'js/services/employeeFilterService';
import { verified } from 'js/services/employeeService';
import { Employee, EmployeeBenchmarkForm } from 'types';

export const useBenchmarkingEvents = (scenarioId: number) => {
  const { trackEvent } = useTrackEvent<OnboardBenchmarkEventDetails>();
  const { data: allEmployees = [] } = useQuery(
    queries.scenarios.detail(scenarioId)._ctx.employees(),
  );
  const employees = filterEmployees(allEmployees, { workPeriodFilter: [WORK_PERIOD.current] });
  const verifiedEmployeesPercent = useMemo(
    () => calculatePercentComplete(getIncludedInTotalsEmployees(allEmployees)),
    [allEmployees],
  );

  const [employeesConfirmed, setEmployeesConfirmed] = useState<EmployeeBenchmarkForm[]>([]);
  const [initialUnconfirmedPercentage, setInitialUnconfirmedPercentage] = useState<number>();

  const verifiedEmployeesCount = employees.filter((employee: Employee) =>
    verified(employee),
  ).length;

  const percentRaw = (verifiedEmployeesCount / employees.length) * 100;
  const percent = Number.isNaN(Math.floor(percentRaw)) ? 0 : Math.floor(percentRaw);

  const unconfirmedPercent = 100 - percent;

  useEffect(() => {
    setInitialUnconfirmedPercentage(unconfirmedPercent);
    trackEvent('onboard.unconfirmed.employeesBenchmarkPercentage', {
      percent: unconfirmedPercent,
      source: getEventSourceValueByCurrentRoute(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (initialUnconfirmedPercentage && initialUnconfirmedPercentage !== unconfirmedPercent) {
      trackEvent('onboard.benchmark.employeesPercent', {
        total: verifiedEmployeesPercent,
        source: getEventSourceValueByCurrentRoute(),
      });

      api.post(`scenarios/${scenarioId}/employees/update_benchmarking_events`, {
        params: { employeesBenchmarked: employeesConfirmed.length },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialUnconfirmedPercentage, percent]);

  const sendCompletionEvents = async () => {
    trackEvent('onboard.benchmark.employee', {
      action: 'complete',
      source: getEventSourceValueByCurrentRoute(),
    });

    trackEvent('onboard.benchmark.employeesPercent', {
      total: verifiedEmployeesPercent,
      source: getEventSourceValueByCurrentRoute(),
    });

    await api.post(`scenarios/${scenarioId}/employees/update_benchmarking_events`, {
      params: { employeesBenchmarked: employeesConfirmed.length },
    });
  };

  return { employees, setEmployeesConfirmed, sendCompletionEvents };
};
