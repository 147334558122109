import { createQueryKeys } from '@lukemorales/query-key-factory';

import { fetchExternalSync } from 'js/api/externalSyncs';

const externalSyncs = createQueryKeys('externalSyncs', {
  detail: (id: string) => ({
    queryKey: [id],
    queryFn: () => fetchExternalSync(id),
  }),
});

export default externalSyncs;
