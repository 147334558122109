import { createContext, ReactNode } from 'react';

import useConfirmNavigator from 'js/hooks/useConfirmNavigator';
import { GenericObject } from 'types';

export type NavigationContextProps = {
  continueNavigate: (state?: GenericObject) => void;
  cancelNavigate: () => void;
  showConfirm: boolean;
  unblockFunction: () => void;
  setBypassBlock: (bypass: boolean) => void;
};

export const NavigatorContext = createContext<NavigationContextProps>({
  continueNavigate: () => {},
  cancelNavigate: () => {},
  showConfirm: false,
  unblockFunction: () => {},
  setBypassBlock: () => {},
});

interface NavigatorContextProviderProps {
  children: ReactNode;
}

const NavigatorContextProvider = ({ children }: NavigatorContextProviderProps) => {
  const contextValue = useConfirmNavigator();

  return <NavigatorContext.Provider value={contextValue}>{children}</NavigatorContext.Provider>;
};

export default NavigatorContextProvider;
