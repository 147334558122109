export default {
  create: {
    cyclePage: 'Create Cycle Page',
  },
  edit: {
    cyclePage: 'Edit Cycle Page',
    proposalPage: 'Edit Proposal Page',
  },
  add: {
    budgetOwner: 'Add Budget Owner',
  },
  remove: {
    budgetOwner: 'Remove Budget Owner',
  },
  view: {
    cycleApprovalsPage: 'Cycle Approvals Page',
    proposalSummaryPage: 'Proposal Summary Page',
    cycleSummaryPage: 'Cycle Summary Page',
  },
  save: {
    draftCycle: 'Save Draft Cycle',
    draftProposal: 'Save Draft Proposal',
  },
  send: {
    cycleAssignments: 'Send Cycle Assignments',
  },
  action: {
    proposal: 'Proposal Action',
    cycle: 'Cycle Action',
    submitProposal: 'Submit Proposal',
  },
};
