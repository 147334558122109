import {
  FloatingArrow,
  FloatingPortal,
  arrow,
  autoPlacement,
  autoUpdate,
  flip,
  offset,
  safePolygon,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useHover,
  useInteractions,
  useRole,
  useTransitionStyles,
} from '@floating-ui/react';
import classNames from 'classnames';
import colors from 'css/_colors.scss';
import { cloneElement, useRef, useState } from 'react';

import { ButtonSize, ButtonVariant } from 'js/design-system/Button/types';

import Button from '../Button/Button';
import { DELAY, TooltipPlacement } from '../Tooltip/Tooltip';

import styles from '../Tooltip/Tooltip.module.scss';

interface PopoverProps {
  children: React.ReactElement;
  content?: React.ReactNode;
  /** Hide the pointer arrow. */
  hideArrow?: boolean;
  /** Popovers are triggered on hover by default. Use this to trigger on click. */
  triggerOnClick?: boolean;
  /** Displays a "close" button at the bottom of the popover with the given text. */
  closeButtonText?: string;
  /** Wait 500ms before showing and dismissing the tooltip.
   *
   * Ignored when `triggerOnClick={true}`. */
  delay?: boolean;
  /** ***Warning - this should only be used in special circumstances.***
   *
   * Overrides autoplacement behavior by restricting to one or more specific placements.
   */
  allowedPlacements?: `${TooltipPlacement}`[];
}

export const Popover = ({
  children,
  content,
  hideArrow,
  triggerOnClick = false,
  closeButtonText,
  delay,
  allowedPlacements,
}: PopoverProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const arrowRef = useRef(null);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      offset(8),
      shift(),
      allowedPlacements ? autoPlacement({ allowedPlacements }) : flip(),
      arrow({
        element: arrowRef,
      }),
    ],
    whileElementsMounted: autoUpdate,
  });

  const { styles: transitionStyles } = useTransitionStyles(context);

  const hover = useHover(context, {
    enabled: !triggerOnClick,
    delay: {
      open: delay ? DELAY : 0,
      close: 0,
    },
    move: false,
    handleClose: safePolygon(),
  });

  const click = useClick(context, {
    enabled: triggerOnClick,
  });

  const dismiss = useDismiss(context);

  const role = useRole(context, {
    role: 'dialog',
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([hover, click, dismiss, role]);

  if (!content) return children;

  const anchor = cloneElement(children, {
    ref: refs.setReference,
    ...getReferenceProps(),
  });

  return (
    <>
      {anchor}
      {isOpen && (
        <FloatingPortal>
          <div
            ref={refs.setFloating}
            className={classNames(styles.tooltip, styles.popover)}
            style={{ ...floatingStyles, ...transitionStyles }}
            {...getFloatingProps()}
          >
            {!hideArrow && (
              <FloatingArrow
                ref={arrowRef}
                context={context}
                height={4}
                width={8}
                strokeWidth={1}
                fill={colors['neutral-full-white']}
                stroke={colors['platform-gray-300']}
              />
            )}

            <div>{content}</div>

            {closeButtonText && (
              <Button
                variant={ButtonVariant.Outlined}
                size={ButtonSize.Small}
                onClick={() => setIsOpen(false)}
                className={styles.closeButton}
              >
                {closeButtonText}
              </Button>
            )}
          </div>
        </FloatingPortal>
      )}
    </>
  );
};
