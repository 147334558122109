import { useEffect, useState } from 'react';

import JobRoleSuggest from 'js/components/common/job-roles/JobRoleSuggest';
import { SuggestJobRole } from 'js/components/common/job-roles/types';
import { SelectXPosition, SelectYPosition } from 'js/design-system/Form/Select/Select';
import LoadingPlaceholder from 'js/design-system/LoadingPlaceholder/LoadingPlaceholder';
import { shouldVerify, SHOULD_VERIFY } from 'js/services/employeeService';
import { Employee } from 'types';

import { MULTIPLE_ROLES } from './MultiStatesBulkEditDrawer';

interface JobRoleInputProps {
  employee?: Employee;
  onSelect: (val: number) => void;
  benchmarkId: number;
  loading?: boolean;
  name?: string;
  positionX?: SelectXPosition;
  positionY?: SelectYPosition;
  initialJobRole?: SuggestJobRole;
  onToggle?: () => void;
}

export const JobRoleInput = ({
  employee,
  onSelect,
  benchmarkId,
  loading,
  name = 'jobRoleMenu',
  positionX,
  positionY,
  initialJobRole,
  onToggle,
}: JobRoleInputProps) => {
  const getInputValue = (employee?: Employee, initialJobRole?: SuggestJobRole) => {
    if (employee) {
      return employee.jobRole.name || employee.jobTitle || '';
    }
    return initialJobRole?.name || MULTIPLE_ROLES;
  };

  const [inputValue, setInputValue] = useState(getInputValue(employee, initialJobRole));

  const notVerified = shouldVerify(employee, SHOULD_VERIFY.jobRole);

  const multipleOption = {
    label: MULTIPLE_ROLES,
    value: 0,
  };

  useEffect(() => {
    const searchValue = getInputValue(employee, initialJobRole);
    setInputValue(searchValue);
  }, [employee, initialJobRole]);

  const handleChange = (jobRole: SuggestJobRole | null) => {
    if (jobRole) {
      onSelect(jobRole.id);
    }
  };

  if (loading) {
    return <LoadingPlaceholder />;
  }

  return (
    <JobRoleSuggest
      name={name}
      benchmarkId={benchmarkId}
      defaultValue={inputValue}
      onChange={handleChange}
      needsVerification={notVerified}
      helpTextState="hidden"
      hideSelectedBadges
      positionX={positionX}
      positionY={positionY}
      appendedOption={employee ? undefined : multipleOption}
      onToggle={onToggle}
    />
  );
};
