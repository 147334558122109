import { ChartLineUp, UserCircle } from '@phosphor-icons/react';
import classNames from 'classnames';
import React from 'react';

import logo from 'img/opencomp-logos/white.svg';
import Icon from 'js/design-system/Icon';

import styles from './CompanyResemble.module.scss';

interface CompanyResembleProps {
  companyName?: string;
  segmentShortName?: string;
  numberEmployees?: number | null;
  children?: React.ReactNode;
  className?: string;
  bodyClassName?: string;
}

const iconColor = 'platform-gray-500';

const CompanyResemble = ({
  companyName = '',
  segmentShortName = '',
  numberEmployees = null,
  children,
  className,
  bodyClassName,
}: CompanyResembleProps) => (
  <div className={classNames(styles.companyResemble, className)}>
    <div className={styles.navBar}>
      <div className={styles.logoAndName}>
        <img src={logo} alt="OpenComp logo" />
        {companyName && <div>{companyName}</div>}
      </div>
      <div className={styles.companyInfo}>
        {numberEmployees !== null && (
          <div className={styles.numberEmployees}>
            <Icon className={styles.employeeIcon} icon={UserCircle} color={iconColor} size={24} />
            {numberEmployees || '000'}
          </div>
        )}
        <div className={styles.fundingRange}>
          <Icon icon={ChartLineUp} color={iconColor} />
          {segmentShortName}
        </div>
      </div>
    </div>
    <div className={classNames(styles.body, bodyClassName)}>{children}</div>
  </div>
);

export default CompanyResemble;
