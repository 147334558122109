import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useQuery } from '@tanstack/react-query';

import api from 'js/api/api';
import { JobType } from 'types';

export const fetchJobTypes = async () => {
  const { success, data } = await api.get<JobType[]>('/analyst/job_types');
  if (success) {
    return data;
  }

  throw new Error('Failed to load job roles');
};

export const jobTypes = createQueryKeys('analystJobTypes', {
  list: {
    queryKey: null,
    queryFn: fetchJobTypes,
  },
});

export const useJobTypes = () => {
  return useQuery(jobTypes.list);
};
