import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { resetRangeBands } from 'js/actions/rangeBands';
import { RangesBuilderEventDetails } from 'js/analytics/types/ranges';
import useTrackEvent from 'js/analytics/useTrackEvent';
import { NavigatorContext } from 'js/components/common/confirm-navigator/NavigatorContextProvider';
import { PayStrategyContext } from 'js/components/pay-strategy/context';
import useGetRange from 'js/components/pay-strategy/hooks/useGetRange';
import useAccess from 'js/hooks/useAccess';
import useAppDispatch from 'js/hooks/useAppDispatch';
import useAppSelector from 'js/hooks/useAppSelector';
import useFeatureAccess from 'js/hooks/useFeatureAccess';
import useNumberParams from 'js/hooks/useNumberParams';
import { useCreateRange, useUpdateRange } from 'js/mutations';
import queries from 'js/queries';
import { getById as companySelector } from 'js/selectors/companies';
import { getById as scenarioSelector } from 'js/selectors/scenarios';
import { PayRangeBuildType, PayRangeStatus, PayStrategy } from 'types';

const useRangeBuilder = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { scenarioId } = useNumberParams();
  const scenario = useAppSelector((state) => scenarioSelector(state, scenarioId));
  const company = useAppSelector((state) => companySelector(state, scenario?.companyId));
  const { trackEvent } = useTrackEvent<RangesBuilderEventDetails>();

  const {
    data: activeRange,
    hasRange: hasActiveRange,
    isSuccess: activeRangeFetched,
  } = useGetRange(PayRangeStatus.Active);
  const { data: draftRange, isSuccess: draftRangeFetched } = useGetRange(PayRangeStatus.Draft);
  const updateRangeMutation = useUpdateRange(scenarioId);
  const createRangeMutation = useCreateRange(scenarioId);

  const { updateRangeAccessible } = useAccess({ company });
  const { hasFeatureAccess, useAccessReady } = useFeatureAccess();
  const canUpdateRange = !!updateRangeAccessible && hasFeatureAccess('have_ranges');

  const { rangeEditable, rangeDirty, setRangeDirty, setRangeRecalculated } =
    useContext(PayStrategyContext);
  const { unblockFunction } = useContext(NavigatorContext);

  const [updating, setUpdating] = useState(false);

  const ready = activeRangeFetched && draftRangeFetched && useAccessReady && !updating;

  const payRange = useMemo(() => {
    if (!rangeEditable && activeRange) return activeRange;

    if (draftRange && draftRange.buildType === PayRangeBuildType.Auto) return draftRange;

    return null;
  }, [draftRange, activeRange, rangeEditable]);

  const startRangeBuilder = useCallback(
    async () => {
      await createRangeMutation.mutateAsync();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [scenarioId],
  );

  const handleUpdateSuccess = useCallback(
    (step: PayStrategy, recalculateRanges = false) => {
      if (!ready || !payRange?.id || !canUpdateRange) return;

      if (payRange.status === PayRangeStatus.Draft && step !== PayStrategy.Geo) {
        setRangeDirty(true);
      }
      setRangeRecalculated((hasBeenRecalculated) => hasBeenRecalculated || recalculateRanges);

      if (!payRange.visitedSteps.includes(step)) {
        updateRangeMutation.mutate({
          id: payRange.id,
          attrs: { visitedSteps: payRange.visitedSteps.concat(step) },
        });
      }

      queryClient.invalidateQueries(queries.payRanges.detail(payRange.id)._ctx.previews);
      dispatch(resetRangeBands());
    },
    [
      ready,
      payRange,
      canUpdateRange,
      queryClient,
      dispatch,
      setRangeDirty,
      setRangeRecalculated,
      updateRangeMutation,
    ],
  );

  const publishRange = useCallback(async () => {
    if (!ready || !payRange?.id || !canUpdateRange) return;

    if (payRange.status === PayRangeStatus.Active || (hasActiveRange && !rangeDirty)) {
      navigate(`/scenarios/${scenarioId}/ranges`);
      return;
    }

    await updateRangeMutation.mutateAsync({
      id: payRange.id,
      attrs: { status: PayRangeStatus.Active },
      onPreSuccess: () => {
        setUpdating(true);
      },
      onSuccess: () => {
        trackEvent(hasActiveRange ? 'ranges.update.complete' : 'ranges.complete.onboarding');
        unblockFunction();
        navigate(`/scenarios/${scenarioId}/ranges${hasActiveRange ? '' : '?status=created'}`);
        setUpdating(false);
      },
    });
  }, [
    ready,
    payRange,
    canUpdateRange,
    updateRangeMutation,
    scenarioId,
    unblockFunction,
    trackEvent,
    hasActiveRange,
    navigate,
    rangeDirty,
  ]);

  return {
    ready,
    hasActiveRange,
    payRange,
    canUpdateRange,
    startRangeBuilder,
    handleUpdateSuccess,
    publishRange,
  };
};

export default useRangeBuilder;
