import { useEffect, useMemo } from 'react';

import RawMultiSelect, { compactValues } from 'js/components-legacy/form/RawMultiSelect';
import { GENDER_OPTIONS } from 'js/config/globals-ts';
import { Gender, keys } from 'types';

interface RawGenderFilterProps {
  value: Gender[] | undefined;
  onChange: (selectedOptions: Gender[]) => void;
  onInit?: (options: Gender[]) => void;
  className?: string;
}

const RawGenderFilter = ({ value, onChange, onInit, className }: RawGenderFilterProps) => {
  const options = useMemo(
    () =>
      keys(GENDER_OPTIONS).map((gender) => ({
        label: GENDER_OPTIONS[gender].name,
        value: gender,
      })),
    [],
  );

  useEffect(() => {
    if (onInit && options) {
      onInit(options.map(({ value }) => value));
    }
  }, [onInit, options]);

  return (
    <RawMultiSelect
      value={value}
      onChange={onChange}
      className={className}
      options={options}
      placeholder="All Genders"
      allOption={{
        value: 0,
        label: 'All Genders',
      }}
      displayedValue={compactValues('Genders')}
    />
  );
};

export default RawGenderFilter;
