import { ReactNode } from 'react';

import { ActivityLogBaseEventDetails } from 'js/analytics/types/activityLog';
import { Sentiment } from 'js/design-system/Alert/types';
import { User } from 'types';

export interface ActivityLogProps<TActivityAction extends string, TActivityDetail> {
  initialActiveTab?: ActiveTab;
  source?: ActivityLogListSource<TActivityAction, TActivityDetail>[];
  onClose?: () => void;
  onCommentAdded?: () => void;
  user: ActivityUser;
  className?: string;
  analytics?: ActivityLogBaseEventDetails; // Track event details
}
export interface ActivityLogListProps<TActivityAction extends string, TActivityDetail> {
  source: ActivityLogListSource<TActivityAction, TActivityDetail>[];
}

export enum ActivityModules {
  Offers = 'Offer',
  EmployeeAdjustmentProposal = 'EmployeeAdjustmentProposal',
}

export interface ConnectedActivityLogProps<TActivityAction extends string, TActivityDetail>
  extends Omit<ActivityLogProps<TActivityAction, TActivityDetail>, 'source' | 'user'> {
  objectableId: number;
  objectableType: `${ActivityModules}`;
}

export enum ActiveTab {
  All = 'all',
  Comments = 'comments',
}

export interface ActivityUser extends Pick<User, 'id' | 'firstName' | 'lastName'> {
  userType: `${User['userType']}`;
  avatar?: string;
}

export type ActivityLogFormData = { comment: string };

export interface ActivityLogCommentFieldProps {
  user: ActivityUser;
}

export type ListComment = CommentResponse;
export type ListActivity<TActivityAction extends string, TActivityDetail> = ActivityResponse<
  TActivityAction,
  TActivityDetail
>;

export type ListCommentProps = CommentResponse;
export type ListActivityProps<TActivityAction extends string, TActivityDetail> = ListActivity<
  TActivityAction,
  TActivityDetail
>;

export type ActivityLogListSource<TActivityAction extends string, TActivityDetail> =
  | {
      type: 'comment';
      data: CommentResponse;
    }
  | {
      type: 'activity';
      data: ListActivity<TActivityAction, TActivityDetail>;
    };

export type ActivityTemplateValueProps<TDetail> = {
  user: ActivityUser;
  detail: TDetail;
  date: string;
};

export type ActivityResponse<TActivityAction extends string, TActivityDetail> = {
  action: `${TActivityAction}`;
  user: ActivityUser;
  detail?: TActivityDetail | undefined;
  createdAt: string;
};

export type CommentResponse = {
  comment: string;
  createdAt: string;
  user: ActivityUser;
};

export type ActivityTemplate<TActivityAction extends string, TActivityTemplateValueProps> = {
  [key in TActivityAction]?: {
    primaryTemplate: (props: TActivityTemplateValueProps) => ReactNode;
    secondaryTemplate?: (props: TActivityTemplateValueProps) => ReactNode;
    icon?: ReactNode;
  };
};

export type Type = 'basic' | 'hype-info' | 'hype-danger' | 'basic-end';

export interface SummaryReasoningProps {
  text: ReactNode;
  sentiment?: Sentiment;
  className?: string;
}

export type RejectedSummary = {
  rejectReason?: string | null;
  rejectNotes?: string;
};

export interface RejectedSummaryProps {
  summary: RejectedSummary;
  className?: string;
}

export type RevisedSummary = {
  reviseReason?: string | null;
  reviseNotes?: string;
};

export interface RevisedSummaryProps {
  summary: RevisedSummary;
  className?: string;
}

export interface ActivityRowProps {
  text: ReactNode;
  date: string;
}
