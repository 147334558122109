import { AnyAction } from 'redux';

import { FetchStatus, IndustrySector } from 'types';

import ACTIONS from '../constants/actions';

type IndustrySectorState = {
  allIds: number[];
  byId: Record<number, IndustrySector>;
  error?: string;
  status: FetchStatus;
};

const initialState: IndustrySectorState = {
  allIds: [],
  byId: {},
  status: FetchStatus.Idle,
};

export default (
  state: IndustrySectorState = initialState,
  action: AnyAction = { type: null },
): IndustrySectorState => {
  switch (action.type) {
    case ACTIONS.FETCHING_INDUSTRY_SECTORS:
      return { ...state, status: FetchStatus.Loading };
    case ACTIONS.RECEIVE_INDUSTRY_SECTORS:
      return {
        ...state,
        allIds: action.data.map((datum: IndustrySector) => datum.id),
        byId: action.data.reduce(
          (industrySectors: Record<number, IndustrySector>, industrySector: IndustrySector) => {
            industrySectors[industrySector.id] = industrySector;
            return industrySectors;
          },
          {},
        ),
        status: FetchStatus.Succeeded,
      };
    default:
      return state;
  }
};
