import { AnyAction } from 'redux';

import ACTIONS from '../constants/actions';

type ConfettiState = {
  shown: boolean;
};

const initialState: ConfettiState = {
  shown: false,
};

export default (
  state: ConfettiState = initialState,
  action: AnyAction = { type: null },
): ConfettiState => {
  switch (action.type) {
    case ACTIONS.SHOW_CONFETTI:
      return state.shown ? state : { ...state, shown: true };
    case ACTIONS.HIDE_CONFETTI:
      return state.shown ? { ...state, shown: false } : state;
    default:
      return state;
  }
};
