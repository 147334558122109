import classNames from 'classnames';
import { memo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { BenchmarkingTitle } from 'js/components/benchmarking/Headings';
import BenchmarkingTable from 'js/components/benchmarking/table/BenchmarkingTable';
import { useBenchmarkingEvents } from 'js/components/benchmarking/useBenchmarkingEvents';
import Button from 'js/design-system/Button/Button';
import { ButtonVariant } from 'js/design-system/Button/types';
import Text from 'js/design-system/Text/Text';
import useNumberParams from 'js/hooks/useNumberParams';
import useScenarioEntry from 'js/hooks/useScenarioEntry';
import useUpdateScenario from 'js/hooks/useUpdateScenario';
import useVerifiedEmployees from 'js/hooks/useVerifiedEmployees';

import styles from './BenchmarkEmployees.module.scss';

/**
 * this page is used to show benchmarked sample employees from the launchpad
 */
const BenchmarkEmployees = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const redirectUri = searchParams.get('redirect_uri');
  const { scenarioId } = useNumberParams();
  const { scenario } = useScenarioEntry();
  const { updateScenario } = useUpdateScenario({ scenarioId });
  const { areAllEmployeesVerified } = useVerifiedEmployees();

  const [selected, setSelected] = useState<Set<string>>(new Set());

  const { sendCompletionEvents } = useBenchmarkingEvents(scenarioId);

  const handleSubmit = async () => {
    if (areAllEmployeesVerified)
      await updateScenario({ benchmarkConfirmed: true }, { skipRefetchRelatedQueries: true });
    await sendCompletionEvents();
    navigate(redirectUri ?? `/scenarios/${scenarioId}/launchpad`);
  };

  return (
    <div className={classNames('container-xl', styles.container)}>
      <Text variant="text-heading-h5" color="platform-gray-900" className={styles.heading}>
        <BenchmarkingTitle />
      </Text>
      <div>To our reliable benchmark roles</div>
      <div className={styles.benchmarkingTable}>
        {scenario?.benchmarkId ? (
          <BenchmarkingTable
            benchmarkId={scenario.benchmarkId}
            selection={selected}
            setSelection={setSelected}
            noHeader
          />
        ) : null}
        <div className={styles.footer}>
          <Button variant={ButtonVariant.Filled} size="medium" onClick={handleSubmit}>
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
};

export default memo(BenchmarkEmployees);
