import { ButtonSize } from 'js/design-system/Button/types';
import ImagePicker from 'js/design-system/Form/ImagePicker/ImagePicker';
import { ImageType } from 'js/design-system/Form/ImagePicker/types';
import { FormFieldProps } from 'types';

import Field from './Field';

const AvatarInput = (props: FormFieldProps<File>) => (
  <Field {...props}>
    {({ value, onChange }) => (
      <ImagePicker
        onChange={onChange}
        value={value}
        imageType={ImageType.Avatar}
        buttonSize={ButtonSize.Small}
      />
    )}
  </Field>
);

export default AvatarInput;
