import { ApproverFormData } from './approval';

export type ApprovalTemplate = {
  id: number;
  name: string;
  approvalTemplateStages: ApprovalTemplateStage[];
};

export type ApprovalTemplateStage = {
  id: number;
  position: number;
  requiredApproverCount: number;
  approvalTemplateApprovers: ApprovalTemplateApprover[];
};

type ApprovalTemplateApprover = {
  email: string;
};

type ApprovalTemplateStagesAttribute = {
  requiredApproverCount: number;
  approvalTemplateApproversAttributes: ApproverFormData[];
};

export enum ApprovalTemplateFormAction {
  Update = 'update',
  Create = 'create',
}

export type ApprovalTemplateFormData = {
  action?: ApprovalTemplateFormAction;
  name?: string;
  newName?: string;
  scenarioId: number;
  approvalTemplateStagesAttributes: ApprovalTemplateStagesAttribute[];
};
