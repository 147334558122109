import Text from 'js/design-system/Text/Text';
import { getByScenarioId } from 'js/selectors/scenarioUsers';
import { Scenario, Company, UserPermission } from 'types';

import GLOBALS from '../../../../config/globals';
import useAccess from '../../../../hooks/useAccess';
import useAppSelector from '../../../../hooks/useAppSelector';
import RoleLabelBadge from '../../common/RoleLabelBadge';
import UserDeleteButton from './UserDeleteButton';

import styles from './UserFullList.module.scss';

interface UserFullListProps {
  scenario: Scenario;
  company?: Company;
}

const UserFullList = ({ scenario, company }: UserFullListProps) => {
  const { revokeScenarioUserAccessible } = useAccess({ company });
  const users = useAppSelector((state) => getByScenarioId(state, scenario.id));
  const nonPendingRoleUsers = users.filter((user) => user.role !== GLOBALS.roles.pending);

  const revocableRoles = [
    GLOBALS.roles.organizationLeader,
    GLOBALS.roles.teamLeader,
    GLOBALS.roles.recruiter,
    GLOBALS.roles.approver,
    GLOBALS.roles.rewardsViewer,
  ];

  return (
    <div className={styles.list}>
      {nonPendingRoleUsers.map((user: UserPermission) => (
        <div className={styles.listItem} key={user.userId}>
          <div className={styles.userInfo}>
            <Text variant="text-misc-input-bold">{user.fullName}</Text>
            <Text variant="text-misc-input" color="platform-gray-700">
              {user.jobTitle}
            </Text>
          </div>
          <div className={styles.rightColumn}>
            <RoleLabelBadge role={user.role} />
            {revokeScenarioUserAccessible && (
              <div className={styles.deleteBox}>
                {revocableRoles.includes(user.role) && (
                  <UserDeleteButton scenario={scenario} user={user} />
                )}
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default UserFullList;
