import classNames from 'classnames';
import { useState } from 'react';

import { ScenarioSortEventDetails } from 'js/analytics/types/scenario';
import useTrackEvent from 'js/analytics/useTrackEvent';
import { getEventSourceValueByCurrentRoute } from 'js/analytics/utils';
import { MARKET_SORT_OPTIONS, RANGE_SORT_OPTIONS, navigationItems } from 'js/config/globals-ts';
import ACTIONS from 'js/constants/actions';
import { Sort } from 'js/design-system/Grid/sort/Sort';
import { filterSortOptionsWithEquity } from 'js/design-system/Grid/sort/sortService';
import useAppDispatch from 'js/hooks/useAppDispatch';
import useAppSelector from 'js/hooks/useAppSelector';
import useShowEquity from 'js/hooks/useShowEquity';
import scenarioViewSelector from 'js/selectors/scenarioView';
import { MarketEmployeeSortBy, RangesEmployeeSortBy } from 'types';

import styles from './SortSelector.module.scss';

const SortSelector = () => {
  const { mode, employeeSort } = useAppSelector(scenarioViewSelector);
  const dispatch = useAppDispatch();
  const { trackEvent } = useTrackEvent<ScenarioSortEventDetails>();
  const [open, setOpen] = useState(false);
  const showEquity = useShowEquity();

  const isRangeMode = mode === navigationItems.ranges.name;

  const handleSort = (sortBy: MarketEmployeeSortBy | RangesEmployeeSortBy) => {
    dispatch({ type: ACTIONS.EMPLOYEE_SORT, data: sortBy });
    const source = getEventSourceValueByCurrentRoute();
    trackEvent('scenario.sort', { sortBy, source });
  };
  if (isRangeMode) {
    return (
      <Sort
        defaultValue={employeeSort as RangesEmployeeSortBy}
        onSelect={handleSort}
        sortOptions={filterSortOptionsWithEquity(RANGE_SORT_OPTIONS, showEquity)}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        iconColor="platform-gray-700"
        iconClassName={classNames({ [styles.open]: open })}
      />
    );
  }
  return (
    <Sort
      defaultValue={employeeSort as MarketEmployeeSortBy}
      onSelect={handleSort}
      sortOptions={filterSortOptionsWithEquity(MARKET_SORT_OPTIONS, showEquity)}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      iconColor="platform-gray-700"
    />
  );
};

export default SortSelector;
