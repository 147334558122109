import { useLocation, useNavigate } from 'react-router-dom';

import Text from 'js/design-system/Text/Text';
import TextButton from 'js/design-system/TextButton/TextButton';
import useAppSelector from 'js/hooks/useAppSelector';
import useNumberParams from 'js/hooks/useNumberParams';
import { getByIdOrBase as scenarioSelector } from 'js/selectors/scenarios';
import { isNumber } from 'js/utils/value';

import { BulkUserInviteNavigationState } from './types';

const BulkUserInviteCTA = ({ enableScenarioSelect = false }: BulkUserInviteNavigationState) => {
  const { scenarioId } = useNumberParams();
  const scenario = useAppSelector((state) => scenarioSelector(state, scenarioId));
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = () => {
    if (!scenario?.id) return;
    const state: BulkUserInviteNavigationState = {
      isAdminPage: !isNumber(scenarioId),
      enableScenarioSelect,
    };

    navigate(`/scenarios/${scenario?.id}/bulk-user-invite?from=${location.pathname}`, { state });
  };

  return (
    <Text Element="span" color="platform-gray-700">
      <TextButton onClick={handleClick}>Click here</TextButton> to invite multiple at once.
    </Text>
  );
};

export default BulkUserInviteCTA;
