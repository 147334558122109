import { SquaresFour } from '@phosphor-icons/react';
import classNames from 'classnames';
import { ReactNode, useCallback, useRef, useState } from 'react';

import { snackbar } from 'js/actions/snackbars';
import Button from 'js/design-system/Button/Button';
import { ButtonProps, ButtonSize, ButtonType, ButtonVariant } from 'js/design-system/Button/types';
import ConnectedRadioField from 'js/design-system/Form/RadioButton';
import RadioField from 'js/design-system/Form/RadioButton/RadioField';
import IconButton from 'js/design-system/IconButton/IconButton';
import Tooltip from 'js/design-system/Tooltip/Tooltip';
import useAppDispatch from 'js/hooks/useAppDispatch';
import { useClickOutside } from 'js/hooks/useClickOutside';

import { SettingsItem } from './types';

import styles from './DisplaySettings.module.scss';

export interface DisplaySettingsProps {
  settingItems: SettingsItem[];
  renderCustomToggle?: (toggle: () => void) => ReactNode;
  inForm?: boolean;
  applyButtonProps?: Partial<ButtonProps>;
  className?: string;
  onClose?: () => void;
}

const DisplaySettings = ({
  settingItems,
  renderCustomToggle,
  inForm,
  applyButtonProps,
  className,
  onClose,
}: DisplaySettingsProps) => {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const toggle = useCallback(() => setOpen((open) => !open), []);

  const renderToggle = useCallback(() => {
    if (renderCustomToggle) return renderCustomToggle(toggle);
    return (
      <Tooltip content="Change display settings">
        <IconButton
          size="medium"
          icon={SquaresFour}
          onClick={toggle}
          className={classNames({ [styles.menuOpen]: open })}
        />
      </Tooltip>
    );
  }, [open, renderCustomToggle, toggle]);

  const renderMenu = () => {
    const renderItem = (item: SettingsItem) => {
      const commonProps = {
        name: item.name,
        options: item.settingOptions,
        label: item.label,
        labelClassName: styles.label,
        innerWrapperClassName: styles.itemOptions,
        disabled: item.disabled,
        onChange: item.onChange,
      };

      return (
        item.settingOptions.length > 0 && (
          <div key={item.name} className={styles.item}>
            {inForm ? (
              <ConnectedRadioField {...commonProps} labelPosition="top" />
            ) : (
              <RadioField {...commonProps} labelPosition="top" />
            )}
          </div>
        )
      );
    };

    return (
      <div
        className={classNames(styles.menu, {
          [styles.open]: open,
        })}
      >
        <div className={styles.itemsPlaceholder}>
          {settingItems.map((item) => renderItem(item))}
        </div>
        {inForm && (
          <div className={styles.actionsContainer}>
            <Button
              variant={ButtonVariant.Filled}
              size={ButtonSize.Small}
              type={ButtonType.Submit}
              className={styles.actionButton}
              {...applyButtonProps}
              onClick={(event) => {
                if (applyButtonProps?.onClick) {
                  applyButtonProps.onClick(event);
                  setOpen(false);
                  return;
                }

                event.stopPropagation();
                setOpen(false);
                dispatch(
                  snackbar({
                    icon: SquaresFour,
                    message: 'Display settings updated.',
                    showCloseButton: false,
                  }),
                );
              }}
            >
              Apply
            </Button>
          </div>
        )}
      </div>
    );
  };

  useClickOutside(ref, () => {
    setOpen(false);
    if (onClose) onClose();
  });

  return (
    <div className={classNames(styles.displaySettingsContainer, className)} ref={ref}>
      {renderToggle()}
      {renderMenu()}
    </div>
  );
};

export default DisplaySettings;
