import { useCallback, useEffect, useMemo, useState } from 'react';

import { debounce } from 'js/utils/debounce';

const useElementRect = <T extends Element>(
  shouldDebounce = true,
): [DOMRect | undefined, (node: T) => void] => {
  const [rect, setRect] = useState<DOMRect>();
  const [domNode, setDomNode] = useState<T | null>(null);

  const onRefChange = useCallback((node: T) => {
    setDomNode(node);
  }, []);
  const set = useCallback(() => {
    if (domNode) setRect(domNode.getBoundingClientRect());
  }, [domNode]);
  const handleEvent = useMemo(() => (shouldDebounce ? debounce(set) : set), [set, shouldDebounce]);

  useEffect(() => {
    handleEvent();
  }, [handleEvent]);

  useEffect(() => {
    if (!domNode) return undefined;

    window.addEventListener('resize', handleEvent);
    return () => window.removeEventListener('resize', handleEvent);
  }, [domNode, handleEvent]);

  useEffect(() => {
    if (!domNode) return undefined;

    window.addEventListener('scroll', handleEvent, true);
    return () => window.removeEventListener('scroll', handleEvent, true);
  }, [domNode, handleEvent]);

  return [rect, onRefChange];
};

export default useElementRect;
