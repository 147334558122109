import ConnectedField, { ConnectedFieldProps } from 'js/design-system/Form/ConnectedField';
import { NumberTextFieldWithDropdownProps } from 'js/design-system/Form/NumberTextFieldWithDropdown/types';

import NumberTextFieldWithDropdown from './NumberTextFieldWithDropdown';

const ConnectedNumberTextFieldWithDropdown = (
  props: ConnectedFieldProps<NumberTextFieldWithDropdownProps>,
) => (
  <ConnectedField
    fieldType="input"
    renderField={(fieldProps: NumberTextFieldWithDropdownProps) => (
      <NumberTextFieldWithDropdown {...fieldProps} />
    )}
    {...props}
  />
);

export default ConnectedNumberTextFieldWithDropdown;
