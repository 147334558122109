import { Gear } from '@phosphor-icons/react';
import { useNavigate } from 'react-router-dom';

import IconButton from 'js/design-system/IconButton/IconButton';
import Tooltip from 'js/design-system/Tooltip/Tooltip';
import useNumberParams from 'js/hooks/useNumberParams';

import styles from './NavBar.module.scss';

const ScenarioSettings = () => {
  const navigate = useNavigate();
  const { scenarioId } = useNumberParams();

  return (
    <Tooltip content="Scenario settings">
      <IconButton
        icon={Gear}
        size="medium"
        className={styles.iconButton}
        onClick={() => navigate(`/scenarios/${scenarioId}/edit`)}
      />
    </Tooltip>
  );
};

export default ScenarioSettings;
