import { isValidElement } from 'react';

import { Cell, CellObject } from './types';

export const isCellObject = (cell: Cell): cell is CellObject => {
  return typeof cell === 'object' && !isValidElement(cell);
};

export const toCellObject = (cell: Cell): CellObject => {
  if (isCellObject(cell)) return cell;

  return { content: cell };
};
