import { isNumber } from 'js/utils/value';
import { Employee, Location } from 'types';

import { ANCHOR_LOCATION_ID } from './employeeFormService';
import { verified } from './employeeService';

export const getCounts = (employees: Employee[]) => ({
  total: employees.length,
  verified: employees.filter(verified).length,
});

export const calculatePercentComplete = (employees: Employee[]) => {
  const { total: employeesCount, verified: verifiedCount } = getCounts(employees);
  return employeesCount ? Math.round((verifiedCount / employeesCount) * 100) : 100;
};

export const getBenchmarkLocationTooltip = ({
  shouldVerify,
  benchmarkLocationName,
  cashOverrideName,
  equityOverrideName,
  cashAdjustment,
  equityAdjustment,
}: {
  shouldVerify: boolean;
  benchmarkLocationName?: string;
  cashOverrideName?: string;
  equityOverrideName?: string;
  cashAdjustment?: number | null;
  equityAdjustment?: number | null;
}) => {
  if (shouldVerify) return 'Confirm benchmark location';

  if (cashAdjustment === 1 && equityAdjustment === 1 && !cashOverrideName && !equityOverrideName) {
    return `Benchmarked to ${benchmarkLocationName}`;
  }

  const cashAdjustmentPercent = cashAdjustment ? Math.round(cashAdjustment * 100) : null;
  const equityAdjustmentPercent = equityAdjustment ? Math.round(equityAdjustment * 100) : null;

  const overridableBenchmarkLocationName =
    cashOverrideName || equityOverrideName || benchmarkLocationName;

  const cashBenchmarkLocationName = cashOverrideName || benchmarkLocationName;
  const equityBenchmarkLocationName = equityOverrideName || benchmarkLocationName;

  if (
    ((cashAdjustment && !equityAdjustment) ||
      (!cashAdjustment && equityAdjustment) ||
      cashAdjustment === equityAdjustment) &&
    cashBenchmarkLocationName === equityBenchmarkLocationName
  ) {
    return `Benchmarked to ${
      cashAdjustmentPercent || equityAdjustmentPercent
    }% of ${overridableBenchmarkLocationName}`;
  }

  if (cashBenchmarkLocationName !== equityBenchmarkLocationName) {
    return `Benchmarked to ${cashAdjustmentPercent}% (cash) of ${cashBenchmarkLocationName} and ${equityAdjustmentPercent}% (equity) of ${equityBenchmarkLocationName}`;
  }

  return `Benchmarked to ${cashAdjustmentPercent}% (cash) and ${equityAdjustmentPercent}% (equity) of ${cashBenchmarkLocationName}`;
};

export const doEmployeesHaveSameBenchmarkLocation = (employees: Employee[]) => {
  const uniqueBenchmarkLocationIds = [
    ...new Set(
      employees
        .map((employee) => employee.appliedGeoAdjustment?.benchmarkLocationId)
        .filter((benchmarkLocationId) => isNumber(benchmarkLocationId)),
    ),
  ];

  return uniqueBenchmarkLocationIds.length <= 1;
};

interface GetBenchmarkLocationResult {
  name?: string;
  cashOverrideName?: string;
  equityOverrideName?: string;
  cashAdjustment: number | null;
  equityAdjustment: number | null;
}

export const getBenchmarkLocation = (
  employee: Employee,
  locationsById: Record<number, Location>,
): GetBenchmarkLocationResult => {
  const { cashAdjustment, equityAdjustment, locationId, appliedGeoAdjustment } = employee;
  const benchmarkLocation = appliedGeoAdjustment?.benchmarkLocationId
    ? locationsById[appliedGeoAdjustment?.benchmarkLocationId]
    : null;

  if (cashAdjustment || equityAdjustment) {
    const anchorLocation = locationsById[ANCHOR_LOCATION_ID];

    return {
      cashAdjustment: cashAdjustment || appliedGeoAdjustment?.cashAdjustment || 1,
      equityAdjustment: equityAdjustment || appliedGeoAdjustment?.equityAdjustment || 1,
      name: benchmarkLocation?.name,
      ...(cashAdjustment ? { cashOverrideName: anchorLocation?.name } : {}),
      ...(equityAdjustment ? { equityOverrideName: anchorLocation?.name } : {}),
    };
  }

  if (benchmarkLocation) {
    return {
      name: benchmarkLocation?.name,
      cashAdjustment: appliedGeoAdjustment?.cashAdjustment || 1,
      equityAdjustment: appliedGeoAdjustment?.equityAdjustment || 1,
    };
  }

  const matchLocation = locationsById[locationId];

  return {
    name: matchLocation?.name,
    cashAdjustment: 1,
    equityAdjustment: 1,
  };
};
