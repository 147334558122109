import { formatError } from 'js/components-legacy/common/AlertMessage';
import { ApprovalTemplate, ApprovalTemplateFormData } from 'types';

import api from './api';

export const fetchApprovalTemplates = async (scenarioId: number | string) => {
  const { success, data, error } = await api.get<ApprovalTemplate[]>(
    `/scenarios/${scenarioId}/approval_templates`,
  );
  if (success) {
    return data;
  }

  throw new Error(formatError(error) || 'Failed to fetch approval templates.');
};

export const createApprovalTemplate = async (values: ApprovalTemplateFormData) => {
  const { success, data, error } = await api.post<ApprovalTemplate>('/approval_templates', {
    params: values,
  });
  if (success) {
    return data;
  }

  throw new Error(formatError(error) || 'Failed to create new approval template.');
};

export const updateApprovalTemplate = async (
  templateId: number,
  values: ApprovalTemplateFormData,
) => {
  const { success, data, error } = await api.put<ApprovalTemplate>(
    `/approval_templates/${templateId}`,
    {
      params: values,
    },
  );
  if (success) {
    return data;
  }

  throw new Error(formatError(error) || 'Failed to update approval template.');
};

export const deleteApprovalTemplate = async (templateId: number) => {
  const { success, data, error } = await api.delete<ApprovalTemplate>(
    `/approval_templates/${templateId}`,
  );
  if (success) {
    return data;
  }

  throw new Error(formatError(error) || 'Failed to delete approval template.');
};
