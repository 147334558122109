import classNames from 'classnames';
import { Outlet } from 'react-router-dom';

import { DataLookupCloseEventDetails } from 'js/analytics/types/dataLookup';
import useTrackEvent from 'js/analytics/useTrackEvent';
import useNumberParams from 'js/hooks/useNumberParams';

import BackToScenarioButton from '../scenario-settings/common/BackToScenarioButton';

import styles from './DataLookup.module.scss';

export enum DataLookupSource {
  ViewPayRanges = 'View Pay Ranges',
  GetJobPostRange = 'Get Job Post Range',
  MarketData = 'Explore Market Data',
  MarketDataNoRemainingQueries = 'Explore Market Data - No Queries Remaining',
}

export const DataLookup = () => {
  const { trackEvent } = useTrackEvent<DataLookupCloseEventDetails>();
  const { scenarioId } = useNumberParams();

  return (
    <div className={classNames('container-xl', styles.container)}>
      <div className={styles.backButtonContainer}>
        <BackToScenarioButton onClick={() => trackEvent('dataLookup.close', { scenarioId })} />
      </div>
      <div className={styles.body}>
        {/* renders <ViewMyRanges /> or <Compualtor /> */}
        <Outlet />
      </div>
    </div>
  );
};
