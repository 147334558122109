export enum CreditedFrom {
  ConnectHris = 'connect_hris',
}

export type BenchmarkQueryCredit = {
  id: number;
  userId: number;
  companyId: number | null;
  amount: number;
  creditedAt: string;
  expiresAt: string | null;
  creditedFrom: CreditedFrom;
};
