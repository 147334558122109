import { WarningCircle } from '@phosphor-icons/react';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';

import UpgradeLink from 'js/components/common/UpgradeLink';
import Icon from 'js/design-system/Icon';
import useAccess from 'js/hooks/useAccess';
import useAppSelector from 'js/hooks/useAppSelector';
import queries from 'js/queries';
import { getById as companySelector } from 'js/selectors/companies';
import { getById as scenarioSelector } from 'js/selectors/scenarios';
import { CompMode, Employee, UpgradeEventSource } from 'types';

import styles from './OverLimitBanner.module.scss';

interface OverLimitEmployeeBannerProps {
  scenarioId: number;
  employeeId: number;
  mode: CompMode;
}

const OverLimitEmployeeBanner = ({
  employeeId,
  scenarioId,
  mode,
}: OverLimitEmployeeBannerProps) => {
  const scenario = useAppSelector((state) => scenarioSelector(state, scenarioId));
  const { data: employee } = useQuery({
    ...queries.scenarios.detail(scenarioId)._ctx.employees(),
    select: (employees = []) => employees.find((e: Employee) => e.id === employeeId),
  });
  const company = useAppSelector((state) => companySelector(state, scenario?.companyId));
  const { upgradePageAccessible, useAccessReady } = useAccess({ company });
  const hideOverLimitBanner = !employee?.needUpgrade[mode];

  if (!employee || !scenario || !useAccessReady || hideOverLimitBanner) return null;

  return (
    <div className={styles.container}>
      <div className={classNames('container-xl', styles.inner)}>
        <Icon icon={WarningCircle} size={20} />
        <div>
          {upgradePageAccessible ? (
            <>
              <span>Please</span>{' '}
              <UpgradeLink
                message="upgrade your plan"
                className={styles.upgradeLink}
                eventSource={UpgradeEventSource.OverLimitEmployeeDetails}
              />{' '}
              <span>to view this employee’s benchmarking details.</span>
            </>
          ) : (
            <span>
              Please contact an admin to upgrade to view this employee’s benchmarking details.
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default OverLimitEmployeeBanner;
