import { X } from '@phosphor-icons/react';
import classnames from 'classnames';
import spacings from 'css/_spacings.scss';
import { useCallback, useEffect, useState } from 'react';

import Icon from 'js/design-system/Icon';
import Text from 'js/design-system/Text/Text';

import { SNACKBAR_TIMEOUT, transitionTime } from './constants';
import { SnackbarProps } from './types';

import styles from './Snackbar.module.scss';

const Snackbar = ({
  message,
  icon,
  actionText,
  autoClose = true,
  showCloseButton = true,
  actionHandler,
  id,
  onClose,
}: SnackbarProps) => {
  const gap =
    icon || (actionText && showCloseButton === true)
      ? spacings['spacing-12']
      : spacings['spacing-16'];

  const [openTransition, startOpenTransition] = useState(false);
  const [closeTransition, startCloseTransition] = useState(false);

  const closeHandler = useCallback(() => {
    startCloseTransition(true);
    setTimeout(() => {
      onClose(id);
    }, transitionTime);
  }, [id, onClose]);

  useEffect(() => {
    startOpenTransition(true);
  }, []);

  useEffect(() => {
    if (autoClose === true) {
      setTimeout(() => {
        startCloseTransition(true);
      }, SNACKBAR_TIMEOUT);

      setTimeout(() => {
        onClose(id);
      }, SNACKBAR_TIMEOUT + transitionTime);
    }
  }, [autoClose, id, onClose]);

  return (
    <div
      className={classnames(
        styles.snackbar,
        { [styles.open]: openTransition },
        { [styles.close]: closeTransition },
      )}
      style={{ gap }}
    >
      <div className={styles.message}>
        {icon && (
          <div className={styles.iconContainer}>
            <Icon icon={icon} size={20} />
          </div>
        )}
        <Text color="neutral-full-white" variant="text-misc-input" className={styles.textContainer}>
          {message}
        </Text>
      </div>
      <div className={styles.buttons}>
        {actionText && (
          <button
            type="button"
            className={styles.actionButton}
            onClick={actionHandler && (() => actionHandler(closeHandler))}
          >
            <Text color="neutral-full-white" variant="text-misc-eyebrow">
              {actionText}
            </Text>
          </button>
        )}

        {showCloseButton && (
          <button
            type="button"
            className={styles.closeButton}
            onClick={closeHandler}
            title="Dismiss"
          >
            <Icon icon={X} size={20} />
          </button>
        )}
      </div>
    </div>
  );
};

export default Snackbar;
