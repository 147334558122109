import GLOBALS from 'js/config/globals';
import { ETHNICITY_OPTIONS, ETHNICITY_SELECT_OPTIONS, GENDER_OPTIONS } from 'js/config/globals-ts';
import { isNumber } from 'js/utils/value';
import { CashDisplayType, DiversityGroupType, EquityDisplayType } from 'types';

import { blendColors } from '../utils/colors';
import countNestedObjWithProp from '../utils/countNestedObjWithProp';
import { getCompTypeDetails } from './compensations/compService';
import { getPercentile, getRangePosition } from './compensations/employeeService';
import { getPercentileMidpoint } from './compensations/percentileService';
import { getClusterMidPoint as getRangePositionMidpoint } from './compensations/rangeService';
import { getPayRangeGroupId } from './employeeService';

const WORK_PERIOD = {
  current: 'current',
  future: 'future',
  past: 'past',
};

const getGroupKey = (group) => {
  if (!Array.isArray(group)) {
    return group;
  }

  switch (group.length) {
    case 0:
      return 'none';
    case 1:
      return group[0];
    default:
      return 'two_or_more';
  }
};

const combineGroupKeys = (keys) => keys.join('_');

const sortEmployeeGroups = (employeesByGroup) => {
  const sortedGroups = Object.keys(employeesByGroup).sort((a, b) => {
    if (a === 'none') {
      return 2;
    }

    if (a < b || b === 'none') {
      return -1;
    }

    if (a > b) {
      return 1;
    }

    return 0;
  });

  return sortedGroups.reduce((sortedEmployees, group) => {
    sortedEmployees[group] = employeesByGroup[group];
    return sortedEmployees;
  }, {});
};

const groupEmployees = (employees, groupType) => {
  const employeesByGroup = employees.reduce((acc, employee) => {
    const groupKey =
      groupType === 'both'
        ? // TODO:
          // - `combineGroupKeys` won't be needed once we refactor the pie chart to a sunburst.
          // - Consuming component would call `nestedGroupEmploeyes` instead.
          combineGroupKeys([getGroupKey(employee.ethnicity), getGroupKey(employee.gender)])
        : getGroupKey(employee[groupType]);

    if (!acc[groupKey]) {
      acc[groupKey] = [employee];
    } else {
      acc[groupKey].push(employee);
    }

    return acc;
  }, {});

  return sortEmployeeGroups(employeesByGroup);
};

export const nestedGroupEmployees = (employees) => {
  const employeesByEthnicity = employees.reduce((acc, employee) => {
    const ethnicityKey = getGroupKey(employee.ethnicity);

    if (!acc[ethnicityKey]) {
      acc[ethnicityKey] = [employee];
    } else {
      acc[ethnicityKey].push(employee);
    }

    return acc;
  }, {});

  Object.entries(employeesByEthnicity).forEach(([key, value]) => {
    const employeesByGender = value.reduce((acc, employee) => {
      const genderKey = getGroupKey(employee.gender);

      if (!acc[genderKey]) {
        acc[genderKey] = [employee];
      } else {
        acc[genderKey].push(employee);
      }

      return acc;
    }, {});

    employeesByEthnicity[key] = employeesByGender;
  });

  return sortEmployeeGroups(employeesByEthnicity);
};

const getGroupTitleOptions = (groupType) => {
  if (groupType === DiversityGroupType.Gender) {
    return GENDER_OPTIONS;
  }

  if (groupType === DiversityGroupType.Ethnicity) {
    return ETHNICITY_OPTIONS;
  }

  const groupTitleOptions = Object.keys(ETHNICITY_OPTIONS).reduce((acc, ethnicityKey) => {
    Object.keys(GENDER_OPTIONS).forEach((genderKey) => {
      const ethnicity = ETHNICITY_OPTIONS[ethnicityKey];
      const gender = GENDER_OPTIONS[genderKey];
      const groupKey = combineGroupKeys([ethnicityKey, genderKey]);
      acc[groupKey] = {
        name: [
          ethnicityKey === 'none' ? 'Ethnicity Unspecified' : ethnicity.name,
          genderKey === 'none' ? 'Gender Unspecified' : gender.name,
        ].join(', '),
        color: blendColors(ethnicity.color, gender.color),
      };
    });
    return acc;
  }, {});

  return groupTitleOptions;
};

const getGroupTitle = (group, groupType) => {
  const groupTitleOptions = getGroupTitleOptions(groupType);
  return groupTitleOptions[group].name;
};

const countLevelJobTypeSelected = (levelFilters) =>
  countNestedObjWithProp(levelFilters, (option) => option.selected, 'nestedOptions');

const filterEmployees = (employees, filters = {}, viewingRangesWithTiers = false) => {
  const {
    departmentFilter,
    levelFilter,
    genderFilter,
    ethnicityFilter,
    workPeriodFilter,
    rangeGroupFilter,
    midPercentileFilter,
    midRangePositionFilter,
    geoTierFilter,
    cashDisplayType,
    equityDisplayType,
    percentileGroupFilter,
  } = filters;

  const allEthnicitiesSelected =
    ethnicityFilter?.length === Object.keys(ETHNICITY_SELECT_OPTIONS).length;

  const levelJobTypeFilterCount = countLevelJobTypeSelected(levelFilter);
  const levelJobTypeFilter = (employee) => {
    if (!levelFilter) return true;

    let jobType = employee.jobRole?.jobType?.name;
    if (!jobType) jobType = 'Custom Role';

    if (levelFilter[jobType].selected) return true;

    const displayLevels = levelFilter[jobType].nestedOptions;

    if (displayLevels) {
      return !!displayLevels[employee.jobLevelId]?.selected;
    }

    return false;
  };

  const matchWorkPeriod = (employee) =>
    !workPeriodFilter?.length ||
    workPeriodFilter === Object.values(WORK_PERIOD) ||
    (workPeriodFilter.includes(WORK_PERIOD.current) && employee.isCurrent) ||
    (workPeriodFilter.includes(WORK_PERIOD.past) && employee.isPast) ||
    (workPeriodFilter.includes(WORK_PERIOD.future) && employee.isFuture);

  const matchDepartment = (employee) =>
    !departmentFilter?.length ||
    departmentFilter.some((departmentId) => +departmentId === +employee.departmentId);

  const matchRangeGroup = (employee) =>
    !rangeGroupFilter?.length ||
    rangeGroupFilter.some((rangeGroupId) => +rangeGroupId === getPayRangeGroupId(employee));

  const matchLevel = (employee) =>
    (levelFilter && levelFilter['All Levels'].selected) ||
    levelJobTypeFilterCount === 0 ||
    levelJobTypeFilter(employee);

  const matchGender = (employee) => !genderFilter?.length || genderFilter.includes(employee.gender);

  const matchEthinicity = (employee) =>
    !ethnicityFilter?.length ||
    allEthnicitiesSelected ||
    ethnicityFilter.some((ethnicity) => employee.ethnicity.includes(ethnicity));

  const matchMidPercentile = (employee) => {
    if (!midPercentileFilter) return true;

    const typeDetails = getCompTypeDetails(midPercentileFilter.type, employee);
    const percentile = getPercentile(employee, typeDetails);
    const percentileMidpoint = !isNumber(percentile) ? null : getPercentileMidpoint(percentile);

    return percentileMidpoint === midPercentileFilter.midPercentile;
  };

  const matchPercentileGroup = (employee) => {
    if (!percentileGroupFilter) return true;

    const typeDetails = getCompTypeDetails(percentileGroupFilter.type, employee);
    const percentile = getPercentile(employee, typeDetails);

    const low = percentileGroupFilter.percentileGroup;
    const high = low < GLOBALS.compPercentileLimits[1] ? low + 9 : Number.POSITIVE_INFINITY;

    return isNumber(percentile) && percentile >= low && percentile <= high;
  };

  const matchMidRangePosition = (employee) => {
    if (!midRangePositionFilter) return true;

    const typeDetails = getCompTypeDetails(midRangePositionFilter.type, employee);
    const rangePosition = getRangePosition(employee, typeDetails);
    const positionMidpoint = !isNumber(rangePosition)
      ? null
      : getRangePositionMidpoint(rangePosition);

    return positionMidpoint === midRangePositionFilter.midRangePosition;
  };

  const matchGeoFilter = (employee) => {
    if (!geoTierFilter) return true;
    if (employee.compensation.appliedGeoAdjustmentId !== geoTierFilter.id) return false;
    if (viewingRangesWithTiers) return true;
    if (
      !!cashDisplayType &&
      cashDisplayType !== CashDisplayType.None &&
      !isNumber(employee.cashAdjustment)
    )
      return true;
    if (
      !!equityDisplayType &&
      equityDisplayType !== EquityDisplayType.None &&
      !isNumber(employee.equityAdjustment)
    )
      return true;

    return false;
  };

  return employees.filter(
    (e) =>
      matchWorkPeriod(e) &&
      matchDepartment(e) &&
      matchLevel(e) &&
      matchGender(e) &&
      matchEthinicity(e) &&
      matchRangeGroup(e) &&
      matchMidPercentile(e) &&
      matchPercentileGroup(e) &&
      matchMidRangePosition(e) &&
      matchGeoFilter(e),
  );
};

const getIncludedInTotalsEmployees = (employees) =>
  employees.filter((employee) => employee.includedInTotals);
const getCurrentEmployees = (employees) => employees.filter((employee) => employee.isCurrent);

const setDepartmentForEmployee = (employees, departments) => {
  if (!employees || !departments) return [];

  const departmentsObj = departments.reduce((obj, department) => {
    obj[department.id] = department;
    return obj;
  }, {});

  return employees.map((employee) => {
    employee.department = departmentsObj[employee.departmentId];
    return employee;
  });
};

const filterByExclJobTypes = (employees, jobTypes) =>
  employees.filter((employee) => !jobTypes.includes(employee.jobRole?.jobType?.name));

const filterByJobTypes = (employees, jobTypes) =>
  employees.filter((employee) => jobTypes.includes(employee.jobRole?.jobType?.name));

const filterByJobLevelIds = (employees, jobLevelIds, jobTypeCode) =>
  employees.filter(
    (e) =>
      jobLevelIds.includes(e.jobLevelId) &&
      (!jobTypeCode || jobTypeCode === e.jobRole?.jobType?.code),
  );

export {
  WORK_PERIOD,
  getIncludedInTotalsEmployees,
  getCurrentEmployees,
  filterEmployees,
  groupEmployees,
  getGroupTitleOptions,
  getGroupTitle,
  countLevelJobTypeSelected,
  setDepartmentForEmployee,
  filterByJobTypes,
  filterByJobLevelIds,
  filterByExclJobTypes,
};
