import { getUserPermissions } from 'js/api/userPermissions';
import { AppDispatch, Permission } from 'types';

import ACTIONS from '../constants/actions';

export const receiveUserPermissions = (data: Permission[]) => (dispatch: AppDispatch) => {
  dispatch({ type: ACTIONS.RECEIVE_PERMISSIONS, data });
};

export const fetchUserPermissions = (userId: number) => async (dispatch: AppDispatch) => {
  const { success, data } = await getUserPermissions(userId);
  if (success) {
    dispatch(receiveUserPermissions(data));
  }
};
