import { AnyAction } from 'redux';

import { FetchStatus, keys, RevokeStatus, UserPermission } from 'types';

import ACTIONS from '../constants/actions';

type ScenarioUserState = {
  byScenarioId: Record<number, UserPermission[]>;
  statusByScenarioId: Record<number, FetchStatus>;
  revokeStatus: RevokeStatus;
};

const initialState: ScenarioUserState = {
  byScenarioId: {},
  statusByScenarioId: {},
  revokeStatus: RevokeStatus.Idle,
};

export default (
  state: ScenarioUserState = initialState,
  action: AnyAction = { type: null },
): ScenarioUserState => {
  switch (action.type) {
    case ACTIONS.FETCHING_SCENARIO_USERS:
      return {
        ...state,
        statusByScenarioId: {
          ...state.statusByScenarioId,
          [action.scenarioId]: FetchStatus.Loading,
        },
      };
    case ACTIONS.RECEIVE_SCENARIO_USERS:
      return {
        ...state,
        byScenarioId: {
          ...state.byScenarioId,
          [action.scenarioId]: action.data,
        },
        statusByScenarioId: {
          ...state.statusByScenarioId,
          [action.scenarioId]: FetchStatus.Succeeded,
        },
      };
    case ACTIONS.RECEIVE_SCENARIO_USER: {
      const { scenarioId, user } = action.data;
      const employeesUsers = state.byScenarioId[scenarioId].filter(
        ({ userId }) => userId !== user.userId,
      );
      return {
        ...state,
        byScenarioId: {
          ...state.byScenarioId,
          [scenarioId]: [...employeesUsers, user],
        },
      };
    }
    case ACTIONS.UPDATE_USERS_ACCESSIBLE_SCENARIOS: {
      const { scenarioIds, user } = action.data;

      return {
        ...state,
        byScenarioId: keys(state.byScenarioId).reduce(
          (byScenarioId: Record<number, UserPermission[]>, scenarioId: number) => {
            byScenarioId[scenarioId] = state.byScenarioId[scenarioId].filter(
              ({ userId }) => userId !== user.userId,
            );

            if (scenarioIds.includes(Number(scenarioId))) {
              byScenarioId[scenarioId] = [...byScenarioId[scenarioId], user];
            }

            return byScenarioId;
          },
          {},
        ),
      };
    }
    case ACTIONS.REVOKE_USER_FROM_SCENARIOS: {
      const { scenarioIds, user } = action.data;

      const scenarioId = scenarioIds[0];
      const stateScenario = state.byScenarioId[scenarioId];
      const newScenarioUsers = stateScenario.filter((_user) => _user.userId !== user?.userId);
      return {
        ...state,
        byScenarioId: { ...state.byScenarioId, ...{ [scenarioId]: newScenarioUsers } },
        revokeStatus: RevokeStatus.Succeeded,
      };
    }

    case ACTIONS.UPDATE_REVOKE_STATUS:
      return {
        ...state,
        revokeStatus: action.data,
      };
    default:
      return state;
  }
};
