import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import FormControl from 'react-bootstrap/FormControl';

import styles from './SearchBox.module.scss';

const SearchBox = ({
  items,
  onFilter,
  searchField,
  placeholder,
  className,
  autofocus,
  onClick,
  onKeyDown,
  variant,
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const inputRef = useRef();

  const handleSearchChange = (event) => {
    filterItems(event.target.value);
  };

  const filterItems = useCallback(
    (searchString) => {
      setSearchQuery(searchString);

      if (searchString) {
        const searchFields = Array.isArray(searchField) ? searchField : [searchField];

        onFilter(
          items.filter((item) => {
            let itemStr = [];

            searchFields.forEach((field) => {
              const itemFieldValue = item[field];

              if (itemFieldValue) {
                itemStr.push(itemFieldValue);
              }
            });

            // Join values into a string
            itemStr = itemStr.join(' ');

            return !!itemStr && itemStr.toLowerCase().includes(searchString.trim().toLowerCase());
          }),
        );
      } else {
        onFilter(items);
      }
    },
    [items, onFilter, searchField],
  );

  useEffect(() => {
    filterItems('');
    if (autofocus) inputRef.current?.focus();
  }, [filterItems, autofocus]);

  return (
    <div className={classNames(styles.searchBox, { [styles[variant]]: !!variant }, className)}>
      <FormControl
        onChange={handleSearchChange}
        onClick={onClick}
        onKeyDown={onKeyDown}
        value={searchQuery}
        className={styles.input}
        placeholder={placeholder}
        ref={inputRef}
      />
      <i className={styles.icon} />
    </div>
  );
};

SearchBox.propTypes = {
  items: PropTypes.arrayOf(Object).isRequired,
  onFilter: PropTypes.func.isRequired,
  searchField: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  placeholder: PropTypes.string,
  className: PropTypes.string,
  autofocus: PropTypes.bool,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  variant: PropTypes.string,
};

SearchBox.defaultProps = {
  searchField: 'name',
  placeholder: 'Search',
  className: null,
  autofocus: true,
  variant: 'default',
  onClick: () => {},
  onKeyDown: () => {},
};

export default SearchBox;
