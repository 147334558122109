import { CaretDown, Check } from '@phosphor-icons/react';
import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { Accordion, FormControl, useAccordionButton } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';

import Icon from 'js/design-system/Icon';
import buttonize from 'js/utils/buttonize';
import { NestedFilterData, NestedFilterDatum } from 'types';

import countNestedObjWithProp from '../../utils/countNestedObjWithProp';

import styles from './RawNestedSelect.module.scss';

interface CustomToggleProps {
  eventKey: string;
  onClick: () => void;
}

const CustomToggle = ({ eventKey, onClick }: CustomToggleProps) => {
  const decoratedOnClick = useAccordionButton(eventKey, (event) => {
    event.stopPropagation();
    onClick();
  });

  return (
    <div {...buttonize(decoratedOnClick)} className={styles.itemToggleBtn}>
      <Icon icon={CaretDown} size={16} />
    </div>
  );
};

interface OptionItemProps {
  children: React.ReactNode;
  className?: string;
}

const OptionItem = ({ children, className, ...props }: OptionItemProps) => (
  <div className={className} {...props}>
    {children}
  </div>
);

interface RawNestedSelectProps {
  label?: string;
  value?: NestedFilterData;
  onChange?: (value: NestedFilterData) => void;
  placeholder?: string;
  options: NestedFilterData;
  dropdownClassName?: string;
  allOptionName?: string;
}

const RawNestedSelect = ({
  label = 'Selected',
  placeholder,
  options,
  value,
  allOptionName = '',
  onChange = () => {},
  dropdownClassName,
}: RawNestedSelectProps) => {
  const [expanded, setExpanded] = useState(false);
  const [flatValues, setFlatValues] = useState<NestedFilterData>(options);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const checkAllOptionSelected = useCallback(
    (value?: NestedFilterData): boolean => {
      let result = true;

      if (!value) return result;

      Object.keys(value).forEach((key) => {
        if (key !== allOptionName && !value[key].selected) {
          result = false;
        }
      });
      return result;
    },
    [allOptionName],
  );

  useEffect(() => {
    if (value) {
      if (allOptionName) {
        value[allOptionName] = options[allOptionName];
        value[allOptionName].selected = checkAllOptionSelected(value);
      }
      setFlatValues(value);
    } else {
      setFlatValues(options);
    }
  }, [value, allOptionName, options, checkAllOptionSelected]);

  const getOption = (path: string[], flatValues: NestedFilterData): NestedFilterDatum => {
    let considerValue: NestedFilterData | NestedFilterDatum = flatValues;
    path.forEach((p: string) => {
      if (considerValue.nestedOptions) considerValue = considerValue.nestedOptions;
      considerValue = (considerValue as NestedFilterData)[p];
    });
    return considerValue;
  };

  const selectAllNestedOptions = (
    nestedOptions?: NestedFilterData,
    selected?: boolean,
  ): NestedFilterData | undefined => {
    return !nestedOptions
      ? nestedOptions
      : Object.keys(nestedOptions).reduce((newNestedOption, optionKey) => {
          const option = newNestedOption[optionKey];
          option.selected = selected;
          if (option.nestedOptions) {
            option.nestedOptions = selectAllNestedOptions(option.nestedOptions, selected);
          }
          return newNestedOption;
        }, nestedOptions);
  };

  const selectOneOption = (
    path: string[],
    values: NestedFilterData,
    selected: boolean,
  ): NestedFilterData => {
    const considerValue = getOption(path, values);
    considerValue.selected = selected;
    if (path.length === 1) {
      const allOption = values[allOptionName];
      allOption.selected = checkAllOptionSelected(values);
      return values;
    }

    const parentPath = path.slice(0, -1);
    const parentSelected = checkAllOptionSelected(getOption(parentPath, values).nestedOptions);
    return selectOneOption(parentPath, values, parentSelected);
  };

  const onItemClick = (e: React.MouseEvent<HTMLElement>, path: string[]) => {
    e.stopPropagation();
    const newValues = { ...flatValues };
    const considerValue = getOption(path, newValues);
    considerValue.nestedOptions = selectAllNestedOptions(
      considerValue.nestedOptions,
      !considerValue.selected,
    );

    selectOneOption(path, newValues, !considerValue.selected);

    setFlatValues(newValues);
    onChange(newValues);
  };

  const onAllItemClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    let newValues = { ...flatValues };
    const selected = !newValues[allOptionName].selected;
    newValues = selectAllNestedOptions(newValues, selected) || {};
    setFlatValues(newValues);
    onChange(newValues);
  };

  const onToggleClick = (path: string[]) => {
    const newValues = { ...flatValues };
    const considerValue = getOption(path, newValues);
    considerValue.expanded = !considerValue?.expanded;
    setFlatValues(newValues);
    onChange(newValues);
  };

  const renderSelectedOptions = () => {
    if (flatValues[allOptionName].selected) {
      return (
        <div className={styles.currentSelections}>
          <span className={styles.currentSelection}>{flatValues[allOptionName].label}</span>
        </div>
      );
    }

    const count = countNestedObjWithProp(
      flatValues,
      (option: NestedFilterDatum) => option.selected,
      'nestedOptions',
    );

    return (
      <div className={styles.currentSelections}>
        {count > 0 ? (
          <span className={styles.currentSelection}>{`(${count}) ${label}`}</span>
        ) : (
          placeholder
        )}
      </div>
    );
  };

  const renderAllOptions = (options: NestedFilterData, parentPath: string[] = []) => (
    <>
      {Object.keys(options).map((optionKey) => {
        const isAllOption = optionKey === allOptionName;
        const path = [...parentPath, optionKey];
        const { label, nestedOptions } = options[optionKey];
        const { selected, expanded } = getOption(path, flatValues) || {};

        return (
          <Dropdown.Item
            className={classNames({ [styles.selected]: selected }, styles.item)}
            as={OptionItem}
            key={path.toString()}
            onClick={(e) => (isAllOption ? onAllItemClick(e) : onItemClick(e, path))}
          >
            <div
              className={styles.itemLabel}
              style={{ '--indent': path.length - 1 } as React.CSSProperties}
            >
              <Icon className={styles.checkmark} icon={Check} size={20} color="oc-primary" />
              {label}
            </div>
            {nestedOptions && (
              <Accordion defaultActiveKey={expanded ? '0' : ''} className={styles.accordion}>
                <Accordion.Collapse eventKey="0" className={styles.collapse}>
                  {renderAllOptions(nestedOptions, path)}
                </Accordion.Collapse>
                <CustomToggle eventKey="0" onClick={() => onToggleClick(path)} />
              </Accordion>
            )}
          </Dropdown.Item>
        );
      })}
    </>
  );

  return (
    <Dropdown
      className={classNames(dropdownClassName, styles.dropdown)}
      onToggle={toggleExpanded}
      show={expanded}
    >
      <Dropdown.Toggle variant="default" className={styles.dropdownToggle}>
        <FormControl as="div" tabIndex={0}>
          {renderSelectedOptions()}
          <Icon icon={CaretDown} size={16} />
        </FormControl>
      </Dropdown.Toggle>
      <Dropdown.Menu className={styles.dropdownMenu} flip={false}>
        {renderAllOptions(options)}
      </Dropdown.Menu>
    </Dropdown>
  );
};
export default RawNestedSelect;
