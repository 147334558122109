import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { fetchOne } from 'js/actions/scenarios';
import TotalRewardsNavBar from 'js/components/navbar/TotalRewardsNavBar';
import useAppDispatch from 'js/hooks/useAppDispatch';
import useNumberParams from 'js/hooks/useNumberParams';

const RewardsViewerScenarioPage = () => {
  const { scenarioId } = useNumberParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchOne(scenarioId));
  }, [dispatch, scenarioId]);

  return (
    <>
      <TotalRewardsNavBar />
      <Outlet />
    </>
  );
};

export default RewardsViewerScenarioPage;
