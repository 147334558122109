import { lazy } from 'react';

const FORCE_REFRESH_AT = 'page-force-refreshed-at';
const REFRESH_INVERVAL = 5 * 60_000; // 5 minutes

const lazyImport = (componentImport) =>
  lazy(async () => {
    try {
      const component = await componentImport();
      return component;
    } catch (error) {
      const now = new Date().getTime();
      const lastForceRefresh = Number(sessionStorage.getItem(FORCE_REFRESH_AT));
      const pageHasBeenRefreshed = now - lastForceRefresh < REFRESH_INVERVAL;

      if (!pageHasBeenRefreshed) {
        sessionStorage.setItem(FORCE_REFRESH_AT, now);
        return window.location.reload();
      }

      throw error;
    }
  });

export default lazyImport;
