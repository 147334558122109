import { getIn } from 'final-form';
import { useFormState } from 'react-final-form';

import ConnectedField, { ConnectedFieldProps } from 'js/design-system/Form/ConnectedField';

import { ColorField, ColorFieldProps } from './ColorField';

export const ConnectedColorField = (props: ConnectedFieldProps<ColorFieldProps>) => {
  const { values: formValues } = useFormState();
  const value = props.value || getIn(formValues, props.name);

  return (
    <ConnectedField
      fieldType="input"
      renderField={(fieldProps: ColorFieldProps) => <ColorField {...fieldProps} value={value} />}
      {...props}
    />
  );
};
