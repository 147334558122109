import api from 'js/api/api';
import { formatError } from 'js/components-legacy/common/AlertMessage';
import { Benchmark, BenchmarkDetails, BenchmarkUpdateResult } from 'types';

export const fetchBenchmark = async (id: number) => {
  const { success, data, error } = await api.get<BenchmarkDetails>(`/analyst/benchmarks/${id}`);
  if (success) {
    return data;
  }

  throw new Error(formatError(error) || 'Failed to fetch benchmark');
};

export type NewBenchmarkParams = {
  companyId: number | null;
  endDate: string;
  label: string;
  description: string | null;
  name: string;
  startDate: string;
  percentiles: object[];
};

export const createBenchmark = async (params: NewBenchmarkParams): Promise<Benchmark> => {
  const { success, data, error } = await api.post<BenchmarkUpdateResult>(`/analyst/benchmarks`, {
    params,
  });
  if (
    success &&
    data &&
    data.benchmark &&
    (!data.percentilesWarnings || data.percentilesWarnings.length === 0)
  ) {
    return data.benchmark;
  }
  if (data && data.percentilesWarnings) {
    // when we make the changes to the benchmark pages and UI validation displays
    // we should maybe just return the entire object
    // instead of throwing an error here,
    throw new Error(formatError(data.percentilesWarnings));
  }

  throw new Error(formatError(error) || 'Failed to create benchmark');
};

export type EditBenchmarkParams = {
  active: boolean;
  endDate: string;
  label: string;
  description: string | null;
  name: string;
  startDate: string;
  percentiles: object[];
  locationIds: number[];
  segmentGroupIds: number[];
};

export const updateBenchmark = async (
  id: number,
  params: EditBenchmarkParams,
): Promise<Benchmark> => {
  const { success, data, error } = await api.put<BenchmarkUpdateResult>(
    `/analyst/benchmarks/${id}`,
    {
      params,
    },
  );
  if (
    success &&
    data &&
    data.benchmark &&
    (!data.percentilesWarnings || data.percentilesWarnings.length === 0)
  ) {
    return data.benchmark;
  }
  if (data && data.percentilesWarnings) {
    // when we make the changes to the benchmark pages and UI validation displays
    // we should maybe just return the entire object
    // instead of throwing an error here,
    throw new Error(formatError(data.percentilesWarnings));
  }

  throw new Error(formatError(error) || 'Failed to update benchmark');
};
