import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { fetchDepartments } from 'js/actions/departments';
import { receiveAll as receiveScenarioEmployeesUsers } from 'js/actions/scenarioEmployeesUsers';
import { receiveAll as receiveScenarioUsers } from 'js/actions/scenarioUsers';
import { queryKeys as scenarioEmployeesUsersQueryKeys } from 'js/api/scenarioEmployeesUsers';
import { queryKeys as scenarioUsersQueryKeys } from 'js/api/scenarioUsers';
import ACTIONS from 'js/constants/actions';
import useAppDispatch from 'js/hooks/useAppDispatch';
import queries from 'js/queries';
import { WebsocketsConsumer } from 'js/websockets';
import { ScenarioChannelReceive } from 'types';

interface ScenarioChannelProps {
  scenarioId: number;
}

export const ScenarioChannel = ({ scenarioId }: ScenarioChannelProps) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const onReceiveScenario = useCallback(
    ({ scenario, users, employeesUsers, message }: ScenarioChannelReceive) => {
      if (scenario?.id) {
        dispatch({ type: ACTIONS.RECEIVE_SCENARIO, data: scenario });
      }
      if (users) {
        dispatch(receiveScenarioUsers(scenarioId, users));
        queryClient.invalidateQueries(scenarioUsersQueryKeys.list(scenarioId).queryKey);
        queryClient.invalidateQueries(queries.scenarios.detail(scenarioId)._ctx.approvalTemplates);
      }
      if (employeesUsers) {
        dispatch(receiveScenarioEmployeesUsers(scenarioId, employeesUsers));
        queryClient.invalidateQueries(scenarioEmployeesUsersQueryKeys.list(scenarioId).queryKey);
      }

      if (message === 'list_geo_tiers') {
        queryClient.invalidateQueries(queries.scenarios.detail(scenarioId)._ctx.payTiers);
        queryClient.invalidateQueries(
          queries.scenarios.detail(scenarioId)._ctx.unassignedLocations,
        );
        queryClient.invalidateQueries(queries.scenarios.detail(scenarioId)._ctx.jobAreaOverrides);
        queryClient.invalidateQueries(queries.scenarios.detail(scenarioId)._ctx.jobTypeOverrides);
      }

      if (message === 'list_employees') {
        dispatch(fetchDepartments(scenarioId));
        queryClient.invalidateQueries(queries.scenarios.detail(scenarioId)._ctx.employees._def);
      }
    },
    [dispatch, scenarioId, queryClient],
  );

  return (
    <WebsocketsConsumer channel="ScenarioChannel" id={scenarioId} onReceived={onReceiveScenario} />
  );
};
