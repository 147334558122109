import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import useNumberParams from './useNumberParams';

const getScenarioBaseURL = (scenarioId: number) => {
  return `/scenarios/${scenarioId}`;
};

const useScenarioNavigation = () => {
  const location = useLocation();
  const { scenarioId } = useNumberParams();

  const getScenarioRedirectionURL = useCallback(
    (targetScenarioId: number) => {
      const targetScenarioBaseURL = getScenarioBaseURL(targetScenarioId);

      // In certain situations, when the user changes scenarios, they should be redirected to the Market Analysis page.
      const doNotPreservePath = [
        !scenarioId,
        /\/scenarios\/[0-9]+\/employees\/new$/.test(location.pathname), // Add New Employee page
        /^\/scenarios\/[0-9]+\/employees\/[0-9]+$/.test(location.pathname), // Employee Detail Page
      ];

      if (doNotPreservePath.some((x) => x === true)) return targetScenarioBaseURL;

      // Otherwise, keep them where they are when they switch scenarios.
      return location.pathname.replace(String(scenarioId), String(targetScenarioId));
    },
    [scenarioId, location.pathname],
  );

  return { getScenarioRedirectionURL };
};

export default useScenarioNavigation;
