import image from 'img/banners/benchmark.svg';
import { GeneralClickContactUsEventDetails } from 'js/analytics/types/general';
import useTrackEvent from 'js/analytics/useTrackEvent';
import useChat from 'js/hooks/useChat';
import { PlayBook } from 'types';

import Banner from './Banner';

import styles from './NewBenchmarkBanner.module.scss';

interface NewBenchmarkBannerProps {
  dismiss(): void;
}

const NewBenchmarkBanner = ({ dismiss }: NewBenchmarkBannerProps) => {
  const { startChat } = useChat();
  const { trackEvent } = useTrackEvent<GeneralClickContactUsEventDetails>();

  const onConfirm = () => {
    trackEvent('general.click.contactUs', { source: 'banner' });
    startChat(PlayBook.Help);
  };

  return (
    <Banner
      shown
      onConfirm={onConfirm}
      onDismiss={dismiss}
      title={
        <div className={styles.titleContainer}>
          <div>New benchmarks</div>
          <div className={styles.subTitle}>Coming soon!</div>
        </div>
      }
      text="In the coming weeks, you’ll receive the freshest market data so you can win top candidates and keep your highest performers."
      cta="Contact us"
      dismissText="Dismiss"
      image={image}
    />
  );
};

export default NewBenchmarkBanner;
