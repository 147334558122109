import { AnyAction } from 'redux';

import { CashConnection } from 'types';

import ACTIONS from '../constants/actions';

type CashConnectionState = {
  data: CashConnection | null;
  fetched: boolean;
  companyId: number | null;
};

const initialState = {
  data: null,
  fetched: false,
  companyId: null,
};

export default (
  state: CashConnectionState = initialState,
  action: AnyAction = { type: null },
): CashConnectionState => {
  switch (action.type) {
    case ACTIONS.RECEIVE_CASH_CONNECTION:
      return {
        data: action.data,
        fetched: true,
        companyId: action.id,
      };
    default:
      return state;
  }
};
