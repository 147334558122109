import { anyPass, isEmpty, isNil, reject } from 'ramda';
import { MutableRefObject, LegacyRef, RefCallback } from 'react';

import { GenericObject } from 'types';

export const openInNewTab = (url: string) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

export const noop = () => {};

export const mergeRefs = <T>(refs: Array<MutableRefObject<T> | LegacyRef<T>>): RefCallback<T> => {
  return (value) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref != null) {
        (ref as MutableRefObject<T | null>).current = value;
      }
    });
  };
};

export const isHTMLElementOverflowing = (element: HTMLElement | null): boolean =>
  element
    ? element.offsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth
    : false;

export const scrollIntoViewById = (id: string) => {
  setTimeout(() => {
    const section = document.querySelector(`#${id}`);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, 100);
};

export const removeFalsyValuesFromObject = (obj: GenericObject) =>
  reject(anyPass([isEmpty, isNil]))(obj);
