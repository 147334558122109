import { FunnelSimple } from '@phosphor-icons/react';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { ReactElement, ReactNode, useState } from 'react';

import CompletionTag from 'js/components-legacy/common/CompletionTag';
import CustomBenchmarkBadge from 'js/components/common/CustomBenchmarkBadge';
import DisplaySettings from 'js/components/common/DisplaySettings/DisplaySettings';
import { HRISButton } from 'js/components/common/HRISButton';
import { SettingsNavigateButton } from 'js/components/dashboards/header/SettingsNavigateButton';
import IconButton from 'js/design-system/IconButton/IconButton';
import Tooltip from 'js/design-system/Tooltip/Tooltip';
import useAppSelector from 'js/hooks/useAppSelector';
import useNumberParams from 'js/hooks/useNumberParams';
import useScenarioEmployeeCount from 'js/hooks/useScenarioEmployeeCount';
import queries from 'js/queries';
import scenarioViewSelector from 'js/selectors/scenarioView';
import { getById as scenarioSelector } from 'js/selectors/scenarios';
import { filterEmployees, getIncludedInTotalsEmployees } from 'js/services/employeeFilterService';
import { DataExportType, FilterOptions } from 'types';

import EmployeesCountHover from './employees-count/EmployeesCountHover';
import { ExportLink } from './export/ExportLink';
import DepartmentFilter from './filter/DepartmentFilter';
import EthnicityFilter from './filter/EthnicityFilter';
import GenderFilter from './filter/GenderFilter';
import LevelFilter from './filter/LevelFilter';
import RangeGroupFilter from './filter/RangeGroupFilter';
import SortSelector from './sort/SortSelector';

import styles from './DashboardHeader.module.scss';

export interface DashboardHeaderProps {
  title: ReactNode;
  description?: string;
  hideHris?: boolean;
  hideHeadcount?: boolean;
  hideBenchmarked?: boolean;
  settingsButton?: ReactElement<typeof SettingsNavigateButton>;
  filterOptions?: FilterOptions;
  sortable?: boolean;
  exportOptions?: DataExportType[];
  actionButton?: ReactNode;
  displaySettings?: ReactElement<typeof DisplaySettings>;
}

export const DashboardHeader = ({
  title,
  description,
  hideHris,
  hideHeadcount,
  hideBenchmarked,
  settingsButton,
  filterOptions,
  sortable,
  exportOptions,
  actionButton,
  displaySettings,
}: DashboardHeaderProps) => {
  const { scenarioId } = useNumberParams();
  const { data: employees = [] } = useQuery(queries.scenarios.detail(scenarioId)._ctx.employees());
  const scenario = useAppSelector((state) => scenarioSelector(state, scenarioId));
  const employeeFilters = useAppSelector(scenarioViewSelector);
  const filteredEmployees = filterEmployees(employees, employeeFilters);
  const filteredIncludedInTotalsEmployees = getIncludedInTotalsEmployees(filteredEmployees);
  const { activeCount, excludedCount, hiddenCount } = useScenarioEmployeeCount(scenarioId);

  const [showFilters, setShowFilters] = useState(true);

  if (!scenario) return null;

  return (
    <div className={styles.stickyHeader}>
      <div className={styles.header}>
        <div className={styles.left}>
          <div className={styles.title}>{title}</div>

          {!hideHris && <HRISButton scenarioId={scenarioId} />}

          <CustomBenchmarkBadge scenario={scenario} />

          {!hideHeadcount && (
            <EmployeesCountHover
              activeCount={activeCount}
              excludedCount={excludedCount}
              hiddenCount={hiddenCount}
            />
          )}

          {!hideBenchmarked && <CompletionTag employees={filteredIncludedInTotalsEmployees} />}
        </div>

        <div className={styles.right}>
          {settingsButton}
          {filterOptions && (
            <Tooltip content="Filter">
              <IconButton
                icon={FunnelSimple}
                size="medium"
                shape="square"
                onClick={() => setShowFilters(!showFilters)}
                className={classNames({ [styles.filterTriggerOpen]: showFilters })}
                data-testid="filters"
              />
            </Tooltip>
          )}
          {sortable && <SortSelector />}
          {displaySettings}
          {exportOptions && (
            <>
              <div className={styles.divider} />
              <ExportLink exportOptions={exportOptions} />
            </>
          )}
          {actionButton}
        </div>

        <div className={styles.description}>{description}</div>
      </div>

      {filterOptions && (
        <div className={classNames(styles.filters, { [styles.open]: showFilters })}>
          {filterOptions.department && <DepartmentFilter />}
          {filterOptions.level && <LevelFilter scenario={scenario} />}
          {filterOptions.gender && <GenderFilter />}
          {filterOptions.ethnicity && <EthnicityFilter />}
          {filterOptions.rangeGroup && <RangeGroupFilter />}
        </div>
      )}
    </div>
  );
};
