import classNames from 'classnames';
import colorsTheme from 'css/_colors.scss';
import { forwardRef } from 'react';

import { GenericObject } from 'types';

import { TextProps } from './types';

import styles from './Text.module.scss';

const TextBase = (
  {
    color = 'platform-gray-900',
    variant = 'text-body-main',
    children,
    className,
    Element = 'div',
    style = {},
    ...props
  }: TextProps,
  ref: React.ForwardedRef<HTMLElement>,
) => (
  <Element
    className={classNames(styles[variant], className)}
    style={{ color: colorsTheme[color], ...style }}
    {...({ ...props, ref } as GenericObject)}
  >
    {children}
  </Element>
);

export default forwardRef(TextBase);
