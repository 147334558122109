import image from 'img/banners/open-imperative.svg';
import { GeneralClickOpenImperativeEventDetails } from 'js/analytics/types/general';
import useTrackEvent from 'js/analytics/useTrackEvent';

import Banner from './Banner';

interface OpenImperativeBannerProps {
  dismiss(): void;
}

const OpenImperativeBanner = ({ dismiss }: OpenImperativeBannerProps) => {
  const { trackEvent } = useTrackEvent<GeneralClickOpenImperativeEventDetails>();

  const onConfirm = () => {
    trackEvent('general.click.openImperative', { source: 'banner' });
    window.open(process.env.REACT_APP_OPEN_IMPERATIVE_URL);
  };

  return (
    <Banner
      shown
      onConfirm={onConfirm}
      onDismiss={dismiss}
      title="Join OPEN Imperative!"
      text={
        <>
          <div>Companies that prioritize pay equity grow faster and keep employees longer.</div>
          <div>
            Join the 200+ companies who have committed to end gender pay disparity in startups by
            2027.
          </div>
        </>
      }
      cta="Learn more"
      dismissText="Dismiss"
      image={image}
    />
  );
};

export default OpenImperativeBanner;
