import { useId } from '@floating-ui/react';
import { X } from '@phosphor-icons/react';
import classNames from 'classnames';
import { ReactNode } from 'react';

import Button from 'js/design-system/Button/Button';
import { ButtonVariant, ButtonSize, ButtonProps } from 'js/design-system/Button/types';
import IconButton from 'js/design-system/IconButton/IconButton';
import Text from 'js/design-system/Text/Text';

import { Modal, ModalProps } from '../Modal';

import styles from './ActionableModal.module.scss';

interface ModalButtonProps
  extends Pick<ButtonProps, 'disabled' | 'className' | 'onClick' | 'type'> {
  label: string;
}

export interface ActionableModalProps extends ModalProps {
  /** If passing an element, the main text should be `text-heading-h6` */
  title: ReactNode;
  description: ReactNode;
  confirmBtnProps: ModalButtonProps;
  cancelBtnProps?: ModalButtonProps;
  contentClassName?: string;
  'data-testid'?: string;
}

export const ActionableModal = ({
  children,
  open,
  onHide,
  title,
  description,
  confirmBtnProps,
  cancelBtnProps,
  contentClassName,
  'data-testid': dataTestId,
}: ActionableModalProps) => {
  // Generates a unique id for the heading and description elements of the dialog,
  // so that the content of the dialog is announced by screen readers with wide compatibility.
  const labelId = useId();
  const descriptionId = useId();

  return (
    <Modal open={open} onHide={onHide} labelId={labelId} descriptionId={descriptionId}>
      <div className={styles.actionableModal} data-testid={dataTestId}>
        <div className={styles.title} id={labelId}>
          {typeof title === 'string' ? <Text variant="text-heading-h6">{title}</Text> : title}
          <IconButton
            icon={X}
            shape="circle"
            iconColor="platform-gray-500"
            onClick={onHide}
            data-testid="btn-close-modal"
          />
        </div>

        <div className={classNames(styles.content, contentClassName)} id={descriptionId}>
          <Text color="platform-gray-700">{description}</Text>
          {children}
        </div>

        <div className={styles.actions}>
          <Button variant={ButtonVariant.Filled} size={ButtonSize.Medium} {...confirmBtnProps}>
            {confirmBtnProps.label}
          </Button>
          {cancelBtnProps && (
            <Button variant={ButtonVariant.Ghost} size={ButtonSize.Medium} {...cancelBtnProps}>
              {cancelBtnProps.label}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};
