import { JobTypeLevel } from 'types';

import api from './api';

export const fetchJobTypeLevels = async (scenarioId: number) => {
  const { success, data, error } = await api.get<JobTypeLevel[]>(
    `/scenarios/${scenarioId}/job_type_levels`,
  );

  if (success) {
    return data;
  }

  throw new Error(`Failed to fetch job type levels: ${error}`);
};
