import { Navigate, useSearchParams } from 'react-router-dom';

import Button from 'js/design-system/Button/Button';
import { ButtonSize, ButtonVariant } from 'js/design-system/Button/types';
import useAppSelector from 'js/hooks/useAppSelector';
import { currentUserSelector, userFetchedStatusSelector } from 'js/selectors/currentUser';

import styles from './NavigateFromRoot.module.scss';

const NavigateFromRoot = () => {
  const { id, landingScenarioId, landingCompanyId, jobTitle, invitation, isPending } =
    useAppSelector(currentUserSelector) || {};
  const indexStatus = useAppSelector(userFetchedStatusSelector);
  const [searchParams] = useSearchParams();

  const completeSignUp = searchParams.get('completeSignUp') === 'true';
  const isEmbedded = window.self !== window.top;

  const goToApp = () => {
    if (window.top) {
      const url = `${window.location.protocol}//${window.location.host}/`;
      window.top.location.href = url;
    }
  };

  if (isEmbedded) {
    return (
      <div className={styles.embeddedContainer}>
        <Button variant={ButtonVariant.Filled} size={ButtonSize.Medium} onClick={goToApp}>
          Go to application
        </Button>
      </div>
    );
  }

  if (!id || indexStatus !== 'succeeded') {
    return null;
  }

  if (isPending) {
    return <Navigate to="/pending" />;
  }

  if (completeSignUp && landingCompanyId && invitation?.purpose === 'hris_connect') {
    return <Navigate to={`/onboarding/${landingCompanyId}/employees`} />;
  }

  if (landingScenarioId) {
    return <Navigate to={`/scenarios/${landingScenarioId}`} />;
  }

  if (landingCompanyId) {
    return <Navigate to={`/companies/${landingCompanyId}/scenarios/new`} />;
  }

  if (jobTitle) {
    return <Navigate to="/onboarding/company" />;
  }

  return <Navigate to="/onboarding/user" />;
};

export default NavigateFromRoot;
