import { useEffect, useMemo } from 'react';

import RawMultiSelect, { compactValues } from 'js/components-legacy/form/RawMultiSelect';
import { ETHNICITY_SELECT_OPTIONS } from 'js/config/globals-ts';
import { Ethnicity, keys } from 'types';

interface RawEthnicityFilterProps {
  value: Ethnicity[] | undefined;
  onChange: (selectedOptions: Ethnicity[]) => void;
  onInit?: (options: Ethnicity[]) => void;
  className?: string;
}

const RawEthnicityFilter = ({ value, onChange, onInit, className }: RawEthnicityFilterProps) => {
  const options = useMemo(
    () =>
      keys(ETHNICITY_SELECT_OPTIONS).map((ethnicity) => ({
        label: ETHNICITY_SELECT_OPTIONS[ethnicity].name,
        value: ethnicity,
      })),
    [],
  );

  useEffect(() => {
    if (onInit && options) {
      onInit(options.map(({ value }) => value));
    }
  }, [onInit, options]);

  return (
    <RawMultiSelect
      value={value}
      onChange={onChange}
      className={className}
      options={options}
      placeholder="All Ethnicities"
      allOption={{
        value: 0,
        label: 'All Ethnicities',
      }}
      displayedValue={compactValues('Ethnicities')}
    />
  );
};

export default RawEthnicityFilter;
