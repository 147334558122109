import { useQueryClient } from '@tanstack/react-query';
import { FormApi } from 'final-form';
import { useCallback, useState, useEffect, useRef } from 'react';

import { setActivityLogDataUpdated } from 'js/actions/activityLog';
import { ActivityLogCommentEventDetails } from 'js/analytics/types/activityLog';
import useTrackEvent from 'js/analytics/useTrackEvent';
import api from 'js/api/api';
import { Form } from 'js/components-legacy/form';
import useAppDispatch from 'js/hooks/useAppDispatch';
import useAppSelector from 'js/hooks/useAppSelector';
import queries from 'js/queries';
import { getActivityLogStale } from 'js/selectors/activityLog';
import { currentUserSelector } from 'js/selectors/currentUser';
import { getTimestamp } from 'js/utils/dates';

import ActivityContextProvider from './ActivityContext';
import ActivityLog from './ActivityLog';
import {
  ActivityLogListSource,
  ConnectedActivityLogProps,
  ActivityLogFormData,
  ActivityResponse,
  CommentResponse,
  ActivityUser,
} from './types';

const sortLogs = <TActivityAction extends string, TActivityDetail>(
  data: ActivityLogListSource<TActivityAction, TActivityDetail>[],
) =>
  data?.sort((a, b) => {
    const timestampA = getTimestamp(a?.data.createdAt);
    const timestampB = getTimestamp(b?.data.createdAt);
    if (!timestampA || !timestampB) return 0;

    return timestampA - timestampB;
  });

const ConnectedActivityLog = <TActivityAction extends string, TActivityDetail>({
  objectableId,
  objectableType,
  onCommentAdded,
  ...rest
}: ConnectedActivityLogProps<TActivityAction, TActivityDetail>) => {
  const queryClient = useQueryClient();
  const [logs, setLogs] = useState<ActivityLogListSource<TActivityAction, TActivityDetail>[]>([]);
  const activityLogRef = useRef<HTMLDivElement | null>(null);
  const userFromSession = useAppSelector(currentUserSelector);
  const isDataStale = useAppSelector(getActivityLogStale);
  const dispatch = useAppDispatch();
  const { analytics: trackingEventDetails } = rest;
  const { trackEvent } = useTrackEvent<ActivityLogCommentEventDetails>();

  const getActivityLogData = useCallback(async () => {
    const fetchData = +objectableId > 0 && objectableType;
    const allData: ActivityLogListSource<TActivityAction, TActivityDetail>[] = [];

    if (!fetchData) return;

    const responses = await Promise.all([
      api.get<CommentResponse[]>(
        `/comments?commentable_type=${objectableType}&commentable_id=${objectableId}`,
      ),
      api.get<ActivityResponse<TActivityAction, TActivityDetail>[]>(
        `/activity?trackable_type=${objectableType}&trackable_id=${objectableId}`,
      ),
    ]);
    const [
      { data: commentsData, success: commentsSuccess },
      { data: activityData, success: activitySuccess },
    ] = responses;

    if (commentsSuccess && commentsData) {
      commentsData.forEach((comment) => {
        allData.push({
          type: 'comment',
          data: comment,
        });
      });
    }

    if (activitySuccess && activityData) {
      activityData.forEach((activity) => {
        allData.push({
          type: 'activity',
          data: activity,
        });
      });
    }
    dispatch(setActivityLogDataUpdated());
    setLogs(sortLogs(allData));
  }, [objectableId, objectableType, dispatch]);

  const handleSubmit = useCallback(
    async (formData: ActivityLogFormData, form: FormApi<ActivityLogFormData>) => {
      const { comment } = formData;

      if (!comment) return;

      const { success } = await api.post('/comments', {
        params: {
          comment: {
            comment,
          },
          commentableId: objectableId,
          commentableType: objectableType,
        },
      });

      if (success) {
        const fields = Object.keys(formData) as (keyof ActivityLogFormData)[];
        fields.forEach((field) => {
          form.change(field, undefined);
          form.resetFieldState(field);
        });

        getActivityLogData();
        queryClient.invalidateQueries(queries.offers.detail(objectableId)._ctx.comments);
        if (onCommentAdded) onCommentAdded();
        activityLogRef.current?.focus();
        trackEvent('activityLog.comment', trackingEventDetails);
      }
    },
    [
      getActivityLogData,
      objectableId,
      objectableType,
      onCommentAdded,
      queryClient,
      trackEvent,
      trackingEventDetails,
    ],
  );

  useEffect(() => {
    if (isDataStale) getActivityLogData();
  }, [getActivityLogData, isDataStale]);

  return (
    <Form onSubmit={handleSubmit}>
      <ActivityContextProvider module={objectableType}>
        <ActivityLog
          ref={activityLogRef}
          {...rest}
          source={logs}
          user={userFromSession as ActivityUser}
        />
      </ActivityContextProvider>
    </Form>
  );
};

export default ConnectedActivityLog;
