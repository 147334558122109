import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ReactComponent as Logo } from 'img/opencomp-logos/green.svg';
import ChatScript from 'js/components-legacy/common/ChatScript';
import Container from 'js/components-legacy/common/Container';
import NotFound from 'js/components-legacy/common/NotFound';
import ReleaseDetector from 'js/components-legacy/common/ReleaseDetector';
import { DataLookup } from 'js/components/data-lookup/DataLookup';
import IntegrationsOauthCallback from 'js/components/integrations/IntegrationsOauthCallback';
import NavigateFromRoot from 'js/components/pages/NavigateFromRoot';
import Profile from 'js/components/profiles/Profile';
import { HomeTabRedirect } from 'js/components/scenario/pages/HomeTabRedirect';
import ScenarioPage from 'js/components/scenario/pages/ScenarioPage';
import ScenarioTab from 'js/components/scenario/pages/ScenarioTab';
import PermissionProtectedRoute from 'js/routes/PermissionProtectedRoute';
import PrivateRoute from 'js/routes/PrivateRoute';
import Store from 'js/store';
import lazyImport from 'js/utils/lazy-import';
import { UserRole } from 'types';

import '../css/App.scss';
import BenchmarkEmployees from './components/benchmark-employees/BenchmarkEmployees';
import AccessDenied from './components/common/AccessDenied';
import { ViewMyRanges } from './components/data-lookup/ranges/ViewMyRanges';
import { Launchpad } from './components/launchpad/Launchpad';
import { FeatureFlags } from './config/feature-flags';
import FeatureFlagProtectedRoute from './routes/FeatureFlagProtectedRoute';
import { OauthInitiate } from './routes/OauthInitiate';
import { OauthSignInCallback } from './routes/OauthSignInCallback';
import ProductPermissionFeatureFlagProtectedRoute from './routes/ProductPermissionFeatureFlagProtectedRoute';
import { RipplingInstallCallback } from './routes/RipplingInstallCallback';

const NewEmployee = lazyImport(() =>
  import('js/components-legacy/employee/employee-entry/NewEmployee'),
);
const EditEmployee = lazyImport(() =>
  import('js/components-legacy/employee/employee-entry/EditEmployee'),
);
const CandidateOfferPreview = lazyImport(() =>
  import('js/components/offers/public/candidate-offer/CandidateOfferPreview'),
);
const CandidateSettingsPage = lazyImport(() =>
  import('js/components/scenario-settings/offers/CandidateSettingsPage'),
);
const EditTotalRewardsPage = lazyImport(() =>
  import('js/components/total-rewards/EditTotalRewards'),
);
const BulkUserInvitePage = lazyImport(() =>
  import('js/components/bulk-user-invite/BulkUserInvitePage'),
);
const LetterBuilder = lazyImport(() =>
  import('js/components/comp-cycle/letter-builder/LetterBuilder'),
);

const AnalystConsole = lazyImport(() => import('js/routes/AnalystConsole'));
const Auth = lazyImport(() => import('js/routes/Auth'));
const Ranges = lazyImport(() => import('js/routes/Ranges'));
const Offers = lazyImport(() => import('js/routes/Offers'));
const Onboarding = lazyImport(() => import('js/routes/Onboarding'));
const OnboardingCta = lazyImport(() => import('js/routes/OnboardCtaRoutes'));
const ScenarioSettings = lazyImport(() => import('js/routes/ScenarioSettings'));
const Companies = lazyImport(() => import('js/routes/Companies'));
const CompCycle = lazyImport(() => import('js/routes/CompCycle'));
const TotalRewards = lazyImport(() => import('js/routes/TotalRewards'));
const Letters = lazyImport(() => import('js/routes/Letters'));
const EmployeeAdjustmentProposals = lazyImport(() =>
  import('js/routes/EmployeeAdjustmentProposals'),
);

const Compulator = lazyImport(() => import('js/components/data-lookup/market/Compulator'));
const Hiring = lazyImport(() => import('js/components/hiring/Hiring'));
const Market = lazyImport(() => import('js/components/market/homepage/MarketHomepage'));
const Diversity = lazyImport(() => import('js/components/dei/Diversity'));

const CandidateOffer = lazyImport(() =>
  import('js/components/offers/public/candidate-offer/CandidateOffer/CandidateOffer'),
);

const queryClient = new QueryClient({
  defaultOptions: { queries: { staleTime: Infinity } },
});

const App = () => (
  <QueryClientProvider client={queryClient}>
    <Provider store={Store}>
      <ReleaseDetector />
      <ToastContainer hideProgressBar transition={Slide} limit={1} />
      <ChatScript />
      <BrowserRouter>
        <Container>
          <Routes>
            <Route
              element={
                <Suspense fallback={<></>}>
                  <Auth />
                </Suspense>
              }
              path="*"
            />

            <Route
              path="/offers/:offerUuid/details"
              element={
                <Suspense>
                  <CandidateOffer />
                </Suspense>
              }
            />
            <Route element={<OauthSignInCallback />} path="/oauth/sign-in/" />
            <Route element={<OauthInitiate />} path="/oauth/initiate/:identityProvider/" />
            <Route element={<PrivateRoute />} path="/">
              <Route element={<NavigateFromRoot />} path="" />
              <Route element={<Profile />} path="profile" />
              <Route element={<RipplingInstallCallback />} path="rippling/install/" />

              <Route
                path="onboarding/*"
                element={
                  <Suspense fallback={<></>}>
                    <Onboarding />
                  </Suspense>
                }
              />

              <Route
                element={
                  <Suspense fallback={<></>}>
                    <AnalystConsole />
                  </Suspense>
                }
                path="analyst-console/*"
              />

              {/* Companies */}
              <Route
                element={
                  <Suspense fallback={<></>}>
                    <Companies />
                  </Suspense>
                }
                path="companies/*"
              />

              {/* /scenario/:id */}
              <Route path="scenarios/:scenarioId" element={<Outlet />}>
                <Route
                  path=""
                  element={
                    <PermissionProtectedRoute
                      only={[
                        UserRole.Analyst,
                        UserRole.Administrator,
                        UserRole.OrganizationLeader,
                        UserRole.TeamLeader,
                      ]}
                    />
                  }
                >
                  <Route
                    element={
                      <Suspense fallback={<></>}>
                        <OnboardingCta />
                      </Suspense>
                    }
                    path="onboarding/*"
                  />
                  <Route
                    element={<FeatureFlagProtectedRoute featureFlag={FeatureFlags.launchpad} />}
                    path="benchmark-employees"
                  >
                    <Route
                      element={
                        <Suspense fallback={<></>}>
                          <BenchmarkEmployees />
                        </Suspense>
                      }
                      path=""
                    />
                  </Route>

                  {/* Candidate Experience */}
                  <Route
                    element={
                      <Suspense fallback={<></>}>
                        <CandidateSettingsPage />
                      </Suspense>
                    }
                    path="candidate-experience"
                  />
                  <Route
                    path="offers/preview"
                    element={
                      <Suspense>
                        <CandidateOfferPreview trackPreview />
                      </Suspense>
                    }
                  />

                  {/* Edit Total Rewards page */}
                  <Route
                    element={
                      <Suspense fallback={<></>}>
                        <EditTotalRewardsPage />
                      </Suspense>
                    }
                    path="total-rewards/edit"
                  />

                  {/* Cycle Letter Builder */}
                  <Route
                    path="comp-cycles/:compCycleId/letter-builder"
                    element={
                      <ProductPermissionFeatureFlagProtectedRoute
                        featureFlag={FeatureFlags.cycleLetters}
                        productPermission="comp_cycles"
                      />
                    }
                  >
                    <Route
                      element={
                        <Suspense fallback={<></>}>
                          <LetterBuilder />
                        </Suspense>
                      }
                      path=""
                    />
                  </Route>

                  {/* Bulk User Invite page */}
                  <Route
                    element={
                      <Suspense fallback={<></>}>
                        <BulkUserInvitePage />
                      </Suspense>
                    }
                    path="bulk-user-invite/:resource?/:resourceId?"
                  />
                </Route>

                <Route element={<ScenarioPage />} path="">
                  {/* Market, Ranges, Offers */}
                  <Route element={<ScenarioTab showBanner={false} />} path="">
                    <Route
                      path=""
                      element={
                        <PermissionProtectedRoute
                          only={[
                            UserRole.Analyst,
                            UserRole.Administrator,
                            UserRole.OrganizationLeader,
                            UserRole.TeamLeader,
                          ]}
                        />
                      }
                    >
                      <Route index element={<HomeTabRedirect />} />
                      <Route
                        element={<FeatureFlagProtectedRoute featureFlag={FeatureFlags.launchpad} />}
                        path="launchpad"
                      >
                        <Route
                          element={
                            <Suspense fallback={<></>}>
                              <Launchpad />
                            </Suspense>
                          }
                          path=""
                        />
                      </Route>
                      <Route
                        element={
                          <Suspense fallback={<></>}>
                            <Market />
                          </Suspense>
                        }
                        path="market"
                      />
                      <Route
                        element={
                          <Suspense fallback={<></>}>
                            <Ranges />
                          </Suspense>
                        }
                        path="ranges/*"
                      />
                    </Route>
                    <Route
                      element={
                        <Suspense fallback={<></>}>
                          <Offers />
                        </Suspense>
                      }
                      path="offers/*"
                    />
                    <Route
                      element={
                        <Suspense fallback={<></>}>
                          <EmployeeAdjustmentProposals />
                        </Suspense>
                      }
                      path="employee-adjustment-proposals/*"
                    />
                    <Route
                      element={
                        <Suspense fallback={<></>}>
                          <CompCycle />
                        </Suspense>
                      }
                      path="comp-cycles/*"
                    />

                    {/* Total Rewards */}
                    <Route
                      path=""
                      element={
                        <PermissionProtectedRoute
                          only={[
                            UserRole.Analyst,
                            UserRole.Administrator,
                            UserRole.OrganizationLeader,
                            UserRole.TeamLeader,
                            UserRole.Recruiter,
                            UserRole.RewardsViewer,
                          ]}
                        />
                      }
                    >
                      <Route
                        element={
                          <Suspense fallback={<></>}>
                            <TotalRewards />
                          </Suspense>
                        }
                        path="total-rewards/*"
                      />
                    </Route>

                    <Route
                      path=""
                      element={
                        <PermissionProtectedRoute
                          only={[
                            UserRole.Analyst,
                            UserRole.Administrator,
                            UserRole.OrganizationLeader,
                            UserRole.TeamLeader,
                            UserRole.Recruiter,
                            UserRole.RewardsViewer,
                          ]}
                        />
                      }
                    >
                      <Route
                        element={
                          <Suspense fallback={<></>}>
                            <Letters />
                          </Suspense>
                        }
                        path="letters/*"
                      />
                    </Route>
                  </Route>

                  {/* Hiring, Diversity */}
                  <Route
                    path=""
                    element={
                      <PermissionProtectedRoute
                        only={[
                          UserRole.Analyst,
                          UserRole.Administrator,
                          UserRole.OrganizationLeader,
                          UserRole.TeamLeader,
                        ]}
                      />
                    }
                  >
                    <Route element={<ScenarioTab />} path="">
                      <Route
                        element={
                          <Suspense fallback={<></>}>
                            <Hiring />
                          </Suspense>
                        }
                        path="hiring"
                      />
                      <Route
                        element={
                          <Suspense fallback={<></>}>
                            <Diversity />
                          </Suspense>
                        }
                        path="diversity"
                      />
                    </Route>

                    <Route
                      element={
                        <Suspense fallback={<></>}>
                          <ScenarioSettings />
                        </Suspense>
                      }
                      path="*"
                    />
                  </Route>

                  {/* preserves `/compulator` */}
                  <Route
                    path="compulator"
                    element={
                      <PermissionProtectedRoute
                        only={[
                          UserRole.Analyst,
                          UserRole.Administrator,
                          UserRole.OrganizationLeader,
                          UserRole.TeamLeader,
                          UserRole.Approver,
                          UserRole.Recruiter,
                        ]}
                      />
                    }
                  >
                    <Route element={<Navigate to="../data-lookup/market" />} path="" />
                  </Route>
                  {/* Data Lookup */}
                  <Route
                    path="data-lookup/*"
                    element={
                      <PermissionProtectedRoute
                        only={[
                          UserRole.Analyst,
                          UserRole.Administrator,
                          UserRole.OrganizationLeader,
                          UserRole.TeamLeader,
                          UserRole.Approver,
                          UserRole.Recruiter,
                        ]}
                      />
                    }
                  >
                    <Route
                      element={
                        <Suspense fallback={<></>}>
                          <DataLookup />
                        </Suspense>
                      }
                      path=""
                    >
                      <Route index element={<Navigate to="market" />} />
                      <Route element={<Compulator />} path="market" />
                      <Route element={<ViewMyRanges />} path="ranges" />
                    </Route>
                  </Route>

                  <Route
                    path=""
                    element={
                      <PermissionProtectedRoute
                        only={[
                          UserRole.Analyst,
                          UserRole.Administrator,
                          UserRole.OrganizationLeader,
                          UserRole.TeamLeader,
                        ]}
                      />
                    }
                  >
                    {/* Edit Employee */}
                    <Route
                      element={
                        <Suspense fallback={<></>}>
                          <EditEmployee />
                        </Suspense>
                      }
                      path="employees/:employeeId"
                    />

                    {/* New Employee */}
                    <Route
                      element={
                        <Suspense fallback={<></>}>
                          <NewEmployee />
                        </Suspense>
                      }
                      path="employees/new"
                    />
                  </Route>
                </Route>
              </Route>

              <Route
                element={<IntegrationsOauthCallback />}
                path="integrations/:integration/oauth/callback"
              />
            </Route>
            <Route path="access-denied" element={<AccessDenied />} />
            <Route
              path="pending"
              element={
                <AccessDenied
                  icon={(props) => <Logo {...props} height={72} width={72} />}
                  heading="Welcome to OpenComp!"
                  subHeading="We have let your admin know to grant you access"
                />
              }
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Container>
      </BrowserRouter>
    </Provider>
  </QueryClientProvider>
);

export default App;
