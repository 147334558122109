import { FunnelSimple } from '@phosphor-icons/react';
import { useForm } from 'react-final-form';

import DisplaySettings from 'js/components/common/DisplaySettings/DisplaySettings';
import { ButtonType } from 'js/design-system/Button/types';
import IconButton from 'js/design-system/IconButton/IconButton';
import Tooltip from 'js/design-system/Tooltip/Tooltip';

import { ActiveTab } from './types';

type ActivityLogFilterProps = {
  activeTab: ActiveTab;
  handleTabChange: (activeTab: ActiveTab) => void;
};

const ActivityLogFilter = ({ activeTab, handleTabChange }: ActivityLogFilterProps) => {
  const form = useForm();
  const commentsAndActivityOption = {
    value: 'all',
    label: 'Comments and activity',
  };
  const commentsOnlyOption = {
    value: 'comments',
    label: 'Only comments',
  };
  const toggleFilter = (toggle: () => void) => () => {
    form.change(
      'activeTab',
      activeTab === ActiveTab.All ? commentsAndActivityOption : commentsOnlyOption,
    );
    toggle();
  };

  return (
    <DisplaySettings
      inForm
      applyButtonProps={{
        type: ButtonType.Button,
        onClick: () => {
          const { activeTab } = form.getState().values;
          handleTabChange(activeTab.value);
        },
      }}
      renderCustomToggle={(toggle) => (
        <Tooltip content="Filter activity">
          <IconButton
            icon={FunnelSimple}
            size="small"
            shape="square"
            iconColor="primary-gray-500"
            onClick={toggleFilter(toggle)}
          />
        </Tooltip>
      )}
      settingItems={[
        {
          name: 'activeTab',
          label: 'Show...',
          settingOptions: [commentsAndActivityOption, commentsOnlyOption],
        },
      ]}
    />
  );
};

export default ActivityLogFilter;
