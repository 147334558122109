import { X } from '@phosphor-icons/react';
import classNames from 'classnames';

import Button from 'js/design-system/Button/Button';
import { ButtonSize, ButtonVariant } from 'js/design-system/Button/types';
import IconButton from 'js/design-system/IconButton/IconButton';
import Modal from 'js/design-system/Modal';
import Text from 'js/design-system/Text/Text';
import useElementScroll from 'js/hooks/useElementScroll';

import { ActionableModalProps } from './types';

import styles from './ActionableModal.module.scss';

const ActionableModal = ({
  title,
  subtitle,
  description,
  show = false,
  confirmLabel = 'Add',
  cancelLabel = 'Cancel',
  children,
  onConfirm,
  onCancel,
  onHide,
  confirmBtnProps = {},
  contentClassName,
  hideCancelButton = false,
}: ActionableModalProps) => {
  const [modalContentScroll, modalContentRef] = useElementScroll<HTMLDivElement>(false);
  const hasScrollTop = modalContentScroll?.scrollTop && modalContentScroll.scrollTop > 0;
  const hasScrollBottom =
    (modalContentScroll?.scrollTop || 0) + (modalContentScroll?.offsetHeight || 0) <
    (modalContentScroll?.scrollHeight || 0);

  const renderHeader = (
    <>
      <Text variant="text-heading-h6" className={styles.title}>
        {title}
      </Text>
      {subtitle && (
        <Text variant="text-misc-caption" color="platform-gray-700" className={styles.subtitle}>
          {subtitle}
        </Text>
      )}
      <IconButton
        icon={X}
        size="small"
        shape="circle"
        className={styles.closeButton}
        onClick={onHide}
        iconColor="primary-gray-500"
      />
    </>
  );

  const renderFooter = (
    <>
      <Button
        variant={ButtonVariant.Filled}
        size={ButtonSize.Medium}
        onClick={onConfirm}
        {...confirmBtnProps}
        className={classNames(styles.footerButton, confirmBtnProps.className)}
      >
        {confirmLabel}
      </Button>
      {!hideCancelButton && (
        <Button
          variant={ButtonVariant.Ghost}
          size={ButtonSize.Medium}
          onClick={onCancel}
          className={styles.footerButton}
        >
          {cancelLabel}
        </Button>
      )}
    </>
  );

  return (
    <Modal
      header={renderHeader}
      footer={renderFooter}
      show={show}
      onHide={onHide}
      dialogClassName={styles.wrapper}
      headerClassName={classNames(styles.header, { [styles.withShadow]: hasScrollTop })}
      footerClassName={classNames({ [styles.withShadow]: hasScrollBottom })}
      contentClassName={classNames(styles.content, contentClassName)}
      ref={modalContentRef}
    >
      {description && <Text color="platform-gray-900">{description}</Text>}
      {children}
    </Modal>
  );
};

export default ActionableModal;
