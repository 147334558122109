const getError = (error) => {
  // Datadog accepts error object with the following attributes: message, stack, type and source
  // https://docs.datadoghq.com/real_user_monitoring/browser/collecting_browser_errors/?tab=npm
  const isError = error && error.message && error.stack;
  return isError ? error : { message: 'Unspecified error' };
};

const getLogger = (method) => (message, exception) => {
  if (window.DD_RUM !== undefined && (method === 'error' || method === 'warn')) {
    const error = getError(message);
    window.DD_RUM.onReady(() => window.DD_RUM.addError(exception || error));
  }
  // eslint-disable-next-line no-console
  console[method](message);
};

export const logError = getLogger('error');
export const logInfo = getLogger('info');
export const logWarn = getLogger('warn');

export default getLogger;
