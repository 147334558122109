import classNames from 'classnames';
import colors from 'css/_colors.scss';
import { forwardRef } from 'react';

import {
  IconButtonProps,
  ButtonShape,
  ButtonSize,
  IconButtonVariant,
} from 'js/design-system/Button/types';
import Icon, { Size as IconSize } from 'js/design-system/Icon';

import styles from './IconButton.module.scss';

const btnIconSizeMap: Record<ButtonSize, IconSize> = {
  [ButtonSize.Small]: 20,
  [ButtonSize.Medium]: 24,
  [ButtonSize.Large]: 24,
};

export const defaultVariant = IconButtonVariant.Default;
export const defaultColor = colors['platform-gray-700'];
export const defaultShape = ButtonShape.Square;
export const defaultSize = ButtonSize.Small;

const IconButton = (
  {
    type = 'button',
    shape = defaultShape,
    variant = defaultVariant,
    icon,
    iconWeight,
    iconColor,
    size = defaultSize,
    className,
    onClick,
    disabled,
    title,
    ...props
  }: IconButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>,
) => {
  const color = !disabled && (iconColor ? colors[iconColor] : defaultColor);

  return (
    <button
      ref={ref}
      type={type}
      className={classNames(styles.button, styles[size], styles[shape], styles[variant], className)}
      style={{ color }}
      onClick={onClick}
      disabled={disabled}
      title={title}
      {...props}
    >
      <Icon icon={icon} size={btnIconSizeMap[size]} weight={iconWeight} />
    </button>
  );
};

export default forwardRef(IconButton);
