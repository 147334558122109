import classnames from 'classnames';
import React from 'react';

import styles from './Container.module.scss';

interface ContainerProps {
  header?: React.ReactNode;
  subHeader?: React.ReactNode;
  children: React.ReactNode;
  containerClassName?: string;
  headerClassName?: string;
  subHeaderClassName?: string;
  contentClassName?: string;
}

const Container = ({
  header,
  subHeader,
  containerClassName,
  headerClassName,
  subHeaderClassName,
  contentClassName,
  children,
}: ContainerProps) => {
  return (
    <div className={classnames('container-xl', containerClassName)}>
      {header && <h1 className={classnames(styles.header, headerClassName)}>{header}</h1>}
      {subHeader && (
        <div className={classnames('text-muted fs-4', styles.subHeader, subHeaderClassName)}>
          {subHeader}
        </div>
      )}
      <div className={classnames(styles.content, contentClassName)}>{children}</div>
    </div>
  );
};

export default Container;
