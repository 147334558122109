import { formatError } from 'js/components-legacy/common/AlertMessage';
import {
  ApiResponseError,
  ImportRangeParams,
  PayRange,
  PayRangeGroup,
  PayRangeJobFamily,
  PayRangeStatus,
  RangePreviewsResponse,
} from 'types';

import api from './api';

export const fetchActiveRange = async (scenarioId: number) => {
  const { success, data, error } = await api.get<PayRange | null>(
    `/scenarios/${scenarioId}/current_pay_range`,
  );

  if (success) return data;

  throw new Error(`Failed to fetch active range. ${formatError(error)}`);
};

export const fetchDraftRange = async (scenarioId: number) => {
  const { success, data, error } = await api.get<PayRange | null>(
    `/scenarios/${scenarioId}/draft_pay_range`,
  );

  if (success) return data;

  throw new Error(`Failed to fetch draft range. ${formatError(error)}`);
};

export const rebuildRange = async (payRangeId: number, keepGroupings?: boolean) => {
  const { success, data, error } = await api.post<PayRange>(`/pay_ranges/${payRangeId}/rebuild`, {
    params: { keepGroupings },
  });

  if (success) return data;

  throw new Error(`Failed to rebuild range. ${formatError(error)}`);
};

export const fetchRangeJobFamilies = async (payRangeId?: number): Promise<PayRangeJobFamily[]> => {
  if (!payRangeId) return [];

  const { success, data, error } = await api.get<PayRangeJobFamily[]>(
    `/pay_ranges/${payRangeId}/pay_range_job_families`,
  );

  if (success) return data;

  throw new Error(`Failed to fetch range group families. ${formatError(error)}`);
};

export const fetchRangeGroup = async (rangeGroupId: number): Promise<PayRangeGroup> => {
  const { success, data, error } = await api.get<PayRangeGroup>(
    `/pay_range_groups/${rangeGroupId}`,
  );

  if (success) return data;

  throw new Error(`Failed to fetch range group. ${formatError(error)}`);
};

export const fetchRangePreviews = async (payRangeId: number) => {
  const { success, data, error } = await api.get<RangePreviewsResponse>(
    `/pay_ranges/${payRangeId}/previews`,
  );

  if (success) return data;

  throw new Error(`Failed to fetch employee previews. ${formatError(error)}`);
};

export const createRange = async (scenarioId: number) => {
  const { data, success, error } = await api.post<PayRange>(`/scenarios/${scenarioId}/pay_ranges`, {
    params: {
      payRange: {
        status: PayRangeStatus.Draft,
      },
    },
  });

  if (success) return data;

  throw new Error(`Failed to create range. ${formatError(error)}`);
};

export const updateRange = async (
  scenarioId: number,
  payRangeId: number,
  attrs: Partial<PayRange>,
) => {
  const { data, success, error } = await api.patch<PayRange>(
    `/scenarios/${scenarioId}/pay_ranges/${payRangeId}`,
    {
      params: {
        payRange: attrs,
      },
    },
  );

  if (success) return data;

  throw new Error(`Failed to update range. ${formatError(error)}`);
};

export class ImportRangeError extends Error {
  csvError: ApiResponseError;

  constructor(message: string, csvError: ApiResponseError) {
    super(message);
    this.csvError = csvError;
  }
}

export const importRange = async (scenarioId: number, params: ImportRangeParams) => {
  const { data, success, error } = await api.post<PayRange>(
    `/scenarios/${scenarioId}/pay_ranges/import`,
    {
      params: {
        payRange: params,
      },
    },
  );

  if (success) return data;

  throw new ImportRangeError('Failed to import range.', error);
};
