import classNames from 'classnames';
import { ForwardedRef, forwardRef } from 'react';

import styles from '../BaseTable.module.scss';

interface TableRowProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  dataTestId?: string;
}

const TableRow = (
  { children, style, className, dataTestId }: TableRowProps,
  ref: ForwardedRef<HTMLDivElement>,
) => (
  <div
    role="row"
    style={style}
    className={classNames(styles.row, className)}
    ref={ref}
    data-testid={dataTestId}
  >
    {children}
  </div>
);

export default forwardRef(TableRow);
