import { AnyAction } from 'redux';

import { FetchStatus, PayRangeBand } from 'types';

import ACTIONS from '../constants/actions';

type RangeBandState = {
  byGroupId: Record<number, PayRangeBand[]>;
  statusByGroupId: Record<number, FetchStatus>;
};

const initialState: RangeBandState = {
  byGroupId: {},
  statusByGroupId: {},
};

export default (
  state: RangeBandState = initialState,
  action: AnyAction = { type: null },
): RangeBandState => {
  switch (action.type) {
    case ACTIONS.RESET_RANGE_BANDS:
      return initialState;
    case ACTIONS.FETCHING_GROUP_OF_RANGE_BANDS:
      return {
        ...state,
        statusByGroupId: {
          ...state.statusByGroupId,
          [action.id]: FetchStatus.Loading,
        },
      };
    case ACTIONS.RECEIVE_GROUP_OF_RANGE_BANDS:
      return {
        ...state,
        byGroupId: {
          ...state.byGroupId,
          [action.id]: action.data,
        },
        statusByGroupId: {
          ...state.statusByGroupId,
          [action.id]: FetchStatus.Succeeded,
        },
      };
    case ACTIONS.FAIL_FETCH_GROUP_OF_RANGE_BANDS:
      return {
        ...state,
        statusByGroupId: {
          ...state.statusByGroupId,
          [action.id]: FetchStatus.Failed,
        },
      };
    default:
      return state;
  }
};
