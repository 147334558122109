import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import { useDismissCompanyNotification, NotificationName } from 'js/api/companyNotifications';
import Carousel from 'js/components-legacy/common/Carousel';
import useAccess from 'js/hooks/useAccess';
import useSeatLimit from 'js/hooks/useSeatLimit';
import queries from 'js/queries';
import { Company } from 'types';

import EquityBanner from './EquityBanner';
import NewBenchmarkBanner from './NewBenchmarkBanner';
import NewBenchmarkReleasedBanner from './NewBenchmarkReleasedBanner';
import OpenImperativeBanner from './OpenImperativeBanner';
import OverSeatLimitBanner from './OverSeatLimitBanner';
import RangesBanner from './RangesBanner';

interface BannerCarouselProps {
  company: Company;
  scenarioId: number;
}

const BannerCarousel = ({ company, scenarioId }: BannerCarouselProps) => {
  const [showOverlimitBanner, setShowOverlimitBanner] = useState(false);
  const { equityBannerAccessible, upgradeBannerAccessible } = useAccess({
    company,
  });
  const { overLimit } = useSeatLimit(scenarioId);
  const { data: companyNotifications = [] } = useQuery(
    queries.companies.detail(company.id)._ctx.notifications,
  );
  const { data: equityConnection, isSuccess: equityFetched } = useQuery(
    queries.companies.detail(company.id)._ctx.equityConnection,
  );
  const dismiss = useDismissCompanyNotification(company.id);

  const rangesBanner = companyNotifications.find(({ name }) => name === NotificationName.Ranges);
  const openImperativeBanner = companyNotifications.find(
    ({ name }) => name === NotificationName.OpenImperative,
  );
  const newBenchmarkReleasedBanner = companyNotifications.find(
    ({ name }) => name === NotificationName.NewBenchmarkReleased,
  );
  const newBenchmarkBanner = companyNotifications.find(
    ({ name }) => name === NotificationName.NewBenchmark,
  );

  const equityBanner = companyNotifications.find(({ name }) => name === NotificationName.Equity);

  const items = [
    rangesBanner && <RangesBanner dismiss={() => dismiss.mutate(rangesBanner.id)} />,
    openImperativeBanner && (
      <OpenImperativeBanner dismiss={() => dismiss.mutate(openImperativeBanner.id)} />
    ),
    newBenchmarkReleasedBanner && (
      <NewBenchmarkReleasedBanner dismiss={() => dismiss.mutate(newBenchmarkReleasedBanner.id)} />
    ),
    newBenchmarkBanner && (
      <NewBenchmarkBanner dismiss={() => dismiss.mutate(newBenchmarkBanner.id)} />
    ),
    upgradeBannerAccessible && overLimit && showOverlimitBanner && (
      <OverSeatLimitBanner company={company} dismiss={() => setShowOverlimitBanner(false)} />
    ),
    equityBannerAccessible && equityBanner && equityFetched && !equityConnection && (
      <EquityBanner companyId={company.id} dismiss={() => dismiss.mutate(equityBanner.id)} />
    ),
  ].filter((item) => !!item);

  if (!company || items.length === 0) return null;

  return <Carousel items={items} className="container-xl px-0 px-lg-5 mt-0 mt-lg-5" />;
};

export default BannerCarousel;
