import { CalendarBlank } from '@phosphor-icons/react';
import classNames from 'classnames';
import { ForwardedRef, forwardRef } from 'react';

import Icon from 'js/design-system/Icon';

import TextField from '../TextField';
import { DateFieldProps } from './types';

import styles from './DateField.module.scss';

const DateField = forwardRef(
  ({ className, type = 'date', ...props }: DateFieldProps, ref: ForwardedRef<HTMLInputElement>) => (
    <TextField
      ref={ref}
      {...props}
      type={type}
      className={classNames(className, styles.input)}
      inputPrepend={<Icon icon={CalendarBlank} size={16} color="platform-gray-700" />}
    />
  ),
);

DateField.displayName = 'DateField';

export default DateField;
