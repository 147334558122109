export enum PayRangeBandVariant {
  Gray = 'gray',
  Green = 'green',
  Blue = 'blue',
  Cyan = 'cyan',
  Mint = 'mint',
  Transparent = 'transparent',
  Outline = 'outline',
}

export type PayRangeBand = {
  id: number;
  compType: string;
  jobLevelId: number;
  jobTypeId: number;
  jobTypeName: string;
  jobTypeCode: string;
  max: number;
  mid: number;
  min: number;
  payRangeGroupId: number;
  payTierId: number | null;
};

export interface RangeBandByJobLevelId {
  min?: number | null;
  max?: number | null;
  byJobLevelId: Record<number, Omit<RangeBandByJobLevelId, 'byJobLevelId'>>;
  empty: boolean;
}

export interface RangeBand {
  min?: number | null;
  max?: number | null;
  maxJobTypeBand?: number | null;
  minJobTypeBand?: number | null;
  variant?: PayRangeBandVariant;
}
