import { AnyAction } from 'redux';

import { FetchStatus, Offer } from 'types';

import ACTIONS from '../constants/actions';

interface OfferState {
  allIds: number[];
  byId: Record<number, Offer>;
  scenarioId: number | null;
  status: FetchStatus;
}

const initialState: OfferState = {
  allIds: [],
  byId: {},
  scenarioId: null,
  status: FetchStatus.Idle,
};

export default (state = initialState, action: AnyAction = { type: null }): OfferState => {
  switch (action.type) {
    case ACTIONS.RECEIVE_OFFERS: {
      const { offers, scenarioId } = action.data;

      return {
        ...state,
        allIds: offers.map((offer: Offer) => offer.id),
        byId: offers.reduce((byId: Record<number, Offer>, offer: Offer) => {
          byId[offer.id] = { ...state.byId[offer.id], ...offer };
          return byId;
        }, {}),
        scenarioId,
        status: FetchStatus.Succeeded,
      };
    }
    case ACTIONS.RECEIVE_OFFER: {
      const offer = action.data;

      return {
        ...state,
        allIds: state.allIds.includes(offer.id) ? state.allIds : [...state.allIds, offer.id],
        byId: { ...state.byId, [offer.id]: { ...state.byId[offer.id], ...offer } },
      };
    }
    default:
      return state;
  }
};
