import classNames from 'classnames';

import styles from './HorizontalFields.module.scss';

interface HorizontalFieldsProps {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

const HorizontalFields = ({ children, className, style }: HorizontalFieldsProps) => (
  <div className={classNames(className, styles.fields)} style={style}>
    {children}
  </div>
);

export default HorizontalFields;
