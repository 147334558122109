import { cloneElement } from 'react';

import { HRISConnectOpenEventDetails } from 'js/analytics/types/hris';
import { OAuthInstallEventDetails } from 'js/analytics/types/oauth';
import useTrackEvent from 'js/analytics/useTrackEvent';
import localStorageSafe from 'js/utils/local-storage-safe';

export const getRipplingRedirectStorageKey = (companyId: number) =>
  `${companyId}_rippling_redirect_to`;

interface ConnectButtonProps {
  children: React.ReactElement;
  companyId: number;
  payrollProvider: string;
}

const ConnectRipplingButton = ({ children, companyId }: ConnectButtonProps) => {
  const { trackEvent } = useTrackEvent<HRISConnectOpenEventDetails | OAuthInstallEventDetails>();

  const openRedirect = () => {
    const ripplingUrl = `${process.env.REACT_APP_RIPPLING_OC_APP_URL}`;
    localStorageSafe.setItem(getRipplingRedirectStorageKey(companyId), window.location.pathname);
    window.location.replace(ripplingUrl);
  };
  const onClick = async () => {
    trackEvent('hris.connect.open', { source: 'rippling' });
    trackEvent('oauth.install', {
      identityProvider: 'rippling',
      source: 'ConnectRipplingButton',
    });
    openRedirect();
  };

  return cloneElement(children, { onClick });
};

export default ConnectRipplingButton;
