import classNames from 'classnames';
import colors from 'css/_colors.scss';
import ReactAvatar from 'react-avatar';

import Tooltip from 'js/design-system/Tooltip/Tooltip';

import { AvatarProps, variants } from './types';

import styles from './Avatar.module.scss';
import textStyles from 'js/design-system/Text/Text.module.scss';

const COLOR = [
  { color: colors['secondary-blue-700'], fgColor: colors['neutral-full-white'], name: 'Blue' },
  { color: colors['system-indigo-600'], fgColor: colors['neutral-full-white'], name: 'Indigo' },
  { color: colors['secondary-purple-700'], fgColor: colors['neutral-full-white'], name: 'Purple' },
  { color: colors['system-pink-600'], fgColor: colors['neutral-full-white'], name: 'Pink' },
  { color: colors['system-red-600'], fgColor: colors['neutral-full-white'], name: 'Red' },
  { color: colors['system-orange-600'], fgColor: colors['neutral-full-white'], name: 'Orange' },
  { color: colors['system-yellow-600'], fgColor: colors['primary-gray-800'], name: 'Yellow' },
  { color: colors['primary-green-700'], fgColor: colors['neutral-full-white'], name: 'Green' },
  { color: colors['system-teal-600'], fgColor: colors['primary-gray-800'], name: 'Teal' },
  { color: colors['system-cyan-600'], fgColor: colors['neutral-full-white'], name: 'Cyan' },
];

const getColorFrom = (colorIndex?: string | number) => {
  const index = typeof colorIndex === 'string' ? colorIndex.charCodeAt(0) : colorIndex;

  return COLOR[(index || 0) % COLOR.length];
};

const Avatar = ({
  avatarSrc,
  name,
  variant = 'small',
  colorIndex,
  tooltip,
  className,
  wrapperClassName,
}: AvatarProps) => {
  const { color, fgColor } = getColorFrom(colorIndex || name);

  return (
    <Tooltip content={tooltip}>
      <div className={classNames('d-inline-block', wrapperClassName)}>
        <ReactAvatar
          src={avatarSrc}
          size={variants[variant].size}
          name={name}
          round
          color={color}
          fgColor={fgColor}
          className={classNames(
            styles.avatar,
            className,
            textStyles[variants[variant].textVariant],
          )}
        />
      </div>
    </Tooltip>
  );
};

export default Avatar;
