import { ArrowsClockwise, ChartLine, ChartPie, Chats, Users } from '@phosphor-icons/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import api from 'js/api/api';
import { s3Upload } from 'js/components-legacy/common/S3Uploader';
import { FileUploadButton, Form } from 'js/components-legacy/form';
import Layout from 'js/components/dashboards/layout/Layout';
import Button from 'js/design-system/Button/Button';
import { ButtonVariant } from 'js/design-system/Button/types';
import PhosphorIcon from 'js/design-system/Icon/PhosphorIcon';
import InformationalModal, { InformationalModalProps } from 'js/design-system/InformationalModal';
import Text from 'js/design-system/Text/Text';
import { ReactComponent as RangesIcon } from 'js/design-system/_custom-icons/ranges-icon.svg';
import useAppSelector from 'js/hooks/useAppSelector';
import useNumberParams from 'js/hooks/useNumberParams';
import { currentUserSelector } from 'js/selectors/currentUser';
import { getById as scenarioSelector } from 'js/selectors/scenarios';
import { openInNewTab } from 'js/utils/common';
import { exportScenario } from 'js/utils/csv-exporter';
import { logError } from 'js/utils/logger';
import { Scenario, ScenarioFormData, ScenarioImportResponseError } from 'types';

import Spacer from '../comp-gallery-page/viewer/Spacer';
import { DashboardHeader } from '../dashboards/header/DashboardHeader';

import styles from './Launchpad.module.scss';

type DownloadModal = Pick<InformationalModalProps, 'onPrimaryClick' | 'onSecondaryClick'>;
interface UploadModal {
  redirectUri: string;
}
export const Launchpad: React.FC = () => {
  const navigate = useNavigate();
  const [downloadModal, setDownloadModal] = React.useState<DownloadModal | undefined>();
  const [uploadModal, setUploadModal] = React.useState<UploadModal | undefined>();
  const [loading, setLoading] = React.useState(false);
  const { scenarioId } = useNumberParams();
  const scenario = useAppSelector((state) => scenarioSelector(state, scenarioId));
  const currentUser = useAppSelector(currentUserSelector);
  if (!currentUser) return null;
  if (!scenario) return null;

  const heading = `Hi ${currentUser.firstName}, what would you like to do today?`;
  const ICON_SIZE = 48;

  // TODO: only show modal onClick if scenario has no employees yet
  const downloadSampleData = (redirect_uri: string) => {
    setDownloadModal(undefined);
    setUploadModal({ redirectUri: redirect_uri });
    exportScenario(scenario, 'market', ['name']);
  };
  const cards: { title: string; icon: React.FunctionComponent; onClick: () => void }[] = [
    {
      title: 'Get market data',
      icon: ChartLine,
      onClick: () =>
        setDownloadModal({
          onPrimaryClick: () => downloadSampleData(`/scenarios/${scenarioId}/market`),
          onSecondaryClick: () => navigate(`/scenarios/${scenarioId}/market`),
        }),
    },
    {
      title: 'Benchmark my employees',
      icon: Users,
      onClick: () =>
        setDownloadModal({
          onPrimaryClick: () => downloadSampleData(`/scenarios/${scenarioId}/launchpad`),
          onSecondaryClick: () => navigate(`/scenarios/${scenarioId}/benchmark-employees`),
        }),
    },
    {
      title: 'Build pay ranges',
      icon: () => <RangesIcon height={ICON_SIZE} width={ICON_SIZE} />,
      onClick: () =>
        setDownloadModal({
          onPrimaryClick: () => downloadSampleData(`/scenarios/${scenarioId}/pay-strategy`),
          onSecondaryClick: () => navigate(`/scenarios/${scenarioId}/pay-strategy`),
        }),
    },
    {
      title: 'Discover DEI insights',
      icon: ChartPie,
      onClick: () =>
        setDownloadModal({
          onPrimaryClick: () => downloadSampleData(`/scenarios/${scenarioId}/diversity`),
          onSecondaryClick: () => navigate(`/scenarios/${scenarioId}/diversity`),
        }),
    },
    {
      title: 'Run a merit cycle',
      icon: ArrowsClockwise,
      onClick: () =>
        setDownloadModal({
          onPrimaryClick: () => downloadSampleData(`/scenarios/${scenarioId}/comp-cycles`),
          onSecondaryClick: () => navigate(`/scenarios/${scenarioId}/comp-cycles`),
        }),
    },
    {
      title: 'Talk to an expert',
      icon: Chats,
      onClick: () => openInNewTab('https://www.opencomp.com/request-a-demo-in-app'),
    },
  ];
  const submitSampleData = async (formValues: ScenarioFormData, redirectUri: string) => {
    setLoading(true);
    try {
      const s3Result = await s3Upload(formValues.importFile);
      const payload = {
        importFileS3Path: s3Result?.s3Path,
        importFileFilename: s3Result?.filename,
      };
      const { error } = await api.put<Scenario, ScenarioImportResponseError>(
        `/scenarios/${scenarioId}`,
        {
          params: { scenario: payload },
        },
      );
      if (error) {
        // TODO: handle error once the designs are ready
        logError(error);
      }
    } catch (e) {
      logError(e);
    }
    setLoading(false);
    setUploadModal(undefined);
    navigate({
      pathname: `/scenarios/${scenarioId}/benchmark-employees`,
      search: `?redirect_uri=${redirectUri}`,
    });
  };
  return (
    <>
      {downloadModal ? (
        <InformationalModal
          title="Experience the power of OpenComp"
          description="Click below to download a sample employee data file. We encourage you to open the file (in Excel or Sheets) and explore. Please do not make any changes to the sample file, however."
          primaryLabel="Download sample data"
          onPrimaryClick={downloadModal.onPrimaryClick}
          secondaryLabel="I'll explore on my own"
          onSecondaryClick={downloadModal.onSecondaryClick}
          icon={() => null}
          show={downloadModal !== undefined}
          onHide={() => setDownloadModal(undefined)}
        />
      ) : null}
      {uploadModal ? (
        <InformationalModal
          title="Now, upload the sample data file"
          description={
            <>
              <Form
                onSubmit={(data: ScenarioFormData) =>
                  submitSampleData(data, uploadModal.redirectUri)
                }
              >
                <div>
                  Below, select & upload the sample data file file_name.csv that you downloaded in
                  the previous step.
                </div>
                <Spacer height="spacing-32" />
                <FileUploadButton label="Select file..." />
                <Spacer height="spacing-32" />
                <Button
                  type="submit"
                  variant={ButtonVariant.Filled}
                  size="large"
                  className={styles.upload}
                  disabled={loading}
                >
                  Upload
                </Button>
              </Form>
            </>
          }
          icon={() => null}
          show={!!uploadModal}
          onHide={() => setUploadModal(undefined)}
        />
      ) : null}
      <Layout
        dashboardHeader={<DashboardHeader title={scenario?.name} hideHris hideHeadcount />}
        heading={heading}
        body={
          <div className="container-xl">
            <div className={styles.deck}>
              {cards.map((card) => (
                <button key={card.title} className={styles.card} onClick={card.onClick}>
                  <PhosphorIcon icon={card.icon} size={ICON_SIZE} />
                  <Text variant="text-subheading-medium" className={styles.title}>
                    {card.title}
                  </Text>
                </button>
              ))}
            </div>
          </div>
        }
      />
    </>
  );
};
