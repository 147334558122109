import { Navigate, Outlet } from 'react-router-dom';

import GLOBALS from 'js/config/globals';
import useAppSelector from 'js/hooks/useAppSelector';
import useNumberParams from 'js/hooks/useNumberParams';
import { currentUserRoleSelector } from 'js/selectors/currentUser';
import { permissionsFetchedStatusSelector } from 'js/selectors/permissions';
import { FetchStatus, UserRole } from 'types';

interface PermissionProtectedRouteProps {
  only: UserRole[];
}

export const fallbacks = (role: UserRole, scenarioId: number) => {
  if (role === UserRole.Recruiter || role === UserRole.Approver) {
    return `/scenarios/${scenarioId}/offers`;
  }

  if (role === GLOBALS.roles.pending) {
    return '/access-denied';
  }

  return `/scenarios/${scenarioId}/total-rewards`;
};

const PermissionProtectedRoute = ({ only }: PermissionProtectedRouteProps) => {
  const { scenarioId } = useNumberParams();
  const currentUserRole = useAppSelector((state) =>
    currentUserRoleSelector(state, undefined, scenarioId),
  );
  const permissionsStatus = useAppSelector(permissionsFetchedStatusSelector);

  if (permissionsStatus !== FetchStatus.Succeeded) return null;

  if (!currentUserRole || currentUserRole === GLOBALS.roles.pending) {
    return <Navigate to="/access-denied" />;
  }

  // I think the current code is just allowing any of these to view any route
  if (!only.includes(currentUserRole)) {
    return <Navigate to={fallbacks(currentUserRole, scenarioId)} />;
  }

  return <Outlet />;
};

export default PermissionProtectedRoute;
