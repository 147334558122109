import image from 'img/banners/benchmark.svg';
import { GeneralClickContactUsEventDetails } from 'js/analytics/types/general';
import useTrackEvent from 'js/analytics/useTrackEvent';
import useChat from 'js/hooks/useChat';
import { PlayBook } from 'types';

import Banner from './Banner';

interface NewBenchmarkReleasedBannerProps {
  dismiss(): void;
}

const NewBenchmarkReleasedBanner = ({ dismiss }: NewBenchmarkReleasedBannerProps) => {
  const { startChat } = useChat();
  const { trackEvent } = useTrackEvent<GeneralClickContactUsEventDetails>();

  const onConfirm = () => {
    trackEvent('general.click.contactUs', { source: 'banner' });
    startChat(PlayBook.Help);
  };

  return (
    <Banner
      shown
      onConfirm={onConfirm}
      onDismiss={dismiss}
      title="Benchmarking update!"
      text="We’ve just hit the refresh button on our market data, so check out your updated market position to win top candidates and retain your top performers."
      cta="Contact us"
      dismissText="Dismiss"
      image={image}
    />
  );
};

export default NewBenchmarkReleasedBanner;
