import { AppState, FetchStatus, UserPermission } from 'types';

export const byScenarioId = (state: AppState): Record<number, UserPermission[]> =>
  state.scenarioUsers.byScenarioId;

export const getByScenarioId = (state: AppState, scenarioId?: number): UserPermission[] =>
  state.scenarioUsers.byScenarioId[Number(scenarioId)] || [];

export const statusByScenarioId = (state: AppState, scenarioId?: number): FetchStatus =>
  state.scenarioUsers.statusByScenarioId[Number(scenarioId)] || FetchStatus.Idle;
