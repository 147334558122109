import { useEffect, useState } from 'react';

import { getSuggestEmployees } from 'js/api/employees';
import { EmployeeSearchOption } from 'js/components/common/employee-search-list/EmployeeSearchOption';
import { Suggest } from 'js/design-system/Form';
import { SuggestProps, Suggestion } from 'js/design-system/Form/Suggest/Suggest';
import { EmployeeMatch } from 'types';

interface EmployeeSuggestProps extends Omit<SuggestProps<number>, 'suggestions' | 'onType'> {
  scenarioId: number;
}

export const InviteEmployeeSuggest = ({ scenarioId, ...suggestProps }: EmployeeSuggestProps) => {
  const [loading, setLoading] = useState(false);
  const [suggestions, setSuggestions] = useState<Suggestion<number>[]>([]);

  const buildSuggestion = (employee: EmployeeMatch) => (
    <EmployeeSearchOption
      employee={employee}
      tooltipContent={employee.usersEmployeeId ? 'Already invited' : ''}
      disabled={!!employee.usersEmployeeId}
    />
  );

  const getSuggestions = async (value: string) => {
    setLoading(true);

    const { success, data } = await getSuggestEmployees({
      scenarioId,
      suggest: { name: value },
    });

    const isSuccess = success && data && data.length > 0;

    setSuggestions(() =>
      isSuccess
        ? data.map((employee) => ({
            value: employee.id,
            label: employee.name,
            displayLabel: buildSuggestion(employee),
            disabled: !!employee.usersEmployeeId,
          }))
        : [],
    );

    setLoading(false);
  };

  useEffect(() => {
    getSuggestions('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Suggest
      defaultValue={suggestProps.defaultValue}
      suggestions={suggestions}
      onType={getSuggestions}
      forceSelection
      loading={!!loading}
      {...suggestProps}
    />
  );
};
