const GLOBALS = Object.freeze({
  maxEmployeeFreeLimit: 25,

  domainName: process.env.REACT_APP_DOMAIN_NAME,

  compPercentileLimits: [10, 90],
  roundPercentileToLimits: false,

  googleMapLibraries: ['places'],
  otherLocationId: 1,

  percentiles: [25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90],

  departmentColors: [
    'red',
    'orange',
    'yellow',
    'green',
    'mint',
    'teal',
    'cyan',
    'indigo',
    'purple',
    'pink',
  ],

  // TODO: merge with active currencies (above) and country-js
  countryOptions: [
    { name: 'Algeria', code: 'DZ' },
    { name: 'Argentina', code: 'AR' },
    { name: 'Australia', code: 'AU' },
    { name: 'Brazil', code: 'BR' },
    { name: 'Bulgaria', code: 'BG' },
    { name: 'Canada', code: 'CA' },
    { name: 'China', code: 'CN' },
    { name: 'Colombia', code: 'CO' },
    { name: 'Czech Republic', code: 'CZ' },
    { name: 'Denmark', code: 'DK' },
    { name: 'Egypt', code: 'EG' },
    { name: 'France', code: 'FR' },
    { name: 'Germany', code: 'DE' },
    { name: 'Hong Kong', code: 'HK' },
    { name: 'India', code: 'IN' },
    { name: 'Indonesia', code: 'ID' },
    { name: 'Ireland', code: 'IE' },
    { name: 'Israel', code: 'IL' },
    { name: 'Japan', code: 'JP' },
    { name: 'Kazakhstan', code: 'KZ' },
    { name: 'Kenya', code: 'KE' },
    { name: 'Mexico', code: 'MX' },
    { name: 'Netherlands', code: 'NL' },
    { name: 'New Zealand', code: 'NZ' },
    { name: 'Nigeria', code: 'NG' },
    { name: 'Norway', code: 'NO' },
    { name: 'Philippines', code: 'PH' },
    { name: 'Poland', code: 'PL' },
    { name: 'Portugal', code: 'PT' },
    { name: 'Romania', code: 'RO' },
    { name: 'Saudi Arabia', code: 'SA' },
    { name: 'Singapore', code: 'SG' },
    { name: 'South Korea', code: 'KR' },
    { name: 'Spain', code: 'ES' },
    { name: 'Sweden', code: 'SE' },
    { name: 'Switzerland', code: 'CH' },
    { name: 'Taiwan', code: 'TW' },
    { name: 'Thailand', code: 'TH' },
    { name: 'Tunisia', code: 'TN' },
    { name: 'Turkey', code: 'TR' },
    { name: 'Ukraine', code: 'UA' },
    { name: 'United Kingdom', code: 'GB' },
    { name: 'United States', code: 'US' },
    { name: 'Vietnam', code: 'VN' },
  ],

  compPercentEqualityTolerance: 0.02,

  cookieTTL: 2592000,

  // NOTE: This must match backend
  employeeLockedFields: [
    'startDate',
    'endDate',
    'name',
    'departmentId',
    'reportsToId',
    'jobTitle',
    'locationName',
    'currencyCode',
    'baseSalary',
    'personalEmail',
    'workEmail',
    'useMarketData',
    'employeeType',
    'employeeSubType',
    'employeeNumber',
  ],

  offerRejectedReasons: {
    ranges: [
      'Above range',
      'Below range',
      'In range, too high',
      'In range, too low',
      'Role/level mismatch',
      'Other',
    ],
    market: [
      'Salary/OTE too high',
      'Salary/OTE too low',
      'Equity too high',
      'Equity too low',
      'Other',
    ],
  },

  candidateRejectedReasons: [
    'Salary / OTE too low',
    'Equity too low',
    'Took another offer due to compensation',
    'Took another offer',
    'Other',
  ],

  candidateReviseReasons: [
    'Salary / OTE too low',
    'Equity too low',
    'Needed sign-on bonus',
    'Other',
  ],

  compCycles: {
    maxCashBudget: 1_000_000_000_000,
    maxEquityBudget: 1_000_000_000_000,
  },

  diversityGroupTypes: {
    gender: 'Gender',
    ethnicity: 'Ethnicity',
    both: 'Both',
  },

  rangeDisplayTypes: {
    departments: 'Departments',
    rangeGroups: 'Range Groups',
  },

  authFlowSteps: {
    signUp: 'signUp',
    signUpEmbedded: 'signUpEmbedded',
    verification: 'verification',
    verificationEmbedded: 'verificationEmbedded',
    signIn: 'signIn',
    forgotPass: 'forgot-password',
    forgotPassConfirm: 'forgot-password-confirm',
    resetPass: 'reset-password',
    continueWithGoogleOAuth: 'continue-with-google-oauth',
  },

  hiringViewMode: {
    headcount: 'Headcount',
    burn: 'Burn',
  },

  jobTypes: {
    support: {
      name: 'Support',
      code: 'SPT',
      id: 4,
    },
    individualContributor: {
      name: 'Individual Contributor',
      code: 'IC',
      id: 3,
    },
    management: {
      name: 'Management',
      code: 'MGMT',
      id: 2,
    },
    executive: {
      name: 'Executive',
      code: 'EXEC',
      id: 1,
    },
  },

  allLevels: [1, 2, 3, 4, 5, 6],
  vpLevels: [1, 2],
  svpLevels: [3, 4],
  execDefaultLevel: [5],
  orgLeaderLevels: [6],

  // TODO: refactor - this is the same as UserRole in permission.ts
  roles: {
    administrator: 'administrator',
    organizationLeader: 'organization_leader',
    teamLeader: 'team_leader',
    analyst: 'analyst',
    approver: 'approver',
    recruiter: 'recruiter',
    rewardsViewer: 'rewards_viewer',
    pending: 'pending',
  },

  onboardStatus: {
    started: 'started',
    employeesAdded: 'employees_added',
    complete: 'complete',
  },

  notificationType: {
    hris: 'hris',
    syncHris: 'sync_hris',
    equity: 'equity',
    syncEquity: 'sync_equity',
    newBenchmark: 'new_benchmark',
    newBenchmarkReleased: 'new_benchmark_released',
    openImperative: 'open_imperative',
    ranges: 'ranges',
  },

  employeeTypes: ['employee', 'contractor'],
  employeeSubTypes: [
    'full_time',
    'part_time',
    'intern',
    'temp',
    'seasonal',
    'individual_contractor',
  ],

  strategyTemplateTypes: {
    payRangePosition: 'pay_range_position',
    payRangeWidth: 'pay_range_width',
    scenarioGeoAdjustment: 'scenario_geo_adjustment',
  },

  modesWithCompDisplayType: ['hiring', 'diversity'],
  tooltipDelay: 1100,
  mobileMaxWidth: 680,
  tabletMaxWidth: 1150,
});

export default GLOBALS;
