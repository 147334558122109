import Badge from 'js/design-system/Badge';
import LoadingPlaceholder from 'js/design-system/LoadingPlaceholder/LoadingPlaceholder';
import Text from 'js/design-system/Text/Text';
import { getCompensationSummaryByType } from 'js/services/compensations/employeeService';
import { formatCash } from 'js/services/compensations/formatter';
import { CompensationType, Employee, truthyFilter } from 'types';

import styles from './BenchmarkingTable.module.scss';

interface EmployeePercentileCellProps {
  employee: Employee;
  loading: boolean;
}

export const EmployeePercentileCell = ({ employee, loading }: EmployeePercentileCellProps) => {
  const compSummary = getCompensationSummaryByType(CompensationType.SalaryOte, employee);

  if (loading) {
    return <LoadingPlaceholder className={styles.loadingCellEmployee} />;
  }

  const { commissionLabel, percentile, value } = compSummary;
  const formattedCash = formatCash(value.raw, {
    currency: employee.currencyCode || undefined,
  });

  return (
    <div className={styles.employeePercentileWrapper}>
      <Text variant="text-misc-input">
        {[formattedCash, percentile.formatted].filter(truthyFilter).join(' | ')}
      </Text>
      {commissionLabel && (
        <Badge className={styles.oteBadge} size="small">
          {commissionLabel}
        </Badge>
      )}
    </div>
  );
};
