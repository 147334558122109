import { useQuery } from '@tanstack/react-query';

import useAccess from 'js/hooks/useAccess';
import useAppSelector from 'js/hooks/useAppSelector';
import useNumberParams from 'js/hooks/useNumberParams';
import queries from 'js/queries';
import { getById as companySelector } from 'js/selectors/companies';
import { getById as scenarioSelector } from 'js/selectors/scenarios';

const useOnboardMarketCompletion = () => {
  const { scenarioId } = useNumberParams();
  const { data: employees = [] } = useQuery(
    queries.scenarios.detail(scenarioId)._ctx.employees(false),
  );
  const scenario = useAppSelector((state) => scenarioSelector(state, scenarioId));
  const company = useAppSelector((state) => companySelector(state, scenario?.companyId));
  const { useAccessReady, onboardMarketAccessible } = useAccess({ company });

  const employeesAdded = employees.length > 0;
  const benchmarkConfirmed = !!scenario?.benchmarkConfirmed;
  const showOnboardMarket = useAccessReady && onboardMarketAccessible && !benchmarkConfirmed;

  return { employeesAdded, benchmarkConfirmed, showOnboardMarket };
};

export default useOnboardMarketCompletion;
