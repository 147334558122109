import { CaretDown } from '@phosphor-icons/react';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Icon from 'js/design-system/Icon';
import Menu, { MenuItem } from 'js/design-system/Menu/Menu';
import Text from 'js/design-system/Text/Text';
import useAppSelector from 'js/hooks/useAppSelector';
import useScenarioNavigation from 'js/hooks/useScenarioNavigation';
import { getAll as scenariosSelector, getById as scenarioSelector } from 'js/selectors/scenarios';
import { Company } from 'types';

import styles from './TotalRewardsMenu.module.scss';

export const TotalRewardsMenu = ({
  scenarioId,
  company,
}: {
  scenarioId: number;
  company?: Company;
}) => {
  const navigate = useNavigate();
  const scenarios = useAppSelector(scenariosSelector);
  const currentScenario = useAppSelector((state) => scenarioSelector(state, scenarioId));
  const scenariosStatus = useAppSelector((state) => state.scenarios.indexStatus);
  const { getScenarioRedirectionURL } = useScenarioNavigation();
  const multiScenarios = scenarios.length > 1;

  const [items, setItems] = useState<MenuItem<number>[]>([]);
  const [loading, setLoading] = useState(true);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const loadingItem = { value: 0, label: 'Loading...', disabled: true };

  useEffect(() => {
    setLoading(scenariosStatus === 'loading');

    if (scenariosStatus === 'succeeded') {
      setItems(
        scenarios
          .map((scenario) => {
            const selected = scenario.id === currentScenario?.id;
            return {
              value: scenario.id,
              label: scenario.name,
              displayLabel: selected ? (
                <Text variant="text-misc-input-bold">{scenario.name}</Text>
              ) : null,
              disabled: selected,
            };
          })
          .sort((a: MenuItem<number>, b: MenuItem<number>) =>
            a.value === currentScenario?.id || b.value === currentScenario?.id ? -1 : 0,
          ),
      );
    }
  }, [currentScenario?.id, scenarios, scenariosStatus]);

  if (!currentScenario || !company) return null;

  const companyDropdown = (
    <div
      className={classNames(
        styles.companyDropdown,
        { [styles.companyDropdown__active]: menuOpen },
        { [styles.companyDropdown__multiScenarios]: multiScenarios },
      )}
    >
      <Text variant="text-subheading-small" color="neutral-full-white">
        Total Rewards Statement
      </Text>
      <div className={styles.companyDropdown__triggerWrap}>
        <div className={styles.companyDropdown__trigger}>
          <Text
            variant="text-misc-caption"
            color="platform-gray-500"
            className={styles.companyDropdown__triggerLabel}
          >
            {company?.companyName}
          </Text>
          {multiScenarios && <Icon icon={CaretDown} size={16} color="platform-gray-500" />}
        </div>
      </div>
    </div>
  );

  return (
    <Menu
      trigger={companyDropdown}
      menuItems={loading ? [loadingItem] : items}
      onSelect={(scenarioId) => navigate(getScenarioRedirectionURL(scenarioId))}
      onOpen={() => setMenuOpen(true)}
      onClose={() => setMenuOpen(false)}
      disabled={!multiScenarios}
      menuClassName={styles.menu}
      resetOnSelect
    />
  );
};
