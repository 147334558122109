import useAppSelector from 'js/hooks/useAppSelector';
import useNumberParams from 'js/hooks/useNumberParams';
import { getById as companySelector } from 'js/selectors/companies';
import { getById as scenarioSelector } from 'js/selectors/scenarios';

import BannerCarousel from './BannerCarousel';

interface DashboardBannerProps {
  show?: boolean;
}

const DashboardBanner = ({ show = true }: DashboardBannerProps) => {
  const { scenarioId } = useNumberParams();
  const scenario = useAppSelector((state) => scenarioSelector(state, scenarioId));
  const company = useAppSelector((state) => companySelector(state, scenario?.companyId));

  if (!show || !company?.id) return null;

  return <BannerCarousel company={company} scenarioId={scenarioId} />;
};

export default DashboardBanner;
