import { WarningCircle } from '@phosphor-icons/react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

import UpgradeLink from 'js/components/common/UpgradeLink';
import Icon from 'js/design-system/Icon';
import useAppSelector from 'js/hooks/useAppSelector';
import useFeatureAccess from 'js/hooks/useFeatureAccess';
import useSeatLimit from 'js/hooks/useSeatLimit';
import { getById as companySelector } from 'js/selectors/companies';
import { getById as scenarioSelector } from 'js/selectors/scenarios';
import { UpgradeEventSource } from 'types';

import styles from './OverLimitBanner.module.scss';

interface OverLimitScenarioBannerProps {
  scenarioId: number;
}

const OverLimitScenarioBanner = ({ scenarioId }: OverLimitScenarioBannerProps) => {
  const { pathname } = useLocation();
  const scenario = useAppSelector((state) => scenarioSelector(state, scenarioId));
  const company = useAppSelector((state) => companySelector(state, scenario?.companyId));
  const isRangesTab = pathname.includes('ranges');
  const { hasFeatureAccess } = useFeatureAccess();
  const hasUnlimitedEmployeesRanges = hasFeatureAccess('unlimited_employees_ranges');
  const hideOverLimitBanner = isRangesTab && hasUnlimitedEmployeesRanges;
  const { overLimit } = useSeatLimit(scenarioId);

  if (!scenario || hideOverLimitBanner) return null;

  if (company && overLimit) {
    return (
      <div className={styles.container}>
        <div className={classNames('container-xl', styles.inner)}>
          <Icon icon={WarningCircle} size={20} />
          <div>
            <span>
              You&lsquo;ve reached your limit of {company?.seatLimit}{' '}
              {company?.seatLimit === 1 ? 'employee' : 'employees'}.{' '}
            </span>
            <UpgradeLink
              message="Upgrade"
              className={styles.upgradeLink}
              eventSource={UpgradeEventSource.OverLimitScenarioBanner}
            />{' '}
            <span>to get detailed insights for all your employees</span>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default OverLimitScenarioBanner;
