import useAppSelector from 'js/hooks/useAppSelector';
import { currentUserSelector } from 'js/selectors/currentUser';
import {
  getByScenarioUserId as userScenarioPermissionSelector,
  getByCompanyUserId as userCompanyPermissionSelector,
} from 'js/selectors/permissions';
import { getById as scenarioSelector } from 'js/selectors/scenarios';
import { UserRole, UserType } from 'types';

import useNumberParams from './useNumberParams';

const useTotalRewardsAccess = () => {
  const { companyId, scenarioId } = useNumberParams();
  const user = useAppSelector(currentUserSelector);
  const scenario = useAppSelector((state) => scenarioSelector(state, scenarioId));
  const finalCompanyId = companyId || scenario?.companyId;

  const companyPermission = useAppSelector((state) =>
    finalCompanyId && user?.id
      ? userCompanyPermissionSelector(state, finalCompanyId, user.id)
      : null,
  );
  const scenarioPermission = useAppSelector((state) =>
    scenarioId && user?.id ? userScenarioPermissionSelector(state, scenarioId, user.id) : null,
  );
  const permission = companyPermission || scenarioPermission;

  if (!user) return { useAccessReady: false };

  const canManage =
    (permission &&
      [UserRole.Administrator, UserRole.OrganizationLeader].includes(permission.role)) ||
    user.userType === UserType.Analyst;
  const isRewardsViewer = permission && [UserRole.RewardsViewer].includes(permission.role);

  return {
    useAccessReady: true,
    canManage,
    canHaveFullNavbar: !isRewardsViewer,
    isRewardsViewer,
  };
};

export default useTotalRewardsAccess;
