import { useQueryClient, useMutation } from '@tanstack/react-query';

import { disableSpinner, enableSpinner } from 'js/actions/spinners';
import { createRange, updateRange } from 'js/api/ranges';
import { queryKeys as showUpdateAvailableQueryKeys } from 'js/api/showUpdateAvailable';
import { alertError } from 'js/components-legacy/common/AlertMessage';
import useAppDispatch from 'js/hooks/useAppDispatch';
import queries from 'js/queries';
import { PayRange, PayRangeStatus } from 'types';

export const useCreateRange = (scenarioId: number) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  return useMutation(async () => createRange(scenarioId), {
    onMutate: () => dispatch(enableSpinner()),
    onSettled: () => dispatch(disableSpinner()),
    onSuccess: () => queryClient.invalidateQueries(queries.payRanges._def),
    onError: (error) => alertError(error),
  });
};

export const useUpdateRange = (scenarioId: number) => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  return useMutation(
    ({
      id,
      attrs,
    }: {
      id: number;
      attrs: Partial<PayRange>;
      onSuccess?: () => void;
      onPreSuccess?: () => void;
    }) => updateRange(scenarioId, id, attrs),
    {
      onMutate: () => {
        dispatch(enableSpinner());
      },
      onSuccess: (payRange, variables) => {
        if (variables.onPreSuccess) variables.onPreSuccess();

        queryClient.setQueryData(
          queries.payRanges.oneByScenarioAndStatus(scenarioId, payRange.status).queryKey,
          payRange,
        );
        if (variables.attrs.status === PayRangeStatus.Active) {
          queryClient.setQueryData(
            queries.payRanges.oneByScenarioAndStatus(scenarioId, PayRangeStatus.Draft).queryKey,
            null,
          );
          queryClient.invalidateQueries(showUpdateAvailableQueryKeys.detail(scenarioId).queryKey);
        }

        if (variables.onSuccess) variables.onSuccess();
      },
      onError: (error) => {
        alertError(error);
      },
      onSettled: () => {
        dispatch(disableSpinner());
      },
    },
  );
};
