import { ElementType, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from 'js/design-system/Button/Button';
import { ButtonSize, ButtonVariant } from 'js/design-system/Button/types';
import useReactQueryCacheClear from 'js/hooks/useReactQueryCacheClear';
import { signOut } from 'js/services/auth/actions';

import EmptyState from './EmptyState';

import styles from './AccessDenied.module.scss';

interface Props {
  icon?: ElementType;
  heading?: string;
  subHeading?: string;
}
const AccessDenied: React.FunctionComponent<Props> = ({ icon, heading, subHeading }: Props) => {
  const clearQueryCache = useReactQueryCacheClear();
  const navigate = useNavigate();
  const handleSignOut = useCallback(() => {
    signOut(() => {
      clearQueryCache();
      navigate('/');
    });
  }, [clearQueryCache, navigate]);

  return (
    <>
      <EmptyState
        icon={icon}
        heading={heading ?? 'Nothing to see here'}
        subHeading={
          subHeading ??
          'Your account does not have access to any scenarios. Contact your admin to gain access.'
        }
      />
      <div className={styles.buttonContainer}>
        <Button
          variant={ButtonVariant.Outlined}
          size={ButtonSize.Medium}
          onClick={handleSignOut}
          className={styles.signOutButton}
        >
          Sign out
        </Button>
      </div>
    </>
  );
};

export default AccessDenied;
