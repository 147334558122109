import { useNavigate } from 'react-router-dom';

import Button from 'js/design-system/Button/Button';
import { ButtonSize, ButtonVariant } from 'js/design-system/Button/types';
import { CustomIcon } from 'js/design-system/Icon';
import Text from 'js/design-system/Text/Text';
import useNumberParams from 'js/hooks/useNumberParams';

import styles from './NoActiveRangeCta.module.scss';

export const NoActiveRangeCta = () => {
  const { scenarioId } = useNumberParams();
  const navigate = useNavigate();

  return (
    <div className={styles.noActiveRange}>
      <CustomIcon
        name="rangesIcon"
        size={72}
        color="platform-gray-400"
        colorApplication="stroke"
        className={styles.icon}
      />
      <Text variant="text-heading-h5">Create ranges to get started</Text>
      <Text variant="text-body-large" color="platform-gray-700" className={styles.subtitle}>
        To view ranges here, you&apos;ll first need to create ranges and define your pay strategy.
      </Text>
      <Button
        variant={ButtonVariant.Filled}
        size={ButtonSize.Medium}
        onClick={() => navigate(`/scenarios/${scenarioId}/ranges`)}
      >
        Create ranges
      </Button>
    </div>
  );
};
