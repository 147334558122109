import { equals } from 'ramda';
import { useEffect, useState } from 'react';
import usePlacesAutocomplete, { getDetails } from 'use-places-autocomplete';

import { Suggest, SuggestProps, Suggestion } from 'js/design-system/Form/Suggest/Suggest';
import { parseGoogleAddress } from 'js/utils/address';

import styles from './LocationSuggest.module.scss';

interface LocationSuggestProps
  extends Pick<
    SuggestProps<string>,
    | 'name'
    | 'label'
    | 'labelPosition'
    | 'required'
    | 'positionX'
    | 'positionY'
    | 'wrapperClassName'
    | 'defaultValue'
    | 'placeholder'
    | 'onToggle'
  > {
  onSelect: (place: { placeFormatted: string; countryCode: string }) => Promise<void>;
  // Adds the capability to insert suggestions that wouldn't be received from the endpoint.
  // For example, "Multiple locations" option on the benchmarking page.
  appendedOption?: Suggestion<string>;
  needsVerification?: boolean;
}

export const LocationSuggest = ({
  onSelect,
  appendedOption,
  needsVerification,
  ...fieldProps
}: LocationSuggestProps) => {
  const [suggestions, setSuggestions] = useState<Suggestion<string>[]>([]);

  const {
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    cache: 24 * 60 * 60, // cache for 24 hours
    requestOptions: {
      types: ['(regions)'],
    },
  });

  const renderLabel = (suggestion: google.maps.places.AutocompletePrediction) => {
    const main = suggestion.structured_formatting.main_text;
    const secondary = suggestion.structured_formatting.secondary_text;

    if (secondary) {
      return `${main}, ${secondary}`;
    }

    return main;
  };

  const onItemClick = (locationId: string) => {
    if (locationId === appendedOption?.value) {
      onSelect({ placeFormatted: locationId, countryCode: '' });
    } else {
      getDetails({
        placeId: locationId,
        fields: ['geometry', 'formatted_address', 'address_components'],
      }).then((details) => {
        if (typeof details === 'object') {
          const address = parseGoogleAddress(details.address_components);

          const place = {
            ...address,
            ...{
              lat: details.geometry?.location?.lat(),
              lng: details.geometry?.location?.lng(),
              placeFormatted:
                address.countryCode === 'US' && address.city && address.state
                  ? `${address.city}, ${address.state}`
                  : details.formatted_address || '',
              placeId: locationId.toString(),
            },
          };
          const { placeFormatted, countryCode } = place;
          onSelect({ placeFormatted, countryCode: countryCode || '' });
        } else {
          clearSuggestions();
        }
      });
    }
  };

  // render suggestions when new location data is available
  useEffect(() => {
    if (status === 'OK') {
      const newOptions = data.map((suggestion) => ({
        value: suggestion.place_id,
        label: renderLabel(suggestion),
        displayLabel: (
          <>
            <span className={styles.mainText}>{suggestion.structured_formatting.main_text}</span>
            <span className={styles.subText}>
              {suggestion.structured_formatting.secondary_text}
            </span>
          </>
        ),
      }));

      setSuggestions((curr) => {
        // Proxy compare autocomplete data to prevent unnecessary re-renders
        let allSuggestions = !equals(suggestions, newOptions) ? newOptions : curr;
        if (appendedOption) {
          allSuggestions = [appendedOption, ...allSuggestions];
        }
        return allSuggestions;
      });
    }
  }, [appendedOption, data, status, suggestions]);

  useEffect(() => {
    if (fieldProps.defaultValue && fieldProps.defaultValue !== '') {
      setValue(fieldProps.defaultValue);
    }
  }, [fieldProps.defaultValue, setValue]);

  return (
    <Suggest
      {...fieldProps}
      suggestions={suggestions}
      onType={(val) => setValue(String(val))}
      onChange={(val) => onItemClick(String(val))}
      forceSelection
      inputPrepend={needsVerification ? <span className={styles.verifyCircle} /> : null}
    />
  );
};
