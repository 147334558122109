import { useEffect, useMemo, useState } from 'react';

import { ActivityModules } from '../types';
import ActivityContext from './Context';

interface ActivityContextProviderProps {
  children: React.ReactNode;
  module: `${ActivityModules}`;
}

const ActivityContextProvider = ({ children, module }: ActivityContextProviderProps) => {
  const [activityTemplates, setActivityTemplates] = useState([]);

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const templates = await import(`../ActivityModules/${module}/templates`);
        setActivityTemplates(templates?.default);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(`Activity module "${module}" doesn't exists`);
      }
    };

    fetchTemplates();
  }, [module]);

  const value = useMemo(
    () => ({
      templates: activityTemplates,
    }),
    [activityTemplates],
  );

  return <ActivityContext.Provider value={value}>{children}</ActivityContext.Provider>;
};

export default ActivityContextProvider;
