import { formatError } from 'js/components-legacy/common/AlertMessage';
import { Benefit } from 'types';

import api from './api';

export const fetchBenefits = async (scenarioId: number) => {
  const { success, data, error } = await api.get<Benefit[]>(`/scenarios/${scenarioId}/benefits`);
  if (success) {
    return data;
  }

  throw new Error(formatError(error) || 'Failed to fetch benefits');
};
