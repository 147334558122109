import { useEffect, useRef } from 'react';

import { welcome } from 'js/actions/companies';
import { showConfetti } from 'js/actions/confetti';
import useAppDispatch from 'js/hooks/useAppDispatch';
import useAppSelector from 'js/hooks/useAppSelector';
import { WelcomeStatus } from 'js/reducers/companies';

import WelcomeModal from './WelcomeModal';

interface WelcomeNewUserProps {
  companyId?: number;
}

const WelcomeNewUser = ({ companyId }: WelcomeNewUserProps) => {
  const welcomeStatus = useAppSelector((state) => state.companies.welcomeStatus);
  const welcomeStatusRef = useRef(welcomeStatus);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(welcome({ welcomeStatus: WelcomeStatus.Idle }));
  }, [dispatch]);

  useEffect(() => {
    if (welcomeStatusRef.current === WelcomeStatus.Welcoming) {
      dispatch(showConfetti());
    }
  }, [dispatch]);

  if (welcomeStatusRef.current !== WelcomeStatus.Welcoming) return null;

  return <WelcomeModal companyId={companyId} />;
};

export default WelcomeNewUser;
