import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { To, UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

const useConfirmNavigator = () => {
  const { navigator } = useContext(NavigationContext);
  const [showConfirm, setShowConfirm] = useState(false);
  const bypassRef = useRef(false);
  const to = useRef<To>();
  const isBack = useRef<boolean>();

  useEffect(() => {
    const { push } = navigator;
    navigator.push = (...args: Parameters<typeof push>) => {
      if (bypassRef.current) {
        push(...args);
        return;
      }
      to.current = args[0];
      isBack.current = undefined;
      setShowConfirm(true);
    };

    window.onpopstate = () => {
      if (bypassRef.current) {
        return;
      }
      setShowConfirm(true);
      isBack.current = true;
      to.current = undefined;
      window.history.pushState(null, document.title, null);
    };

    window.onbeforeunload = (e) => {
      if (bypassRef.current) {
        return null;
      }
      e.preventDefault();
      return e;
    };

    return () => {
      window.onbeforeunload = null;
      window.onpopstate = null;
      navigator.push = push;
    };
  }, [navigator, showConfirm]);

  const setBypassBlock = useCallback((bypass: boolean) => {
    if (!bypass) {
      window.history.pushState(null, document.title, null);
    }
    bypassRef.current = bypass;
  }, []);

  const continueNavigate = useCallback(
    (state = {}) => {
      if (isBack.current) {
        window.history.go(-2);
        return;
      }
      if (to.current) {
        bypassRef.current = true;
        navigator.push(to.current, state);
      }
      setShowConfirm(false);
    },
    [navigator],
  );

  const cancelNavigate = useCallback(() => {
    setShowConfirm(false);
  }, []);

  const unblockFunction = useCallback(() => {
    bypassRef.current = true;
  }, []);

  return {
    continueNavigate,
    cancelNavigate,
    showConfirm,
    unblockFunction,
    setBypassBlock,
  };
};

export default useConfirmNavigator;
