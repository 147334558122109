import classNames from 'classnames';
import { ReactNode } from 'react';

import Label from 'js/design-system/Form/Label';

import styles from './FieldContainer.module.scss';

export interface FieldContainerProps {
  children: ReactNode;
  name?: string;
  label?: ReactNode;
  subLabel?: ReactNode;
  labelPosition?: 'left' | 'top' | 'right';
  className?: string;
  labelClassName?: string;
  required?: boolean;
}

const FieldContainer = ({
  children,
  name,
  label,
  subLabel,
  labelPosition = 'top',
  className,
  labelClassName,
  required = false,
}: FieldContainerProps) => (
  <div
    className={classNames(styles.inputContainer, className, {
      [styles.leftLabel]: labelPosition === 'left',
      [styles.rightLabel]: labelPosition === 'right',
    })}
  >
    {!!label && (
      <Label
        label={label}
        subLabel={subLabel}
        htmlFor={name}
        className={classNames(styles.label, labelClassName)}
        required={required}
      />
    )}
    {children}
  </div>
);

export default FieldContainer;
