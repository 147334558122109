import api from './api';

interface PayrollProvider {
  id: number;
  name: string;
  logo: string;
  integrationProxyName: string;
  providerKey: string;
  connectionStatus: 'active' | 'disconnected' | null;
}

export const fetchPayrollProviders = async (companyId: number, name?: string) => {
  const { success, data } = await api.get<PayrollProvider[]>('/payroll_providers', {
    urlParams: {
      companyId,
      search: { name },
    },
  });

  if (success) {
    return data;
  }

  throw new Error('Failed to fetch payroll providers');
};
