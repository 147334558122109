import { useFormState } from 'react-final-form';

import ConnectedField, { ConnectedFieldProps } from 'js/design-system/Form/ConnectedField';
import { RadioFieldProps } from 'js/design-system/Form/RadioButton/types';

import RadioField from './RadioField';

const ConnectedRadioField = ({ options, ...props }: ConnectedFieldProps<RadioFieldProps>) => {
  const { values } = useFormState();
  const selectedValue = values[props.name]?.value;
  const processedOptions = options?.map((option) => {
    return {
      ...option,
      selected: option.value === selectedValue,
    };
  });

  return (
    <ConnectedField
      fieldType="radio"
      renderField={(fieldProps: RadioFieldProps) => (
        <RadioField {...fieldProps} options={processedOptions} />
      )}
      {...props}
    />
  );
};

export default ConnectedRadioField;
