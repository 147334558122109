import { keys } from 'types';

export const filterSortOptionsWithEquity = <T extends string>(
  sortOptions: Partial<Record<T, string>>,
  showEquity: boolean,
) =>
  showEquity
    ? sortOptions
    : keys(sortOptions).reduce((acc: typeof sortOptions, key: T) => {
        if (!key.includes('equity')) {
          acc[key] = sortOptions[key];
        }
        return acc;
      }, {} as typeof sortOptions);
