import google from 'use-places-autocomplete';

import { Address } from 'types';

const isStreetNumber = (component: google.maps.GeocoderAddressComponent) =>
  component.types.includes('street_number');
const isStreetName = (component: google.maps.GeocoderAddressComponent) =>
  component.types.includes('route');
const isCity = (component: google.maps.GeocoderAddressComponent) =>
  component.types.includes('locality') || component.types.includes('sublocality');
const isState = (component: google.maps.GeocoderAddressComponent) =>
  component.types.includes('administrative_area_level_1');
const isCountry = (component: google.maps.GeocoderAddressComponent) =>
  component.types.includes('country');
const isPostalCode = (component: google.maps.GeocoderAddressComponent) =>
  component.types.includes('postal_code');

const parseGoogleAddress = (components?: google.maps.GeocoderAddressComponent[]) => {
  const address: Partial<Address> = {};

  if (!Array.isArray(components)) {
    throw Error('Address Components is not an array');
  }

  if (!components.length) {
    throw Error('Address Components is empty');
  }

  for (let i = 0; i < components.length; i++) {
    const component = components[i];

    if (isStreetNumber(component)) {
      address.street_number = component.long_name;
    }

    if (isStreetName(component)) {
      address.street_name = component.long_name;
    }

    if (isCity(component)) {
      address.city = component.long_name;
    }

    if (isCountry(component)) {
      address.countryCode = component.short_name;
    }

    if (isState(component)) {
      address.state = component.short_name;
    }

    if (isPostalCode(component)) {
      address.postalCode = component.long_name;
    }
  }

  return address;
};

export { parseGoogleAddress };
