import Avatar from 'js/design-system/Avatar';
import useAmplifyStorage from 'js/hooks/useAmplifyStorage';
import { UserPermission } from 'types';

import { AvatarProps } from './types';

interface UserAvatarProps
  extends Pick<
    AvatarProps,
    'variant' | 'colorIndex' | 'tooltip' | 'className' | 'wrapperClassName'
  > {
  userPermission: Pick<UserPermission, 'avatar' | 'fullName' | 'id'>;
}

const UserAvatar = ({ userPermission, ...props }: UserAvatarProps) => {
  const avatarSrc = useAmplifyStorage(userPermission?.avatar);
  return (
    <Avatar
      avatarSrc={avatarSrc}
      name={userPermission?.fullName}
      tooltip={userPermission?.fullName}
      {...props}
    />
  );
};

export default UserAvatar;
