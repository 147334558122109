import { CognitoHostedUIIdentityProvider, Auth } from '@aws-amplify/auth';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { trackEvent } from 'js/analytics';

/**
 * This route initiates the oauth flow with the given identity provider (eg Rippling OIDC)
 * This flow looks like:
 * - User is redirected to Cognito authorize URL
 * - Cognito redirects to identity provider auth URL
 * - Identity provider redirects back to Cognito idpresponse URL
 * - Cognito redirects to oauth sign in callback URL
 */
export const OauthInitiate = () => {
  const { identityProvider } = useParams();

  useEffect(() => {
    if (!identityProvider) return;

    trackEvent('oauth.initiate', {
      identityProvider,
      skipCompany: true,
    });
    if (identityProvider === CognitoHostedUIIdentityProvider.Google) {
      Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
    } else {
      Auth.federatedSignIn({ customProvider: identityProvider });
    }
  });

  return null;
};
