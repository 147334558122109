import { createQueryKeys } from '@lukemorales/query-key-factory';

import { fetchEmployeeSegments } from 'js/api/employees';

const employees = createQueryKeys('employees', {
  detail: (id: number) => ({
    queryKey: [id],
    contextQueries: {
      segments: {
        queryKey: null,
        queryFn: () => fetchEmployeeSegments(id),
      },
    },
  }),
});

export default employees;
