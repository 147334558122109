import { AppDispatch, ApiOnSuccess, ApiOnFailure, User, PromoUtmParams, FetchStatus } from 'types';

import api from '../api/api';
import ACTIONS from '../constants/actions';
import { signOut } from '../services/auth/actions';

export const loadUser = () => async (dispatch: AppDispatch) => {
  dispatch({ type: ACTIONS.GET_USER_START });

  const { success, data: user } = await api.get<User>('/current_user');
  if (success) {
    dispatch({ type: ACTIONS.USER_LOADED, data: user });
  } else {
    signOut();
  }
};

export const updateUser =
  ({
    userData,
    promoUtmData,
    onSuccess,
    onFailure,
  }: {
    userData?: Partial<User>;
    promoUtmData?: PromoUtmParams;
    onSuccess?: ApiOnSuccess<User>;
    onFailure?: ApiOnFailure;
  }) =>
  async (dispatch: AppDispatch) => {
    const {
      success,
      data: user,
      error,
    } = await api.put<User>('/current_user', { params: { user: userData, ...promoUtmData } });
    if (success) {
      dispatch({ type: ACTIONS.USER_LOADED, data: user });
      dispatch({ type: ACTIONS.RESET_COMPANY_NOTIFICATIONS });
      if (onSuccess) onSuccess(user);
    } else if (onFailure) {
      onFailure(error || "Failed to update user's profile data");
    }
    return success;
  };

export const updateUserStatus =
  ({ indexStatus }: { indexStatus: FetchStatus }) =>
  (dispatch: AppDispatch) => {
    dispatch({ type: ACTIONS.USER_STATUS_UPDATED, data: { indexStatus } });
  };
