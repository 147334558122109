import { AnyAction } from 'redux';

import { Department, keys } from 'types';

import ACTIONS from '../constants/actions';

type DepartmentState = {
  allIds: number[];
  byId: Record<number, Department>;
};

const initialState: DepartmentState = {
  allIds: [],
  byId: {},
};

const addIdToList = (
  allIds: number[],
  byId: Record<number, Department>,
  department: Department,
) => {
  let start = 0;
  let end = allIds.length - 1;
  let index = end + 1;
  while (start <= end) {
    const midIdx = Math.floor((start + end) / 2);
    const midElement = byId[allIds[midIdx]];

    if (midElement.name.localeCompare(department.name) === 1) {
      index = midIdx;
      end = midIdx - 1;
    } else {
      start = midIdx + 1;
    }
  }
  return [...allIds.slice(0, index), department.id, ...allIds.slice(index)];
};

export default (
  state: DepartmentState = initialState,
  action: AnyAction = { type: null },
): DepartmentState => {
  switch (action.type) {
    case ACTIONS.CREATE_COMPANY:
    case ACTIONS.DELETE_COMPANY:
      return initialState;
    case ACTIONS.CREATE_DEPARTMENT: {
      const department = action.data;
      const allIds = addIdToList(state.allIds, state.byId, department);
      return { ...state, allIds, byId: { ...state.byId, [department.id]: department } };
    }
    case ACTIONS.DELETE_DEPARTMENT: {
      const byId = keys(state.byId).reduce(
        (acc: Record<number, Department>, departmentId: number) => {
          if (departmentId !== action.id) {
            acc[departmentId] = state.byId[departmentId];
          }
          return acc;
        },
        {},
      );
      return { ...state, allIds: state.allIds.filter((id) => id !== action.id), byId };
    }
    case ACTIONS.RECEIVE_DEPARTMENTS:
      return {
        ...state,
        allIds: action.data.map((datum: Department) => datum.id),
        byId: action.data.reduce((departments: Department[], department: Department) => {
          departments[department.id] = department;
          return departments;
        }, {}),
      };
    case ACTIONS.UPDATE_DEPARTMENT:
      return {
        ...state,
        // Remove id, then add back in the correct spot
        allIds: addIdToList(
          state.allIds.filter((id) => id !== action.data.id),
          state.byId,
          action.data,
        ),
        byId: { ...state.byId, [action.data.id]: action.data },
      };
    case ACTIONS.UPDATING_DEPARTMENT:
      return {
        ...state,
      };
    default:
      return state;
  }
};
