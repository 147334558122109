import classNames from 'classnames';

import Icon from 'js/design-system/Icon';
import { BaseTable, TableRow, TableCell } from 'js/design-system/Table';
import Text from 'js/design-system/Text/Text';
import { isEmpty } from 'js/utils/value';

import { LegendHoverProps } from './types';

import styles from './LegendHover.module.scss';

const LegendHover = ({
  header,
  headerIcon,
  subHeader,
  bodyData,
  helpText,
  className,
  withBorder,
}: LegendHoverProps) => {
  const headerSection = (
    <div className={styles.headerContainer}>
      <div className={styles.header}>
        {headerIcon && <Icon icon={headerIcon} color="platform-gray-500" size={20} />}
        {header}
      </div>
      {subHeader}
    </div>
  );

  const bodySection = bodyData.map(({ label, value, subValue, subValueColor, bullet }) => (
    <TableRow key={label} className={styles.tableRow}>
      {bullet && <TableCell className={styles.bulletCell}>{bullet}</TableCell>}
      <TableCell className={styles.labelCell}>
        <Text color="platform-gray-700" className={styles.label}>
          {label}
        </Text>
      </TableCell>
      <TableCell className={styles.valueCell} align="end">
        <Text variant="text-body-main-bold" color="platform-gray-700">
          {value}
        </Text>
      </TableCell>
      {!isEmpty(subValue) ? (
        <TableCell className={styles.subValueCell}>
          <Text variant="text-misc-caption" color={subValueColor || 'platform-gray-500'}>
            {subValue}
          </Text>
        </TableCell>
      ) : null}
    </TableRow>
  ));

  return (
    <div
      className={classNames(styles.tooltipContainer, className, {
        [styles.withBorder]: withBorder,
      })}
    >
      {headerSection}
      <BaseTable className={styles.dataTable}>{bodySection}</BaseTable>
      {helpText && (
        <Text variant="text-misc-help" color="platform-gray-500">
          {helpText}
        </Text>
      )}
    </div>
  );
};

export default LegendHover;
