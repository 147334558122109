const HIDDEN_EMPLOYEES_BIT = {
  future: 1,
  terminated: 2,
  international: 4,
  contractor: 8,
  partTime: 16,
  intern: 32,
  temp: 64,
  seasonal: 128,
  executive: 256,
};

const DEFAULT_HIDDEN_EMPLOYEES_BIT =
  HIDDEN_EMPLOYEES_BIT.future |
  HIDDEN_EMPLOYEES_BIT.terminated |
  HIDDEN_EMPLOYEES_BIT.contractor |
  HIDDEN_EMPLOYEES_BIT.partTime |
  HIDDEN_EMPLOYEES_BIT.intern |
  HIDDEN_EMPLOYEES_BIT.temp |
  HIDDEN_EMPLOYEES_BIT.seasonal;

const normalizeHiddenEmployeesBit = (hiddenEmployeesBit) =>
  Object.keys(HIDDEN_EMPLOYEES_BIT).reduce((types, hiddenType) => {
    if (
      (HIDDEN_EMPLOYEES_BIT[hiddenType] & hiddenEmployeesBit) ===
      HIDDEN_EMPLOYEES_BIT[hiddenType]
    ) {
      types.push(hiddenType);
    }
    return types;
  }, []);

const denormalizeHiddenEmployeesBit = (hiddenEmployeeTypes) =>
  hiddenEmployeeTypes.reduce((value, hiddenType) => value | HIDDEN_EMPLOYEES_BIT[hiddenType], 0);

const hasCashConnection = (scenario) =>
  scenario.connection === 'cash' || scenario.connection === 'both';

const hasEquityConnection = (scenario) =>
  scenario.connection === 'equity' || scenario.connection === 'both';

const hasEnabledBenefits = (scenario) => {
  const { benefits } = scenario || {};
  const enabledBenefits = benefits?.filter((benefit) => benefit.enabled) ?? [];
  return enabledBenefits.length > 0;
};

const SYNCED_CONNECTION_TEXT = 'Some of this employee’s information is provided by your HRIS.';

const SYNCED_CONNECTION_WITH_CREATING_SCENARIO_TEXT =
  'Some of this employee’s information is provided by your HRIS. Create a new scenario to make changes.';

export {
  hasCashConnection,
  hasEquityConnection,
  normalizeHiddenEmployeesBit,
  denormalizeHiddenEmployeesBit,
  hasEnabledBenefits,
  HIDDEN_EMPLOYEES_BIT,
  DEFAULT_HIDDEN_EMPLOYEES_BIT,
  SYNCED_CONNECTION_TEXT,
  SYNCED_CONNECTION_WITH_CREATING_SCENARIO_TEXT,
};
