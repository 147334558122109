import { parseISO, getTime, toDate, isFuture } from 'date-fns';

export type DateValue = string | number | Date | null | undefined;

export const parseDate = (date: DateValue) => {
  switch (typeof date) {
    case 'string':
      return parseISO(date);
    case 'number':
      return toDate(date);
    default:
      return date;
  }
};

export const isFutureDate = (date: DateValue) => {
  const dateObj = parseDate(date);
  if (!dateObj) return null;

  return isFuture(dateObj);
};

export const getTimestamp = (date: DateValue) => {
  const dateObj = parseDate(date);
  if (!dateObj) return null;

  return getTime(dateObj);
};

export const getTimezoneAbbreviation = (timezoneIdentifier: string) => {
  // Get a formatter function for the given timezone.
  const dateTimeFormat = new Intl.DateTimeFormat(undefined, {
    timeZone: timezoneIdentifier,
    timeZoneName: 'short',
  });

  // Use the formatter on the current ISO time. This yields the proper abbreviation.
  const abbr = dateTimeFormat
    .formatToParts(new Date())
    .find((part) => part.type === 'timeZoneName')?.value;

  return abbr || null;
};
