import EditEmployeeBanner from 'js/components-legacy/employee/employee-entry/EditEmployeeBanner';
import useAppSelector from 'js/hooks/useAppSelector';
import { BannerPlace } from 'types';

interface OverLimitScenarioBannerProps {
  bannerPlace: BannerPlace;
}

const VerifiedEmployeeBanner = ({ bannerPlace }: OverLimitScenarioBannerProps) => {
  const verifiedBannerId = 'verifiedEmployee';
  const banner = useAppSelector((state) => state.banners?.byId[verifiedBannerId]) || {};
  const { message, animate, variant, verifyFields } = banner;

  if (!banner[bannerPlace]) return null;

  return (
    <EditEmployeeBanner
      message={message}
      verifyFields={verifyFields}
      animate={animate}
      variant={variant}
    />
  );
};

export default VerifiedEmployeeBanner;
