import { getIn } from 'final-form';
import { useFormState } from 'react-final-form';

import ConnectedField, { ConnectedFieldProps } from 'js/design-system/Form/ConnectedField';

import { Multiselect, MultiselectProps } from './Multiselect';

export const ConnectedMultiselect = <T extends number | string>({
  options,
  ...rest
}: ConnectedFieldProps<MultiselectProps<T>>) => {
  const { values: formValues } = useFormState();
  const formValue = getIn(formValues, rest.name);
  const connectedOptions = options.map((option) => ({
    ...option,
    default: formValue?.includes(option.value),
  }));

  return (
    <ConnectedField
      fieldType="select"
      renderField={(fieldProps) => (
        <Multiselect {...fieldProps} options={rest.value ? options : connectedOptions} />
      )}
      {...rest}
    />
  );
};
