import { batch } from 'react-redux';

import { trackEvent } from 'js/analytics';
import { ShareInviteUserEventDetails, ShareRevokeUserEventDetails } from 'js/analytics/types/share';
import { alertError, alertInfo } from 'js/components-legacy/common/AlertMessage';
import { WelcomeStatus } from 'js/reducers/companies';
import { readStatus } from 'js/selectors/companies';
import {
  AppDispatch,
  Company,
  InviteStatus,
  FetchStatus,
  GetState,
  UserRole,
  UserPermission,
  DeleteCompanyResponse,
} from 'types';

import api from '../api/api';
import ACTIONS from '../constants/actions';

export const create = (data: Company) => ({
  type: ACTIONS.CREATE_COMPANY,
  data,
});
interface DeleteCompanyData extends DeleteCompanyResponse {
  companyId: number;
}

export const destroy = (data: DeleteCompanyData) => (dispatch: AppDispatch) => {
  dispatch({ type: ACTIONS.DELETE_COMPANY, data });
};

export const fetchOne =
  (id: number, forced = false) =>
  async (dispatch: AppDispatch, getState: GetState) => {
    const fetched = readStatus(getState(), id) === FetchStatus.Succeeded;

    if (!forced && (!id || fetched)) {
      return;
    }
    const { success, data, error } = await api.get<Company>(`/companies/${id}`);
    if (success) {
      batch(() => {
        dispatch({
          type: ACTIONS.RECEIVE_COMPANY,
          data,
        });
      });
    } else {
      dispatch({
        type: ACTIONS.FAILED_COMPANY_FETCH,
        error,
      });
      alertError(error || 'Company not found');
    }
  };

export const forcedFetchOne = (id: number) => fetchOne(id, true);

export const invite =
  ({
    email,
    companyId,
    role,
    scenarioIds,
    employeesUsers,
  }: {
    email: string;
    companyId: number;
    role: UserRole;
    scenarioIds: number[];
    employeesUsers: {
      scenarioId?: number;
      employeeId?: number;
    }[];
  }) =>
  async (dispatch: AppDispatch) => {
    const { success, data, error } = await api.post<{
      user: UserPermission;
      scenarioIds: number[];
    }>(`/companies/${companyId}/users`, {
      params: {
        email,
        role,
        scenarioIds,
        employeesUsers,
      },
    });

    if (success) {
      trackEvent<ShareInviteUserEventDetails>('share.invite.user', { companyId });

      // await will un-batch dispatch updates
      await dispatch({
        type: ACTIONS.INVITE_USER_TO_COMPANY,
        data: {
          user: data.user,
        },
      });
      dispatch({
        type: ACTIONS.UPDATE_USERS_ACCESSIBLE_SCENARIOS,
        data: {
          scenarioIds: data.scenarioIds,
          user: data.user,
        },
      });

      alertInfo(
        data.user.pending ? `Invitation sent to ${email}` : 'User permissions updated successfully',
      );
    } else {
      alertError(error || 'Failed to invite user');
    }

    dispatch({
      type: ACTIONS.UPDATE_INVITE_STATUS,
      data: InviteStatus.Idle,
    });
  };

export const revoke =
  (companyId: number, user: UserPermission, onSuccess = () => {}) =>
  async (dispatch: AppDispatch) => {
    const url = user.pending
      ? `/companies/${companyId}/invitations/${user.id}`
      : `/companies/${companyId}/permissions/${user.id}`;

    const { success, data, error } = await api.delete<{ user: UserPermission }>(url);

    if (success) {
      trackEvent<ShareRevokeUserEventDetails>('share.revoke.user', { companyId });

      batch(() => {
        dispatch({
          type: ACTIONS.REVOKE_USER_FROM_COMPANY,
          data: {
            user: data.user,
          },
        });
        dispatch({
          type: ACTIONS.UPDATE_USERS_ACCESSIBLE_SCENARIOS,
          data: {
            scenarioIds: [],
            user: data.user,
          },
        });
      });

      onSuccess();
      alertInfo('User has been removed from company successfully');
    } else {
      alertError(error || 'Failed to remove user');
    }

    dispatch({ type: ACTIONS.RESET_INVITE_STATUS });
  };

export const update = (data: Partial<Company>) => (dispatch: AppDispatch) => {
  dispatch({ type: ACTIONS.RECEIVE_COMPANY, data });
};

export const welcome = ({ welcomeStatus }: { welcomeStatus: WelcomeStatus }) => ({
  data: { welcomeStatus },
  type: ACTIONS.WELCOME,
});
