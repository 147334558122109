import { UpgradeClickLinkEventDetails } from 'js/analytics/types/upgrade';
import useTrackEvent from 'js/analytics/useTrackEvent';
import api from 'js/api/api';
import Button from 'js/design-system/Button/Button';
import { ButtonSize, ButtonVariant } from 'js/design-system/Button/types';
import TextButton from 'js/design-system/TextButton/TextButton';
import useUpgrade from 'js/hooks/useUpgrade';
import { UpgradeEventSource } from 'types';

interface UpgradeLinkProps {
  message?: string;
  variant?: ButtonVariant | 'text';
  size?: ButtonSize;
  className?: string;
  eventSource: UpgradeEventSource;
}

const UpgradeLink = ({
  message = 'Upgrade Now',
  variant = 'text',
  size,
  className,
  eventSource,
}: UpgradeLinkProps) => {
  const { openUpgrade } = useUpgrade();
  const { trackEvent } = useTrackEvent<UpgradeClickLinkEventDetails>();

  const handleOnClick = () => {
    trackEvent('upgrade.click.link', { source: eventSource });
    api.post('upgrade_click_event', {
      params: { source: eventSource },
    });
    openUpgrade();
  };

  return variant === 'text' ? (
    <TextButton onClick={handleOnClick} className={className}>
      {message}
    </TextButton>
  ) : (
    <Button onClick={handleOnClick} variant={variant} size={size} className={className}>
      {message}
    </Button>
  );
};

export default UpgradeLink;
