import { useQueryClient } from '@tanstack/react-query';
import { memo } from 'react';

import { forcedFetchOne as fetchCompany } from 'js/actions/companies';
import { CompanyAction } from 'js/channels/types';
import useAppDispatch from 'js/hooks/useAppDispatch';
import queries from 'js/queries';
import { WebsocketsConsumer } from 'js/websockets';

const CompanyChannel = ({ id }: { id: number }) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const onReceived = ({ action }: { action: CompanyAction }) => {
    if (action === 'set_active_connected_company') {
      queryClient.invalidateQueries(queries.companies.detail(id)._ctx.connectedCompanies);
    }

    if (action === 'list_notifications') {
      queryClient.invalidateQueries(queries.companies.detail(id)._ctx.notifications);
    }

    if (action === 'update') {
      dispatch(fetchCompany(id));
    }
  };

  return <WebsocketsConsumer channel="CompanyChannel" id={id} onReceived={onReceived} />;
};

export default memo(CompanyChannel);
