import ConnectedField, { ConnectedFieldProps } from 'js/design-system/Form/ConnectedField';

import { Suggest, SuggestProps } from './Suggest';

export const ConnectedSuggest = <T extends number | string>({
  suggestions,
  onType,
  ...rest
}: ConnectedFieldProps<SuggestProps<T>>) => (
  <ConnectedField
    fieldType="select"
    renderField={(fieldProps) => (
      <Suggest {...fieldProps} suggestions={suggestions} onType={onType} />
    )}
    {...rest}
  />
);
