interface RoleDescriptionsLinkProps {
  text?: React.ReactNode;
  className?: string;
}

const RoleDescriptionsLink = ({
  text = 'Role Descriptions',
  className,
}: RoleDescriptionsLinkProps) => {
  return (
    <a
      href={`${process.env.REACT_APP_JOB_ROLE_DESCRIPTION_FILE_URL}`}
      target="_blank"
      rel="noreferrer"
      className={className}
    >
      {typeof text !== 'object' ? <span>{text}</span> : text}
    </a>
  );
};

export default RoleDescriptionsLink;
