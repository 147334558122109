import {
  FloatingPortal,
  autoUpdate,
  flip,
  safePolygon,
  useClick,
  useDismiss,
  useFloating,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import { CaretRight, MagnifyingGlass } from '@phosphor-icons/react';
import classNames from 'classnames';
import {
  ReactElement,
  ReactNode,
  cloneElement,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';

import buttonize from 'js/utils/buttonize';
import { GenericObject } from 'types';

import { TextField } from '../Form/TextField';
import Icon from '../Icon';
import { deepFilter, findDefaultItem, hasNested, isSectioned } from './helpers';

import styles from './Menu.module.scss';

export enum MenuPlacement {
  LeftStart = 'left-start',
  RightStart = 'right-start',
  BottomEnd = 'bottom-end',
  BottomStart = 'bottom-start',
}

export interface MenuItem<T> {
  value: T;
  label: string;
  displayLabel?: ReactNode;
  disabled?: boolean;
  default?: boolean;
  sticky?: boolean;
  nestedItems?: Array<MenuItem<T>>;
  data?: GenericObject;
}

export interface MenuProps<T> {
  trigger: ReactElement;
  menuItems: Array<MenuItem<T> | Array<MenuItem<T>>>;
  onSelect: (val: T, item?: MenuItem<T>) => void;
  onOpen?: () => void;
  onClose?: () => void;
  disabled?: boolean;
  placement?: `${MenuPlacement}`;
  filterable?: boolean;
  placeholder?: string;
  // A `Button` component that will be rendered at the bottom of the list.
  actionButton?: React.ReactElement;
  // clears the filter input and scrolls all options to the top
  resetOnSelect?: boolean;
  menuContainerClassName?: string;
  menuClassName?: string;
  loading?: boolean;
  // Depending on the context in which a Menu instance is used,
  // we may want to indicate to the user which item was selected.
  highlightSelectedItem?: boolean;
  // Some Menu instances require the first option/set of options to be "sticky". For example, `ScenarioMenu.tsx`.
  stickyFirstSection?: boolean;
  // By default, menus are opened by clicking on their trigger element. Use this to open the menu on hover, instead.
  openOnHover?: boolean;
}

const Menu = <T extends number | string>({
  trigger,
  menuItems,
  onSelect,
  onOpen,
  onClose,
  disabled = false,
  placement,
  filterable,
  placeholder = 'Filter',
  actionButton,
  resetOnSelect,
  menuContainerClassName,
  menuClassName,
  loading,
  highlightSelectedItem,
  stickyFirstSection,
  openOnHover = false,
}: MenuProps<T>) => {
  const filterBoxHeight = 72;

  const itemsContainerRef = useRef<HTMLDivElement>(null);

  const [selectedValue, setSelectedValue] = useState<T>();
  const [inputValue, setInputValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [filteredSections, setFilteredSections] = useState<Array<Array<MenuItem<T>>>>(
    isSectioned(menuItems) ? menuItems : [menuItems as Array<MenuItem<T>>],
  );

  const rightAligned = !!(placement && (placement.includes('right') || placement.includes('end')));

  // FLOATING UI
  const { floatingStyles, refs, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: placement || 'bottom-start',
    middleware: [flip()],
    whileElementsMounted: autoUpdate,
  });

  const hover = useHover(context, {
    enabled: openOnHover,
    move: false,
    handleClose: safePolygon(),
  });

  const click = useClick(context, { enabled: !disabled && !openOnHover });
  const dismiss = useDismiss(context, { bubbles: false });
  const role = useRole(context, { role: 'menu' });

  const { getReferenceProps, getFloatingProps } = useInteractions([hover, click, dismiss, role]);
  //

  const filter = useCallback(
    (val: string) => {
      const filtered = isSectioned(menuItems)
        ? menuItems
            .filter((section) => deepFilter(val, section).length > 0)
            .map((section) => deepFilter(val, section))
        : [deepFilter(val, menuItems as Array<MenuItem<T>>)];

      setFilteredSections(filtered);
    },
    [menuItems],
  );

  const onInputChange = (val: string) => {
    setInputValue(String(val));
    filter(String(val));
  };

  const onItemClick = (item: MenuItem<T>) => {
    if (!item.nestedItems) {
      setSelectedValue(item.value);
      onSelect(item.value, item);
      setIsOpen(false);
      setInputValue('');

      if (resetOnSelect) {
        itemsContainerRef.current?.scrollTo(0, 0);
      }
    }
  };

  const onActionButtonClick = (e: React.MouseEvent<HTMLElement>) => {
    setIsOpen(false);
    const upstreamClick = actionButton?.props.onClick;
    if (upstreamClick) upstreamClick(e);
  };

  const renderItem = (item: MenuItem<T>) => (
    <div
      key={String(item.value)}
      className={classNames(styles.item, {
        [styles.disabled]: item.disabled,
        [styles.selected]: highlightSelectedItem && selectedValue === item.value,
      })}
      {...buttonize(() => onItemClick(item))}
    >
      {item.displayLabel || item.label || item.value}
      {item.nestedItems && (
        <>
          <Icon icon={CaretRight} size={16} className={styles.nestedIcon} />
          <div
            className={classNames(styles.menu, styles.nested, styles.hasNested)}
            style={{ [rightAligned ? 'right' : 'left']: '100%' }}
          >
            {item.nestedItems.map(renderItem)}
          </div>
        </>
      )}
    </div>
  );

  useEffect(() => {
    filter('');
  }, [filter]);

  useEffect(() => {
    setSelectedValue(findDefaultItem(filteredSections.flat())?.value);
  }, [filteredSections]);

  useEffect(() => {
    const handler = isOpen ? onOpen : onClose;
    if (handler) handler();
  }, [isOpen, onOpen, onClose]);

  return (
    <div className={classNames(menuContainerClassName, styles.menuContainer)}>
      <div className={styles.triggerContainer} ref={refs.setReference} {...getReferenceProps()}>
        {trigger}
      </div>
      {isOpen && (
        <FloatingPortal>
          <div
            ref={refs.setFloating}
            className={classNames(menuClassName, styles.menu, styles.open, {
              [styles.filterable]: filterable,
              [styles.hasNested]: hasNested(menuItems),
            })}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            <div ref={itemsContainerRef} className={styles.itemsContainer}>
              {filterable && (
                <div
                  className={classNames(styles.filter, styles.sticky)}
                  style={{ height: filterBoxHeight }}
                >
                  <TextField
                    value={inputValue}
                    name="menu-filter-input"
                    onChange={(val) => onInputChange(String(val))}
                    placeholder={placeholder}
                    inputPrepend={
                      <Icon icon={MagnifyingGlass} size={16} color="primary-gray-600" />
                    }
                  />
                </div>
              )}

              {!filteredSections[0]?.length ? (
                <div className={classNames(styles.item, styles.disabled)}>
                  {loading ? 'Loading...' : 'No results'}
                </div>
              ) : (
                filteredSections.map((section, i) => (
                  <div
                    key={`${String(i)}`}
                    className={classNames({ [styles.sticky]: i === 0 && stickyFirstSection })}
                    style={{ top: filterable ? filterBoxHeight : 0 }}
                  >
                    {section.map(renderItem)}
                    {i < filteredSections.length - 1 && <div className={styles.divider} />}
                  </div>
                ))
              )}
            </div>

            {actionButton && (
              <>
                <div className={styles.divider} />
                <div className={styles.actionButton}>
                  {cloneElement(actionButton, {
                    onClick: onActionButtonClick,
                  })}
                </div>
              </>
            )}
          </div>
        </FloatingPortal>
      )}
    </div>
  );
};

export default Menu;
