import { createQueryKeys } from '@lukemorales/query-key-factory';
import { QueryFunction, QueryKey, UseQueryOptions, useQuery } from '@tanstack/react-query';

import { fetchGoals } from 'js/api/goals';
import { Goal } from 'types';

const goals = createQueryKeys('goals', {
  list: {
    queryKey: null,
    queryFn: fetchGoals,
  },
});

export const useGoals = (options?: UseQueryOptions<Goal[]>) => {
  return useQuery<Goal[]>(
    goals.list.queryKey,
    goals.list.queryFn as QueryFunction<Goal[], QueryKey>,
    options,
  );
};

export default goals;
