import { CURRENCY_CODES, CONFIG as CURRENCY_CONFIG } from 'js/config/currency';

import { isValidNumber, round } from '../numbers';

export type CurrencyCode = (typeof CURRENCY_CODES)[number];

export type ExtendedCurrencyCode = CurrencyCode | 'local';

interface FormatOptions
  extends Pick<
    Intl.NumberFormatOptions,
    'notation' | 'maximumFractionDigits' | 'minimumFractionDigits'
  > {
  currencyCode?: CurrencyCode | null;
  shortFormat?: boolean;
  decimalPlaces?: number;
  roundingFactor?: number;
}

export const getFormattedCurrency = (value: number, options: FormatOptions = {}) => {
  if (!isValidNumber(value)) {
    throw new Error(`Invalid number ${value}`);
  }
  // Merge default format options
  const { currency, decimalPlaces, shortFormat, roundingFactor } = {
    currency: options.currencyCode || CURRENCY_CONFIG.defaultCurrencyCode,
    decimalPlaces: options.decimalPlaces ?? CURRENCY_CONFIG.defaultDecimalPlaces,
    shortFormat: options.shortFormat || false,
    roundingFactor: options.roundingFactor ?? 0,
  };

  const roundedValue = round(value, { to: 10 ** roundingFactor });
  const isShortFormatAndLargeNumber =
    shortFormat && Math.abs(value) >= CURRENCY_CONFIG.defaultShortFormatThreshold;
  const maximumFractionDigits = isShortFormatAndLargeNumber ? 1 : decimalPlaces;
  const minimumFractionDigits = options.minimumFractionDigits ?? 0;
  const notation =
    options.notation || (shortFormat && Math.abs(value) > 1e4 ? 'compact' : 'standard');

  try {
    const formatted = new Intl.NumberFormat(CURRENCY_CONFIG.defaultLocale, {
      style: 'currency',
      currency,
      notation,
      minimumFractionDigits,
      maximumFractionDigits,
    }).format(roundedValue);

    // Replaces all `&nbsp;` characters with a regular space.
    return formatted.split(/\s+/).join(' ');
  } catch (err) {
    return String(roundedValue);
  }
};
