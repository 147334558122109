import {
  CashDisplayType,
  CompCycleSummarySortBy,
  CycleSortBy,
  EmployeeAdjustmentsProposalSortBy,
  EquityDisplayType,
  Ethnicity,
  Gender,
  MarketEmployeeSortBy,
  OfferSortBy,
  RangesEmployeeSortBy,
} from 'types';

export const EMPTY_EMPLOYEE_NAME = '<empty>';

export const GEO_CARD_OVERRIDES: Record<string, string[][]> = {
  'Tech Ex.': [['Technology', 'San Francisco, CA']],
  Premium: [
    ['San Francisco, CA', 'San Francisco, CA'],
    ['New York, NY', 'New York, NY'],
  ],
};

export const CASH_DISPLAY_TYPES_TO_TEXT_MAPPING = {
  [CashDisplayType.TotalCash]: 'Total Cash',
  [CashDisplayType.BaseSalary]: 'Base Salary',
  [CashDisplayType.SalaryOte]: 'Salary / OTE',
  [CashDisplayType.None]: undefined,
};

export const EQUITY_DISPLAY_TYPES_TO_TEXT_MAPPING: Partial<Record<EquityDisplayType, string>> = {
  [EquityDisplayType.NumOfShares]: 'Number of Shares',
  [EquityDisplayType.PercentOwnership]: 'Percent Ownership',
  [EquityDisplayType.None]: undefined,
};

export const GENDER_OPTIONS: Record<Gender, { name: string; color: string }> = {
  [Gender.Female]: { name: 'Female', color: '#6F42C1' },
  [Gender.Male]: { name: 'Male', color: '#337AB7' },
  [Gender.Nonconforming]: { name: 'Nonbinary', color: '#FD7E14' },
  [Gender.SomethingElse]: { name: 'Other', color: '#9f77a5' },
  [Gender.None]: { name: 'None Specified', color: '#D63384' },
};

export const ETHNICITY_SELECT_OPTIONS = {
  [Ethnicity.Asian]: {
    name: 'Asian',
    abbreviation: 'Asian',
    color: '#6610F2',
  },
  [Ethnicity.BlackOrAfricanAmerican]: {
    name: 'Black or African American',
    abbreviation: 'Black',
    color: '#337AB7',
  },
  [Ethnicity.HispanicOrLatino]: {
    name: 'Hispanic or Latino',
    abbreviation: 'Hispanic',
    color: '#D63384',
  },
  [Ethnicity.NativeAmericanOrAlaskaNative]: {
    name: 'Native American or Alaska Native',
    abbreviation: 'AI/AN',
    color: '#FD7E14',
  },
  [Ethnicity.NativeHawaiianOrPacificIslander]: {
    name: 'Native Hawaiian or Pacific Islander',
    abbreviation: 'NHPI',
    color: '#198754',
  },
  [Ethnicity.White]: {
    name: 'White',
    abbreviation: 'White',
    color: '#1DB2AA',
  },
  [Ethnicity.TwoOrMore]: {
    name: 'Two or more groups',
    abbreviation: '2+ Groups',
    color: '#C4C4C4',
  },
};

export const ETHNICITY_OPTIONS = {
  ...ETHNICITY_SELECT_OPTIONS,
  none: {
    name: 'None Specified',
    abbreviation: 'None',
    color: '#FFC107',
  },
};

export const fundingStages: {
  [key: string]: {
    [key: string]: string;
  };
} = {
  seed: { name: 'Seed', compKey: 'Seed Funded' },
  'series a': { name: 'Series A', compKey: 'Series A' },
  'series b': { name: 'Series B', compKey: 'Series B' },
  'series c': { name: 'Series C', compKey: 'Series C' },
  'series d': { name: 'Series D', compKey: 'Series D' },
  'series e': { name: 'Series E', compKey: 'Series E' },
};

export const urlSearchParams = new URLSearchParams(window.location.search);

export const CUSTOM_PAY_RANGE_POSITION_NAME = 'Custom target';

interface NavigationItem {
  name: string;
  url: string;
  label: string;
  new: boolean;
  beta?: boolean;
  activeTabRoutes?: string[];
  alignRight?: boolean;
}
export const navigationItems: {
  [key: string]: NavigationItem;
} = {
  launchpad: {
    name: 'launchpad',
    url: 'launchpad',
    label: 'Launchpad',
    new: false,
  },
  market: {
    name: 'market',
    url: 'market',
    label: 'Market',
    new: false,
  },
  ranges: {
    name: 'ranges',
    url: 'ranges',
    label: 'Ranges',
    new: false,
  },
  hiring: {
    name: 'hiring',
    url: 'hiring',
    label: 'Headcount',
    new: false,
  },
  diversity: {
    name: 'diversity',
    url: 'diversity',
    label: 'DEI',
    new: false,
  },
  offers: {
    name: 'offers',
    url: 'offers',
    label: 'Offers',
    new: false,
  },
  compCycles: {
    name: 'compCycles',
    url: 'comp-cycles',
    activeTabRoutes: ['employee-adjustment-proposals'],
    label: 'Cycles',
    new: false,
    beta: false,
  },
  totalRewards: {
    name: 'totalRewards',
    url: 'total-rewards',
    label: 'Total Rewards',
    new: false,
    alignRight: true,
  },
};

export const MARKET_SORT_OPTIONS = {
  [MarketEmployeeSortBy.Name]: 'Employee Name',
  [MarketEmployeeSortBy.JobTitle]: 'Job Title',
  [MarketEmployeeSortBy.Level]: 'Job Level',
  [MarketEmployeeSortBy.StartDate]: 'Start Date',
  [MarketEmployeeSortBy.Equity]: 'Equity',
  [MarketEmployeeSortBy.EquityPercentile]: 'Equity Percentile',
  [MarketEmployeeSortBy.Salary]: 'Salary',
  [MarketEmployeeSortBy.SalaryPercentile]: 'Salary Percentile',
  [MarketEmployeeSortBy.Cash]: 'Total Cash',
  [MarketEmployeeSortBy.CashPercentile]: 'Total Cash Percentile',
};

export const RANGE_SORT_OPTIONS = {
  [RangesEmployeeSortBy.Name]: 'Employee Name',
  [RangesEmployeeSortBy.JobTitle]: 'Job Title',
  [RangesEmployeeSortBy.Level]: 'Job Level',
  [RangesEmployeeSortBy.StartDate]: 'Start Date',
  [RangesEmployeeSortBy.Equity]: 'Equity',
  [RangesEmployeeSortBy.EquityRangePosition]: 'Equity Range Position',
  [RangesEmployeeSortBy.SalaryOte]: 'Salary/OTE',
  [RangesEmployeeSortBy.SalaryOteRangePosition]: 'Salary/OTE Range Position',
};

export const OFFER_SORT_OPTIONS: Record<OfferSortBy, string> = {
  [OfferSortBy.CandidateName]: 'Sort by Candidate Name',
  [OfferSortBy.JobTitle]: 'Sort by Job Title',
  [OfferSortBy.Level]: 'Sort by Level',
  [OfferSortBy.Cash]: 'Sort by Cash',
  [OfferSortBy.Equity]: 'Sort by Equity',
  [OfferSortBy.UpdatedAt]: 'Sort by Last Updated',
  [OfferSortBy.CreatedBy]: 'Sort by Creator',
  [OfferSortBy.Status]: 'Sort by Status',
};

export const COMP_CYCLE_SORT_OPTIONS: Record<CycleSortBy, string> = {
  [CycleSortBy.CycleName]: 'Sort by Cycle Name',
  [CycleSortBy.Status]: 'Sort by Status',
  [CycleSortBy.CreatedBy]: 'Sort by Creator',
  [CycleSortBy.EndDate]: 'Sort by End Date',
  [CycleSortBy.CashBudget]: 'Sort by Cash Budget',
  [CycleSortBy.EquityBudget]: 'Sort by Equity Budget',
};

export const COMP_CYCLE_SUMMARY_SORT_OPTIONS: Record<CompCycleSummarySortBy, string> = {
  [CompCycleSummarySortBy.BudgetHolderName]: 'Sort by Budget Holder Name',
  [CompCycleSummarySortBy.NumberOfEmployees]: 'Sort by Number of Employees',
  [CompCycleSummarySortBy.DateSubmitted]: 'Sort by Date Submitted',
  [CompCycleSummarySortBy.CashBudget]: 'Sort by Cash Budget',
  [CompCycleSummarySortBy.EquityBudget]: 'Sort by Equity Budget',
  [CompCycleSummarySortBy.Status]: 'Sort by Status',
};

export const MERIT_EMPLOYEE_ADJUSTMENT_PROPOSAL_SORT_OPTIONS = {
  [EmployeeAdjustmentsProposalSortBy.Level]: 'Sort by Level',
  [EmployeeAdjustmentsProposalSortBy.Tenure]: 'Sort by Tenure',
  [EmployeeAdjustmentsProposalSortBy.BaseSalary]: 'Sort by Base Salary',
  [EmployeeAdjustmentsProposalSortBy.BaseSalaryIncrease]: 'Sort by Base Salary Increase',
  [EmployeeAdjustmentsProposalSortBy.TotalCash]: 'Sort by Total Cash',
  [EmployeeAdjustmentsProposalSortBy.CashIncrease]: 'Sort by Cash Increase',
  [EmployeeAdjustmentsProposalSortBy.CashRangePosition]: 'Sort by Cash Range Position',
  [EmployeeAdjustmentsProposalSortBy.TotalEquity]: 'Sort by Total Equity',
  [EmployeeAdjustmentsProposalSortBy.EquityIncrease]: 'Sort by Equity Increase',
  [EmployeeAdjustmentsProposalSortBy.EquityRangePosition]: 'Sort by Equity Range Position',
};

export const BONUS_EMPLOYEE_ADJUSTMENT_PROPOSAL_SORT_OPTIONS = {
  [EmployeeAdjustmentsProposalSortBy.Level]: 'Sort by Level',
  [EmployeeAdjustmentsProposalSortBy.Tenure]: 'Sort by Tenure',
  [EmployeeAdjustmentsProposalSortBy.CashBonus]: 'Sort by Cash Bonus',
  [EmployeeAdjustmentsProposalSortBy.TargetAnnualBonus]: 'Sort by Target Annual Bonus',
  [EmployeeAdjustmentsProposalSortBy.TotalEquity]: 'Sort by Total Equity',
  [EmployeeAdjustmentsProposalSortBy.EquityRefresh]: 'Sort by Equity Refresh',
};
