import spacings from 'css/_spacings.scss';
import { Spacing } from 'types/designSystem';

interface SpacerProps {
  height: Spacing;
}

const Spacer = ({ height }: SpacerProps) => (
  <div
    style={{
      width: '100%',
      height: spacings[height],
    }}
  />
);

export default Spacer;
