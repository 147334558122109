import { Link } from 'react-router-dom';

import { setNavbarSelection } from 'js/actions/navbar';
import { ScenarioChangeViewEventDetails } from 'js/analytics/types/scenario';
import useTrackEvent from 'js/analytics/useTrackEvent';
import useOnboardMarketCompletion from 'js/components/onboard-cta/onboard-market/hooks/useOnboardMarketCompletion';
import useOnboardRangesAccess from 'js/components/onboard-cta/onboard-ranges/hooks/useOnboardRangesAccess';
import useGetRange from 'js/components/pay-strategy/hooks/useGetRange';
import { FeatureFlags } from 'js/config/feature-flags';
import { navigationItems } from 'js/config/globals-ts';
import useAppDispatch from 'js/hooks/useAppDispatch';
import useAppSelector from 'js/hooks/useAppSelector';
import useFeatureFlag from 'js/hooks/useFeatureFlag';
import useNumberParams from 'js/hooks/useNumberParams';
import { getByScenarioId as companyByScenarioIdSelector } from 'js/selectors/companies';
import { currentUserRoleSelector } from 'js/selectors/currentUser';
import scenarioViewSelector from 'js/selectors/scenarioView';
import { getById as scenarioSelector } from 'js/selectors/scenarios';
import { UserRole } from 'types';

import NavigationTab from './NavigationTab/NavigationTab';

import styles from './NavigationMenu.module.scss';

export interface ViewModeItemType {
  name: string;
  url: string;
  label: string;
  new: boolean;
  beta?: boolean;
  alignRight?: boolean;
  featured?: boolean;
}

/* If the user type is not in `visibleTabsByRole`, all tabs will be visible */
const visibleTabsByRole: Partial<Record<UserRole, string[]>> = {
  [UserRole.Approver]: ['offers', 'compCycles'],
  [UserRole.Recruiter]: ['offers', 'compCycles', 'totalRewards'],
};

const NavigationMenu = () => {
  const { mode: currentViewMode } = useAppSelector(scenarioViewSelector);
  const { scenarioId } = useNumberParams();
  const scenario = useAppSelector((state) => scenarioSelector(state, scenarioId));
  const userRole = useAppSelector((state) => currentUserRoleSelector(state, scenario?.companyId));
  const dispatch = useAppDispatch();
  const { hasRange: hasActiveRange } = useGetRange();
  const company = useAppSelector((state) => companyByScenarioIdSelector(state, scenarioId));
  const { trackEvent } = useTrackEvent<ScenarioChangeViewEventDetails>();
  const { enabled: launchpadEnabled } = useFeatureFlag(FeatureFlags.launchpad, undefined);

  const shouldShowWarning = (viewModeName: string) =>
    viewModeName === 'ranges' && hasActiveRange && !company?.paid;

  const { showOnboardRanges } = useOnboardRangesAccess();
  const { showOnboardMarket } = useOnboardMarketCompletion();
  const viewHasBanner = (viewModeName: string) =>
    (viewModeName === 'ranges' && showOnboardRanges) ||
    (viewModeName === 'market' && showOnboardMarket);

  const handleNavbarClick = (viewMode: ViewModeItemType) => {
    dispatch(setNavbarSelection(viewMode.url));

    trackEvent('scenario.change.view', {
      scenarioId,
      view: viewMode.name,
    });
  };

  const generateLink = (viewMode: ViewModeItemType) => (
    <Link
      to={viewMode.url}
      key={`view-tab-${viewMode.name}`}
      onClick={() => handleNavbarClick(viewMode)}
    >
      <NavigationTab
        name={viewMode.name}
        label={viewMode.label}
        selected={currentViewMode === viewMode.name}
        isNew={viewMode.new}
        beta={viewMode.beta}
        upgrade={shouldShowWarning(viewMode.name)}
        hasBanner={viewHasBanner(viewMode.name)}
        featured={viewMode.featured}
      />
    </Link>
  );

  if (!userRole) return null;

  const items = Object.entries(navigationItems).reduce(
    (acc: Record<string, ViewModeItemType>, [key, value]) => {
      if (key === 'launchpad' && !launchpadEnabled) {
        return acc;
      }
      if (!visibleTabsByRole[userRole] || visibleTabsByRole[userRole]?.includes(key))
        acc[key] = value;
      return acc;
    },
    {},
  );

  return (
    <>
      <div className={styles.navigation}>
        {Object.values(items)
          .filter((viewMode) => !viewMode.alignRight)
          .map((viewMode) => generateLink(viewMode))}
      </div>
      <div className={styles.navigation}>
        {Object.values(items)
          .filter((viewMode) => viewMode.alignRight)
          .map((viewMode) => generateLink(viewMode))}
      </div>
    </>
  );
};

export default NavigationMenu;
