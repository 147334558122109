import { AppDispatch, UserPermission } from 'types';

import ACTIONS from '../constants/actions';

export const fetching = () => (dispatch: AppDispatch) => {
  dispatch({ type: ACTIONS.FETCHING_COMPANY_USERS });
};

export const receiveAll =
  (data: UserPermission[], companyId: number) => (dispatch: AppDispatch) => {
    dispatch({
      type: ACTIONS.RECEIVE_COMPANY_USERS,
      data: {
        users: data,
        companyId,
      },
    });
  };
