import Text from 'js/design-system/Text/Text';

import { ErrorTextProps } from './types';

import styles from './ErrorText.module.scss';

const ErrorText = ({ validation }: ErrorTextProps) => {
  if (!validation?.text) return null;

  return (
    <Text variant="text-misc-help" color="system-red-600" className={styles.errorText}>
      {validation?.text}
    </Text>
  );
};

export default ErrorText;
