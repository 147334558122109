import { Minus, Plus } from '@phosphor-icons/react';
import classNames from 'classnames';
import { path } from 'ramda';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-final-form';
import NumericInput from 'react-number-format';

import { NumberInputProps } from 'js/components-legacy/form/NumberInput';
import { IconButtonVariant } from 'js/design-system/Button/types';
import IconButton from 'js/design-system/IconButton/IconButton';

import Field from './Field';
import ValidationWrapper from './ValidationWrapper';

import styles from './PercentileInput.module.scss';

const STEP = 5;

interface PercentileInputProps extends NumberInputProps {
  stateValidation?: 'dirty' | 'modified';
  autoSave?: () => void;
  onChangePosition?: () => void;
}

const PercentileInput = ({
  name,
  disabled = false,
  min = Number.MIN_SAFE_INTEGER,
  max = Number.MAX_SAFE_INTEGER,
  maxLength,
  placeholder,
  precision,
  fixedDecimalScale = true,
  allowNegative = false,
  format,
  parse,
  size,
  prefix = '',
  suffix = '',
  stateValidation = 'dirty',
  autoSave,
  onChangePosition,
  ...rest
}: PercentileInputProps) => {
  const form = useForm();
  const nameParts = name.replace(/\]$/, '').split(/[.[\]]+/);
  const getCurrentValue = () => Number(path(nameParts, form.getState().values));

  const savePercentile = (newValue: number) => {
    form.change(name, newValue);
    if (autoSave && !disabled) autoSave();
    if (onChangePosition && !disabled) onChangePosition();
  };

  const increasePercentile = () => {
    const currentValue = getCurrentValue();
    savePercentile(Math.min(currentValue + STEP, max));
  };

  const decreasePercentile = () => {
    const currentValue = getCurrentValue();
    savePercentile(Math.max(currentValue - STEP, min));
  };

  return (
    <Field name={name} {...rest}>
      {({ invalid, onChange, meta, className, ...input }) => (
        <>
          <ValidationWrapper invalid={invalid} className={styles.percentileInputField}>
            <IconButton
              icon={Minus}
              onClick={decreasePercentile}
              disabled={disabled}
              variant={IconButtonVariant.Default}
              data-testid={`decrement-${name}`}
              className={styles.button}
            />
            <Form.Control
              as={NumericInput}
              {...input}
              className={classNames({ [styles.dirty]: meta[stateValidation] }, className)}
              isInvalid={invalid}
              format={format}
              removeFormatting={parse}
              decimalScale={precision}
              fixedDecimalScale={fixedDecimalScale}
              placeholder={placeholder}
              allowNegative={allowNegative}
              allowEmptyFormatting={false}
              prefix={prefix}
              suffix={suffix}
              size={size}
              max={max}
              min={min}
              disabled
              plaintext
            />
            <IconButton
              icon={Plus}
              variant={IconButtonVariant.Default}
              onClick={increasePercentile}
              disabled={disabled}
              data-testid={`increment-${name}`}
              className={styles.button}
            />
          </ValidationWrapper>
        </>
      )}
    </Field>
  );
};

export default PercentileInput;
