import classnames from 'classnames';
import { useState, useEffect } from 'react';
import { Field, useFormState, useForm, FieldInputProps } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';

import { fetchJobRole } from 'js/api/jobRoles';
import { Form } from 'js/components-legacy/form';
import { SuggestJobRole } from 'js/components/common/job-roles/types';
import { getLevelOptions } from 'js/components/common/level/utils';
import GLOBALS from 'js/config/globals';
import Button from 'js/design-system/Button/Button';
import { ButtonSize, ButtonVariant } from 'js/design-system/Button/types';
import { Select, SelectOption } from 'js/design-system/Form/Select/Select';
import Text from 'js/design-system/Text/Text';
import Tooltip from 'js/design-system/Tooltip/Tooltip';
import useNumberParams from 'js/hooks/useNumberParams';
import { useJobLevels } from 'js/queries/jobLevels';
import { isValidJobLevelId } from 'js/services/levelService';

import { JobRoleInput } from './JobRoleInput';
import { LocationNameInput } from './LocationNameInput';

import styles from './BulkEditDrawer.module.scss';

const MULTIPLE_LEVELS = 'Multiple levels';
export const MULTIPLE_LOCATIONS = 'Multiple locations';
export const MULTIPLE_ROLES = 'Multiple roles';

enum BulkEditState {
  Actions = 0,
  Edit = 1,
  Confirm = 2,
}

export type BulkEditForm = {
  jobRole?: SelectOption<number>;
  jobRoleId?: number;
  jobLevelId?: number | null;
  locationName?: string;
};

interface MultiStatesBulkEditDrawerProps {
  benchmarkId: number;
  selected: Set<string>;
  setSelected: React.Dispatch<React.SetStateAction<Set<string>>>;
  onChangeAll: (values: BulkEditForm) => Promise<void>;
  onConfirmAll: (values: BulkEditForm) => Promise<void>;
  initialJobLevelId?: number | null;
  initialLocationName?: string;
  initialJobRole?: SuggestJobRole;
  className?: string;
}
interface BulkActionsDrawerProps {
  selected: Set<string>;
  setSelected: React.Dispatch<React.SetStateAction<Set<string>>>;
  onConfirmAll: (values: BulkEditForm) => Promise<void>;
  onClickEdit: () => void;
  className?: string;
}

const BulkLevelField = () => {
  const form = useForm();
  const { values: formValues } = useFormState();
  const { jobRole, jobLevelId: currentJobLevelId } = formValues;
  const jobTypeId = jobRole?.data?.jobType?.id;
  const validLevels =
    jobRole && jobRole.label !== MULTIPLE_ROLES ? jobRole.data.validLevels : GLOBALS.allLevels;
  const { scenarioId } = useNumberParams();

  const { data: jobLevels, isSuccess: isJobLevelsFetched } = useJobLevels(
    scenarioId,
    jobRole?.data || { validLevels },
  );

  const multipleOption = {
    label: MULTIPLE_LEVELS,
    value: -1,
    default: !currentJobLevelId || currentJobLevelId === -1,
  };

  useEffect(() => {
    if (isJobLevelsFetched && jobTypeId && !isValidJobLevelId(jobLevels, currentJobLevelId)) {
      form.change('jobLevelId', jobLevels[0]?.id || null);
    }
  }, [form, isJobLevelsFetched, currentJobLevelId, jobLevels, jobTypeId]);

  if (!isJobLevelsFetched) return null;

  const levelOptions = getLevelOptions(jobLevels, {
    defaultJobLevelId: currentJobLevelId,
    selectedOptionLongFormat: false,
    showOptionDescription: false,
  });

  const options = [multipleOption, ...levelOptions];

  return (
    <Field name="jobLevelId">
      {({ input }) => (
        <Select name={input.name} onChange={input.onChange} options={options} positionY="top" />
      )}
    </Field>
  );
};

interface BulkJobRoleFieldProps {
  benchmarkId: number;
  initialJobRole?: SuggestJobRole;
}

const BulkJobRoleField = ({ benchmarkId, initialJobRole }: BulkJobRoleFieldProps) => {
  const form = useForm();
  const { scenarioId } = useNumberParams();

  const onSelect = (roleId: number) => {
    if (roleId <= 0) {
      form.change('jobRoleId', roleId);
      form.change('jobRole', {
        value: roleId,
        data: { validLevels: GLOBALS.allLevels },
      });
    } else {
      fetchJobRole(roleId, scenarioId).then((jobRole) => {
        form.change('jobRoleId', roleId);
        form.change('jobRole', { value: roleId, data: jobRole });
      });
    }
  };

  return (
    <JobRoleInput
      benchmarkId={benchmarkId}
      name="jobRole"
      positionX="left"
      positionY="top"
      onSelect={onSelect}
      initialJobRole={initialJobRole}
    />
  );
};

interface BulkLocationNameFieldProps {
  initialLocationName?: string;
}

const BulkLocationNameField = ({ initialLocationName }: BulkLocationNameFieldProps) => {
  const form = useForm();

  const onSelect = (locationName: string, input: FieldInputProps<string, HTMLElement>) => {
    if (locationName === MULTIPLE_LOCATIONS) {
      form.change('locationName', null);
    } else {
      input.onChange(locationName);
    }
  };

  return (
    <Field name="locationName">
      {({ input }) => (
        <LocationNameInput
          initialLocationName={initialLocationName}
          name={input.name}
          onSelect={(locationName) => onSelect(locationName, input)}
        />
      )}
    </Field>
  );
};

export const BulkActionsDrawer = ({
  selected,
  setSelected,
  onConfirmAll,
  onClickEdit,
  className,
}: BulkActionsDrawerProps) => {
  return (
    <Form
      onSubmit={onConfirmAll}
      formClassName={classnames(styles.bulkEditForm, className, {
        [styles.open]: selected.size > 1,
      })}
      contentClassName={styles.formContent}
      actionsClassName={styles.formActions}
    >
      <Text variant="text-body-main-bold" color="neutral-full-white" className={styles.formText}>
        {selected.size} employees selected
      </Text>
      <div className={styles.actions}>
        <Tooltip
          content={`Confirming employees "as-is" means that you are content with all of the current selections for those employees' Benchmark Role, Level, and Location`}
        >
          <Button type="submit" variant={ButtonVariant.Filled} size={ButtonSize.Medium}>
            Confirm {selected.size} employees as-is
          </Button>
        </Tooltip>
        <Text variant="text-misc-caption" color="neutral-full-white">
          OR
        </Text>
        <Tooltip
          content="Click here to bulk-edit
          Benchmark Role, Level, and Location fields for all selected employees at once"
        >
          <Button variant={ButtonVariant.Filled} size={ButtonSize.Medium} onClick={onClickEdit}>
            Edit fields for {selected.size} employees
          </Button>
        </Tooltip>
      </div>

      <div className={styles.buttons}>
        <Button
          variant={ButtonVariant.Ghost}
          size={ButtonSize.Medium}
          className={styles.cancelButton}
          onClick={() => setSelected(new Set())}
        >
          Cancel
        </Button>
      </div>
    </Form>
  );
};

const MultiStatesBulkEditDrawer = ({
  benchmarkId,
  selected,
  setSelected,
  onChangeAll,
  onConfirmAll,
  initialJobLevelId = -1,
  initialLocationName,
  initialJobRole,
  className,
}: MultiStatesBulkEditDrawerProps) => {
  const [state, setState] = useState(BulkEditState.Actions);

  useEffect(() => {
    if (selected.size === 1) {
      setState(BulkEditState.Actions);
    }
  }, [selected]);

  if (state === BulkEditState.Actions) {
    return (
      <BulkActionsDrawer
        selected={selected}
        setSelected={setSelected}
        onConfirmAll={onConfirmAll}
        onClickEdit={() => setState(BulkEditState.Edit)}
        className={className}
      />
    );
  }

  const onFieldChange = () => {
    if (state === BulkEditState.Confirm) {
      setState(BulkEditState.Edit);
    }
  };

  const onSubmit = async (values: BulkEditForm) => {
    if (state === BulkEditState.Edit) {
      await onChangeAll(values);
      setState(BulkEditState.Confirm);
    } else {
      await onConfirmAll(values);
    }
  };

  const onCancel = () => {
    setSelected(new Set());
    setState(BulkEditState.Actions);
  };

  const initialValues = {
    jobRoleId: initialJobRole?.id,
    jobRole: initialJobRole
      ? { value: initialJobRole.id, label: initialJobRole.name, data: initialJobRole }
      : undefined,
    jobLevelId: initialJobLevelId || -1,
    locationName: initialLocationName,
  };

  return (
    <Form
      onSubmit={onSubmit}
      formClassName={classnames(styles.bulkEditForm, className, {
        [styles.open]: selected.size > 1,
      })}
      contentClassName={styles.formContent}
      actionsClassName={styles.formActions}
      initialValues={initialValues}
    >
      <Text variant="text-body-main-bold" color="neutral-full-white" className={styles.formText}>
        {state === BulkEditState.Edit
          ? `Edit fields for ${selected.size} employees`
          : `${selected.size} employees selected`}
      </Text>
      <div className={styles.fields}>
        <BulkJobRoleField benchmarkId={benchmarkId} initialJobRole={initialJobRole} />
        <BulkLevelField />
        <BulkLocationNameField initialLocationName={initialLocationName} />
      </div>
      <div className={styles.buttons}>
        <Button
          variant={ButtonVariant.Ghost}
          size={ButtonSize.Medium}
          className={styles.cancelButton}
          onClick={onCancel}
        >
          {state === BulkEditState.Edit ? 'Cancel' : 'Done'}
        </Button>
        {state === BulkEditState.Edit ? (
          <Tooltip
            content={`"Apply changes" will change the benchmarking fields for your selected employees. They will not be confirmed until the "Confirm" action has been taken.`}
          >
            <Button
              type="submit"
              variant={ButtonVariant.Outlined}
              size={ButtonSize.Medium}
              className={styles.submitButton}
            >
              Apply changes
            </Button>
          </Tooltip>
        ) : (
          <Tooltip
            content={`"Confirm" your employees once you have finished benchmarking them. These confirmed benchmarks will impact what market data the employees are compared to`}
          >
            <Button type="submit" variant={ButtonVariant.Filled} size={ButtonSize.Medium}>
              Confirm {selected.size} employees
            </Button>
          </Tooltip>
        )}
      </div>
      {['jobRoleId', 'jobLevelId', 'locationName'].map((field) => (
        <OnChange name={field} key={field}>
          {onFieldChange}
        </OnChange>
      ))}
    </Form>
  );
};

export default MultiStatesBulkEditDrawer;
